import clsx from 'clsx';
import { FC } from 'react';
import { SectionSchemaResponse } from 'src/api';
import GenFieldWithLabel from 'src/components/FinishFormGenTemplate/components/GenField/GenFieldWithLabel';
import RSoRString from 'src/components/FinishFormGenTemplate/components/RSoRString';
import SectionTitle from '../SectionTitle';
import { useFormContext } from 'react-hook-form';

export type GenSectionProps = {
  section: SectionSchemaResponse;
  baseNameFactory?: () => string;
  className?: string;
};

const GenSection: FC<GenSectionProps> = ({ className, baseNameFactory, section }) => {
  const { watch } = useFormContext();
  const baseName = baseNameFactory?.();
  const showIfName = getShowIfName(section, baseName);
  const showIfValues = section.showIfValues;
  const showIfFieldValue = showIfName && watch(showIfName);
  const valueMatch = showIfValues?.includes(showIfFieldValue);

  if (showIfName && !valueMatch) return null;

  return (
    <div className={clsx('flex flex-col gap-y-4 mb-16', className)}>
      <SectionTitle>
        <RSoRString label={section.label} />
      </SectionTitle>
      {section.fields.map((field) => (
        <GenFieldWithLabel key={field.id} field={field} baseName={baseName} />
      ))}
    </div>
  );
};

export default GenSection;

function getShowIfName(field: SectionSchemaResponse, baseName?: string): string | undefined {
  if (!field.showIfName) return undefined;
  return baseName ? `${baseName}.${field.showIfName}` : field.showIfName;
}
