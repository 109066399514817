import { Updater } from '@tanstack/react-table';
import useSearchParam, { UseSearchParamOptions } from 'src/hooks/router/useSearchParam';

const useNumberSearchParam = (
  paramName: string,
  options: UseSearchParamOptions<number | null> = {},
): readonly [number | null, (value: Updater<number | null>) => void] =>
  useSearchParam(paramName, {
    defaultValue: null,
    convertFromString: (value) => (value ? Number(value) : null),
    convertToString: (value) => (value ? value.toString() : ''),
    ...options,
  });
export default useNumberSearchParam;
