import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { ContactAresDataResponse } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

const useAresData = (ico: string): UseQueryResult<ContactAresDataResponse> => {
  const client = useApiClient();
  const { queryKey } = queryKeysFactory.aresData.detail(ico);

  return useQuery(
    queryKey,
    async () => {
      const data = await client.contact.getContractAresData({ ico });
      return data;
    },
    {
      enabled: ico.length === 8,
      retry: false,
    },
  );
};

export default useAresData;
