import { CreateOrderProductRequest, ProductResponse } from 'src/api';
import useCurrentTenantConfig from 'src/api/hooks/queries/useCurrentTenantConfig';

type UseGetIsHiddenProductFormFieldReturn = (
  fieldName:
    | keyof ProductResponse
    | keyof Omit<CreateOrderProductRequest, 'product' | 'createNewProduct' | 'productId'>,
) => boolean;

// TODO: analyze option to use ts-pattern for this kind of logic
const useGetIsHiddenProductFormField = (): UseGetIsHiddenProductFormFieldReturn => {
  const { data: config, isLoading } = useCurrentTenantConfig();

  if (isLoading) return () => true;
  return (fieldName) => {
    if (config?.productForm?.hiddenFields?.includes(fieldName)) return true;
    if (fieldName === 'productSerialNumber2' && !config?.productForm?.showProductSerialNumber2) return true;
    if (fieldName === 'technology' && !config?.productForm?.showTechnology) return true;
    if (fieldName === 'warranty' && !config?.productForm?.showWarranty) return true;

    return false;
  };
};

export default useGetIsHiddenProductFormField;
