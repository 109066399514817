import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { CreateOrderTypeRequest, OrderTypeRepsonse } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';
import { WithId } from 'src/types/mutations';

const useUpdateOrderType = (): UseMutationResult<OrderTypeRepsonse, unknown, WithId<CreateOrderTypeRequest>> => {
  const queryClient = useQueryClient();
  const client = useApiClient();

  return useMutation<OrderTypeRepsonse, unknown, WithId<CreateOrderTypeRequest>>(
    async (orderType: WithId<CreateOrderTypeRequest>) => {
      const data = await client.orderTypes.updateOrderType({ requestBody: orderType, id: orderType.id });
      return data as OrderTypeRepsonse;
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(queryKeysFactory.orderTypes.list.queryKey);
        queryClient.invalidateQueries(queryKeysFactory.orderTypes.detail(data.id).queryKey);
        toast(<FormattedMessage id='app.toasts.update_success' />, {
          type: 'success',
        });
      },
      onError: () => {
        toast(<FormattedMessage id='app.toasts.update_fail' />, {
          type: 'error',
        });
      },
    },
  );
};

export default useUpdateOrderType;
