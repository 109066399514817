import { FC, HTMLAttributes } from 'react';
import AutoPopup from 'src/components/common/AutoPopup';
import { FormattedMessage } from 'react-intl';
import AddBranchForm from '../AddBranchForm';
import { useParams } from 'react-router-dom';

export type AddBranchPopupProps = {
  //
} & HTMLAttributes<HTMLDivElement>;

const AddBranchPopup: FC<AddBranchPopupProps> = ({ className }) => {
  const contactId = Number(useParams().contactId);

  return (
    <AutoPopup
      id='add_branch'
      title={<FormattedMessage id='app.contact_page.add_branch.popup_title' defaultMessage='Editace zarizeni' />}
      className={className}
    >
      <AddBranchForm contactId={contactId} />
    </AutoPopup>
  );
};

AddBranchPopup.displayName = 'AddBranchPopup';

export default AddBranchPopup;
