import { startOfMonth, endOfMonth, startOfWeek, endOfWeek, addDays, eachDayOfInterval } from 'date-fns';

const MAX_ROWS_TO_DISPLAY = 6;
const MIN_ROWS_TO_DISPLAY = 5;

export function getCalendarDates(dayOfTheMonth: Date): Date[] {
  const firstDayOfMonth = startOfMonth(dayOfTheMonth);
  const lastDayOfMonth = endOfMonth(firstDayOfMonth);

  const startDate = startOfWeek(firstDayOfMonth, { weekStartsOn: 1 });
  const endDate = endOfWeek(lastDayOfMonth, { weekStartsOn: 1 });

  const calendarDates = eachDayOfInterval({ start: startDate, end: endDate });

  const hasMonthDateOnTheLastRow = calendarDates.length > 35;
  const rowsToDisplay = hasMonthDateOnTheLastRow ? MAX_ROWS_TO_DISPLAY : MIN_ROWS_TO_DISPLAY;
  const totalDays = 7 * rowsToDisplay;
  if (calendarDates.length >= totalDays) return calendarDates;

  const extraDaysNeeded = totalDays - calendarDates.length;
  const lastDate = calendarDates[calendarDates.length - 1];

  for (let i = 0; i < extraDaysNeeded; i++) {
    calendarDates.push(addDays(lastDate, i + 1));
  }

  return calendarDates;
}
