import { FC, useMemo } from 'react';
import { getCalendarDates } from 'src/components/datetime/WeekPicker/helpers';
import useCalendarFocusedDateParam from 'src/pages/CalendarPage/hooks/useCalendarFocusedDateParam';
import { chunks } from 'src/utils/chunks';
import MonthTabDayCell from './MonthTabDayCell';
import clsx from 'clsx';
import { isSameDay, isSameMonth } from 'date-fns';
import useTechnicianMonthOrders from 'src/api/hooks/queries/useTechnicianMonthOrders';
import useCalendarSingleTechnicianIdParam from 'src/pages/CalendarPage/hooks/useCalendarSingleTechnicianIdParam';
import parseISO from 'date-fns/esm/fp/parseISO/index.js';
import { OrderListItemResponse } from 'src/api';

export type MonthTabGridProps = {
  className?: string;
};

const MonthTabGrid: FC<MonthTabGridProps> = () => {
  const [focusedDate] = useCalendarFocusedDateParam();
  const dates = useMemo(() => getCalendarDates(focusedDate), [focusedDate]);
  const weekRows = useMemo(() => chunks(dates, 7), [dates]);
  const [technicianId] = useCalendarSingleTechnicianIdParam();
  const { data: orders } = useTechnicianMonthOrders(
    technicianId ?? 0,
    focusedDate.getMonth(),
    focusedDate.getFullYear(),
    { enabled: !!technicianId },
  );

  const ordersByDate = useMemo(
    () =>
      dates.reduce((acc, date) => {
        const dateOrders = orders?.filter((i) => i.datetime && isSameDay(parseISO(i.datetime), date)) ?? [];
        acc[date.getTime()] = dateOrders;
        return acc;
      }, {} as Record<number, OrderListItemResponse[]>),
    [dates, orders],
  );

  return (
    <div className='w-full flex flex-col'>
      {weekRows.map((week, index) => (
        <div key={index} className={clsx('flex w-full', index < weekRows.length - 1 && 'border-b border-neutral-200')}>
          {week.map((date, index) => (
            <MonthTabDayCell
              key={date.getTime()}
              date={date}
              orders={ordersByDate[date.getTime()]}
              greyedOut={!isSameMonth(focusedDate, date)}
              className={clsx('flex-1 py-2 px-1.5', index < week.length - 1 && 'border-r border-r-neutral-200')}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

export default MonthTabGrid;
