import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { ServiceFilter } from 'src/types/filters';
import { useApiClient } from 'src/api';
import { SortingQuery } from 'src/api/types/shared/sorting';
import { PaginationState } from '@tanstack/react-table';
import { DEFAULT_TABLE_PAGINATION } from 'src/constants/table';
import queryKeysFactory from 'src/store/queryKeysFactory';
import { OrderListItemsPageResponse } from 'src/api/.gen';

export const useOrders = (
  filters?: ServiceFilter,
  sorting?: SortingQuery,
  pagination: PaginationState = DEFAULT_TABLE_PAGINATION,
): UseQueryResult<OrderListItemsPageResponse> => {
  const client = useApiClient();
  const { queryKey } = queryKeysFactory.orders.list._ctx.search(filters, sorting, pagination);
  const { technicianIds, statusProp, paymentMethods, ...restFilters } = filters || {};

  return useQuery(
    queryKey,
    () =>
      client.orders.getOrderList({
        ...restFilters,
        ...(statusProp && { statusProp: statusProp.join(',') }),
        ...(technicianIds && { technicianIds: technicianIds.join(',') }),
        ...(paymentMethods && { paymentMethods: paymentMethods.join(',') }),
        ...sorting,
        ...pagination,
      }),
    {
      refetchInterval: false,
      staleTime: Infinity,
    },
  );
};
