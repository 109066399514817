import { FC, useCallback } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { CreateVatTariffRequest, VatTariffResponse } from 'src/api';
import { usePopupContext } from 'src/components/Popup/hook';
import VatTariffForm from '../ParametersPage/components/VatTariffForm';
import useUpdateVatTariff from 'src/api/hooks/mutations/vatTariffs/useUpdateVatTariff';
import useAllVatTariffs from 'src/api/hooks/queries/useAllVatTariffs';

export type UpdateVatTariffFormProps = {
  id: number;
};

const UpdateVatTariffForm: FC<UpdateVatTariffFormProps> = ({ id }) => {
  const { data: vatTariffs } = useAllVatTariffs();
  const data = vatTariffs?.find((vatTariff) => vatTariff.id === id) as VatTariffResponse;
  const mutation = useUpdateVatTariff();
  const { onClose } = usePopupContext();

  const onSubmit: SubmitHandler<CreateVatTariffRequest> = useCallback(
    (data) => {
      mutation.mutate({ id, data });
      onClose();
    },
    [mutation],
  );

  return <VatTariffForm defaultValues={data} disableValue onSubmit={onSubmit} />;
};

UpdateVatTariffForm.displayName = 'UpdateVatTariffForm';

export default UpdateVatTariffForm;
