import { FC } from 'react';
import { ProductTemplateResponse } from 'src/api';
import { createColumnHelper } from '@tanstack/react-table';
import { ExtendedColumnDef } from 'src/types/table';
import Table, { TableProps } from 'src/components/Table';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

export type ProductTemplatesTableProps = Omit<TableProps<ProductTemplateResponse>, 'columns'>;

const columnHelper = createColumnHelper<ProductTemplateResponse>();

const columns: ExtendedColumnDef<ProductTemplateResponse>[] = [
  {
    ...columnHelper.accessor('productName', {
      id: 'productName',
      header: () => <FormattedMessage id='app.product.name' />,
      enableColumnFilter: false,
      enableSorting: false,
      cell: (info) => info.renderValue(),
    }),
  },
  {
    ...columnHelper.accessor('productProducer', {
      id: 'order.datetime',
      header: () => <FormattedMessage id='app.product.producer' />,
      enableColumnFilter: false,
      enableSorting: false,
      cell: (info) => info.renderValue(),
    }),
  },
];

const ProductTemplatesTable: FC<ProductTemplatesTableProps> = (props) => {
  const navigate = useNavigate();

  return (
    <Table {...props} columns={columns} handleRowClick={(original) => navigate(`/admin/devices/${original.id}`)} />
  );
};

ProductTemplatesTable.displayName = 'ProductTemplatesTable';

export default ProductTemplatesTable;
