import clsx from 'clsx';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Navigate, useParams } from 'react-router-dom';
import { ContactResponse } from 'src/api';
import useBranchProducts from 'src/api/hooks/queries/useBranchProducts';
import useContactBranch from 'src/api/hooks/queries/useContactBranch';
import ParametersListWithPreviews from 'src/components/common/ParametersListWithPreviews';
import Loader from 'src/components/utils/Loader';
import mapOrEmpty from 'src/helpers/mapOrEmpty';
import useOpenPopup from 'src/hooks/popups/useOpenPopup';
import useIsCurrentUserAllowedToEdit from 'src/hooks/useIsCurrentUserAllowedToEdit';
import AddProductPopup, { ADD_PRODUCT_POPUP_ID } from '../AddProductPopup';
import BranchPreview from '../BranchPreview';
import ProductListItemDetail from '../ProductListItemDetail';

type BranchDetailProps = {
  className?: string;
};

const BranchDetail: React.FC<BranchDetailProps> = ({ className }) => {
  const contactId = Number(useParams().contactId);
  const branchId = Number(useParams().branchId);

  const openAddProductPopup = useOpenPopup(ADD_PRODUCT_POPUP_ID);

  const isCurrentUserAllowedToEdit = useIsCurrentUserAllowedToEdit();
  const { data, isFetching } = useContactBranch(branchId);
  const { data: products, isLoading: isLoadingProducts } = useBranchProducts(branchId);

  const productsListItems = useMemo(
    () =>
      mapOrEmpty(
        (i) => ({
          title: i.productName + (i.productSerialNumber ? ` (${i.productSerialNumber})` : ``),
          path: `/contact/${contactId}/branch/${i.branchId}/product/${i.id}`,
          renderPreview: () => <ProductListItemDetail branchId={i.branchId ?? undefined} productId={i.id} />,
        }),
        products,
      ),
    [products],
  );

  if (isFetching || isLoadingProducts) return <Loader />;
  if (!data) return <Navigate to='/404' />;

  return (
    <>
      <div className={clsx(className, 'flex flex-col')}>
        <BranchPreview className='mb-20' data={data} />
        <ParametersListWithPreviews
          data={productsListItems}
          onAddClick={openAddProductPopup}
          hideAddButton={!isCurrentUserAllowedToEdit}
          title={<FormattedMessage id='app.contact_page.products_list.title' />}
        />
      </div>
      <AddProductPopup category={ContactResponse.category.B2B} contactId={contactId} fixedBranchId={branchId} />
    </>
  );
};

export default BranchDetail;
