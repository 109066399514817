import { FC } from 'react';
import clsx from 'clsx';
import Toggle from '../Toggle/Toggle';
import { ContactResponse } from 'src/api';

export type ToggleB2Cb2BProps = {
  className?: string;
  value: ContactResponse.category;
  onChange: (value: ContactResponse.category) => void;
};

const ToggleB2Cb2B: FC<ToggleB2Cb2BProps> = ({ className, value, onChange }) => {
  return (
    <div className={clsx('flex gap-x-4 w-full', className)}>
      <span>B2B</span>
      <Toggle
        alwayActive
        value={value === ContactResponse.category.B2C}
        onChange={(v: boolean) => onChange(v ? ContactResponse.category.B2C : ContactResponse.category.B2B)}
      />
      <span>B2C</span>
    </div>
  );
};

ToggleB2Cb2B.displayName = 'ToggleB2Cb2B';

export default ToggleB2Cb2B;
