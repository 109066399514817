import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

const useDeleteTenant = (): UseMutationResult<void, unknown, number> => {
  const client = useApiClient();
  const queryClient = useQueryClient();

  return useMutation<void, unknown, number>(
    async (tenantId: number) => {
      const data = await client.tenant.deactivateTenant({ id: tenantId });
      return data;
    },
    {
      onSuccess: (_, tenantId) => {
        queryClient.invalidateQueries(queryKeysFactory.tenant.list.queryKey);
        queryClient.invalidateQueries(queryKeysFactory.tenant.detail(tenantId));
        toast(<FormattedMessage id='app.toasts.delete_success' />, {
          type: 'success',
        });
      },
      onError: () => {
        toast(<FormattedMessage id='app.toasts.delete_fail' />, {
          type: 'error',
        });
      },
    },
  );
};

export default useDeleteTenant;
