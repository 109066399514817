import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { UpdateContactRequest, ContactResponse } from 'src/api';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';
import { WithId } from 'src/types/mutations';

const useUpdateContact = (): UseMutationResult<ContactResponse, unknown, WithId<UpdateContactRequest>> => {
  const client = useApiClient();
  const queryClient = useQueryClient();

  return useMutation<ContactResponse, unknown, WithId<UpdateContactRequest>>(
    async (contact: WithId<UpdateContactRequest>) => {
      const data = await client.contact.updateContact({ id: contact.id, requestBody: contact });
      return data as ContactResponse;
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(['contacts']);
        queryClient.invalidateQueries(queryKeysFactory.contacts.detail(variables.id).queryKey);
        toast(<FormattedMessage id='app.toasts.update_success' defaultMessage='Záznam úspěšně zmenen' />, {
          type: 'success',
        });
      },
      onError: () => {
        toast(<FormattedMessage id='app.toasts.update_fail' defaultMessage='Chyba zmeny záznamu' />, {
          type: 'error',
        });
      },
    },
  );
};

export default useUpdateContact;
