/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { OrderResponse } from 'src/api';
import { useApiClient } from 'src/api/useApiClient';
import { OrderSchema, orderSchemaToCreateOrderRequest } from 'src/pages/OrdersPage/ServiceForm/schema';
import queryKeysFactory from 'src/store/queryKeysFactory';

const useAddOrder = (): UseMutationResult<OrderResponse, AxiosError<any, any>, OrderSchema> => {
  const client = useApiClient();
  const queryClient = useQueryClient();

  return useMutation<OrderResponse, AxiosError<any, any>, OrderSchema>(
    async (order: OrderSchema) => {
      const requestBody = orderSchemaToCreateOrderRequest(order);
      const data = await client.orders.create({ requestBody });
      return data as OrderResponse;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeysFactory.orders.list.queryKey);
        queryClient.invalidateQueries(queryKeysFactory.allTechniciansWeekOrders._def);
        queryClient.invalidateQueries(queryKeysFactory.technicianMonthOrders._def);
        queryClient.invalidateQueries(queryKeysFactory.dashboard._def);

        toast(<FormattedMessage id='app.toasts.add_success' defaultMessage='Záznam úspěšně přidán' />, {
          type: 'success',
        });
      },
      onError: (error) => {
        if (error.response?.status === 409) {
          toast(
            <FormattedMessage
              id='app.toasts.service_already_exists'
              defaultMessage='Zadané IČ již existuje. Kontakt nebyl uložen, ale servisní zakázka byla založena'
            />,
            { type: 'error' },
          );
          return;
        }
        toast(<FormattedMessage id='app.toasts.add_fail' defaultMessage='Chyba přidáni záznamu' />, {
          type: 'error',
        });
      },
    },
  );
};

export default useAddOrder;
