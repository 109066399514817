import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { NumberFieldControlled } from 'src/components/fields/NumberField';
import FieldWrapper from 'src/pages/TechnicianOrderFinishPage/components/FieldWrapper';
import { FinishOrderFormValues } from '../../../schema';

export type ManualDeliveryFormProps = {
  className?: string;
  hiddenFields?: string[];
};

const ManualDeliveryForm: React.FC<ManualDeliveryFormProps> = ({ className, hiddenFields }) => {
  const { control } = useFormContext<FinishOrderFormValues>();

  if (hiddenFields?.includes('deliveryPrice')) return null;

  return (
    <FieldWrapper className={className} label={<FormattedMessage id='app.finish_order.delivery_price' />}>
      <NumberFieldControlled control={control} name='deliveryPrice' />
    </FieldWrapper>
  );
};

export default ManualDeliveryForm;
