import { FC } from 'react';
import Popup from 'src/components/Popup';
import { FormattedMessage } from 'react-intl';

export type UserLimitExceededPopupProps = {
  open: boolean;
  onClose: () => void;
};

const UserLimitExceededPopup: FC<UserLimitExceededPopupProps> = ({ open, onClose }) => {
  return (
    <Popup open={open} onClose={onClose} title={<FormattedMessage id='app.users_limit_exceeded.popup.title' />}>
      <FormattedMessage id='app.users_limit_exceeded.popup.text' />
    </Popup>
  );
};

UserLimitExceededPopup.displayName = 'UserLimitExceededPopup';

export default UserLimitExceededPopup;
