import jwtDecode from 'jwt-decode';
import { useSearchParams } from 'react-router-dom';
import { FinishOrderJwtPayload } from 'src/types/jwt';

const useOrderIdFromToken = (): number => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const { orderId } = jwtDecode<FinishOrderJwtPayload>(token ?? '');

  return orderId;
};

export default useOrderIdFromToken;
