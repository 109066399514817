import { LinkIcon } from '@heroicons/react/24/outline';
import { createColumnHelper } from '@tanstack/react-table';
import { format, parseISO } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { ProductListWithLastOrderResponse } from 'src/api';
import Table, { TableProps } from 'src/components/Table';
import HyperLink from 'src/components/common/HyperLink';
import { ExtendedColumnDef } from 'src/types/table';

export type ProductsTableProps = Omit<TableProps<ProductListWithLastOrderResponse>, 'columns'>;

const columnHelper = createColumnHelper<ProductListWithLastOrderResponse>();

const columns: ExtendedColumnDef<ProductListWithLastOrderResponse>[] = [
  {
    ...columnHelper.accessor('productName', {
      id: 'productName',
      header: () => <FormattedMessage id='app.product.name' />,
      enableColumnFilter: false,
      enableSorting: false,
      cell: (info) => info.renderValue(),
    }),
  },
  {
    ...columnHelper.accessor('productSerialNumber', {
      id: 'productSerialNumber',
      header: () => <FormattedMessage id='app.product.serial_number' />,
      enableColumnFilter: false,
      enableSorting: false,
      cell: (info) => info.renderValue(),
    }),
  },
  {
    ...columnHelper.accessor('productSerialNumber2', {
      id: 'productSerialNumber2',
      header: () => <FormattedMessage id='app.product.serial_number' />,
      enableColumnFilter: false,
      enableSorting: false,
    }),
    showOnlyWhen: (config) => !!config?.productForm?.showProductSerialNumber2,
  },
  {
    ...columnHelper.accessor('technology', {
      id: 'technology',
      header: () => <FormattedMessage id='app.product.technology' />,
      enableColumnFilter: false,
      enableSorting: false,
    }),
    showOnlyWhen: (config) => !!config?.productForm?.showTechnology,
  },
  {
    ...columnHelper.accessor('warranty', {
      id: 'waranty',
      header: () => <FormattedMessage id='app.product.warranty' />,
      cell: (info) => (info.getValue() ? format(parseISO(info.getValue()), 'dd.MM.yyyy') : null),
      enableColumnFilter: false,
      enableSorting: false,
    }),
    showOnlyWhen: (config) => !!config?.productForm?.showWarranty,
  },
  {
    ...columnHelper.accessor('companyName', {
      id: 'companyName',
      header: () => <FormattedMessage id='app.order.company_name' />,
      enableColumnFilter: false,
      enableSorting: false,
      cell: (info) => info.renderValue(),
    }),
  },
  {
    ...columnHelper.accessor('branchName', {
      id: 'branchName',
      header: () => <FormattedMessage id='app.order.branch_name' />,
      enableColumnFilter: false,
      enableSorting: false,
      cell: (info) => info.renderValue(),
    }),
  },
  {
    ...columnHelper.accessor('lastOrderId', {
      id: 'branchName',
      header: () => <FormattedMessage id='app.product.last_order' />,
      enableColumnFilter: false,
      enableSorting: false,
      cell: (info) =>
        info.row.original.lastOrderDate && (
          <HyperLink className='flex gap-2' href={`/order/${info.row.original.lastOrderId}`}>
            {format(parseISO(info.row.original.lastOrderDate), 'yyyy-MM-dd')}
            <LinkIcon className='w-7' />
          </HyperLink>
        ),
    }),
  },
  {
    ...columnHelper.accessor('deletedAt', {
      id: 'name',
      header: () => <FormattedMessage id='app.common.active' />,
      enableColumnFilter: false,
      enableSorting: false,
      cell: (info) =>
        info.getValue() ? <FormattedMessage id='app.common.no' /> : <FormattedMessage id='app.common.yes' />,
    }),
  },
];

const ProductsTable: React.FC<ProductsTableProps> = (props) => {
  const navigate = useNavigate();

  return <Table columns={columns} handleRowClick={(original) => navigate(`/product/${original.id}`)} {...props} />;
};

export default ProductsTable;
