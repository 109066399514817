import { FC, useCallback, useState } from 'react';
import clsx from 'clsx';
import { useParams, useNavigate, Navigate } from 'react-router-dom';
import Loader from 'src/components/utils/Loader';
import WorkTariffPreview from './WorkTariffPreview';
import { FormattedMessage } from 'react-intl';
import BackIcon from 'src/components/Icons/BackIcon';
import PageContentHeader from 'src/components/PageContentHeader';
import Popup from 'src/components/Popup';
import useWorkTariff from 'src/api/hooks/queries/useWorkTariff';
import UpdateWorkTariffFrom from './UpdateDeliveryTariffFrom';
import useDeleteWorkTariff from 'src/api/hooks/mutations/workTariffs/useDeleteWorkTariff';
import useRestoreWorkTariff from 'src/api/hooks/mutations/workTariffs/useRestoreWorkTariff';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import Button from 'src/components/Button';
import PageCard from 'src/components/PageCard';

export type WorkTariffsDetailPageProps = {
  className?: string;
};

const WorkTariffsDetailPage: FC<WorkTariffsDetailPageProps> = ({ className }) => {
  const workTariffId = Number(useParams().workTariffId);
  const navigate = useNavigate();
  const { isLoading, data } = useWorkTariff(workTariffId);
  const deleteWorkTariff = useDeleteWorkTariff();
  const restoreWorkTariff = useRestoreWorkTariff();
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleButtonClick = useCallback(() => {
    if (data?.deletedAt) restoreWorkTariff.mutate(workTariffId);
    else deleteWorkTariff.mutate(workTariffId);
  }, [restoreWorkTariff, deleteWorkTariff, data, workTariffId]);

  if (isLoading) return <Loader />;
  if (!data) return <Navigate to='/404' />;

  return (
    <div className={clsx(className, 'flex flex-col')}>
      <PageContentHeader
        mobilePrimaryButtonType='edit'
        className='my-4'
        title={
          <div className='flex gap-x-2'>
            <FormattedMessage id='app.work_tariff_detail_page.title' values={{ value: data.id }} />
            <Button size='small' variant='cta' onClick={handleButtonClick}>
              {data?.deletedAt ? <EyeIcon className='h-6 mr-1' /> : <EyeSlashIcon className='h-6 mr-1' />}
            </Button>
          </div>
        }
        primaryButtonText={<FormattedMessage id='app.buttons.edit' />}
        secondaryButtonText={
          <>
            <BackIcon />
            <FormattedMessage id='app.buttons.back' defaultMessage='Zpět' />
          </>
        }
        onPrimaryButtonClick={() => setIsPopupOpen(true)}
        onSecondaryButtonClick={() => navigate(-1)}
      />
      <PageCard>
        <WorkTariffPreview data={data} />
      </PageCard>
      <Popup
        title={<FormattedMessage id='app.delivery_tariff_page.update_tarriff_popup_title' />}
        open={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
      >
        <UpdateWorkTariffFrom workTariffId={workTariffId} />
      </Popup>
    </div>
  );
};

WorkTariffsDetailPage.displayName = 'WorkTariffsDetailPage';

export default WorkTariffsDetailPage;
