import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import { Fragment, PropsWithoutRef, ReactNode } from 'react';
import { standartTransitionProps } from 'src/constants/transitions';

export type DropdownProps<T> = {
  value: T;
  label?: ReactNode;
  items: {
    label: ReactNode;
    value: T;
    disabled?: boolean;
  }[];
  disabled?: boolean;
  onChange: (value: T) => void;
  hideErrorMessage?: boolean;
  error?: ReactNode;
  variant?: 'normal' | 'outlined';
  vertical?: 'top' | 'bottom';
  horizontal?: 'left' | 'right';
};

type ClassNames = Partial<{ button: string; items: string; menu: string }>;

const getVariantCalssNames = (variant: 'normal' | 'outlined'): ClassNames => {
  if (variant === 'outlined') {
    return {
      menu: 'w-max',
      button: 'flex text-md text-gray-700 items-center',
      items:
        'absolute left-0 z-10 mt-2 w-max origin-top-right rounded bg-white shadow-lg ring-1 ring-gray-1000 ring-opacity-5 focus:outline-none',
    };
  }

  // normal
  return {
    menu: 'w-full',
    button:
      'inline-flex w-full h-10 rounded border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100',
    items:
      'absolute right-0 z-10 mt-2 w-56 origin-top-right rounded bg-white shadow-lg ring-1 ring-gray-1000 ring-opacity-5 focus:outline-none',
  };
};

const getPositionClassNames = (vertical: 'top' | 'bottom', horizontal: 'left' | 'right'): ClassNames => {
  const classNames = {
    items: '',
    button: '',
    menu: '',
  };

  if (vertical === 'top') classNames.items += ' bottom-10 ';
  if (horizontal === 'left') classNames.items += ' left-0 ';

  return classNames;
};

const Dropdown = <T,>({
  disabled,
  value,
  items,
  label,
  onChange,
  hideErrorMessage,
  error,
  vertical = 'bottom',
  horizontal = 'right',
  variant = 'normal',
}: PropsWithoutRef<DropdownProps<T>>): JSX.Element => {
  const selected = items.find((i) => i.value === value);
  const variantClassNames = getVariantCalssNames(variant);
  const positionClassNames = getPositionClassNames(vertical, horizontal);

  return (
    <Menu as='div' className={clsx('relative inline-block text-left', variantClassNames.menu, positionClassNames.menu)}>
      <div>
        <Menu.Button
          disabled={disabled}
          className={clsx(
            variantClassNames.button,
            positionClassNames.button,
            selected?.label ?? label ? 'justify-between' : 'justify-end',
            disabled && 'cursor-not-allowed',
          )}
        >
          {selected?.label ?? label}
          <ChevronDownIcon className='-mr-1 ml-2 h-5 w-5' aria-hidden='true' />
        </Menu.Button>
      </div>

      <Transition as={Fragment} {...standartTransitionProps}>
        <Menu.Items className={clsx(variantClassNames.items, positionClassNames.items)}>
          <div className='py-1'>
            {items.map((i, index) => (
              <Menu.Item key={index}>
                {({ active }) => (
                  <span
                    onClick={() => onChange(i.value)}
                    className={clsx(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm')}
                  >
                    {i.label}
                  </span>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
      {!hideErrorMessage && error && <span className='text-error'>{error}</span>}
    </Menu>
  );
};

export default Dropdown;
