import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { CreateMaterialRequest, UpdateMaterialRequest } from 'src/api';
import NewItemPopupFooter from 'src/components/common/NewItemPopupFooter';
import { NumberFieldControlled } from 'src/components/fields/NumberField';
import { TextFieldControlled } from 'src/components/fields/Textfield';
import { MaterialSchema } from './schema';

export type MaterialFormProps = {
  defaultValues?: Partial<CreateMaterialRequest>;
  onSubmit: SubmitHandler<CreateMaterialRequest & UpdateMaterialRequest>;
  className?: string;
};

const DEFAULT_VALUES: Partial<CreateMaterialRequest> = {
  materialValue: 0,
  materialValueEur: 0,
  materialName: '',
  materialCode: '',
  supplierCode: '',
};

const MaterialForm: React.FC<MaterialFormProps> = ({ defaultValues, onSubmit }) => {
  const form = useForm<MaterialSchema>({
    resolver: zodResolver(MaterialSchema),
    defaultValues: { ...DEFAULT_VALUES, ...defaultValues },
  });

  const { handleSubmit, control } = form;

  return (
    <FormProvider {...form}>
      <div className='flex-col w-full'>
        <form className='flex flex-col w-full gap-y-4' onSubmit={handleSubmit(onSubmit, console.error)}>
          <TextFieldControlled
            control={control}
            name='materialName'
            asterisk
            label={<FormattedMessage id='app.material.name' />}
          />
          <NumberFieldControlled
            label={<FormattedMessage id='app.material.value' />}
            control={control}
            name='materialValue'
          />
          <NumberFieldControlled
            control={control}
            name='materialValueEur'
            label={<FormattedMessage id='app.common.price_eur' />}
          />
          <TextFieldControlled
            control={control}
            name='materialCode'
            asterisk
            label={<FormattedMessage id='app.material.code' />}
          />
          <TextFieldControlled
            control={control}
            name='supplierCode'
            label={<FormattedMessage id='app.material.supplier_code' />}
          />
          <NewItemPopupFooter />
        </form>
      </div>
    </FormProvider>
  );
};

export default MaterialForm;
