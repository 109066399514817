import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { TranslationPackResponse } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

export type UseAddCustomTranslationPackPayload = {
  language: string;
};

const useAddCustomTranslationPack = (): UseMutationResult<
  TranslationPackResponse,
  unknown,
  UseAddCustomTranslationPackPayload
> => {
  const client = useApiClient();
  const queryClient = useQueryClient();

  return useMutation<TranslationPackResponse, unknown, UseAddCustomTranslationPackPayload>(
    async (data: UseAddCustomTranslationPackPayload) => {
      const res = await client.translationPacks.createCustomTranslationPack({ requestBody: data });
      return res;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeysFactory.customTranslationPacks.list.queryKey);
        queryClient.invalidateQueries(queryKeysFactory.customTranslationPacks.languagesList.queryKey);

        toast(<FormattedMessage id='app.toasts.add_success' />, {
          type: 'success',
        });
      },
      onError: () => {
        toast(<FormattedMessage id='app.toasts.add_fail' />, {
          type: 'error',
        });
      },
    },
  );
};

export default useAddCustomTranslationPack;
