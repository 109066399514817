import React, { useMemo } from 'react';
import HelperText from 'src/components/common/HelperText';
import Select from '../Select';
import withController from 'src/hoc/withController';
import { setHours, setMinutes, parseISO } from 'date-fns';

export type TimePickerProps = {
  label?: React.ReactNode;
  value: string;
  onChange: (date: string) => void;
  startHour: number;
  endHour: number;
  stepInMinutes: number;
  hideErrorMessage?: boolean;
  error?: string;
  name: string;
};

const TimePicker: React.ForwardRefRenderFunction<HTMLInputElement, TimePickerProps> = (props) => {
  const { value, name, onChange, error, hideErrorMessage, startHour, endHour, stepInMinutes } = props;
  const options = useMemo(
    () => generateTimeOptions(startHour, endHour, stepInMinutes),
    [startHour, endHour, stepInMinutes],
  );

  const handleChange = (option: string): void => {
    const [hour, minute] = option.split(':');
    const data = new Date();

    const dateWithHours = setHours(data, parseInt(hour));
    const dateWithMinutes = setMinutes(dateWithHours, parseInt(minute));
    onChange(dateWithMinutes.toISOString());
  };

  return (
    <div className='flex flex-col w-full'>
      <Select
        name={name}
        options={options}
        getOptionLabel={(i) => i}
        getOptionValue={(i) => i}
        onChange={handleChange}
        value={datetimeToOption(value) ?? ''}
      />
      {!hideErrorMessage && error && <HelperText variant='error'>{error}</HelperText>}
    </div>
  );
};

export const TimePickerRhfc = withController(TimePicker);

function generateTimeOptions(startHour: number, endHour: number, stepInMinutes: number): string[] {
  const options = [];
  for (let i = startHour; i <= endHour; i++) {
    for (let j = 0; j < 60; j += stepInMinutes) {
      const hour = i.toString().padStart(2, '0');
      const minute = j.toString().padStart(2, '0');
      options.push(`${hour}:${minute}`);
    }
  }
  return options;
}

function datetimeToOption(datetime: string): string | null {
  try {
    const v = parseISO(datetime);
    const hour = v.getHours().toString().padStart(2, '0');
    const minute = v.getMinutes().toString().padStart(2, '0');

    return `${hour}:${minute}`;
  } catch (e) {
    console.error(e);
    return null;
  }
}

export default React.forwardRef(TimePicker);
