import clsx from 'clsx';
import { range } from 'ramda';
import { CSSProperties, FC } from 'react';
import useEventsGridSettings from '../../hooks/useEventsGridSettings';

export type HoursBarProps = {
  style?: CSSProperties;
  className?: string;
};

const HoursBar: FC<HoursBarProps> = ({ className, style }) => {
  const { START_HOUR, END_HOUR } = useEventsGridSettings();
  const hours = range(START_HOUR, END_HOUR);

  return (
    <div
      className={clsx('sticky left-0 z-10 w-24 flex-none border-r-2 border-neutral-300 bg-neutral-100', className)}
      style={style}
    >
      <div
        className='col-start-1 col-end-2 row-start-1 grid'
        style={{
          gridTemplateRows: `repeat(${END_HOUR - START_HOUR}, minmax(3.5rem, 1fr))`,
        }}
      >
        {hours.map((hour, index) => (
          <div
            key={hour}
            data-events-grid={`hour-${hour}`}
            className={clsx(
              'text-lg w-24 h-24 flex justify-end p-2',
              index < hours.length - 1 && 'border-b-2 border-neutral-200',
            )}
          >
            {hour}:00
          </div>
        ))}
      </div>
    </div>
  );
};

HoursBar.displayName = 'HoursBar';

export default HoursBar;
