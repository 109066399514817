import { FC, HTMLAttributes, useCallback } from 'react';
import useAddMaterial from 'src/api/hooks/mutations/material/useAddMaterial';
import { CreateMaterialRequest } from 'src/api';
import { SubmitHandler } from 'react-hook-form';
import { usePopupContext } from 'src/components/Popup/hook';
import MaterialForm from '../MaterialForm';

export type AddMaterialFormProps = {
  //
} & HTMLAttributes<HTMLDivElement>;

const AddMaterialForm: FC<AddMaterialFormProps> = ({ className }) => {
  const addMaterial = useAddMaterial();

  const { onClose } = usePopupContext();

  const onSubmit: SubmitHandler<CreateMaterialRequest> = useCallback(
    (data) => {
      addMaterial.mutate(data);
      onClose();
    },
    [addMaterial, onClose],
  );

  return <MaterialForm onSubmit={onSubmit} className={className} />;
};

AddMaterialForm.displayName = 'AddMaterialForm';

export default AddMaterialForm;
