import { FC } from 'react';
import { twMerge } from 'tailwind-merge';
import useCalendarSelectedDateParam from '../../hooks/useCalendarSelectedDateParam';
import { startOfWeek, endOfWeek, eachDayOfInterval, format, isSameDay } from 'date-fns';
import clsx from 'clsx';
import useUserFnsLocale from 'src/api/hooks/queries/useUserFnsLocale';

export type CalendarWeekDaysHeaderProps = {
  className?: string;
};

const CalendarWeekDaysHeader: FC<CalendarWeekDaysHeaderProps> = ({ className }) => {
  const { data: locale } = useUserFnsLocale();
  const [selectedDate, setSelectedDate] = useCalendarSelectedDateParam();

  const start = startOfWeek(selectedDate, { locale, weekStartsOn: 1 });
  const end = endOfWeek(selectedDate, { locale, weekStartsOn: 1 });
  const daysInWeek = eachDayOfInterval({ start, end });

  return (
    <div className={twMerge('flex justify-between', className)}>
      {daysInWeek.map((day) => (
        <button
          type='button'
          onClick={() => setSelectedDate(day)}
          key={day.getTime()}
          className={clsx('flex flex-col gap-y-1 items-center text-neutral-600')}
        >
          <span className={clsx('flex flex-1 justify-center items-center text-xs')}>
            {format(day, 'EEE', { locale }).slice(0, 2)}
          </span>
          <span
            className={clsx(
              'flex justify-center items-center w-8 h-8 rounded-full',
              isSameDay(day, selectedDate) && 'bg-orange-100',
            )}
          >
            {day.getDate()}
          </span>
        </button>
      ))}
    </div>
  );
};

export default CalendarWeekDaysHeader;
