import clsx from 'clsx';
import { ChangeEvent, ChangeEventHandler, FC, ReactNode, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from 'src/components/Button';

export type ImportCardProps = {
  className?: string;
  title: ReactNode;
  description: ReactNode;
  badge?: ReactNode;
  handleDownloadTemplate?: () => void;
  handleImport?: ChangeEventHandler<HTMLInputElement>;
};

const ImportCard: FC<ImportCardProps> = ({
  className,
  title,
  handleDownloadTemplate,
  handleImport,
  description,
  badge,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    handleImport?.(e);
    if (!inputRef.current) return;
    inputRef.current.value = '';
  };

  return (
    <li
      className={clsx(
        className,
        'flex w-full flex-col overflow-hidden rounded-lg border border-b border-gray-200 bg-white',
      )}
    >
      <div className='flex items-center justify-between gap-x-4 border-b border-gray-200 px-5 py-3'>
        <div className='font-semibold'>{title}</div>
        {badge}
      </div>
      <div className='p-6 flex-grow border-b border-gray-200'>
        <div className='text-sm text-gray-900'>{description}</div>
      </div>
      <div className='flex px-5 py-3 gap-2  md:flex-row'>
        <Button size='small' variant='secondary' className='w-full' onClick={handleDownloadTemplate}>
          <FormattedMessage id='app.imports_page.cards.download_template' />
        </Button>
        <Button size='small' variant='primary' className='w-full' onClick={() => inputRef.current?.click()}>
          <FormattedMessage id='app.imports_page.cards.upload' />
        </Button>
        <input type='file' className='hidden' ref={inputRef} onChange={handleChange} />
      </div>
    </li>
  );
};

export default ImportCard;
