import { DocumentDuplicateIcon, PlusCircleIcon, TrashIcon } from '@heroicons/react/24/outline';
import { FC, ReactNode, useMemo, useRef, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import Button from 'src/components/Button';
import PageContentHeader from 'src/components/PageContentHeader';
import Autocomplete from 'src/components/fields/Autocomplete';

export type CustomTranslationsListProps = {
  translations: Record<string, string>;
  sourceTranslations: Record<string, string>;
  onSubmit: (data: CustomTranslationsFormValues) => void;
  title: ReactNode;
};

export type CustomTranslationItem = {
  key: string;
  value: string;
};

export type CustomTranslationsFormValues = {
  translations: CustomTranslationItem[];
};

const CustomTranslationsList: FC<CustomTranslationsListProps> = ({
  translations,
  sourceTranslations,
  onSubmit,
  title,
}) => {
  const navigate = useNavigate();
  const form = useForm<CustomTranslationsFormValues>({
    defaultValues: {
      translations: Object.entries(translations).map(([key, value]) => ({ key, value })),
    },
  });
  const formRef = useRef<HTMLFormElement>(null);
  const { control, handleSubmit, register, setValue } = form;
  const { fields, remove, append } = useFieldArray({ control, name: 'translations' });
  const [searchValue, setSearchValue] = useState<string | null>(null);

  const translationsOptions = useMemo(() => Object.entries(sourceTranslations), [sourceTranslations]);
  const handleAdd = (): void => {
    if (!searchValue) return;
    append({ key: searchValue, value: '' });
    setSearchValue(null);
  };

  return (
    <form ref={formRef} className='flex flex-col w-full gap-y-8' onSubmit={handleSubmit(onSubmit, console.error)}>
      <PageContentHeader
        title={title}
        primaryButtonText={<FormattedMessage id='app.buttons.save' />}
        primaryButtonType='submit'
        secondaryButtonText={<FormattedMessage id='app.buttons.back' />}
        onSecondaryButtonClick={() => navigate(-1)}
      />
      <div className='flex gap-4'>
        <Autocomplete
          value={searchValue}
          onChange={setSearchValue}
          options={translationsOptions}
          getOptionLabel={([key, value]) => `${key} - ${value}`}
          getOptionValue={([key]) => key}
        />
        <Button onClick={handleAdd} disabled={!searchValue} variant='cta' size='small'>
          <PlusCircleIcon className='w-8 h-8' />
        </Button>
      </div>
      {fields.map((field, index) => (
        <fieldset key={field.id} className='grid grid-cols-[1fr,2fr] gap-4'>
          <div className='flex items-center gap-2'>
            <span className='text-ellipsis'>{field.key}</span>
            <Button
              variant='cta'
              size='small'
              onClick={() => setValue(`translations.${index}.value`, sourceTranslations[field.key])}
            >
              <DocumentDuplicateIcon className='h-6 ml-1' />
            </Button>
          </div>
          <div className='flex gap-4'>
            <input
              {...register(`translations.${index}.value`)}
              className={
                'w-full  border border-gray-300 rounded font-openSans font-normal text-base px-2 h-10 bg-white'
              }
            />
            <Button onClick={() => remove(index)} variant='cta' size='small'>
              <TrashIcon className='w-8 h-8' />
            </Button>
          </div>
        </fieldset>
      ))}
    </form>
  );
};
export default CustomTranslationsList;
