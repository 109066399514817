import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { CreateRoutineServicesRequest, useApiClient } from 'src/api';
import queryKeysFactory from 'src/store/queryKeysFactory';

export type UseDeleteRoutineServiceData = {
  id: number;
};

const useDeleteRoutineService = (): UseMutationResult<
  CreateRoutineServicesRequest,
  unknown,
  UseDeleteRoutineServiceData
> => {
  const queryClient = useQueryClient();
  const client = useApiClient();

  return useMutation(async (body) => client.routineServices.deleteRoutineServices({ id: body.id }), {
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(queryKeysFactory.routineServices.detail(variables.id));
      queryClient.invalidateQueries(queryKeysFactory.routineServices.list.queryKey);
      toast(<FormattedMessage id='app.toasts.delete_success' />, {
        type: 'success',
      });
    },
    onError: () => {
      toast(<FormattedMessage id='app.toasts.delete_fail' />, {
        type: 'error',
      });
    },
  });
};
export default useDeleteRoutineService;
