import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { UserProfileResponse } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

type Options = {
  enabled?: boolean;
};

const useUserProfile = (options?: Options): UseQueryResult<UserProfileResponse> => {
  const client = useApiClient();
  const { queryKey } = queryKeysFactory.userProfile.detail;

  return useQuery(
    queryKey,
    async () => {
      const data = await client.userProfile.getUserProfile();
      return data;
    },
    {
      refetchInterval: false,
      staleTime: 1000 * 60 * 60,
      ...options,
    },
  );
};

export default useUserProfile;
