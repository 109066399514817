import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import useWeekContributions from 'src/api/hooks/queries/useWeekContributions';
import ContributionGraph from 'src/components/ContributionGraph/ContributionGraph';
import PageCard from 'src/components/PageCard';
import Loader from 'src/components/utils/Loader';
import { twMerge } from 'tailwind-merge';

export type TechniciansContributionGraphProps = {
  className?: string;
  dayOfTheWeek: Date;
};

const TechniciansContributionGraph: FC<TechniciansContributionGraphProps> = ({ className, dayOfTheWeek }) => {
  const { data, isLoading } = useWeekContributions(dayOfTheWeek);

  if (isLoading) return <Loader />;
  if (!data || data.length === 0) return null;

  return (
    <PageCard className={twMerge('w-max flex flex-col gap-y-4', className)}>
      <span className='font-medium text-lg'>
        <FormattedMessage id='app.contribution.orders.title' />
      </span>
      <ContributionGraph contributors={data} dayOfTheWeek={dayOfTheWeek} />
    </PageCard>
  );
};

export default TechniciansContributionGraph;
