import { FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

export type WeekRowProps = {
  className?: string;
  week: Date[];
  onClick?: () => void;
  children: ReactNode;
};

const WeekRow: FC<WeekRowProps> = ({ onClick, className, children }) => {
  return (
    <div onClick={onClick} className={twMerge('grid grid-cols-7 border border-transparent rounded', className)}>
      {children}
    </div>
  );
};

export default WeekRow;
