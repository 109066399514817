import { FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

export type AvatarProps = {
  className?: string;
  children?: ReactNode;
  style?: React.CSSProperties;
};

const Avatar: FC<AvatarProps> = ({ className, children, style }) => {
  return (
    <div className={twMerge('flex items-center justify-center w-8 h-8 min-w-8 min-h-8', className)} style={style}>
      {children}
    </div>
  );
};

export default Avatar;
