import { concat, filter, keys, map, pipe, reject, toPairs } from 'ramda';
import { FC, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import PageContentHeader from 'src/components/PageContentHeader';
import useDebounce from 'src/hooks/useDebounce';
import { MessageId } from 'src/types/commonTypes';
import { match, sortTranslationItems } from '../../helpers';
import ImportExportButtons from '../ImportExportButtons';
import TranslationSearchBox from '../TranslationSearchBox';
import TranslationsList from '../TranslationsList';
import PageCard from 'src/components/PageCard';

export type TranslationsFormSumbitOptions = {
  addedTranslations: string[];
  deletedTranslations: string[];
};

export type TranslationsFormProps = {
  language: string;
  translations: Record<string, string>;
  defaultTranslations: Record<string, string>;
  onSubmit: (values: Record<string, string>, options: TranslationsFormSumbitOptions) => void;
};

const TranslationsForm: FC<TranslationsFormProps> = ({ language, translations, defaultTranslations, onSubmit }) => {
  const navigate = useNavigate();

  const mergedTranslations = useMemo(() => translations, [translations, defaultTranslations]);
  const form = useForm({
    defaultValues: mergedTranslations,
  });
  const { getValues, handleSubmit } = form;
  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedSearchValue = useDebounce(searchValue, 100);

  const [addedTranslations, setAddedTranslations] = useState<string[]>([]);
  const [deletedTranslations, setDeletedTranslations] = useState<string[]>([]);

  const handleDeleteTranslationFactory = (id: string) => () => {
    if (addedTranslations.includes(id))
      return setAddedTranslations(addedTranslations.filter((addedId) => addedId !== id));
    setDeletedTranslations((prev) => [...prev, id]);
  };

  const handleRestoreTranslationFactory = (id: string) => () =>
    setDeletedTranslations(reject((deletedId) => deletedId === id, deletedTranslations));

  const translationItems = useMemo(
    () =>
      pipe(
        toPairs,
        map(([id, value]: [string, string]) => ({
          id,
          value,
          isNew: false,
          isMissing: !translations[id],
          isMatch:
            !debouncedSearchValue || match(id, debouncedSearchValue) || match(getValues(id), debouncedSearchValue),
          isDeleted: deletedTranslations.includes(id),
        })),
        concat(
          map(
            (id) => ({
              id,
              value: getValues(id),
              isNew: true,
              isMissing: true,
              isMatch:
                !debouncedSearchValue || match(id, debouncedSearchValue) || match(getValues(id), debouncedSearchValue),
              isDeleted: false,
            }),
            addedTranslations,
          ),
        ),
        sortTranslationItems,
      )(mergedTranslations ?? {}),
    [mergedTranslations, debouncedSearchValue, deletedTranslations, addedTranslations],
  );

  const handleAddMissingTranslations = (): void => {
    const defaultTranslationsIds = keys(defaultTranslations);
    const translationsItemsIds = map(({ id }) => id, translationItems);
    const missingTranslations = reject((id: string) => translationsItemsIds.includes(id), defaultTranslationsIds);

    if (!missingTranslations.length) {
      toast(<FormattedMessage id='app.toasts.no_missing_translation' />, {
        type: 'info',
      });

      return;
    }

    setAddedTranslations((prev) => [...prev, ...missingTranslations]);
    setDeletedTranslations([]);
  };

  useEffect(() => {
    const translationKeys = keys(translations);

    setDeletedTranslations((prev) => filter((id: string) => translationKeys.includes(id), prev));
    setAddedTranslations((prev) => reject((id: string) => translationKeys.includes(id), prev));
  }, [translations]);

  return (
    <FormProvider {...form}>
      <PageContentHeader
        mobilePrimaryButtonType='new'
        className='mb-16'
        title={
          <FormattedMessage
            id='app.translations_wizzard_page.title'
            values={{
              value: <FormattedMessage id={`app.language.${language}` as MessageId} />,
            }}
          />
        }
        secondaryButtonText={<FormattedMessage id='app.buttons.back' />}
        primaryButtonText={<FormattedMessage id='app.buttons.save' />}
        onSecondaryButtonClick={() => navigate(-1)}
        onPrimaryButtonClick={handleSubmit((data) => onSubmit(data, { addedTranslations, deletedTranslations }))}
      />

      <PageCard className='w-full grid grid-rows-[minmax(3rem,auto)_minmax(0,100%)_minmax(3rem,auto)]'>
        <TranslationSearchBox
          searchValue={searchValue}
          onSearchValueChange={setSearchValue}
          onAddMissingTranslations={handleAddMissingTranslations}
        />
        <TranslationsList
          defaultTranslations={defaultTranslations}
          translationItems={translationItems}
          onRestoreTranslationFactory={handleRestoreTranslationFactory}
          onDeleteTranslationFactory={handleDeleteTranslationFactory}
        />
        <ImportExportButtons language={language} setAddedTranslations={setAddedTranslations} />
      </PageCard>
    </FormProvider>
  );
};

export default TranslationsForm;
