import { FC, useCallback, useMemo } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { Navigate } from 'react-router-dom';
import { TenantWithLicencesResponse } from 'src/api';
import useUpdateTenant from 'src/api/hooks/mutations/tenant/useUpdateTenant';
import useTenant from 'src/api/hooks/queries/useTenant';
import { usePopupContext } from 'src/components/Popup/hook';
import Loader from 'src/components/utils/Loader';
import WithLoader from 'src/components/utils/WithLoader';
import TenantForm from '../TenantForm';
import { TenantSchema } from '../TenantForm/schema';

export type UpdateTenantFormProps = {
  className?: string;
  tenantId: number;
};

const mapDataToDefaultValues = (data: TenantWithLicencesResponse): TenantSchema => ({
  ...data,
  tenantDomain: data.tenantDomain?.tenantDomain ?? '',
});

const UpdateTenantForm: FC<UpdateTenantFormProps> = ({ className, tenantId }) => {
  const { onClose } = usePopupContext();
  const { isLoading, data } = useTenant(tenantId);
  const updateTenant = useUpdateTenant();
  const defaultValues = useMemo(() => (data ? mapDataToDefaultValues(data) : undefined), [data]);

  const handleSubmit = useCallback<SubmitHandler<TenantSchema>>(
    (data) => {
      updateTenant.mutate({ ...data, id: tenantId });
      onClose();
    },
    [onClose],
  );

  if (isLoading) return <Loader />;
  if (!data) return <Navigate to='/404' />;

  return (
    <WithLoader isLoading={updateTenant.isLoading}>
      <TenantForm onSubmit={handleSubmit} defaultValues={defaultValues} className={className} />
    </WithLoader>
  );
};

UpdateTenantForm.displayName = 'UpdateTenantForm';

export default UpdateTenantForm;
