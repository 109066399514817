import { createContext } from 'react';
import { EventGridContextValue } from '../types';

const defaultContextValue: EventGridContextValue = {
  gridRef: { current: null },
  scrollContainerRef: { current: null },
  eventToGrid: () => {
    throw new Error('eventToGrid not implemented');
  },
  gridToTimeSpan: () => {
    throw new Error('gridToTimeSpan not implemented');
  },
  getGridCellDimensions: () => {
    throw new Error('getGridCellDimensions not implemented');
  },
  gridSettings: {
    ROWS: 0,
    COLS: 0,
    START_HOUR: 0,
    END_HOUR: 0,
    TOTAL_SEPARATORS: 0,
    START_MINUTE: 0,
    END_MINUTE: 0,
  },
};

export const EventGridContext = createContext<EventGridContextValue>(defaultContextValue);
