import { SortingState } from '@tanstack/react-table';
import { SortingQuery, TenantSortingOrderBy } from 'src/api/types/shared/sorting';

const defaultSortingTransform = (sorting: SortingState): SortingQuery => {
  if (!sorting.length) return {};

  const { id, desc } = sorting[0];

  return {
    orderBy: id as TenantSortingOrderBy,
    direction: desc ? 'DESC' : 'ASC',
  };
};

export default defaultSortingTransform;
