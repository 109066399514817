import { getDate, isEqual, setDate } from 'date-fns';
import { UserResponse } from 'src/api';
import useMoveTechnicianEvent from 'src/hooks/useMoveTechnicianEvent';
import { findById } from '../helpers/state';
import { GridEvent, HandleDropFunc } from '../types';
import useMoveTechnicianEventToUnassigned from 'src/hooks/useMoveTechnicianEventToUnassigned';
import { UNASSIGNED_ORDERS_ROW } from '../constants';
import useEvent from 'src/hooks/useEvent';

const useHandleDropMTDFactory = (
  events: GridEvent[],
  technicians: UserResponse[],
): ((date: Date) => HandleDropFunc) => {
  const moveEvent = useMoveTechnicianEvent(events);
  const unassignEvent = useMoveTechnicianEventToUnassigned(events);

  return useEvent<(date: Date) => HandleDropFunc>((date: Date) => (item, monitor, context, result) => {
    const event = findById(item.id, events);
    if (!event) return;

    const { row } = result;

    const { gridToTimeSpan } = context;

    const { start, end } = event;
    const { start: newStart } = gridToTimeSpan({ ...result, start, end });
    const newStartDate = setDate(newStart, getDate(date));

    if (row === UNASSIGNED_ORDERS_ROW) {
      if (isEqual(start, newStart) && event.data.technicians.length === 0) return;
      unassignEvent(item.id, newStartDate, item.technicianId);
      return;
    }

    const newTechnicianId = technicians[row - 2].id;
    if (isEqual(start, newStart) && event.data.technicians.map((i) => i.id).includes(newTechnicianId)) return;

    moveEvent(item.id, newStartDate, item.technicianId, newTechnicianId);
  });
};

export default useHandleDropMTDFactory;
