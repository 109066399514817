import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { formatISO, startOfDay } from 'date-fns';
import { OrderListItemResponse } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

const useAllTechniciansDayOrders = (date: Date): UseQueryResult<OrderListItemResponse[]> => {
  const client = useApiClient();
  const start = formatISO(startOfDay(date));
  const { queryKey } = queryKeysFactory.allTechniciansDayOrders.list(start);

  return useQuery(queryKey, async () => {
    const data = await client.calendar.getTechniciansDayOrders({ date: start });
    return data;
  });
};

export default useAllTechniciansDayOrders;
