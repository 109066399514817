import { FC } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import useHeadquarters from 'src/api/hooks/queries/useHeadquarters';
import Loader from 'src/components/utils/Loader';
import Popup from 'src/components/Popup';
import { FormattedMessage } from 'react-intl';
import UpdateHeadquartersForm from '../UpdateHeadquartersForm';

export type UpdateHeadquartersPopupProps = {
  open: boolean;
  onClose: () => void;
};

const UpdateHeadquartersPopup: FC<UpdateHeadquartersPopupProps> = ({ open, onClose }) => {
  const headquartersId = Number(useParams()?.headquartersId);
  const { data: headquarters, isLoading } = useHeadquarters(headquartersId);

  if (isLoading) return <Loader />;
  if (!headquarters) return <Navigate to='/500' />;

  return (
    <Popup
      open={open && !isLoading}
      onClose={onClose}
      className='w-[954px]'
      title={<FormattedMessage id='app.order_detail_page.update_order.popup_title' />}
    >
      <UpdateHeadquartersForm headquartersId={headquartersId} headquarters={headquarters} />
    </Popup>
  );
};

UpdateHeadquartersPopup.displayName = 'UpdateHeadquartersPopup';

export default UpdateHeadquartersPopup;
