import { FC } from 'react';
import { WorkTariffResponse } from 'src/api';
import Table, { TableProps } from 'src/components/Table';
import { useNavigate } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';
import { FormattedMessage } from 'react-intl';
import TextFilter from 'src/components/Table/filters/TextFilter';
import { ExtendedColumnDef } from 'src/types/table';

export type WorkTariffsTableProps = Omit<TableProps<WorkTariffResponse>, 'columns'>;

const columnHelper = createColumnHelper<WorkTariffResponse>();

const columns: ExtendedColumnDef<WorkTariffResponse>[] = [
  {
    ...columnHelper.accessor('name', {
      id: 'name',
      header: () => <FormattedMessage id='app.delivery_tariff.name' />,
      enableColumnFilter: true,
      enableSorting: true,
      cell: (info) => info.renderValue(),
      size: 250,
    }),
    renderFilter: (column) => <TextFilter column={column} />,
  },
  {
    ...columnHelper.accessor('price', {
      id: 'price',
      header: () => <FormattedMessage id='app.delivery_tariff.price' />,
      enableColumnFilter: false,
      enableSorting: true,
      cell: (info) => info.renderValue(),
      size: 200,
    }),
  },
  {
    ...columnHelper.accessor('priceEur', {
      id: 'priceEur',
      header: () => <FormattedMessage id='app.common.price_eur' />,
      enableColumnFilter: false,
      enableSorting: true,
      cell: (info) => info.renderValue(),
      size: 200,
    }),
  },
  {
    ...columnHelper.accessor('deletedAt', {
      id: 'deletedAt',
      header: () => <FormattedMessage id='app.common.active' />,
      enableColumnFilter: false,
      enableSorting: false,
      cell: (info) =>
        info.getValue() ? <FormattedMessage id='app.common.no' /> : <FormattedMessage id='app.common.yes' />,
      size: 100,
    }),
  },
];

const WorkTariffsTable: FC<WorkTariffsTableProps> = (props) => {
  const navigate = useNavigate();

  return (
    <Table columns={columns} {...props} handleRowClick={(original) => navigate(`/admin/work-tariffs/${original.id}`)} />
  );
};

WorkTariffsTable.displayName = 'WorkTariffsTable';

export default WorkTariffsTable;
