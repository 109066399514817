import { FC, HTMLAttributes } from 'react';
import clsx from 'clsx';
import { Navigate } from 'react-router-dom';
import Loader from 'src/components/utils/Loader';
import useContactBranch from 'src/api/hooks/queries/useContactBranch';
import BranchPreview from '../BranchPreview';
import HyperLink from 'src/components/common/HyperLink';
import { FormattedMessage } from 'react-intl';
import { ArrowLongRightIcon } from '@heroicons/react/24/outline';

export type BranchListItemDetailProps = {
  contactId: number;
  branchId: number;
} & HTMLAttributes<HTMLDivElement>;

const BranchListItemDetail: FC<BranchListItemDetailProps> = ({ className, contactId, branchId }) => {
  const { data, isFetching } = useContactBranch(branchId);

  if (isFetching) return <Loader />;
  if (!data) return <Navigate to='/404' />;

  return (
    <div className={clsx('flex flex-col', className)}>
      <BranchPreview noTitle data={data} />
      <HyperLink className='flex self-end mt-4 font-semibold' href={`/contact/${contactId}/branch/${branchId}`}>
        <FormattedMessage id='app.button.detail' />
        <ArrowLongRightIcon className='block ml-2 h-6' aria-hidden='true' />
      </HyperLink>
    </div>
  );
};

BranchListItemDetail.displayName = 'BranchListItemDetail';

export default BranchListItemDetail;
