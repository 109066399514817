import { z } from 'zod';

export const NotificationSettingsSchema = z.object({
  text: z.string().min(1),
  title: z.string().nullable(),
  targets: z.string().nullable(),
  daysBeforeEvent: z.number().min(1).max(14),
  time: z.string(),
  defaultEnabled: z.boolean(),
});

export type NotificationSettingsSchema = z.infer<typeof NotificationSettingsSchema>;
