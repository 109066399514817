import { FC, HTMLAttributes } from 'react';
import { Link } from 'react-router-dom';
import Logo from 'src/components/common/Logo';
import PageContentHeader from 'src/components/PageContentHeader';
import FinishOrderFormDataProvider from './components/FinishOrderFormDataProvider';

export type TechnicianOrderFinishPageProps = {
  //
} & HTMLAttributes<HTMLDivElement>;

const TechnicianOrderFinishPage: FC<TechnicianOrderFinishPageProps> = () => {
  return (
    <>
      <PageContentHeader
        className='mb-20'
        title={
          <Link to='/'>
            <Logo className='h-20' />
          </Link>
        }
        noLine
        hidePrimaryButton
        hideSecondaryButton
      />
      <FinishOrderFormDataProvider />
    </>
  );
};

TechnicianOrderFinishPage.displayName = 'TechnicianOrderFinishPage';

export default TechnicianOrderFinishPage;
