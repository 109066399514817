import { Column } from '@tanstack/react-table';
import { useCallback, useState } from 'react';
import DatePicker from 'src/components/fields/DatePicker';

export type DateFilterProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  column: Column<any, unknown>;
};

const DateFilter: React.FC<DateFilterProps> = ({ column }) => {
  const { getFilterValue, setFilterValue } = column;
  const [value, setValue] = useState<Date | null>(getFilterValue() as Date | null);

  const handleChange = useCallback((date: Date | null) => {
    setValue(date);
    setFilterValue(date);
  }, []);

  return (
    <div className='w-40'>
      <DatePicker className='max-w-full text-sm' value={value} onChange={handleChange} />
    </div>
  );
};

export default DateFilter;
