import { Updater } from '@tanstack/react-table';
import { CALENDAR_SINGLE_TECHNICIAN_ID_PARAM_KEY } from '../constants';
import useNumberSearchParam from 'src/hooks/router/useNumberSearchParam';

const useCalendarSingleTechnicianIdParam = ({ defaultValue }: { defaultValue?: number } = {}): readonly [
  number | null,
  (value: Updater<number | null>) => void,
] => useNumberSearchParam(CALENDAR_SINGLE_TECHNICIAN_ID_PARAM_KEY, { defaultValue });

export default useCalendarSingleTechnicianIdParam;
