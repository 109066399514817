import { useCallback, useContext } from 'react';
import { AutoPopupsContext } from 'src/store/autoPopupsContext';

const useOpenPopup = (id: string): ((props?: unknown) => void) => {
  const [, setValue] = useContext(AutoPopupsContext);
  const handleOpen = useCallback(
    (props?: unknown) => setValue((prev) => ({ ...prev, [id]: { open: true, props } })),
    [setValue],
  );

  return handleOpen;
};

export default useOpenPopup;
