import { createColumnHelper } from '@tanstack/react-table';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PaymentMethodResponse } from 'src/api';
import PaymentMethodLabel from 'src/components/PaymentMethodLabel';
import Table, { TableProps } from 'src/components/Table';
import TextFilter from 'src/components/Table/filters/TextFilter';
import { ExtendedColumnDef } from 'src/types/table';

export type PaymentMethodProps = Omit<TableProps<PaymentMethodResponse>, 'columns'>;

const columnHelper = createColumnHelper<PaymentMethodResponse>();

const columns: ExtendedColumnDef<PaymentMethodResponse>[] = [
  {
    ...columnHelper.accessor('name', {
      id: 'imp_name',
      header: () => <FormattedMessage id='app.payment_method.name' />,
      enableColumnFilter: true,
      enableSorting: true,
      cell: (info) => <PaymentMethodLabel name={info.getValue()} />,
      size: 350,
    }),
    renderFilter: (column) => <TextFilter column={column} />,
  },
];

const PaymentMethodTable: React.FC<PaymentMethodProps> = (props) => {
  return <Table columns={columns} {...props} />;
};

export default PaymentMethodTable;
