import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { formatISO } from 'date-fns';
import { OrderListItemResponse } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

const useAllTechniciansWeekOrders = (dayOfTheWeek: Date): UseQueryResult<OrderListItemResponse[]> => {
  const client = useApiClient();
  const { queryKey } = queryKeysFactory.allTechniciansWeekOrders.list(dayOfTheWeek);

  return useQuery(queryKey, async () => {
    const data = await client.dashboard.getAllWeekOrders({
      requestBody: { dayOfTheWeek: formatISO(dayOfTheWeek) },
    });
    return data;
  });
};

export default useAllTechniciansWeekOrders;
