import { Transition } from '@headlessui/react';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Navigate, Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import { useContacts } from 'src/api/hooks/queries/useContacts';
import PageContentHeader from 'src/components/PageContentHeader';
import Popup from 'src/components/Popup';
import SearchBlock from 'src/components/SearchBlock';
import useTableState from 'src/components/Table/hooks/useTableState';
import Loader from 'src/components/utils/Loader';
import { standartTransitionProps } from 'src/constants/transitions';
import useGetIsModuleValid from 'src/hooks/useGetIsModuleValid';
import useIsCurrentUserAllowedToEdit from 'src/hooks/useIsCurrentUserAllowedToEdit';
import useLastDefined from 'src/hooks/useLastDefined';
import usePageBaseState from 'src/hooks/usePageBaseState';
import { Modules } from 'src/types/commonTypes';
import { ContactFilter } from 'src/types/filters';
import stub from 'src/utils/stub';
import ContactDetailPage from '../ContactDetailPage';
import BranchDetail from '../ContactDetailPage/BranchDetail';
import BranchDetailHeader from '../ContactDetailPage/BranchDetailHeader';
import ContactDetailHeader from '../ContactDetailPage/ContactDetailHeader';
import DeleteBranchPopup from '../ContactDetailPage/DeleteBranchPopup';
import DeleteEmployeePopup from '../ContactDetailPage/DeleteEmployeePopup';
import EmployeeDetail from '../ContactDetailPage/EmployeeDetail';
import EmployeeDetailHeader from '../ContactDetailPage/EmployeeDetailHeader';
import ProductDetailHeader from '../ContactDetailPage/ProductDetailHeader';
import UpdateEmployeePopup from '../ContactDetailPage/UpdateEmployeePopup';
import AddContactForm from './AddContactForm';
import ContactTable from './ContactTable';
import UpdateBranchPopup from './UpdateBranchPopup';
import UpdateContactPopup from './UpdateContactPopup';
import useCurrentTenantConfig from 'src/api/hooks/queries/useCurrentTenantConfig';
import PageCard from 'src/components/PageCard';

const defaultSortingState = [{ id: 'company_name', desc: false }];

const defaultColumnFiltersState = [
  {
    id: 'filterString',
    value: '',
  },
];

const ContactPage: React.FC = () => {
  const getIsModuleValid = useGetIsModuleValid();
  const isCurrentUserAllowedToEdit = useIsCurrentUserAllowedToEdit();
  const { data: currentTenantConfig, isLoading } = useCurrentTenantConfig();

  const navigate = useNavigate();

  const { isPopupOpen, handleClosePopup, handleOpenPopup } = usePageBaseState();

  const {
    columnFilters,
    setColumnFilters,
    sorting,
    setSorting,
    pagination,
    setPagination,
    debouncedServerFriendlyFilters,
    sortingQuery,
  } = useTableState<ContactFilter>({
    defaultColumnFiltersState,
    defaultSortingState,
  });

  const [searchValue, setSearchValue] = useState('');

  const handleSearch = (): void => {
    navigate('/contact');
    setColumnFilters([{ id: 'filterString', value: searchValue }]);
  };

  const { data, isFetching } = useContacts(
    { ...debouncedServerFriendlyFilters, withDeleted: true },
    sortingQuery,
    pagination,
  );
  const definedData = useLastDefined(data);

  if (isLoading) return <Loader variant='global' />;

  return (
    <>
      {!getIsModuleValid(Modules.CONTACTS) && <Navigate to='/contact' />}
      <Routes>
        <Route
          path='/'
          element={
            <PageContentHeader
              mobilePrimaryButtonType='new'
              title={<FormattedMessage id='app.contact_page.title' />}
              onPrimaryButtonClick={handleOpenPopup}
              primaryButtonText={
                <FormattedMessage id='app.contact_page.new_contact_button' defaultMessage='Nový kontakt' />
              }
              hideSecondaryButton
              hidePrimaryButton={!isCurrentUserAllowedToEdit}
            />
          }
        />
        <Route path='/:contactId' element={<ContactDetailHeader />} />
        <Route path='/:contactId/branch/:branchId' element={<BranchDetailHeader />} />
        <Route path='/:contactId/employee/:employeeId' element={<EmployeeDetailHeader />} />
        <Route path='/:contactId/branch/:branchId/product/:productId' element={<ProductDetailHeader />} />
      </Routes>

      <PageCard className='mt-12'>
        <SearchBlock
          searchValue={searchValue}
          handleSearch={handleSearch}
          setSearchValue={setSearchValue}
          description={
            <FormattedMessage
              id='app.contact_page.search.helper_text'
              defaultMessage='Zadejte název, IČO, nebo město'
            />
          }
          // helperText={helperText}
          className='mb-20'
        />
        <Routes>
          <Route
            path='/'
            element={
              <>
                <Transition show={!!definedData} {...standartTransitionProps}>
                  <ContactTable
                    data={definedData?.items ?? []}
                    isFilterOpen={false}
                    isLoading={false}
                    columnFilters={columnFilters}
                    sorting={sorting}
                    pagination={pagination}
                    handleSort={setSorting}
                    handleFilter={stub}
                    paginationMeta={definedData?.meta}
                    handlePaginationChange={setPagination}
                  />
                </Transition>
                {isFetching && <Loader variant='global' />}
              </>
            }
          />

          <Route path='/:contactId' element={<ContactDetailPage />} />
          <Route path='/:contactId/branch/:branchId' element={<BranchDetail />} />
          <Route path='/:contactId/employee/:employeeId' element={<EmployeeDetail />} />
        </Routes>
      </PageCard>

      <Outlet />
      <Routes>
        <Route
          path='/'
          element={
            <Popup
              title={<FormattedMessage id='app.contact_page.popup_title' defaultMessage='Novy kontakt' />}
              open={isPopupOpen}
              onClose={handleClosePopup}
            >
              <AddContactForm defaultValues={currentTenantConfig?.contactForm?.defaultValues} />
            </Popup>
          }
        />
        <Route path='/:contactId' element={<UpdateContactPopup />} />
        <Route
          path='/:contactId/branch/:branchId'
          element={
            <>
              <UpdateBranchPopup />
              <DeleteBranchPopup />
            </>
          }
        />
        <Route
          path='/:contactId/employee/:employeeId'
          element={
            <>
              <UpdateEmployeePopup />
              <DeleteEmployeePopup />
            </>
          }
        />
      </Routes>
    </>
  );
};

export default ContactPage;
