import { useCallback, useContext } from 'react';
import { AutoPopupsContext } from 'src/store/autoPopupsContext';

const useClosePopup = (id: string): (() => void) => {
  const [, setValue] = useContext(AutoPopupsContext);
  const handleClose = useCallback(
    () => setValue((prev) => ({ ...prev, [id]: { ...prev?.[id], open: false } })),
    [setValue],
  );

  return handleClose;
};

export default useClosePopup;
