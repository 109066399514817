import { curry, filter, find, map, propEq, __ } from 'ramda';
import { addMinutes, differenceInMinutes, isEqual, isSameWeek, isWithinInterval, startOfDay } from 'date-fns';
import hasOverlapingIntervals from 'src/utils/hasOverlapingIntervals';
import { GridEvent } from '../types';
import { ItemWithId, WeekOptions } from 'src/types/commonTypes';
import { Interval } from 'date-fns';

export type IdEqualsF<T> = CurriedFn2<T, { id: T }, boolean>;

export const idEquals = propEq(__, 'id') as unknown as IdEqualsF<number | string>;

export const findById = <Tid extends string | number, T extends ItemWithId>(id: Tid, list: T[]): T | undefined =>
  find(idEquals(id), list);

export const replaceEventMap = curry((eventId: number, newEvent: GridEvent, events: GridEvent[]) =>
  map((event) => {
    if (event.id === eventId) return newEvent;
    return event;
  }, events),
);

export const getEventsFromDay = (day: Date, events: GridEvent[]): GridEvent[] =>
  filter((e) => isEqual(startOfDay(day), startOfDay(e.start)), events);

export const getEventsFromWeek = (day: Date, events: GridEvent[], options?: WeekOptions): GridEvent[] =>
  filter((e) => isSameWeek(day, e.start, options), events);

export const getEventsFromInterval = (interval: Interval, events: GridEvent[]): GridEvent[] =>
  filter((e) => isWithinInterval(e.start, interval), events);

export const updateEventsByIds = curry((oldEvents: GridEvent[], changedEvents: GridEvent[]): GridEvent[] =>
  map((oldEvent) => findById(oldEvent.id, changedEvents) ?? oldEvent, oldEvents),
);

export const updateEventStartDate = curry((event: GridEvent, newStartDate: Date): GridEvent => {
  const { start, end } = event;
  const durationInMunutes = differenceInMinutes(end, start, {
    roundingMethod: 'round',
  });
  const newEndDate = addMinutes(newStartDate, durationInMunutes);

  const newEvent = {
    ...event,
    start: newStartDate,
    end: newEndDate,
  };

  return newEvent;
});

export const updateEventStartDateMap = (eventId: number, newStartDate: Date, events: GridEvent[]): GridEvent[] => {
  const event = findById(eventId, events);

  if (!event) return events;

  const newEvent = updateEventStartDate(event, newStartDate);

  return replaceEventMap(eventId, newEvent, events);
};

export const moveEvent = curry((eventId, newStartDate, events) => {
  const updatedEvents = updateEventStartDateMap(eventId, newStartDate, events);

  if (hasOverlapingIntervals(updatedEvents)) return events;
  return updatedEvents;
});
