import { FC } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { FORM_BUILDER_DROPPABLE_TYPES } from '../../constants';
import { FormBuilderFieldValue } from '../../context';
import FormBuilderField from '../FormBuilderField/FormBuilderField';

export type FormBuilderFieldsListProps = {
  id: string;
  fields: FormBuilderFieldValue[];
};

const FormBuilderFieldsList: FC<FormBuilderFieldsListProps> = ({ id, fields }) => {
  return (
    <Droppable type={FORM_BUILDER_DROPPABLE_TYPES.LIST} droppableId={id}>
      {(provided) => (
        <div className='flex flex-col' ref={provided.innerRef} {...provided.droppableProps}>
          {fields.map((field, index) => (
            <FormBuilderField id={field.id} key={field.id} index={index} />
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

FormBuilderFieldsList.displayName = 'FormBuilderFieldsList';

export default FormBuilderFieldsList;
