/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateVatTariffRequest } from '../models/CreateVatTariffRequest';
import type { VatTariffResponse } from '../models/VatTariffResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class VatTariffsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns VatTariffResponse
   * @throws ApiError
   */
  public getAllVatTariffs({
    withDeleted,
  }: {
    /**
     * With deleted
     */
    withDeleted?: boolean;
  }): CancelablePromise<Array<VatTariffResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/vat-tariffs',
      query: {
        withDeleted: withDeleted,
      },
    });
  }
  /**
   * @returns VatTariffResponse
   * @throws ApiError
   */
  public createVatTariff({
    requestBody,
  }: {
    requestBody: CreateVatTariffRequest;
  }): CancelablePromise<VatTariffResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/vat-tariffs',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns VatTariffResponse
   * @throws ApiError
   */
  public getVatTariff({ id }: { id: number }): CancelablePromise<VatTariffResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/vat-tariffs/{id}',
      path: {
        id: id,
      },
    });
  }
  /**
   * @returns VatTariffResponse
   * @throws ApiError
   */
  public updateVatTariff({
    id,
    requestBody,
  }: {
    id: number;
    requestBody: CreateVatTariffRequest;
  }): CancelablePromise<VatTariffResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/vat-tariffs/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns void
   * @throws ApiError
   */
  public deleteVatTariff({ id }: { id: number }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/vat-tariffs/{id}',
      path: {
        id: id,
      },
    });
  }
  /**
   * @returns void
   * @throws ApiError
   */
  public restoreVatTariff({ id }: { id: number }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/vat-tariffs/{id}/restore',
      path: {
        id: id,
      },
    });
  }
}
