import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import AutoPopup from 'src/components/common/AutoPopup';
import AddProductTemplateForm from '../AddProductTemplateForm';

export type AddProductTemplatePopupProps = {
  className?: string;
};

export const ADD_PRODUCT_TEMPLATE_POPUP_ID = 'add_product_template';

const AddProductTemplatePopup: FC<AddProductTemplatePopupProps> = ({ className }) => {
  return (
    <AutoPopup
      id={ADD_PRODUCT_TEMPLATE_POPUP_ID}
      title={<FormattedMessage id='app.devices.new_device.popup_title' />}
      className={className}
    >
      <AddProductTemplateForm />
    </AutoPopup>
  );
};

AddProductTemplatePopup.displayName = 'AddProductTemplatePopup';

export default AddProductTemplatePopup;
