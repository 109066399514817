import { PlusCircleIcon } from '@heroicons/react/24/outline';
import { FC, ReactNode } from 'react';
import Button from 'src/components/Button';
import { twMerge } from 'tailwind-merge';
import SeparatedInput from './SeparatedInput';
import FieldError from '../FieldError';
import FieldLabel from '../FieldLabel';

export type SeparatedTextFieldProps = {
  className?: string;
  disabled?: boolean;
  name: string;
  onChange: (value: string) => void;
  value: string;
  separator: string;
  hideErrorMessage?: boolean;
  labelClassName?: string;
  labelTextClassName?: string;
  error?: string;
  label: ReactNode;
};

const SeparatedTextField: FC<SeparatedTextFieldProps> = ({
  value,
  onChange,
  separator,
  className,
  name,
  label,
  labelClassName,
  labelTextClassName,
  hideErrorMessage,
  error,
  disabled,
}) => {
  const addInput = (): void => {
    onChange(value + separator);
  };

  return (
    <div className={twMerge('flex flex-col w-full gap-y-1', className)}>
      {label && (
        <FieldLabel className={labelClassName} textClassName={labelTextClassName}>
          {label}
          <Button disabled={disabled} variant='cta' size='small' onClick={addInput}>
            <PlusCircleIcon className='h-6 ml-1' />
          </Button>
        </FieldLabel>
      )}
      <div className={twMerge('flex flex-col gap-y-2 w-full')}>
        <SeparatedInput separator={separator} name={name} value={value} onChange={onChange} />
        {!hideErrorMessage && error && <FieldError>{error}</FieldError>}
      </div>
    </div>
  );
};

export default SeparatedTextField;
