import cronTime from 'cron-time-generator';
import { format, setHours, setMinutes, subMinutes } from 'date-fns';
import { range } from 'ramda';
import { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { NotificationResponse } from 'src/api';
import useUpdateNotificationSetting from 'src/api/hooks/mutations/notificationSettings/useUpdateNotificationSetting';
import Button from 'src/components/Button';
import { CheckboxControlled } from 'src/components/fields/Checkbox';
import { DescriptionFieldControlled } from 'src/components/fields/DescriptionField';
import { SelectControlled } from 'src/components/fields/Select';
import Title from 'src/components/Title';
import WithLoader from 'src/components/utils/WithLoader';
import { TIME_SELECT_VALUES } from 'src/pages/OrdersPage/ServiceForm/constants';
import { NotificationSettingsSchema } from './schema';
import { zodResolver } from '@hookform/resolvers/zod';

export type CustomerBeforeServiceSmsFormProps = {
  data: NotificationResponse;
};

const DAYS_SELECT_OPTIONS = range(1, 14);

const timeOptionToCron = (time: string): string => {
  const [hours, minutes] = time.split(':').map(Number);
  const date = setMinutes(setHours(new Date(), hours), minutes);

  return cronTime.everyDayAt(date.getUTCHours(), date.getUTCMinutes());
};

const cronToTimeOption = (cron: string): string => {
  const [munutes, hours] = cron.split(' ');
  const dateUtc = setMinutes(setHours(new Date(), Number(hours)), Number(munutes));
  const date = subMinutes(dateUtc, dateUtc.getTimezoneOffset());

  return format(date, 'HH:mm');
};

const notificationSettingsToFormValues = (data: NotificationResponse): NotificationSettingsSchema => ({
  text: data.text,
  title: null,
  targets: null,
  daysBeforeEvent: data.daysBeforeEvent,
  time: cronToTimeOption(data.cron),
  defaultEnabled: !!data.defaultEnabled,
});

const CustomerBeforeServiceSmsForm: FC<CustomerBeforeServiceSmsFormProps> = ({ data }) => {
  const { control, handleSubmit } = useForm<NotificationSettingsSchema>({
    resolver: zodResolver(NotificationSettingsSchema),
    defaultValues: notificationSettingsToFormValues(data),
  });

  const updateNotificationSettings = useUpdateNotificationSetting();

  const onSubmit: SubmitHandler<NotificationSettingsSchema> = (formData) => {
    const { time, daysBeforeEvent, text, defaultEnabled } = formData;
    const cron = timeOptionToCron(time);

    updateNotificationSettings.mutate({ id: data.id, daysBeforeEvent, text, cron, defaultEnabled });
  };

  return (
    <WithLoader isLoading={updateNotificationSettings.isLoading}>
      <div className='flex flex-col'>
        <Title className='text-2xl mb-8' data-joyride='notifications.customer_sms'>
          <FormattedMessage id='app.notification.customer_before_service' />
        </Title>
        <span className='mb-8'>
          <FormattedMessage id='app.notification.customer_before_service.description' />
        </span>
        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-y-4'>
          <SelectControlled
            label={<FormattedMessage id='app.notification.days_before_event' />}
            name='daysBeforeEvent'
            control={control}
            options={DAYS_SELECT_OPTIONS}
            getOptionLabel={(option) => option.toString()}
            getOptionValue={(option) => option}
          />
          <SelectControlled
            name='time'
            control={control}
            label={<FormattedMessage id='app.common.time' />}
            options={TIME_SELECT_VALUES}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
          />
          <DescriptionFieldControlled
            name='text'
            control={control}
            label={<FormattedMessage id='app.notification.text' />}
          />
          <CheckboxControlled
            name='defaultEnabled'
            control={control}
            label={<FormattedMessage id='app.notification.default_enabled' />}
          />
          <Button type='submit'>
            <FormattedMessage id='app.buttons.save' />
          </Button>
        </form>
      </div>
    </WithLoader>
  );
};

export default CustomerBeforeServiceSmsForm;
