import { FC, HTMLAttributes } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import AutoPopup from 'src/components/common/AutoPopup';
import { FormattedMessage } from 'react-intl';
import UpdateBranchForm from 'src/pages/ContactDetailPage/UpdateBranchForm';

export type UpdateBranchPopupProps = {
  //
} & HTMLAttributes<HTMLDivElement>;

const UpdateBranchPopup: FC<UpdateBranchPopupProps> = ({ className }) => {
  const contactId = Number(useParams().contactId);
  const branchId = Number(useParams().branchId);

  if (!contactId || !branchId) return <Navigate to='/404' />;

  return (
    <AutoPopup
      id='update_branch'
      title={<FormattedMessage id='app.contact_page.update_contact.popup_title' defaultMessage='Editace pobocky' />}
      className={className}
    >
      <UpdateBranchForm contactId={contactId} branchId={branchId} />
    </AutoPopup>
  );
};

UpdateBranchPopup.displayName = 'UpdateBranchPopup';

export default UpdateBranchPopup;
