import clsx from 'clsx';
import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import useUpdateCurrentTenantConfigField from 'src/api/hooks/mutations/tenant/useUpdateCurrentTenantConfigField';
import useAllHeadquarters from 'src/api/hooks/queries/useAllHeadquarters';
import useAllOrderTypes from 'src/api/hooks/queries/useAllOrderTypes';
import useAllVatTariffs from 'src/api/hooks/queries/useAllVatTariffs';
import useCurrentTenant from 'src/api/hooks/queries/useCurrentTenant';
import ToggleB2Cb2B from 'src/components/ToggleB2Cb2B';
import ParametersRow from 'src/components/common/ParametersRow';
import Select from 'src/components/fields/Select';
import Loader from 'src/components/utils/Loader';
import { EMAIL_TARGET_OPTIONS } from 'src/constants/emailTargets';

export type TenantOrderDefaultValuesFormProps = {
  className?: string;
};

const TenantOrderDefaultValuesForm: FC<TenantOrderDefaultValuesFormProps> = ({ className }) => {
  const intl = useIntl();
  const updateConfigFieldMutation = useUpdateCurrentTenantConfigField();

  const { data: currentTenant, isLoading: isLoadingCurrentTenant } = useCurrentTenant();
  const { data: headquarters, isLoading: isLoadingHeadquarters } = useAllHeadquarters();
  const { data: orderTypes, isLoading: isLoadingOrderTypes } = useAllOrderTypes();
  const { data: vatTariffs, isLoading: isLoadingVatTariffs } = useAllVatTariffs();

  const isLoading = isLoadingCurrentTenant || isLoadingHeadquarters || isLoadingOrderTypes || isLoadingVatTariffs;

  if (isLoading) return <Loader />;

  const config = currentTenant?.tenantConfig?.config ?? {};

  return (
    <span className={clsx('text-2xl mb-4', className)}>
      <ParametersRow.Title>
        <FormattedMessage id='app.settings.order_default_values.title' />
      </ParametersRow.Title>
      <ParametersRow className='h-18'>
        <ParametersRow.Label>
          <FormattedMessage id='app.order.headquarters' />
        </ParametersRow.Label>
        <ParametersRow.Value className='w-full max-w-1/2 min-w-1/2'>
          <Select
            name='orderForm.defaultValues.headquartersId'
            value={config.orderForm?.defaultValues?.headquartersId}
            onChange={(value) => {
              updateConfigFieldMutation.mutate({
                path: 'orderForm.defaultValues.headquartersId',
                value,
              });
            }}
            options={headquarters ?? []}
            getOptionValue={(i) => i.id}
            getOptionLabel={(i) => `${i.companyName} ${i.companyStreet} ${i.companyCity}`}
          />
        </ParametersRow.Value>
      </ParametersRow>
      <ParametersRow className='h-18'>
        <ParametersRow.Label>
          <FormattedMessage id='app.order.order_type' />
        </ParametersRow.Label>
        <ParametersRow.Value className='w-full max-w-1/2 min-w-1/2'>
          <Select
            name='orderForm.defaultValues.orderTypeId'
            value={config.orderForm?.defaultValues?.orderTypeId}
            onChange={(value) => {
              updateConfigFieldMutation.mutate({
                path: 'orderForm.defaultValues.orderTypeId',
                value,
              });
            }}
            options={orderTypes ?? []}
            getOptionValue={(i) => i.id}
            getOptionLabel={(i) => i.name}
          />
        </ParametersRow.Value>
      </ParametersRow>
      <ParametersRow className='h-18'>
        <ParametersRow.Label>
          <FormattedMessage id='app.customer.category' />
        </ParametersRow.Label>
        <ParametersRow.Value className='w-full max-w-1/2 min-w-1/2'>
          <ToggleB2Cb2B
            className='justify-end'
            value={config.orderForm?.defaultValues?.category}
            onChange={(value) => {
              updateConfigFieldMutation.mutate({
                path: 'orderForm.defaultValues.category',
                value,
              });
            }}
          />
        </ParametersRow.Value>
      </ParametersRow>

      <ParametersRow className='h-18'>
        <ParametersRow.Label>
          <FormattedMessage id='app.order.vat_tariff' />
        </ParametersRow.Label>
        <ParametersRow.Value className='w-full max-w-1/2 min-w-1/2'>
          <Select
            name='orderForm.defaultValues.vatTariffId'
            value={config.orderForm?.defaultValues?.vatTariffId}
            onChange={(value) => {
              updateConfigFieldMutation.mutate({
                path: 'orderForm.defaultValues.vatTariffId',
                value,
              });
            }}
            options={vatTariffs ?? []}
            getOptionValue={(i) => i.id}
            getOptionLabel={(i) => `${i.tariffValue}%`}
          />
        </ParametersRow.Value>
      </ParametersRow>

      <ParametersRow className='h-18'>
        <ParametersRow.Label>
          <FormattedMessage id='app.order.email_target' />
        </ParametersRow.Label>
        <ParametersRow.Value className='w-full max-w-1/2 min-w-1/2'>
          <Select
            name='orderForm.defaultValues.emailTarget'
            value={config.orderForm?.defaultValues?.emailTarget}
            onChange={(value) => {
              updateConfigFieldMutation.mutate({
                path: 'orderForm.defaultValues.emailTarget',
                value,
              });
            }}
            options={EMAIL_TARGET_OPTIONS}
            getOptionValue={(i) => i.value}
            getOptionLabel={(i) => intl.formatMessage(i.labelRs)}
          />
        </ParametersRow.Value>
      </ParametersRow>
    </span>
  );
};

export default TenantOrderDefaultValuesForm;
