/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ForgottenPasswordRequest } from '../models/ForgottenPasswordRequest';
import type { LoginUserRequest } from '../models/LoginUserRequest';
import type { TokenResponse } from '../models/TokenResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AuthService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns TokenResponse
   * @throws ApiError
   */
  public login({ requestBody }: { requestBody: LoginUserRequest }): CancelablePromise<TokenResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/auth/login',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns any
   * @throws ApiError
   */
  public forgottenPassword({ requestBody }: { requestBody: ForgottenPasswordRequest }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/auth/forgotten-password',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
