import { FC, HTMLAttributes } from 'react';
import AllTechniciansCalendar from '../AllTechniciansCalendar';
import useCalendarSelectedDateParam from '../../hooks/useCalendarSelectedDateParam';
// import Tabs from 'src/components/common/Tabs';

export type AllTechniciansTabProps = {
  //
} & HTMLAttributes<HTMLDivElement>;

const AllTechniciansTab: FC<AllTechniciansTabProps> = ({ className }) => {
  const [selectedDate] = useCalendarSelectedDateParam();

  return <AllTechniciansCalendar className={className} selectedDate={selectedDate} />;
};

AllTechniciansTab.displayName = 'AllTechniciansTab';

export default AllTechniciansTab;
