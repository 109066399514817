import { useCallback } from 'react';
import useDownloadFile, { DownloadState } from './useDownloadFile';

export type UseOpenFileInNewTabReturn = {
  state: DownloadState;
  openInNewWindow: () => Promise<void>;
};

const useOpenFileInNewTab = (fileId: number): UseOpenFileInNewTabReturn => {
  const { state, downloadFile } = useDownloadFile(fileId);

  const openInNewWindow = useCallback(async () => {
    const data = await downloadFile();
    if (!data) return;

    const url = window.URL.createObjectURL(data);
    window.open(url, '_blank')?.focus();
  }, [fileId]);

  return {
    state,
    openInNewWindow,
  };
};

export default useOpenFileInNewTab;
