import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { TenantConfigDto, TenantWithLicencesResponse } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

export type UseUpdateTenantConfigVariables = {
  tenantId: number;
  requestBody: TenantConfigDto;
};

const useUpdateTenantConfig = (): UseMutationResult<unknown, unknown, UseUpdateTenantConfigVariables> => {
  const client = useApiClient();
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, UseUpdateTenantConfigVariables>(
    async ({ tenantId, requestBody }) => {
      const data = await client.tenantConfigs.updateConfig({ requestBody, tenantId });
      return data;
    },
    {
      onSuccess: (_, variables) => {
        const currentTenantQueryKey = queryKeysFactory.tenant.current.queryKey;
        const currentTenantId = queryClient.getQueryData<TenantWithLicencesResponse>(currentTenantQueryKey);
        if (currentTenantId?.id === variables.tenantId) {
          queryClient.invalidateQueries(queryKeysFactory.tenant.current);
        }

        queryClient.invalidateQueries(queryKeysFactory.tenant.detail(variables.tenantId));
        queryClient.invalidateQueries(queryKeysFactory.tenantConfig.detail(variables.tenantId));
        toast(<FormattedMessage id='app.toasts.update_success' />, {
          type: 'success',
        });
      },
      onError: () => {
        toast(<FormattedMessage id='app.toasts.update_fail' />, {
          type: 'error',
        });
      },
    },
  );
};

export default useUpdateTenantConfig;
