import { FC } from 'react';
import { format, parseISO } from 'date-fns';
import { OrderListItemResponse, OrderStatusResponse } from 'src/api';
import PageCard from '../PageCard';
import StatusLabel from '../StatusLabel';
import { MapPinIcon, PhoneIcon } from '@heroicons/react/20/solid';
import { ClockIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import Divider from '../Divider';
import AddressLink from '../AdressLink';
import { createAddressQuery } from 'src/helpers/createAddressQuery';

export type OrderInfoCardProps = {
  order: OrderListItemResponse;
};

const OrderInfoCard: FC<OrderInfoCardProps> = ({ order }) => {
  const phone = order.branch?.phoneNumber || order.company?.phoneNumber;

  return (
    <PageCard className='flex flex-col'>
      <div className='flex justify-between items-center mb-8'>
        <Link to={`/order/${order.id}`} className='text-xl font-semibold'>
          #{order.orderNumber}
        </Link>
        {order.status?.name && <StatusLabel name={order.status?.name as OrderStatusResponse.name} />}
      </div>
      <div className='flex flex-col'>
        <Link to={`/contact/${order.companyId}`} className='font-semibold mb-4'>
          {order.companyName}
        </Link>
        <div className='flex justify-between'>
          <AddressLink
            className='flex items-center gap-1'
            adressQuery={createAddressQuery({
              city: order.branch?.city ?? order.company?.city ?? '',
              street: order.branch?.street ?? order.company?.street ?? '',
            })}
          >
            <MapPinIcon className='h-4 w-4' />
            <span>{order.branch?.name || order.companyName}</span>
          </AddressLink>
          {phone && (
            <a href={`tel:${phone}`} className='flex items-center gap-1'>
              <PhoneIcon className='h-4 w-4' />
              <span>{phone}</span>
            </a>
          )}
        </div>
        <Divider />
        {order.datetime && order.endDatetime && (
          <div className='flex gap-x-1 items-center'>
            <ClockIcon className='h-4 w-4' />
            <span>{order.datetime && format(parseISO(order.datetime), 'HH:mm')}</span>-
            <span>{order.endDatetime && format(parseISO(order.endDatetime), 'HH:mm')}</span>
          </div>
        )}
      </div>
    </PageCard>
  );
};

export default OrderInfoCard;
