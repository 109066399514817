import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

const useUpdateResetCurrentTenantConfig = (): UseMutationResult<void, unknown, void> => {
  const queryClient = useQueryClient();
  const apiClient = useApiClient();

  return useMutation<void, unknown, void>(
    async () => {
      await apiClient.tenantConfigs.resetConfig();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeysFactory.tenant.list.queryKey);
        queryClient.invalidateQueries(queryKeysFactory.tenant.current);
        toast(<FormattedMessage id='app.toasts.update_success' />, {
          type: 'success',
        });
      },
      onError: () => {
        toast(<FormattedMessage id='app.toasts.update_fail' />, {
          type: 'error',
        });
      },
    },
  );
};

export default useUpdateResetCurrentTenantConfig;
