import { FC } from 'react';
import { twMerge } from 'tailwind-merge';

export type AddressLinkProps = {
  className?: string;
  adressQuery: string;
  children?: React.ReactNode;
};

const AddressLink: FC<AddressLinkProps> = ({ adressQuery, className, children }: AddressLinkProps) => {
  return (
    <a
      target='_blank'
      className={twMerge('', className)}
      href={`https://www.google.com/maps?q=${encodeURIComponent(adressQuery)}`}
      rel='noreferrer'
    >
      {children}
    </a>
  );
};

export default AddressLink;
