/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC } from 'react';
import { ExtendedColumnDef } from 'src/types/table';
import { OrderListItemResponse } from 'src/api';
import { ReactElement } from 'react';
import Icon from '@mdi/react';
import { mdiDrag } from '@mdi/js';
import Checkbox from 'src/components/fields/Checkbox';
import clsx from 'clsx';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

export type OrderColumnConfigurationRowProps = {
  className?: string;
  column: ExtendedColumnDef<OrderListItemResponse>;
  onClick: () => void;
  id: string;
  visible: boolean;
};

export const OrderColumnDndItem = Symbol('OrderColumnDndItem');

const OrderColumnConfigurationRow: FC<OrderColumnConfigurationRowProps> = ({ id, column, onClick, visible }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div style={style} className={clsx('w-full pb-1 bg-gray-50', 'border-transparent border-t-2 border-b-2')}>
      <div
        className={clsx(
          'bg-gray-50 w-full flex justify-between items-center rounded-lg border border-primary px-2 py-1',
        )}
      >
        <div className='flex items-center'>
          <Checkbox value={visible} onChange={onClick} name={column.id as string} className='mr-2' />
          <span>{typeof column.header === 'function' ? (column.header as () => ReactElement)?.() : column.header}</span>
        </div>
        <div {...attributes} {...listeners} ref={setNodeRef}>
          <Icon path={mdiDrag} size={1.25} className={'text-primary'} />
        </div>
      </div>
    </div>
  );
};

OrderColumnConfigurationRow.displayName = 'OrderColumnConfigurationRow';

export default OrderColumnConfigurationRow;
