import { CreateTenantRequest } from 'src/api';
import { z } from 'zod';

export const TenantSchema = z.object({
  tenantDomain: z.string().min(1),
  tenantName: z.string().min(1),
  applicationTitle: z.string().nullable(),
  colorTheme: z.nativeEnum(CreateTenantRequest.colorTheme).nullable(),
  maxUserCount: z.number().positive(),
  isDemo: z.boolean(),
  file: z.unknown().nullable(),
});

export type TenantSchema = z.infer<typeof TenantSchema>;
