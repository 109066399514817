import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { format } from 'date-fns';
import { FC } from 'react';
import useUserFnsLocale from 'src/api/hooks/queries/useUserFnsLocale';
import { twMerge } from 'tailwind-merge';

export type MonthHeaderProps = {
  className?: string;
  onNextMonth: () => void;
  onPrevMonth: () => void;
  disableNextMonth?: boolean;
  disablePrevMonth?: boolean;
  currentMonthStart: Date;
};

const MonthHeader: FC<MonthHeaderProps> = ({
  className,
  onNextMonth,
  onPrevMonth,
  disableNextMonth,
  disablePrevMonth,
  currentMonthStart,
}) => {
  const { data: locale } = useUserFnsLocale();

  return (
    <div className={twMerge('flex justify-between items-center mb-2', className)}>
      <span className='capitalize text-primaryFocus text-lg'>
        {format(currentMonthStart, 'LLLL', { locale })} {currentMonthStart.getFullYear()}
      </span>
      <div className='text-primaryFocus'>
        <button
          className={clsx(disablePrevMonth && 'text-neutral-400')}
          onClick={onPrevMonth}
          disabled={disablePrevMonth}
        >
          <ChevronLeftIcon className='w-8 h-8' />
        </button>
        <button
          onClick={onNextMonth}
          disabled={disableNextMonth}
          className={clsx(disableNextMonth && 'text-neutral-400')}
        >
          <ChevronRightIcon className='w-8 h-8' />
        </button>
      </div>
    </div>
  );
};

export default MonthHeader;
