import { FC, HTMLAttributes } from 'react';
import AutoPopup from 'src/components/common/AutoPopup';
import UpdateEmployeeForm from '../UpdateEmployeeForm';
import { Navigate, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

export type UpdateEmployeePopupProps = {
  //
} & HTMLAttributes<HTMLDivElement>;

const UpdateEmployeePopup: FC<UpdateEmployeePopupProps> = ({ className }) => {
  const contactId = Number(useParams().contactId);
  const employeeId = Number(useParams().employeeId);

  if (!contactId || !employeeId) return <Navigate to='/404' />;

  return (
    <AutoPopup
      id='update_employee'
      title={<FormattedMessage id='app.contact_page.update_employee.popup_title' defaultMessage='Editace pracovniku' />}
      className={className}
    >
      <UpdateEmployeeForm contactId={contactId} employeeId={employeeId} />
    </AutoPopup>
  );
};

UpdateEmployeePopup.displayName = 'UpdateEmployeePopup';

export default UpdateEmployeePopup;
