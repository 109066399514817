import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { TranslationPackResponse } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

const usePdfTranslationPack = (language: string): UseQueryResult<TranslationPackResponse> => {
  const client = useApiClient();

  const { queryKey } = queryKeysFactory.pdfTranslationsPack.detail(language);

  return useQuery(queryKey, async () => {
    const data = await client.translationPacks.getPdfTranslationsPack({ language });
    return data;
  });
};

export default usePdfTranslationPack;
