import clsx from 'clsx';
import { FC } from 'react';
// import clsx from 'clsx';
import { ReactNode } from 'react';

export type BadgeProps = {
  className?: string;
  text: ReactNode;
  color: BadgeColor;
};

export type BadgeColor = 'gray' | 'red' | 'yellow' | 'green' | 'blue' | 'indigo' | 'purple' | 'pink';

const getColorClassNames = (color: BadgeColor): string => {
  if (color === 'gray') return 'bg-gray-50 text-gray-600 ring-gray-500/10';
  if (color === 'red') return 'bg-red-50 text-red-700 ring-red-600/10';
  if (color === 'yellow') return 'bg-yellow-50 text-yellow-800 ring-yellow-600/20';
  if (color === 'green') return 'bg-green-50 text-green-700 ring-green-600/20';
  if (color === 'blue') return 'bg-blue-50 text-blue-700 ring-blue-600/20';
  if (color === 'indigo') return 'bg-indigo-50 text-indigo-700 ring-indigo-600/20';
  if (color === 'purple') return 'bg-purple-50 text-purple-700 ring-purple-600/20';
  if (color === 'pink') return 'bg-pink-50 text-pink-700 ring-pink-600/20';
  return '';
};

const Badge: FC<BadgeProps> = ({ className, color, text }) => {
  return (
    <span
      className={clsx(
        'inline-flex items-center gap-x-0.5 rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset',
        className,
        getColorClassNames(color),
      )}
    >
      {text}
    </span>
  );
};

Badge.displayName = 'Badge';

export default Badge;
