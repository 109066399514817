import useTableState from 'src/components/Table/hooks/useTableState';
import ProductsTable from './components/ProductsTable';
import { FormattedMessage } from 'react-intl';
import Title from 'src/components/Title';
import { ProductFilter } from 'src/types/filters';
import { useState } from 'react';
import useEvent from 'src/hooks/useEvent';
import SearchBlock from 'src/components/SearchBlock';
import useProductsFromSearch from 'src/api/hooks/queries/useProductsFromSearch';
import PageCard from 'src/components/PageCard';

export type ProductsPageProps = {
  //
};

const ProductsPage: React.FC<ProductsPageProps> = () => {
  const { columnFilters, setColumnFilters, sorting, setSorting, pagination, setPagination } =
    useTableState<ProductFilter>();

  const [searchValue, setSearchValue] = useState('');
  const [queryString, setQueryString] = useState('');

  const handleSearch = useEvent(() => setQueryString(searchValue));

  const { data } = useProductsFromSearch(queryString, pagination);

  return (
    <>
      <Title className='mb-12'>
        <FormattedMessage id='app.products_page.title' />
      </Title>
      <PageCard className='flex flex-col gap-12'>
        <SearchBlock
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          handleSearch={handleSearch}
          description={<FormattedMessage id='app.branches_page.search.helper_text' />}
        />
        <ProductsTable
          columnFilters={columnFilters}
          handleFilter={setColumnFilters}
          pagination={pagination}
          handlePaginationChange={setPagination}
          sorting={sorting}
          handleSort={setSorting}
          data={data?.items ?? []}
          paginationMeta={data?.meta}
        />
      </PageCard>
    </>
  );
};

export default ProductsPage;
