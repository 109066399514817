// eslint-disable-next-line @typescript-eslint/no-explicit-any
const parseJsonFile = async (file: File): Promise<Record<string, any>> =>
  new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = (event) => {
      if (!event?.target?.result) reject();
      resolve(JSON.parse(event.target?.result as string));
    };
    fileReader.onerror = (error) => reject(error);
    fileReader.readAsText(file);
  });

export default parseJsonFile;
