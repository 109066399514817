import { createColumnHelper } from '@tanstack/react-table';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { OrderTypeListItemRepsonse } from 'src/api';
import Table, { TableProps } from 'src/components/Table';
import TextFilter from 'src/components/Table/filters/TextFilter';
import { ExtendedColumnDef } from 'src/types/table';

export type OrderTypesTableProps = Omit<TableProps<OrderTypeListItemRepsonse>, 'columns'>;

const columnHelper = createColumnHelper<OrderTypeListItemRepsonse>();

const columns: ExtendedColumnDef<OrderTypeListItemRepsonse>[] = [
  {
    ...columnHelper.accessor('id', {
      id: 'price',
      header: () => <FormattedMessage id='app.common.id' />,
      enableColumnFilter: false,
      enableSorting: true,
      cell: (info) => info.renderValue(),
      size: 200,
    }),
  },
  {
    ...columnHelper.accessor('name', {
      id: 'name',
      header: () => <FormattedMessage id='app.order_type.name' />,
      enableColumnFilter: true,
      enableSorting: true,
      cell: (info) => info.renderValue(),
      size: 250,
    }),
    renderFilter: (column) => <TextFilter column={column} />,
  },
];

const OrderTypesTable: FC<OrderTypesTableProps> = (props) => {
  const navigate = useNavigate();

  return (
    <Table columns={columns} {...props} handleRowClick={(original) => navigate(`/types-of-orders/${original.id}`)} />
  );
};

OrderTypesTable.displayName = 'OrderTypesTable';

export default OrderTypesTable;
