import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { formatISO, startOfWeek } from 'date-fns';
import { WeekOrdersStatisticsForStatusResponse } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

const useWeekOrdersStatistics = (dayOfTheWeek: Date): UseQueryResult<WeekOrdersStatisticsForStatusResponse[]> => {
  const client = useApiClient();
  const startOfTheWeek = startOfWeek(dayOfTheWeek, { weekStartsOn: 1 });
  const { queryKey } = queryKeysFactory.dashboard.weekOrdersStatistics(startOfTheWeek);

  return useQuery(
    queryKey,
    async () => {
      const data = await client.dashboard.getWeekOrdersStatistics({
        requestBody: { dayOfTheWeek: formatISO(startOfTheWeek) },
      });
      return data;
    },
    {
      refetchInterval: false,
      staleTime: 1000 * 60 * 5,
    },
  );
};

export default useWeekOrdersStatistics;
