import { FC, HTMLAttributes, useCallback } from 'react';
import { SubmitHandler } from 'react-hook-form';
import useUpdateMaterial from 'src/api/hooks/mutations/material/useUpdateMaterial';
import useMaterial from 'src/api/hooks/queries/useMaterial';
import { UpdateMaterialRequest } from 'src/api';
import { usePopupContext } from 'src/components/Popup/hook';
import MaterialForm from '../MaterialForm';

export type UpdateMaterialFormProps = {
  materialId: number;
} & HTMLAttributes<HTMLDivElement>;

const UpdateMaterialForm: FC<UpdateMaterialFormProps> = ({ className, materialId }) => {
  const updateMaterial = useUpdateMaterial();
  const { data } = useMaterial(materialId);
  const { onClose } = usePopupContext();

  const onSubmit: SubmitHandler<UpdateMaterialRequest> = useCallback(
    (data) => {
      updateMaterial.mutate({ ...data, id: materialId });
      onClose();
    },
    [updateMaterial, onClose],
  );

  return <MaterialForm defaultValues={data} onSubmit={onSubmit} className={className} />;
};

UpdateMaterialForm.displayName = 'UpdateMaterialForm';

export default UpdateMaterialForm;
