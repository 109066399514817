import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { UnsuccessfulOrderRequest } from 'src/api';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

const useDoomTechnicianOrder = (): UseMutationResult<void, unknown, UnsuccessfulOrderRequest> => {
  const client = useApiClient();
  const queryClient = useQueryClient();

  return useMutation<void, unknown, UnsuccessfulOrderRequest>(
    async (data: UnsuccessfulOrderRequest) => {
      await client.technicianOrder.unsuccessfulOrder({ requestBody: data });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeysFactory.technicianOrder._def);
        queryClient.invalidateQueries(queryKeysFactory.orders._def);
        toast(<FormattedMessage id='app.toasts.update_success' />, {
          type: 'success',
        });
      },
      onError: () => {
        toast(<FormattedMessage id='app.toasts.update_fail' />, {
          type: 'error',
        });
      },
    },
  );
};

export default useDoomTechnicianOrder;
