import { FC, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams, Navigate } from 'react-router-dom';
import useRestoreMaterial from 'src/api/hooks/mutations/material/useRestoreMaterial';
import ConfirmationPopup from 'src/components/common/ConfirmationPopup';

export type RestoreMaterialPopupProps = {
  className?: string;
};

export const RESTORE_MATERIAL_POPUP_ID = 'restore_material';

const RestoreMaterialPopup: FC<RestoreMaterialPopupProps> = ({ className }) => {
  const materialId = Number(useParams().materialId);

  const restoreMaterial = useRestoreMaterial();

  const onOk = useCallback(() => {
    restoreMaterial.mutate(materialId);
  }, [materialId, restoreMaterial]);

  if (!materialId) return <Navigate to='/404' />;

  return (
    <ConfirmationPopup
      className={className}
      id={RESTORE_MATERIAL_POPUP_ID}
      onOk={onOk}
      title={<FormattedMessage id='app.material_page.restore_material.popup_title' />}
    />
  );
};

RestoreMaterialPopup.displayName = 'RestoreMaterialPopup';

export default RestoreMaterialPopup;
