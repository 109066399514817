import { useQuery, UseQueryResult } from '@tanstack/react-query';
import queryKeysFactory from 'src/store/queryKeysFactory';

const getLocalizationsMap: Record<string, () => Promise<object>> = {
  es: () => import('react-phone-input-2/lang/es.json'),
  fr: () => import('react-phone-input-2/lang/fr.json'),
  de: () => import('react-phone-input-2/lang/de.json'),
  cz: () => import('src/components/fields/PhoneInputLocalized/lang/cz.json'),
};

// localizations for PhoneInput. undef = use english
const useCountriesLocalization = (lang: string): UseQueryResult<Record<string, string> | null> => {
  const { queryKey } = queryKeysFactory.countriesLocalization.detail(lang);
  return useQuery(queryKey, () => getLocalizationsMap[lang]?.() ?? null);
};

export default useCountriesLocalization;
