import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import useOrderTypes from 'src/api/hooks/queries/useOrderTypes';
import PageContentHeader from 'src/components/PageContentHeader';
import Popup from 'src/components/Popup';
import useTableState from 'src/components/Table/hooks/useTableState';
import useLastDefined from 'src/hooks/useLastDefined';
import usePageBaseState from 'src/hooks/usePageBaseState';
import { OrderTypeFilter } from 'src/types/filters';
import AddOrderTypeForm from './AddOrderTypeForm';
import OrderTypesTable from './OrderTypesTable';
import PageCard from 'src/components/PageCard';

const defaultSortingState = [{ id: 'name', desc: false }];

const OrderTypesPage: FC = () => {
  const { isFilterOpen, isPopupOpen, handleClosePopup, handleOpenPopup, handleToggleFilter } = usePageBaseState();

  const {
    columnFilters,
    setColumnFilters,
    sorting,
    setSorting,
    pagination,
    setPagination,
    debouncedServerFriendlyFilters,
    sortingQuery,
  } = useTableState<OrderTypeFilter>({
    defaultSortingState,
  });

  const { data, isLoading } = useOrderTypes(debouncedServerFriendlyFilters, sortingQuery, pagination);
  const definedData = useLastDefined(data);

  const isEmpty = !isLoading && definedData?.items.length === 0;

  return (
    <>
      <PageContentHeader
        className='mb-12'
        mobilePrimaryButtonType='new'
        title={<FormattedMessage id='app.order_type_page.title' />}
        onSecondaryButtonClick={handleToggleFilter}
        onPrimaryButtonClick={handleOpenPopup}
        primaryButtonText={<FormattedMessage id='app.order_type.new_order_type.popup_title' />}
      />
      <PageCard>
        {isEmpty ? (
          <FormattedMessage id='app.common.no_items' />
        ) : (
          <OrderTypesTable
            isLoading={isLoading}
            data={definedData?.items ?? []}
            isFilterOpen={isFilterOpen}
            columnFilters={columnFilters}
            sorting={sorting}
            pagination={pagination}
            handleFilter={setColumnFilters}
            handleSort={setSorting}
            handlePaginationChange={setPagination}
            paginationMeta={definedData?.meta}
          />
        )}
        <Popup
          title={<FormattedMessage id='app.order_type.new_order_type.popup_title' />}
          onClose={handleClosePopup}
          open={isPopupOpen}
        >
          <AddOrderTypeForm />
        </Popup>
      </PageCard>
    </>
  );
};

OrderTypesPage.displayName = 'OrderTypesPage';

export default OrderTypesPage;
