import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import AutoPopup from 'src/components/common/AutoPopup';
import AddLanguageForm from '../AddLanguageForm';

export type AddLanguagePopupProps = {
  className?: string;
};

const AddLanguagePopup: FC<AddLanguagePopupProps> = ({ className }) => {
  return (
    <AutoPopup
      id='add_language'
      title={<FormattedMessage id='app.translations_page.add_language_popup_title' />}
      className={className}
    >
      <AddLanguageForm />
    </AutoPopup>
  );
};

AddLanguagePopup.displayName = 'AddLanguagePopup';

export default AddLanguagePopup;
