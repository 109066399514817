import { FC } from 'react';
import clsx from 'clsx';
import Alert from 'src/components/common/Alert';
import { OrderResponse } from 'src/api';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMemo } from 'react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

export type ServiceAlertsProps = {
  className?: string;
  data: OrderResponse;
};

const ServiceAlerts: FC<ServiceAlertsProps> = ({ className, data }) => {
  const intl = useIntl();
  const hasTechnicians = data.technicians.length > 0;
  const hasStartDate = data.datetime !== null;

  const warningReasons = useMemo(() => {
    const reasons = [];

    if (!hasTechnicians) reasons.push(intl.formatMessage({ id: 'app.order.no_technicians' }).toLowerCase());
    if (!hasStartDate) reasons.push(intl.formatMessage({ id: 'app.order.no_datetime' }).toLowerCase());

    return reasons;
  }, [hasStartDate, hasTechnicians, intl]);

  if (!warningReasons.length) return null;

  return (
    <div className={clsx(className, 'flex flex-col gap-y-5')}>
      {warningReasons.length > 0 && (
        <Alert
          icon={<ExclamationTriangleIcon className='w-6 h-6 text-yellow-500' />}
          text={<FormattedMessage id='app.alert.can_not_finish_order' values={{ value: warningReasons.join(', ') }} />}
        />
      )}
    </div>
  );
};

ServiceAlerts.displayName = 'ServiceAlerts';

export default ServiceAlerts;
