import clsx from 'clsx';
import React, { useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import NewItemPopupFooter from 'src/components/common/NewItemPopupFooter';
import { PhoneInputLocalizedControlled } from 'src/components/fields/PhoneInputLocalized';
import { TextFieldControlled } from 'src/components/fields/Textfield';
import { EmployeeSchema } from './schema';
import { zodResolver } from '@hookform/resolvers/zod';

interface EmployeeFormProps {
  defaultValues?: Partial<EmployeeSchema>;
  className?: string;
  onSubmit: SubmitHandler<EmployeeSchema>;
}

const EmployeeForm: React.FC<EmployeeFormProps> = ({ defaultValues, className, onSubmit }) => {
  const form = useForm<EmployeeSchema>({
    resolver: zodResolver(EmployeeSchema),
    defaultValues: defaultValues,
  });
  const { handleSubmit, control, setValue } = form;

  useEffect(() => {
    if (!defaultValues) return;
    Object.entries(defaultValues).forEach(([k, v]) => setValue(k as keyof EmployeeSchema, v));
  }, [defaultValues]);

  return (
    <div className={clsx(className, 'flex-col w-full')}>
      <form className='flex flex-col w-full' onSubmit={handleSubmit(onSubmit)}>
        <FormProvider {...form}>
          <TextFieldControlled
            control={control}
            name='name'
            asterisk
            label={<FormattedMessage id='app.employee.name' defaultMessage='Jmeno' />}
          />

          <PhoneInputLocalizedControlled
            control={control}
            name='phoneNumber'
            label={<FormattedMessage id='app.common.phone' defaultMessage='Telefon' />}
          />
          <TextFieldControlled
            control={control}
            name='email'
            label={<FormattedMessage id='app.common.email' defaultMessage='E-mail' />}
          />
          <NewItemPopupFooter />
        </FormProvider>
      </form>
    </div>
  );
};

export default EmployeeForm;
