import { EllipsisHorizontalIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { defaultIsSelectedNavigationItem, mobileBottomNavigationItems } from 'src/constants/navigation';
import { isSidebarOpenState } from 'src/store/atoms/sidebar';
import { NavigationItem } from 'src/types/commonTypes';

export type MobileBottomNavProps = {
  //
};

const MobileBottomNav: FC<MobileBottomNavProps> = () => {
  useLocation();
  const [, setSidebarOpen] = useRecoilState(isSidebarOpenState);

  return (
    <nav className='w-full h-18 flex justify-around items-center border-t border-t-gray-300 pt-2 px-4'>
      {mobileBottomNavigationItems.map((item) => (
        <MobileBottomNavLink item={item} key={item.href} />
      ))}
      <button type='button' onClick={() => setSidebarOpen((p) => !p)}>
        <EllipsisHorizontalIcon width='2rem' height='2rem' />
      </button>
    </nav>
  );
};

type MobileBottomNavLinkProps = {
  item: NavigationItem;
};
const MobileBottomNavLink: FC<MobileBottomNavLinkProps> = ({ item }) => {
  const { pathname } = useLocation();
  const isSelected = defaultIsSelectedNavigationItem(pathname, item.href);

  return (
    <Link to={item.href} className='flex flex-col justify-center items-center h-full'>
      <span>{item.renderIcon({ width: '2rem', height: '2rem' })}</span>
      <span className={clsx('block w-full h-1 mt-2', isSelected && 'rounded-full bg-primary')}></span>
    </Link>
  );
};

export default MobileBottomNav;
