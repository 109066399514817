/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type WeekOrdersStatisticsForStatusResponse = {
  status: WeekOrdersStatisticsForStatusResponse.status;
  count: number;
};
export namespace WeekOrdersStatisticsForStatusResponse {
  export enum status {
    PLANNED = 'PLANNED',
    UNSUCCESSFUL = 'UNSUCCESSFUL',
    COMPLETED = 'COMPLETED',
    INVOICED = 'INVOICED',
    ROUTINE = 'ROUTINE',
    DELETED = 'DELETED',
    IN_PROGRESS = 'IN_PROGRESS',
  }
}
