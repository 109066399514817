import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import AddVatTariffForm from '../AddVatTariffForm';
import AutoPopup from 'src/components/common/AutoPopup';

export type AddVatTariffPopupProps = {
  className?: string;
};

export const ADD_VAT_TARIFF_POPUP_ID = 'add_vat_tariff';

const AddVatTariffPopup: FC<AddVatTariffPopupProps> = ({ className }) => {
  return (
    <AutoPopup
      id={ADD_VAT_TARIFF_POPUP_ID}
      title={<FormattedMessage id='app.vat_tariff.new_vat_tariff.popup_title' />}
      className={className}
    >
      <AddVatTariffForm />
    </AutoPopup>
  );
};

AddVatTariffPopup.displayName = 'AddVatTariffPopup';

export default AddVatTariffPopup;
