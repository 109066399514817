import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

type UseUpdateOrderTypeNumberFormatVariables = {
  orderTypeId: number;
  numberFormat: string;
};
const useUpdateOrderTypeNumberFormat = (): UseMutationResult<
  unknown,
  unknown,
  UseUpdateOrderTypeNumberFormatVariables
> => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, UseUpdateOrderTypeNumberFormatVariables>(
    async ({ orderTypeId, numberFormat }: UseUpdateOrderTypeNumberFormatVariables) => {
      await apiClient.orderTypes.updateOrderNumberFormat({ id: orderTypeId, numberFormat });
    },
    {
      onSuccess: (_data, { orderTypeId }) => {
        queryClient.invalidateQueries(queryKeysFactory.orderTypes.detail(orderTypeId).queryKey);
        queryClient.invalidateQueries(queryKeysFactory.orderTypes.list._def);
        toast(<FormattedMessage id='app.toasts.update_success' />, {
          type: 'success',
        });
      },
      onError: () => {
        toast(<FormattedMessage id='app.toasts.update_fail' />, {
          type: 'error',
        });
      },
    },
  );
};

export default useUpdateOrderTypeNumberFormat;
