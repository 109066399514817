const isDesktopOS = (): boolean => {
  const { userAgent } = navigator;

  if (userAgent.match(/Mac/)) return true;
  if (userAgent.match(/Windows/)) return true;
  if (userAgent.match(/Linux/)) return true;
  if (userAgent.match(/X11/)) return true;

  return false;
};

export default isDesktopOS;
