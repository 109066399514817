import { FC } from 'react';
import { twMerge } from 'tailwind-merge';

export type DayCellProps = {
  className?: string;
  disabled?: boolean;
  selected?: boolean;
  date: Date;
  onClick?: () => void;
  greyedOut?: boolean;
};

const DayCell: FC<DayCellProps> = ({ className, greyedOut, onClick, date, disabled, selected }) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      key={date.getTime()}
      className={twMerge(
        'flex justify-center rounded items-center h-8 w-8 text-center',
        onClick && !disabled && 'cursor-pointer',
        selected && 'bg-primaryFocus text-white',
        greyedOut && 'text-gray-400',
        className,
      )}
    >
      {date.getDate()}
    </button>
  );
};

export default DayCell;
