import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { ProductListResponse } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

type QueryKey = ReturnType<typeof queryKeysFactory.products.list._ctx.fromBranch>['queryKey'];
type QueryOptions = Omit<
  UseQueryOptions<ProductListResponse[], unknown, ProductListResponse[], QueryKey>,
  'queryKey' | 'queryFn' | 'initialData'
>;

const useBranchProducts = (branchId: number, options: QueryOptions = {}): UseQueryResult<ProductListResponse[]> => {
  const client = useApiClient();
  const { queryKey } = queryKeysFactory.products.list._ctx.fromBranch(branchId);

  return useQuery(
    queryKey,
    async () => {
      const data = await client.product.getBranchProductList({ branchId });

      return data;
    },
    {
      refetchInterval: false,
      staleTime: 1000 * 60 * 5,
      ...options,
    },
  );
};

export default useBranchProducts;
