import { FC } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { TextFieldControlled } from 'src/components/fields/Textfield';
import { SelectControlled } from 'src/components/fields/Select';
import { SelectFieldSchemaResponse, UserResponse } from 'src/api';
import NewItemPopupFooter from 'src/components/common/NewItemPopupFooter';
import { FormBuilderFieldSchema } from './schema';
import { FormattedMessage } from 'react-intl';
import { CheckboxControlled } from 'src/components/fields/Checkbox';
import { zodResolver } from '@hookform/resolvers/zod';
import useCurrentUser from 'src/api/hooks/queries/useCurrentUser';
import ArrayTextField from 'src/components/fields/MultiInput/ArrayTextField';
import { twMerge } from 'tailwind-merge';

export type FormBuilderFieldFormProps = {
  className?: string;
  onSubmit: SubmitHandler<FormBuilderFieldSchema>;
  defaultValues?: Partial<FormBuilderFieldSchema>;
};

const DEFAULT_VALUES = {
  id: '',
  name: '',
  label: '',
  type: SelectFieldSchemaResponse.type.CHECKBOX,
  options: null,
  showIfName: null,
} as unknown as FormBuilderFieldSchema;

const FormBuilderFieldForm: FC<FormBuilderFieldFormProps> = ({ className, onSubmit, defaultValues }) => {
  const { data: currentUser } = useCurrentUser();
  const form = useForm<FormBuilderFieldSchema>({
    defaultValues: {
      ...DEFAULT_VALUES,
      ...defaultValues,
    },
    resolver: zodResolver(FormBuilderFieldSchema),
  });
  const { control, watch, setValue, handleSubmit } = form;

  const type = watch('type');
  const isSysmAdmin = currentUser?.role === UserResponse.role.SYSTEM_ADMINISTRATOR;

  return (
    <form className={twMerge('flex flex-col gap-y-4', className)} onSubmit={handleSubmit(onSubmit)}>
      {isSysmAdmin && (
        <TextFieldControlled control={control} name='name' label={<FormattedMessage id='app.common.name' />} />
      )}
      <TextFieldControlled control={control} name='label' label={<FormattedMessage id='app.common.label' />} />
      <SelectControlled
        label={<FormattedMessage id='app.common.type' />}
        control={control}
        extraOnChange={(value) => {
          if (value !== SelectFieldSchemaResponse.type.SELECT) setValue('options', null);
          else setValue('options', ['']);
        }}
        name='type'
        options={Object.values(SelectFieldSchemaResponse.type)}
        getOptionLabel={(option) => option}
        getOptionValue={(option) => option}
      />

      <CheckboxControlled
        control={control}
        name='ignoreFalseInPDF'
        label={<FormattedMessage id='app.order_type.sl_config.field.ignoreFalseInPDF' />}
      />

      {type === SelectFieldSchemaResponse.type.SELECT && (
        <Controller
          name='options'
          control={control}
          render={({ field: { value, onChange } }) => (
            <ArrayTextField
              name='options'
              label={<FormattedMessage id='app.order_type.sl_config.field.options' />}
              value={value ?? []}
              onChange={onChange}
            />
          )}
        />
      )}

      <NewItemPopupFooter />
    </form>
  );
};

FormBuilderFieldForm.displayName = 'FormBuilderFieldForm';

export default FormBuilderFieldForm;
