import { CellContext } from '@tanstack/react-table';
import { FC } from 'react';
import { OrderListItemResponse } from 'src/api';
import CustomerAvatar from 'src/components/CustomerAvatar';

export type CustomerCellProps = {
  info: CellContext<OrderListItemResponse, unknown>;
};

const CustomerCell: FC<CustomerCellProps> = ({ info }) => {
  const name = info.row.original.companyName;

  if (!name) return null;

  return <CustomerAvatar key={name} name={name} />;
};

export default CustomerCell;
