import { FC, PropsWithChildren } from 'react';
import Loader from '../Loader';

export type WithLoaderProps = PropsWithChildren<{
  isLoading: boolean;
  renderLoader?: () => JSX.Element;
}>;

const WithLoader: FC<WithLoaderProps> = ({ isLoading, renderLoader, children }) => (
  <>
    {isLoading && (renderLoader?.() ?? <Loader />)}
    {children}
  </>
);

export default WithLoader;
