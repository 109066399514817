/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BranchResponse } from './BranchResponse';
import type { ContactResponse } from './ContactResponse';
import type { DeliveryRateResponse } from './DeliveryRateResponse';
import type { DeliveryTariffResponse } from './DeliveryTariffResponse';
import type { EmployeeResponse } from './EmployeeResponse';
import type { HeadquartersResponse } from './HeadquartersResponse';
import type { OrderProductResponse } from './OrderProductResponse';
import type { OrderTypeRepsonse } from './OrderTypeRepsonse';
import type { UserResponse } from './UserResponse';
import type { VatTariffResponse } from './VatTariffResponse';
import type { WorkTariffResponse } from './WorkTariffResponse';
export type OrderResponse = {
  id: number;
  status: string | null;
  datetime: string | null;
  endDatetime: string | null;
  realStart: string | null;
  realEnd: string | null;
  descriptionNote: string | null;
  deliveryType: OrderResponse.deliveryType | null;
  invoiceNumber: string | null;
  preferredCurrency: string | null;
  company: ContactResponse;
  branch: BranchResponse | null;
  employee: EmployeeResponse | null;
  orderProducts: Array<OrderProductResponse>;
  technicians: Array<UserResponse>;
  deliveryTariff: DeliveryTariffResponse | null;
  workTariff: WorkTariffResponse | null;
  orderType: OrderTypeRepsonse | null;
  headquarters: HeadquartersResponse | null;
  vatTariff: VatTariffResponse | null;
  serviceDuration: number | null;
  serviceMileage: number | null;
  serviceTimeOnRoad: number | null;
  serviceTotalPrice: number | null;
  materialPrice: number | null;
  companyId: number;
  branchId: number | null;
  employeeId: number | null;
  deliveryTariffId: number | null;
  orderTypeId: number | null;
  workTariffId: number | null;
  headquartersId: number | null;
  vatTariffId: number | null;
  previousOrderId: number | null;
  category: OrderResponse.category;
  signingCustomerName: string | null;
  contactName: string | null;
  contactEmail: string | null;
  contactPhone: string | null;
  orderUrl: string;
  orderNumber: string;
  clientOrderNumber: string | null;
  useManualOrderNumber: boolean;
  emailTarget: OrderResponse.emailTarget;
  slConfigOutput: Record<string, any> | null;
  deliveryRateId: number;
  deliveryRate: DeliveryRateResponse | null;
  createdAt: string;
};
export namespace OrderResponse {
  export enum deliveryType {
    CALCULATED = 'CALCULATED',
    TARIFF = 'TARIFF',
    MANUAL = 'MANUAL',
  }
  export enum category {
    B2B = 'B2B',
    B2C = 'B2C',
  }
  export enum emailTarget {
    HQ = 'hq',
    BRANCH = 'branch',
  }
}
