import React from 'react';

const BackIcon: React.FC = () => (
  <svg width='18' height='16' viewBox='0 0 18 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M17.4989 7.49896H5.51127L8.64364 4.36658C8.84034 4.16988 8.84034 3.85442 8.64364 3.65772C8.44694 3.46102 8.13148 3.46102 7.93478 3.65772L3.94508 7.64741C3.8523 7.74019 3.79663 7.87009 3.79663 7.99999C3.79663 8.12988 3.84859 8.25978 3.94508 8.35256L7.93478 12.3423C8.03127 12.4387 8.16117 12.4907 8.28735 12.4907C8.41354 12.4907 8.54343 12.4425 8.63993 12.3423C8.83663 12.1456 8.83663 11.8301 8.63993 11.6334L5.51127 8.50102H17.4989C17.7772 8.50102 17.9999 8.27834 17.9999 7.99999C17.9999 7.72164 17.7772 7.49896 17.4989 7.49896Z'
      fill='black'
    />
    <path
      d='M0.501031 0.670105C0.22268 0.670105 0 0.892785 0 1.17114V14.8289C0 15.1072 0.22268 15.3299 0.501031 15.3299C0.779381 15.3299 1.00206 15.1072 1.00206 14.8289V1.17114C1.00206 0.892785 0.779381 0.670105 0.501031 0.670105Z'
      fill='black'
    />
  </svg>
);

export default BackIcon;
