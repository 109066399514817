import { ColumnFiltersState } from '@tanstack/react-table';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Outlet } from 'react-router-dom';
import usePaymentMethods from 'src/api/hooks/queries/usePaymentMethods';
import PageContentHeader from 'src/components/PageContentHeader';
import useTableState from 'src/components/Table/hooks/useTableState';
import useLastDefined from 'src/hooks/useLastDefined';
import usePageBaseState from 'src/hooks/usePageBaseState';
import { PaymentMethodFilter } from 'src/types/filters';
import PaymentMethodTable from './PaymentMethodTable';
import PageCard from 'src/components/PageCard';

const transformFilters = (filters: ColumnFiltersState): PaymentMethodFilter => ({
  pm: (filters.find((i) => i.id === 'imp_name')?.value as string) ?? '',
});

const defaultSortingState = [{ id: 'imp_name', desc: false }];

const PaymentMethodPage: React.FC = () => {
  const { isFilterOpen } = usePageBaseState();

  const {
    columnFilters,
    sorting,
    pagination,
    setColumnFilters,
    setSorting,
    setPagination,
    debouncedServerFriendlyFilters,
    sortingQuery,
  } = useTableState<PaymentMethodFilter>({
    transformFilters,
    defaultSortingState,
  });

  const { data, isLoading } = usePaymentMethods(debouncedServerFriendlyFilters, sortingQuery);
  const definedData = useLastDefined(data);

  return (
    <>
      <PageContentHeader
        className='my-4'
        title={<FormattedMessage id='app.payment_method_page.title' />}
        // onSecondaryButtonClick={handleToggleFilter}
        // onPrimaryButtonClick={handleOpenPopup}
        hidePrimaryButton
        hideSecondaryButton
        // primaryButtonText={
        //   <FormattedMessage id='app.payment_method_page.new_payment_method_button' defaultMessage='Nova metoda' />
        // }
      />
      <PageCard>
        <PaymentMethodTable
          data={definedData?.items ?? []}
          isLoading={isLoading}
          isFilterOpen={isFilterOpen}
          columnFilters={columnFilters}
          sorting={sorting}
          pagination={pagination}
          handleFilter={setColumnFilters}
          handleSort={setSorting}
          handlePaginationChange={setPagination}
          paginationMeta={definedData?.meta}
        />
        <Outlet />
      </PageCard>
      {/* <Popup
        open={isPopupOpen}
        onClose={handleClosePopup}
        title={<FormattedMessage id='app.payment_method_page.popup_title' defaultMessage='Nova platební metoda' />}
      >
        <PaymentMethodForm />
      </Popup> */}
    </>
  );
};

export default PaymentMethodPage;
