import React, { useEffect } from 'react';
import { TextFieldControlled } from 'src/components/fields/Textfield';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { CreateRoutineServicesRequest, UpdateRoutineServicesRequest } from 'src/api';
import { ServiceRoutineSchema } from './schema';
import NewItemPopupFooter from 'src/components/common/NewItemPopupFooter';
import { NumberFieldControlled } from 'src/components/fields/NumberField';
import { zodResolver } from '@hookform/resolvers/zod';

interface RoutineServicesFormProps {
  defaultValues?: Partial<ServiceRoutineSchema>;
  onSubmit: SubmitHandler<ServiceRoutineSchema>;
  className?: string;
}

const RoutineServicesForm: React.FC<RoutineServicesFormProps> = ({ defaultValues, onSubmit, className }) => {
  const form = useForm<CreateRoutineServicesRequest & UpdateRoutineServicesRequest>({
    resolver: zodResolver(ServiceRoutineSchema),
    defaultValues,
  });
  const { handleSubmit, control, setValue } = form;

  useEffect(() => {
    if (!defaultValues) return;
    Object.entries(defaultValues).forEach(([k, v]) => setValue(k as keyof ServiceRoutineSchema, v));
  }, [defaultValues]);

  return (
    <div className={clsx(className, 'flex-col w-full')}>
      <form className='flex flex-col w-full gap-y-4' onSubmit={handleSubmit(onSubmit, console.error)}>
        <FormProvider {...form}>
          <TextFieldControlled
            control={control}
            name='routineName'
            asterisk
            label={<FormattedMessage id='app.routine_service.name' />}
          />

          <NumberFieldControlled
            control={control}
            name='routineValue'
            asterisk
            label={<FormattedMessage id='app.routine_service.value' />}
            type='text'
          />
          <NewItemPopupFooter />
        </FormProvider>
      </form>
    </div>
  );
};

export default RoutineServicesForm;
