import { z } from 'zod';

export const BranchSchema = z.object({
  name: z.string().min(1),
  city: z.string().min(1),
  street: z.string().min(1),
  zip: z.string().min(1),
  phoneNumber: z.string().nullable().default(null),
  email: z
    .union([
      z.string().min(1),
      z
        .string()
        .max(0)
        .transform(() => null)
        .nullable(),
    ])
    .default(null),
  vat: z.string().nullable().default(null),
  contactPerson: z.string().default('').nullable(),
});

export type BranchSchema = z.infer<typeof BranchSchema>;
