import { FC, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import useDeleteContactProduct from 'src/api/hooks/mutations/contact/useDeleteContactProduct';
import ConfirmationPopup from 'src/components/common/ConfirmationPopup';

export type DeleteProductPopupProps = {
  productId: number;
  className?: string;
};

const DeleteProductPopup: FC<DeleteProductPopupProps> = ({ productId, className }) => {
  const navigate = useNavigate();

  const deleteBranch = useDeleteContactProduct();

  const onOk = useCallback(() => {
    deleteBranch.mutate({ productId });
    navigate(-1);
  }, [productId, deleteBranch]);

  return (
    <ConfirmationPopup
      className={className}
      id='delete_product'
      onOk={onOk}
      title={<FormattedMessage id='app.contact_page.delete_product.popup_title' />}
    />
  );
};

DeleteProductPopup.displayName = 'DeleteProductPopup';

export default DeleteProductPopup;
