import { UserResponse } from 'src/api';
import useCurrentUser from 'src/api/hooks/queries/useCurrentUser';

export type UseGetIsRoleAllowedReturn = (allowedRoles: UserResponse.role[]) => boolean;

const useGetIsRoleAllowed = (): UseGetIsRoleAllowedReturn => {
  const { data: currentUser } = useCurrentUser();

  return (allowedRoles) => !!currentUser?.role && allowedRoles.includes(currentUser?.role);
};

export default useGetIsRoleAllowed;
