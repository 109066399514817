import { useMemo } from 'react';
import { FC } from 'react';
import { useDragLayer, XYCoord } from 'react-dnd';
import { ITEM_TYPES } from '../../constants';
import { GridEvent } from '../../types';
import EventPreview from '../EventPreview';
import useGridCellDimensions from '../../hooks/useGridCellDimensions';
import useEventToGrid from '../../hooks/useEventToGrid';

export type EventsDragLayerProps = {
  events: GridEvent[];
};

const defaultOffset: XYCoord = { x: 0, y: 0 };

const EventsDragLayer: FC<EventsDragLayerProps> = ({ events }) => {
  const { itemType, isDragging, item, initialOffset, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  const event = useMemo(() => events.find((e) => e.id === item?.id), [events, item?.id]);
  const { columnWidth, rowHeight } = useGridCellDimensions();
  const eventToGrid = useEventToGrid();

  if (!isDragging) return null;
  if (!initialOffset || !currentOffset) return null;
  if (!event) return null;

  const xOffset = initialOffset.x - currentOffset.x;
  const yOffset = initialOffset.y - currentOffset.y;
  const { rowSpan, colSpan } = eventToGrid(event);

  const row = Math.round(yOffset / rowHeight);
  const col = Math.round(xOffset / columnWidth);

  if (itemType === ITEM_TYPES.EVENT) {
    return (
      <div className='fixed left-0 top-0 z-[99999] pointer-events-none'>
        <div
          className='block absolute border-4 rounded-md border-neutral-400 border-dashed bg-neutral-100'
          style={{
            top: initialOffset.y,
            left: initialOffset.x,
            transform: `translate(${-col * columnWidth}px, ${-row * rowHeight}px)`,
            width: columnWidth * colSpan,
            height: rowSpan * rowHeight,
          }}
        ></div>
        <EventPreview
          event={event}
          currentOffset={currentOffset ?? defaultOffset}
          initialOffset={initialOffset ?? defaultOffset}
        />
      </div>
    );
  }

  return null;
};

EventsDragLayer.displayName = 'EventsDragLayer';

export default EventsDragLayer;
