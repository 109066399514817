import { FC, HTMLAttributes, useCallback } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { CreateBranchRequest } from 'src/api';
import useUpdateContactBranch from 'src/api/hooks/mutations/contact/useUpdateContactBranch';
import useContactBranch from 'src/api/hooks/queries/useContactBranch';
import { usePopupContext } from 'src/components/Popup/hook';
import BranchForm from '../BranchForm';

export type UpdateBranchFormProps = {
  contactId: number;
  branchId: number;
} & HTMLAttributes<HTMLDivElement>;

const UpdateBranchForm: FC<UpdateBranchFormProps> = ({ className, contactId, branchId }) => {
  const updateBranch = useUpdateContactBranch();
  const { data } = useContactBranch(branchId);
  const { onClose } = usePopupContext();

  const onSubmit: SubmitHandler<CreateBranchRequest> = useCallback(
    (data) => {
      updateBranch.mutate({ ...data, contactId, id: branchId });
      onClose();
    },
    [updateBranch],
  );

  return <BranchForm defaultValues={data} onSubmit={onSubmit} className={className} />;
};

UpdateBranchForm.displayName = 'UpdateBranchForm';

export default UpdateBranchForm;
