import { useCallback, useLayoutEffect, useRef } from 'react';

const useEvent = <T>(handler: T): T => {
  const handlerRef = useRef<T | null>(null);

  useLayoutEffect(() => {
    handlerRef.current = handler;
  });

  return useCallback((...args: unknown[]) => {
    const fn = handlerRef.current;

    // @ts-expect-error ".current is always defined"
    return fn?.(...args);
  }, []) as T;
};

export default useEvent;
