export const getVisiblePages = (currentPageIndex: number, totalPages: number, blockSize = 1): Array<number | null> => {
  const items: Array<number | null> = [...Array(totalPages)].map((_, i) => i + 1);
  // ret if enough place

  if (items.length <= blockSize * 2 + 5) return items;

  const hasSeparatorLeft = currentPageIndex > blockSize * 2 + 1;
  const items2 = hasSeparatorLeft ? [1, null, ...items.slice(currentPageIndex - blockSize)] : items;

  const cpIndex = items2.findIndex((v) => v === currentPageIndex + 1);

  const hasSeparatorRight = totalPages > currentPageIndex + blockSize * 2 + 1;
  const items3 = hasSeparatorRight ? [...items2.slice(0, cpIndex + blockSize + 1), null, totalPages] : items2;

  return items3;
};
