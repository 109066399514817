import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { PaginationState } from '@tanstack/react-table';
import { ContactsPageResponse, useApiClient } from 'src/api';
import { SortingQuery } from 'src/api/types/shared/sorting';
import { DEFAULT_TABLE_PAGINATION } from 'src/constants/table';
import queryKeysFactory from 'src/store/queryKeysFactory';
import { ContactFilter } from 'src/types/filters';

export const useContacts = (
  filters: ContactFilter,
  sorting?: SortingQuery,
  pagination: PaginationState = DEFAULT_TABLE_PAGINATION,
): UseQueryResult<ContactsPageResponse> => {
  const client = useApiClient();

  const { queryKey } = queryKeysFactory.contacts.list._ctx.search(pagination, filters, sorting);

  return useQuery(
    queryKey,
    () =>
      client.contact.getContactList({
        ...filters,
        ...sorting,
        ...(pagination ?? DEFAULT_TABLE_PAGINATION),
      }),
    {
      refetchInterval: false,
      staleTime: 1000 * 60 * 5,
    },
  );
};
