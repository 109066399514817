import { FC } from 'react';
import useAllHeadquarters from 'src/api/hooks/queries/useAllHeadquarters';
import useAllVatTariffs from 'src/api/hooks/queries/useAllVatTariffs';
import Loader from 'src/components/utils/Loader';
import { Navigate } from 'react-router-dom';
import useAllOrderTypes from 'src/api/hooks/queries/useAllOrderTypes';
import useCurrentTenantConfig from 'src/api/hooks/queries/useCurrentTenantConfig';
import { OrderSchema } from '../ServiceForm/schema';
import { useNotificationSettings } from 'src/api/hooks/queries/useNotificationSettings';
import useWindowWidth from 'src/hooks/useWindowWidth';
import { isLgOrBigger } from 'src/helpers/screenSize';
import MobileAddServicePopup from './MobileAddServicePopup';
import DesktopAddServicePopup from './DesktopAddServicePopup';

export type AddServicePopupProps = {
  open: boolean;
  onClose: () => void;
  defaultValues?: Partial<OrderSchema>;
};

const AddServicePopup: FC<AddServicePopupProps> = ({ open, onClose, defaultValues }) => {
  const { data: vatTariffs, isLoading: isLoadingVatTariffs } = useAllVatTariffs();
  const { data: headquarters, isLoading: isLoadingHeadquarters } = useAllHeadquarters();
  const { data: orderTypes, isLoading: isLoadingOrderTypes } = useAllOrderTypes();
  const { data: config, isLoading: isLoadingConfig } = useCurrentTenantConfig();
  const { data: notificationSettings, isLoading: isLoadingNotificationSettings } = useNotificationSettings();

  const isLoading =
    isLoadingVatTariffs ||
    isLoadingHeadquarters ||
    isLoadingOrderTypes ||
    isLoadingConfig ||
    isLoadingNotificationSettings;

  const windowWidth = useWindowWidth();
  const isMobile = !isLgOrBigger(windowWidth);

  if (isLoading) return <Loader />;
  if (!vatTariffs || !headquarters || !orderTypes) return <Navigate to='/500' />;

  if (isMobile) {
    return (
      <MobileAddServicePopup
        open={open}
        onClose={onClose}
        isLoading={isLoading}
        defaultValues={defaultValues}
        config={config}
        orderTypes={orderTypes}
        vatTariffs={vatTariffs}
        headquarters={headquarters}
        notificationSettings={notificationSettings}
      />
    );
  }

  return (
    <DesktopAddServicePopup
      open={open}
      onClose={onClose}
      isLoading={isLoading}
      defaultValues={defaultValues}
      config={config}
      orderTypes={orderTypes}
      defaultShowCalendar={config?.defaultShowCalendarInOrderForm}
      vatTariffs={vatTariffs}
      headquarters={headquarters}
      notificationSettings={notificationSettings}
    />
  );
};

AddServicePopup.displayName = 'AddServicePopup';

export default AddServicePopup;
