import { FC, HTMLAttributes, useCallback } from 'react';
import RoutineServicesForm from '../RoutineServicesForm';
import useUpdateRoutineService from 'src/api/hooks/mutations/routineServices/useUpdateRoutineService';
import useRoutineService from 'src/api/hooks/queries/useRoutineService';
import { usePopupContext } from 'src/components/Popup/hook';
import { UpdateRoutineServicesRequest } from 'src/api';
import { SubmitHandler } from 'react-hook-form';

export type UpdateRoutineServicesFormProps = {
  routineServiceId: number;
} & HTMLAttributes<HTMLDivElement>;

const UpdateRoutineServicesForm: FC<UpdateRoutineServicesFormProps> = ({ className, routineServiceId }) => {
  const updateRoutineService = useUpdateRoutineService();
  const { data } = useRoutineService(routineServiceId);
  const { onClose } = usePopupContext();

  const onSubmit: SubmitHandler<UpdateRoutineServicesRequest> = useCallback(
    (data) => {
      updateRoutineService.mutate({ ...data, id: routineServiceId });
      onClose();
    },
    [updateRoutineService],
  );

  return <RoutineServicesForm defaultValues={data} onSubmit={onSubmit} className={className} />;
};

UpdateRoutineServicesForm.displayName = 'UpdateRoutineServicesForm';

export default UpdateRoutineServicesForm;
