import { ContactResponse } from 'src/api';
import { z } from 'zod';

export const ContactSchema = z.object({
  vat: z.string().nullable().default(null),
  companyName: z.string().min(1),
  email: z.string().email(),
  phoneNumber: z.string().nullable().default(null),
  city: z.string().min(1),
  street: z.string().min(1),
  zip: z.string().min(1),
  country: z.string().nullable().default(null),
  preferredCurrency: z.string().min(1),
  preferredLanguage: z.string().nullable().default(null),
  in: z.string().nullable().default(null),
  notification: z.boolean().default(false),
  deletedAt: z.boolean().nullable().default(null),
  internalCategory: z.string().nullable().default(null),
  contractNumber: z.string().nullable().default(null),
  category: z.nativeEnum(ContactResponse.category).default(ContactResponse.category.B2B),
});

export type ContactSchema = z.infer<typeof ContactSchema>;
