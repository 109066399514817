import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import ConfirmationPopup, { ConfirmationPopupProps } from 'src/components/common/ConfirmationPopup';
import { noop } from 'src/utils/commonUtils';
import useOpenPopup from './useOpenPopup';

const confirmationPopupRootId = 'ConfirmationPopupRoot';

export type ConfirmationPopupContextType = {
  props: Omit<ConfirmationPopupProps, 'id'>;
  setProps: Dispatch<SetStateAction<Omit<ConfirmationPopupProps, 'id'>>>;
};

export const ConfirmationPopupContext = createContext<ConfirmationPopupContextType>({
  props: {
    onOk: noop,
  },
  setProps: noop,
});

export const ConfirmationPopupProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [props, setProps] = useState<Omit<ConfirmationPopupProps, 'id'>>({
    onOk: noop,
  });
  const contextValue = useMemo(() => ({ props, setProps }), [props, setProps]);

  return (
    <ConfirmationPopupContext.Provider value={contextValue}>
      {children}
      <ConfirmationPopup id={confirmationPopupRootId} {...props} />
    </ConfirmationPopupContext.Provider>
  );
};

export type UseWithConfirmationPopupReturn = (props: Omit<ConfirmationPopupProps, 'id'>) => () => void;

export const useWithConfirmationPopup = (): UseWithConfirmationPopupReturn => {
  const { setProps } = useContext(ConfirmationPopupContext);
  const openPopup = useOpenPopup(confirmationPopupRootId);

  return useCallback(
    (props) => () => {
      setProps(props);
      openPopup();
    },
    [openPopup, setProps],
  );
};

export default useWithConfirmationPopup;
