import { nanoid } from 'nanoid';
import { curry, insert, nth, omit, remove } from 'ramda';
import { SectionSchemaResponse, SelectFieldSchemaResponse } from 'src/api';
import { FormBuilderFieldValue, FormBuilderSectionValue } from './context';

export const reorder = curry((start, end, list) => insert(end, nth(start, list), remove(start, 1, list)));

export const fieldFactory = (): FormBuilderFieldValue => {
  const id = nanoid();

  return {
    id,
    name: `Field ${id}`,
    type: SelectFieldSchemaResponse.type.INPUT,
    label: {
      id: `app.form_builder.field.${id}`,
      defaultMessage: `Field ${id}`,
    },
  };
};

export const sectionFactory = (): FormBuilderSectionValue => {
  const id = nanoid();

  return {
    id,
    name: `Section ${id}`,
    label: {
      id: `app.form_builder.section.${id}`,
      defaultMessage: `Section ${id}`,
    },
    fieldIds: [],
  };
};

export type FormBuilderStateToDtoOptions = {
  sections: FormBuilderSectionValue[];
  fields: FormBuilderFieldValue[];
};

export type FormBuilderStateToDtoReturn = {
  sections: SectionSchemaResponse[];
};

export const formBuilderStateToDto = ({
  sections,
  fields,
}: FormBuilderStateToDtoOptions): FormBuilderStateToDtoReturn => {
  const sectionsDto = sections.map((section) => ({
    ...omit(['fieldIds'], section),
    fields: fields
      .filter((field) => section.fieldIds.includes(field.id))
      .sort((a, b) => section.fieldIds.indexOf(a.id) - section.fieldIds.indexOf(b.id)),
  }));

  return {
    sections: sectionsDto,
  };
};

export type FormBuilderDtoToStateOptions = {
  sections: SectionSchemaResponse[];
};

export type FormBuilderDtoToStateReturn = {
  sections: FormBuilderSectionValue[];
  fields: FormBuilderFieldValue[];
};

export const formBuilderDtoToState = ({ sections }: FormBuilderDtoToStateOptions): FormBuilderDtoToStateReturn => {
  const fields = sections.reduce((acc, section) => [...acc, ...section.fields], [] as FormBuilderFieldValue[]);

  return {
    sections: sections.map((section) => ({
      ...omit(['fields'], section),
      fieldIds: section.fields.map((field) => field.id),
    })),
    fields,
  };
};
