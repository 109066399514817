import { FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

export type PageCardProps = {
  className?: string;
  children: ReactNode;
};

const PageCard: FC<PageCardProps> = ({ className, children }) => {
  return (
    <div className={twMerge('w-full p-4 rounded-lg shadow bg-white overflow-x-auto flex-shrink-0', className)}>
      {children}
    </div>
  );
};

export default PageCard;
