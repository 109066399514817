import { FC, HTMLAttributes, useCallback } from 'react';
import ConfirmationPopup from 'src/components/common/ConfirmationPopup';
import useDeleteContactBranch from 'src/api/hooks/mutations/contact/useDeleteContactBranch';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

export type DeleteBranchPopupProps = {
  //
} & HTMLAttributes<HTMLDivElement>;

const DeleteBranchPopup: FC<DeleteBranchPopupProps> = ({ className }) => {
  const contactId = Number(useParams().contactId);
  const branchId = Number(useParams().branchId);
  const navigate = useNavigate();

  const deleteBranch = useDeleteContactBranch();

  const onOk = useCallback(() => {
    deleteBranch.mutate({ contactId, branchId });
    navigate(`/contact/${contactId}`);
  }, [contactId, branchId, deleteBranch]);

  if (!contactId || !branchId) return <Navigate to='/404' />;

  return (
    <ConfirmationPopup
      className={className}
      id='delete_branch'
      onOk={onOk}
      title={<FormattedMessage id='app.contact_page.delete_branch.popup_title' />}
    />
  );
};

DeleteBranchPopup.displayName = 'DeleteBranchPopup';

export default DeleteBranchPopup;
