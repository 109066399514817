import { isNil } from 'ramda';
import { ContactResponse, CreateOrderProductRequest, CreateOrderRequest, OrderResponse } from 'src/api';
import { BranchSchema } from 'src/pages/ContactDetailPage/BranchForm/schema';
import { EmployeeSchema } from 'src/pages/ContactDetailPage/EmployeeForm/schema';
import { ProductBaseSchema } from 'src/pages/ContactDetailPage/ProductForm/schema';
import { ContactSchema } from 'src/pages/ContactPage/ContactForm/schema';
import { z } from 'zod';

const OrderOldProductSchema = z.object({
  createNewProduct: z.literal(false),
  productId: z.number(),
  routineServiceId: z.number().nullable(),
  productDescription: z.string().nullable(),
  quarante: z.boolean().default(false),
});

const OrderNewProductSchema = z.object({
  createNewProduct: z.literal(true),
  product: ProductBaseSchema,
  routineServiceId: z.number().nullable(),
  productDescription: z.string().nullable(),
  quarante: z.boolean().default(false),
  branchId: z.number().nullish(),
  customerId: z.number().nullish(),
});

export const OrderProductSchema = z.discriminatedUnion('createNewProduct', [
  OrderOldProductSchema,
  OrderNewProductSchema,
]);
export type OrderProductSchema = z.infer<typeof OrderProductSchema>;

const ManualNumberSchema = z.object({
  useManualOrderNumber: z.literal(true),
  manualOrderNumber: z.string().min(1),
});
const AutoNumberSchema = z.object({
  useManualOrderNumber: z.literal(false),
});
const OrderNumberSchema = z.discriminatedUnion('useManualOrderNumber', [ManualNumberSchema, AutoNumberSchema]);

const OldCompanySchema = z.object({
  createNewCompany: z.literal(false),
  companyId: z.number(),
});
export const NewCompanySchema = z.object({
  createNewCompany: z.literal(true),
  companyId: z.null().default(null),
  company: ContactSchema,
});
export type NewCompanySchema = z.infer<typeof NewCompanySchema>;

const OrderCompanySchema = z.discriminatedUnion('createNewCompany', [OldCompanySchema, NewCompanySchema]);

const OldBranchSchema = z
  .object({
    createNewBranch: z.literal(false),
    branchId: z.number(),
  })
  .passthrough();
const NewBranchSchema = z.object({
  createNewBranch: z.literal(true),
  branchId: z.null().default(null),
  branch: BranchSchema,
});
const OrderBranchSchema = z.discriminatedUnion('createNewBranch', [OldBranchSchema, NewBranchSchema]);

const OldEmployeeSchema = z.object({
  createNewEmployee: z.literal(false),
  employeeId: z.number().nullable().default(null),
});
const NewEmployeeSchema = z.object({
  createNewEmployee: z.literal(true),
  employeeId: z.null().default(null),
  employee: EmployeeSchema,
});
const OrderEmployeeSchema = z.discriminatedUnion('createNewEmployee', [OldEmployeeSchema, NewEmployeeSchema]);

const OrderBaseSchema = z.object({
  _date: z.date().nullable().default(null),
  _start: z.string().nullable().default(null),
  _end: z.string().nullable().default(null),
  clientOrderNumber: z.string().nullable().default(null),
  technicianIds: z.array(z.object({ technicianId: z.number() })),
  contactName: z.string().nullable().default(null),
  contactEmail: z.string().email().nullable().default(null),
  contactPhone: z.string().nullable().default(null),
  headquartersId: z.number(),
  vatTariffId: z.number(),
  orderTypeId: z.number(),
  emailTarget: z.nativeEnum(OrderResponse.emailTarget),
  datetime: z.string().nullable().default(null),
  endDatetime: z.string().nullable().default(null),
  invoiceNumber: z.string().nullable().default(null),
  previousOrderId: z.number().nullable().default(null),
  quarantee: z.boolean().default(false),
  shouldSendNotifications: z.boolean(),
  employee: OrderEmployeeSchema,
  number: OrderNumberSchema,
  company: OrderCompanySchema,
});
const OrderB2CSchema = OrderBaseSchema.extend({
  category: z.literal(ContactResponse.category.B2C),
  orderProducts: z.array(OrderProductSchema).min(0),
  branch: z.any().nullish(),
});
const OrderB2BSchema = OrderBaseSchema.extend({
  category: z.literal(ContactResponse.category.B2B),
  orderProducts: z.array(OrderProductSchema).min(1),
  branch: OrderBranchSchema,
});
export const OrderSchema = z.discriminatedUnion('category', [OrderB2CSchema, OrderB2BSchema]);
export type OrderSchema = z.infer<typeof OrderSchema>;

export function orderSchemaToCreateOrderRequest(data: OrderSchema): CreateOrderRequest {
  const { technicianIds, orderProducts, number, company, employee, branch, ...rest } = data;

  return {
    ...rest,
    ...number,
    ...company,
    ...branch,
    ...employee,
    orderProducts: orderProducts as unknown as CreateOrderProductRequest[],
    technicianIds: technicianIds.map(({ technicianId }) => technicianId).filter((i) => !isNil(i)) as number[],
  };
}
