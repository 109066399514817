/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type LicenceResponse = {
  id: number;
  module: LicenceResponse.module;
  isValid: boolean;
};
export namespace LicenceResponse {
  export enum module {
    SERVICES = 'services',
    ADMINISTRATION = 'administration',
    CONTACTS = 'contacts',
  }
}
