import { PlusCircleIcon } from '@heroicons/react/24/outline';
import { useMemo } from 'react';
import { FC } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { CreateOrderMaterialRequest, OrderResponse } from 'src/api';
import Button from 'src/components/Button';
import { isLgOrBigger } from 'src/helpers/screenSize';
import useWindowWidth from 'src/hooks/useWindowWidth';
import Material from '../../Material';
import SectionTitle from '../../SectionTitle';
import { FinishOrderFormValues } from '../schema';
import clsx from 'clsx';
import useCurrentTenantConfig from 'src/api/hooks/queries/useCurrentTenantConfig';

export type ProductFormProps = {
  orderProductIndex: number;
  order: OrderResponse;
};

const ProductMaterialsForm: FC<ProductFormProps> = ({ orderProductIndex, order }) => {
  const windowWidth = useWindowWidth();
  const { control } = useFormContext<FinishOrderFormValues>();
  const { data: tenantConfig } = useCurrentTenantConfig();
  const { fields, append, remove } = useFieldArray({ name: `products.${orderProductIndex}.materials`, control });
  const materials: CreateOrderMaterialRequest[] = useWatch<FinishOrderFormValues>({
    name: `products.${orderProductIndex}.materials`,
  });

  const materialsPrice = useMemo(
    () =>
      materials?.reduce(
        (materialsPrice, material) => materialsPrice + (material?.price ?? 0) * (material.quantity ?? 0),
        0,
      ),
    [materials],
  );
  const paymentMethodId = useWatch<FinishOrderFormValues>({ name: 'servicePaymentMethodId', control });

  const shouldHidePrice = Boolean(order.orderType?.slConfig?.hidePricesFor?.includes(paymentMethodId));

  return (
    <>
      <div className='flex items-center mb-4'>
        <SectionTitle>
          <FormattedMessage id='app.finish_order.section_title.materials' />
        </SectionTitle>
        <Button
          variant='cta'
          size='small'
          className='ml-2'
          onClick={() => append({ material: '', price: null, quantity: 1, unit: null, code: '' })}
        >
          <PlusCircleIcon className='h-6 mr-1' />
          <FormattedMessage id='app.buttons.add' />
        </Button>
      </div>

      {isLgOrBigger(windowWidth) && (
        <div
          className={clsx(
            'grid gap-x-6 items-center',
            tenantConfig?.materialTable?.informativeMaterialSuggetions
              ? 'grid-cols-[8fr,2fr,2fr,2fr,2fr,1fr]'
              : 'grid-cols-[5fr,2fr,2fr,2fr,2fr,1fr]',
          )}
        >
          <span>
            <FormattedMessage id='app.material.name' />
          </span>
          <span>
            <FormattedMessage id='app.material.code' defaultMessage='code' />
          </span>
          <span>
            <FormattedMessage id='app.common.quantity' defaultMessage='quantity' />
          </span>
          <span>
            <FormattedMessage id='app.material.unit' defaultMessage='unit' />
          </span>
          {!shouldHidePrice && (
            <span>
              <FormattedMessage id='app.material.value' defaultMessage='value' />
            </span>
          )}
        </div>
      )}
      <div className='w-full flex flex-col gap-y-2 '>
        {fields.map((field, index) => (
          <Material
            key={`${field.id}-${orderProductIndex}`}
            index={index}
            orderProductIndex={orderProductIndex}
            shouldHidePrice={shouldHidePrice}
            onDelete={() => remove(index)}
          />
        ))}
      </div>
      {!shouldHidePrice && (
        <div className='flex mb-16 mt-8'>
          <span>
            <FormattedMessage
              id='app.finish_order.product.materials_price'
              values={{
                price: materialsPrice,
              }}
            />
          </span>
        </div>
      )}
    </>
  );
};

export default ProductMaterialsForm;
