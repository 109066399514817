import { OrderResponse } from 'src/api';

export const getAllowedDeliveryTypes = (order: OrderResponse): OrderResponse.deliveryType[] => {
  if (order.orderType?.slConfig?.allowManualPrices)
    return [
      OrderResponse.deliveryType.MANUAL,
      OrderResponse.deliveryType.CALCULATED,
      OrderResponse.deliveryType.TARIFF,
    ];

  return [OrderResponse.deliveryType.CALCULATED, OrderResponse.deliveryType.TARIFF];
};
