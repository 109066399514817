import { FC } from 'react';
import Popup, { PopupProps } from 'src/components/Popup';
import useIsPopupOpen from 'src/hooks/popups/useIsPopupOpen';
import useClosePopup from 'src/hooks/popups/useClosePopup';
import { noop } from 'src/utils/commonUtils';
import { useCallback } from 'react';

export type AutoPopupProps = Omit<PopupProps, 'open' | 'onClose'> & {
  id: string;
  notClosable?: boolean;
  extraOnClose?: () => void;
};

const AutoPopup: FC<AutoPopupProps> = ({ id, notClosable, extraOnClose, ...rest }) => {
  const open = useIsPopupOpen(id);
  const onClose = useClosePopup(id);
  const handleClose = useCallback(() => {
    onClose();
    extraOnClose?.();
  }, [extraOnClose]);

  return <Popup {...rest} open={!!open} onClose={notClosable ? noop : handleClose} />;
};

AutoPopup.displayName = 'AutoPopup';

export default AutoPopup;
