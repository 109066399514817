import { useState } from 'react';

const useAutocompleteQueryState = (
  query: string | undefined,
  onQueryChange: ((query: string) => void) | undefined,
): [string, (query: string) => void] => {
  const [state, setState] = useState('');
  const hasQuery = query !== undefined && onQueryChange !== undefined;

  if (hasQuery) return [query, onQueryChange];
  return [state, setState];
};

export default useAutocompleteQueryState;
