import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { DeliveryTariffResponse } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

type QueryKey = (typeof queryKeysFactory.deliveryTariffs.list)['queryKey'];
type QueryOptions = Omit<
  UseQueryOptions<DeliveryTariffResponse[], unknown, DeliveryTariffResponse[], QueryKey>,
  'queryKey' | 'queryFn' | 'initialData'
>;

const useAllDeliveryTariffs = (options?: QueryOptions): UseQueryResult<DeliveryTariffResponse[]> => {
  const client = useApiClient();
  const { queryKey } = queryKeysFactory.deliveryTariffs.list;

  return useQuery(
    queryKey,
    async () => {
      const data = await client.deliveryTariffs.getAllDeliveryTariffs();
      return data;
    },
    {
      refetchInterval: false,
      staleTime: 1000 * 60 * 5,
      ...options,
    },
  );
};

export default useAllDeliveryTariffs;
