import clsx from 'clsx';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '../Button';
import { FunnelIcon, PencilIcon, PlusIcon } from '@heroicons/react/24/outline';
import useWindowWidth from 'src/hooks/useWindowWidth';
import { isMdOrBigger } from 'src/helpers/screenSize';
import PageTitle from '../PageTitle';

export type PageContentHeaderProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> & {
  title?: ReactNode;
  noLine?: boolean;
  primaryButtonText?: ReactNode;
  secondaryButtonText?: ReactNode;
  className?: string;
  onSecondaryButtonClick?: () => void;
  onPrimaryButtonClick?: () => void;
  hideSecondaryButton?: boolean;
  hidePrimaryButton?: boolean;
  isPrimaryDisabled?: boolean;
  primaryButtonType?: HTMLButtonElement['type'];
  mobilePrimaryButtonType?: 'new' | 'edit';
};

// TODO: refactor

const PageContentHeader: React.FC<PageContentHeaderProps> = ({
  className,
  primaryButtonText,
  secondaryButtonText,
  onPrimaryButtonClick: onCreate,
  onSecondaryButtonClick: onFilter,
  hideSecondaryButton,
  hidePrimaryButton,
  primaryButtonType,
  isPrimaryDisabled,
  mobilePrimaryButtonType,
  title,
}) => {
  const windowWidth = useWindowWidth();
  const isDesktop = isMdOrBigger(windowWidth);

  return (
    <>
      <div className={clsx(className, 'flex justify-between gap-y-4 flex-wrap w-full md:flex-row flex-col')}>
        <PageTitle>{title}</PageTitle>
        <div className='flex gap-x-4'>
          {!hideSecondaryButton && (
            <Button className='gap-x-2' variant='secondary' onClick={onFilter}>
              {secondaryButtonText ?? (
                <>
                  <FunnelIcon className='w-6 h-6' />
                  <FormattedMessage id='app.buttons.filter' defaultMessage='Filtrování' />
                </>
              )}
            </Button>
          )}
          {!hidePrimaryButton && (isDesktop || !mobilePrimaryButtonType) && (
            <Button type={primaryButtonType} onClick={onCreate} disabled={isPrimaryDisabled}>
              {primaryButtonText}
            </Button>
          )}
        </div>
      </div>
      {!hidePrimaryButton && !isDesktop && mobilePrimaryButtonType && (
        <Button
          size='small'
          className='z-50 w-14 h-14 rounded-full absolute bottom-20 right-4'
          onClick={onCreate}
          disabled={isPrimaryDisabled}
        >
          {mobilePrimaryButtonType === 'edit' ? (
            <PencilIcon width='2.25rem' height='2.25rem' />
          ) : (
            <PlusIcon width='2.75rem' height='2.75rem' />
          )}
        </Button>
      )}
    </>
  );
};

export default PageContentHeader;
