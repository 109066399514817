import { FC, useState } from 'react';
import {
  VatTariffResponse,
  HeadquartersResponse,
  OrderTypeListItemRepsonse,
  TenantConfigDto,
  OrderResponse,
} from 'src/api';
import AllTechniciansCalendar from 'src/pages/CalendarPage/components/AllTechniciansCalendar';
import { FormattedMessage } from 'react-intl';
import DoublePopup from 'src/components/DoublePopup';
import UpdateServiceForm from '../UpdateServiceForm';
import { parseISO } from 'date-fns';
import { GridEvent } from 'src/components/EventsGrid/types';

export type DesktopUpdateServicePopupProps = {
  open: boolean;
  onClose: () => void;
  defaultShowCalendar?: boolean;
  isLoading: boolean;
  vatTariffs: VatTariffResponse[];
  headquarters: HeadquartersResponse[];
  orderTypes: OrderTypeListItemRepsonse[];
  order: OrderResponse;
  config?: TenantConfigDto;
};

const DesktopUpdateServicePopup: FC<DesktopUpdateServicePopupProps> = ({
  open,
  order,
  onClose,
  isLoading,
  config,
  orderTypes,
  vatTariffs,
  headquarters,
  defaultShowCalendar,
}) => {
  const [potentialEvents, setPotentialEvents] = useState<GridEvent[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(() => {
    if (!order.datetime) return undefined;
    try {
      return parseISO(order.datetime);
    } catch (error) {
      console.error('Failed to parse order datetime', error);
      return undefined;
    }
  });

  const handleClose = (): void => {
    setSelectedDate(undefined);
    setPotentialEvents([]);
    onClose();
  };

  const [showSecondPanel, setShowSecondPanel] = useState(!!defaultShowCalendar);

  return (
    <DoublePopup
      open={open && !isLoading}
      disableCloseOnClickOutside
      disableCloseOnEscape
      onClose={handleClose}
      showSecondPanel={showSecondPanel}
      setShowSecondPanel={setShowSecondPanel}
      rightTitle={null}
      containerClassName='w-[45vw]'
      leftTitle={<FormattedMessage id='app.order_detail_page.update_order.popup_title' />}
      leftContent={
        <UpdateServiceForm
          config={config}
          order={order}
          orderTypes={orderTypes}
          vatTariffs={vatTariffs}
          headquarters={headquarters}
          onDateChange={setSelectedDate}
          onPotentialEventsChange={setPotentialEvents}
        />
      }
      rightContent={
        <AllTechniciansCalendar
          selectedDate={selectedDate ?? new Date()}
          potentialEvents={potentialEvents}
          disableTechnicianPreviewOnClick
          disableEventPreviewOnClick
          disableDnd
          disableGridClick
        />
      }
    />
  );
};

export default DesktopUpdateServicePopup;
