/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BatchUpdateTenantConfigField } from '../models/BatchUpdateTenantConfigField';
import type { CreateOrderTypeRequest } from '../models/CreateOrderTypeRequest';
import type { OrderTypeListItemRepsonse } from '../models/OrderTypeListItemRepsonse';
import type { OrderTypePageResponse } from '../models/OrderTypePageResponse';
import type { OrderTypeRepsonse } from '../models/OrderTypeRepsonse';
import type { SlConfigGenSchemaDto } from '../models/SlConfigGenSchemaDto';
import type { UpdateTenantConfigField } from '../models/UpdateTenantConfigField';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class OrderTypesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns void
   * @throws ApiError
   */
  public updateOrderNumberFormat({ id, numberFormat }: { id: number; numberFormat: string }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/order-types/update-number-format/{id}/{numberFormat}',
      path: {
        id: id,
        numberFormat: numberFormat,
      },
    });
  }
  /**
   * @returns void
   * @throws ApiError
   */
  public updateOrderTypeName({ id, name }: { id: number; name: string }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/order-types/update-name/{id}/{name}',
      path: {
        id: id,
        name: name,
      },
    });
  }
  /**
   * @returns OrderTypePageResponse
   * @throws ApiError
   */
  public getOrderTypes({
    pageSize,
    pageIndex,
    name,
    direction,
    orderBy,
  }: {
    pageSize: number;
    pageIndex: number;
    name?: string;
    direction?: 'ASC' | 'DESC';
    orderBy?: string;
  }): CancelablePromise<OrderTypePageResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/order-types',
      query: {
        name: name,
        direction: direction,
        orderBy: orderBy,
        pageSize: pageSize,
        pageIndex: pageIndex,
      },
    });
  }
  /**
   * @returns OrderTypeRepsonse
   * @throws ApiError
   */
  public createOrderType({
    requestBody,
  }: {
    requestBody: CreateOrderTypeRequest;
  }): CancelablePromise<OrderTypeRepsonse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/order-types',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns void
   * @throws ApiError
   */
  public resetOrderTypeConfig({ id }: { id: number }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/order-types/reset-config/{id}',
      path: {
        id: id,
      },
    });
  }
  /**
   * @returns void
   * @throws ApiError
   */
  public batchUpdateOrderTypeConfigFields({
    id,
    requestBody,
  }: {
    id: number;
    requestBody: BatchUpdateTenantConfigField;
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/order-types/batch/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns void
   * @throws ApiError
   */
  public updateOrderTypeConfigField({
    id,
    requestBody,
  }: {
    id: number;
    requestBody: UpdateTenantConfigField;
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/order-types/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns OrderTypeRepsonse
   * @throws ApiError
   */
  public getOrderType({ id }: { id: number }): CancelablePromise<OrderTypeRepsonse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/order-types/{id}',
      path: {
        id: id,
      },
    });
  }
  /**
   * @returns OrderTypeRepsonse
   * @throws ApiError
   */
  public updateOrderType({
    id,
    requestBody,
  }: {
    id: number;
    requestBody: CreateOrderTypeRequest;
  }): CancelablePromise<OrderTypeRepsonse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/order-types/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns OrderTypeListItemRepsonse
   * @throws ApiError
   */
  public getAllOrderTypes(): CancelablePromise<Array<OrderTypeListItemRepsonse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/order-types/all',
    });
  }
  /**
   * @returns OrderTypeRepsonse
   * @throws ApiError
   */
  public updateOrderTypeSlConfig({
    id,
    requestBody,
  }: {
    id: number;
    requestBody: SlConfigGenSchemaDto;
  }): CancelablePromise<OrderTypeRepsonse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/order-types/{id}/slConfig',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
