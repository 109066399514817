import { FC, HTMLAttributes } from 'react';
import clsx from 'clsx';
import WithLoader from 'src/components/utils/WithLoader';
import useUserProfile from 'src/api/hooks/queries/useUserProfile';
import FormDataRow from 'src/components/FormDataRow';
import { FormattedMessage } from 'react-intl';
import LanguageButtonGroup from 'src/components/LanguageButtonGroup';

export type ProfileSettingsProps = {
  //
} & HTMLAttributes<HTMLDivElement>;

// todo: refactor if there will be more data in user profile
const ProfileSettings: FC<ProfileSettingsProps> = ({ className }) => {
  const { isFetching } = useUserProfile();

  return (
    <WithLoader isLoading={isFetching}>
      <div className={clsx('grid gap-y-2', className)}>
        <h2 className=' text-3xl font-semibold text-gray-800 mb-8'>
          <FormattedMessage id='app.settings_page.personalization' />
        </h2>
        <div className={clsx(className)}>
          <FormDataRow label={<FormattedMessage id='app.common.language' />}>
            <LanguageButtonGroup />
          </FormDataRow>
        </div>
      </div>
    </WithLoader>
  );
};

ProfileSettings.displayName = 'ProfileSettings';

export default ProfileSettings;
