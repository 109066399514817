import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { ContactResponse } from 'src/api';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

const useDeleteContact = (): UseMutationResult<ContactResponse, unknown, number> => {
  const client = useApiClient();
  const queryClient = useQueryClient();

  return useMutation<ContactResponse, unknown, number>(
    async (contactId: number) => {
      const data = await client.contact.deleteContact({ id: Number(contactId) });
      return data as ContactResponse;
    },
    {
      onSuccess: (_, contactId) => {
        queryClient.invalidateQueries(queryKeysFactory.contacts.list.queryKey);
        queryClient.invalidateQueries(queryKeysFactory.contacts.detail(contactId).queryKey);
        toast(<FormattedMessage id='app.toasts.delete_success' defaultMessage='Záznam úspěšně smazan' />, {
          type: 'success',
        });
      },
      onError: () => {
        toast(<FormattedMessage id='app.toasts.delete_fail' defaultMessage='Chyba smazani záznamu' />, {
          type: 'error',
        });
      },
    },
  );
};

export default useDeleteContact;
