import { FC } from 'react';
import { CreateDeliveryTariffRequest, CreateWorkTariffRequest } from 'src/api';
import { useForm, FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import NewItemPopupFooter from 'src/components/common/NewItemPopupFooter';
import { NumberFieldControlled } from 'src/components/fields/NumberField';
import { TextFieldControlled } from 'src/components/fields/Textfield';
import { WorkTariffSchema } from './schema';
import { zodResolver } from '@hookform/resolvers/zod';

export type WorkTariffFormProps = {
  defaultValues?: Partial<CreateDeliveryTariffRequest>;
  onSubmit: (data: CreateDeliveryTariffRequest) => void;
  className?: string;
};

const DEFAULT_VALUES: Partial<CreateWorkTariffRequest> = {
  name: '',
  price: 0,
  priceEur: 0,
};

const WorkTariffForm: FC<WorkTariffFormProps> = ({ defaultValues, onSubmit }) => {
  const form = useForm<WorkTariffSchema>({
    defaultValues: { ...DEFAULT_VALUES, ...defaultValues },
    resolver: zodResolver(WorkTariffSchema),
  });

  const { handleSubmit, control } = form;

  return (
    <FormProvider {...form}>
      <div className='flex-col w-full'>
        <form className='flex flex-col w-full gap-y-4' onSubmit={handleSubmit(onSubmit)}>
          <TextFieldControlled
            control={control}
            name='name'
            asterisk
            label={<FormattedMessage id='app.delivery_tariff.name' />}
          />
          <NumberFieldControlled
            control={control}
            name='price'
            label={<FormattedMessage id='app.delivery_tariff.price' />}
          />
          <NumberFieldControlled
            control={control}
            name='priceEur'
            label={<FormattedMessage id='app.common.price_eur' />}
          />
          <NewItemPopupFooter />
        </form>
      </div>
    </FormProvider>
  );
};

WorkTariffForm.displayName = 'WorkTariffForm';

export default WorkTariffForm;
