import { FC } from 'react';
import { CreateDeliveryRateRequest } from 'src/api';
import { useForm, FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import NewItemPopupFooter from 'src/components/common/NewItemPopupFooter';
import { NumberFieldControlled } from 'src/components/fields/NumberField';
import { TextFieldControlled } from 'src/components/fields/Textfield';
import { zodResolver } from '@hookform/resolvers/zod';
import { DeliveryTariffSchema } from 'src/pages/DeliveryTariffsPage/DeliveryTariffForm/schema';

export type DeliveryRateFormProps = {
  defaultValues?: Partial<CreateDeliveryRateRequest>;
  onSubmit: (data: CreateDeliveryRateRequest) => void;
  className?: string;
};

const DeliveryRateForm: FC<DeliveryRateFormProps> = ({ defaultValues, onSubmit }) => {
  const form = useForm<CreateDeliveryRateRequest>({
    defaultValues: defaultValues,
    resolver: zodResolver(DeliveryTariffSchema),
  });

  const { handleSubmit, control } = form;

  return (
    <FormProvider {...form}>
      <div className='flex-col w-full'>
        <form className='flex flex-col w-full gap-y-4' onSubmit={handleSubmit(onSubmit)}>
          <TextFieldControlled
            control={control}
            name='name'
            asterisk
            label={<FormattedMessage id='app.delivery_tariff.name' />}
          />
          <NumberFieldControlled
            control={control}
            name='price'
            label={<FormattedMessage id='app.delivery_tariff.price' />}
          />
          <NumberFieldControlled
            control={control}
            name='priceEur'
            label={<FormattedMessage id='app.common.price_eur' />}
          />
          <NewItemPopupFooter />
        </form>
      </div>
    </FormProvider>
  );
};

DeliveryRateForm.displayName = 'DeliveryRateForm';

export default DeliveryRateForm;
