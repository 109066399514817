import { atom } from 'recoil';

export type JoyrideState = {
  stepIndex: number;
  run: boolean;
};

export const adminJoyrideState = atom<JoyrideState>({
  key: 'adminJoyride',
  default: {
    stepIndex: 0,
    run: false,
  },
});
