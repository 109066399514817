import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { useApiClient, RoutineServicesResponse, UpdateRoutineServicesRequest } from 'src/api';
import queryKeysFactory from 'src/store/queryKeysFactory';
import { WithId } from 'src/types/mutations';

const useUpdateRoutineService = (): UseMutationResult<
  RoutineServicesResponse,
  unknown,
  WithId<UpdateRoutineServicesRequest>
> => {
  const queryClient = useQueryClient();
  const client = useApiClient();

  return useMutation(
    async (routineService) =>
      client.routineServices.updateRoutineServices({ id: routineService.id, requestBody: routineService }),
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(queryKeysFactory.routineServices.detail(variables.id).queryKey);
        queryClient.invalidateQueries(queryKeysFactory.routineServices.list.queryKey);
        toast(<FormattedMessage id='app.toasts.update_success' />, {
          type: 'success',
        });
      },
      onError: () => {
        toast(<FormattedMessage id='app.toasts.update_fail' />, {
          type: 'error',
        });
      },
    },
  );
};

export default useUpdateRoutineService;
