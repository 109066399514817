/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateDeliveryTariffRequest } from '../models/CreateDeliveryTariffRequest';
import type { DeliveryTariffPageResponse } from '../models/DeliveryTariffPageResponse';
import type { DeliveryTariffResponse } from '../models/DeliveryTariffResponse';
import type { UpdateDeliveryTariffRequest } from '../models/UpdateDeliveryTariffRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class DeliveryTariffsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns DeliveryTariffPageResponse
   * @throws ApiError
   */
  public getDeliveryTariffs({
    pageSize,
    pageIndex,
    name,
    price,
    withDeleted,
    direction,
    orderBy,
  }: {
    pageSize: number;
    pageIndex: number;
    name?: string;
    price?: number;
    /**
     * With deleted
     */
    withDeleted?: boolean;
    direction?: 'ASC' | 'DESC';
    orderBy?: string;
  }): CancelablePromise<DeliveryTariffPageResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/delivery-tariffs',
      query: {
        name: name,
        price: price,
        withDeleted: withDeleted,
        direction: direction,
        orderBy: orderBy,
        pageSize: pageSize,
        pageIndex: pageIndex,
      },
    });
  }
  /**
   * @returns DeliveryTariffResponse
   * @throws ApiError
   */
  public createDeliveryTariff({
    requestBody,
  }: {
    requestBody: CreateDeliveryTariffRequest;
  }): CancelablePromise<DeliveryTariffResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/delivery-tariffs',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns DeliveryTariffResponse
   * @throws ApiError
   */
  public getAllDeliveryTariffs(): CancelablePromise<Array<DeliveryTariffResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/delivery-tariffs/all',
    });
  }
  /**
   * @returns DeliveryTariffResponse
   * @throws ApiError
   */
  public getDeliveryTariff({ id }: { id: number }): CancelablePromise<DeliveryTariffResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/delivery-tariffs/{id}',
      path: {
        id: id,
      },
    });
  }
  /**
   * @returns DeliveryTariffResponse
   * @throws ApiError
   */
  public updateDeliveryTariff({
    id,
    requestBody,
  }: {
    id: number;
    requestBody: UpdateDeliveryTariffRequest;
  }): CancelablePromise<DeliveryTariffResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/delivery-tariffs/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns void
   * @throws ApiError
   */
  public deleteDeliveryTariff({ id }: { id: number }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/delivery-tariffs/{id}',
      path: {
        id: id,
      },
    });
  }
  /**
   * @returns void
   * @throws ApiError
   */
  public restoreDeliveryTariff({ id }: { id: number }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/delivery-tariffs/{id}/restore',
      path: {
        id: id,
      },
    });
  }
}
