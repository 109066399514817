import clsx from 'clsx';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { OrderMaterialResponse, OrderTypeRepsonse } from 'src/api';
import { OrderMaterialColumn } from './types';

export type OrderMaterialsTableProps = {
  className?: string;
  materials: OrderMaterialResponse[];
  orderType?: OrderTypeRepsonse;
};

const columns: OrderMaterialColumn[] = [
  {
    id: 'material',
    header: () => <FormattedMessage id='app.material.name' />,
    renderCell: (row: OrderMaterialResponse) => row.material,
  },
  {
    id: 'quantity',
    header: () => <FormattedMessage id='app.common.quantity' />,
    renderCell: (row: OrderMaterialResponse) => row.quantity,
  },
  {
    id: 'unit',
    header: () => <FormattedMessage id='app.material.unit' />,
    renderCell: (row: OrderMaterialResponse) => row.unit,
  },
  {
    id: 'price',
    header: () => <FormattedMessage id='app.common.price' />,
    renderCell: (row: OrderMaterialResponse) => row.price,
    shouldHide: (orderType: OrderTypeRepsonse) => !!orderType?.slConfig?.hidePricesForMaterilas,
  },
];

const OrderMaterialsTable: FC<OrderMaterialsTableProps> = ({ materials, orderType, className }) => {
  const visibleColumns = orderType ? columns.filter((column) => !column.shouldHide?.(orderType)) : columns;

  return (
    <table className={clsx('w-full relative whitespace-nowrap', className)}>
      <thead className='sticky top-0 bg-gray-50'>
        <tr>
          {visibleColumns.map((column) => (
            <th key={column.id} className={clsx('text-left py-2 px-2 text-xl')}>
              <div className={clsx('flex flex-col h-full')}>
                <div className={clsx('flex items-center gap-x-2 select-none font-medium')}>{column.header()}</div>
              </div>
            </th>
          ))}
        </tr>
      </thead>

      <tbody>
        {materials.map((row) => (
          <tr
            key={row.id}
            className={clsx(
              'border-t border-gray-100 min-h-[85px]',
              'hover:border-t-primary hover:border-b hover:border-b-primary',
            )}
          >
            {visibleColumns.map((column) => (
              <td key={`${row.id}-${column.id}`} className='py-3 px-2 min-h-[85px] text-[1.075rem]  whitespace-normal'>
                {column.renderCell(row)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default OrderMaterialsTable;
