import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import AutoPopup from 'src/components/common/AutoPopup';
import AddTenantForm from '../AddTenantForm';

export type AddTenantPopupProps = {
  className?: string;
};

const AddTenantPopup: FC<AddTenantPopupProps> = ({ className }) => {
  return (
    <AutoPopup
      id='add_tenant'
      title={<FormattedMessage id='app.tenants_page.add_tenant.popup_title' />}
      className={className}
    >
      <AddTenantForm />
    </AutoPopup>
  );
};

AddTenantPopup.displayName = 'AddTenantPopup';

export default AddTenantPopup;
