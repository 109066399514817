import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { PaginationState } from '@tanstack/react-table';
import { StockOrderPageReponse } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import { DEFAULT_TABLE_PAGINATION } from 'src/constants/table';
import queryKeysFactory from 'src/store/queryKeysFactory';
import { StockOrderFilter } from 'src/types/filters';

const useStockOrders = (
  pagination: PaginationState = DEFAULT_TABLE_PAGINATION,
  filter: StockOrderFilter,
): UseQueryResult<StockOrderPageReponse> => {
  const apiClient = useApiClient();
  const { queryKey } = queryKeysFactory.stockOrders.list._ctx.search(filter, pagination);

  return useQuery(
    queryKey,
    async () => {
      const data = await apiClient.stockOrders.getStockOrders({ ...pagination, ...filter });
      return data;
    },
    {
      refetchInterval: false,
      staleTime: Infinity,
    },
  );
};

export default useStockOrders;
