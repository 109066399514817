import { PencilSquareIcon, PlusCircleIcon, TrashIcon } from '@heroicons/react/24/outline';
import { mdiDrag } from '@mdi/js';
import Icon from '@mdi/react';
import clsx from 'clsx';
import { nanoid } from 'nanoid';
import { FC, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { FormattedMessage } from 'react-intl';
import Button from 'src/components/Button';
import Popup from 'src/components/Popup';
import { useFormBuilderSectionState } from '../../context';
import FormBuilderFieldForm from '../FormBuilderFieldForm';
import FormBuilderFieldsList from '../FormBuilderFieldsList';
import FormBuilderSectionForm from '../FormBuilderSectionForm';

export type FormBuilderSectionProps = {
  className?: string;
  index: number;
  id: string;
};

const FormBuilderSection: FC<FormBuilderSectionProps> = ({ className, id, index }) => {
  const { section, deleteSection, addField, updateSection } = useFormBuilderSectionState(id);
  const [isAddFieldPopupOpen, setIsAddFieldPopupOpen] = useState(false);
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);

  return (
    <>
      <Draggable draggableId={id} index={index}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            className={clsx(className, 'border border-gray-300 bg-white rounded-lg min-h-[8rem] my-2')}
          >
            <div className='flex items-center justify-between w-full border-b border-gray-300 px-4 py-2'>
              <div className='flex items-center'>
                <div {...provided.dragHandleProps}>
                  <Icon path={mdiDrag} size={2} className={'text-primary cursor-move'} />
                </div>
                <span>{section.label?.defaultMessage ?? section.name ?? section.id}</span>
                <Button size='small' variant='cta' onClick={() => setIsAddFieldPopupOpen(true)}>
                  <PlusCircleIcon className='w-6 h-6' />
                </Button>
              </div>
              <div className='flex items-center gap-1'>
                <Button size='small' variant='cta' onClick={() => setIsEditPopupOpen(true)}>
                  <PencilSquareIcon className='w-6 h-6' />
                </Button>
                <Button size='small' variant='cta' onClick={deleteSection} disabled={!!section.fieldIds.length}>
                  <TrashIcon className='w-6 h-6' />
                </Button>
              </div>
            </div>
            <div className='w-full px-4 py-2 mb-2'>
              <FormBuilderFieldsList fields={section.fields} id={id} />
            </div>
          </div>
        )}
      </Draggable>
      <Popup
        onClose={() => setIsAddFieldPopupOpen(false)}
        open={isAddFieldPopupOpen}
        title={<FormattedMessage id='app.order_type_wizzard.add_field' />}
      >
        <FormBuilderFieldForm
          defaultValues={{
            id: nanoid(),
            name: nanoid(),
          }}
          onSubmit={(data) => {
            addField(data);
            setIsAddFieldPopupOpen(false);
          }}
        />
      </Popup>
      <Popup
        onClose={() => setIsEditPopupOpen(false)}
        open={isEditPopupOpen}
        title={<FormattedMessage id='app.order_type_wizzard.edit_section' />}
      >
        <FormBuilderSectionForm
          defaultValues={{
            name: section.name,
            label: section.label.defaultMessage,
            labelAsTh: section.labelAsTh,
            noThead: section.noThead,
            showIfName: section.showIfName ?? undefined,
            showIfValues: section.showIfValues ?? undefined,
          }}
          onSubmit={(data) => {
            updateSection(data);
            setIsEditPopupOpen(false);
          }}
        />
      </Popup>
    </>
  );
};

FormBuilderSection.displayName = 'FormBuilderSection';

export default FormBuilderSection;
