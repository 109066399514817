import clsx from 'clsx';
import { FC, useState } from 'react';
import { UserResponse } from 'src/api';
import useUser from 'src/api/hooks/queries/useUser';
import WithLoader from 'src/components/utils/WithLoader';
import useLastDefined from 'src/hooks/useLastDefined';
import TechnicianInfoPopup from '../TechnicianInfoPopup';
import TechniciansBarItem from '../TechniciansBarItem';
import UnassignedTechniciansBarItem from '../UnassignedTechniciansBarItem';

export type TechniciansBarProps = {
  className?: string;
  technicians: UserResponse[];
  disableTechnicianPreviewOnClick?: boolean;
};

const TechniciansBar: FC<TechniciansBarProps> = ({ className, technicians, disableTechnicianPreviewOnClick }) => {
  const [technicianIdToLoad, setTechnicianIdToLoad] = useState<number | null>(null);
  const { data, isFetching } = useUser(technicianIdToLoad ?? 0, {
    enabled: !!technicianIdToLoad,
  });

  const technicianData = useLastDefined(data);

  const handleTechnicianClick = async (technicianId: number): Promise<void> => {
    setTechnicianIdToLoad(technicianId);
  };

  return (
    <WithLoader isLoading={isFetching}>
      <div
        className={clsx(
          'flex rounded-l overflow-hidden flex-col justify-between border-l border-t border-b border-r border-neutral-300',
          className,
        )}
      >
        <div className='h-[2.75rem] min-w-40 border-b-2 border-neutral-300 bg-neutral-100'></div>
        <UnassignedTechniciansBarItem />
        {technicians.map((technician, index) => (
          <TechniciansBarItem
            key={technician.id}
            onClick={!disableTechnicianPreviewOnClick ? () => handleTechnicianClick(technician.id) : undefined}
            user={technician}
            className={clsx(index < technicians.length - 1 && 'border-b-2 border-neutral-200')}
          />
        ))}
      </div>
      <TechnicianInfoPopup
        data={technicianData ?? ({} as UserResponse)}
        onClose={() => setTechnicianIdToLoad(null)}
        open={!!technicianIdToLoad && !!data}
      />
    </WithLoader>
  );
};

TechniciansBar.displayName = 'TechniciansBar';

export default TechniciansBar;
