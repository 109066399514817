import { FC } from 'react';
import useOrderType from 'src/api/hooks/queries/useOrderType';
import OrderTypeSettingsForm, { OrderTypeSettingsFormProps } from './OrderTypeSettingsForm';
import Loader from 'src/components/utils/Loader';

export type OrderTypeSettingsFormContainerProps = Omit<OrderTypeSettingsFormProps, 'data'>;

const OrderTypeSettingsFormContainer: FC<OrderTypeSettingsFormContainerProps> = (props) => {
  const { orderTypeId } = props;
  const { data, isLoading } = useOrderType(orderTypeId);

  if (isLoading) return <Loader />;
  if (!data) return null;

  return <OrderTypeSettingsForm {...props} data={data} />;
};

export default OrderTypeSettingsFormContainer;
