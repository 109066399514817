import { VatTariffResponse } from 'src/api';

type TariffBase = Pick<VatTariffResponse, 'tariffValue' | 'name'>;

export default function getFullTariffName(tariff: TariffBase): string {
  const { tariffValue, name } = tariff;
  if (!name) return `${tariffValue}%`;

  return `${name} (${tariffValue}%)`;
}
