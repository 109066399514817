import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { CreateTenantRequest, TenantWithLicencesResponse } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

const useAddTenant = (): UseMutationResult<TenantWithLicencesResponse, unknown, CreateTenantRequest> => {
  const client = useApiClient();
  const queryClient = useQueryClient();

  return useMutation<TenantWithLicencesResponse, unknown, CreateTenantRequest>(
    async (tenant: CreateTenantRequest) => {
      const data = await client.tenant.createTenant({ formData: tenant });
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeysFactory.tenant.list.queryKey);
        toast(<FormattedMessage id='app.toasts.add_success' />, {
          type: 'success',
        });
      },
      onError: () => {
        toast(<FormattedMessage id='app.toasts.add_fail' />, {
          type: 'error',
        });
      },
    },
  );
};

export default useAddTenant;
