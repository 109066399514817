import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { FC, Fragment } from 'react';
import { useRecoilState } from 'recoil';
import { isSidebarOpenState } from 'src/store/atoms/sidebar';
import SidePanel from '../SidePanel';
import { FormattedMessage } from 'react-intl';

export type MobileMenuProps = {
  //
};

const MobileMenu: FC<MobileMenuProps> = () => {
  const [sidebarOpen, setSidebarOpen] = useRecoilState(isSidebarOpenState);

  return (
    <Transition.Root show={sidebarOpen} as={Fragment}>
      <Dialog as='div' className='relative z-50 lg:hidden' onClose={() => setSidebarOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter='transition-opacity ease-linear duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='transition-opacity ease-linear duration-300'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-900/80' />
        </Transition.Child>

        <div className='fixed inset-0 flex'>
          <Transition.Child
            as={Fragment}
            enter='transition ease-in-out duration-300 transform'
            enterFrom='-translate-x-full'
            enterTo='translate-x-0'
            leave='transition ease-in-out duration-300 transform'
            leaveFrom='translate-x-0'
            leaveTo='-translate-x-full'
          >
            <Dialog.Panel className='relative  flex w-full max-w-xs flex-1'>
              <div>
                <div className='absolute left-full top-0 flex w-16 justify-center pt-5'>
                  <button type='button' className='-m-2.5 p-2.5' onClick={() => setSidebarOpen(false)}>
                    <span className='sr-only'>
                      <FormattedMessage id='app.button.close_sidebar' />
                    </span>
                    <XMarkIcon className='h-6 w-6 text-white' aria-hidden='true' />
                  </button>
                </div>
              </div>
              <SidePanel onItemClick={() => setSidebarOpen(false)} />
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

MobileMenu.displayName = 'MobileMenu';

export default MobileMenu;
