import { Combobox, Transition } from '@headlessui/react';
import { ChevronUpDownIcon, CheckIcon, TrashIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import {
  ForwardedRef,
  ChangeEventHandler,
  Fragment,
  ReactNode,
  useState,
  forwardRef,
  Ref,
  useMemo,
  useRef,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { Controller, FieldPath, FieldValues, UseControllerProps } from 'react-hook-form';
import { match } from 'ts-pattern';
import useEvent from 'src/hooks/useEvent';
import useAutocompleteQueryState from './useAutocompleteQueryState';
import { twMerge } from 'tailwind-merge';
import FieldLabel from '../FieldLabel';
import FieldError from '../FieldError';
import { getFieldClassName } from '../helpers';

export type AutocompleteProps<TOption, TValue extends string | number | null> = {
  className?: string;
  inputClassName?: string;
  clearButtonType?: 'icon' | 'text';
  disabled?: boolean;
  error?: ReactNode;
  externalFilter?: boolean;
  extraOnChange?: (value: TValue, option: TOption | null) => void;
  getOptionLabel: (option: TOption) => string;
  getOptionValue: (option: TOption) => TValue;
  hideButton?: boolean;
  id?: string;
  asterisk?: boolean;
  label?: ReactNode;
  labelClassName?: string;
  name?: string;
  notFoundMessage?: ReactNode;
  onChange: (value: TValue) => void;
  onQueryChange?: (query: string) => void;
  options: TOption[];
  placeholder?: string;
  query?: string;
  showClearButton?: boolean;
  value: TValue;
};

export const Autocomplete = forwardRef(
  <T, TValue extends string | number | null>(
    {
      className,
      inputClassName,
      disabled,
      value,
      onChange,
      options,
      labelClassName,
      getOptionLabel,
      getOptionValue,
      placeholder,
      query: _query,
      onQueryChange: _onQueryChange,
      extraOnChange,
      notFoundMessage,
      hideButton,
      name,
      error,
      label,
      showClearButton,
      clearButtonType = 'text',
      externalFilter,
    }: AutocompleteProps<T, TValue>,
    ref: ForwardedRef<HTMLInputElement>,
  ): JSX.Element => {
    const [query, onQueryChange] = useAutocompleteQueryState(_query, _onQueryChange);
    const handleInputChange = useEvent<ChangeEventHandler<HTMLInputElement>>((event) =>
      onQueryChange(event.target.value),
    );
    const handleChange = useEvent((newValue: TValue) => {
      const option = options.find((i) => getOptionValue(i) === newValue);
      onChange(newValue);
      extraOnChange?.(newValue, option ?? null);
    });

    const displayValue = (value: TValue): string => {
      const option = options?.find((option) => getOptionValue(option) === value) ?? null;
      return option ? getOptionLabel(option) : '';
    };

    // TODO: somehow make this type safe, so handlers wont be called with wrong types? Aad | null to handlers?
    const handelClear = useEvent(() => {
      onChange(null as TValue);
      extraOnChange?.(null as TValue, null);
    });

    const getOptionLabelRef = useRef(getOptionLabel);
    const filteredOptions = useMemo(() => {
      if (externalFilter) return options;
      const filtered = options?.filter((i) => getOptionLabelRef.current(i).toLowerCase().includes(query.toLowerCase()));
      return filtered ?? [];
    }, [options, query, externalFilter]);

    return (
      <label className={clsx('flex-col w-full', className)}>
        {label && (
          <FieldLabel asterisk className={labelClassName}>
            {label}
          </FieldLabel>
        )}
        <Combobox value={value ?? null} onChange={handleChange} disabled={disabled}>
          <div className='relative'>
            <div className={clsx('relative w-full cursor-default rounded', showClearButton && 'join')}>
              <Combobox.Input
                ref={ref}
                name={name}
                className={getFieldClassName({ disabled, error }, inputClassName)}
                displayValue={displayValue}
                onChange={handleInputChange}
                placeholder={placeholder}
              />
              {!hideButton && !showClearButton && (
                <Combobox.Button className='absolute inset-y-0 right-0 flex items-center pr-2'>
                  <ChevronUpDownIcon className='h-5 w-5 text-base-content' aria-hidden='true' />
                </Combobox.Button>
              )}
              {showClearButton &&
                match(clearButtonType)
                  .with('text', () => (
                    <button type='button' className='btn join-item input-bordered rounded-r-btn' onClick={handelClear}>
                      <FormattedMessage id='app.buttons.clear' />
                    </button>
                  ))
                  .with('icon', () => (
                    <button
                      type='button'
                      className='btn join-item input-bordered rounded-r-btn px-1'
                      onClick={handelClear}
                    >
                      <TrashIcon className='w-6' />
                    </button>
                  ))
                  .exhaustive()}
            </div>
            <Transition
              as={Fragment}
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Combobox.Options className='ring-base-300 bg-white absolute z-[3000] mt-1 max-h-60 min-w-full overflow-auto rounded-box py-1 text-base shadow-lg ring-1 ring-opacity-5 focus:outline-none rounded'>
                {filteredOptions.length === 0 && query !== '' ? (
                  <div className='relative cursor-default select-none px-4 py-2'>
                    {notFoundMessage ?? <FormattedMessage id='app.autocomplete.nothing_found' />}
                  </div>
                ) : (
                  filteredOptions.map((option) => (
                    <Combobox.Option
                      key={getOptionValue(option)}
                      className={({ active }) =>
                        twMerge('relative select-none py-2 pl-10 pr-4', active && 'cursor-pointer bg-base-100')
                      }
                      value={getOptionValue(option)}
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={clsx(
                              'block min-w-full whitespace-nowrap',
                              selected ? 'font-medium' : 'font-normal',
                            )}
                          >
                            {getOptionLabel(option)}
                          </span>
                          {selected ? (
                            <span className={'absolute inset-y-0 left-0 flex items-center pl-3'}>
                              <CheckIcon className='h-5 w-5' aria-hidden='true' />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Combobox.Option>
                  ))
                )}
              </Combobox.Options>
            </Transition>
          </div>
        </Combobox>

        {error && <FieldError>{error}</FieldError>}
      </label>
    );
  },
) as <T, TValue extends string | number | null>(
  props: AutocompleteProps<T, TValue> & { ref?: Ref<HTMLInputElement> },
) => JSX.Element;

type AutocompleteControlledProps<
  TOption,
  TValue extends string | number | null,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<AutocompleteProps<TOption, TValue>, 'query' | 'onQueryChange' | 'value' | 'onChange' | 'name' | 'error'> & {
  control: UseControllerProps<TFieldValues, TName>['control'];
  name: TName;
};

export function AutocompleteControlled<
  TOption,
  TValue extends string | number | null,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  ...autocompleteProps
}: AutocompleteControlledProps<TOption, TValue, TFieldValues, TName>): JSX.Element {
  const [query, setQuery] = useState('');

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete<TOption, TValue>
          {...autocompleteProps}
          {...field}
          name={name}
          query={query}
          onQueryChange={setQuery}
          error={error?.message}
        />
      )}
    />
  );
}
