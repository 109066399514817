import { useLayoutEffect, useState } from 'react';

const useWindowWidth = (): number => {
  const [width, setWidth] = useState(0);

  useLayoutEffect(() => {
    const updateWidth = (): void => setWidth(window.innerWidth);
    updateWidth();

    window.addEventListener('resize', updateWidth);

    return () => window.removeEventListener('resize', updateWidth);
  }, []);
  return width;
};

export default useWindowWidth;
