import { FC } from 'react';
import clsx from 'clsx';
import useAddVatTariff from 'src/api/hooks/mutations/vatTariffs/useAddVatTariff';
import { usePopupContext } from 'src/components/Popup/hook';
import { useCallback } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { CreateVatTariffRequest } from 'src/api';
import VatTariffForm from '../VatTariffForm';

export type AddVatTariffFormProps = {
  className?: string;
};

const AddVatTariffForm: FC<AddVatTariffFormProps> = ({ className }) => {
  const addVatTariff = useAddVatTariff();
  const { onClose } = usePopupContext();

  const onSubmit: SubmitHandler<CreateVatTariffRequest> = useCallback(
    (data) => {
      addVatTariff.mutate(data);
      onClose();
    },
    [addVatTariff, onClose],
  );

  return <VatTariffForm className={clsx(className)} onSubmit={onSubmit} />;
};

AddVatTariffForm.displayName = 'AddVatTariffForm';

export default AddVatTariffForm;
