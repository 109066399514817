import { FC } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import ImagesUploadField from 'src/components/ImagesUploadField';
import SectionTitle from '../../SectionTitle';
import { FinishOrderFormValues } from '../schema';
import UploadedImagePreview from 'src/components/UploadedImagePreview';
import useDeleteFileMutation from 'src/api/hooks/mutations/files/useDeleteFileMutation';
import { sanitizeForm } from '../helpers';
import useSaveOrderForm from 'src/api/hooks/mutations/savedOrderForms/useSaveOrderForm';

export type ProductPhotosFormProps = {
  orderProductIndex: number;
  orderId: number;
};

const ProductPhotosForm: FC<ProductPhotosFormProps> = ({ orderProductIndex, orderId }) => {
  const { control, getValues } = useFormContext<FinishOrderFormValues>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `products.${orderProductIndex}.imagesIds`,
  });
  const deleteFileMutation = useDeleteFileMutation();
  const handleRemoveImage = (fileId: number, index: number): void => {
    deleteFileMutation.mutate(
      { fileId },
      {
        onSuccess: () => remove(index),
      },
    );
  };
  const saveOrderForm = useSaveOrderForm();
  // const [selectedImage, setSelectedImage] = useState<File | null>(null);

  // const handleRemoveImage = (index: number): void =>
  //   setValue(`products.${orderProductIndex}.images`, remove(index, 1, images));

  return (
    <>
      <div className='flex flex-col'>
        <SectionTitle className='mt-16 mb-4'>
          <FormattedMessage id='app.finish_order.photo' />
        </SectionTitle>
        <ImagesUploadField
          onUpload={(fileId) => {
            append({ fileId });
            const form = getValues();
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const clearForm = sanitizeForm(form as any);
            const jsonString = JSON.stringify(clearForm);

            saveOrderForm.mutate({
              orderId,
              jsonString,
            });
          }}
          addImagesOnChange
          buttonClassName='w-16'
          className='mb-4'
        />
        {fields?.length > 0 && (
          <div className='flex flex-wrap gap-16 w-full mb-6'>
            {fields?.map(({ fileId }, index) => (
              <UploadedImagePreview key={fileId} fileId={fileId} onClose={() => handleRemoveImage(fileId, index)} />
            ))}
          </div>
        )}
      </div>
      {/* <Popup open={!!selectedImage} onClose={() => setSelectedImage(null)}> */}
      {/*   <div className='flex w-full h-full'> */}
      {/*     {selectedImage && ( */}
      {/*       <img */}
      {/*         className='object-contain w-full h-full cursor-zoom-in' */}
      {/*         src={window.URL.createObjectURL(selectedImage)} */}
      {/*       /> */}
      {/*     )} */}
      {/*   </div> */}
      {/* </Popup> */}
    </>
  );
};

export default ProductPhotosForm;
