import { TrashIcon } from '@heroicons/react/24/outline';
import { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import useRoutineService from 'src/api/hooks/queries/useRoutineService';
import BackIcon from 'src/components/Icons/BackIcon';
import PageContentHeader from 'src/components/PageContentHeader';
import Popup from 'src/components/Popup';
import Loader from 'src/components/utils/Loader';
import Button from 'src/components/Button';
import RoutineServicesPreview from '../RoutineServicesPreview/RoutineServicesPreview';
import UpdateRoutineServicesForm from '../UpdateRoutineServicesForm';
import useOpenPopup from 'src/hooks/popups/useOpenPopup';
import DeleteRoutineServicePopup from '../DeleteRoutineServicePopup';
import useGetIsModuleValid from 'src/hooks/useGetIsModuleValid';
import { Modules } from 'src/types/commonTypes';
import PageCard from 'src/components/PageCard';

export type RoutineServicesDetailPageProps = {
  //
};

const RoutineServicesDetailPage: FC<RoutineServicesDetailPageProps> = () => {
  const getIsModuleValid = useGetIsModuleValid();
  const routineServiceId = Number(useParams().routineServiceId);
  const { isLoading, data } = useRoutineService(routineServiceId);
  const navigate = useNavigate();
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openDeletePopup = useOpenPopup('delete_routine_service');

  if (!getIsModuleValid(Modules.ADMINISTRATION)) return <Navigate to='/' />;
  if (isLoading) return <Loader />;
  if (!data) return <Navigate to='/404' />;

  return (
    <>
      <PageContentHeader
        mobilePrimaryButtonType='edit'
        className='my-4'
        title={
          <span className='flex'>
            <FormattedMessage id='app.routine_services_page.detail_title' />
            <Button variant='cta' size='small' onClick={openDeletePopup}>
              <TrashIcon className='h-6' />
            </Button>
          </span>
        }
        primaryButtonText={<FormattedMessage id='app.buttons.edit' defaultMessage='Upravit' />}
        secondaryButtonText={
          <>
            <BackIcon />
            <FormattedMessage id='app.buttons.back' defaultMessage='Zpět' />
          </>
        }
        onPrimaryButtonClick={() => setIsPopupOpen(true)}
        onSecondaryButtonClick={() => navigate(-1)}
      />
      <PageCard>
        <RoutineServicesPreview data={data} />
      </PageCard>
      <Popup
        title={<FormattedMessage id='app.routine_services_detail_page.update_routine_service.popup_title' />}
        open={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
      >
        <UpdateRoutineServicesForm routineServiceId={routineServiceId} />
      </Popup>
      <DeleteRoutineServicePopup />
    </>
  );
};

RoutineServicesDetailPage.displayName = 'RoutineServicesDetailPage';

export default RoutineServicesDetailPage;
