import { FC } from 'react';
import clsx from 'clsx';
import { WorkTariffResponse } from 'src/api';
import ParametersRow from 'src/components/common/ParametersRow/ParametersRow';
import { FormattedMessage } from 'react-intl';

export type WorkTariffPreviewProps = {
  className?: string;
  data: WorkTariffResponse;
};

const WorkTariffPreview: FC<WorkTariffPreviewProps> = ({ data, className }) => {
  return (
    <div className={clsx('flex flex-col w-full', className)}>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.common.name' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.name}</ParametersRow.Value>
      </ParametersRow>

      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.common.price' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.price}</ParametersRow.Value>
      </ParametersRow>

      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.common.price_eur' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.priceEur}</ParametersRow.Value>
      </ParametersRow>

      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.common.active' />
        </ParametersRow.Label>
        <ParametersRow.Value>
          {data.deletedAt ? <FormattedMessage id='app.common.no' /> : <FormattedMessage id='app.common.yes' />}
        </ParametersRow.Value>
      </ParametersRow>
    </div>
  );
};

WorkTariffPreview.displayName = 'WorkTariffPreview';

export default WorkTariffPreview;
