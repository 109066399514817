import clsx from 'clsx';
import { FC, PropsWithChildren } from 'react';

export type SectionProps = {
  className?: string;
  noMb?: boolean;
};

const Section: FC<PropsWithChildren<SectionProps>> = ({ className, children, noMb }) => (
  <div
    className={clsx(
      'grid grid-cols-1 gap-y-4 md:flex md:gap-x-12 lg:gap-x-12 xl:gap-x-12 2xl:gap-x-12 lg:grid-cols-2 xl:grid-cols-2',
      !noMb && 'mb-16',
      className,
    )}
  >
    {children}
  </div>
);

export default Section;
