import clsx from 'clsx';
import React, { HTMLAttributes } from 'react';
import { FormattedMessage } from 'react-intl';
import { BranchResponse } from 'src/api';
import ParametersRow from 'src/components/common/ParametersRow';

export type BranchPreviewProps = {
  data: BranchResponse;
  noTitle?: boolean;
  noLastLine?: boolean;
} & HTMLAttributes<HTMLDivElement>;

const BranchPreview: React.FC<BranchPreviewProps> = ({ data, noTitle, noLastLine, className }) => {
  return (
    <div className={clsx(className, 'flex flex-col w-full')}>
      {!noTitle && (
        <ParametersRow.Title>
          <FormattedMessage id='app.branch.detail_title' defaultMessage='Detail pobocky' />
        </ParametersRow.Title>
      )}
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.branch.name' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.name}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.common.city' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.city}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.common.street' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.street}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.common.zip' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.zip}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.common.phone' defaultMessage='Telefon' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.phoneNumber}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.common.email' defaultMessage='Email' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.email}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.common.branch.ico' defaultMessage='IČO' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.vat}</ParametersRow.Value>
      </ParametersRow>
      {/* <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.branch.coordinates' defaultMessage='Souřadnice' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.}</ParametersRow.Value>
      </ParametersRow> */}
      <ParametersRow noBorder={noLastLine}>
        <ParametersRow.Label>
          <FormattedMessage id='app.branch.contact_person' defaultMessage='Kontaktní osoba' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.contactPerson}</ParametersRow.Value>
      </ParametersRow>
    </div>
  );
};

export default BranchPreview;
