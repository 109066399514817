/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChangePreferredLanguage } from '../models/ChangePreferredLanguage';
import type { ChangePrefferedColumnsRequest } from '../models/ChangePrefferedColumnsRequest';
import type { UserProfileResponse } from '../models/UserProfileResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class UserProfileService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns UserProfileResponse
   * @throws ApiError
   */
  public getUserProfile(): CancelablePromise<UserProfileResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/userProfile',
    });
  }
  /**
   * @returns UserProfileResponse
   * @throws ApiError
   */
  public updatePrefferedLanguage({
    requestBody,
  }: {
    requestBody: ChangePreferredLanguage;
  }): CancelablePromise<UserProfileResponse> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/userProfile/preferred-language',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns UserProfileResponse
   * @throws ApiError
   */
  public updatePrefferedColumns({
    requestBody,
  }: {
    requestBody: ChangePrefferedColumnsRequest;
  }): CancelablePromise<UserProfileResponse> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/userProfile/preferred-columns',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns void
   * @throws ApiError
   */
  public markJoyrideAsSeen(): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/userProfile/mark-joyride-as-seen',
    });
  }
}
