import { FC, HTMLAttributes, useCallback } from 'react';
import UserForm from '../UserForm';
import useAddUser from 'src/api/hooks/mutations/user/useAddUser';
import { SubmitHandler } from 'react-hook-form';
import { usePopupContext } from 'src/components/Popup/hook';
import { UserSchema } from '../UserForm/schema';

export type AddUserFormProps = {
  //
} & HTMLAttributes<HTMLDivElement>;

const AddUserForm: FC<AddUserFormProps> = ({ className }) => {
  const addUser = useAddUser();

  const { onClose } = usePopupContext();

  const onSubmit: SubmitHandler<UserSchema> = useCallback(
    (data) => {
      addUser.mutate(data);
      onClose();
    },
    [addUser, onClose],
  );

  return <UserForm onSubmit={onSubmit} className={className} />;
};

AddUserForm.displayName = 'AddUserForm';

export default AddUserForm;
