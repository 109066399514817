import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { TenantWithLicencesResponse } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

const useTenant = (id: number): UseQueryResult<TenantWithLicencesResponse> => {
  const client = useApiClient();
  const { queryKey } = queryKeysFactory.tenant.detail(id);

  return useQuery(
    queryKey,
    async () => {
      const data = await client.tenant.getTenant({ id });
      return data;
    },
    {
      staleTime: Infinity,
    },
  );
};

export default useTenant;
