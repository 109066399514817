import { FC, HTMLAttributes } from 'react';
import AutoPopup from 'src/components/common/AutoPopup';
import { FormattedMessage } from 'react-intl';
import AddProductForm from '../AddProductForm';
import { ContactResponse } from 'src/api';

export type AddProductPopupProps = {
  fixedBranchId?: number | null;
  contactId: number;
  category: ContactResponse.category;
} & HTMLAttributes<HTMLDivElement>;

export const ADD_PRODUCT_POPUP_ID = 'add_product';

const AddProductPopup: FC<AddProductPopupProps> = ({ category, className, contactId, fixedBranchId }) => {
  return (
    <AutoPopup
      id={ADD_PRODUCT_POPUP_ID}
      title={<FormattedMessage id='app.contact_page.add_product.popup_title' />}
      className={className}
    >
      <AddProductForm category={category} contactId={contactId} fixedBranchId={fixedBranchId} />
    </AutoPopup>
  );
};

AddProductPopup.displayName = 'AddProductPopup';

export default AddProductPopup;
