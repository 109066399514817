import { CalendarDaysIcon, LinkIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import React, { Fragment, HTMLAttributes, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { OrderResponse, OrderStatusResponse, UserResponse } from 'src/api';
import HyperLink from 'src/components/common/HyperLink';
import ParametersRow from 'src/components/common/ParametersRow';
import RSoRString from 'src/components/FinishFormGenTemplate/components/RSoRString';
import OrderProductPreview from 'src/components/OrderProductPreview';
import ServiceSummaryPreview from 'src/components/ServiceSummaryPreview';
import StatusLabel from 'src/components/StatusLabel';
import useGetIsRoleAllowed from 'src/hooks/useGetIsRoleAllowed';
import { MessageId } from 'src/types/commonTypes';
import { statusesWithLinkToPdf } from './helpers';
import useCurrentTenantConfig from 'src/api/hooks/queries/useCurrentTenantConfig';
import AddressLink from 'src/components/AdressLink/AddressLink';
import { createAddressQuery } from 'src/helpers/createAddressQuery';
import { ArrowLongRightIcon } from '@heroicons/react/24/solid';

export type ServicePreviewProps = {
  data: OrderResponse;
  extraTitle?: ReactNode;
} & HTMLAttributes<HTMLDivElement>;

const ServicePreview: React.FC<ServicePreviewProps> = ({ data, extraTitle, className }) => {
  const getIsRoleAllowed = useGetIsRoleAllowed();
  const { data: config } = useCurrentTenantConfig();

  const customerEmail =
    data.emailTarget === OrderResponse.emailTarget.BRANCH && data.branch?.email
      ? data.branch?.email
      : data.company.email;
  const usedEmail = data.contactEmail ?? customerEmail;

  return (
    <div className={clsx('flex flex-col', className)}>
      <ParametersRow.Title className='flex gap-x-2'>
        <FormattedMessage id='app.order.section_title.order' />
        {extraTitle}
      </ParametersRow.Title>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.order.status' />
        </ParametersRow.Label>
        <ParametersRow.Value>
          {data?.status && <StatusLabel name={data.status as OrderStatusResponse.name} />}
        </ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.customer.category' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.category}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.order.headquarters' />
        </ParametersRow.Label>
        <ParametersRow.Value>
          {data.headquarters?.companyName} {data.headquarters?.companyStreet} {data.headquarters?.companyCity}
        </ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.order.datetime' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.datetime && format(parseISO(data.datetime), 'dd.MM.yyyy')}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.order.start_time' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.datetime && format(parseISO(data.datetime), 'HH:mm')}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.order.end_time' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.endDatetime && format(parseISO(data.endDatetime), 'HH:mm')}</ParametersRow.Value>
      </ParametersRow>
      {[OrderStatusResponse.name.COMPLETED, OrderStatusResponse.name.INVOICED].includes(
        data.status?.toUpperCase() as OrderStatusResponse.name,
      ) && (
        <>
          <ParametersRow>
            <ParametersRow.Label>
              <FormattedMessage id='app.order.real_datetime' />
            </ParametersRow.Label>
            <ParametersRow.Value>
              {data.realStart && format(parseISO(data.realStart), 'dd.MM.yyyy')}
            </ParametersRow.Value>
          </ParametersRow>
          <ParametersRow>
            <ParametersRow.Label>
              <FormattedMessage id='app.order.real_start_time' />
            </ParametersRow.Label>
            <ParametersRow.Value>{data.realStart && format(parseISO(data.realStart), 'HH:mm')}</ParametersRow.Value>
          </ParametersRow>
          <ParametersRow>
            <ParametersRow.Label>
              <FormattedMessage id='app.order.real_end_time' />
            </ParametersRow.Label>
            <ParametersRow.Value>{data.realEnd && format(parseISO(data.realEnd), 'HH:mm')}</ParametersRow.Value>
          </ParametersRow>
        </>
      )}
      {data.previousOrderId && (
        <ParametersRow>
          <ParametersRow.Label>
            <FormattedMessage id='app.order.previous_order' />
          </ParametersRow.Label>
          <ParametersRow.Value>
            <HyperLink href={`/order/${data.previousOrderId}`}>
              <LinkIcon className='ml-3 w-7' />
            </HyperLink>
          </ParametersRow.Value>
        </ParametersRow>
      )}
      {data.status?.toUpperCase() === OrderStatusResponse.name.UNSUCCESSFUL && (
        <ParametersRow>
          <ParametersRow.Label>
            <FormattedMessage id='app.order.description_note' />
          </ParametersRow.Label>
          <ParametersRow.Value>{data.descriptionNote}</ParametersRow.Value>
        </ParametersRow>
      )}
      {data.deliveryType && (
        <ParametersRow>
          <ParametersRow.Label>
            <FormattedMessage id='app.finish_order.delivery_type' />
          </ParametersRow.Label>
          <ParametersRow.Value>
            <FormattedMessage id={`app.finish_order.delivery_type.${data.deliveryType.toLowerCase()}` as MessageId} />
          </ParametersRow.Value>
        </ParametersRow>
      )}
      {data.deliveryTariff && (
        <ParametersRow>
          <ParametersRow.Label>
            <FormattedMessage id='app.finish_order.delivery_tariff' />
          </ParametersRow.Label>
          <ParametersRow.Value>{data.deliveryTariff.name}</ParametersRow.Value>
        </ParametersRow>
      )}
      {data.workTariff && (
        <ParametersRow>
          <ParametersRow.Label>
            <FormattedMessage id='app.finish_order.work_tariff' />
          </ParametersRow.Label>
          <ParametersRow.Value>{data.workTariff.name}</ParametersRow.Value>
        </ParametersRow>
      )}
      {data.orderType && (
        <ParametersRow>
          <ParametersRow.Label>
            <FormattedMessage id='app.order.order_type' />
          </ParametersRow.Label>
          <ParametersRow.Value>{data.orderType.name}</ParametersRow.Value>
        </ParametersRow>
      )}
      {statusesWithLinkToPdf.includes(data.status as OrderStatusResponse.name) && (
        <>
          <ParametersRow>
            <ParametersRow.Label>
              <FormattedMessage id='app.finish_order.service_total_price_without_vat' />
            </ParametersRow.Label>
            <ParametersRow.Value>{data.serviceTotalPrice}</ParametersRow.Value>
          </ParametersRow>
          {data.materialPrice !== null && (
            <ParametersRow>
              <ParametersRow.Label>
                <FormattedMessage id='app.finish_order.material_price.without_vat' />
              </ParametersRow.Label>
              <ParametersRow.Value>{data.materialPrice}</ParametersRow.Value>
            </ParametersRow>
          )}
          <ParametersRow>
            <ParametersRow.Label>
              <FormattedMessage id='app.finish_order.preferred_currency' />
            </ParametersRow.Label>
            <ParametersRow.Value>{data.preferredCurrency}</ParametersRow.Value>
          </ParametersRow>
        </>
      )}
      {!config?.disableVat && data.vatTariff && (
        <ParametersRow>
          <ParametersRow.Label>
            <FormattedMessage id='app.order.vat_tariff' />
          </ParametersRow.Label>
          <ParametersRow.Value>{data?.vatTariff?.name ?? `${data.vatTariff?.tariffValue}%`}</ParametersRow.Value>
        </ParametersRow>
      )}

      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.order.invoice_number' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.invoiceNumber}</ParametersRow.Value>
      </ParametersRow>

      <ParametersRow className='mb-20'>
        <ParametersRow.Label>
          <FormattedMessage id='app.order.contact_email' />
        </ParametersRow.Label>
        <ParametersRow.Value>{usedEmail}</ParametersRow.Value>
      </ParametersRow>

      <ServiceSummaryPreview data={data} className='mb-20' />

      {config?.orderForm?.showContactInfo && (
        <>
          <ParametersRow.Title>
            <FormattedMessage id='app.order.section_title.contact_info' />
          </ParametersRow.Title>

          <ParametersRow>
            <ParametersRow.Label>
              <FormattedMessage id='app.order.contact_name' />
            </ParametersRow.Label>
            <ParametersRow.Value>{data.contactName}</ParametersRow.Value>
          </ParametersRow>
          <ParametersRow>
            <ParametersRow.Label>
              <FormattedMessage id='app.order.contact_email' />
            </ParametersRow.Label>
            <ParametersRow.Value>{data.contactEmail}</ParametersRow.Value>
          </ParametersRow>
          <ParametersRow className='mb-20'>
            <ParametersRow.Label>
              <FormattedMessage id='app.order.contact_phone' />
            </ParametersRow.Label>
            <ParametersRow.Value>{data.contactPhone}</ParametersRow.Value>
          </ParametersRow>
        </>
      )}

      {data.orderType?.slConfig?.sections?.map((section) => (
        <Fragment key={section.id}>
          <ParametersRow.Title>
            <RSoRString label={section.label} />
          </ParametersRow.Title>
          {section.fields.map((field, index) => (
            <ParametersRow key={field.id} className={clsx(index === section.fields.length - 1 && 'mb-20')}>
              <ParametersRow.Label>
                <RSoRString label={field.label} />
              </ParametersRow.Label>
              <ParametersRow.Value>
                {field.type === 'checkbox' ? (
                  data.slConfigOutput?.[field.name] ? (
                    <FormattedMessage id='app.common.yes' />
                  ) : (
                    <FormattedMessage id='app.common.no' />
                  )
                ) : (
                  data.slConfigOutput?.[field.name]
                )}
              </ParametersRow.Value>
            </ParametersRow>
          ))}
        </Fragment>
      ))}

      <ParametersRow.Title>
        <FormattedMessage id='app.order.section_title.technicians' />
      </ParametersRow.Title>
      {data.technicians.length === 0 && (
        <span className='mb-20'>
          <FormattedMessage id='app.order.no_technicians' />
        </span>
      )}
      {data.technicians.map((technician, index) => (
        <ParametersRow key={technician.id} className={clsx(index === data.technicians.length - 1 && 'mb-20')}>
          <ParametersRow.Label>
            <FormattedMessage id='app.order.technician_n' values={{ value: index + 1 }} />
          </ParametersRow.Label>
          <ParametersRow.Value>
            {technician?.profile?.name}
            {getIsRoleAllowed([
              UserResponse.role.SYSTEM_ADMINISTRATOR,
              UserResponse.role.ADMINISTRATOR,
              UserResponse.role.ADMINISTRATOR_TECHNICIAN,
              UserResponse.role.DISPATCHER_TECHNICIAN,
              UserResponse.role.DISPATCHER,
            ]) &&
              !!technician.id && (
                <div className='flex'>
                  <HyperLink href={`/calendar/single/${technician.id}/month`}>
                    <CalendarDaysIcon className='ml-3 w-7' />
                  </HyperLink>
                  <HyperLink href={`/admin/user/${technician.id}`}>
                    <LinkIcon className='ml-3 w-7' />
                  </HyperLink>
                </div>
              )}
          </ParametersRow.Value>
        </ParametersRow>
      ))}

      <ParametersRow.Title>
        <FormattedMessage id='app.order.section_title.contact' />
      </ParametersRow.Title>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.contact.company_name' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.company?.companyName}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.common.ico' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.company?.in}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.common.vat' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.company?.vat}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.common.city' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.company?.city}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.common.street' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.company.street}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.common.zip' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.company?.zip}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.common.phone' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.company?.phoneNumber}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.common.email' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.company?.email}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.common.preferred_currency' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.company?.preferredCurrency}</ParametersRow.Value>
      </ParametersRow>
      {config?.contactForm?.showInternalCategory && (
        <ParametersRow>
          <ParametersRow.Label>
            <FormattedMessage id='app.contact.internal_category' />
          </ParametersRow.Label>
          <ParametersRow.Value>{data.company?.internalCategory}</ParametersRow.Value>
        </ParametersRow>
      )}
      {data.category === OrderResponse.category.B2C && (
        <AddressLink
          className='mb-20 text-primary'
          adressQuery={createAddressQuery({
            city: data.company?.city ?? '',
            street: data.company?.street ?? '',
          })}
        >
          <ParametersRow>
            <ParametersRow.Label>
              <FormattedMessage id='app.common.navigate_to' />
            </ParametersRow.Label>
            <ParametersRow.Value>
              <ArrowLongRightIcon className='w-6 h-6' />
            </ParametersRow.Value>
          </ParametersRow>
        </AddressLink>
      )}

      {data.category === OrderResponse.category.B2B && data.branch && (
        <>
          <ParametersRow.Title className='mt-20'>
            <FormattedMessage id='app.order.section_title.branch' />
          </ParametersRow.Title>
          <ParametersRow>
            <ParametersRow.Label>
              <FormattedMessage id='app.branch.name' />
            </ParametersRow.Label>
            <ParametersRow.Value>
              <span>{data.branch?.name}</span>
              <HyperLink href={`/contact/${data.companyId}/branch/${data.branchId}`}>
                <LinkIcon className='ml-3 w-7' />
              </HyperLink>
            </ParametersRow.Value>
          </ParametersRow>
          <ParametersRow>
            <ParametersRow.Label>
              <FormattedMessage id='app.common.city' />
            </ParametersRow.Label>
            <ParametersRow.Value>{data.branch?.city}</ParametersRow.Value>
          </ParametersRow>
          <ParametersRow>
            <ParametersRow.Label>
              <FormattedMessage id='app.common.street' />
            </ParametersRow.Label>
            <ParametersRow.Value>{data.branch?.street}</ParametersRow.Value>
          </ParametersRow>
          <ParametersRow>
            <ParametersRow.Label>
              <FormattedMessage id='app.common.zip' />
            </ParametersRow.Label>
            <ParametersRow.Value>{data.branch?.zip}</ParametersRow.Value>
          </ParametersRow>
          <ParametersRow>
            <ParametersRow.Label>
              <FormattedMessage id='app.branch.contact_person' />
            </ParametersRow.Label>
            <ParametersRow.Value>
              <span>{data.employee?.name}</span>
              <HyperLink href={`/contact/${data.companyId}/employee/${data.employeeId}`}>
                <LinkIcon className='ml-3 w-7' />
              </HyperLink>
            </ParametersRow.Value>
          </ParametersRow>
          <ParametersRow>
            <ParametersRow.Label>
              <FormattedMessage id='app.common.email' />
            </ParametersRow.Label>
            <ParametersRow.Value>{data.branch?.email}</ParametersRow.Value>
          </ParametersRow>
          <AddressLink
            className='mb-20 text-primary'
            adressQuery={createAddressQuery({
              city: data.branch?.city ?? data.company?.city ?? '',
              street: data.branch?.street ?? data.company?.street ?? '',
            })}
          >
            <ParametersRow>
              <ParametersRow.Label>
                <FormattedMessage id='app.common.navigate_to' />
              </ParametersRow.Label>
              <ParametersRow.Value>
                <ArrowLongRightIcon className='w-6 h-6' />
              </ParametersRow.Value>
            </ParametersRow>
          </AddressLink>

          <ParametersRow.Title>
            <FormattedMessage id='app.order.section_title.employee' />
          </ParametersRow.Title>
          <ParametersRow>
            <ParametersRow.Label>
              <FormattedMessage id='app.employee.name' />
            </ParametersRow.Label>
            <ParametersRow.Value>{data.employee?.name}</ParametersRow.Value>
          </ParametersRow>
          <ParametersRow>
            <ParametersRow.Label>
              <FormattedMessage id='app.common.phone' />
            </ParametersRow.Label>
            <ParametersRow.Value>{data.employee?.phoneNumber}</ParametersRow.Value>
          </ParametersRow>
          <ParametersRow className='mb-20'>
            <ParametersRow.Label>
              <FormattedMessage id='app.common.email' />
            </ParametersRow.Label>
            <ParametersRow.Value>{data.employee?.email}</ParametersRow.Value>
          </ParametersRow>
        </>
      )}

      <ParametersRow.Title>
        <FormattedMessage id='app.order.section_title.products' />
      </ParametersRow.Title>
      {data.orderProducts.map((orderProduct, index) => (
        <OrderProductPreview
          key={index}
          order={data}
          // productSlConfigOutput={data.slConfigOutput?.products?.[index]}
          data={orderProduct}
          index={index}
          contactId={data.companyId}
        />
      ))}
    </div>
  );
};

export default ServicePreview;
