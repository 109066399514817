import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { RSResponse } from 'src/api';
import { MessageId } from 'src/types/commonTypes';

export type RSoRStringProps = {
  label: string | RSResponse;
};

const RSoRString: FC<RSoRStringProps> = ({ label }) => {
  if (!label) return null;
  if (typeof label === 'string') return <>{label}</>;
  return <FormattedMessage id={label.id as MessageId} defaultMessage={label?.defaultMessage} />;
};

RSoRString.displayName = 'RSoRString';

export default RSoRString;
