import { HTMLAttributes, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import BackIcon from 'src/components/Icons/BackIcon';
import PageContentHeader from 'src/components/PageContentHeader';
import useOpenPopup from 'src/hooks/popups/useOpenPopup';
import useIsCurrentUserAllowedToEdit from 'src/hooks/useIsCurrentUserAllowedToEdit';

type ContactDetailHeaderProps = HTMLAttributes<HTMLDivElement> & {
  //
};

const ContactDetailHeader: React.FC<ContactDetailHeaderProps> = () => {
  const isCurrentUserAllowedToEdit = useIsCurrentUserAllowedToEdit();
  const navigate = useNavigate();
  const openPopup = useOpenPopup('update_contact');
  const back = useCallback(() => navigate(-1), [navigate]);

  return (
    <PageContentHeader
      mobilePrimaryButtonType='edit'
      title={
        <span>
          <FormattedMessage id='app.contact_page.title' defaultMessage='Kontakty' />
          {' - '}
          <FormattedMessage id='app.contact_page.detail_title' defaultMessage='Detail kontaktu' />{' '}
        </span>
      }
      primaryButtonText={<FormattedMessage id='app.buttons.edit' />}
      hidePrimaryButton={!isCurrentUserAllowedToEdit}
      secondaryButtonText={
        <>
          <BackIcon />
          <FormattedMessage id='app.buttons.back' defaultMessage='Zpět' />
        </>
      }
      onPrimaryButtonClick={openPopup}
      onSecondaryButtonClick={back}
    />
  );
};

export default ContactDetailHeader;
