import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { BranchListResponse } from 'src/api';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

type QueryKey = ReturnType<typeof queryKeysFactory.branches.listForContact>['queryKey'];
type QueryOptions = Omit<
  UseQueryOptions<BranchListResponse[], unknown, BranchListResponse[], QueryKey>,
  'queryKey' | 'queryFn' | 'initialData'
>;

const useContactBranches = (contactId: number, options: QueryOptions = {}): UseQueryResult<BranchListResponse[]> => {
  const client = useApiClient();
  const { queryKey } = queryKeysFactory.branches.listForContact(contactId);

  return useQuery<BranchListResponse[], unknown, BranchListResponse[], QueryKey>(
    queryKey,
    async () => {
      const data = await client.branch.getContactBranches({ contactId: Number(contactId) });
      return data;
    },
    {
      refetchInterval: false,
      staleTime: 1000 * 60 * 5,
      ...options,
    },
  );
};

export default useContactBranches;
