import { FC } from 'react';
import { XYCoord } from 'react-dnd';
import useGridCellDimensions from '../../hooks/useGridCellDimensions';
import useEventToGrid from '../../hooks/useEventToGrid';
import { GridEvent } from '../../types';
import Event from '../Event';

export type EventPreviewProps = {
  event: GridEvent;
  initialOffset: XYCoord;
  currentOffset: XYCoord;
};

// TODO: add snap
const EventPreview: FC<EventPreviewProps> = ({ initialOffset, currentOffset, event }) => {
  const eventToGrid = useEventToGrid();
  const { columnWidth, rowHeight } = useGridCellDimensions();

  if (!event) return null;

  const xOffset = initialOffset.x - currentOffset.x;
  const yOffset = initialOffset.y - currentOffset.y;
  const { row, rowSpan, col, colSpan } = eventToGrid(event);

  return (
    <Event
      {...event}
      row={row}
      rowSpan={rowSpan}
      col={col}
      colSpan={colSpan}
      style={{
        border: 'red',
        position: 'absolute',
        top: initialOffset.y,
        left: initialOffset.x,
        transform: `translate(${-xOffset}px, ${-yOffset}px)`,
        width: columnWidth * colSpan,
        height: rowSpan * rowHeight,
      }}
    />
  );
};

export default EventPreview;
