import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { PreferredColumnRequest, UserPreferredColumResponse, UserProfileResponse } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

const useUpdatePreferredColumns = (): UseMutationResult<UserProfileResponse, unknown, UserPreferredColumResponse[]> => {
  const client = useApiClient();
  const queryClient = useQueryClient();

  return useMutation<UserProfileResponse, unknown, UserPreferredColumResponse[]>(
    async (columns: PreferredColumnRequest[]) => {
      const data = await client.userProfile.updatePrefferedColumns({
        requestBody: {
          columns,
        },
      });

      return data;
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(queryKeysFactory.users.current.queryKey);
      },
    },
    //
  );
};

export default useUpdatePreferredColumns;
