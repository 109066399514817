import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { CreateHeadquatersRequest, HeadquartersResponse } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';
import { WithId } from 'src/types/mutations';

const useUpdateHeadquarters = (): UseMutationResult<
  HeadquartersResponse,
  unknown,
  WithId<CreateHeadquatersRequest>
> => {
  const queryClient = useQueryClient();
  const client = useApiClient();

  return useMutation<HeadquartersResponse, unknown, WithId<CreateHeadquatersRequest>>(
    async (req: WithId<CreateHeadquatersRequest>) => {
      const data = await client.headquarters.updateHeadquarters({ id: req.id, formData: req });
      return data as HeadquartersResponse;
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(queryKeysFactory.headquarters.list.queryKey);
        queryClient.invalidateQueries(queryKeysFactory.headquarters.detail(data.id).queryKey);
        toast(<FormattedMessage id='app.toasts.add_success' />, {
          type: 'success',
        });
      },
      onError: () => {
        toast(<FormattedMessage id='app.toasts.add_fail' />, {
          type: 'error',
        });
      },
    },
  );
};

export default useUpdateHeadquarters;
