import { ChangeEventHandler, FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Badge from 'src/components/common/Badge';
import ImportCard from './ImportCard';
import { toast } from 'react-toastify';
import saveBlob from 'src/helpers/saveBlob';
import { MaterialSchema } from 'src/pages/MaterialPage/MaterialForm/schema';
import { utils, write, read } from 'xlsx';
import { useApiClient } from 'src/api';
import { z } from 'zod';

const ImportMaterialsCard: FC = () => {
  const intl = useIntl();
  const apiClient = useApiClient();

  const handleCreateMaterialTemplate = (): void => {
    const workbook = utils.book_new();
    const worksheet = utils.json_to_sheet([], {
      header: [
        intl.formatMessage({ id: 'app.material.name' }),
        intl.formatMessage({ id: 'app.material.code' }),
        intl.formatMessage({ id: 'app.material.supplier_code' }),
        intl.formatMessage({ id: 'app.material.value' }),
        intl.formatMessage({ id: 'app.common.price_eur' }),
      ],
    });
    utils.book_append_sheet(workbook, worksheet);
    const buffer = write(workbook, { type: 'buffer', bookType: 'xlsx' });
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveBlob(blob, 'materials_template.xlsx');
  };

  const handleMaterialImport: ChangeEventHandler<HTMLInputElement> = (e) => {
    const file = e.target.files?.[0];
    if (!file) return;
    const reader = new FileReader();

    reader.onload = async (e) => {
      const wb = read(e.target?.result as ArrayBuffer);
      const data = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], {
        header: ['materialName', 'materialCode', 'supplierCode', 'materialValue', 'materialValueEur'],
      });
      const cleanData = data.slice(1).map(convertDataToMaterialSchema);
      if (!cleanData.length) {
        toast(<FormattedMessage id='app.toast.no_data' />, {
          type: 'error',
        });
        return;
      }
      const schema = z.array(MaterialSchema);
      try {
        const validated = schema.parse(cleanData);
        if (!validated) return;
        await apiClient.material.createMaterialList({
          requestBody: validated,
        });
        toast(<FormattedMessage id='app.toast.import_success' />, {
          type: 'success',
        });
      } catch (err) {
        console.error(err);
        toast(<FormattedMessage id='app.toast.validation_error' />, {
          type: 'error',
        });
      }
    };

    reader.readAsArrayBuffer(file);
  };

  return (
    <ImportCard
      title={<FormattedMessage id='app.imports_page.cards.material.title' />}
      description={<FormattedMessage id='app.imports_page.cards.material.description' />}
      handleDownloadTemplate={handleCreateMaterialTemplate}
      handleImport={handleMaterialImport}
      badge={
        <>
          <div className='flex gap-x-2'>
            <Badge text={<FormattedMessage id='app.common.excel' />} color='green' />
          </div>
        </>
      }
    />
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function convertDataToMaterialSchema(data: any): Partial<MaterialSchema> {
  return {
    materialValue: Number(data.materialValue),
    materialValueEur: data.materialValueEur ? Number(data.materialValueEur) : null,
    materialName: data.materialName?.toString() ?? null,
    materialCode: data.materialCode?.toString() ?? null,
    supplierCode: data.supplierCode?.toString() ?? null,
  };
}

export default ImportMaterialsCard;
