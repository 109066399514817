import { Column } from '@tanstack/react-table';
import { PropsWithoutRef, useCallback } from 'react';
import Dropdown, { DropdownProps } from 'src/components/fields/Dropdown';

export type DropdownFilterProps<T> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  column: Column<any, unknown>;
  defaultValue?: T;
} & Omit<DropdownProps<T>, 'value' | 'onChange'>;

const DropdownFilter = <T,>({
  defaultValue,
  column,
  items,
  label,
  ...rest
}: PropsWithoutRef<DropdownFilterProps<T>>): JSX.Element => {
  const { setFilterValue, getFilterValue } = column;

  const handleChange = useCallback((value: T) => setFilterValue(value), []);

  return (
    <Dropdown
      items={items}
      label={label}
      value={(getFilterValue() ?? defaultValue) as T}
      onChange={handleChange}
      {...rest}
    />
  );
};

export default DropdownFilter;
