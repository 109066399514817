import { FC } from 'react';
import clsx from 'clsx';
import { DeliveryTariffResponse } from 'src/api';
import { FormattedMessage } from 'react-intl';
import ParametersRow from 'src/components/common/ParametersRow';

export type DeliveryTariffPreviewProps = {
  className?: string;
  data: DeliveryTariffResponse;
};

const DeliveryTariffPreview: FC<DeliveryTariffPreviewProps> = ({ data, className }) => {
  return (
    <div className={clsx('flex flex-col w-full', className)}>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.delivery_tariff.name' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.name}</ParametersRow.Value>
      </ParametersRow>

      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.delivery_tariff.price' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.price}</ParametersRow.Value>
      </ParametersRow>

      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.common.price_eur' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.priceEur}</ParametersRow.Value>
      </ParametersRow>

      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.common.active' />
        </ParametersRow.Label>
        <ParametersRow.Value>
          {data.deletedAt ? <FormattedMessage id='app.common.no' /> : <FormattedMessage id='app.common.yes' />}
        </ParametersRow.Value>
      </ParametersRow>
    </div>
  );
};

DeliveryTariffPreview.displayName = 'DeliveryTariffPreview';

export default DeliveryTariffPreview;
