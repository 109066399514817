import { TrashIcon } from '@heroicons/react/24/outline';
import { HTMLAttributes, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import Button from 'src/components/Button';
import BackIcon from 'src/components/Icons/BackIcon';
import PageContentHeader from 'src/components/PageContentHeader';
import useOpenPopup from 'src/hooks/popups/useOpenPopup';
import useIsCurrentUserAllowedToEdit from 'src/hooks/useIsCurrentUserAllowedToEdit';

type EmployeeDetailHeaderProps = HTMLAttributes<HTMLDivElement> & {
  //
};

const EmployeeDetailHeader: React.FC<EmployeeDetailHeaderProps> = () => {
  const isCurrentUserAllowedToEdit = useIsCurrentUserAllowedToEdit();
  const navigate = useNavigate();
  const back = useCallback(() => navigate(-1), [navigate]);
  const openUpdatePopup = useOpenPopup('update_employee');
  const openDeletePopup = useOpenPopup('delete_employee');

  return (
    <PageContentHeader
      mobilePrimaryButtonType='edit'
      title={
        <span className='flex'>
          <FormattedMessage id='app.contact_page.title' defaultMessage='Kontakty' /> {' - '}
          <FormattedMessage id='app.employee.detail_title' defaultMessage='Detail pracovniku' />
          {isCurrentUserAllowedToEdit && (
            <Button variant='cta' size='small' onClick={openDeletePopup}>
              <TrashIcon className='h-6' />
            </Button>
          )}
        </span>
      }
      primaryButtonText={<FormattedMessage id='app.buttons.edit' />}
      hidePrimaryButton={!isCurrentUserAllowedToEdit}
      secondaryButtonText={
        <>
          <BackIcon />
          <FormattedMessage id='app.buttons.back' defaultMessage='Zpět' />
        </>
      }
      onPrimaryButtonClick={openUpdatePopup}
      onSecondaryButtonClick={back}
    />
  );
};

export default EmployeeDetailHeader;
