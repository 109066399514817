/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateWorkTariffRequest } from '../models/CreateWorkTariffRequest';
import type { WorkTariffPageResponse } from '../models/WorkTariffPageResponse';
import type { WorkTariffResponse } from '../models/WorkTariffResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class WorkTariffsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns WorkTariffPageResponse
   * @throws ApiError
   */
  public getWorkTariffs({
    pageSize,
    pageIndex,
    name,
    price,
    withDeleted,
    direction,
    orderBy,
  }: {
    pageSize: number;
    pageIndex: number;
    name?: string;
    price?: number;
    /**
     * With deleted
     */
    withDeleted?: boolean;
    direction?: 'ASC' | 'DESC';
    orderBy?: string;
  }): CancelablePromise<WorkTariffPageResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/work-tariffs',
      query: {
        name: name,
        price: price,
        withDeleted: withDeleted,
        direction: direction,
        orderBy: orderBy,
        pageSize: pageSize,
        pageIndex: pageIndex,
      },
    });
  }
  /**
   * @returns WorkTariffResponse
   * @throws ApiError
   */
  public createWorkTariff({
    requestBody,
  }: {
    requestBody: CreateWorkTariffRequest;
  }): CancelablePromise<WorkTariffResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/work-tariffs',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns WorkTariffResponse
   * @throws ApiError
   */
  public getAllWorkTariffs(): CancelablePromise<Array<WorkTariffResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/work-tariffs/all',
    });
  }
  /**
   * @returns WorkTariffResponse
   * @throws ApiError
   */
  public getWorkTariff({ id }: { id: number }): CancelablePromise<WorkTariffResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/work-tariffs/{id}',
      path: {
        id: id,
      },
    });
  }
  /**
   * @returns WorkTariffResponse
   * @throws ApiError
   */
  public updateWorkTariff({
    id,
    requestBody,
  }: {
    id: number;
    requestBody: CreateWorkTariffRequest;
  }): CancelablePromise<WorkTariffResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/work-tariffs/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns void
   * @throws ApiError
   */
  public deleteWorkTariff({ id }: { id: number }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/work-tariffs/{id}',
      path: {
        id: id,
      },
    });
  }
  /**
   * @returns void
   * @throws ApiError
   */
  public restoreWorkTariff({ id }: { id: number }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/work-tariffs/{id}/restore',
      path: {
        id: id,
      },
    });
  }
}
