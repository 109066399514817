import { UseQueryResult } from '@tanstack/react-query';
import { TranslationPackResponse } from 'src/api';
import useTranslationPack from 'src/api/hooks/queries/useTranslationPack';
import useLanguage from './useLanguage';

const useUserTranslationPack = (): UseQueryResult<TranslationPackResponse, unknown> => {
  const [language] = useLanguage();
  return useTranslationPack(language);
};

export default useUserTranslationPack;
