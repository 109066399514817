import { FC } from 'react';
import { twMerge } from 'tailwind-merge';
import WeekDaysHeader from '../common/WeekDaysHeader';
import MonthHeader from '../common/MonthHeader';
import WeekRow from '../common/WeekRow';
import useMonthGridState from '../hooks/useMonthGridState';
import { isSameDay, isSameMonth } from 'date-fns';
import clsx from 'clsx';
import DayCell from '../common/DayCell';

export type DayPickerProps = {
  className?: string;
  defaultCurrentMonthStart?: Date;
  selectedDay: Date;
  onDaySelect: (date: Date) => void;
};

const DayPicker: FC<DayPickerProps> = ({ className, defaultCurrentMonthStart, selectedDay, onDaySelect }) => {
  const { weekRows, currentMonthStart, onNextMonth, onPrevMonth } = useMonthGridState({
    defaultCurrentMonthStart,
  });

  return (
    <div className={twMerge('w-max', className)}>
      <MonthHeader onNextMonth={onNextMonth} onPrevMonth={onPrevMonth} currentMonthStart={currentMonthStart} />
      <WeekDaysHeader />
      <div className='flex flex-col gap-y-1'>
        {weekRows.map((week, index) => (
          <WeekRow key={index} week={week}>
            {week.map((date) => {
              const selected = isSameDay(date, selectedDay);
              const currentMonth = isSameMonth(date, currentMonthStart);

              return (
                <DayCell
                  key={date.getTime()}
                  className={clsx(
                    selected && currentMonth && 'hover:border hover:border-neutral-400 rounded border-dashed',
                  )}
                  onClick={() => onDaySelect(date)}
                  selected={selected}
                  disabled={!currentMonth}
                  greyedOut={!currentMonth}
                  date={date}
                />
              );
            })}
          </WeekRow>
        ))}
      </div>
    </div>
  );
};

export default DayPicker;
