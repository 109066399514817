import type { FC, ReactNode, RefObject } from 'react';
import { useCallback, useMemo } from 'react';
import { SEPARATORS_PER_HOUR } from '../constants';
import {
  EventGridContextValue,
  EventGridSettings,
  EventToGridFunc,
  EventToGridRawFunc,
  GetGridCellDimensionsRawFunc,
  GridToTimeSpanFunc,
  GridToTimeSpanFuncOptions,
  GridToTimeSpanRawFunc,
} from '../types';
import { EventGridContext } from './context';

export type EventGridContextProviderProps = {
  children: ReactNode;
  gridRef: RefObject<HTMLElement>;
  scrollContainerRef: RefObject<HTMLElement>;
  eventToGrid: EventToGridRawFunc;
  gridToTimeSpan: GridToTimeSpanRawFunc;
  getGridCellDimensions: GetGridCellDimensionsRawFunc;
  cols: number;
  rows: number;
  startHour: number;
  endHour: number;
  disabledColumns?: number[];
  minEnabledMinute?: number;
  maxEnabledMinute?: number;
};

export const EventGridContextProvider: FC<EventGridContextProviderProps> = ({
  children,
  gridRef,
  scrollContainerRef,
  eventToGrid: eventToGridRaw,
  gridToTimeSpan: gridToTimeSpanRaw,
  getGridCellDimensions: getGridCellDimensionsRaw,
  cols,
  rows,
  startHour,
  endHour,
  disabledColumns,
  minEnabledMinute,
  maxEnabledMinute,
}) => {
  const workingHours = endHour - startHour;
  const totalSeparators = workingHours * SEPARATORS_PER_HOUR;

  const gridSettings = useMemo<EventGridSettings>(
    () => ({
      ROWS: rows,
      COLS: cols,
      START_HOUR: startHour,
      END_HOUR: endHour,
      TOTAL_SEPARATORS: totalSeparators, // TODO: remove
      START_MINUTE: startHour * 60,
      END_MINUTE: endHour * 60,
      DISABLED_COLLUMNS: disabledColumns,
      MIN_ENABLED_MINUTE: minEnabledMinute,
      MAX_ENABLED_MINUTE: maxEnabledMinute,
    }),
    [rows, cols, startHour, endHour, disabledColumns?.join(','), minEnabledMinute, maxEnabledMinute],
  );

  const eventToGrid = useCallback<EventToGridFunc>(
    (event) => eventToGridRaw(event, gridSettings),
    [eventToGridRaw, gridSettings],
  );

  const gridToTimeSpan = useCallback<GridToTimeSpanFunc>(
    (options: GridToTimeSpanFuncOptions) => gridToTimeSpanRaw(options, gridSettings),
    [gridToTimeSpanRaw, gridSettings],
  );

  const getGridCellDimensions = useCallback(
    () => getGridCellDimensionsRaw(gridRef, gridSettings),
    [getGridCellDimensionsRaw, gridSettings],
  );

  const contextValue = useMemo<EventGridContextValue>(
    () => ({
      gridRef,
      scrollContainerRef,
      eventToGrid,
      gridToTimeSpan,
      getGridCellDimensions,
      gridSettings,
    }),
    [eventToGrid, gridToTimeSpan, getGridCellDimensions, gridSettings],
  );

  return <EventGridContext.Provider value={contextValue}>{children}</EventGridContext.Provider>;
};
