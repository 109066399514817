import { Navigate, useParams } from 'react-router-dom';
import useContactEmployee from 'src/api/hooks/queries/useContactEmployee';
import Loader from 'src/components/utils/Loader';
import EmployeePreview from '../EmployeePreview';

type EmployeeDetailProps = {
  className?: string;
};

const EmployeeDetail: React.FC<EmployeeDetailProps> = ({ className }) => {
  const contactId = Number(useParams().contactId);
  const employeeId = Number(useParams().employeeId);

  const { data, isFetching } = useContactEmployee(contactId, employeeId);

  if (isFetching) return <Loader />;
  if (!data) return <Navigate to='/404' />;

  return <EmployeePreview className={className} data={data} />;
};

export default EmployeeDetail;
