import clsx from 'clsx';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import CalendarPageHeader from './components/CalendarPageHeader';

export type CalendarPageProps = {
  //
};

const CalendarPage: FC<CalendarPageProps> = () => {
  return (
    <div className={clsx()}>
      <CalendarPageHeader className='mb-12' />
      <Outlet />
    </div>
  );
};

CalendarPage.displayName = 'CalendarPage';

export default CalendarPage;
