import { UseMutationResult, useQueryClient, useMutation } from '@tanstack/react-query';
import { formatISO, isSameDay, startOfDay } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

export type UseResizeOrderPayload = {
  id: number;
  start: Date;
  end: Date;
};

const useResizeOrder = (): UseMutationResult<void, unknown, UseResizeOrderPayload> => {
  const client = useApiClient();
  const queryClient = useQueryClient();

  return useMutation<void, unknown, UseResizeOrderPayload>(
    async (data: UseResizeOrderPayload) => {
      await client.calendar.resizeOrder({
        id: data.id,
        requestBody: {
          start: formatISO(data.start),
          end: formatISO(data.end),
        },
      });
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(queryKeysFactory.orders.list.queryKey);
        queryClient.invalidateQueries(queryKeysFactory.orders.detail(variables.id).queryKey);
      },
      onError: () => {
        toast(<FormattedMessage id='app.toasts.update_fail' />, {
          type: 'error',
        });
      },
      onSettled: (_data, _err, payload) => {
        const start = formatISO(startOfDay(payload.start));
        const { queryKey: startATDKey } = queryKeysFactory.allTechniciansDayOrders.list(start);

        queryClient.invalidateQueries(startATDKey);

        if (isSameDay(payload.start, payload.end)) return;

        const end = formatISO(startOfDay(payload.end));
        const { queryKey: endATDKey } = queryKeysFactory.allTechniciansDayOrders.list(end);

        queryClient.invalidateQueries(endATDKey);
      },
    },
  );
};

export default useResizeOrder;
