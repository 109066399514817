/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChangePasswordRequest } from '../models/ChangePasswordRequest';
import type { CreateUserRequest } from '../models/CreateUserRequest';
import type { UserResponse } from '../models/UserResponse';
import type { UsersPageResponse } from '../models/UsersPageResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class UserService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns UserResponse
   * @throws ApiError
   */
  public create({ requestBody }: { requestBody: CreateUserRequest }): CancelablePromise<UserResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/user',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns UsersPageResponse
   * @throws ApiError
   */
  public getUserList({
    pageSize,
    pageIndex,
    email,
    role,
    direction,
    orderBy,
  }: {
    pageSize: number;
    pageIndex: number;
    /**
     * User email
     */
    email?: string;
    /**
     * User role
     */
    role?: any;
    direction?: 'ASC' | 'DESC';
    orderBy?: string;
  }): CancelablePromise<UsersPageResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/user',
      query: {
        email: email,
        role: role,
        direction: direction,
        orderBy: orderBy,
        pageSize: pageSize,
        pageIndex: pageIndex,
      },
    });
  }
  /**
   * @returns UserResponse
   * @throws ApiError
   */
  public getAllTechnicians(): CancelablePromise<Array<UserResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/user/all-technicians',
    });
  }
  /**
   * @returns any
   * @throws ApiError
   */
  public getAllTechniciansAvailability({
    start,
    end,
  }: {
    /**
     * Start date
     */
    start: string;
    /**
     * End date
     */
    end: string;
  }): CancelablePromise<Record<string, any>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/user/all-technicians/availability',
      query: {
        start: start,
        end: end,
      },
    });
  }
  /**
   * @returns UserResponse
   * @throws ApiError
   */
  public getCurrentUser(): CancelablePromise<UserResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/user/current',
    });
  }
  /**
   * @returns UserResponse
   * @throws ApiError
   */
  public getUser({ id }: { id: number }): CancelablePromise<UserResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/user/{id}',
      path: {
        id: id,
      },
    });
  }
  /**
   * @returns UserResponse
   * @throws ApiError
   */
  public deleteUser({ id }: { id: number }): CancelablePromise<UserResponse> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/user/{id}',
      path: {
        id: id,
      },
    });
  }
  /**
   * @returns UserResponse
   * @throws ApiError
   */
  public updateUser({
    id,
    requestBody,
  }: {
    id: number;
    requestBody: CreateUserRequest;
  }): CancelablePromise<UserResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/user/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns void
   * @throws ApiError
   */
  public changePassword({ requestBody }: { requestBody: ChangePasswordRequest }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/user/change-password',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns UserResponse
   * @throws ApiError
   */
  public restoreUser({ id }: { id: number }): CancelablePromise<UserResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/user/{id}/restore-user',
      path: {
        id: id,
      },
    });
  }
}
