import { FC } from 'react';
import clsx from 'clsx';
import { TenantWithLicencesResponse } from 'src/api';
import ParametersRow from 'src/components/common/ParametersRow';
import { FormattedMessage } from 'react-intl';
import ImagePreview from 'src/components/common/ImagePreview';
import HyperLink from 'src/components/common/HyperLink';
import copyToClipboard from 'src/helpers/copyToClipboard';
import { ClipboardIcon } from '@heroicons/react/24/outline';
import Button from 'src/components/Button';
import { toast } from 'react-toastify';
import { addDays, differenceInDays, parseISO } from 'date-fns';

export type TenantPreviewProps = {
  className?: string;
  data: TenantWithLicencesResponse;
};

const TenantPreview: FC<TenantPreviewProps> = ({ className, data }) => {
  const daysFromFreeTrialEnding = differenceInDays(addDays(parseISO(data.createdAt), 30), new Date());
  const freeTrialLeft = daysFromFreeTrialEnding < 0 ? 0 : daysFromFreeTrialEnding;

  return (
    <div className={clsx(className)}>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.tenant.logo' />
        </ParametersRow.Label>
        <ParametersRow.Value className='w-1/2'>
          {data.logoFileId && (
            <ImagePreview
              isSvg={data.logoMimeType === 'image/svg+xml'}
              className='max-h-12 max-w-full min-w-[3rem]'
              id={data.logoFileId}
            />
          )}
        </ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.common.id' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.id}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.tenant.max_user_count' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.maxUserCount}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.tenant.current_user_count' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.currentUserCount}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.tenant.tenant_name' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.tenantName}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.tenant.tenant_domain' />
        </ParametersRow.Label>
        <ParametersRow.Value>
          {data.tenantDomain?.tenantDomain && (
            <>
              <HyperLink forceExternal href={data.tenantDomain?.tenantDomain}>
                https://{data.tenantDomain?.tenantDomain}
              </HyperLink>
              <Button
                variant='cta'
                size='small'
                onClick={async () => {
                  await copyToClipboard(`https://${data.tenantDomain?.tenantDomain}`);
                  toast(<FormattedMessage id='app.toasts.link_coppied_to_clipboard' />, { type: 'success' });
                }}
              >
                <ClipboardIcon className='h-6' />
              </Button>
            </>
          )}
        </ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.tenant.licenses' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.licenses?.map((licence) => licence.module).join(', ')}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.tenant.application_title' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.applicationTitle}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.tenant.application_title' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.applicationTitle}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.tenant.color_theme' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.colorTheme}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.contact.active_tenant' />
        </ParametersRow.Label>
        <ParametersRow.Value>
          {data.deletedAt ? <FormattedMessage id='app.common.no' /> : <FormattedMessage id='app.common.yes' />}
        </ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.tenant.is_demo' />
        </ParametersRow.Label>
        <ParametersRow.Value>
          {data.isDemo ? <FormattedMessage id='app.common.yes' /> : <FormattedMessage id='app.common.no' />}
        </ParametersRow.Value>
      </ParametersRow>
      {data.isDemo && (
        <ParametersRow>
          <ParametersRow.Label>
            <FormattedMessage id='app.tenant.free_trial_left' />
          </ParametersRow.Label>
          <ParametersRow.Value>{freeTrialLeft}</ParametersRow.Value>
        </ParametersRow>
      )}
    </div>
  );
};

TenantPreview.displayName = 'TenantPreview';

export default TenantPreview;
