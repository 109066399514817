import schemes, { ColorSchemeName } from '../constants/colorSchemes';

export type ColorScheme = {
  primary: string;
  primaryFocus: string;

  secondary: string;
  secondaryFocus: string;

  error?: string;
  errorLight?: string;
  success?: string;
  successLight?: string;

  status?: {
    success?: string;
    paid?: string;
    planned?: string;
    failed?: string;
    new?: string;
    routine?: string;
  };

  gray?: Record<number, string>;
};

const _setColorScheme = (scheme: ColorScheme): void => {
  const root = document.querySelector<HTMLElement>(':root');

  if (!root) return;

  if (scheme.primary) root.style.setProperty('--primary-color', scheme.primary);
  if (scheme.primaryFocus) root.style.setProperty('--primary-focus-color', scheme.primaryFocus);

  if (scheme.secondary) root.style.setProperty('--secondary-color', scheme.secondary);
  if (scheme.secondaryFocus) root.style.setProperty('--secondary-focus-color', scheme.secondaryFocus);

  if (scheme.error) root.style.setProperty('--error-color', scheme.error);
  if (scheme.success) root.style.setProperty('--success-color', scheme.success);

  if (scheme.errorLight) root.style.setProperty('--error-light-color', scheme.errorLight);
  if (scheme.successLight) root.style.setProperty('--error-success-color', scheme.successLight);

  if (scheme.status) {
    for (const [key, value] of Object.entries(scheme.status)) {
      root.style.setProperty(`--status-${key}-color`, value);
    }
  }

  if (scheme.gray) {
    for (const [key, value] of Object.entries(scheme.gray)) {
      root.style.setProperty(`--gray-${key}-color`, value);
    }
  }
};

const setColorScheme = (name: ColorSchemeName): void => {
  _setColorScheme(schemes[name]);
};

export default setColorScheme;
