/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateProfileRequest } from './CreateProfileRequest';
export type ExternalCreateUserRequest = {
  userEmail: string;
  role: ExternalCreateUserRequest.role;
  profile: CreateProfileRequest | null;
};
export namespace ExternalCreateUserRequest {
  export enum role {
    ADMINISTRATOR = 'administrator',
    DISPATCHER = 'dispatcher',
    DISPATCHER_TECHNICIAN = 'dispatcherTechnician',
    TECHNICIAN = 'technician',
    READ_ONLY_TECHNICIAN = 'readOnlyTechnician',
    ADMINISTRATOR_TECHNICIAN = 'administratorTechnician',
  }
}
