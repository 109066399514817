/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type NotificationResponse = {
  id: number;
  type: NotificationResponse.type;
  key: NotificationResponse.key;
  title: string;
  text: string;
  targets: string;
  cron: string;
  daysBeforeEvent: number;
  defaultEnabled: boolean;
};
export namespace NotificationResponse {
  export enum type {
    SMS = 'sms',
    EMAIL = 'email',
  }
  export enum key {
    EMPLOYEE_BEFORE_SERVICE = 'employeeBeforeService',
    CUSTOMER_BEFORE_SERVICE = 'customerBeforeService',
    DISPATCHER_BEFORE_ROUTINE_SERVICE = 'dispatcherBeforeRoutineService',
  }
}
