import clsx from 'clsx';
import { PropsWithChildren } from 'react';
import { FC, ReactNode } from 'react';

export type FieldWrapperProps = {
  className?: string;
  label: ReactNode;
  asRow?: boolean;
  reversed?: boolean;
};

const getFlexDirection = (row: boolean, reversed: boolean): string => {
  if (row && reversed) return 'flex-row-reverse justify-end';
  if (row && !reversed) return 'flex-row';
  if (!row && reversed) return 'flex-col-reverse';
  return 'flex-col';
};

const FieldWrapper: FC<PropsWithChildren<FieldWrapperProps>> = ({ className, reversed, asRow, children, label }) => {
  return (
    <div className={clsx(className, 'flex gap-3 w-full', getFlexDirection(!!asRow, !!reversed))}>
      {label && <span>{label}</span>}
      {children}
    </div>
  );
};

FieldWrapper.displayName = 'FieldWrapper';

export default FieldWrapper;
