import { FC, ReactNode } from 'react';
import clsx from 'clsx';
import { LinkButton } from 'src/components/Button';

export type ExportCardData = {
  title: ReactNode;
  badge?: ReactNode;
  description: ReactNode;
  buttonLink?: string;
  buttonText: ReactNode;
};

export type ExportCardProps = ExportCardData & {
  className?: string;
};

const ExportCard: FC<ExportCardProps> = ({ className, buttonText, title, description, badge, buttonLink }) => {
  return (
    <li
      className={clsx(className, 'flex flex-col overflow-hidden rounded-lg border border-b border-gray-200 bg-white')}
    >
      <div className='flex items-center justify-between gap-x-4 border-b border-gray-200 px-5 py-3'>
        <div className='font-semibold'>{title}</div>
        {badge}
      </div>
      <div className='p-6 flex-grow border-b border-gray-200'>
        <div className='text-sm text-gray-900'>{description}</div>
      </div>
      <div className='flex px-5 py-3'>
        <LinkButton size='small' variant='primary' className='w-full' href={buttonLink} disabled={!buttonLink}>
          {buttonText}
        </LinkButton>
      </div>
    </li>
  );
};

ExportCard.displayName = 'ExportCard';

export default ExportCard;
