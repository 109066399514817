import { Updater } from '@tanstack/react-table';
import { useEffect, useRef } from 'react';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import useEvent from '../useEvent';

export type UseSearchParamOptions<T> = {
  defaultValue?: T;
  convertFromString?: (value: string | null) => T;
  convertToString?: (value: T | null) => string;
};

const useSearchParam = <T>(
  paramName: string,
  options: UseSearchParamOptions<T> = {},
): readonly [state: T, setState: (next: Updater<T | null>) => void] => {
  const { convertFromString, convertToString, defaultValue } = options;
  const defaultInit = {
    [paramName]: defaultValue ? convertToString?.(defaultValue) ?? defaultValue?.toString() ?? '' : '',
  };
  const [searchParams, setSearchParams] = useSearchParams(defaultInit);
  const defaultSearchParams = useRef(createSearchParams(defaultInit));

  // stable reference to searchParams value for useCallback
  const searchParamsRef = useRef(searchParams);
  searchParamsRef.current = searchParams;

  const param = searchParams.get(paramName);
  const state = convertFromString?.(param) ?? (param as unknown as T);

  const setState = useEvent((value: Updater<T | null>) => {
    if (typeof value === 'function') {
      const param = searchParams.get(paramName);
      const paramValue = convertFromString?.(param) ?? (param as T | null);
      const updater = value as (old: T | null) => T;

      setSearchParams({
        ...Object.fromEntries(searchParams.entries()),
        [paramName]: convertToString?.(updater(paramValue)) ?? value.toString(),
      });
      return;
    }

    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      [paramName]: convertToString?.(value) ?? value?.toString() ?? '',
    });
  });

  useEffect(() => {
    const update: Record<string, string> = {};
    let needUpdate = false;

    defaultSearchParams.current.forEach((v, k) => {
      if (!searchParams.has(k)) {
        update[k] = v;
        needUpdate = true;
      }
    });

    if (needUpdate) setSearchParams(update, { replace: true });
  }, []);

  return [state, setState];
};

export default useSearchParam;
