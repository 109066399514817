import { createContext, Dispatch, SetStateAction, useCallback, useContext } from 'react';
import { InfoPopupProps } from 'src/components/InfoPopup/InfoPopup';
import { noop } from 'src/utils/commonUtils';

export type MainInfoPopupProps = Omit<InfoPopupProps, 'open' | 'onClose'>;

export type InfoPopupContextValue = {
  props: MainInfoPopupProps;
  setProps: Dispatch<SetStateAction<MainInfoPopupProps>>;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

export const InfoPopupContext = createContext<InfoPopupContextValue>({
  props: {} as MainInfoPopupProps,
  setProps: noop,
  setOpen: noop,
});

export const useShowInfoPopup = (): ((props: MainInfoPopupProps) => void) => {
  const { setProps, setOpen } = useContext(InfoPopupContext);

  const wrapper = useCallback(
    (props: MainInfoPopupProps) => {
      setOpen(true);
      setProps({ ...props });
    },
    [setProps, setOpen],
  );

  return wrapper;
};
