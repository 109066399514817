import { LinkIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { ReactNode } from 'react';
import { FC, HTMLAttributes } from 'react';
import { FormattedMessage } from 'react-intl';
import { OrderResponse, UserResponse } from 'src/api';
import useCurrentTenantConfig from 'src/api/hooks/queries/useCurrentTenantConfig';
import HyperLink from 'src/components/common/HyperLink';
import ParametersRow from 'src/components/common/ParametersRow';
import OrderProductPreview from 'src/components/OrderProductPreview';
import ServiceSummaryPreview from 'src/components/ServiceSummaryPreview';
import useGetIsRoleAllowed from 'src/hooks/useGetIsRoleAllowed';
import { MessageId } from 'src/types/commonTypes';

export type TechnicianOrderPreviewProps = {
  data: OrderResponse;
  extraTitle?: ReactNode;
} & HTMLAttributes<HTMLDivElement>;

const TechnicianOrderPreview: FC<TechnicianOrderPreviewProps> = ({ className, data, extraTitle }) => {
  const getIsRoleAllowed = useGetIsRoleAllowed();
  const { data: tenantConfig } = useCurrentTenantConfig();

  return (
    <div className={clsx(className, 'flex flex-col')}>
      <ParametersRow.Title className='mb-3 flex'>
        <FormattedMessage id='app.finish_order.section_title.order' values={{ value: data.orderNumber }} />
        {extraTitle}
      </ParametersRow.Title>
      <ParametersRow.Description>
        <FormattedMessage id='app.order.section_description.order' />
      </ParametersRow.Description>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.order.datetime' />
        </ParametersRow.Label>
        <ParametersRow.Value>
          {data.datetime && format(parseISO(data.datetime), 'dd.MM.yyyy HH:mm')}
        </ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.order.end_time' />
        </ParametersRow.Label>
        <ParametersRow.Value>
          {data.endDatetime && format(parseISO(data.endDatetime), 'dd.MM.yyyy HH:mm')}
        </ParametersRow.Value>
      </ParametersRow>
      {data.deliveryType && (
        <ParametersRow>
          <ParametersRow.Label>
            <FormattedMessage id='app.finish_order.delivery_type' />
          </ParametersRow.Label>
          <ParametersRow.Value>
            <FormattedMessage id={`app.finish_order.delivery_type.${data.deliveryType.toLowerCase()}` as MessageId} />
          </ParametersRow.Value>
        </ParametersRow>
      )}
      {data.deliveryTariff && (
        <ParametersRow>
          <ParametersRow.Label>
            <FormattedMessage id='app.finish_order.delivery_tariff' />
          </ParametersRow.Label>
          <ParametersRow.Value>{data.deliveryTariff.name}</ParametersRow.Value>
        </ParametersRow>
      )}
      <ParametersRow className='mb-20'>
        <ParametersRow.Label>
          <FormattedMessage id='app.order.invoice_number' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.invoiceNumber}</ParametersRow.Value>
      </ParametersRow>

      <ServiceSummaryPreview data={data} className='mb-20' />

      <ParametersRow.Title>
        <FormattedMessage id='app.order.section_title.technicians' />
      </ParametersRow.Title>
      {data.technicians.length === 0 && (
        <span className='mb-20'>
          <FormattedMessage id='app.order.no_technicians' />
        </span>
      )}
      {data.technicians.map((technician, index) => (
        <ParametersRow key={technician.id} className={clsx(index === data.technicians.length - 1 && 'mb-20')}>
          <ParametersRow.Label>
            <FormattedMessage id='app.order.technician_n' values={{ value: index + 1 }} />
          </ParametersRow.Label>
          <ParametersRow.Value>
            {technician?.profile?.name}
            {getIsRoleAllowed([
              UserResponse.role.SYSTEM_ADMINISTRATOR,
              UserResponse.role.ADMINISTRATOR,
              UserResponse.role.ADMINISTRATOR_TECHNICIAN,
              UserResponse.role.DISPATCHER_TECHNICIAN,
              UserResponse.role.DISPATCHER,
            ]) &&
              !!technician.id && (
                <HyperLink href={`/admin/user/${technician.id}`}>
                  <LinkIcon className='ml-3 w-7' />
                </HyperLink>
              )}
          </ParametersRow.Value>
        </ParametersRow>
      ))}

      <ParametersRow.Title className='mb-3'>
        <FormattedMessage id='app.order.section_title.contact' />
      </ParametersRow.Title>
      <ParametersRow.Description>
        <FormattedMessage id='app.order.section_description.contact' />
      </ParametersRow.Description>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.contact.company_name' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.company?.companyName}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.common.ico' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.company?.in}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.common.vat' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.company?.vat}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.common.city' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.company?.city}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.common.street' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.company?.street}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.common.zip' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.company?.zip}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.common.phone' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.company?.phoneNumber}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.common.email' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.company?.email}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow noBorder={!tenantConfig?.contactForm?.showInternalCategory}>
        <ParametersRow.Label>
          <FormattedMessage id='app.common.preferred_currency' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.company?.preferredCurrency}</ParametersRow.Value>
      </ParametersRow>
      {tenantConfig?.contactForm?.showInternalCategory && (
        <ParametersRow noBorder>
          <ParametersRow.Label>
            <FormattedMessage id='app.contact.internal_category' />
          </ParametersRow.Label>
          <ParametersRow.Value>{data.company?.internalCategory}</ParametersRow.Value>
        </ParametersRow>
      )}

      <ParametersRow.Title className='mb-3 mt-20'>
        <FormattedMessage id='app.order.section_title.branch' />
      </ParametersRow.Title>
      <ParametersRow.Description>
        <FormattedMessage id='app.order.section_description.branch' />
      </ParametersRow.Description>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.branch.name' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.branch?.name}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.common.city' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.branch?.city}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.common.street' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.branch?.street}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.common.zip' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.branch?.zip}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.branch.contact_person' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.employee?.name}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow noBorder className='mb-20'>
        <ParametersRow.Label>
          <FormattedMessage id='app.common.email' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.branch?.email}</ParametersRow.Value>
      </ParametersRow>

      <ParametersRow.Title className='mb-3'>
        <FormattedMessage id='app.order.section_title.products' />
      </ParametersRow.Title>
      <ParametersRow.Description>
        <FormattedMessage id='app.order.section_description.products' />
      </ParametersRow.Description>
      {data.orderProducts.map((orderProduct, index) => (
        <OrderProductPreview key={index} data={orderProduct} index={index} contactId={data.company.id} />
      ))}
    </div>
  );
};

TechnicianOrderPreview.displayName = 'TechnicianOrderPreview';

export default TechnicianOrderPreview;
