/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { LicenceResponse, TenantWithLicencesResponse, UserResponse } from 'src/api';
import LANGUAGE_OPTIONS from 'src/constants/languageOptions';
import messages from 'src/translations/default.json';

export type FieldProps<E extends React.HTMLAttributes<T>, T, V> = Omit<React.DetailedHTMLProps<E, T>, 'value'> & {
  label?: React.ReactNode;
  value?: V;
  error?: boolean | string;
};

export type NavigationItem = {
  href: string;
  renderText: () => JSX.Element;
  renderIcon: (props?: Record<string, any>) => JSX.Element;
  getIsSelected?: (pathname: string, href: string) => boolean;
  allowedRoles?: UserResponse.role[];
  shouldHideOnThisTenant?: (tenant: TenantWithLicencesResponse) => boolean;
  dataJoyride?: string;
};

export const Modules = LicenceResponse.module;

export type ArrayElement<ArrayType extends readonly unknown[]> = ArrayType extends readonly (infer ElementType)[]
  ? ElementType
  : never;

export type LanguageOptionValue = ArrayElement<typeof LANGUAGE_OPTIONS>;

export type MessageId = keyof typeof messages;

export type TranslationsDictionary = Record<MessageId, string>;

export type WeekOptions = {
  locale?: Locale;
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
};

export type ItemWithId = {
  id: number | string;
};
