import { FC } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import useContactProduct from 'src/api/hooks/queries/useContactProduct';
import Loader from 'src/components/utils/Loader';
import DeleteProductPopup from '../ContactDetailPage/DeleteProductPopup';
import ProductDetailHeader from '../ContactDetailPage/ProductDetailHeader';
import ProductPreview from '../ContactDetailPage/ProductPreview';
import UpdateProductPopup from '../ContactDetailPage/UpdateProductPopup';
import PageCard from 'src/components/PageCard';

export type ProductDetailPageProps = {
  className?: string;
};

const ProductDetailPage: FC<ProductDetailPageProps> = () => {
  const productId = Number(useParams().productId);
  const { data, isLoading } = useContactProduct(productId);

  if (isLoading) return <Loader />;
  if (!data) return <Navigate to='/404' />;

  return (
    <>
      <ProductDetailHeader disableEdit={!!data.deletedAt} disableDelete={!!data.deletedAt} className='mb-12' />
      <PageCard className='flex flex-col gap-12'>
        <ProductPreview data={data} noTitle />
      </PageCard>
      <UpdateProductPopup productId={productId} />
      <DeleteProductPopup productId={productId} />
    </>
  );
};

ProductDetailPage.displayName = 'ProductDetailPage';

export default ProductDetailPage;
