import { useQuery, UseQueryResult } from '@tanstack/react-query';
import queryKeysFactory from 'src/store/queryKeysFactory';
import { useOldApiClient } from '../useApiClient';

const useFileUrl = (id: number | null): UseQueryResult<string> => {
  const client = useOldApiClient();
  const { queryKey } = queryKeysFactory.fileUrl.detail(id ?? 0);

  return useQuery(
    queryKey,
    async (): Promise<string> => {
      const { data } = await client.get(`file/${id}`, { responseType: 'blob' });
      const url = window.URL.createObjectURL(new Blob([data], { type: data.type }));
      return url;
    },
    {
      enabled: !!id,
    },
  );
};

export default useFileUrl;
