import { isEqual } from 'date-fns';
import { useCallback } from 'react';
import { getAllMinutes } from 'src/helpers/datetime';
import useMoveTechnicianEvent from 'src/hooks/useMoveTechnicianEvent';
import { findById, updateEventStartDate } from '../helpers/state';
import { GridEvent, HandleDropFunc } from '../types';

const useHandleDropEvent = (events: GridEvent[]): HandleDropFunc => {
  const moveEvent = useMoveTechnicianEvent(events);

  return useCallback<HandleDropFunc>(
    (item, monitor, context, result) => {
      const event = findById(item.id, events);
      if (!event) return;
      const { gridSettings, gridToTimeSpan } = context;
      const { MAX_ENABLED_MINUTE, END_MINUTE } = gridSettings;

      const { start, end } = event;
      const { start: newStart } = gridToTimeSpan({ ...result, start, end });

      if (isEqual(start, newStart)) return;

      const updatedEvent = updateEventStartDate(event, newStart);
      const newEndMinute = getAllMinutes(updatedEvent.end);

      if (newEndMinute > (MAX_ENABLED_MINUTE ?? END_MINUTE)) return;

      moveEvent(item.id, newStart, item.technicianId);
    },
    [events],
  );
};

export default useHandleDropEvent;
