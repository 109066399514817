import { FC, Fragment, HTMLAttributes } from 'react';
import clsx from 'clsx';
import { LinkIcon } from '@heroicons/react/24/outline';
import { FormattedMessage } from 'react-intl';
import HyperLink from '../common/HyperLink';
import ParametersRow from '../common/ParametersRow';
import { OrderProductResponse, OrderResponse } from 'src/api';
import RoutineServiceLabel from '../RoutineServiceLabel';
import RSoRString from '../FinishFormGenTemplate/components/RSoRString';
import useGetIsHiddenProductFormField from 'src/hooks/formsConfig/useGetIsHiddenProductFormField';
import { format, parseISO } from 'date-fns';
import OrderMaterialsPreview from '../OrderMaterialsPreview';

export type OrderProductPreviewProps = HTMLAttributes<HTMLDivElement> & {
  data: OrderProductResponse;
  contactId: number;
  index: number;
  order?: OrderResponse;
};

const OrderProductPreview: FC<OrderProductPreviewProps> = ({ className, data, order, index }) => {
  const getIsHiddenField = useGetIsHiddenProductFormField();

  return (
    <div className={clsx('flex flex-col mb-8', className)}>
      <span className='font-semibold text-xl'>
        <FormattedMessage id='app.order.section_title.product' values={{ value: index + 1 }} />
      </span>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.product.producer' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.product?.productProducer}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.product.name' />
        </ParametersRow.Label>
        <ParametersRow.Value>
          <span>{data.product?.productName}</span>
          <HyperLink href={`/product/${data.productId}`}>
            <LinkIcon className='ml-3 w-7' />
          </HyperLink>
        </ParametersRow.Value>
      </ParametersRow>
      {!getIsHiddenField('productSerialNumber') && (
        <ParametersRow>
          <ParametersRow.Label>
            <FormattedMessage id='app.product.serial_number' />
          </ParametersRow.Label>
          <ParametersRow.Value>
            <span>{data?.product.productSerialNumber}</span>
          </ParametersRow.Value>
        </ParametersRow>
      )}

      {!getIsHiddenField('productSerialNumber2') && (
        <ParametersRow>
          <ParametersRow.Label>
            <FormattedMessage id='app.product.serial_number2' />
          </ParametersRow.Label>
          <ParametersRow.Value>{data.product.productSerialNumber2}</ParametersRow.Value>
        </ParametersRow>
      )}

      {!getIsHiddenField('technology') && (
        <ParametersRow>
          <ParametersRow.Label>
            <FormattedMessage id='app.product.technology' />
          </ParametersRow.Label>
          <ParametersRow.Value>{data.product.technology}</ParametersRow.Value>
        </ParametersRow>
      )}

      {!getIsHiddenField('quarante') && (
        <ParametersRow>
          <ParametersRow.Label>
            <FormattedMessage id='app.order.quarantee' />
          </ParametersRow.Label>
          <ParametersRow.Value>
            {data.quarante ? <FormattedMessage id='app.common.yes' /> : <FormattedMessage id='app.common.no' />}
          </ParametersRow.Value>
        </ParametersRow>
      )}
      {!getIsHiddenField('warranty') && (
        <ParametersRow>
          <ParametersRow.Label>
            <FormattedMessage id='app.product.in_use_from' />
          </ParametersRow.Label>
          <ParametersRow.Value>
            {data.product.warranty ? format(parseISO(data.product.warranty), 'yyyy-MM-dd') : ''}
          </ParametersRow.Value>
        </ParametersRow>
      )}
      {!getIsHiddenField('routineServiceId') && (
        <ParametersRow>
          <ParametersRow.Label>
            <FormattedMessage id='app.product.routine_service' />
          </ParametersRow.Label>
          <ParametersRow.Value>
            <RoutineServiceLabel id={data?.routineServiceId} />
          </ParametersRow.Value>
        </ParametersRow>
      )}
      {order?.slConfigOutput &&
        order?.orderType?.slConfig?.productsSections?.map((section) => (
          <Fragment key={section.id}>
            <span className='mt-4 mb-2 font-semibold text-xl'>
              <RSoRString label={section.label} />
            </span>
            {section.fields.map((field, index) => (
              <ParametersRow key={field.id}>
                <ParametersRow.Label>
                  <RSoRString label={field.label} />
                </ParametersRow.Label>
                <ParametersRow.Value>
                  {field.type === 'checkbox' ? (
                    order?.slConfigOutput?.products?.[index]?.[field.name] ? (
                      <FormattedMessage id='app.common.yes' />
                    ) : (
                      <FormattedMessage id='app.common.no' />
                    )
                  ) : (
                    order?.slConfigOutput?.products?.[index]?.[field.name]
                  )}
                </ParametersRow.Value>
              </ParametersRow>
            ))}
          </Fragment>
        ))}
      {!getIsHiddenField('productDescription') && (
        <ParametersRow>
          <ParametersRow.Label>
            <FormattedMessage id='app.order.product_description' />
          </ParametersRow.Label>
          <ParametersRow.Value>{data.productDescription}</ParametersRow.Value>
        </ParametersRow>
      )}
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.order.repair_description' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.repairDescription}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.order.problem_description' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.problemDescription}</ParametersRow.Value>
      </ParametersRow>

      {data.materials?.length > 0 && (
        <OrderMaterialsPreview orderType={order?.orderType ?? undefined} materials={data.materials} />
      )}
    </div>
  );
};

OrderProductPreview.displayName = 'OrderProductPreview';

export default OrderProductPreview;
