import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { ContactResponse, CreateContactRequest } from 'src/api';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

const useAddContact = (): UseMutationResult<ContactResponse, unknown, CreateContactRequest> => {
  const client = useApiClient();
  const queryClient = useQueryClient();

  return useMutation<ContactResponse, unknown, CreateContactRequest>(
    async (contact: CreateContactRequest) => {
      const data = await client.contact.createContact({ requestBody: contact });
      return data as ContactResponse;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeysFactory.contacts.list.queryKey);
        toast(<FormattedMessage id='app.toasts.add_success' defaultMessage='Záznam úspěšně přidán' />, {
          type: 'success',
        });
      },
      onError: () => {
        toast(<FormattedMessage id='app.toasts.add_fail' defaultMessage='Chyba přidáni záznamu' />, {
          type: 'error',
        });
      },
    },
  );
};

export default useAddContact;
