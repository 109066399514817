import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { CreateUserRequest, UserResponse } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';
import { WithId } from 'src/types/mutations';

const useUpdateUser = (): UseMutationResult<UserResponse, unknown, WithId<CreateUserRequest>> => {
  const client = useApiClient();
  const queryClient = useQueryClient();

  return useMutation<UserResponse, unknown, WithId<CreateUserRequest>>(
    async (user: WithId<CreateUserRequest>) => {
      const data = await client.user.updateUser({ requestBody: user, id: user.id });
      return data;
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(queryKeysFactory.users.list.queryKey);
        queryClient.invalidateQueries(queryKeysFactory.users.detail(variables.id).queryKey);

        const currentUser: UserResponse | undefined = queryClient.getQueryData(
          queryKeysFactory.userProfile.detail.queryKey,
        );
        if (currentUser?.id === variables.id)
          queryClient.invalidateQueries(queryKeysFactory.userProfile.detail.queryKey);

        toast(<FormattedMessage id='app.toasts.update_success' />, {
          type: 'success',
        });
      },
      onError: () => {
        toast(<FormattedMessage id='app.toasts.update_fail' />, {
          type: 'error',
        });
      },
    },
  );
};

export default useUpdateUser;
