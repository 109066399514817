import { addDays, differenceInDays, parseISO } from 'date-fns';
import { FC, useState } from 'react';
import { useApiClient } from 'src/api';
import useCurrentTenant from 'src/api/hooks/queries/useCurrentTenant';
import { DEMO_CONTACT_DURATION_IN_DAYS, DEMO_PERION_IN_DAYS } from 'src/constants/demo';
import PaymentSupportRequestSentPopup from './PaymentSupportRequestSentPopup';
import useDemoBannerStatus from 'src/hooks/useDemoBannerStatus';
import DemoBannerContent from './DemoBannerContent';

const DemoBanner: FC = () => {
  const apiClient = useApiClient();
  const { shouldShowDemoBanner, closeDemoBanner } = useDemoBannerStatus();

  const [showPopup, setShowPopup] = useState(false);
  const { data: data } = useCurrentTenant();

  const demoDaysRemaining =
    data && differenceInDays(addDays(parseISO(data.createdAt), DEMO_PERION_IN_DAYS), new Date());

  const handleContact = async (): Promise<void> => {
    try {
      setShowPopup(true);
      const hideUntil = addDays(new Date(), DEMO_CONTACT_DURATION_IN_DAYS);
      closeDemoBanner(hideUntil);
      await apiClient.tenant.requestPaymentSupport();
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = (): void => closeDemoBanner();

  return (
    <>
      {shouldShowDemoBanner && (
        <DemoBannerContent
          onContact={handleContact}
          onDismiss={handleClose}
          demoDaysRemaining={demoDaysRemaining ?? 0}
        />
      )}
      <PaymentSupportRequestSentPopup open={showPopup} onClose={() => setShowPopup(false)} />
    </>
  );
};

export default DemoBanner;
