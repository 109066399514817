import { TrashIcon } from '@heroicons/react/24/outline';
import { FC, useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { MaterialResponse, useApiClient } from 'src/api';
import Button from 'src/components/Button';
import InputWithSuggestionsBe from 'src/components/fields/InputWithSuggestionsBe';
import { NumberFieldControlled } from 'src/components/fields/NumberField';
import { SelectControlled } from 'src/components/fields/Select';
import { TextFieldControlled } from 'src/components/fields/Textfield';
import { isMdOrSmaller } from 'src/helpers/screenSize';
import useUnits from 'src/hooks/useUnits';
import useWindowWidth from 'src/hooks/useWindowWidth';
import { FinishOrderFormValues } from '../FinishOrderForm/schema';
import useCurrentTenantConfig from 'src/api/hooks/queries/useCurrentTenantConfig';
import clsx from 'clsx';

type MaterialProps = {
  onDelete: () => void;
  orderProductIndex: number;
  index: number;
  shouldHidePrice?: boolean;
};

const Material: FC<MaterialProps> = ({ onDelete, shouldHidePrice, orderProductIndex, index }) => {
  const apiClient = useApiClient();
  const windowWidth = useWindowWidth();
  const { data: tenantConfig } = useCurrentTenantConfig();
  const form = useFormContext<FinishOrderFormValues>();
  const { control, setValue, watch } = form;
  const isEurPrice = watch('preferredCurrency') === 'EUR';

  const getMaterialOptions = useCallback(
    async (query: string) => {
      const data = await apiClient.material.searchMaterials({ query, limit: 100 });

      function createOptionLabel(material: MaterialResponse): string {
        let label = material.materialName;

        if (tenantConfig?.materialTable?.informativeMaterialSuggetions && material.supplierCode) {
          label += ` (${material.supplierCode})`;
        }

        return label;
      }

      return data.map((material) => ({ label: createOptionLabel(material), value: material.id }));
    },
    [apiClient, tenantConfig],
  );

  const handleOptionClick = useCallback(
    async (id: number) => {
      const material = await apiClient.material.getMaterial({ id });

      setValue(`products.${orderProductIndex}.materials.${index}.material`, material.materialName);
      setValue(
        `products.${orderProductIndex}.materials.${index}.price`,
        isEurPrice ? material.materialValueEur : material.materialValue,
      );
      setValue(`products.${orderProductIndex}.materials.${index}.code`, material.materialCode);
    },
    [apiClient],
  );

  const units = useUnits();

  if (isMdOrSmaller(windowWidth)) {
    return (
      <div className='flex flex-col gap-y-4'>
        <div className='flex items-center gap-x-4'>
          <span className='font-semibold text-xl'>
            <FormattedMessage id='app.finish_order.material' values={{ value: index + 1 }} />
          </span>
          <Button className='px-0 min-w-fit' variant='cta' onClick={onDelete}>
            <TrashIcon className='h-6' />
          </Button>
        </div>
        <Controller
          control={control}
          name={`products.${orderProductIndex}.materials.${index}.material`}
          render={({ field: { value, ...rest } }) => (
            <InputWithSuggestionsBe
              {...rest}
              label={<FormattedMessage id='app.material.name' />}
              value={value ?? ''}
              getOptions={getMaterialOptions}
              onOptionClick={handleOptionClick}
            />
          )}
        />
        <TextFieldControlled
          control={control}
          name={`products.${orderProductIndex}.materials.${index}.code`}
          label={<FormattedMessage id='app.material.code' defaultMessage='code' />}
          className='mb-0'
        />
        <NumberFieldControlled
          control={control}
          name={`products.${orderProductIndex}.materials.${index}.quantity`}
          label={<FormattedMessage id='app.common.quantity' defaultMessage='quantity' />}
        />
        <SelectControlled
          label={<FormattedMessage id='app.material.unit' defaultMessage='unit' />}
          control={control}
          name={`products.${orderProductIndex}.materials.${index}.unit`}
          options={units}
          getOptionValue={(v) => v.label}
          getOptionLabel={(v) => v.label}
        />
        <NumberFieldControlled
          label={<FormattedMessage id='app.material.value' defaultMessage='value' />}
          control={control}
          name={`products.${orderProductIndex}.materials.${index}.price`}
        />
      </div>
    );
  }

  return (
    <div
      className={clsx(
        'grid gap-x-6 items-center',
        tenantConfig?.materialTable?.informativeMaterialSuggetions
          ? 'grid-cols-[8fr,2fr,2fr,2fr,2fr,1fr]'
          : 'grid-cols-[5fr,2fr,2fr,2fr,2fr,1fr]',
      )}
    >
      <Controller
        control={control}
        name={`products.${orderProductIndex}.materials.${index}.material`}
        render={({ field: { value, ...rest } }) => (
          <InputWithSuggestionsBe
            {...rest}
            value={value ?? ''}
            getOptions={getMaterialOptions}
            onOptionClick={handleOptionClick}
          />
        )}
      />
      <TextFieldControlled control={control} name={`products.${orderProductIndex}.materials.${index}.code`} />
      <NumberFieldControlled control={control} name={`products.${orderProductIndex}.materials.${index}.quantity`} />
      <SelectControlled
        control={control}
        name={`products.${orderProductIndex}.materials.${index}.unit`}
        options={units}
        getOptionValue={(v) => v.label}
        getOptionLabel={(v) => v.label}
      />
      {!shouldHidePrice && (
        <NumberFieldControlled control={control} name={`products.${orderProductIndex}.materials.${index}.price`} />
      )}

      <Button className='px-0 min-w-fit' variant='cta' onClick={onDelete}>
        <TrashIcon className='h-6' />
        <FormattedMessage id='app.butons.delete' />
      </Button>
    </div>
  );
};

export default Material;
