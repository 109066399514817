import { PropsWithChildren, useCallback, useState } from 'react';
import { useContext } from 'react';
import { SetStateAction } from 'react';
import { Dispatch } from 'react';
import { FC } from 'react';
import { createContext } from 'react';
import {
  CheckboxFieldSchemaResponse,
  NumberFieldSchemaResponse,
  SectionSchemaResponse,
  SelectFieldSchemaResponse,
  TextFieldSchemaResponse,
} from 'src/api';
import { noop } from 'src/utils/commonUtils';
import { DropResult, OnDragEndResponder } from 'react-beautiful-dnd';
import { reorder } from './helpers';
import { FORM_BUILDER_DROPPABLE_TYPES } from './constants';
import { insert } from 'ramda';
import { useMemo } from 'react';
import { FormBuilderFieldSchema } from './components/FormBuilderFieldForm/schema';
import { nanoid } from 'nanoid';
import { FormBuilderSectionSchema } from './components/FormBuilderSectionForm/schema';
import useEvent from 'src/hooks/useEvent';

export type FormBuilderSectionValue = Omit<SectionSchemaResponse, 'fields'> & {
  fieldIds: string[];
};
export type FormBuilderFieldValue =
  | TextFieldSchemaResponse
  | NumberFieldSchemaResponse
  | CheckboxFieldSchemaResponse
  | SelectFieldSchemaResponse;
export type FormBuilderSectionWithFieldsValue = FormBuilderSectionValue & {
  fields: FormBuilderFieldValue[];
};

export type FormBuilderContextValue = {
  sections: FormBuilderSectionValue[];
  setSections: Dispatch<SetStateAction<FormBuilderSectionValue[]>>;
  fields: FormBuilderFieldValue[];
  setFields: Dispatch<SetStateAction<FormBuilderFieldValue[]>>;
  onDragEnd: OnDragEndResponder;
  sectionsWithFields: FormBuilderSectionWithFieldsValue[];
  addSection: (data: FormBuilderSectionSchema) => void;
  addSectionField: (sectionId: string, data: FormBuilderFieldSchema) => void;
};

export const FORM_BUILDER_CONTEXT_DEFAULT_VALUE: FormBuilderContextValue = {
  sections: [],
  setFields: noop,
  fields: [],
  setSections: noop,
  onDragEnd: noop,
  sectionsWithFields: [],
  addSection: noop,
  addSectionField: noop,
};

export const FormBuilderContext = createContext<FormBuilderContextValue>(FORM_BUILDER_CONTEXT_DEFAULT_VALUE);

export const useFormBuilderContext = (): FormBuilderContextValue => useContext(FormBuilderContext);
export const useFormBuilderSections = (): FormBuilderSectionValue[] => useContext(FormBuilderContext).sections;
export const useFormBuilderFields = (): FormBuilderFieldValue[] => useContext(FormBuilderContext).fields;

export type UseFormBuilderStateOptionsValue = {
  onChange?: (sections: FormBuilderSectionValue[], fields: FormBuilderFieldValue[]) => void;
  defaultSections?: FormBuilderSectionValue[];
  defaultFields?: FormBuilderFieldValue[];
};

export type UseFormBuilderStateOptionsValueFactory = () => UseFormBuilderStateOptionsValue;

export type UseFormBuilderStateOptions = UseFormBuilderStateOptionsValue | UseFormBuilderStateOptionsValueFactory;

export type UseFormBuilderSectionStateReturn = {
  section: FormBuilderSectionWithFieldsValue;
  deleteSection: () => void;
  updateSection: (data: FormBuilderSectionSchema) => void;
  addField: (data: FormBuilderFieldSchema) => void;
};

export const useFormBuilderSectionState = (id: string): UseFormBuilderSectionStateReturn => {
  const { sectionsWithFields, setSections, setFields, addSectionField } = useFormBuilderContext();
  const section = sectionsWithFields.find((section) => section.id === id);
  if (!section) throw new Error(`Section with id ${id} not found`);

  const deleteSection = useCallback(() => {
    setSections((prev) => prev.filter((section) => section.id !== id));
    setFields((prev) => prev.filter((field) => !section.fieldIds.includes(field.id)));
  }, [id, section]);
  const updateSection = useCallback(
    (newSection: FormBuilderSectionSchema) =>
      setSections((prev) =>
        prev.map((section) =>
          section.id === id
            ? {
                ...section,
                id: nanoid(),
                name: newSection.name,
                label: {
                  id: `app.gen_section.${nanoid()}`,
                  defaultMessage: newSection.label,
                },
                labelAsTh: newSection.labelAsTh,
                noThead: newSection.noThead,
              }
            : section,
        ),
      ),
    [],
  );
  const addField = useCallback((data: FormBuilderFieldSchema) => addSectionField(id, data), [id, addSectionField]);

  return {
    section,
    deleteSection,
    updateSection,
    addField,
  };
};

export type UseFormBuilderFieldStateReturn = {
  field: FormBuilderFieldValue;
  deleteField: () => void;
  updateField: (newField: FormBuilderFieldSchema) => void;
};

export const useFormBuilderFieldState = (id: string): UseFormBuilderFieldStateReturn => {
  const { fields, setFields, setSections } = useFormBuilderContext();
  const field = fields.find((field) => field.id === id);
  if (!field) throw new Error(`Field with id ${id} not found`);

  const deleteField = useCallback(() => {
    setFields((prev) => prev.filter((field) => field.id !== id));
    setSections((prev) => prev.map((section) => ({ ...section, fieldIds: section.fieldIds.filter((i) => i !== id) })));
  }, [id]);

  const updateField = useCallback(
    (data: FormBuilderFieldSchema) =>
      setFields((prev) =>
        prev.map((field) =>
          field.id === id
            ? {
                ...field,
                id,
                name: data.name,
                type: data.type,
                showIfName: data.showIfName,
                label: {
                  id: `app.gen_field.${id}`,
                  defaultMessage: data.label,
                },
                options: data.options,
              }
            : field,
        ),
      ),
    [],
  );

  return {
    field,
    deleteField,
    updateField,
  };
};

export const useFormBuilderState = (options: UseFormBuilderStateOptions = {}): FormBuilderContextValue => {
  const memoOptions = useMemo(() => (typeof options === 'function' ? options() : options), [options]);

  const { defaultSections, defaultFields, onChange } = memoOptions;
  const [sections, setSections] = useState<FormBuilderSectionValue[]>(defaultSections ?? []);
  const [fields, setFields] = useState<FormBuilderFieldValue[]>(defaultFields ?? []);

  const sectionsWithFields = useMemo(
    () =>
      sections.map((section) => ({
        ...section,
        fields: fields
          .filter((field) => section.fieldIds.includes(field.id))
          .sort((a, b) => section.fieldIds.indexOf(a.id) - section.fieldIds.indexOf(b.id)),
      })),
    [sections, fields],
  );

  const onDragEnd = useEvent((result: DropResult) => {
    if (!result.destination) return;

    const { destination, source } = result;

    if (source.droppableId === destination.droppableId && source.index === destination.index) return;

    // reorder sections
    if (result.type === FORM_BUILDER_DROPPABLE_TYPES.ROOT) {
      const nextSections = reorder(source.index, destination.index, sections) as FormBuilderSectionValue[];
      setSections(nextSections);
      onChange?.(nextSections, fields);
      return;
    }

    // reorder a column
    if (result.type === FORM_BUILDER_DROPPABLE_TYPES.LIST && source.droppableId === destination.droppableId) {
      const targetSection = sections.find((section) => section.id === source.droppableId);
      if (!targetSection) return sections;

      const newFieldIds = reorder(source.index, destination.index, targetSection.fieldIds);
      const newSections = sections.map((section) =>
        section.id === targetSection.id ? { ...section, fieldIds: newFieldIds } : section,
      );

      setSections(newSections);
      onChange?.(newSections, fields);

      return;
    }

    // reordering different columns
    const sourceSection = sections.find((section) => section.id === source.droppableId);
    const destinationSection = sections.find((section) => section.id === destination.droppableId);
    if (!destinationSection || !sourceSection) return sections;

    const newSourceFields = sourceSection.fieldIds.filter((fieldId) => fieldId !== result.draggableId);
    const newDestinationFields = insert(destination.index, result.draggableId, destinationSection.fieldIds);

    const newSections = sections.map((section) => {
      if (section.id === sourceSection.id) return { ...section, fieldIds: newSourceFields };
      if (section.id === destinationSection.id) return { ...section, fieldIds: newDestinationFields };
      return section;
    });

    setSections(newSections);
    onChange?.(newSections, fields);
  });

  const addSection = useEvent((data: FormBuilderSectionSchema) => {
    const nextSections = [
      ...sections,
      {
        id: nanoid(),
        name: data.name,
        label: {
          id: `app.gen_section.${nanoid()}`,
          defaultMessage: data.label,
        },
        fieldIds: [],
      },
    ];

    setSections(nextSections);
    onChange?.(nextSections, fields);
  });

  const addSectionField = useEvent((sectionId: string, data: FormBuilderFieldSchema) => {
    const id = data.id || nanoid();
    const nextFields = [
      ...fields,
      {
        id,
        name: data.name,
        type: data.type,
        showIfName: data.showIfName,
        label: {
          id: `app.gen_field.${id}`,
          defaultMessage: data.label,
        },
        options: data.options,
      },
    ];
    const nextSections = sections.map((section) =>
      section.id === sectionId ? { ...section, fieldIds: [...section.fieldIds, id] } : section,
    );

    setFields(nextFields);
    setSections(nextSections);
    onChange?.(nextSections, nextFields);
  });

  const handleSetSections = useEvent<typeof setSections>((updater) => {
    const nextSections = typeof updater === 'function' ? updater(sections) : updater;
    setSections(nextSections);
    onChange?.(nextSections, fields);
  });

  const handleSetFields = useEvent<typeof setFields>((updater) => {
    const nextFields = typeof updater === 'function' ? updater(fields) : updater;
    setFields(nextFields);
    onChange?.(sections, nextFields);
  });

  return {
    sections,
    setSections: handleSetSections,
    fields,
    setFields: handleSetFields,
    onDragEnd,
    sectionsWithFields,
    addSection,
    addSectionField,
  };
};

export const FormBuilderContextProvider: FC<
  PropsWithChildren<{
    value: FormBuilderContextValue;
  }>
> = ({ children, value }) => {
  return <FormBuilderContext.Provider value={value}>{children}</FormBuilderContext.Provider>;
};
