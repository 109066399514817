import { useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { SelectControlled } from 'src/components/fields/Select';
import { FinishOrderFormValues } from '../../../schema';
import { FC } from 'react';
import useAllDeliveryTariffs from 'src/api/hooks/queries/useAllDeliveryTariffs';

export type TariffDeliveryFormProps = {
  className?: string;
  hiddenFields?: string[];
};

const TariffDeliveryForm: FC<TariffDeliveryFormProps> = ({ className, hiddenFields }) => {
  const intl = useIntl();
  const { control } = useFormContext<FinishOrderFormValues>();
  const preferredCurrency = useWatch<FinishOrderFormValues>({ name: 'preferredCurrency' });
  const { data: deliveryTariffs } = useAllDeliveryTariffs();

  if (hiddenFields?.includes('deliveryTariffId')) return null;

  return (
    <SelectControlled
      className={className}
      label={<FormattedMessage id='app.common.price' />}
      control={control}
      name='deliveryTariffId'
      options={deliveryTariffs ?? []}
      getOptionLabel={(option) =>
        `${option.name} (${intl.formatNumber(preferredCurrency === 'CZK' ? option.price : option.priceEur, {
          style: 'currency',
          currency: preferredCurrency,
        })})`
      }
      getOptionValue={(option) => option.id}
    />
  );
};

export default TariffDeliveryForm;
