import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { ProductResponse } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

const useContactProduct = (productId: number): UseQueryResult<ProductResponse> => {
  const client = useApiClient();
  const { queryKey } = queryKeysFactory.products.detail(productId);

  return useQuery(
    queryKey,
    async () => {
      const data = client.product.getProduct({ id: productId });
      return data;
    },
    {
      refetchInterval: false,
      staleTime: 1000 * 60 * 5,
    },
  );
};

export default useContactProduct;
