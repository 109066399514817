import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { UserResponse } from 'src/api';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

const useRestoreUser = (): UseMutationResult<UserResponse, unknown, number> => {
  const client = useApiClient();
  const queryClient = useQueryClient();

  return useMutation<UserResponse, unknown, number>(
    async (userId: number) => {
      const data = await client.user.restoreUser({ id: userId });
      return data as UserResponse;
    },
    {
      onSuccess: (_, userId) => {
        queryClient.invalidateQueries(queryKeysFactory.users.list.queryKey);
        queryClient.invalidateQueries(queryKeysFactory.users.detail(userId));
        queryClient.invalidateQueries(queryKeysFactory.tenant.current.queryKey);
        toast(<FormattedMessage id='app.toasts.update_success' />, {
          type: 'success',
        });
      },
      onError: () => {
        toast(<FormattedMessage id='app.toasts.update_fail' />, {
          type: 'error',
        });
      },
    },
  );
};

export default useRestoreUser;
