import { FC } from 'react';
import { HeadquartersResponse } from 'src/api';
import { createColumnHelper } from '@tanstack/react-table';
import { FormattedMessage } from 'react-intl';
import { ExtendedColumnDef } from 'src/types/table';
import { useNavigate } from 'react-router-dom';
import DumbTable, { DumbTableProps } from 'src/components/Table/DumbTable';

export type HeadquatersTableProps = Omit<DumbTableProps<HeadquartersResponse>, 'columns'>;

const columnHelper = createColumnHelper<HeadquartersResponse>();

const columns: ExtendedColumnDef<HeadquartersResponse>[] = [
  {
    ...columnHelper.accessor('companyName', {
      id: 'companyName',
      header: () => <FormattedMessage id='app.parameter_title.company_name' />,
      enableColumnFilter: false,
      enableSorting: false,
      cell: (info) => info.renderValue(),
      size: 200,
    }),
  },
  {
    ...columnHelper.accessor('companyStreet', {
      id: 'companyStreet',
      header: () => <FormattedMessage id='app.parameter_title.company_street' />,
      enableColumnFilter: false,
      enableSorting: false,
      cell: (info) => info.renderValue(),
      size: 175,
    }),
  },
  {
    ...columnHelper.accessor('companyCity', {
      id: 'companyCity',
      header: () => <FormattedMessage id='app.parameter_title.company_city' />,
      enableColumnFilter: false,
      enableSorting: false,
      cell: (info) => info.renderValue(),
      size: 150,
    }),
  },
  {
    ...columnHelper.accessor('companyZIP', {
      id: 'companyZIP',
      header: () => <FormattedMessage id='app.parameter_title.company_zip' />,
      enableColumnFilter: false,
      enableSorting: false,
      cell: (info) => info.renderValue(),
      size: 150,
    }),
  },
  {
    ...columnHelper.accessor('companyIn', {
      id: 'companyIn',
      header: () => <FormattedMessage id='app.parameter_title.IC' />,
      enableColumnFilter: false,
      enableSorting: false,
      cell: (info) => info.renderValue(),
      size: 175,
    }),
  },
  {
    ...columnHelper.accessor('companyVatNumber', {
      id: 'companyVatNumber',
      header: () => <FormattedMessage id='app.parameter_title.DIC' />,
      enableColumnFilter: false,
      enableSorting: false,
      cell: (info) => info.renderValue(),
      size: 175,
    }),
  },
  {
    ...columnHelper.accessor('serviceDurationRate', {
      id: 'serviceDurationRate',
      header: () => <FormattedMessage id='app.parameter_title.service_duration_rate' />,
      enableColumnFilter: false,
      enableSorting: false,
      cell: (info) => info.renderValue(),
      size: 100,
    }),
  },
  {
    ...columnHelper.accessor('serviceMileageRate', {
      id: 'serviceMileageRate',
      header: () => <FormattedMessage id='app.parameter_title.service_mileage_rate' />,
      enableColumnFilter: false,
      enableSorting: false,
      cell: (info) => info.renderValue(),
      size: 100,
    }),
  },
  {
    ...columnHelper.accessor('serviceTimeOnRoadRate', {
      id: 'serviceTimeOnRoadRate',
      header: () => <FormattedMessage id='app.parameter_title.service_time_on_road_rate' />,
      enableColumnFilter: false,
      enableSorting: false,
      cell: (info) => info.renderValue(),
      size: 100,
    }),
  },
  {
    ...columnHelper.accessor('defaultVatTariff.tariffValue', {
      id: 'defaultVatTariff.tariffValue',
      header: () => <FormattedMessage id='app.vat_tariff.value' />,
      enableColumnFilter: false,
      enableSorting: false,
      cell: (info) => info.renderValue(),
      size: 100,
    }),
  },
];

const HeadquatersTable: FC<HeadquatersTableProps> = (props) => {
  const navigate = useNavigate();

  return (
    <DumbTable
      columns={columns}
      {...props}
      handleRowClick={(original) => navigate(`/admin/parameter/headquarters/${original.id}`)}
    />
  );
};

HeadquatersTable.displayName = 'HeadquatersTable';

export default HeadquatersTable;
