/* eslint-disable @typescript-eslint/no-empty-function */

import { curry, fromPairs, pipe, toPairs, map, addIndex, filter } from 'ramda';

/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const noop = () => {};

export const dotToComma = (val: string): string => {
  return val?.match(/./g) ? val.replace('.', ',') : val;
};

export const mapKeys = curry((fn: (key: string) => string, obj) =>
  pipe(
    toPairs,
    map(([k, v]) => [fn(k.toString()), v] as [string, unknown]),
    fromPairs,
  )(obj),
);

export const mapIndexed = addIndex(map);
export const filterIndexed = addIndex(filter);
