import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { CreateEmployeeRequest, EmployeeResponse } from 'src/api';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';
import { WithContactId } from 'src/types/mutations';

const useAddContactEmployee = (): UseMutationResult<
  EmployeeResponse,
  unknown,
  WithContactId<CreateEmployeeRequest>
> => {
  const client = useApiClient();
  const queryClient = useQueryClient();

  return useMutation<EmployeeResponse, unknown, WithContactId<CreateEmployeeRequest>>(
    async (employee: WithContactId<CreateEmployeeRequest>) => {
      const data = await client.employee.createEmployee({ contactId: employee.contactId, requestBody: employee });
      return data as EmployeeResponse;
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(queryKeysFactory.employees.list(variables.contactId).queryKey);
        toast(<FormattedMessage id='app.toasts.add_success' defaultMessage='Záznam úspěšně přidán' />, {
          type: 'success',
        });
      },
      onError: () => {
        toast(<FormattedMessage id='app.toasts.add_fail' defaultMessage='Chyba přidáni záznamu' />, {
          type: 'error',
        });
      },
    },
  );
};

export default useAddContactEmployee;
