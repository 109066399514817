import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { OrderStatusResponse } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

const useStatuses = (): UseQueryResult<OrderStatusResponse[]> => {
  const client = useApiClient();
  const { queryKey } = queryKeysFactory.statuses.list;

  return useQuery(
    queryKey,
    async () => {
      const data = client.status.getStatusList();
      return data;
    },
    {
      refetchInterval: false,
      staleTime: Infinity,
    },
  );
};

export default useStatuses;
