import { atom } from 'recoil';

export const isSidebarOpenState = atom({
  key: 'isSidebarOpen',
  default: false,
});

export const isAdminCategoryOpenState = atom<boolean>({
  key: 'isAdminCategoryOpen',
  default: false,
});

export const isDevCategoryOpenState = atom<boolean>({
  key: 'isDevCategoryOpen',
  default: false,
});

export const isSettingCategoryOpenState = atom<boolean>({
  key: 'isSettingCategoryOpen',
  default: false,
});

export const isSysAdminCategoryOpenState = atom<boolean>({
  key: 'isSysAdminCategoryOpen',
  default: false,
});
