import clsx from 'clsx';
import { FC } from 'react';
import { SectionSchemaResponse } from 'src/api/.gen/models/SectionSchemaResponse';
import GenSection from 'src/pages/TechnicianOrderFinishPage/components/GenSection';

export type FinishFormGenTemplateProps = {
  className?: string;
  sections: Array<SectionSchemaResponse>;
  baseNameFactory?: () => string;
};

const FinishFormGenTemplate: FC<FinishFormGenTemplateProps> = ({ className, sections, baseNameFactory }) => {
  return (
    <div className={clsx(className, 'flex flex-col')}>
      {sections?.map((section) => (
        <GenSection key={section.id} section={section} baseNameFactory={baseNameFactory} />
      ))}
    </div>
  );
};

FinishFormGenTemplate.displayName = 'FinishFormGenTemplate';

export default FinishFormGenTemplate;
