import { FC } from 'react';
import { BranchResponse } from 'src/api';
import { createColumnHelper } from '@tanstack/react-table';
import { FormattedMessage } from 'react-intl';
import { ExtendedColumnDef } from 'src/types/table';
import Table, { TableProps } from 'src/components/Table';
import { useNavigate } from 'react-router-dom';
import TextFilter from 'src/components/Table/filters/TextFilter';

export type BranchesTableProps = Omit<TableProps<BranchResponse>, 'columns'>;

const columnHelper = createColumnHelper<BranchResponse>();

const columns: ExtendedColumnDef<BranchResponse>[] = [
  {
    ...columnHelper.accessor('name', {
      id: 'branchName',
      header: () => <FormattedMessage id='app.branch.name' />,
      enableColumnFilter: true,
      enableSorting: true,
      cell: (info) => info.renderValue(),
    }),
    renderFilter: (column) => <TextFilter column={column} />,
  },
  {
    ...columnHelper.accessor('city', {
      id: 'branchCity',
      header: () => <FormattedMessage id='app.common.city' />,
      enableColumnFilter: true,
      enableSorting: true,
      cell: (info) => info.renderValue(),
    }),
    renderFilter: (column) => <TextFilter column={column} />,
  },
  {
    ...columnHelper.accessor('street', {
      id: 'branchStreet',
      header: () => <FormattedMessage id='app.common.street' />,
      enableColumnFilter: true,
      enableSorting: true,
      cell: (info) => info.renderValue(),
    }),
    renderFilter: (column) => <TextFilter column={column} />,
  },
  {
    ...columnHelper.accessor('zip', {
      id: 'branchZip',
      header: () => <FormattedMessage id='app.common.zip' />,
      enableColumnFilter: true,
      enableSorting: true,
      cell: (info) => info.renderValue(),
    }),
    renderFilter: (column) => <TextFilter column={column} />,
  },
  {
    ...columnHelper.accessor('company.companyName', {
      id: 'companyName',
      header: () => <FormattedMessage id='app.contact.company_name' defaultMessage='Název společnosti' />,
      enableColumnFilter: true,
      enableSorting: false,
      cell: (info) => info.renderValue(),
    }),
    renderFilter: (column) => <TextFilter column={column} />,
  },
  {
    ...columnHelper.accessor('phoneNumber', {
      id: 'branchPhoneNumber',
      header: () => <FormattedMessage id='app.common.phone' />,
      enableColumnFilter: false,
      enableSorting: true,
      cell: (info) => info.renderValue(),
    }),
  },
  {
    ...columnHelper.accessor('email', {
      id: 'branchEmail',
      header: () => <FormattedMessage id='app.common.email' />,
      enableColumnFilter: false,
      enableSorting: true,
      cell: (info) => info.renderValue(),
    }),
  },
  {
    ...columnHelper.accessor('vat', {
      id: 'vat',
      header: () => <FormattedMessage id='app.common.branch.ico' />,
      enableColumnFilter: false,
      enableSorting: false,
      cell: (info) => info.renderValue(),
    }),
  },
  {
    ...columnHelper.accessor('contactPerson', {
      id: 'contactPerson',
      header: () => <FormattedMessage id='app.branch.contact_person' />,
      enableColumnFilter: false,
      enableSorting: false,
      cell: (info) => info.renderValue(),
    }),
  },
  {
    ...columnHelper.accessor('deletedAt', {
      id: 'deleted_at',
      header: () => <FormattedMessage id='app.contact.active_user' />,
      enableColumnFilter: false,
      enableSorting: true,
      cell: (info) =>
        info.getValue() ? <FormattedMessage id='app.common.no' /> : <FormattedMessage id='app.common.yes' />,
      size: 220,
    }),
  },
];

const BranchesTable: FC<BranchesTableProps> = (props) => {
  const navigate = useNavigate();

  return (
    <Table
      columns={columns}
      handleRowClick={(original) => navigate(`/contact/${original.companyId}/branch/${original.id}`)}
      {...props}
    />
  );
};

BranchesTable.displayName = 'BranchesTable';

export default BranchesTable;
