import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { useApiClient, CreateRoutineServicesRequest, RoutineServicesResponse } from 'src/api';
import queryKeysFactory from 'src/store/queryKeysFactory';

const useAddRoutineService = (): UseMutationResult<RoutineServicesResponse, unknown, CreateRoutineServicesRequest> => {
  const queryClient = useQueryClient();
  const client = useApiClient();

  return useMutation(async (body) => client.routineServices.createRoutineServices({ requestBody: body }), {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeysFactory.routineServices.list.queryKey);
      toast(<FormattedMessage id='app.toasts.add_success' defaultMessage='Záznam úspěšně přidán' />, {
        type: 'success',
      });
    },
    onError: () => {
      toast(<FormattedMessage id='app.toasts.add_fail' defaultMessage='Chyba přidáni záznamu' />, {
        type: 'error',
      });
    },
  });
};
export default useAddRoutineService;
