import React, { memo, useRef } from 'react';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import SignatureCanvas from 'react-signature-canvas';
import HelperText from '../common/HelperText';

export type SignatureValue = {
  image: Blob | null;
  dataUrl: string | null;
};

interface SignatureProps {
  value: SignatureValue;
  onChange: (value: SignatureValue) => void;
  error?: string;
}

const Signature: React.FC<SignatureProps> = ({ error, value, onChange }) => {
  const sigPad = useRef<SignatureCanvas | null>();

  const getSignatureRef = (element: SignatureCanvas | null): void => {
    sigPad.current = element;
  };

  const handleChange = (): void => {
    sigPad.current?.getCanvas().toBlob((blob) => {
      onChange({
        image: blob,
        dataUrl: sigPad.current?.toDataURL() ?? null,
      });
    });
  };

  const handleClear = (): void => {
    sigPad.current?.clear();
  };

  useEffect(() => {
    if (!value?.dataUrl) return;

    sigPad.current?.fromDataURL(value.dataUrl, { width: 320, height: 200 });
  }, []);

  return (
    <>
      <div className='flex flex-col gap-y-2 w-max'>
        <div className='border bg-white w-80 border-gray-300'>
          <SignatureCanvas
            penColor='black'
            ref={(ref) => getSignatureRef(ref)}
            canvasProps={{ width: 320, height: 200 }}
            onEnd={handleChange}
          />
        </div>

        <button
          className='w-full rounded-md py-1 bg-dangerous hover:bg-error text-white'
          type='button'
          onClick={() => handleClear()}
        >
          <FormattedMessage id='app.buttons.clear' />
        </button>

        {error && <HelperText variant='error'>{error}</HelperText>}
      </div>
    </>
  );
};

export default memo(Signature);
