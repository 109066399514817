import { FC, useCallback } from 'react';
import useUser from 'src/api/hooks/queries/useUser';
import useUpdateUser from 'src/api/hooks/mutations/user/useUpdateUser';
import { usePopupContext } from 'src/components/Popup/hook';
import { SubmitHandler } from 'react-hook-form';
import UserForm from '../../Users/UserForm';
import { UserSchema } from 'src/pages/Users/UserForm/schema';

export type UpdateUserFormProps = {
  className?: string;
  userId: number;
};

const UpdateUserForm: FC<UpdateUserFormProps> = ({ className, userId }) => {
  const updateUser = useUpdateUser();
  const { data } = useUser(userId);
  const { onClose } = usePopupContext();

  const onSubmit: SubmitHandler<UserSchema> = useCallback((data) => {
    updateUser.mutate({ ...data, id: userId });
    onClose();
  }, []);

  return <UserForm className={className} defaultValues={data as UserSchema} onSubmit={onSubmit} />;
};

UpdateUserForm.displayName = 'UpdateUserForm';

export default UpdateUserForm;
