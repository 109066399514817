import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { OrderResponse } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import { OrderSchema, orderSchemaToCreateOrderRequest } from 'src/pages/OrdersPage/ServiceForm/schema';
import queryKeysFactory from 'src/store/queryKeysFactory';
import { WithId } from 'src/types/mutations';

const useUpdateOrder = (): UseMutationResult<OrderResponse, unknown, WithId<OrderSchema>> => {
  const client = useApiClient();
  const queryClient = useQueryClient();

  return useMutation<OrderResponse, unknown, WithId<OrderSchema>>(
    async (order: WithId<OrderSchema>) => {
      const requestBody = orderSchemaToCreateOrderRequest(order);
      const data = await client.orders.updateData({ id: order.id, requestBody });
      return data as OrderResponse;
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(queryKeysFactory.orders.list.queryKey);
        queryClient.invalidateQueries(queryKeysFactory.orders.detail(variables.id).queryKey);
        queryClient.invalidateQueries(queryKeysFactory.allTechniciansWeekOrders._def);
        queryClient.invalidateQueries(queryKeysFactory.technicianMonthOrders._def);

        toast(<FormattedMessage id='app.toasts.update_success' defaultMessage='Záznam úspěšně zmenen' />, {
          type: 'success',
        });
      },
      onError: () => {
        toast(<FormattedMessage id='app.toasts.update_fail' defaultMessage='Chyba zmeny záznamu' />, {
          type: 'error',
        });
      },
    },
  );
};

export default useUpdateOrder;
