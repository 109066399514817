import { FC, useState } from 'react';
import SearchBlock from 'src/components/SearchBlock';
import useEvent from 'src/hooks/useEvent';
import useTableState from 'src/components/Table/hooks/useTableState';
import BranchesTable from './BranchesTable';
import useBranchesFromSearch from 'src/api/hooks/queries/useBranchesFromSearch';
import { FormattedMessage } from 'react-intl';
import useBranches from 'src/api/hooks/queries/useBranches';
import { BranchFilterQuery } from 'src/api';
import PageContentHeader from 'src/components/PageContentHeader';
import useCurrentTenantConfig from 'src/api/hooks/queries/useCurrentTenantConfig';
import PageCard from 'src/components/PageCard';

export type BranchesPageProps = {
  //
};

const BranchesPage: FC<BranchesPageProps> = () => {
  const { data: tenantConfig } = useCurrentTenantConfig();
  const [searchValue, setSearchValue] = useState('');
  const [queryString, setQueryString] = useState('');
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const handleSearch = useEvent(() => setQueryString(searchValue));
  const {
    columnFilters,
    debouncedServerFriendlyFilters,
    sortingQuery,
    setColumnFilters,
    sorting,
    setSorting,
    pagination,
    setPagination,
  } = useTableState<BranchFilterQuery>();

  const shouldUseFilters = tenantConfig?.enableTableSearchForBranches ?? false;

  const { data: searchData } = useBranchesFromSearch(queryString, pagination, sortingQuery, {
    enabled: !shouldUseFilters,
  });
  const { data: filterData } = useBranches(debouncedServerFriendlyFilters, sortingQuery, pagination, {
    enabled: shouldUseFilters,
  });

  const data = shouldUseFilters ? filterData : searchData;

  return (
    <>
      <PageContentHeader
        className='mb-12'
        title={<FormattedMessage id='app.branches_page.title' />}
        onSecondaryButtonClick={() => setIsFilterOpen((p) => !p)}
        hidePrimaryButton={true}
        hideSecondaryButton={!shouldUseFilters}
      />

      <PageCard className='flex flex-col gap-12'>
        {!shouldUseFilters && (
          <SearchBlock
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            handleSearch={handleSearch}
            description={<FormattedMessage id='app.branches_page.search.helper_text' />}
          />
        )}
        <BranchesTable
          isFilterOpen={isFilterOpen}
          columnFilters={columnFilters}
          handleFilter={setColumnFilters}
          pagination={pagination}
          handlePaginationChange={setPagination}
          sorting={sorting}
          handleSort={setSorting}
          data={data?.items ?? []}
          paginationMeta={data?.meta}
        />
      </PageCard>
    </>
  );
};

BranchesPage.displayName = 'BranchesPage';

export default BranchesPage;
