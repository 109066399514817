import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { CreateProductRequest, ProductResponse } from 'src/api';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';
import { WithId } from 'src/types/mutations';

const useUpdateProduct = (): UseMutationResult<ProductResponse, unknown, WithId<CreateProductRequest>> => {
  const client = useApiClient();
  const queryClient = useQueryClient();

  return useMutation<ProductResponse, unknown, WithId<CreateProductRequest>>(
    async (product: WithId<CreateProductRequest>) => {
      const data = await client.product.updateProduct({
        id: product.id,
        requestBody: {
          ...product,
          branchId: product.branchId ?? null,
        },
      });
      return data as ProductResponse;
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(queryKeysFactory.products.detail(variables.id).queryKey);
        queryClient.invalidateQueries(queryKeysFactory.products.list._def);
        toast(<FormattedMessage id='app.toasts.update_success' defaultMessage='Záznam úspěšně zmenen' />, {
          type: 'success',
        });
      },
      onError: () => {
        toast(<FormattedMessage id='app.toasts.update_fail' defaultMessage='Chyba zmeny záznamu' />, {
          type: 'error',
        });
      },
    },
  );
};

export default useUpdateProduct;
