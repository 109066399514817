import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { NotificationResponse } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

const useTenantNotificationsSettings = (): UseQueryResult<NotificationResponse[]> => {
  const client = useApiClient();
  const { queryKey } = queryKeysFactory.tenantNotificationsSettings.list;

  return useQuery(queryKey, () => client.notificationSettings.getNotificationList());
};

export default useTenantNotificationsSettings;
