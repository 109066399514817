import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { CreateProductRequest, ProductResponse } from 'src/api';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

const useAddProduct = (): UseMutationResult<ProductResponse, unknown, CreateProductRequest> => {
  const client = useApiClient();
  const queryClient = useQueryClient();

  return useMutation<ProductResponse, unknown, CreateProductRequest>(
    async (product: CreateProductRequest) => {
      const data = await client.product.createProduct({
        requestBody: {
          ...product,
          customerId: product.customerId ?? null,
          branchId: product.branchId ?? null,
        },
      });
      return data as ProductResponse;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeysFactory.products.list._def);
        toast(<FormattedMessage id='app.toasts.add_success' defaultMessage='Záznam úspěšně přidán' />, {
          type: 'success',
        });
      },
      onError: () => {
        toast(<FormattedMessage id='app.toasts.add_fail' defaultMessage='Chyba přidáni záznamu' />, {
          type: 'error',
        });
      },
    },
  );
};

export default useAddProduct;
