import React, { createContext, useContext, useState } from 'react';
import Cookies from 'js-cookie';
import { noop } from 'src/utils/commonUtils';
import { useCallback } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { HAS_CLOSED_DEMO_BANNER, HAS_CLOSED_DEMO_BANNER_UNTIL } from 'src/constants/demo';

interface AuthContextProps {
  token: string;
  email: string;
  setAuthToken: (value: string, canSaveToken?: boolean) => void;
  setAuthEmail: (value: string) => void;
  clearAuth: () => void;
}

interface AuthProviderProps {
  children: React.ReactNode;
}

const defaultState: AuthContextProps = {
  token: '',
  email: '',
  clearAuth: noop,
  setAuthToken: () => {
    return;
  },
  setAuthEmail: noop,
};

export const AuthContext = createContext<AuthContextProps>(defaultState);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [token, setToken] = useState(Cookies.get('token') || '');
  const [email, setEmail] = useState(Cookies.get('email') || '');
  const [, setHasClosedDemoBanner, removeHasClosedDemoBanner] = useLocalStorage(HAS_CLOSED_DEMO_BANNER, false);
  const [hasClosedDemoBannerUntil] = useLocalStorage<string | null>(HAS_CLOSED_DEMO_BANNER_UNTIL, null);

  const handleSetToken = (tokenHandle: string, canSaveToken?: boolean): void => {
    if (!hasClosedDemoBannerUntil) setHasClosedDemoBanner(false);
    setToken(tokenHandle);
    if (canSaveToken) {
      Cookies.set('token', tokenHandle, { expires: 365, path: '/' });
    } else {
      Cookies.set('token', tokenHandle, { path: '/' });
    }
  };
  const handleSetAuthEmail = (email: string): void => {
    if (!hasClosedDemoBannerUntil) setHasClosedDemoBanner(false);
    setEmail(email);
    Cookies.set('email', email, { path: '/' });
  };

  const clearAuth = useCallback(() => {
    if (!hasClosedDemoBannerUntil) removeHasClosedDemoBanner();
    Cookies.remove('token');
    Cookies.remove('email');
    setToken('');
    setEmail('');
  }, []);

  return (
    <AuthContext.Provider
      value={{
        token,
        email,
        clearAuth,
        setAuthToken: handleSetToken,
        setAuthEmail: handleSetAuthEmail,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextProps => useContext(AuthContext);
