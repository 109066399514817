import { FC } from 'react';
import clsx from 'clsx';
import Button from 'src/components/Button';
import { FormattedMessage } from 'react-intl';
import { useRef } from 'react';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { mapKeys } from 'src/utils/commonUtils';
import { fromRhfSafeId, toRhfSafeId } from '../../helpers';
import parseJsonFile from 'src/helpers/parseJsonFile';
import { keys } from 'ramda';
import saveBlob from 'src/helpers/saveBlob';

export type ImportExportButtonsProps = {
  className?: string;
  language: string;
  setAddedTranslations: (ids: string[]) => void;
};

const ImportExportButtons: FC<ImportExportButtonsProps> = ({ className, setAddedTranslations, language }) => {
  const importInputRef = useRef<HTMLInputElement>(null);
  const { getValues, setValue } = useFormContext();

  const handleImport = useCallback((): void => {
    if (!importInputRef.current) return;

    importInputRef.current.click();
  }, []);

  const handleImportInputChange = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    if (!e.target.files) return;
    const values = getValues();
    const valuesIds = keys(values);

    const file = e.target.files[0];
    const data = await parseJsonFile(file);
    const mapped = mapKeys(toRhfSafeId, data) as Record<string, string>;
    const newIds = keys(mapped);

    const addedTranslations = newIds.filter((id) => !valuesIds.includes(id));
    setAddedTranslations(addedTranslations);

    for (const key in mapped) setValue(key, mapped[key]);
  };

  const hanldeExport = (): void => {
    const values = getValues();
    const mapped = mapKeys(fromRhfSafeId, values);
    const blob = new Blob([JSON.stringify(mapped)], { type: 'application/json' });
    saveBlob(blob, `translations-${language}-${new Date().toISOString()}.json`);
  };

  return (
    <div className={clsx(className, 'w-full flex justify-end gap-x-4 mt-8')}>
      <Button variant='secondary' onClick={handleImport}>
        <input ref={importInputRef} onChange={handleImportInputChange} accept='.json' type='file' className='hidden' />
        <FormattedMessage id='app.buttons.import' />
      </Button>
      <Button onClick={hanldeExport}>
        <FormattedMessage id='app.buttons.export' />
      </Button>
    </div>
  );
};

ImportExportButtons.displayName = 'ImportExportButtons';

export default ImportExportButtons;
