import { ReactNode, useEffect } from 'react';
import { ChangeEvent, FocusEvent, forwardRef, InputHTMLAttributes, useCallback, useState } from 'react';
import { FieldValues, FieldPath, UseControllerProps, Controller } from 'react-hook-form';
import { FieldProps } from 'src/types/forms';
import { twMerge } from 'tailwind-merge';
import FieldLabel from '../FieldLabel';
import FieldError from '../FieldError';
import { getFieldClassName } from '../helpers';

export type NumberFieldBaseProps = InputHTMLAttributes<HTMLInputElement> & {
  hideErrorMessage?: boolean;
  error?: ReactNode;
  label?: ReactNode;
  asterisk?: boolean;
  labelClassName?: string;
  labelTextClassName?: string;
  inputClassName?: string;
};

export type NumberFieldProps = Omit<NumberFieldBaseProps, 'value'> & FieldProps<number | null>;

const NUMBER_PATTERN = '^-?[0-9]\\d*\\.?\\d*$';
const NUMBER_REGEX = new RegExp(NUMBER_PATTERN);

const valueToVisible = (value: number | null): string => {
  if (value === null || value === undefined) return '';
  return value.toString();
};

export const NumberField = forwardRef<HTMLInputElement, NumberFieldProps>((props, ref) => {
  const {
    onChange,
    onBlur,
    disabled,
    className,
    labelClassName,
    label,
    labelTextClassName,
    inputClassName,
    error,
    asterisk,
    hideErrorMessage,
    ...rest
  } = props;
  const [visibleValue, setVisibleValue] = useState<string | undefined>(props.value?.toString() ?? '0');

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const targetValue = e.target.value.replace(',', '.');

      if (targetValue === '') {
        setVisibleValue('');
        onChange(0);
      }

      if (!NUMBER_REGEX.test(targetValue)) {
        e.preventDefault();
        return;
      }

      if (targetValue.endsWith('.')) {
        setVisibleValue(targetValue);
        return;
      }

      const numberValue = parseFloat(targetValue);
      const visible = targetValue.endsWith('.') || targetValue.endsWith('0') ? targetValue : numberValue.toString();
      setVisibleValue(visible);
      onChange?.(numberValue);
    },
    [onChange],
  );

  // ugly solution to handle external value change
  useEffect(() => {
    setVisibleValue(valueToVisible(props.value));
  }, [props.value]);

  const handleBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      const targetValue = e.target.value;

      if (targetValue.endsWith('.') || targetValue.endsWith('0')) {
        setVisibleValue(parseFloat(targetValue).toString());
      }

      if (targetValue === '') {
        setVisibleValue('0');
      }

      onBlur?.(e);
    },
    [onBlur],
  );

  return (
    <label className={twMerge('flex flex-col w-full gap-y-1 relative', className)}>
      {label && (
        <FieldLabel asterisk={asterisk} className={labelClassName} textClassName={labelTextClassName}>
          {label}
        </FieldLabel>
      )}
      <input
        {...rest}
        className={getFieldClassName({ disabled, error }, inputClassName)}
        onChange={handleChange}
        onBlur={handleBlur}
        type={'tel'}
        value={visibleValue ?? ''}
        pattern='^-?[0-9]\d*\.?\d*$'
        ref={ref}
      />
      {!hideErrorMessage && error && <FieldError>{error}</FieldError>}
    </label>
  );
});

export default NumberField;

type NumberFieldControlledProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<NumberFieldProps, 'value' | 'onChange' | 'name' | 'error'> & {
  control: UseControllerProps<TFieldValues, TName>['control'];
  name: TName;
};

export const NumberFieldControlled = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  ...textFieldProps
}: NumberFieldControlledProps<TFieldValues, TName>): JSX.Element => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <NumberField {...textFieldProps} {...field} name={name} error={error?.message} />
      )}
    />
  );
};
