import { FC, useCallback } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { CreateProductTemplateRequest } from 'src/api';
import useAddProductTemplate from 'src/api/hooks/mutations/productTemplates/useAddProductTemplate';
import { usePopupContext } from 'src/components/Popup/hook';
import ProductTemplateForm from '../ProductTemplateForm';

export type AddProductTemplateFormProps = {
  className?: string;
};

const AddProductTemplateForm: FC<AddProductTemplateFormProps> = ({ className }) => {
  const addProductTempalate = useAddProductTemplate();
  const { onClose } = usePopupContext();

  const onSubmit: SubmitHandler<CreateProductTemplateRequest> = useCallback(
    (data) => {
      addProductTempalate.mutate(data);
      onClose();
    },
    [onClose, addProductTempalate],
  );

  return <ProductTemplateForm onSubmit={onSubmit} className={className} />;
};

AddProductTemplateForm.displayName = 'AddProductTemplateForm';

export default AddProductTemplateForm;
