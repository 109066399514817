import { FC, useEffect } from 'react';
import clsx from 'clsx';
import { CreateVatTariffRequest } from 'src/api';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { VatTariffSchema } from './schema';
import { FormattedMessage } from 'react-intl';
import { NumberFieldControlled } from 'src/components/fields/NumberField';
import NewItemPopupFooter from 'src/components/common/NewItemPopupFooter';
import { TextFieldControlled } from 'src/components/fields/Textfield';
import { zodResolver } from '@hookform/resolvers/zod';

export type VatTariffFormProps = {
  className?: string;
  defaultValues?: Partial<VatTariffSchema>;
  onSubmit: SubmitHandler<VatTariffSchema>;
  disableValue?: boolean;
};

const DEFAULT_VALUES: Partial<VatTariffSchema> = {
  name: '',
  tariffValue: 0,
};

const VatTariffForm: FC<VatTariffFormProps> = ({ className, disableValue: hideValue, defaultValues, onSubmit }) => {
  const form = useForm<VatTariffSchema>({
    resolver: zodResolver(VatTariffSchema),
    defaultValues: { ...DEFAULT_VALUES, ...defaultValues },
  });

  const { handleSubmit, control, setValue } = form;

  useEffect(() => {
    if (!defaultValues) return;
    Object.entries(defaultValues).forEach(([k, v]) => setValue(k as keyof CreateVatTariffRequest, v));
  }, [defaultValues]);

  return (
    <div className={clsx(className, 'flex-col w-full')}>
      <form className='flex flex-col w-full gap-y-4' onSubmit={handleSubmit(onSubmit)}>
        <FormProvider {...form}>
          {!hideValue && (
            <NumberFieldControlled
              control={control}
              name='tariffValue'
              asterisk
              label={<FormattedMessage id='app.vat_tariff.value' />}
              type='text'
            />
          )}

          <TextFieldControlled
            control={control}
            name='name'
            asterisk
            label={<FormattedMessage id='app.common.name' />}
          />
        </FormProvider>
        <NewItemPopupFooter />
      </form>
    </div>
  );
};

VatTariffForm.displayName = 'VatTariffForm';

export default VatTariffForm;
