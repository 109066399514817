import { Transition } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { FC, useState } from 'react';
import { twMerge } from 'tailwind-merge';

export type CollapsableSectionProps = {
  children: React.ReactNode;
  defaultOpen?: boolean;
  className?: string;
  buttonClassName?: string;
  disabled?: boolean;
};

const CollapsableSection: FC<CollapsableSectionProps> = ({
  children,
  disabled,
  defaultOpen,
  className,
  buttonClassName,
}) => {
  const [open, setOpen] = useState(!!defaultOpen);

  return (
    <>
      {!disabled && (
        <div
          onClick={() => setOpen((prev) => !prev)}
          className={twMerge('flex items-center cursor-pointer gap-2 w-full', buttonClassName)}
        >
          <div className='border-t border-t-neutral-300 w-full'></div>
          {open ? <ChevronDownIcon className='w-4 h-4' /> : <ChevronUpIcon className='w-4 h-4' />}
          <div className='border-t border-t-neutral-300 w-4'></div>
        </div>
      )}
      <Transition
        show={disabled || open}
        enter='transition duration-100 ease-out'
        enterFrom='transform scale-95 opacity-0'
        enterTo='transform scale-100 opacity-100'
        leave='transition duration-75 ease-out'
        leaveFrom='transform scale-100 opacity-100'
        leaveTo='transform scale-95 opacity-0'
      >
        <div className={twMerge('flex flex-col', className)}>{children}</div>
      </Transition>
    </>
  );
};

export default CollapsableSection;
