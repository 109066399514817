import { useRef } from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { NavigateOptions, Path, useLocation, useNavigate } from 'react-router-dom';

export type UseNavigateWithSearch = (to: Partial<Path>, options?: NavigateOptions) => void;

export const useNavigateWithSearch = (): UseNavigateWithSearch => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const searchRef = useRef(search);

  useEffect(() => {
    searchRef.current = search;
  }, [search]);

  return useCallback<UseNavigateWithSearch>(
    (path, options) => {
      navigate({ search: searchRef.current, ...path }, options);
    },
    [navigate],
  );
};
