import { path } from 'ramda';
import { FC } from 'react';
import useUpdateCurrentTenantConfigField from 'src/api/hooks/mutations/tenant/useUpdateCurrentTenantConfigField';
import useCurrentTenant from 'src/api/hooks/queries/useCurrentTenant';
import Toggle from 'src/components/Toggle';

export type TenantConfigFieldToggleProps = {
  name: string;
};

const TenantConfigFieldToggle: FC<TenantConfigFieldToggleProps> = ({ name }) => {
  const updateConfigFieldMutation = useUpdateCurrentTenantConfigField();
  const { data: currentTenant } = useCurrentTenant();
  const config = currentTenant?.tenantConfig?.config ?? {};
  const value = path(name.split('.'), config);

  return (
    <Toggle
      value={!!value}
      onChange={(value) => {
        updateConfigFieldMutation.mutate({
          path: name,
          value,
        });
      }}
    />
  );
};

export default TenantConfigFieldToggle;
