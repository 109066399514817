import { useQuery, useQueryClient, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { ContactResponse } from 'src/api/.gen';
import queryKeysFactory from 'src/store/queryKeysFactory';
import { useApiClient } from '../../useApiClient';

type QueryKey = ReturnType<typeof queryKeysFactory.contacts.detail>['queryKey'];
type QueryOptions = Omit<
  UseQueryOptions<ContactResponse, unknown, ContactResponse, QueryKey>,
  'queryKey' | 'queryFn' | 'initialData'
>;

const useContact = (id: number, options?: QueryOptions): UseQueryResult<ContactResponse> => {
  const client = useApiClient();
  const { queryKey } = queryKeysFactory.contacts.detail(id);
  const queryClient = useQueryClient();

  return useQuery(
    queryKey,
    async () => {
      const data = await client.contact.getContact({ id });
      return data;
    },
    {
      refetchInterval: false,
      staleTime: 1000 * 60 * 5,
      onSuccess: () => {
        const { queryKey } = queryKeysFactory.contacts.history;
        queryClient.invalidateQueries(queryKey);
      },
      ...options,
    },
  );
};

export default useContact;
