import { FC, useCallback } from 'react';
import useUpdateProductTemplate from 'src/api/hooks/mutations/productTemplates/useUpdateProductTemplate';
import { SubmitHandler } from 'react-hook-form';
import { CreateProductTemplateRequest } from 'src/api';
import { usePopupContext } from 'src/components/Popup/hook';
import ProductTemplateForm from '../../DevicesPage/ProductTemplateForm';
import useProductTemplate from 'src/api/hooks/queries/useProductTemplate';
import { ProductTemplateSchema } from 'src/pages/DevicesPage/ProductTemplateForm/schema';

export type UpdateProductTemplateFormProps = {
  className?: string;
  productTemplateId: number;
};

const UpdateProductTemplateForm: FC<UpdateProductTemplateFormProps> = ({ className, productTemplateId }) => {
  const updateProductTempalate = useUpdateProductTemplate();
  const { onClose } = usePopupContext();
  const { data } = useProductTemplate(productTemplateId);

  const onSubmit: SubmitHandler<CreateProductTemplateRequest> = useCallback(
    (data) => {
      updateProductTempalate.mutate({ ...data, id: productTemplateId });
      onClose();
    },
    [onClose, updateProductTempalate],
  );

  return (
    <ProductTemplateForm onSubmit={onSubmit} className={className} defaultValues={data as ProductTemplateSchema} />
  );
};

UpdateProductTemplateForm.displayName = 'UpdateProductTemplateForm';

export default UpdateProductTemplateForm;
