import { FC, HTMLAttributes } from 'react';
import clsx from 'clsx';
import { UserResponse } from 'src/api';
import ParametersRow from 'src/components/common/ParametersRow';
import { FormattedMessage } from 'react-intl';
import { LanguageOptionValue } from 'src/types/commonTypes';

export type UserPreviewProps = {
  data: UserResponse;
} & HTMLAttributes<HTMLDivElement>;

const UserPreview: FC<UserPreviewProps> = ({ data, className }) => {
  return (
    <div className={clsx('flex flex-col w-full', className)}>
      <ParametersRow.Title>
        <FormattedMessage id='app.user.basic_info' />
      </ParametersRow.Title>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.common.email' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.userEmail}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.user.role' />
        </ParametersRow.Label>
        <ParametersRow.Value>
          <FormattedMessage id={`app.role.${data.role}`} />
        </ParametersRow.Value>
      </ParametersRow>
      <ParametersRow className='mb-20'>
        <ParametersRow.Label>
          <FormattedMessage id='app.contact.active_user' />
        </ParametersRow.Label>
        <ParametersRow.Value>
          {!data.deletedAt ? <FormattedMessage id='app.common.yes' /> : <FormattedMessage id='app.common.no' />}
        </ParametersRow.Value>
      </ParametersRow>
      <ParametersRow.Title>
        <FormattedMessage id='app.user.profile' />
      </ParametersRow.Title>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.user.profile.name' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.profile?.name}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.user.profile.preffered_language' />
        </ParametersRow.Label>
        <ParametersRow.Value>
          {data.profile?.preferredLanguage && (
            <FormattedMessage id={`app.language.${data.profile?.preferredLanguage as LanguageOptionValue}`} />
          )}
        </ParametersRow.Value>
      </ParametersRow>
    </div>
  );
};

UserPreview.displayName = 'UserPreview';

export default UserPreview;
