import { FC, HTMLAttributes } from 'react';
import clsx from 'clsx';
import Title from 'src/components/Title';
import { FormattedMessage } from 'react-intl';
import Button from 'src/components/Button';
import { Link } from 'react-router-dom';
import SadWorkerImg from 'src/pictures/sad-worker.png';

export type NotFoundPageProps = {
  //
} & HTMLAttributes<HTMLDivElement>;

const NotFoundPage: FC<NotFoundPageProps> = ({ className }) => {
  return (
    <div className={clsx(className, 'flex flex-col justify-center items-center w-full min-h-full h-full grow')}>
      <div className='flex items-center'>
        <div className='flex flex-col items-center gap-y-8 max-w-[420px]'>
          <Title className='text-7xl'>
            <FormattedMessage id='app.error_page.404.title' />
          </Title>
          <p className='text-3xl text-center'>
            <FormattedMessage id='app.error_page.404.description' />
          </p>
          <Link to='/'>
            <Button>
              <FormattedMessage id='app.buttons.back_to_home' />
            </Button>
          </Link>
        </div>
        <div className='md:hidden sm:hidden max-w-xl'>
          <img src={SadWorkerImg} alt='sad worker' />
        </div>
      </div>
    </div>
  );
};

NotFoundPage.displayName = 'NotFoundPage';

export default NotFoundPage;
