import { offset, useFloating, useHover, useInteractions } from '@floating-ui/react';
import { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';

export type ContributionDayProps = {
  contributionsCount: number;
};

const getColor = (ordersCount: number): string => {
  if (ordersCount === 0) return 'bg-gray-200 border-2 border-gray-300';
  if (ordersCount === 1) return 'bg-green-100 border-2 border-green-200';
  if (ordersCount === 2) return 'bg-green-200 border-2 border-green-300';
  if (ordersCount === 3) return 'bg-green-300 border-2 border-green-400';
  return 'bg-green-400 border-2 border-green-500';
};

const ContributionDay: FC<ContributionDayProps> = ({ contributionsCount }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'top',
    middleware: [offset(10)],
  });

  const hover = useHover(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([hover]);

  return (
    <>
      <div
        className={`block w-8 h-4 rounded ${getColor(contributionsCount)}`}
        ref={refs.setReference}
        {...getReferenceProps()}
      ></div>
      {isOpen && (
        <div ref={refs.setFloating} style={floatingStyles} {...getFloatingProps()}>
          <span className='bg-white border-2 rounded border-gray-300 p-2 text-sm'>
            <FormattedMessage id='app.contribution.orders.floating_text' values={{ n: contributionsCount }} />
          </span>
        </div>
      )}
    </>
  );
};

export default ContributionDay;
