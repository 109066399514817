/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateStockOrderRequest } from '../models/CreateStockOrderRequest';
import type { StockOrderPageReponse } from '../models/StockOrderPageReponse';
import type { StockOrderResponse } from '../models/StockOrderResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class StockOrdersService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns StockOrderPageReponse
   * @throws ApiError
   */
  public getStockOrders({
    pageSize,
    pageIndex,
    companyName,
    productName,
    materialName,
  }: {
    pageSize: number;
    pageIndex: number;
    companyName?: string | null;
    productName?: string | null;
    materialName?: string | null;
  }): CancelablePromise<StockOrderPageReponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/stock-orders',
      query: {
        pageSize: pageSize,
        pageIndex: pageIndex,
        companyName: companyName,
        productName: productName,
        materialName: materialName,
      },
    });
  }
  /**
   * @returns StockOrderResponse
   * @throws ApiError
   */
  public createStockOrder({ formData }: { formData: CreateStockOrderRequest }): CancelablePromise<StockOrderResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/stock-orders',
      formData: formData,
      mediaType: 'multipart/form-data',
    });
  }
  /**
   * @returns StockOrderResponse
   * @throws ApiError
   */
  public getStockOrder({ id }: { id: number }): CancelablePromise<StockOrderResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/stock-orders/{id}',
      path: {
        id: id,
      },
    });
  }
}
