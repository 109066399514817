/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateTenantExternalRequest } from '../models/CreateTenantExternalRequest';
import type { ExternalCreateUserRequest } from '../models/ExternalCreateUserRequest';
import type { TenantsPageResponse } from '../models/TenantsPageResponse';
import type { TenantWithLicencesResponse } from '../models/TenantWithLicencesResponse';
import type { UpdateTenantExternalRequest } from '../models/UpdateTenantExternalRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ExternalTenantManagmentService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns TenantsPageResponse
   * @throws ApiError
   */
  public getTenantsPage({
    pageSize,
    pageIndex,
    tenantName,
    applicationTitle,
    accountingEmail,
    direction,
    orderBy,
  }: {
    pageSize: number;
    pageIndex: number;
    tenantName?: string;
    applicationTitle?: string;
    accountingEmail?: string;
    direction?: 'ASC' | 'DESC';
    orderBy?: 'tenant.id' | 'tenant_name' | 'application_title';
  }): CancelablePromise<TenantsPageResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/external-tenant-managment',
      query: {
        tenantName: tenantName,
        applicationTitle: applicationTitle,
        accountingEmail: accountingEmail,
        direction: direction,
        orderBy: orderBy,
        pageSize: pageSize,
        pageIndex: pageIndex,
      },
    });
  }
  /**
   * @returns TenantWithLicencesResponse
   * @throws ApiError
   */
  public createTenant({
    formData,
  }: {
    formData: CreateTenantExternalRequest;
  }): CancelablePromise<TenantWithLicencesResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/external-tenant-managment',
      formData: formData,
      mediaType: 'multipart/form-data',
    });
  }
  /**
   * @returns TenantWithLicencesResponse
   * @throws ApiError
   */
  public getTenant({ id }: { id: number }): CancelablePromise<TenantWithLicencesResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/external-tenant-managment/{id}',
      path: {
        id: id,
      },
    });
  }
  /**
   * @returns TenantWithLicencesResponse
   * @throws ApiError
   */
  public changeTenantMaxUsers({
    id,
    requestBody,
  }: {
    id: number;
    requestBody: UpdateTenantExternalRequest;
  }): CancelablePromise<TenantWithLicencesResponse> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/external-tenant-managment/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns any
   * @throws ApiError
   */
  public deactivateTenant({ id }: { id: number }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/external-tenant-managment/{id}',
      path: {
        id: id,
      },
    });
  }
  /**
   * @returns any
   * @throws ApiError
   */
  public activateTenant({ id }: { id: number }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/external-tenant-managment/{id}/activate',
      path: {
        id: id,
      },
    });
  }
  /**
   * @returns any
   * @throws ApiError
   */
  public addUserToTenant({
    id,
    requestBody,
  }: {
    id: number;
    requestBody: ExternalCreateUserRequest;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/external-tenant-managment/{id}/user',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
