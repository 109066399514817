import { Column } from '@tanstack/react-table';
import { PropsWithoutRef } from 'react';
import CheckboxGroupBase, { CheckboxGroupBaseProps } from 'src/components/fields/CheckboxGroup/CheckboxGroup';

export type CheckboxGroupFilterProps<T extends Array<string | number | symbol>> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  column: Column<any, unknown>;
} & Omit<CheckboxGroupBaseProps<T>, 'value' | 'onChange'>;

const CheckboxGroupFilter = <T extends Array<string | number | symbol>>({
  column,
  options,
  ...rest
}: PropsWithoutRef<CheckboxGroupFilterProps<T>>): JSX.Element => {
  const { setFilterValue, getFilterValue } = column;

  return (
    <CheckboxGroupBase isFilter options={options} value={getFilterValue() as T} onChange={setFilterValue} {...rest} />
  );
};

export default CheckboxGroupFilter;
