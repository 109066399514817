import { LinkIcon } from '@heroicons/react/24/outline';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { format, parseISO } from 'date-fns';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { BranchResponse, ProductResponse } from 'src/api';
import Alert from 'src/components/common/Alert';
import HyperLink from 'src/components/common/HyperLink';
import ParametersRow from 'src/components/common/ParametersRow';
import useGetIsHiddenProductFormField from 'src/hooks/formsConfig/useGetIsHiddenProductFormField';

interface ProductPreviewProps {
  noTitle?: boolean;
  data: ProductResponse;
  branch?: BranchResponse;
  className?: string;
}

const ProductPreview: React.FC<ProductPreviewProps> = ({ data, branch, noTitle, className }) => {
  const getIsHiddenField = useGetIsHiddenProductFormField();

  return (
    <div className={clsx(className, 'flex flex-col w-full')}>
      {!noTitle && (
        <ParametersRow.Title>
          <FormattedMessage id='app.product.detail_title' defaultMessage='Detail zarizeni' />
        </ParametersRow.Title>
      )}
      {data.deletedAt && (
        <Alert
          icon={<ExclamationTriangleIcon className='w-6 h-6 text-yellow-500' />}
          text={<FormattedMessage id='app.product.alert.deleted_product' />}
        />
      )}
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.product.name' defaultMessage='Název' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.productName}</ParametersRow.Value>
      </ParametersRow>
      {!getIsHiddenField('productProducer') && (
        <ParametersRow>
          <ParametersRow.Label>
            <FormattedMessage id='app.product.producer' defaultMessage='Značka' />
          </ParametersRow.Label>
          <ParametersRow.Value>{data.productProducer}</ParametersRow.Value>
        </ParametersRow>
      )}
      {!getIsHiddenField('productSerialNumber') && (
        <ParametersRow>
          <ParametersRow.Label>
            <FormattedMessage id='app.product.serial_number' defaultMessage='Sériové číslo' />
          </ParametersRow.Label>
          <ParametersRow.Value>{data.productSerialNumber}</ParametersRow.Value>
        </ParametersRow>
      )}

      {!getIsHiddenField('productSerialNumber2') && (
        <ParametersRow>
          <ParametersRow.Label>
            <FormattedMessage id='app.product.serial_number2' />
          </ParametersRow.Label>
          <ParametersRow.Value>{data.productSerialNumber2}</ParametersRow.Value>
        </ParametersRow>
      )}

      {!getIsHiddenField('technology') && (
        <ParametersRow>
          <ParametersRow.Label>
            <FormattedMessage id='app.product.technology' />
          </ParametersRow.Label>
          <ParametersRow.Value>{data.technology}</ParametersRow.Value>
        </ParametersRow>
      )}

      {branch && (
        <ParametersRow>
          <ParametersRow.Label>
            <FormattedMessage id='app.order.branch' />
          </ParametersRow.Label>
          <ParametersRow.Value>
            {branch?.name}
            <HyperLink className='ml-3' href={`/contact/${branch?.companyId}/branch/${branch?.id}`}>
              <LinkIcon className='w-7' />
            </HyperLink>
          </ParametersRow.Value>
        </ParametersRow>
      )}
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.product.history' defaultMessage='Historie zásahů' />
        </ParametersRow.Label>
        <ParametersRow.Value>
          <HyperLink href={`/product/${data.id}/history`}>
            <LinkIcon className='w-7' />
          </HyperLink>
        </ParametersRow.Value>
      </ParametersRow>
      {!getIsHiddenField('inUseFrom') && (
        <ParametersRow>
          <ParametersRow.Label>
            <FormattedMessage id='app.product.in_use_from' />
          </ParametersRow.Label>
          <ParametersRow.Value>
            {data.inUseFrom ? format(parseISO(data.inUseFrom), 'yyyy-MM-dd') : ''}
          </ParametersRow.Value>
        </ParametersRow>
      )}
      {!getIsHiddenField('warranty') && (
        <ParametersRow>
          <ParametersRow.Label>
            <FormattedMessage id='app.product.warranty' />
          </ParametersRow.Label>
          <ParametersRow.Value>
            {data.warranty ? format(parseISO(data.warranty), 'yyyy-MM-dd') : ''}
          </ParametersRow.Value>
        </ParametersRow>
      )}
      {data.lastOrderId && data.lastOrderDate && (
        <ParametersRow>
          <ParametersRow.Label>
            <FormattedMessage id='app.product.last_order' />
          </ParametersRow.Label>
          <ParametersRow.Value>
            <HyperLink className='flex gap-2' href={`/order/${data.lastOrderId}`}>
              {format(parseISO(data.lastOrderDate), 'yyyy-MM-dd')}
              <LinkIcon className='w-7' />
            </HyperLink>
          </ParametersRow.Value>
        </ParametersRow>
      )}
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.product.documentation_note' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.productNote}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.product.documentation' defaultMessage='Dokumentace' />
        </ParametersRow.Label>
        <ParametersRow.Value>
          <HyperLink className='ml-3' href={`/product/${data.id}/documentation`}>
            <LinkIcon className='ml-3 w-7' />
          </HyperLink>
        </ParametersRow.Value>
      </ParametersRow>
    </div>
  );
};

export default ProductPreview;
