/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OrderStatusResponse } from '../models/OrderStatusResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class StatusService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns OrderStatusResponse
   * @throws ApiError
   */
  public getStatusList(): CancelablePromise<Array<OrderStatusResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/status',
    });
  }
}
