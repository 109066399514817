import { ClipboardDocumentListIcon } from '@heroicons/react/24/outline';
import { range } from 'ramda';
import { FC, useCallback } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { BranchResponse, useApiClient } from 'src/api';
import useContact from 'src/api/hooks/queries/useContact';
import useContactBranches from 'src/api/hooks/queries/useContactBranches';
import Button from 'src/components/Button';
import { CheckboxControlled } from 'src/components/fields/Checkbox';
import { PhoneInputLocalizedControlled } from 'src/components/fields/PhoneInputLocalized';
import { SelectControlled } from 'src/components/fields/Select';
import { TextFieldControlled } from 'src/components/fields/Textfield/TextField';
import { BRANCH_FIELDS } from '../constants';
import { OrderSchema } from '../schema';
import useCurrentTenantConfig from 'src/api/hooks/queries/useCurrentTenantConfig';
import { DEFAULT_TABLE_PAGINATION } from 'src/constants/table';
import SeparatedTextField from 'src/components/fields/MultiInput/SeparatedTextField';
import { AutocompleteAsync } from 'src/components/fields/AutocompleteAsync/AutocompleteAsync';
import CollapsableSection from 'src/components/CollapsableSection';

const ServiceBranchForm: FC<{ disableCreateNew?: boolean }> = ({ disableCreateNew }) => {
  const { data: tenantConfig } = useCurrentTenantConfig();
  const apiClient = useApiClient();
  const { control, setValue, getValues, watch, resetField } = useFormContext<OrderSchema>();
  const contactId = watch('company.companyId');
  const { data: contact } = useContact(contactId ?? 0, {
    enabled: !!contactId,
  });

  const createNewCompany = watch('company.createNewCompany');
  const createNewBranch = watch('branch.createNewBranch');
  const { fields: productFields } = useFieldArray({ name: 'orderProducts', control });

  const { data: branches } = useContactBranches(contactId ?? 0, {
    enabled: !!contactId && !tenantConfig?.useBranchSearchInOrderForm,
  });

  const handleBranchChange = async (branchId: number | null): Promise<void> => {
    if (!branchId) return;

    const data = await apiClient.branch.getBranch({ id: branchId });
    BRANCH_FIELDS.forEach((field) => setValue(`branch.branch.${field}`, data[field]));
  };

  const getOptions = async (query: string): Promise<BranchResponse[]> => {
    const data = await apiClient.branch.getBranchesFromSearchQuery({ query, ...DEFAULT_TABLE_PAGINATION });

    return data?.items;
  };

  const handleCreateNewBranchChange = useCallback(
    (value: boolean | null) => {
      if (!value) return;

      resetField('branch.branchId');
      BRANCH_FIELDS.forEach((field) => setValue(`branch.branch.${field}`, ''));
      range(0, productFields.length).forEach((index) => setValue(`orderProducts.${index}.createNewProduct`, true));
    },
    [setValue],
  );

  const handlePrefillFromContact = useCallback(() => {
    try {
      const contactData = contactId ? contact : getValues('company.company');
      if (!contactData) return;

      setValue('branch.branch.name', contactData.companyName ?? '');
      setValue('branch.branch.city', contactData.city ?? '');
      setValue('branch.branch.street', contactData.street ?? '');
      setValue('branch.branch.zip', contactData.zip ?? '');
      setValue('branch.branch.vat', contactData.in ?? '');
      setValue('branch.branch.email', contactData.email ?? '');
      setValue('branch.branch.phoneNumber', contactData.phoneNumber ?? '');
    } catch (e) {
      //
      console.error(e);
    }
  }, [contact, setValue]);

  const getBranch = async (id: number | null): Promise<BranchResponse | null> => {
    if (!id) return null;

    const data = await apiClient.branch.getBranch({ id });
    return data;
  };

  return (
    <div className='flex flex-col mb-16 gap-y-4'>
      <div className='font-bold text-gray-800 text-2xl'>
        <FormattedMessage id='app.order.section_title.branch' />
      </div>

      {!disableCreateNew && (
        <div className='flex justify-between items-center'>
          <CheckboxControlled
            label={<FormattedMessage id='app.order.new_branch' />}
            control={control}
            name='branch.createNewBranch'
            disabled={!!createNewCompany}
            extraOnChange={handleCreateNewBranchChange}
          />
          {createNewBranch && (
            <Button variant='cta' className='ml-auto' onClick={handlePrefillFromContact}>
              <ClipboardDocumentListIcon className='w-6 h-6 mr-1' />
              <FormattedMessage id='app.order.prefill_branch_from_customer' />
            </Button>
          )}
        </div>
      )}

      {!createNewBranch &&
        (tenantConfig?.useBranchSearchInOrderForm ? (
          <Controller
            control={control}
            name='branch.branchId'
            render={({ field }) => (
              <AutocompleteAsync
                {...field}
                asterisk
                preloadDefaultValueOption={getBranch}
                label={<FormattedMessage id='app.order.branch' />}
                getOptions={getOptions}
                getOptionLabel={(i) => i.name}
                getOptionValue={(i) => i.id}
                extraOnChange={handleBranchChange}
              />
            )}
          />
        ) : (
          <SelectControlled
            control={control}
            asterisk
            label={<FormattedMessage id='app.order.branch' />}
            name='branch.branchId'
            options={branches ?? []}
            getOptionLabel={(option) => option.name ?? ''}
            getOptionValue={(option) => option.id}
            extraOnChange={handleBranchChange}
          />
        ))}
      <CollapsableSection disabled={createNewBranch} className='gap-y-4 grid grid-cols-2 gap-x-2'>
        <TextFieldControlled
          control={control}
          name='branch.branch.name'
          className='col-span-2'
          disabled={!createNewBranch}
          asterisk
          label={<FormattedMessage id='app.branch.name' />}
        />

        <TextFieldControlled
          control={control}
          name='branch.branch.vat'
          disabled={!createNewBranch}
          label={<FormattedMessage id='app.common.branch.ico' />}
        />

        <TextFieldControlled
          control={control}
          name='branch.branch.city'
          disabled={!createNewBranch}
          asterisk
          label={<FormattedMessage id='app.common.city' />}
        />

        <TextFieldControlled
          control={control}
          name='branch.branch.street'
          disabled={!createNewBranch}
          asterisk
          label={<FormattedMessage id='app.common.street' />}
        />

        <TextFieldControlled
          control={control}
          name='branch.branch.zip'
          disabled={!createNewBranch}
          asterisk
          label={<FormattedMessage id='app.common.zip' />}
        />

        <TextFieldControlled
          control={control}
          name='branch.branch.contactPerson'
          disabled={!createNewBranch}
          label={<FormattedMessage id='app.branch.contact_person' />}
        />

        <PhoneInputLocalizedControlled
          control={control}
          name='branch.branch.phoneNumber'
          disabled={!createNewBranch}
          label={<FormattedMessage id='app.common.phone' />}
        />

        <Controller
          control={control}
          name='branch.branch.email'
          render={({ field: { value, name, onChange }, fieldState }) => (
            <SeparatedTextField
              name={name}
              className='col-span-2'
              label={<FormattedMessage id='app.common.email' defaultMessage='Email' />}
              value={value ?? ''}
              onChange={onChange}
              separator=';'
              error={fieldState.error?.message}
            />
          )}
        />
      </CollapsableSection>
    </div>
  );
};

export default ServiceBranchForm;
