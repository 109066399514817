import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import AutoPopup from 'src/components/common/AutoPopup';
import UpdateProductForm from '../UpdateProductForm';

export type UpdateProductPopupProps = {
  productId: number;
  className?: string;
};

const UpdateProductPopup: FC<UpdateProductPopupProps> = ({ productId, className }) => (
  <AutoPopup
    id='update_product'
    title={<FormattedMessage id='app.contact_page.update_product.popup_title' defaultMessage='Editace zarizeni' />}
    className={className}
  >
    <UpdateProductForm productId={productId} />
  </AutoPopup>
);

UpdateProductPopup.displayName = 'UpdateProductPopup';

export default UpdateProductPopup;
