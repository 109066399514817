import clsx from 'clsx';
import React, { ReactNode, forwardRef } from 'react';
import { Link } from 'react-router-dom';

export type SidebarItemProps = React.HTMLProps<HTMLDivElement> & {
  isSelected: boolean;
  className?: string;
  href: string;
  text: ReactNode;
  icon: ReactNode;
};

const SidebarItem = forwardRef<HTMLDivElement, SidebarItemProps>(
  ({ isSelected, className, href, text, icon, ...rest }, ref) => {
    return (
      <div ref={ref} className={clsx(className, 'w-full h-14 min-h-14')} {...rest}>
        <Link to={href} className='flex items-center w-full h-full max-w-full'>
          <span className={clsx('block w-1.5 h-7 mr-3', isSelected && 'rounded-full bg-primary')}></span>
          <div
            className={clsx(
              'flex justify-center items-center w-menu-icon h-menu-icon mr-2',
              isSelected && 'text-primary',
            )}
          >
            {icon}
          </div>
          <span className={clsx('text-xl', isSelected && 'font-semibold')}>{text}</span>
        </Link>
      </div>
    );
  },
);

export default SidebarItem;
