import { Column } from '@tanstack/react-table';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { OrderResponse } from 'src/api';
import DropdownFilter from 'src/components/Table/filters/DropdownFilter';

export type CategoryFilterProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  column: Column<any, unknown>;
};

const CategoryFilter: FC<CategoryFilterProps> = ({ column }) => {
  return (
    <DropdownFilter<OrderResponse.category | null>
      horizontal='left'
      defaultValue={null}
      items={[
        {
          label: <FormattedMessage id='app.common.all' />,
          value: null,
        },
        {
          label: OrderResponse.category.B2C,
          value: OrderResponse.category.B2C,
        },
        {
          label: OrderResponse.category.B2B,
          value: OrderResponse.category.B2B,
        },
      ]}
      column={column}
    />
  );
};

export default CategoryFilter;
