import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { UserResponse } from 'src/api';
import useGetIsRoleAllowed from 'src/hooks/useGetIsRoleAllowed';
import AdministrationPage from 'src/pages/AdministrationPage';
import DeliveryRateDetailPage from 'src/pages/DeliveryRateDetailPage';
import DeliveryRatesPage from 'src/pages/DeliveryRatesPage';
import DeliveryTariffsDetailPage from 'src/pages/DeliveryTariffsDetailPage';
import DeliveryTariffsPage from 'src/pages/DeliveryTariffsPage';
import DeviceDetailPage from 'src/pages/DeviceDetailPage';
import DevicesPage from 'src/pages/DevicesPage';
import ExportCustomFilteredOrdersPage from 'src/pages/ExportCustomFilteredOrdersPage';
import ExportsCustomerOrderDataPage from 'src/pages/ExportsCustomerOrderDataPage';
import ExportsPage from 'src/pages/ExportsPage';
import OrdersExportsTab from 'src/pages/ExportsPage/tabs/OrdersExportsTab';
import ExportsProductsOrdersDataPage from 'src/pages/ExportsProductsOrdersDataPage';
import ExportsTechniciansOrdersDataPage from 'src/pages/ExportsTechniciansOrdersDataPage';
import HeadquartersDetailPage from 'src/pages/HeadquartersDetailPage';
import ImportsPage from 'src/pages/ImportsPage';
import NotificationPage from 'src/pages/NotificationPage';
import ParametersPage from 'src/pages/ParametersPage';
import PaymentMethodPage from 'src/pages/PaymentMethodPage';
import RoutineServicesPage from 'src/pages/RoutineServices';
import RoutineServicesDetailPage from 'src/pages/RoutineServices/RoutineServicesDetailPage';
import StockOrderDetailPage from 'src/pages/StockOrderDetailPage';
import StockOrdersPage from 'src/pages/StockOrdersPage';
import UserDetailPage from 'src/pages/UserDetailPage';
import UserPage from 'src/pages/Users';
import VatTariffDetailPage from 'src/pages/VatTariffDetailPage';
import WorkTariffsDetailPage from 'src/pages/WorkTariffsDetailPage';
import WorkTariffsPage from 'src/pages/WorkTariffsPage';

const AdminRouter: FC = () => {
  const getIsRoleAllowed = useGetIsRoleAllowed();
  const hasAdminAccess = getIsRoleAllowed([
    UserResponse.role.SYSTEM_ADMINISTRATOR,
    UserResponse.role.ADMINISTRATOR,
    UserResponse.role.ADMINISTRATOR_TECHNICIAN,
  ]);

  if (!hasAdminAccess) return null;

  return (
    <Routes>
      <Route path='' element={<AdministrationPage />}>
        <Route path='' element={<Navigate to='parameter' />} />
        <Route path='user/*' element={<UserPage />} />
        <Route path='user/:userId' element={<UserDetailPage />} />
        <Route path='payment-method/*' element={<PaymentMethodPage />} />
        <Route path='parameter' element={<ParametersPage />} />
        <Route path='devices/*' element={<DevicesPage />} />
        <Route path='devices/:productTemplateId' element={<DeviceDetailPage />} />
        <Route path='vat-tariff/:vatTariffId' element={<VatTariffDetailPage />} />
        <Route path='parameter/headquarters/:headquartersId' element={<HeadquartersDetailPage />} />
        <Route path='routine-services/*' element={<RoutineServicesPage />} />
        <Route path='routine-services/:routineServiceId' element={<RoutineServicesDetailPage />} />
        <Route path='notification/*' element={<NotificationPage />} />
        <Route path='delivery-tariffs/' element={<DeliveryTariffsPage />} />
        <Route path='delivery-rates/' element={<DeliveryRatesPage />} />
        <Route path='delivery-rates/:deliveryRateId' element={<DeliveryRateDetailPage />} />
        <Route path='delivery-tariffs/:deliveryTariffId' element={<DeliveryTariffsDetailPage />} />
        <Route path='imports' element={<ImportsPage />} />
        <Route path='exports' element={<ExportsPage />}>
          <Route path='orders' element={<OrdersExportsTab />} />
          <Route path='' element={<Navigate to='orders' />} />
        </Route>
        <Route path='work-tariffs/' element={<WorkTariffsPage />} />
        <Route path='work-tariffs/:workTariffId' element={<WorkTariffsDetailPage />} />
        <Route path='exports/orders/custom-filter' element={<ExportCustomFilteredOrdersPage />} />
        <Route path='exports/orders/technicians-data' element={<ExportsTechniciansOrdersDataPage />} />
        <Route path='exports/orders/customer-data' element={<ExportsCustomerOrderDataPage />} />
        <Route path='exports/orders/products-data' element={<ExportsProductsOrdersDataPage />} />
        <Route path='stock-orders' element={<StockOrdersPage />} />
        <Route path='stock-orders/:stockOrderId' element={<StockOrderDetailPage />} />
      </Route>
    </Routes>
  );
};

export default AdminRouter;
