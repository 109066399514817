/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type UserListResponse = {
  id: number;
  userEmail: string;
  role: UserListResponse.role;
  /**
   * Datetime
   */
  deletedAt: string | null;
};
export namespace UserListResponse {
  export enum role {
    SYSTEM_ADMINISTRATOR = 'systemAdministrator',
    ADMINISTRATOR = 'administrator',
    DISPATCHER = 'dispatcher',
    DISPATCHER_TECHNICIAN = 'dispatcherTechnician',
    TECHNICIAN = 'technician',
    READ_ONLY_TECHNICIAN = 'readOnlyTechnician',
    ADMINISTRATOR_TECHNICIAN = 'administratorTechnician',
  }
}
