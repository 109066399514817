import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';
import { assocPath } from 'ramda';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { TenantWithLicencesResponse, UpdateTenantConfigField } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

type UpdateTenantConfigFieldNormalized = Omit<UpdateTenantConfigField, 'value'> & { value: unknown };

const useUpdateCurrentTenantConfigField = (): UseMutationResult<void, unknown, UpdateTenantConfigFieldNormalized> => {
  const queryClient = useQueryClient();
  const apiClient = useApiClient();

  return useMutation<
    void,
    unknown,
    UpdateTenantConfigFieldNormalized,
    { oldCurrentTenant: TenantWithLicencesResponse }
  >(
    async (change) => {
      await apiClient.tenantConfigs.updateTenantConfigField({
        requestBody: change as UpdateTenantConfigField,
      });
    },
    {
      onMutate: (change) => {
        const queryKey = queryKeysFactory.tenant.current.queryKey;
        const currentTenant = queryClient.getQueryData<TenantWithLicencesResponse>(queryKey);
        if (!currentTenant) return;
        const changePath = change.path.split('.');

        const config = currentTenant.tenantConfig?.config ?? {};
        const newConfig = assocPath(changePath, change.value, config);
        const newCurrentTenant = assocPath(['tenantConfig', 'config'], newConfig, currentTenant);

        queryClient.setQueryData<TenantWithLicencesResponse>(queryKey, newCurrentTenant);

        return { oldCurrentTenant: currentTenant };
      },
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeysFactory.tenant.list.queryKey);
        queryClient.invalidateQueries(queryKeysFactory.tenant.current);
        toast(<FormattedMessage id='app.toasts.update_success' />, {
          type: 'success',
        });
      },
      onError: (_err, _variables, context) => {
        toast(<FormattedMessage id='app.toasts.update_fail' />, {
          type: 'error',
        });

        const oldCurrentTenant = context?.oldCurrentTenant;
        if (!oldCurrentTenant) return;

        const queryKey = queryKeysFactory.tenant.current.queryKey;
        queryClient.setQueryData<TenantWithLicencesResponse>(queryKey, oldCurrentTenant);
      },
    },
  );
};

export default useUpdateCurrentTenantConfigField;
