import { useLocation } from 'react-router-dom';

export type CalendarMode = 'timeline' | 'day' | 'week' | 'month' | 'mobile';

const useCalendarMode = (): CalendarMode | null => {
  const location = useLocation();
  const pathname = location.pathname;

  if (pathname.includes('/timeline')) return 'timeline';
  if (pathname.includes('/day')) return 'day';
  if (pathname.includes('/week')) return 'week';
  if (pathname.includes('/month')) return 'month';
  if (pathname.includes('/mobile')) return 'mobile';

  return null;
};

export default useCalendarMode;
