import { FC } from 'react';
import { TextFieldControlled } from 'src/components/fields/Textfield';
import { useForm } from 'react-hook-form';
import { FormBuilderSectionSchema } from './schema';
import { CheckboxControlled } from 'src/components/fields/Checkbox';
import NewItemPopupFooter from 'src/components/common/NewItemPopupFooter';
import { FormattedMessage } from 'react-intl';
import { UserResponse } from 'src/api';
import useCurrentUser from 'src/api/hooks/queries/useCurrentUser';
import { twMerge } from 'tailwind-merge';

export type FormBuilderSectionFormProps = {
  className?: string;
  onSubmit: (data: FormBuilderSectionSchema) => void;
  defaultValues?: FormBuilderSectionSchema;
};

const DEFAULT_VALUES: FormBuilderSectionSchema = {
  name: '',
  label: '',
  noThead: false,
  labelAsTh: false,
};

const FormBuilderSectionForm: FC<FormBuilderSectionFormProps> = ({ className, defaultValues, onSubmit }) => {
  const { data: currentUser } = useCurrentUser();
  const form = useForm<FormBuilderSectionSchema>({
    defaultValues: {
      ...DEFAULT_VALUES,
      ...defaultValues,
    },
  });

  const { control, handleSubmit } = form;
  const isSysmAdmin = currentUser?.role === UserResponse.role.SYSTEM_ADMINISTRATOR;

  return (
    <form className={twMerge('flex flex-col gap-y-4', className)} onSubmit={handleSubmit(onSubmit)}>
      <TextFieldControlled control={control} name='name' label={<FormattedMessage id='app.common.name' />} />
      <TextFieldControlled control={control} name='label' label={<FormattedMessage id='app.common.label' />} />
      {isSysmAdmin && (
        <>
          <CheckboxControlled
            label={<FormattedMessage id='app.order_type.sl_config.sections.no_thead' />}
            control={control}
            name='noThead'
          />
          <CheckboxControlled
            label={<FormattedMessage id='app.order_type.sl_config.sections.label_as_th' />}
            control={control}
            name='labelAsTh'
          />
        </>
      )}
      <NewItemPopupFooter />
    </form>
  );
};

FormBuilderSectionForm.displayName = 'FormBuilderSectionForm';

export default FormBuilderSectionForm;
