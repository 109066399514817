import { FC, HTMLAttributes, useCallback } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { CreateRoutineServicesRequest } from 'src/api';
import useAddRoutineService from 'src/api/hooks/mutations/routineServices/useAddRoutineService';
import { usePopupContext } from 'src/components/Popup/hook';
import RoutineServicesForm from '../RoutineServicesForm';

export type AddRoutineServicesFormProps = {
  //
} & HTMLAttributes<HTMLDivElement>;

const AddRoutineServicesForm: FC<AddRoutineServicesFormProps> = ({ className }) => {
  const addRoutinService = useAddRoutineService();
  const { onClose } = usePopupContext();

  const onSubmit: SubmitHandler<CreateRoutineServicesRequest> = useCallback(
    (data) => {
      addRoutinService.mutate(data);
      onClose();
    },
    [addRoutinService, onClose],
  );

  return <RoutineServicesForm onSubmit={onSubmit} className={className} />;
};

AddRoutineServicesForm.displayName = 'AddRoutineServicesForm';

export default AddRoutineServicesForm;
