import { FC, useMemo } from 'react';
import useAllTechniciansDayOrders from 'src/api/hooks/queries/useAllTechniciansDayOrders';
import useCalendarSelectedDateParam from '../CalendarPage/hooks/useCalendarSelectedDateParam';
import useCalendarSingleTechnicianIdParam from '../CalendarPage/hooks/useCalendarSingleTechnicianIdParam';
import Loader from 'src/components/utils/Loader';
import OrderInfoCard from 'src/components/OrderInfoCard';

export type CalendarMobilePageProps = {
  //
};

const CalendarMobilePage: FC<CalendarMobilePageProps> = () => {
  const [technicianId] = useCalendarSingleTechnicianIdParam();
  const [selectedDate] = useCalendarSelectedDateParam();
  const { data: orders, isLoading: isLoadingEvents } = useAllTechniciansDayOrders(selectedDate);
  const technicianOrders = useMemo(
    () => orders?.filter((order) => order.technicians.some((technician) => technician.id === technicianId)),
    [orders, technicianId],
  );

  if (isLoadingEvents) {
    return <Loader />;
  }

  console.log(technicianOrders, technicianId, orders);

  return (
    <div className='flex flex-col gap-y-2'>
      {technicianOrders?.map((order) => (
        <OrderInfoCard key={order.id} order={order} />
      ))}
    </div>
  );
};

export default CalendarMobilePage;
