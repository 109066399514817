import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import SectionTitle from '../../SectionTitle';
import { TimePickerRhfc } from 'src/components/fields/TimePicker';
import { OrderFinishWithRealTimeSchema } from '../schema';
import { FormattedMessage } from 'react-intl';
import Section from '../../Section';
import FieldWrapper from '../../FieldWrapper';

export type RealTimeFormProps = {
  //
};

const RealTimeForm: FC<RealTimeFormProps> = () => {
  const { control } = useFormContext<OrderFinishWithRealTimeSchema>();

  return (
    <div className='flex flex-col'>
      <SectionTitle className='mt-16 mb-4'>
        <FormattedMessage id='app.finish_order.real_time' />
      </SectionTitle>
      <Section>
        <FieldWrapper label={<FormattedMessage id='app.finish_order.real_time.start' />}>
          <TimePickerRhfc control={control} name='realStart' startHour={6} endHour={20} stepInMinutes={5} />
        </FieldWrapper>
        <FieldWrapper label={<FormattedMessage id='app.finish_order.real_time.end' />}>
          <TimePickerRhfc control={control} name='realEnd' startHour={6} endHour={20} stepInMinutes={5} />
        </FieldWrapper>
      </Section>
    </div>
  );
};

export default RealTimeForm;
