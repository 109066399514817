import clsx from 'clsx';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { RoutineServicesResponse } from 'src/api';
import ParametersRow from 'src/components/common/ParametersRow';

export type RoutineServicesPreviewProps = {
  data: RoutineServicesResponse;
};

const RoutineServicesPreview: FC<RoutineServicesPreviewProps> = ({ data }) => {
  return (
    <div className={clsx('flex flex-col w-full')}>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.routine_service.name' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.routineName}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.routine_service.value' defaultMessage='Detail kontaktu' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.routineValue}</ParametersRow.Value>
      </ParametersRow>
    </div>
  );
};

export default RoutineServicesPreview;
