import { ColumnFiltersState, PaginationState, SortingState, Updater } from '@tanstack/react-table';
import { useCallback } from 'react';
import { useMemo } from 'react';
import { Dispatch, SetStateAction, useState } from 'react';
import { SortingQuery } from 'src/api/types/shared/sorting';
import { DEFAULT_TABLE_PAGINATION } from 'src/constants/table';
import defaultSortingTransform from 'src/helpers/defaultSortingTransform';
import defaultTransformFilters from 'src/helpers/defaultTransformFilters';
import updateLocalStorageItem from 'src/helpers/updateLocalStorageItem';
import useDebounce from 'src/hooks/useDebounce';

export type UseTableStateReturn<TFilters> = {
  sorting: SortingState;
  setSorting: Dispatch<SetStateAction<SortingState>>;

  sortingQuery: SortingQuery;

  pagination: PaginationState;
  setPagination: Dispatch<SetStateAction<PaginationState>>;

  columnFilters: ColumnFiltersState;
  setColumnFilters: Dispatch<SetStateAction<ColumnFiltersState>>;
  serverFriendlyFilters: TFilters;
  debouncedServerFriendlyFilters: TFilters;
};

export type UseTableStateOptions<TFilters> = {
  defaultColumnFiltersState?: ColumnFiltersState;
  defaultSortingState?: SortingState;
  transformFilters?: (filters: ColumnFiltersState) => TFilters;
  debounceDelay?: number;
  localStorageKey?: string;
  keepFiltersInLocalStorage?: boolean;
};

const DEFAULT_DEBOUNCE_DELAY = 500;

const getColumnFiltersKey = (localStorageKey: string): string => `tableState.${localStorageKey}.columnFilters`;

const getUsedDefaultColumnFiltersState = (options: UseTableStateOptions<unknown>): ColumnFiltersState => {
  const { defaultColumnFiltersState, localStorageKey, keepFiltersInLocalStorage } = options;

  if (!keepFiltersInLocalStorage) return defaultColumnFiltersState ?? [];
  if (!localStorageKey) {
    console.warn('useTableState: localStorageKey is required when keepFiltersInLocalStorage is true');
    return defaultColumnFiltersState ?? [];
  }

  const columnFiltersKey = getColumnFiltersKey(localStorageKey);
  try {
    const filtersState = localStorage.getItem(columnFiltersKey);
    if (!filtersState) return defaultColumnFiltersState ?? [];
    return JSON.parse(filtersState) ?? defaultColumnFiltersState ?? [];
  } catch (_) {
    localStorage.removeItem(columnFiltersKey);
    return defaultColumnFiltersState ?? [];
  }
};

const useTableState = <TFilters>(options: UseTableStateOptions<TFilters> = {}): UseTableStateReturn<TFilters> => {
  const { defaultSortingState, transformFilters, localStorageKey, keepFiltersInLocalStorage, debounceDelay } = options;

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(
    () => getUsedDefaultColumnFiltersState(options) ?? [],
  );

  const [sorting, setSorting] = useState<SortingState>(defaultSortingState ?? []);
  const [pagination, setPagination] = useState<PaginationState>(DEFAULT_TABLE_PAGINATION);

  const setColumnFiltersAndResetPagination = useCallback((filters: Updater<ColumnFiltersState>) => {
    if (localStorageKey && keepFiltersInLocalStorage) {
      const columnFiltersKey = getColumnFiltersKey(localStorageKey);
      updateLocalStorageItem(columnFiltersKey, filters);
    }
    setColumnFilters(filters);
    setPagination(DEFAULT_TABLE_PAGINATION);
  }, []);

  const serverFriendlyFilters = useMemo(
    () => transformFilters?.(columnFilters) ?? defaultTransformFilters<TFilters>(columnFilters),
    [columnFilters],
  );
  const debouncedServerFriendlyFilters = useDebounce(serverFriendlyFilters, debounceDelay ?? DEFAULT_DEBOUNCE_DELAY);
  const sortingQuery = useMemo(() => defaultSortingTransform(sorting), [sorting]);

  return {
    columnFilters,
    setColumnFilters: setColumnFiltersAndResetPagination,
    sorting,
    setSorting,
    pagination,
    setPagination,

    serverFriendlyFilters,
    debouncedServerFriendlyFilters,
    sortingQuery,
  };
};

export default useTableState;
