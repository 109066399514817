import { addMonths, subMonths } from 'date-fns';
import { useState, useMemo } from 'react';
import { chunks } from 'src/utils/chunks';
import { getCalendarDates } from '../WeekPicker/helpers';
import { Updater } from '@tanstack/react-table';

export type UseMonthGridStateReturn = {
  currentMonthStart: Date;
  onCurrentMonthStartChange: (value: Updater<Date>) => void;
  dates: Date[];
  weekRows: Date[][];
  onNextMonth: () => void;
  onPrevMonth: () => void;
};

export type UseMonthGridStateOptions = {
  defaultCurrentMonthStart?: Date;
};

const useMonthGridState = ({ defaultCurrentMonthStart }: UseMonthGridStateOptions = {}): UseMonthGridStateReturn => {
  const [currentMonthStart, setCurrentMonthStart] = useState(() => defaultCurrentMonthStart ?? new Date());

  const dates = useMemo(() => getCalendarDates(currentMonthStart), [currentMonthStart]);
  const weekRows = useMemo(() => chunks(dates, 7), [dates]);

  const handleNextMonth = (): void => setCurrentMonthStart((prev) => addMonths(prev, 1));
  const handlePrevMonth = (): void => setCurrentMonthStart((prev) => subMonths(prev, 1));

  return {
    dates,
    weekRows,
    currentMonthStart,
    onCurrentMonthStartChange: setCurrentMonthStart,
    onNextMonth: handleNextMonth,
    onPrevMonth: handlePrevMonth,
  };
};

export default useMonthGridState;
