import { FC, useEffect } from 'react';
import clsx from 'clsx';
import { CreateHeadquatersRequest } from 'src/api';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { HeadquartersSchema } from './schema';
import { FormattedMessage } from 'react-intl';
import { NumberFieldControlled } from 'src/components/fields/NumberField';
import { TextFieldControlled } from 'src/components/fields/Textfield';
import { SelectControlled } from 'src/components/fields/Select';
import useAllVatTariffs from 'src/api/hooks/queries/useAllVatTariffs';
import NewItemPopupFooter from 'src/components/common/NewItemPopupFooter';
import { zodResolver } from '@hookform/resolvers/zod';
import SeparatedTextField from 'src/components/fields/MultiInput/SeparatedTextField';
import { FileFieldControlled } from 'src/components/fields/FileField';

export type HeadquatersFormProps = {
  className?: string;
  defaultValues?: Partial<CreateHeadquatersRequest>;
  onSubmit: SubmitHandler<CreateHeadquatersRequest>;
};

const HeadquatersForm: FC<HeadquatersFormProps> = ({ className, defaultValues, onSubmit }) => {
  const { data } = useAllVatTariffs();

  const form = useForm<HeadquartersSchema>({
    resolver: zodResolver(HeadquartersSchema),
    defaultValues,
  });

  const { handleSubmit, control, setValue } = form;

  useEffect(() => {
    if (!defaultValues) return;
    Object.entries(defaultValues).forEach(([k, v]) => setValue(k as keyof CreateHeadquatersRequest, v));
  }, [defaultValues]);

  return (
    <div className={clsx('flex-col w-full', className)}>
      <form className='flex flex-col w-full gap-y-4' onSubmit={handleSubmit(onSubmit)}>
        <FormProvider {...form}>
          <NumberFieldControlled
            control={control}
            name='serviceDurationRate'
            asterisk
            label={<FormattedMessage id='app.parameter_title.service_duration_rate' />}
          />

          <NumberFieldControlled
            control={control}
            name='serviceMileageRate'
            asterisk
            label={<FormattedMessage id='app.parameter_title.service_mileage_rate' />}
          />

          <NumberFieldControlled
            control={control}
            name='serviceTimeOnRoadRate'
            asterisk
            label={<FormattedMessage id='app.parameter_title.service_time_on_road_rate' />}
          />

          <TextFieldControlled
            control={control}
            name='companyName'
            asterisk
            label={<FormattedMessage id='app.parameter_title.company_name' />}
          />

          <TextFieldControlled
            control={control}
            name='companyCity'
            asterisk
            label={<FormattedMessage id='app.parameter_title.company_city' />}
          />

          <TextFieldControlled
            control={control}
            name='companyStreet'
            asterisk
            label={<FormattedMessage id='app.parameter_title.company_street' />}
          />

          <TextFieldControlled
            control={control}
            name='companyZIP'
            asterisk
            label={<FormattedMessage id='app.parameter_title.company_zip' />}
          />

          <TextFieldControlled
            control={control}
            name='companyIn'
            asterisk
            label={<FormattedMessage id='app.parameter_title.IC' />}
          />

          <TextFieldControlled
            control={control}
            name='companyVatNumber'
            label={<FormattedMessage id='app.common.vat' />}
          />

          <SelectControlled
            asterisk
            label={<FormattedMessage id='app.vat_tariff.value' />}
            control={control}
            name='defaultVatTariffId'
            options={data ?? []}
            getOptionLabel={(option) => `${option.tariffValue}%`}
            getOptionValue={(option) => option.id}
          />

          <TextFieldControlled control={control} name='email' label={<FormattedMessage id='app.common.email' />} />

          <TextFieldControlled control={control} name='phone' label={<FormattedMessage id='app.common.phone' />} />

          <Controller
            name='accountingEmail'
            control={control}
            render={({ field: { value, onChange } }) => (
              <SeparatedTextField
                name='accountingEmail'
                separator=';'
                label={<FormattedMessage id='app.tenant.accounting_email' />}
                value={value ?? ''}
                onChange={onChange}
              />
            )}
          />
          <FileFieldControlled
            control={control}
            name='stampFile'
            label={<FormattedMessage id='app.headquarters.stamp' />}
            type='file'
            accept='image/*'
          />
        </FormProvider>
        <NewItemPopupFooter />
      </form>
    </div>
  );
};

HeadquatersForm.displayName = 'HeadquatersForm';

export default HeadquatersForm;
