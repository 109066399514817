import { addMinutes, getISODay, setDay, startOfDay } from 'date-fns';
import { getAllMinutes, snapToHalfHour } from 'src/helpers/datetime';
import { getRemSize } from 'src/hooks/useRemSize';
import { MINUTES_PER_ROW, ROWS_PER_HOUR } from '../constants';
import { EventGridSettings, EventToGridRawFunc, GetGridCellDimensionsRawFunc, GridToTimeSpanRawFunc } from '../types';

export const snapToGrid = (row: number, col: number): { row: number; col: number } => {
  const snappedRow = Math.round(row / (ROWS_PER_HOUR / 2)) * (ROWS_PER_HOUR / 2);
  const snappedCol = col;

  return {
    row: snappedRow,
    col: snappedCol,
  };
};

export const timeSpanToGridRow = (
  start: Date,
  end: Date,
  gridSettings: EventGridSettings,
): { row: number; rowSpan: number } => {
  const { START_MINUTE } = gridSettings;

  const startMinute = getAllMinutes(start);
  const endMinute = getAllMinutes(end);

  // + 1 - because we start from 1
  const startRow = (startMinute - START_MINUTE) / MINUTES_PER_ROW + 1;
  const endRow = (endMinute - START_MINUTE) / MINUTES_PER_ROW + 1;

  const row = startRow;
  const rowSpan = endRow - startRow;

  return {
    row,
    rowSpan,
  };
};

export const gridToTimeSpanWeek: GridToTimeSpanRawFunc = (options, gridSettings) => {
  const { start, row, col, end } = options;
  const { START_MINUTE, MIN_ENABLED_MINUTE } = gridSettings;

  const duration = getAllMinutes(end) - getAllMinutes(start);
  const startOfEventDay = startOfDay(start);
  const startMinute = snapToHalfHour(START_MINUTE + MINUTES_PER_ROW * row);
  const eventDayWithTime = addMinutes(
    startOfEventDay,
    startMinute < (MIN_ENABLED_MINUTE ?? START_MINUTE) ? MIN_ENABLED_MINUTE ?? START_MINUTE : startMinute,
  );

  const newStartDate = setDay(eventDayWithTime, weekColToWeekDay(col), {
    weekStartsOn: 1,
  });
  const newEndDate = addMinutes(newStartDate, duration);

  return {
    start: newStartDate,
    end: newEndDate,
  };
};

export const eventToGridDay: EventToGridRawFunc = (event, gridSettings) => {
  const { start, end } = event;
  const { row, rowSpan } = timeSpanToGridRow(start, end, gridSettings);
  const col = 1;
  const colSpan = 1;

  return {
    row,
    rowSpan,
    col,
    colSpan,
  };
};

export const eventToGridWeek: EventToGridRawFunc = (event, gridSettings) => {
  const { start, end } = event;
  const { row, rowSpan } = timeSpanToGridRow(start, end, gridSettings);

  const col = getISODay(start);
  const colSpan = 1; // TODO: implement?

  return {
    row,
    rowSpan,
    col,
    colSpan,
  };
};

export const gridToTimeSpanDay: GridToTimeSpanRawFunc = (options, gridSettings) => {
  const { start, row, end } = options;
  const { START_MINUTE, MIN_ENABLED_MINUTE } = gridSettings;

  const duration = getAllMinutes(end) - getAllMinutes(start);
  const startOfEventDay = startOfDay(start);
  const startMinute = snapToHalfHour(START_MINUTE + MINUTES_PER_ROW * row);
  const eventDayWithTime = addMinutes(
    startOfEventDay,
    startMinute < (MIN_ENABLED_MINUTE ?? START_MINUTE) ? MIN_ENABLED_MINUTE ?? START_MINUTE : startMinute,
  );

  const newStartDate = eventDayWithTime;
  const newEndDate = addMinutes(newStartDate, duration);

  return {
    start: newStartDate,
    end: newEndDate,
  };
};

export const weekColToWeekDay = (col: number): number => col;

export const getDimensionsFromNode = (
  node: HTMLElement | null,
  gridSettings: EventGridSettings,
): { columnWidth: number; rowHeight: number } => {
  const { COLS, ROWS } = gridSettings;

  if (!node) {
    return {
      columnWidth: 0,
      rowHeight: 0,
    };
  }

  const { width, height } = node.getBoundingClientRect();

  const columnWidth = width / COLS;
  const rowHeight = height / ROWS;

  return {
    columnWidth,
    rowHeight,
  };
};

export const getGridCellDimensions: GetGridCellDimensionsRawFunc = (gridRef, gridSettings) => {
  const remSize = getRemSize();
  const { ROWS, COLS } = gridSettings;

  if (!gridRef.current)
    return {
      columnWidth: 0,
      rowHeight: 0,
    };

  const { width, height } = gridRef.current.getBoundingClientRect();

  const gridWidth = width - 2 * remSize;
  const columnWidth = gridWidth / COLS;
  const rowHeight = height / ROWS;

  return {
    columnWidth,
    rowHeight,
  };
};
