import { createContext } from 'react';
import stub from 'src/utils/stub';

export type PopupContextValue = {
  open: boolean;
  onClose: () => void;
};

const defaultValue: PopupContextValue = {
  open: false,
  onClose: stub,
};

export const PopupContext = createContext(defaultValue);
