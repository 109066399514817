import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import useAllProductTemplates from 'src/api/hooks/queries/useAllProductTemplates';
import { SelectControlled } from 'src/components/fields/Select';

export type ProductFormTemplateSelectProps = {
  className?: string;
};

const ProductFormTemplateSelect: FC<ProductFormTemplateSelectProps> = ({ className }) => {
  const { control, setValue } = useFormContext();
  const { data: templates } = useAllProductTemplates();

  return (
    <SelectControlled
      label={<FormattedMessage id='app.order.product' />}
      className={className}
      options={templates ?? []}
      getOptionLabel={(template) => `${template.productName} (${template.productProducer})`}
      getOptionValue={(tempalte) => tempalte.id}
      extraOnChange={(value) => {
        const template = templates?.find((template) => template.id === value);
        if (!template) return;

        setValue(`productName`, template.productName);
        setValue(`productProducer`, template.productName);
      }}
      control={control}
      name={`product._template`}
    />
  );
};

ProductFormTemplateSelect.displayName = 'ProductFormTemplateSelect';

export default ProductFormTemplateSelect;
