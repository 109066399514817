import { FC } from 'react';
import { Navigate } from 'react-router-dom';
import useOrder from 'src/api/hooks/queries/useOrder';
import Loader from 'src/components/utils/Loader';
import useOrderIdFromToken from '../../hooks/useOrderIdFromToken';
import FinishOrderFormContainer from '../FinishOrderFormContainer';
import useSavedOrderForm from 'src/api/hooks/queries/useSavedOrderForm';

export type FinishOrderFormDataProviderProps = {
  className?: string;
};

const FinishOrderFormDataProvider: FC<FinishOrderFormDataProviderProps> = ({ className }) => {
  const orderId = useOrderIdFromToken();
  const { data: order, isLoading } = useOrder(orderId);
  const { data: savedForm, isLoading: isLoadingSavedForm } = useSavedOrderForm(orderId);

  if (isLoading || isLoadingSavedForm) return <Loader />;
  if (!order) return <Navigate to='/500' />;

  return <FinishOrderFormContainer order={order} savedForm={savedForm} className={className} />;
};

FinishOrderFormDataProvider.displayName = 'FinishOrderFormDataProvider';

export default FinishOrderFormDataProvider;
