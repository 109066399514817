import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { PaginationState } from '@tanstack/react-table';
import { TenantsPageResponse } from 'src/api/.gen';
import { SortingQuery } from 'src/api/types/shared/sorting';
import { useApiClient } from 'src/api/useApiClient';
import { DEFAULT_TABLE_PAGINATION } from 'src/constants/table';
import queryKeysFactory from 'src/store/queryKeysFactory';
import { TenantFilter } from 'src/types/filters';

const useTenants = (
  filters?: TenantFilter,
  sorting?: SortingQuery,
  pagination: PaginationState = DEFAULT_TABLE_PAGINATION,
): UseQueryResult<TenantsPageResponse> => {
  const client = useApiClient();
  const { queryKey } = queryKeysFactory.tenant.list._ctx.search(filters, sorting, pagination);

  return useQuery(
    queryKey,
    async () => {
      const data = await client.tenant.getTenantsPage({ ...filters, ...sorting, ...pagination });
      return data;
    },
    {
      refetchInterval: false,
      staleTime: 1000 * 60 * 5,
    },
  );
};

export default useTenants;
