import { useCallback } from 'react';
import { LicenceResponse } from 'src/api';
import useLicenses from 'src/api/hooks/queries/useLicenses';

const useGetIsModuleValid = (): ((module: LicenceResponse.module) => boolean) => {
  const { data } = useLicenses();

  return useCallback(
    (module: LicenceResponse.module) => Boolean((data ?? []).find((lincese) => lincese.module === module)?.isValid),
    [data],
  );
};

export default useGetIsModuleValid;
