import { FC } from 'react';
import clsx from 'clsx';
import ParametersRow from 'src/components/common/ParametersRow';
import { FormattedMessage } from 'react-intl';
import { HeadquartersResponse } from 'src/api';

export type HeadquartersPreviewProps = {
  className?: string;
  data: HeadquartersResponse;
};

const HeadquartersPreview: FC<HeadquartersPreviewProps> = ({ data, className }) => {
  return (
    <div className={clsx(className, 'flex flex-col w-full')}>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.parameter_title.company_name' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.companyName}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.parameter_title.company_street' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.companyStreet}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.parameter_title.company_city' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.companyCity}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.parameter_title.company_zip' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.companyZIP}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.parameter_title.IC' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.companyIn}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.parameter_title.DIC' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.companyVatNumber}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.parameter_title.service_duration_rate' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.serviceDurationRate}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.parameter_title.service_mileage_rate' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.serviceMileageRate}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.parameter_title.service_time_on_road_rate' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.serviceTimeOnRoadRate}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.headquarters.default_vat_tariff' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.defaultVatTariff?.tariffValue}%</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.tenant.accounting_email' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.accountingEmail?.split(';').join(', ')}</ParametersRow.Value>
      </ParametersRow>
    </div>
  );
};

HeadquartersPreview.displayName = 'HeadquartersPreview';

export default HeadquartersPreview;
