import clsx from 'clsx';
import { format } from 'date-fns';
import { FC } from 'react';
import useEventsGridSettings from 'src/components/EventsGrid/hooks/useEventsGridSettings';
import { range } from 'ramda';

const HoursBarMTD: FC = () => {
  const { START_HOUR, END_HOUR } = useEventsGridSettings();
  const cols = range(START_HOUR, END_HOUR);

  return (
    <div
      className={clsx(
        'col-start-1 h-[2.8125rem] col-end-2 border-b-2 border-neutral-300 row-start-1 divide-x divide-neutral-300 grid border-y',
      )}
      style={{
        gridTemplateColumns: `repeat(${cols.length}, 96px)`,
      }}
    >
      {cols.map((hour, i) => (
        <div
          key={i}
          className='h-full px-2 bg-neutral-100 flex items-center text-neutral-600 text-lg'
          style={{ gridColumnStart: i + 1 }}
        >
          <span>{format(new Date(0, 0, 0, hour), 'HH:mm')}</span>
        </div>
      ))}
    </div>
  );
};

export default HoursBarMTD;
