import { FC } from 'react';
import useUpdateHeadquarters from 'src/api/hooks/mutations/headquaters/useUpdateHeadquarters';
import { useCallback } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { CreateHeadquatersRequest, HeadquartersResponse } from 'src/api';
import { usePopupContext } from 'src/components/Popup/hook';
import HeadquatersForm from 'src/pages/ParametersPage/components/HeadquatersForm';

export type UpdateHeadquartersFormProps = {
  className?: string;
  headquarters: HeadquartersResponse;
  headquartersId: number;
};

const UpdateHeadquartersForm: FC<UpdateHeadquartersFormProps> = ({ headquartersId, headquarters, className }) => {
  const updateHeadquarters = useUpdateHeadquarters();
  const { onClose } = usePopupContext();

  const handleSubmit: SubmitHandler<CreateHeadquatersRequest> = useCallback((data) => {
    updateHeadquarters.mutate({ ...data, id: headquartersId });
    onClose();
  }, []);

  return (
    <HeadquatersForm
      className={className}
      defaultValues={headquarters as CreateHeadquatersRequest}
      onSubmit={handleSubmit}
    />
  );
};

UpdateHeadquartersForm.displayName = 'UpdateHeadquartersForm';

export default UpdateHeadquartersForm;
