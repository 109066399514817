import { FC, useCallback } from 'react';
import useUpdateDeliveryTariff from 'src/api/hooks/mutations/deliveryTariffs/useUpdateDeliveryTariff';
import { usePopupContext } from 'src/components/Popup/hook';
import { CreateDeliveryTariffRequest } from 'src/api';
import { SubmitHandler } from 'react-hook-form';
import DeliveryTariffForm from '../../DeliveryTariffsPage/DeliveryTariffForm';
import useDeliveryTariff from 'src/api/hooks/queries/useDeliveryTariff';

export type UpdateDeliveryTariffFromProps = {
  className?: string;
  deliveryTariffId: number;
};

const UpdateDeliveryTariffFrom: FC<UpdateDeliveryTariffFromProps> = ({ deliveryTariffId, className }) => {
  const updateDeliveryTariff = useUpdateDeliveryTariff();
  const { data: tariff } = useDeliveryTariff(deliveryTariffId);
  const { onClose } = usePopupContext();

  const onSubmit: SubmitHandler<CreateDeliveryTariffRequest> = useCallback(
    (data) => {
      updateDeliveryTariff.mutate({ id: deliveryTariffId, ...data });
      onClose();
    },
    [updateDeliveryTariff, onClose],
  );

  return <DeliveryTariffForm defaultValues={tariff} onSubmit={onSubmit} className={className} />;
};

UpdateDeliveryTariffFrom.displayName = 'UpdateDeliveryTariffFrom';

export default UpdateDeliveryTariffFrom;
