import { FC, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { WeekOrdersStatisticsForStatusResponse, OrderStatusResponse } from 'src/api';
import useWeekOrdersStatistics from 'src/api/hooks/queries/useWeekOrdersStatistics';
import PageCard from 'src/components/PageCard';
import StatsProgressBar from 'src/components/StatsProgressBar';
import StatsProgressBarLegend from 'src/components/StatsProgressBarLegend/StatsProgressBarLegend';
import { getStatusBackgroundClassNames } from 'src/components/StatusLabel/helpers';
import Loader from 'src/components/utils/Loader';
import { twMerge } from 'tailwind-merge';

export type OrdersStatsProgressProps = {
  className?: string;
  dayOfTheWeek: Date;
};

const displayedStatuses = [
  WeekOrdersStatisticsForStatusResponse.status.INVOICED,
  WeekOrdersStatisticsForStatusResponse.status.COMPLETED,
  WeekOrdersStatisticsForStatusResponse.status.PLANNED,
];

const OrdersStatsProgress: FC<OrdersStatsProgressProps> = ({ className, dayOfTheWeek }) => {
  const { data, isLoading } = useWeekOrdersStatistics(dayOfTheWeek);

  const items = useMemo(() => {
    if (!data) return [];

    const displyedData = data
      .filter((item) => displayedStatuses.includes(item.status))
      .toSorted((a, b) => displayedStatuses.indexOf(a.status) - displayedStatuses.indexOf(b.status));

    const statItems = displyedData.map((item) => ({
      value: item.count,
      className: getStatusBackgroundClassNames(item.status as unknown as OrderStatusResponse.name),
      label: (
        <FormattedMessage
          id={`app.order_status.${item.status.toLowerCase() as unknown as Lowercase<OrderStatusResponse.name>}`}
        />
      ),
    }));

    return statItems;
  }, [data]);

  if (isLoading) return <Loader />;
  if (!data || data.length === 0) return null;

  return (
    <PageCard className={twMerge('flex flex-col', className)}>
      <span className='font-medium text-lg mb-4'>
        <FormattedMessage id='app.dashboard.orders_stats' />
      </span>
      <StatsProgressBar items={items} className='mb-6' />
      <StatsProgressBarLegend items={items} />
    </PageCard>
  );
};

export default OrdersStatsProgress;
