import { useRef } from 'react';

const useLastDefined = <T>(value: T | undefined, defaultValue?: T): T | undefined => {
  const lastValueRef = useRef(defaultValue);

  if (value !== undefined) {
    lastValueRef.current = value;
  }

  return lastValueRef.current;
};

export default useLastDefined;
