import { FC } from 'react';
import { Navigate } from 'react-router-dom';
import useAllHeadquarters from 'src/api/hooks/queries/useAllHeadquarters';
import useAllOrderTypes from 'src/api/hooks/queries/useAllOrderTypes';
import useAllVatTariffs from 'src/api/hooks/queries/useAllVatTariffs';
import useOrder from 'src/api/hooks/queries/useOrder';
import useCurrentTenantConfig from 'src/api/hooks/queries/useCurrentTenantConfig';
import Loader from 'src/components/utils/Loader';
import { isLgOrBigger } from 'src/helpers/screenSize';
import useWindowWidth from 'src/hooks/useWindowWidth';
import MobileUpdateServicePopup from './MobileUpdateServicePopup';
import DesktopUpdateServicePopup from './DesktopUpdateServicePopup';

export type UpdateServicePopupProps = {
  orderId: number;
  open: boolean;
  onClose: () => void;
};

const UpdateServicePopup: FC<UpdateServicePopupProps> = ({ open, onClose, orderId }) => {
  const { data: vatTariffs, isLoading: isLoadingVatTariffs } = useAllVatTariffs();
  const { data: headquarters, isLoading: isLoadingHeadquarters } = useAllHeadquarters();
  const { data: orderTypes, isLoading: isLoadingOrderTypes } = useAllOrderTypes();
  const { data: order, isLoading: isLoadingOrder } = useOrder(orderId);
  const { data: config, isLoading: isLoadingConfig } = useCurrentTenantConfig();

  const isLoading =
    isLoadingOrder || isLoadingVatTariffs || isLoadingHeadquarters || isLoadingOrderTypes || isLoadingConfig;

  const windowWidth = useWindowWidth();
  const isMobile = !isLgOrBigger(windowWidth);

  if (isLoading) return <Loader />;
  if (!order || !vatTariffs || !headquarters || !orderTypes) return <Navigate to='/500' />;

  if (isMobile) {
    return (
      <MobileUpdateServicePopup
        open={open}
        onClose={onClose}
        isLoading={isLoading}
        config={config}
        order={order}
        orderTypes={orderTypes}
        vatTariffs={vatTariffs}
        headquarters={headquarters}
      />
    );
  }

  return (
    <DesktopUpdateServicePopup
      defaultShowCalendar={config?.defaultShowCalendarInOrderForm}
      open={open}
      onClose={onClose}
      isLoading={isLoading}
      config={config}
      order={order}
      orderTypes={orderTypes}
      vatTariffs={vatTariffs}
      headquarters={headquarters}
    />
  );
};

UpdateServicePopup.displayName = 'UpdateServicePopup';

export default UpdateServicePopup;
