import { DocumentTextIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { FC, HTMLAttributes } from 'react';
import Button from 'src/components/Button';
import WithLoader from 'src/components/utils/WithLoader';
import useOpenFileInNewTab from 'src/hooks/useOpenFileInNewTab';

export type DocumentPreviewProps = {
  fileId: number;
} & HTMLAttributes<HTMLDivElement>;

const DocumentPreview: FC<DocumentPreviewProps> = ({ className, fileId }) => {
  const { openInNewWindow, state } = useOpenFileInNewTab(fileId);

  return (
    <WithLoader isLoading={state === 'downloading'}>
      <div className={clsx(className, 'flex flex-col items-center cursor-pointer')}>
        <Button variant='cta' size='small'>
          <DocumentTextIcon
            onClick={openInNewWindow}
            className={'text-primary h-8 hover:text-primaryFocus'}
            fontSize={26}
          />
        </Button>
      </div>
    </WithLoader>
  );
};

DocumentPreview.displayName = 'DocumentPreview';

export default DocumentPreview;
