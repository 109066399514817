import { LinkIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { OrderResponse } from 'src/api';
import HyperLink from 'src/components/common/HyperLink';
import Tooltip from 'src/components/common/Tooltip';
import Popup from 'src/components/Popup';
import { getCanFinish } from 'src/pages/ServicePreviewPage/helpers';
import ServicePreview from 'src/pages/ServicePreviewPage/ServicePreview';

export type EventInfoPopupProps = {
  className?: string;
  open: boolean;
  onClose: () => void;
  order: OrderResponse;
};

const EventInfoPopup: FC<EventInfoPopupProps> = ({ open, onClose, order }) => {
  const canFinish = getCanFinish(order);

  return (
    <Popup open={open} onClose={onClose}>
      <ServicePreview
        data={order}
        extraTitle={
          <>
            {canFinish && (
              <Tooltip text={<FormattedMessage id='app.buttons.finish' />}>
                <HyperLink forceExternal href={order.orderUrl} target='_blank' className='flex gap-x-1 text-inherit'>
                  <PencilSquareIcon className='h-6 text-primary' />
                </HyperLink>
              </Tooltip>
            )}
            <HyperLink href={`/order/${order.id}`} target='_blank'>
              <LinkIcon className='w-6' />
            </HyperLink>
          </>
        }
      />
    </Popup>
  );
};

EventInfoPopup.displayName = 'EventInfoPopup';

export default EventInfoPopup;
