import { FC } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import BackIcon from 'src/components/Icons/BackIcon';
import PageContentHeader from 'src/components/PageContentHeader';
import useStockOrder from 'src/api/hooks/queries/useStockOrder';
import Loader from 'src/components/utils/Loader';
import StockOrderPreview from './components/StockOrderPreview';
import PageCard from 'src/components/PageCard';

export type StockOrderDetailPageProps = {
  //
};

const StockOrderDetailPage: FC<StockOrderDetailPageProps> = () => {
  const stockOrderId = Number(useParams().stockOrderId);
  const navigate = useNavigate();
  const { isLoading, data } = useStockOrder(stockOrderId);

  if (isLoading) return <Loader />;
  if (!data) return <Navigate to='/404' />;

  return (
    <>
      <PageContentHeader
        className='my-4'
        title={<FormattedMessage id='app.stock_order_detail_page.title' values={{ value: data.id }} />}
        secondaryButtonText={
          <>
            <BackIcon />
            <FormattedMessage id='app.buttons.back' defaultMessage='Zpět' />
          </>
        }
        onSecondaryButtonClick={() => navigate(-1)}
        hidePrimaryButton
        mobilePrimaryButtonType='edit'
      />
      <PageCard>
        <StockOrderPreview data={data} noTitle />
      </PageCard>
    </>
  );
};

StockOrderDetailPage.displayName = 'StockOrderDetailPage';

export default StockOrderDetailPage;
