import clsx from 'clsx';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { EmployeeResponse } from 'src/api';
import ParametersRow from 'src/components/common/ParametersRow';

type EmployeePreviewProps = {
  data: EmployeeResponse;
  className?: string;
  noTitle?: boolean;
};

const EmployeePreview: React.FC<EmployeePreviewProps> = ({ data, noTitle, className }) => {
  return (
    <div className={clsx(className, 'flex flex-col w-full')}>
      {!noTitle && (
        <ParametersRow.Title>
          <FormattedMessage id='app.employee.detail_title' defaultMessage='Detail pobocky' />
        </ParametersRow.Title>
      )}
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.employee.name' defaultMessage='Jméno' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.name}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.common.phone' defaultMessage='Telefon' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.phoneNumber}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.common.email' defaultMessage='Email' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.email}</ParametersRow.Value>
      </ParametersRow>
      {/* <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.employee.role' defaultMessage='Role' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.}</ParametersRow.Value>
      </ParametersRow> */}
    </div>
  );
};

export default EmployeePreview;
