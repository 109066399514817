import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { CreateDeliveryTariffRequest, DeliveryTariffResponse } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';
import { WithId } from 'src/types/mutations';

const useUpdateDeliveryTariff = (): UseMutationResult<
  DeliveryTariffResponse,
  unknown,
  WithId<CreateDeliveryTariffRequest>
> => {
  const queryClient = useQueryClient();
  const client = useApiClient();

  return useMutation<DeliveryTariffResponse, unknown, WithId<CreateDeliveryTariffRequest>>(
    async (tariff: WithId<CreateDeliveryTariffRequest>) => {
      const data = await client.deliveryTariffs.updateDeliveryTariff({ id: tariff.id, requestBody: tariff });
      return data as DeliveryTariffResponse;
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(queryKeysFactory.deliveryTariffs.list.queryKey);
        queryClient.invalidateQueries(queryKeysFactory.deliveryTariffs.detail(data.id).queryKey);
        toast(<FormattedMessage id='app.toasts.update_success' />, {
          type: 'success',
        });
      },
      onError: () => {
        toast(<FormattedMessage id='app.toasts.update_fail' />, {
          type: 'error',
        });
      },
    },
  );
};

export default useUpdateDeliveryTariff;
