import { omit, pipe } from 'ramda';
import { FC, useMemo } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import useUpdateTranslationPack from 'src/api/hooks/mutations/translations/useUpdateTranslationPack';
import useDefaultTranslationPack from 'src/api/hooks/queries/useDefaultTranslationPack';
import useDefaultTranslations from 'src/api/hooks/queries/useDefaultTranslations';
import Loader from 'src/components/utils/Loader';
import WithLoader from 'src/components/utils/WithLoader';
import { mapKeys } from 'src/utils/commonUtils';
import TranslationsForm, { TranslationsFormSumbitOptions } from './components/TranslationsForm/TranslationsForm';
import { fromRhfSafeId, omitEmptyValues, toRhfSafeId } from './helpers';

export type TranslationsWizzardPageProps = {
  className?: string;
};

const TranslationsWizzardPage: FC<TranslationsWizzardPageProps> = () => {
  const language = useParams().language as string;

  const { isLoading, data: translationsPack } = useDefaultTranslationPack(language);
  const { isLoading: isLoadingDefault, data: defaultTranslations } = useDefaultTranslations();

  const rhfSafeDefaultTranslations = useMemo(
    () => mapKeys(toRhfSafeId, defaultTranslations),
    [defaultTranslations],
  ) as Record<string, string>;

  const rhfSafeTranslations = useMemo(
    () => mapKeys(toRhfSafeId, translationsPack?.json ?? {}),
    [translationsPack],
  ) as Record<string, string>;

  const updatedTranslationPack = useUpdateTranslationPack();

  const onSubmit = (values: Record<string, string>, options: TranslationsFormSumbitOptions): void => {
    const { deletedTranslations } = options;
    const newTranslations = pipe(
      omit(deletedTranslations),
      omitEmptyValues,
      mapKeys(fromRhfSafeId),
    )(values) as unknown as Record<string, string>;

    updatedTranslationPack.mutate({
      language,
      json: newTranslations,
    });
  };

  if (isLoading || isLoadingDefault) return <Loader />;
  if (!translationsPack || !defaultTranslations) return <Navigate to='/500' />;

  return (
    <WithLoader isLoading={updatedTranslationPack.isLoading}>
      <TranslationsForm
        language={language}
        translations={rhfSafeTranslations}
        defaultTranslations={rhfSafeDefaultTranslations}
        onSubmit={onSubmit}
      />
    </WithLoader>
  );
};

TranslationsWizzardPage.displayName = 'TranslationsWizzardPage';

export default TranslationsWizzardPage;
