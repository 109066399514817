import { Column } from '@tanstack/react-table';
import { ChangeEvent, KeyboardEvent, useCallback, useState } from 'react';
import Textfield from 'src/components/fields/Textfield';

export type TextFilterProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  column: Column<any, unknown>;
};

const TextFilter: React.FC<TextFilterProps> = ({ column }) => {
  const { getFilterValue, setFilterValue } = column;
  const [value, setValue] = useState((getFilterValue() ?? '') as string);

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value), []);
  const handleBlur = useCallback((e: ChangeEvent<HTMLInputElement>) => setFilterValue(e.target.value || undefined), []);
  const handleKeyDown = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key == 'Enter') setFilterValue(e.currentTarget.value || undefined);
  }, []);

  return (
    <div className='w-40'>
      <Textfield
        className='max-w-full'
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
      />
    </div>
  );
};

export default TextFilter;
