/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateSavedOrderFormRequest } from '../models/CreateSavedOrderFormRequest';
import type { SavedOrderFormResponse } from '../models/SavedOrderFormResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class SavedOrderFormsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns SavedOrderFormResponse
   * @throws ApiError
   */
  public create({
    requestBody,
  }: {
    requestBody: CreateSavedOrderFormRequest;
  }): CancelablePromise<SavedOrderFormResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/saved-order-forms',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns SavedOrderFormResponse
   * @throws ApiError
   */
  public findOne({ orderId }: { orderId: number }): CancelablePromise<SavedOrderFormResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/saved-order-forms/{orderId}',
      path: {
        orderId: orderId,
      },
    });
  }
  /**
   * @returns void
   * @throws ApiError
   */
  public remove({ orderId }: { orderId: number }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/saved-order-forms/{orderId}',
      path: {
        orderId: orderId,
      },
    });
  }
}
