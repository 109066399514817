import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import AutoPopup from 'src/components/common/AutoPopup';
import UpdateProductTemplateForm from '../UpdateProductTemplateForm';

export type UpdateProductTemplatePopupProps = {
  className?: string;
  productTemplateId?: number;
};

export const UPDATE_PRODUCT_TEMPLATE_POPUP_ID = 'update_product_template';

const UpdateProductTemplatePopup: FC<UpdateProductTemplatePopupProps> = ({ className }) => {
  const productTemplateId = Number(useParams().productTemplateId);

  return (
    <AutoPopup
      id={UPDATE_PRODUCT_TEMPLATE_POPUP_ID}
      title={<FormattedMessage id='app.devices.new_device.popup_title' />}
      className={className}
    >
      <UpdateProductTemplateForm productTemplateId={productTemplateId} />
    </AutoPopup>
  );
};

UpdateProductTemplatePopup.displayName = 'UpdateProductTemplatePopup';

export default UpdateProductTemplatePopup;
