import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { CreateVatTariffRequest, VatTariffResponse } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

const useAddVatTariff = (): UseMutationResult<VatTariffResponse, unknown, CreateVatTariffRequest> => {
  const queryClient = useQueryClient();
  const client = useApiClient();

  return useMutation<VatTariffResponse, unknown, CreateVatTariffRequest>(
    async (tariff: CreateVatTariffRequest) => {
      const data = await client.vatTariffs.createVatTariff({ requestBody: tariff });
      return data as VatTariffResponse;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeysFactory.vatTariffs.list._def);
        toast(<FormattedMessage id='app.toasts.add_success' />, {
          type: 'success',
        });
      },
      onError: () => {
        toast(<FormattedMessage id='app.toasts.add_fail' />, {
          type: 'error',
        });
      },
    },
  );
};

export default useAddVatTariff;
