import { PlusCircleIcon } from '@heroicons/react/24/outline';
import { FC, ReactNode } from 'react';
import Button from 'src/components/Button';
import { twMerge } from 'tailwind-merge';
import ArrayInput from './ArrayInput';
import FieldLabel from '../FieldLabel';
import FieldError from '../FieldError';

export type ArrayTextFieldProps = {
  className?: string;
  disabled?: boolean;
  name: string;
  onChange: (value: string[]) => void;
  value: string[];
  hideErrorMessage?: boolean;
  error?: string;
  label: ReactNode;
};

const ArrayTextField: FC<ArrayTextFieldProps> = ({
  value,
  onChange,
  className,
  name,
  label,
  hideErrorMessage,
  error,
  disabled,
}) => {
  const addInput = (): void => {
    const nextValue = [...value, ''];
    onChange(nextValue);
  };

  return (
    <div className='flex flex-col gap-y-1'>
      <FieldLabel>
        <span>{label}</span>
        <Button disabled={disabled} variant='cta' size='small' onClick={addInput}>
          <PlusCircleIcon className='h-6 ml-1' />
        </Button>
      </FieldLabel>
      <div className={twMerge('flex flex-col gap-y-2 w-full', className)}>
        <ArrayInput name={name} value={value} onChange={onChange} />
      </div>
      {!hideErrorMessage && error && <FieldError>{error}</FieldError>}
    </div>
  );
};

export default ArrayTextField;
