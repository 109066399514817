import { FC, useCallback } from 'react';
import useUpdateDeliveryRate from 'src/api/hooks/mutations/deliveryRates/useUpdateDeliveryRate';
import { usePopupContext } from 'src/components/Popup/hook';
import { CreateDeliveryRateRequest } from 'src/api';
import { SubmitHandler } from 'react-hook-form';
import DeliveryRateForm from '../../DeliveryRatesPage/DeliveryRateForm';
import useDeliveryRate from 'src/api/hooks/queries/useDeliveryRate';

export type UpdateDeliveryRateFromProps = {
  className?: string;
  deliveryRateId: number;
};

const UpdateDeliveryRateFrom: FC<UpdateDeliveryRateFromProps> = ({ deliveryRateId, className }) => {
  const updateDeliveryRate = useUpdateDeliveryRate();
  const { data: tariff } = useDeliveryRate(deliveryRateId);
  const { onClose } = usePopupContext();

  const onSubmit: SubmitHandler<CreateDeliveryRateRequest> = useCallback(
    (data) => {
      updateDeliveryRate.mutate({ id: deliveryRateId, ...data });
      onClose();
    },
    [updateDeliveryRate, onClose],
  );

  return <DeliveryRateForm defaultValues={tariff} onSubmit={onSubmit} className={className} />;
};

UpdateDeliveryRateFrom.displayName = 'UpdateDeliveryRateFrom';

export default UpdateDeliveryRateFrom;
