import { PlusCircleIcon, TrashIcon } from '@heroicons/react/24/outline';
import { FC } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import useAllTechnicians from 'src/api/hooks/queries/useAllTechnicians';
import useAllTechniciansAvailability from 'src/api/hooks/queries/useAllTechniciansAvailability';
import Button from 'src/components/Button';
import { SelectControlled } from 'src/components/fields/Select';
import { OrderSchema } from '../schema';

export type ServiceTechniciansFormProps = {
  //
};

const ServiceTechniciansForm: FC<ServiceTechniciansFormProps> = () => {
  const { control, watch } = useFormContext<OrderSchema>();
  const { data: technicians } = useAllTechnicians();

  const start = watch('datetime');
  const end = watch('endDatetime');

  const { data: availability } = useAllTechniciansAvailability(
    start ? new Date(start) : new Date(),
    end ? new Date(end) : new Date(),
    {
      enabled: !!start && !!end,
    },
  );

  const { fields: technicianIdsFields, append, remove } = useFieldArray({ name: 'technicianIds', control });

  return (
    <div className='flex flex-col mb-16 gap-y-4'>
      <div className='flex mb-2'>
        <div className='font-bold flex text-gray-800 text-2xl'>
          <FormattedMessage id='app.order.section_title.technicians' />
          {technicianIdsFields.length < (technicians ?? []).length && (
            <Button
              variant='cta'
              size='small'
              className='ml-2'
              onClick={() => append({ technicianId: undefined } as unknown as OrderSchema['technicianIds'][0])}
            >
              <PlusCircleIcon className='h-6 mr-1' />
              <FormattedMessage id='app.buttons.add' />
            </Button>
          )}
        </div>
      </div>

      {technicianIdsFields.map((field, index) => (
        <div className='w-full flex items-end' key={`t${field.technicianId}-i${index}`}>
          <SelectControlled
            label={<FormattedMessage id='app.order.technician_n' values={{ value: index + 1 }} />}
            control={control}
            name={`technicianIds.${index}.technicianId`}
            options={technicians ?? []}
            shouldHideOption={(option) => technicianIdsFields.map((i) => i.technicianId).includes(option.id)}
            getOptionLabelClassname={(option) =>
              availability?.[option.id] ? 'bg-success-light hover:bg-success' : 'bg-error-light hover:bg-error'
            }
            getOptionLabel={(option) => option?.profile?.name ?? option.id.toString()}
            getOptionValue={(option) => option.id}
          />
          <Button className='h-10' size='small' variant='cta' onClick={() => remove(index)}>
            <TrashIcon className='h-6' />
          </Button>
        </div>
      ))}
    </div>
  );
};

export default ServiceTechniciansForm;
