import { forwardRef } from 'react';
import { OrderStatusResponse } from 'src/api';
import { GridEvent } from '../../types';
import { getEventBackgroundClassNames, getEventBorderClassNames } from '../Event/helpers';
import { twMerge } from 'tailwind-merge';

export type EventContentProps = GridEvent & {
  className?: string;
  onClick?: (e: React.MouseEvent) => void;
};

const EventContent = forwardRef<HTMLDivElement, EventContentProps>(({ className, onClick, data }, ref) => {
  const { orderNumber, status, companyName } = data;

  return (
    <div
      ref={ref}
      onClick={onClick}
      className={twMerge(
        'cursor-pointer group absolute inset-1 top-0.5 flex flex-col rounded-lg border-l-[6px] p-2 text-xs overflow-hidden leading-5',
        status?.name && getEventBackgroundClassNames(status.name as OrderStatusResponse.name),
        status?.name && getEventBorderClassNames(status.name as OrderStatusResponse.name),
        className,
      )}
    >
      <p className='font-semibold'>{orderNumber}</p>
      <p className='overflow-hidden text-ellipsis whitespace-nowrap'>{companyName}</p>
    </div>
  );
});

export default EventContent;
