import { compareAsc, formatISO } from 'date-fns';
import { useCallback, useMemo } from 'react';
import { UserResponse } from 'src/api';
import useCurrentTenant from 'src/api/hooks/queries/useCurrentTenant';
import useCurrentUser from 'src/api/hooks/queries/useCurrentUser';
import { HAS_CLOSED_DEMO_BANNER, HAS_CLOSED_DEMO_BANNER_UNTIL } from 'src/constants/demo';
import { useLocalStorage } from 'usehooks-ts';

const useDemoBannerStatus = (): { shouldShowDemoBanner: boolean; closeDemoBanner: (until?: Date) => void } => {
  const { data: currecntUser } = useCurrentUser();
  const { data: currentTenant } = useCurrentTenant();
  const [hasClosedDemoBanner, setHasClosedDemoBanner] = useLocalStorage(HAS_CLOSED_DEMO_BANNER, false);
  const [hasClosedDemoBannerUntil, setHasClosedDemoBannerUntil] = useLocalStorage<string | null>(
    HAS_CLOSED_DEMO_BANNER_UNTIL,
    null,
  );
  const hasClosedDemoBannerUntilDate = useMemo(
    () => (hasClosedDemoBannerUntil ? new Date(hasClosedDemoBannerUntil) : null),
    [hasClosedDemoBannerUntil],
  );

  const isDemo = currentTenant?.isDemo;
  const shouldUserSeeBanner = [
    UserResponse.role.ADMINISTRATOR,
    UserResponse.role.ADMINISTRATOR_TECHNICIAN,
    UserResponse.role.SYSTEM_ADMINISTRATOR,
  ].includes(currecntUser?.role as UserResponse.role);
  const shouldSeeBanner = isDemo && shouldUserSeeBanner;

  const shouldShowDemoBanner = useMemo(() => {
    if (!shouldSeeBanner) return false;
    if (!hasClosedDemoBanner) return true;
    if (hasClosedDemoBanner && !hasClosedDemoBannerUntilDate) return false;
    if (hasClosedDemoBannerUntilDate && compareAsc(new Date(), hasClosedDemoBannerUntilDate) > 0) return true;

    return false;
  }, [hasClosedDemoBanner, hasClosedDemoBannerUntilDate, shouldSeeBanner]);

  const closeDemoBanner = useCallback((until?: Date) => {
    setHasClosedDemoBanner(true);
    const untilDate = until ? formatISO(until) : null;
    setHasClosedDemoBannerUntil(untilDate);
  }, []);

  return { shouldShowDemoBanner, closeDemoBanner };
};

export default useDemoBannerStatus;
