import { FC } from 'react';
import clsx from 'clsx';
import ParametersRow from '../common/ParametersRow';
import { FormattedMessage } from 'react-intl';
import { OrderResponse } from 'src/api';
import { Fragment } from 'react';

export type ServiceSummaryPreviewProps = {
  className?: string;
  data: OrderResponse;
};

const ServiceSummaryPreview: FC<ServiceSummaryPreviewProps> = ({ className, data }) => {
  return (
    <div className={clsx(className, 'flex flex-col')}>
      <ParametersRow.Title>
        <FormattedMessage id='app.order.summary' />
      </ParametersRow.Title>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.order.company_name' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.company?.companyName}</ParametersRow.Value>
      </ParametersRow>
      {data.orderProducts?.map((orderProduct, index) => (
        <Fragment key={orderProduct.id}>
          <ParametersRow>
            <ParametersRow.Label>
              <FormattedMessage id='app.order.product_name_n' values={{ n: index + 1 }} />
            </ParametersRow.Label>
            <ParametersRow.Value>{orderProduct.product?.productName}</ParametersRow.Value>
          </ParametersRow>
          <ParametersRow>
            <ParametersRow.Label>
              <FormattedMessage id='app.order.product_description' />
            </ParametersRow.Label>
            <ParametersRow.Value>{orderProduct.productDescription}</ParametersRow.Value>
          </ParametersRow>
        </Fragment>
      ))}
    </div>
  );
};

ServiceSummaryPreview.displayName = 'ServiceSummaryPreview';

export default ServiceSummaryPreview;
