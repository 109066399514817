import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { DeliveryTariffResponse } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

const useDeliveryTariff = (id: number): UseQueryResult<DeliveryTariffResponse> => {
  const client = useApiClient();
  const { queryKey } = queryKeysFactory.deliveryTariffs.detail(id);

  return useQuery(
    queryKey,
    async () => {
      const data = await client.deliveryTariffs.getDeliveryTariff({ id });
      return data;
    },
    {
      refetchInterval: false,
      staleTime: 1000 * 60 * 5,
    },
  );
};

export default useDeliveryTariff;
