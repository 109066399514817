import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { WorkTariffResponse } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

const useWorkTariff = (id: number): UseQueryResult<WorkTariffResponse> => {
  const client = useApiClient();
  const { queryKey } = queryKeysFactory.workTariffs.detail(id);

  return useQuery(
    queryKey,
    async () => {
      const data = await client.workTariffs.getWorkTariff({ id });
      return data;
    },
    {
      refetchInterval: false,
      staleTime: 1000 * 60 * 5,
    },
  );
};

export default useWorkTariff;
