import { useQuery } from '@tanstack/react-query';
import { ForwardedRef, useState, useId, useMemo, forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Controller, FieldPath, FieldValues, UseControllerProps } from 'react-hook-form';
import { Autocomplete, AutocompleteProps } from '../Autocomplete';
import { uniqBy } from 'ramda';
import useLastDefined from 'src/hooks/useLastDefined';
import { useDebounceValue } from 'usehooks-ts';
import queryKeysFactory from 'src/store/queryKeysFactory';

export type AutocompleteAsyncProps<TOption, TValue extends string | number | null> = Omit<
  AutocompleteProps<TOption, TValue>,
  'options' | 'query' | 'onQueryChange' | 'externalFilter'
> & {
  debouceDelay?: number;
  defaultOptions?: TOption[];
  getOptions: (query: string) => Promise<TOption[]>;
  initialData?: TOption[];
  preloadDefaultValueOption?: (value: TValue) => Promise<TOption | null>;
};

export const AutocompleteAsync = forwardRef(
  <TOption, TValue extends string | number | null>(
    {
      getOptions,
      initialData,
      defaultOptions,
      preloadDefaultValueOption,
      debouceDelay = 200,
      ...rest
    }: AutocompleteAsyncProps<TOption, TValue>,
    ref?: ForwardedRef<HTMLInputElement>,
  ): JSX.Element => {
    const [query, setQuery] = useState('');
    const [debouncedQuery] = useDebounceValue<string>(query, debouceDelay);

    async function getAllOptions(queryValue: string): Promise<TOption[]> {
      if (rest.value && !debouncedQuery && preloadDefaultValueOption) {
        const [valueOption, options] = await Promise.all([preloadDefaultValueOption(rest.value), getOptions('')]);

        const allOptions = valueOption ? [valueOption, ...options] : options;
        const uniqOptions = uniqBy((i) => rest.getOptionValue(i), allOptions);
        return uniqOptions;
      }
      return getOptions(queryValue);
    }

    const generatedId = useId(); // probably should use some uniq id form props to prevent data leaks on remount
    const id = rest.id ?? generatedId;
    const { queryKey } = queryKeysFactory.autocomplete.detail(id, debouncedQuery);
    const {
      data: options,
      isFetching,
      isPlaceholderData,
    } = useQuery({
      enabled: !!debouncedQuery || !!rest.value,
      initialData,
      queryFn: () => getAllOptions(debouncedQuery) ?? [],
      queryKey,
      refetchOnMount: false,
    });
    const lastDefinedOptions = useLastDefined(options);

    const notFoundMessage = useMemo(() => {
      if (isPlaceholderData || isFetching) return <FormattedMessage id='app.common.loading' />;
      return <FormattedMessage id='app.autocomplete.nothing_found' />;
    }, [isFetching, isPlaceholderData]);

    const usedOptions =
      lastDefinedOptions && (lastDefinedOptions?.length ?? 0) > 0
        ? lastDefinedOptions
        : defaultOptions ?? ([] as TOption[]);

    return (
      <Autocomplete
        {...rest}
        ref={ref}
        externalFilter
        notFoundMessage={notFoundMessage}
        query={query}
        onQueryChange={setQuery}
        options={usedOptions}
        hideButton
      />
    );
  },
) as <T, TValue extends string | number | null>(
  props: AutocompleteAsyncProps<T, TValue> & {
    ref?: ForwardedRef<HTMLInputElement>;
  },
) => JSX.Element;

export type AutocompleteControlledProps<
  TOption,
  TValue extends string | number | null,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<AutocompleteAsyncProps<TOption, TValue>, 'value' | 'onChange' | 'name' | 'error'> & {
  control: UseControllerProps<TFieldValues, TName>['control'];
  name: TName;
};

export function AutocompleteAsyncControlled<
  TOption,
  TValue extends string | number | null,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  control,
  ...autocompleteProps
}: AutocompleteControlledProps<TOption, TValue, TFieldValues, TName>): JSX.Element {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <AutocompleteAsync {...autocompleteProps} {...field} name={name} error={error?.message} />
      )}
    />
  );
}
