import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { BranchResponse, CreateBranchRequest } from 'src/api';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';
import { WithContactId } from 'src/types/mutations';

const useAddContactBranch = (): UseMutationResult<BranchResponse, unknown, WithContactId<CreateBranchRequest>> => {
  const client = useApiClient();
  const queryClient = useQueryClient();

  return useMutation<BranchResponse, unknown, WithContactId<CreateBranchRequest>>(
    async (branch: WithContactId<CreateBranchRequest>) => {
      const data = await client.branch.createBranch({ contactId: branch.contactId, requestBody: branch });
      // const { data } = await oldApiclient.post(`/contact/${branch.contactId}/branch`, branch);
      return data as BranchResponse;
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(queryKeysFactory.branches.listForContact(variables.contactId).queryKey);

        toast(<FormattedMessage id='app.toasts.add_success' defaultMessage='Záznam úspěšně přidán' />, {
          type: 'success',
        });
      },
      onError: () => {
        toast(<FormattedMessage id='app.toasts.add_fail' defaultMessage='Chyba přidáni záznamu' />, {
          type: 'error',
        });
      },
    },
  );
};

export default useAddContactBranch;
