import { FC } from 'react';
import clsx from 'clsx';
import useAddTranslationPack from 'src/api/hooks/mutations/translations/useAddTranslationPack';
import { FormProvider, useForm } from 'react-hook-form';
import { usePopupContext } from 'src/components/Popup/hook';
import { TextFieldControlled } from 'src/components/fields/Textfield';
import NewItemPopupFooter from 'src/components/common/NewItemPopupFooter';
import { FormattedMessage } from 'react-intl';
import Tooltip from 'src/components/common/Tooltip';
import { InformationCircleIcon } from '@heroicons/react/24/outline';

export type AddLanguageFormProps = {
  className?: string;
};

const AddLanguageForm: FC<AddLanguageFormProps> = ({ className }) => {
  const addTranslationPack = useAddTranslationPack();
  const form = useForm<{ language: string }>();
  const { control, handleSubmit } = form;
  const { onClose } = usePopupContext();

  const onSubmit = (data: { language: string }): void => {
    addTranslationPack.mutate(data);
    onClose?.();
  };

  return (
    <FormProvider {...form}>
      <form className={clsx(className, 'flex flex-col w-full')} onSubmit={handleSubmit(onSubmit)}>
        <TextFieldControlled
          control={control}
          name='language'
          label={
            <div className='flex items-center'>
              <FormattedMessage id='app.common.language_code' />
              <Tooltip
                text={
                  <span className='inline-block max-w-sm w-max'>
                    <FormattedMessage id='app.language_code.tooltip' />
                  </span>
                }
              >
                <InformationCircleIcon className='w-6' />
              </Tooltip>
            </div>
          }
        />
        <NewItemPopupFooter />
      </form>
    </FormProvider>
  );
};

AddLanguageForm.displayName = 'AddLanguageForm';

export default AddLanguageForm;
