import { always, complement, isEmpty, map, mergeWith, pickBy, sort } from 'ramda';

export type TranslationItem = {
  id: string;
  value: string;
  isNew: boolean;
  isMatch: boolean;
  isDeleted: boolean;
};

export const sortTranslationItems = sort<TranslationItem>((a, b) => {
  if (a.isNew && !b.isNew) return -1;
  if (!a.isNew && b.isNew) return 1;
  return a.id.localeCompare(b.id);
});

export const match = (str: string, search: string): boolean => str.toLowerCase().includes(search.toLowerCase());

export const toRhfSafeId = (id: string): string => id.replace(/\./g, '/');
export const fromRhfSafeId = (id: string): string => id.replace(/\//g, '.');

const setObjectValues = <T>(obj: Record<string, unknown>, value: T): Record<string, T> => map(always(value), obj);

export const mergeToLeft = (left: Record<string, string>, right: Record<string, string>): Record<string, string> => {
  const clearObject = setObjectValues(left, '');
  const merged = mergeWith((l, r) => r, clearObject, right);

  return merged;
};

export const omitEmptyValues = pickBy(complement(isEmpty));
