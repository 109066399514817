import hashString from './hashString';

function intToRGB(i: number): string {
  const c = (i & 0x00ffffff).toString(16).toUpperCase();

  return '00000'.substring(0, 6 - c.length) + c;
}

export default function stringToHexColor(str: string): string {
  return '#' + intToRGB(hashString(str));
}
