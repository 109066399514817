import { FC, useCallback } from 'react';
import DeliveryTariffForm from '../WorkTariffForm';
import { CreateWorkTariffRequest } from 'src/api';
import { usePopupContext } from 'src/components/Popup/hook';
import { SubmitHandler } from 'react-hook-form';
import useAddWorkTariff from 'src/api/hooks/mutations/workTariffs/useAddWorkTariff';

export type AddWorkTariffFormProps = {
  className?: string;
};

const AddWorkTariffForm: FC<AddWorkTariffFormProps> = ({ className }) => {
  const addWorkTariff = useAddWorkTariff();
  const { onClose } = usePopupContext();

  const onSubmit: SubmitHandler<CreateWorkTariffRequest> = useCallback(
    (data) => {
      addWorkTariff.mutate(data);
      onClose();
    },
    [addWorkTariff, onClose],
  );

  return <DeliveryTariffForm onSubmit={onSubmit} className={className} />;
};

AddWorkTariffForm.displayName = 'AddWorkTariffForm';

export default AddWorkTariffForm;
