import clsx from 'clsx';
import { ChangeEvent, FC, useCallback, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import Button from '../Button';
import useUploadFileMutation from 'src/api/hooks/mutations/files/useUploadFileMutation';

export type ImagesUploadFieldProps = {
  name?: string;
  addImagesOnChange?: boolean;
  onUpload: (fileId: number) => void;
  className?: string;
  buttonClassName?: string;
  disabled?: boolean;
};

const ImagesUploadField: FC<ImagesUploadFieldProps> = ({ disabled, buttonClassName, className, name, onUpload }) => {
  const uploadMutation = useUploadFileMutation({ noToast: true });
  const hiddenFileInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = useCallback(() => hiddenFileInputRef?.current?.click(), []);

  const handleFilesChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (!e.target.files || !e.target.files[0]) return;

    const file = e.target.files[0];

    const maxSize = 1024 * 1024 * 10;
    if (file.size > maxSize) {
      toast(<FormattedMessage id='app.toasts.file_size' values={{ value: '4mb' }} />, { type: 'error' });
      return;
    }

    uploadMutation.mutate(
      {
        file,
      },
      {
        onSuccess: (res) => {
          const fileId = res.data.id;
          onUpload(fileId);
        },
      },
    );
  };

  return (
    <div className={clsx(className, 'flex flex-col gap-y-4')}>
      <Button type='button' disabled={disabled} className={buttonClassName} onClick={handleButtonClick}>
        <FormattedMessage id='app.butons.add_photo' />
        <input
          ref={hiddenFileInputRef}
          name={name}
          accept='.png, .jpg, .jpeg'
          type='file'
          className='hidden'
          onChange={handleFilesChange}
          disabled={disabled}
        />
      </Button>
    </div>
  );
};

ImagesUploadField.displayName = 'ImagesUploadField';

export default ImagesUploadField;
