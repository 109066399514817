import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { OrderMaterialResponse, OrderTypeRepsonse } from 'src/api';
import OrderMaterialsTable from '../OrderMaterialsTable';

export type OrderMaterialsPreviewProps = {
  materials: OrderMaterialResponse[];
  orderType?: OrderTypeRepsonse;
};

const OrderMaterialsPreview: FC<OrderMaterialsPreviewProps> = ({ materials, orderType }) => {
  return (
    <section className='flex flex-col gap-y-4 mt-10'>
      <h3 className='text-xl font-semibold'>
        <FormattedMessage id='app.order.order_product.materials' />
      </h3>
      <OrderMaterialsTable materials={materials} orderType={orderType} />
    </section>
  );
};

export default OrderMaterialsPreview;
