import { z } from 'zod';

export const ProductBaseSchema = z.object({
  productName: z.string().min(1),
  productSerialNumber: z.string().nullable().default(null),
  productSerialNumber2: z.string().nullable().default(null),
  technology: z.string().nullable().default(null),
  productProducer: z.string().nullable().default(null),
  productNote: z.string().nullable().default(null),
  inUseFrom: z.string().nullable().default(null),
  warranty: z.string().nullable().default(null),
});

export type ProductBaseSchema = z.infer<typeof ProductBaseSchema>;

export const ProductSchemaB2B = ProductBaseSchema.extend({
  branchId: z.number(),
});
export type ProductSchemaB2B = z.infer<typeof ProductSchemaB2B>;

export const ProductSchemaB2C = ProductBaseSchema.extend({
  branchId: z.number().nullable(),
});
export type ProductSchemaB2C = z.infer<typeof ProductSchemaB2C>;
