import { FC, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { OrderResponse, PaymentMethodResponse, TenantConfigDto } from 'src/api';
import Select from 'src/components/fields/Select';
import { CURRENCY_OPTIONS } from 'src/constants/currency';
import useFinishOrderPrice from 'src/pages/TechnicianOrderFinishPage/hooks/useFinishOrderPrice';
import { MessageId } from 'src/types/commonTypes';
import FieldWrapper from '../../FieldWrapper';
import Section from '../../Section';
import SectionTitle from '../../SectionTitle';
import { FinishOrderFormValues } from '../schema';

export type PaymentFormProps = {
  paymentMethods: PaymentMethodResponse[];
  order: OrderResponse;
  tenantConfig: TenantConfigDto | undefined;
};

const PaymentForm: FC<PaymentFormProps> = ({ paymentMethods, tenantConfig, order }) => {
  const intl = useIntl();
  const { control, setValue, watch } = useFormContext<FinishOrderFormValues>();
  const { finalPriceWithVat, finalPriceWithoutVat } = useFinishOrderPrice(order);
  const preferred_currency = watch('preferredCurrency');
  const paymentMethodId = watch('servicePaymentMethodId');

  // TODO: move to be
  useEffect(() => {
    setValue('serviceTotalPrice', finalPriceWithoutVat);
  }, [finalPriceWithVat]);

  return (
    <>
      <SectionTitle className='mb-4'>
        <FormattedMessage id='app.finish_order.section_title.payment' />
      </SectionTitle>

      <Section noMb className='mb-4'>
        <FieldWrapper label={<FormattedMessage id='app.finish_order.preferred_currency' />}>
          <Controller
            control={control}
            name='preferredCurrency'
            render={({ field, fieldState }) => (
              <Select
                {...field}
                options={CURRENCY_OPTIONS}
                getOptionLabel={(option) => option}
                getOptionValue={(option) => option}
                error={fieldState.error?.message}
              />
            )}
          />
        </FieldWrapper>
        <FieldWrapper label={<FormattedMessage id='app.finish_order.payment_method' />}>
          <Controller
            control={control}
            name={'servicePaymentMethodId'}
            render={({ field }) => (
              <Select
                {...field}
                getOptionLabel={(option) =>
                  intl.formatMessage({
                    id: `app.payment_method.${option.name.toLocaleLowerCase()}` as MessageId,
                  })
                }
                getOptionValue={(option) => option.id}
                options={paymentMethods ?? ([] as PaymentMethodResponse[])}
              />
            )}
          />
        </FieldWrapper>
      </Section>

      {!order.orderType?.slConfig?.hidePricesFor?.includes(paymentMethodId) && (
        <Section>
          <div className='flex flex-col max-w-xs w-full'>
            <div className='w-full flex justify-between'>
              <span>
                <FormattedMessage id='app.finish_order.service_total_price_without_vat' />
              </span>
              <span className='font-semibold'>
                <FormattedNumber
                  style='currency'
                  trailingZeroDisplay='stripIfInteger'
                  value={finalPriceWithoutVat}
                  currency={preferred_currency}
                />
              </span>
            </div>
            {!tenantConfig?.disableVat && (
              <div className='w-full flex justify-between'>
                <span>
                  <FormattedMessage id='app.finish_order.service_total_price' />
                </span>
                <span className='font-semibold'>
                  <FormattedNumber
                    style='currency'
                    trailingZeroDisplay='stripIfInteger'
                    value={finalPriceWithVat}
                    currency={preferred_currency}
                  />
                </span>
              </div>
            )}
          </div>
        </Section>
      )}
    </>
  );
};

export default PaymentForm;
