import clsx from 'clsx';
import React from 'react';

type LayoutProps = {
  children: React.ReactNode;
};

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  return <div className={clsx('flex flex-col w-full bg-base-100 p-4 md:p-8')}>{children}</div>;
};
