import { FC, HTMLAttributes } from 'react';
import AutoPopup from 'src/components/common/AutoPopup';
import { FormattedMessage } from 'react-intl';
import AddEmployeeForm from '../AddEmployeeForm';
import { useParams } from 'react-router-dom';

export type AddEmployeePopupProps = {
  //
} & HTMLAttributes<HTMLDivElement>;

const AddEmployeePopup: FC<AddEmployeePopupProps> = ({ className }) => {
  const contactId = Number(useParams().contactId);

  return (
    <AutoPopup
      id='add_employee'
      title={<FormattedMessage id='app.contact_page.add_employee.popup_title' defaultMessage='Editace zarizeni' />}
      className={className}
    >
      <AddEmployeeForm contactId={contactId} />
    </AutoPopup>
  );
};

AddEmployeePopup.displayName = 'AddEmployeePopup';

export default AddEmployeePopup;
