import { FC, useCallback } from 'react';
import { usePopupContext } from 'src/components/Popup/hook';
import { CreateDeliveryTariffRequest } from 'src/api';
import { SubmitHandler } from 'react-hook-form';
import useUpdateWorkTariff from 'src/api/hooks/mutations/workTariffs/useUpdateWorkTariff';
import WorkTariffForm from 'src/pages/WorkTariffsPage/WorkTariffForm';
import useWorkTariff from 'src/api/hooks/queries/useWorkTariff';

export type UpdateWorkTariffFromProps = {
  className?: string;
  workTariffId: number;
};

const UpdateWorkTariffFrom: FC<UpdateWorkTariffFromProps> = ({ workTariffId, className }) => {
  const updateWorkTariff = useUpdateWorkTariff();
  const { data: tariff } = useWorkTariff(workTariffId);
  const { onClose } = usePopupContext();

  const onSubmit: SubmitHandler<CreateDeliveryTariffRequest> = useCallback(
    (data) => {
      updateWorkTariff.mutate({ id: workTariffId, ...data });
      onClose();
    },
    [updateWorkTariff, onClose],
  );

  return <WorkTariffForm defaultValues={tariff} onSubmit={onSubmit} className={className} />;
};

UpdateWorkTariffFrom.displayName = 'UpdateWorkTariffFrom';

export default UpdateWorkTariffFrom;
