import { FC } from 'react';
import AddHeadquatersForm from '../AddHeadquatersForm';
import AutoPopup from 'src/components/common/AutoPopup';
import { FormattedMessage } from 'react-intl';

export type AddHeadquatersPopupProps = {
  className?: string;
};

export const ADD_HEADQUATERS_POPUP_ID = 'add_headquaters_popup';

const AddHeadquatersPopup: FC<AddHeadquatersPopupProps> = ({ className }) => {
  return (
    <AutoPopup
      id={ADD_HEADQUATERS_POPUP_ID}
      title={<FormattedMessage id='app.add_headquaters.popup_title' />}
      className={className}
    >
      <AddHeadquatersForm />
    </AutoPopup>
  );
};

AddHeadquatersPopup.displayName = 'AddHeadquatersPopup';

export default AddHeadquatersPopup;
