import clsx from 'clsx';
import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

export type HyperLinkProps = Omit<LinkProps, 'to'> & {
  href: string;
  clasName?: string;
  forceExternal?: boolean;
  noStyles?: boolean;
};

const getClassnames = (): string => 'text-primary hover:underline';

const HyperLink: React.FC<HyperLinkProps> = ({ className, href, forceExternal, noStyles, ...rest }) => {
  if (forceExternal || href.startsWith('http'))
    return (
      <a
        {...rest}
        href={href.startsWith('http') ? href : `https://${href}`}
        className={clsx(className, !noStyles && getClassnames())}
      />
    );
  return <Link {...rest} to={href} className={clsx(className, !noStyles && getClassnames())} />;
};

export default HyperLink;
