import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

const useDeleteVatTariff = (): UseMutationResult<void, unknown, number> => {
  const queryClient = useQueryClient();
  const client = useApiClient();

  return useMutation<void, unknown, number>(
    async (vatTariffId: number) => {
      const data = await client.vatTariffs.deleteVatTariff({ id: vatTariffId });
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeysFactory.vatTariffs._def);
        toast(<FormattedMessage id='app.toasts.delete_success' />, {
          type: 'success',
        });
      },
      onError: () => {
        toast(<FormattedMessage id='app.toasts.delete_fail' />, {
          type: 'error',
        });
      },
    },
  );
};

export default useDeleteVatTariff;
