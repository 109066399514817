/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RSResponse } from './RSResponse';
export type TextFieldSchemaResponse = {
  id: string;
  name: string;
  type: TextFieldSchemaResponse.type | null;
  label: RSResponse;
  emptyLabelInPDF?: boolean;
  underline?: boolean;
  ignoreFalseInPDF?: string;
  showIfName?: string | null;
  showIfValues?: Array<Record<string, any>> | null;
};
export namespace TextFieldSchemaResponse {
  export enum type {
    INPUT = 'input',
    NUMBER = 'number',
    SELECT = 'select',
    CHECKBOX = 'checkbox',
    TEXTAREA = 'textarea',
    DATE = 'date',
  }
}
