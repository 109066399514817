import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { TranslationPackResponse } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

export type UseAddTranslationPackPayload = {
  language: string;
};

const useAddTranslationPack = (): UseMutationResult<TranslationPackResponse, unknown, UseAddTranslationPackPayload> => {
  const client = useApiClient();
  const queryClient = useQueryClient();

  return useMutation<TranslationPackResponse, unknown, UseAddTranslationPackPayload>(
    async (data: UseAddTranslationPackPayload) => {
      const res = await client.translationPacks.createTranslationPack({ requestBody: data });
      return res;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeysFactory.translationPacks.list.queryKey);
        queryClient.invalidateQueries(queryKeysFactory.translationPacks.languagesList.queryKey);

        toast(<FormattedMessage id='app.toasts.add_success' />, {
          type: 'success',
        });
      },
      onError: () => {
        toast(<FormattedMessage id='app.toasts.add_fail' />, {
          type: 'error',
        });
      },
    },
  );
};

export default useAddTranslationPack;
