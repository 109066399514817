import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

const useSupportedLanguages = (): UseQueryResult<string[]> => {
  const client = useApiClient();

  const { queryKey } = queryKeysFactory.translationPacks.languagesList;

  return useQuery(queryKey, async () => {
    const data = await client.translationPacks.getLanguages();
    return data;
  });
};

export default useSupportedLanguages;
