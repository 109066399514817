import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { TranslationPackListItemResponse } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

const useTranslationPacks = (): UseQueryResult<TranslationPackListItemResponse[]> => {
  const client = useApiClient();

  const { queryKey } = queryKeysFactory.translationPacks.list;

  return useQuery(queryKey, async () => {
    const data = await client.translationPacks.getTranslationPacks();
    return data;
  });
};

export default useTranslationPacks;
