import { FC } from 'react';
import clsx from 'clsx';
import { DeliveryRateResponse } from 'src/api';
import { FormattedMessage } from 'react-intl';
import ParametersRow from 'src/components/common/ParametersRow';

export type DeliveryRatePreviewProps = {
  className?: string;
  data: DeliveryRateResponse;
};

const DeliveryRatePreview: FC<DeliveryRatePreviewProps> = ({ data, className }) => {
  return (
    <div className={clsx('flex flex-col w-full', className)}>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.delivery_tariff.name' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.name}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.delivery_tariff.price' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.price}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.common.price_eur' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.priceEur}</ParametersRow.Value>
      </ParametersRow>
    </div>
  );
};

DeliveryRatePreview.displayName = 'DeliveryRatePreview';

export default DeliveryRatePreview;
