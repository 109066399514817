import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline';
import { mdiDrag } from '@mdi/js';
import Icon from '@mdi/react';
import { FC, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { FormattedMessage } from 'react-intl';
import { SelectFieldSchemaResponse } from 'src/api';
import Button from 'src/components/Button';
import Popup from 'src/components/Popup';
import { useFormBuilderFieldState } from '../../context';
import FormBuilderFieldForm from '../FormBuilderFieldForm';

export type FormBuilderFieldProps = {
  id: string;
  index: number;
};

const FormBuilderField: FC<FormBuilderFieldProps> = ({ id, index }) => {
  const { field, deleteField, updateField } = useFormBuilderFieldState(id);
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);

  return (
    <>
      <Draggable draggableId={id} index={index}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            className='flex items-center justify-between border border-gray-300 bg-slate-50 mt-2 rounded-md'
          >
            <div className='flex items-center'>
              <div {...provided.dragHandleProps}>
                <Icon path={mdiDrag} size={2} className={'text-primary cursor-move'} />
              </div>
              <span>{field.label?.defaultMessage ?? field.name ?? field.id}</span>
            </div>

            <div className='flex items-center gap-1'>
              <Button size='small' variant='cta' onClick={() => setIsEditPopupOpen(true)}>
                <PencilSquareIcon className='w-6 h-6' />
              </Button>
              <Button size='small' variant='cta' onClick={deleteField}>
                <TrashIcon className='w-6 h-6' />
              </Button>
            </div>
          </div>
        )}
      </Draggable>
      <Popup
        open={isEditPopupOpen}
        onClose={() => setIsEditPopupOpen(false)}
        title={<FormattedMessage id='app.order_type_wizzard.edit_field' />}
      >
        <FormBuilderFieldForm
          onSubmit={(data) => {
            updateField(data);
            setIsEditPopupOpen(false);
          }}
          defaultValues={{
            name: field.name,
            label: field.label.defaultMessage,
            type: field.type as SelectFieldSchemaResponse.type,
            showIfName: field.showIfName,
            showIfValues: field.showIfValues,
            options:
              (field as SelectFieldSchemaResponse).options
                ?.filter(Boolean)
                .map((i) => (i as string | number)?.toString()) ?? null,
          }}
        />
      </Popup>
    </>
  );
};

FormBuilderField.displayName = 'FormBuilderField';

export default FormBuilderField;
