import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Navigate } from 'react-router-dom';
import { TenantConfigDto } from 'src/api';
import useAllHeadquarters from 'src/api/hooks/queries/useAllHeadquarters';
import useAllOrderTypes from 'src/api/hooks/queries/useAllOrderTypes';
import useAllVatTariffs from 'src/api/hooks/queries/useAllVatTariffs';
import { CheckboxControlled } from 'src/components/fields/Checkbox';
import { CheckboxGroupRhfc } from 'src/components/fields/CheckboxGroup';
import { SelectControlled } from 'src/components/fields/Select';
import FormDataRow from 'src/components/FormDataRow';
import ToggleB2Cb2B from 'src/components/ToggleB2Cb2B';
import Loader from 'src/components/utils/Loader';
import { EMAIL_TARGET_OPTIONS } from 'src/constants/emailTargets';

const FIELD_OPTIONS = [
  {
    label: () => <FormattedMessage id='app.order.use_manual_order_number' />,
    name: 'useManualOrderNumber',
    value: 'useManualOrderNumber',
  },
  {
    label: () => <FormattedMessage id='app.order.headquarters' />,
    name: 'headquartersId',
    value: 'headquartersId',
  },
  {
    label: () => <FormattedMessage id='app.order.vat_tariff' />,
    name: 'vatTariffId',
    value: 'vatTariffId',
  },
  {
    label: () => <FormattedMessage id='app.order.order_type' />,
    name: 'orderTypeId',
    value: 'orderTypeId',
  },
  {
    label: () => <FormattedMessage id='app.order.email_target' />,
    name: 'emailTarget',
    value: 'emailTarget',
  },
  {
    label: () => <FormattedMessage id='app.order.invoice_number' />,
    name: 'invoiceNumber',
    value: 'invoiceNumber',
  },
  {
    label: () => <FormattedMessage id='app.order.should_send_notifications' />,
    name: 'shouldSendNotifications',
    value: 'shouldSendNotifications',
  },
];

const TenantConfigurationOrderForm: FC = () => {
  const intl = useIntl();
  const { control, setValue } = useFormContext<TenantConfigDto>();
  const { data: headquarters, isLoading: isLoadingHeadquarters } = useAllHeadquarters();
  const { data: orderTypes, isLoading: isLoadingOrderTypes } = useAllOrderTypes();
  const { data: vatTariffs, isLoading: isLoadingVatTariffs } = useAllVatTariffs();

  const isLoading = isLoadingVatTariffs || isLoadingHeadquarters || isLoadingOrderTypes;

  if (isLoading) return <Loader />;
  if (!vatTariffs || !headquarters || !orderTypes) return <Navigate to='/500' />;

  return (
    <>
      <span className='text-2xl mb-4'>
        <FormattedMessage id='app.tenants_page.configuration.order_form' />
      </span>

      <div className='flex w-full gap-2 items-center'>
        <details className='flex w-full gap-3'>
          <summary className='flex w-full gap-3'>
            <InformationCircleIcon className='w-6 h-6 text-primary' />
            <FormattedMessage id='app.tenants_page.configuration.order_form.use_product_templates' />
            <CheckboxControlled control={control} name='orderForm.useProductTemplates' />
          </summary>
          <span className='mt-2'>
            <FormattedMessage id='app.tenants_page.configuration.order_form.use_product_templates.hint' />
          </span>
        </details>
      </div>
      <div className='flex w-full gap-3 items-center'>
        <details className='flex w-full gap-3'>
          <summary className='flex w-full gap-3'>
            <InformationCircleIcon className='w-6 h-6 text-primary' />
            <FormattedMessage id='app.tenants_page.configuration.order_form.show_contact_info' />
            <CheckboxControlled control={control} name='orderForm.showContactInfo' />
          </summary>
          <span className='mt-2'>
            <FormattedMessage id='app.tenants_page.configuration.order_form.show_contact_info.hint' />
          </span>
        </details>
      </div>

      <div className='grid grid-cols-1 lg:grid-cols-2 gap-x-20 gap-y-8 border-b broder-gray-300 w-auto mb-10'>
        <div className='flex flex-col'>
          <span className='text-xl mb-4 mt-8'>
            <FormattedMessage id='app.tenants_page.configuration.order_form.defaultValues' />
          </span>

          <SelectControlled
            className='col-1'
            asterisk
            label={<FormattedMessage id='app.order.headquarters' />}
            control={control}
            name='orderForm.defaultValues.headquartersId'
            options={headquarters ?? []}
            extraOnChange={(v) => {
              const hq = headquarters?.find((i) => i.id === v);
              if (!hq) return;

              setValue('orderForm.defaultValues.vatTariffId', hq.defaultVatTariffId);
            }}
            getOptionValue={(i) => i.id}
            getOptionLabel={(i) => `${i.companyName} ${i.companyStreet} ${i.companyCity}`}
          />

          <SelectControlled
            asterisk
            label={<FormattedMessage id='app.order.order_type' />}
            control={control}
            name='orderForm.defaultValues.orderTypeId'
            options={orderTypes ?? []}
            getOptionValue={(i) => i.id}
            getOptionLabel={(i) => i.name}
          />

          <FormDataRow asterisk label={<FormattedMessage id='app.customer.category' />}>
            <Controller
              name='orderForm.defaultValues.category'
              render={({ field }) => (
                <ToggleB2Cb2B
                  value={field.value}
                  onChange={(v) => {
                    field.onChange(v);
                  }}
                />
              )}
            />
          </FormDataRow>

          <SelectControlled
            asterisk
            label={<FormattedMessage id='app.order.vat_tariff' />}
            control={control}
            name='orderForm.defaultValues.vatTariffId'
            options={vatTariffs ?? []}
            getOptionValue={(i) => i.id}
            getOptionLabel={(i) => `${i.tariffValue}%`}
          />

          <SelectControlled
            asterisk
            label={<FormattedMessage id='app.order.email_target' />}
            control={control}
            name='orderForm.defaultValues.emailTarget'
            options={EMAIL_TARGET_OPTIONS}
            getOptionValue={(i) => i.value}
            getOptionLabel={(i) => intl.formatMessage(i.labelRs)}
          />
        </div>

        <div className='flex flex-col'>
          <span className='text-xl mb-4 mt-8'>
            <FormattedMessage id='app.tenants_page.configuration.order_form.hidden_fields' />
          </span>

          <CheckboxGroupRhfc
            className='mb-12'
            name={'orderForm.hiddenFields'}
            control={control}
            options={FIELD_OPTIONS}
          />
        </div>
      </div>
    </>
  );
};

export default TenantConfigurationOrderForm;
