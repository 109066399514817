import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { TranslationPackResponse } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

export type UseUpdateTranslationPackPayload = {
  language: string;
  json: Record<string, string>;
};

const useUpdateTranslationPack = (): UseMutationResult<
  TranslationPackResponse,
  unknown,
  UseUpdateTranslationPackPayload
> => {
  const client = useApiClient();
  const queryClient = useQueryClient();

  return useMutation<TranslationPackResponse, unknown, UseUpdateTranslationPackPayload>(
    async ({ language, json }) => {
      const data = await client.translationPacks.updateTranslationPack({ language, requestBody: json });
      return data;
    },
    {
      onSuccess: (_, { language }) => {
        queryClient.invalidateQueries(queryKeysFactory.translationPacks.detail(language).queryKey);
        queryClient.invalidateQueries(queryKeysFactory.translationPacks.defaultDetail(language).queryKey);

        toast(<FormattedMessage id='app.toasts.add_success' />, {
          type: 'success',
        });
      },
      onError: () => {
        toast(<FormattedMessage id='app.toasts.add_fail' />, {
          type: 'error',
        });
      },
    },
  );
};

export default useUpdateTranslationPack;
