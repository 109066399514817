import clsx from 'clsx';
import { startOfWeek, endOfWeek, eachDayOfInterval, format } from 'date-fns';
import { FC } from 'react';
import useUserFnsLocale from 'src/api/hooks/queries/useUserFnsLocale';
import { twMerge } from 'tailwind-merge';

export type MonthTabHeaderProps = {
  className?: string;
};

const MonthTabHeader: FC<MonthTabHeaderProps> = ({ className }) => {
  const { data: locale } = useUserFnsLocale();
  const start = startOfWeek(new Date(), { locale, weekStartsOn: 1 });
  const end = endOfWeek(new Date(), { locale, weekStartsOn: 1 });
  const daysInWeek = eachDayOfInterval({ start, end });

  return (
    <div className={twMerge('w-full flex border-b-2 border-neutral-300', className)}>
      {daysInWeek.map((day, index) => (
        <span
          key={index}
          className={clsx(
            'flex-1 h-11 flex items-center justify-center text-lg',
            index < daysInWeek.length - 1 && 'border-r border-neutral-200',
          )}
        >
          {format(day, 'EEE', { locale }).slice(0, 2)}
        </span>
      ))}
    </div>
  );
};

export default MonthTabHeader;
