import { z } from 'zod';

export const ChangePasswordSchema = z
  .object({
    userPsw: z.string().min(8),
    userNewPsw: z.string().min(8),
    userNewPswConfirm: z.string().min(8),
  })
  .refine((data) => data.userNewPsw === data.userNewPswConfirm, {
    path: ['userNewPswConfirm'],
  });

export type ChangePasswordSchema = z.infer<typeof ChangePasswordSchema>;
