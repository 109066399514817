import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { CreateUserRequest } from 'src/api/.gen';
import { UserResponse } from 'src/api';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';
import { ApiError } from 'src/api/.gen/core/ApiError';

const useAddUser = (): UseMutationResult<UserResponse, ApiError, CreateUserRequest> => {
  const client = useApiClient();
  const queryClient = useQueryClient();

  return useMutation<UserResponse, ApiError, CreateUserRequest>(
    async (user: CreateUserRequest) => {
      const data = await client.user.create({ requestBody: user });
      return data as UserResponse;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeysFactory.users.list.queryKey);
        queryClient.invalidateQueries(queryKeysFactory.tenant.current.queryKey);
        toast(<FormattedMessage id='app.toasts.add_success' defaultMessage='Záznam úspěšně přidán' />, {
          type: 'success',
        });
      },
      onError: (err: ApiError) => {
        const message = err.body?.errors?.[0]?.code ?? (
          <FormattedMessage id='app.toasts.add_fail' defaultMessage='Chyba přidáni záznamu' />
        );
        toast(message, {
          type: 'error',
        });
      },
    },
  );
};

export default useAddUser;
