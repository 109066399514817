import { FC } from 'react';
import Table, { TableProps } from 'src/components/Table';
import { DeliveryRateResponse } from 'src/api';
import { useNavigate } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';
import { ExtendedColumnDef } from 'src/types/table';
import TextFilter from 'src/components/Table/filters/TextFilter';
import { FormattedMessage } from 'react-intl';

export type DeliveryRatesTableProps = Omit<TableProps<DeliveryRateResponse>, 'columns'>;

const columnHelper = createColumnHelper<DeliveryRateResponse>();

const columns: ExtendedColumnDef<DeliveryRateResponse>[] = [
  {
    ...columnHelper.accessor('name', {
      id: 'name',
      header: () => <FormattedMessage id='app.delivery_tariff.name' />,
      enableColumnFilter: true,
      enableSorting: true,
      cell: (info) => info.renderValue(),
      size: 250,
    }),
    renderFilter: (column) => <TextFilter column={column} />,
  },
  {
    ...columnHelper.accessor('price', {
      id: 'price',
      header: () => <FormattedMessage id='app.delivery_tariff.price' />,
      enableColumnFilter: false,
      enableSorting: true,
      cell: (info) => info.renderValue(),
      size: 200,
    }),
  },
  {
    ...columnHelper.accessor('priceEur', {
      id: 'priceEur',
      header: () => <FormattedMessage id='app.common.price_eur' />,
      enableColumnFilter: false,
      enableSorting: true,
      cell: (info) => info.renderValue(),
      size: 200,
    }),
  },
];

const DeliveryRatesTable: FC<DeliveryRatesTableProps> = (props) => {
  const navigate = useNavigate();

  return (
    <Table
      columns={columns}
      {...props}
      handleRowClick={(original) => navigate(`/admin/delivery-rates/${original.id}`)}
    />
  );
};

DeliveryRatesTable.displayName = 'DeliveryRatesTable';

export default DeliveryRatesTable;
