import { FC, HTMLAttributes } from 'react';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { MessageId } from 'src/types/commonTypes';

export type PaymentMethodLabelProps = {
  name: string;
} & HTMLAttributes<HTMLDivElement>;

const PaymentMethodLabel: FC<PaymentMethodLabelProps> = ({ className, name }) => {
  return (
    <span className={clsx(className)}>
      <FormattedMessage id={`app.payment_method.${name.toLocaleLowerCase()}` as MessageId} />
    </span>
  );
};

PaymentMethodLabel.displayName = 'PaymentMethodLabel';

export default PaymentMethodLabel;
