/* eslint-disable @typescript-eslint/no-unused-vars */
import { Disclosure, Transition } from '@headlessui/react';
import { ArrowRightCircleIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import React, { FC, HTMLProps, PropsWithChildren, ReactNode } from 'react';
import { standartTransitionProps } from 'src/constants/transitions';

export type TParametersRow = React.FC<
  PropsWithChildren<HTMLProps<HTMLDivElement> & { noBorder?: boolean; alwaysRow?: boolean }>
>;
export type TLabel = React.FC<PropsWithChildren<HTMLProps<HTMLSpanElement>>>;
export type TValue = React.FC<PropsWithChildren<HTMLProps<HTMLSpanElement>>>;
export type TTitle = React.FC<PropsWithChildren<HTMLProps<HTMLHeadingElement>>>;
export type TDescription = React.FC<PropsWithChildren<HTMLProps<HTMLSpanElement>>>;

const Label: TLabel = ({ className, ...rest }) => {
  return (
    <span
      className={clsx(
        className,
        'flex items-center text-[1.075rem] font-semibold md:font-normal font-openSans sm:text-xl',
      )}
      {...rest}
    />
  );
};

const Value: TValue = ({ className, ...rest }) => {
  return (
    <span
      className={clsx(
        className,
        'flex items-center md:justify-end text-[1.075rem] text-right font-openSans sm:text-xl whitespace-pre-line',
      )}
      {...rest}
    />
  );
};

const Title: TTitle = ({ className, ...rest }) => {
  return <h2 className={clsx(className, 'flex text-2xl font-openSans font-semibold mb-6')} {...rest} />;
};

const Description: TDescription = ({ className, ...rest }) => {
  return <span className={clsx(className, 'flex text-sm text-gray-600 font-openSans mb-5')} {...rest} />;
};

type ParametersRowWithItems = {
  Value: TValue;
  Label: TLabel;
  Title: TTitle;
  Description: TDescription;
} & TParametersRow;

const ParametersRow: TParametersRow = ({ className, noBorder, alwaysRow, ...rest }) => {
  return (
    <div
      className={clsx(
        'flex md:items-center gap-y-2 justify-between w-full py-3',
        className,
        !alwaysRow && 'md:flex-row flex-col gap-y-1',
        !noBorder && 'border-b border-gray-300',
      )}
      {...rest}
    />
  );
};

export type TParametersRowWithPreview = FC<{
  noBorder?: boolean;
  alwaysRow?: boolean;
  className?: string;
  label: ReactNode;
  value: ReactNode;
  renderPreview: () => ReactNode;
}>;

export const ParametersRowWithPreview: TParametersRowWithPreview = ({ label, value, renderPreview, ...rest }) => {
  return (
    <Disclosure>
      {({ open }) => (
        <ParametersRow {...rest}>
          <div className='flex flex-col w-full'>
            <div className='w-full flex justify-between'>
              <Label className='font-bold'>{label}</Label>
              <Value className='font-bold'>
                <span>{value}</span>
                <Disclosure.Button>
                  <ArrowRightCircleIcon
                    className={clsx(
                      'w-7 text-primary ml-2 transition-all duration-300',
                      open ? 'rotate-90' : 'rotate-0',
                    )}
                  />
                </Disclosure.Button>
              </Value>
            </div>
            <Transition show={open} {...standartTransitionProps}>
              <Disclosure.Panel>{renderPreview()}</Disclosure.Panel>
            </Transition>
          </div>
        </ParametersRow>
      )}
    </Disclosure>
  );
};

(ParametersRow as ParametersRowWithItems).Label = Label;
(ParametersRow as ParametersRowWithItems).Value = Value;
(ParametersRow as ParametersRowWithItems).Title = Title;
(ParametersRow as ParametersRowWithItems).Description = Description;

export default ParametersRow as ParametersRowWithItems;
