import { FC, HTMLAttributes, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { OrderStatusResponse, UnsuccessfulOrderRequest } from 'src/api';
import useDoomTechnicianOrder from 'src/api/hooks/mutations/technicianOrder/useDoomTechnicianOrder';
import Button from 'src/components/Button';
import AutoPopup from 'src/components/common/AutoPopup';
import NewItemPopupFooter from 'src/components/common/NewItemPopupFooter';
import DescriptionField from 'src/components/fields/DescriptionField';

export type UnsuccessfulOrderPopupProps = {
  //
} & HTMLAttributes<HTMLDivElement>;

const formDefaultValues = {
  descriptionNote: '',
  status: OrderStatusResponse.name.UNSUCCESSFUL,
};

const UnsuccessfulOrderPopup: FC<UnsuccessfulOrderPopupProps> = ({ className }) => {
  const [isSent, setIsSent] = useState(false);
  const doomTechnicianOrder = useDoomTechnicianOrder();

  const form = useForm({
    defaultValues: formDefaultValues,
  });
  const { handleSubmit, control } = form;

  const onSubmit: SubmitHandler<UnsuccessfulOrderRequest> = (data: UnsuccessfulOrderRequest) => {
    doomTechnicianOrder.mutate(data);
    setIsSent(true);
  };

  return (
    <AutoPopup
      id='unseccessful_order'
      title={
        isSent ? (
          <FormattedMessage id='app.unseccessful_order.finished' />
        ) : (
          <FormattedMessage id='app.technician_order_page.unseccessful_order.popup_title' />
        )
      }
      centerTitle={isSent}
      className={className}
      notClosable={isSent}
      hideCloseIcon={isSent}
    >
      {isSent ? (
        <Button
          className='w-full'
          onClick={() => {
            window.opener = null;
            window.open('', '_self');
            window.close();
          }}
        >
          <FormattedMessage id='app.finish_order.close_page' />
        </Button>
      ) : (
        <form className='flex flex-col' onSubmit={handleSubmit(onSubmit)}>
          <span className='mb-6'>
            <FormattedMessage id='app.unseccessful_order.description' />
          </span>
          <Controller
            control={control}
            name='descriptionNote'
            render={({ field }) => <DescriptionField {...field} />}
          />
          <NewItemPopupFooter />
        </form>
      )}
    </AutoPopup>
  );
};

UnsuccessfulOrderPopup.displayName = 'UnsuccessfulOrderPopup';

export default UnsuccessfulOrderPopup;
