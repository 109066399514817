import { FC } from 'react';
import clsx from 'clsx';
import ParametersRow from 'src/components/common/ParametersRow';
import { ProductTemplateResponse } from 'src/api';
import { FormattedMessage } from 'react-intl';

export type ProductTemplatePreviewProps = {
  className?: string;
  data: ProductTemplateResponse;
};

const ProductTemplatePreview: FC<ProductTemplatePreviewProps> = ({ className, data }) => {
  return (
    <div className={clsx(className, 'flex flex-col w-full')}>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.product.name' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.productName}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.product.producer' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.productProducer}</ParametersRow.Value>
      </ParametersRow>
    </div>
  );
};

ProductTemplatePreview.displayName = 'ProductTemplatePreview';

export default ProductTemplatePreview;
