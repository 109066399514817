import { useCallback, useState } from 'react';
import { useOldApiClient } from 'src/api';

type UseDownloadFileReturn = {
  state: DownloadState;
  downloadFile: () => Promise<Blob | null>;
};
export type DownloadState = 'not_started' | 'downloading' | 'downloaded' | 'error';

const useDownloadFile = (fileId: number): UseDownloadFileReturn => {
  const oldApiClient = useOldApiClient();
  const [state, setState] = useState<DownloadState>('not_started');

  const downloadFile = useCallback(async (): Promise<Blob | null> => {
    try {
      setState('downloading');
      const res = await oldApiClient.get(`/file/${fileId}`, { responseType: 'blob' });
      const blob = new Blob([res.data], { type: res.headers['content-type'] });
      setState('downloaded');

      return blob;
    } catch (err) {
      setState('error');
      return null;
    }
  }, [fileId]);

  return {
    state,
    downloadFile,
  };
};

export default useDownloadFile;
