import { FC, HTMLAttributes } from 'react';
import clsx from 'clsx';
import useContactEmployee from 'src/api/hooks/queries/useContactEmployee';
import Loader from 'src/components/utils/Loader';
import { Navigate } from 'react-router-dom';
import HyperLink from 'src/components/common/HyperLink';
import { FormattedMessage } from 'react-intl';
import { ArrowLongRightIcon } from '@heroicons/react/24/outline';
import EmployeePreview from '../EmployeePreview';

export type EmployeeListItemDetailProps = {
  contactId: number;
  employeeId: number;
} & HTMLAttributes<HTMLDivElement>;

const EmployeeListItemDetail: FC<EmployeeListItemDetailProps> = ({ className, contactId, employeeId }) => {
  const { data, isFetching } = useContactEmployee(contactId, employeeId);

  if (isFetching) return <Loader />;
  if (!data) return <Navigate to='/404' />;

  return (
    <div className={clsx('flex flex-col', className)}>
      <EmployeePreview noTitle data={data} />
      <HyperLink className='flex self-end mt-4 font-semibold' href={`/contact/${contactId}/employee/${employeeId}`}>
        <FormattedMessage id='app.button.detail' />
        <ArrowLongRightIcon className='block ml-2 h-6' aria-hidden='true' />
      </HyperLink>
    </div>
  );
};

EmployeeListItemDetail.displayName = 'EmployeeListItemDetail';

export default EmployeeListItemDetail;
