import { ReactNode, FC } from 'react';
import { twMerge } from 'tailwind-merge';

export type PageTitleProps = {
  children?: ReactNode;
  className?: string;
};

const PageTitle: FC<PageTitleProps> = ({ children, className }) => {
  return <h1 className={twMerge('font-bold text-3xl', className)}>{children}</h1>;
};

export default PageTitle;
