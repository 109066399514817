import { createColumnHelper } from '@tanstack/react-table';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { UserListResponse, UserResponse } from 'src/api';
import Table, { TableProps } from 'src/components/Table';
import TextFilter from 'src/components/Table/filters/TextFilter';
import { ExtendedColumnDef } from 'src/types/table';
import RolesFilter from './filters/RoleFIlter';

export type UsersTableProps = Omit<TableProps<UserListResponse>, 'columns'>;

const columnHelper = createColumnHelper<UserListResponse>();

const columns: ExtendedColumnDef<UserListResponse>[] = [
  {
    ...columnHelper.accessor('userEmail', {
      id: 'user_email',
      header: () => <FormattedMessage id='app.common.email' />,
      enableColumnFilter: true,
      enableSorting: true,
      cell: (info) => info.renderValue(),
      size: 350,
    }),
    renderFilter: (column) => <TextFilter column={column} />,
  },
  {
    ...columnHelper.accessor('role', {
      id: 'role',
      header: () => <FormattedMessage id='app.user.role' />,
      enableColumnFilter: true,
      enableSorting: true,
      cell: (info) => <FormattedMessage id={`app.role.${info.getValue() as UserResponse.role}`} />,
      size: 160,
    }),
    renderFilter: (column) => <RolesFilter column={column} />,
  },
  {
    ...columnHelper.accessor('deletedAt', {
      id: 'deleted_at',
      header: () => <FormattedMessage id='app.contact.active_user' />,
      enableColumnFilter: false,
      enableSorting: true,
      cell: (info) =>
        info.getValue() ? <FormattedMessage id='app.common.no' /> : <FormattedMessage id='app.common.yes' />,
      size: 220,
    }),
  },
];

const UsersTable: React.FC<UsersTableProps> = (props) => {
  const navigate = useNavigate();
  return <Table columns={columns} handleRowClick={(original) => navigate(`/admin/user/${original.id}`)} {...props} />;
};

export default UsersTable;
