import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { BranchResponse } from 'src/api';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

export type UseDeleteContactBranchData = {
  branchId: number;
  contactId?: number;
};

const useDeleteContactBranch = (): UseMutationResult<BranchResponse, unknown, UseDeleteContactBranchData> => {
  const client = useApiClient();
  const queryClient = useQueryClient();

  return useMutation<BranchResponse, unknown, UseDeleteContactBranchData>(
    async ({ branchId }) => {
      const data = await client.branch.deleteBranch({ id: branchId });
      return data;
    },
    {
      onSuccess: (_, variables) => {
        if (variables.contactId) {
          queryClient.invalidateQueries(queryKeysFactory.branches.listForContact(variables.contactId).queryKey);
        } else {
          queryClient.invalidateQueries(queryKeysFactory.branches.listForContact._def);
        }
        queryClient.invalidateQueries(queryKeysFactory.branches.detail(variables.branchId).queryKey);

        toast(<FormattedMessage id='app.toasts.delete_success' />, {
          type: 'success',
        });
      },
      onError: () => {
        toast(<FormattedMessage id='app.toasts.delete_fail' />, {
          type: 'error',
        });
      },
    },
  );
};

export default useDeleteContactBranch;
