import { OrderListItemResponse } from 'src/api';
import { GridEvent } from 'src/components/EventsGrid/types';
import removeSecAndMs from 'src/utils/removeSecAndMs';

const orderListItemToGridEvents = (order: OrderListItemResponse): GridEvent[] => {
  if (!order.technicians.length) {
    return [
      {
        id: order.id,
        start: removeSecAndMs(new Date(order.datetime ?? 0)),
        end: removeSecAndMs(new Date(order.endDatetime ?? 0)),
        data: order,
        technicianId: null,
      },
    ];
  }

  return order.technicians.map((technician) => ({
    id: order.id,
    start: removeSecAndMs(new Date(order.datetime ?? 0)),
    end: removeSecAndMs(new Date(order.endDatetime ?? 0)),
    data: order,
    technicianId: technician.id,
  }));
};

export default orderListItemToGridEvents;
