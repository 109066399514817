import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { BranchResponse } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

type QueryKey = ReturnType<typeof queryKeysFactory.branches.detail>['queryKey'];
type QueryOptions = Omit<
  UseQueryOptions<BranchResponse, unknown, BranchResponse, QueryKey>,
  'queryKey' | 'queryFn' | 'initialData'
>;

const useContactBranch = (branchId: number, options?: QueryOptions): UseQueryResult<BranchResponse> => {
  const client = useApiClient();
  const { queryKey } = queryKeysFactory.branches.detail(branchId);

  return useQuery(
    queryKey,
    async () => {
      const data = await client.branch.getBranch({ id: Number(branchId) });
      return data;
    },
    {
      refetchInterval: false,
      staleTime: 1000 * 60 * 5,
      ...options,
    },
  );
};

export default useContactBranch;
