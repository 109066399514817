import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { CreateBranchRequest } from 'src/api/.gen';
import { BranchResponse } from 'src/api';
import { useApiClient } from 'src/api/useApiClient';
import { WithContactId, WithId } from 'src/types/mutations';
import queryKeysFactory from 'src/store/queryKeysFactory';

const useUpdateContactBranch = (): UseMutationResult<
  BranchResponse,
  unknown,
  WithContactId<WithId<CreateBranchRequest>>
> => {
  const client = useApiClient();
  const queryClient = useQueryClient();

  return useMutation<BranchResponse, unknown, WithContactId<WithId<CreateBranchRequest>>>(
    async (branch: WithContactId<WithId<CreateBranchRequest>>) => {
      const data = await client.branch.updateBranch({
        id: branch.id,
        requestBody: branch,
      });
      return data;
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(queryKeysFactory.branches.detail(variables.id).queryKey);
        queryClient.invalidateQueries(queryKeysFactory.branches.listForContact(variables.contactId).queryKey);
        toast(<FormattedMessage id='app.toasts.update_success' defaultMessage='Záznam úspěšně zmenen' />, {
          type: 'success',
        });
      },
      onError: () => {
        toast(<FormattedMessage id='app.toasts.update_fail' defaultMessage='Chyba zmeny záznamu' />, {
          type: 'error',
        });
      },
    },
  );
};

export default useUpdateContactBranch;
