import { format, addDays, startOfWeek } from 'date-fns';
import { FC, useMemo } from 'react';
import useUserFnsLocale from 'src/api/hooks/queries/useUserFnsLocale';
import { twMerge } from 'tailwind-merge';

export type WeekDaysHeaderProps = {
  className?: string;
};

const WeekDaysHeader: FC<WeekDaysHeaderProps> = ({ className }) => {
  const { data: locale } = useUserFnsLocale();
  const weekDays = useMemo(
    () =>
      Array.from({ length: 7 })
        .map((_, index) => format(addDays(startOfWeek(new Date(), { weekStartsOn: 1 }), index), 'EE', { locale }))
        .map((day) => day.charAt(0).toUpperCase()),
    [locale],
  );

  return (
    <div className={twMerge('grid grid-cols-7 mb-2', className)}>
      {weekDays.map((day, index) => (
        <span key={index} className='text-center text-xs font-light'>
          {day}
        </span>
      ))}
    </div>
  );
};

export default WeekDaysHeader;
