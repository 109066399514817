import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

const useAllTechniciansAvailability = (
  start: Date,
  end: Date,
  options?: UseQueryOptions<Record<string, boolean>>,
): UseQueryResult<Record<string, boolean>> => {
  const client = useApiClient();
  const { queryKey } = queryKeysFactory.users.techniciansAvailability(start.toISOString(), end.toISOString());

  return useQuery({
    queryKey,
    queryFn: async () => {
      const data = await client.user.getAllTechniciansAvailability({
        start: start.toISOString(),
        end: end.toISOString(),
      });
      return data;
    },
    retry: false,
    ...options,
  });
};

export default useAllTechniciansAvailability;
