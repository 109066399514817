import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { CreateDeliveryTariffRequest, DeliveryTariffResponse } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

const useAddDeliveryTariff = (): UseMutationResult<DeliveryTariffResponse, unknown, CreateDeliveryTariffRequest> => {
  const queryClient = useQueryClient();
  const client = useApiClient();

  return useMutation<DeliveryTariffResponse, unknown, CreateDeliveryTariffRequest>(
    async (tariff: CreateDeliveryTariffRequest) => {
      const data = await client.deliveryTariffs.createDeliveryTariff({ requestBody: tariff });
      return data as DeliveryTariffResponse;
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(queryKeysFactory.deliveryTariffs.list.queryKey);
        queryClient.invalidateQueries(queryKeysFactory.deliveryTariffs.detail(data.id).queryKey);
        toast(<FormattedMessage id='app.toasts.add_success' />, {
          type: 'success',
        });
      },
      onError: () => {
        toast(<FormattedMessage id='app.toasts.add_fail' />, {
          type: 'error',
        });
      },
    },
  );
};

export default useAddDeliveryTariff;
