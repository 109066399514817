import { FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

export type OrderDayCardsDividerProps = {
  label: ReactNode;
  className?: string;
};

const OrderDayCardsDivider: FC<OrderDayCardsDividerProps> = ({ label, className }) => {
  return (
    <div className={twMerge('flex items-center gap-2 my-4', className)}>
      <div className='border-t border-t-gray-200 w-4'></div>
      <span>{label}</span>
      <hr className='border-t border-t-gray-200 flex-1'></hr>
    </div>
  );
};

export default OrderDayCardsDivider;
