/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MoveOrderRequest } from '../models/MoveOrderRequest';
import type { OrderListItemResponse } from '../models/OrderListItemResponse';
import type { ResizeOrderRequest } from '../models/ResizeOrderRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class CalendarService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns OrderListItemResponse
   * @throws ApiError
   */
  public getTechnicianMonthOrders({
    technicianId,
    year,
    monthIndex,
  }: {
    technicianId: number;
    year: number;
    monthIndex: number;
  }): CancelablePromise<Array<OrderListItemResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/calendar/technicianMonthOrders/{technicianId}/{year}/{monthIndex}',
      path: {
        technicianId: technicianId,
        year: year,
        monthIndex: monthIndex,
      },
    });
  }
  /**
   * @returns OrderListItemResponse
   * @throws ApiError
   */
  public getTechniciansDayOrders({ date }: { date: string }): CancelablePromise<Array<OrderListItemResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/calendar/techniciansDayOrders/{date}',
      path: {
        date: date,
      },
    });
  }
  /**
   * @returns OrderListItemResponse
   * @throws ApiError
   */
  public getTechniciansWeekOrders({
    year,
    weekIndex,
  }: {
    year: number;
    weekIndex: number;
  }): CancelablePromise<Array<OrderListItemResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/calendar/techniciansWeekOrders/{year}/{weekIndex}',
      path: {
        year: year,
        weekIndex: weekIndex,
      },
    });
  }
  /**
   * @returns void
   * @throws ApiError
   */
  public moveOrder({ id, requestBody }: { id: number; requestBody: MoveOrderRequest }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/calendar/{id}/move',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns void
   * @throws ApiError
   */
  public resizeOrder({ id, requestBody }: { id: number; requestBody: ResizeOrderRequest }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/calendar/{id}/resize',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns void
   * @throws ApiError
   */
  public moveOrderToUnassigned({
    id,
    requestBody,
  }: {
    id: number;
    requestBody: MoveOrderRequest;
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/calendar/{id}/move-to-unassigned',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
