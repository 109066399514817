import clsx from 'clsx';
import { FC } from 'react';
import stub from 'src/utils/stub';

export type PaginationItemProps = {
  pageNumber: number;
  onClick: () => void;
  selected: boolean;
};

const PaginationItem: FC<PaginationItemProps> = ({ onClick, pageNumber, selected }) => {
  return (
    <div
      onClick={selected ? stub : onClick}
      className={clsx(
        'flex items-center h-full px-2 box-border sm:px-1',
        selected ? 'border-t-2 border-primary mt-[-1px] cursor-not-allowed' : 'cursor-pointer',
      )}
    >
      <span>{pageNumber}</span>
    </div>
  );
};

export default PaginationItem;
