import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { ProductResponse } from 'src/api';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

export type UseDeleteContactProductData = {
  productId: number;
};

const useDeleteContactProduct = (): UseMutationResult<ProductResponse, unknown, UseDeleteContactProductData> => {
  const client = useApiClient();
  const queryClient = useQueryClient();

  return useMutation<ProductResponse, unknown, UseDeleteContactProductData>(
    async ({ productId }) => {
      const data = await client.product.deleteProduct({ id: productId });
      return data;
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(queryKeysFactory.products.list._def);
        queryClient.invalidateQueries(queryKeysFactory.products.detail(variables.productId).queryKey);

        toast(<FormattedMessage id='app.toasts.delete_success' />, {
          type: 'success',
        });
      },
      onError: () => {
        toast(<FormattedMessage id='app.toasts.delete_success' />, {
          type: 'error',
        });
      },
    },
  );
};

export default useDeleteContactProduct;
