import { FC, useCallback, useMemo } from 'react';
import { SubmitHandler } from 'react-hook-form';
import {
  HeadquartersResponse,
  NotificationResponse,
  OrderTypeListItemRepsonse,
  TenantConfigDto,
  VatTariffResponse,
} from 'src/api';
import useAddOrder from 'src/api/hooks/mutations/order/useAddOrder';
import { usePopupContext } from 'src/components/Popup/hook';
import ServiceForm from '../ServiceForm';
import { OrderSchema } from '../ServiceForm/schema';
import { GridEvent } from 'src/components/EventsGrid/types';

export type AddServiceFormProps = {
  defaultValues?: Partial<OrderSchema>;
  vatTariffs: VatTariffResponse[];
  headquarters: HeadquartersResponse[];
  orderTypes: OrderTypeListItemRepsonse[];
  config?: TenantConfigDto;
  notificationSettings?: NotificationResponse[];
  className?: string;
  onDateChange?: (date: Date) => void;
  onPotentialEventsChange?: (events: GridEvent[]) => void;
};

const AddServiceForm: FC<AddServiceFormProps> = ({
  className,
  config,
  orderTypes,
  defaultValues,
  vatTariffs,
  headquarters,
  notificationSettings,
  onDateChange,
  onPotentialEventsChange,
}) => {
  const notificationEnabled =
    notificationSettings?.find((i) => i.key === NotificationResponse.key.CUSTOMER_BEFORE_SERVICE)?.defaultEnabled ??
    false;
  const addOrder = useAddOrder();
  const formDefaultValues = useMemo(
    () => ({
      shouldSendNotifications: notificationEnabled,
      orderTypeId: orderTypes?.[0]?.id,
      headquartersId: headquarters?.[0]?.id,
      vatTariffId: headquarters?.[0]?.defaultVatTariffId,
      ...config?.orderForm?.defaultValues,
      ...defaultValues,
    }),
    [vatTariffs, headquarters, orderTypes],
  );

  const { onClose } = usePopupContext();

  const onSubmit: SubmitHandler<OrderSchema> = useCallback((data) => {
    addOrder.mutate(data);
    onClose();
  }, []);

  return (
    <ServiceForm
      defaultValues={formDefaultValues}
      orderTypes={orderTypes}
      vatTariffs={vatTariffs}
      config={config}
      headquarters={headquarters}
      onSubmit={onSubmit}
      onDateChange={onDateChange}
      className={className}
      onPotentialEventsChange={onPotentialEventsChange}
    />
  );
};

AddServiceForm.displayName = 'AddServiceForm';

export default AddServiceForm;
