import clsx from 'clsx';
import React, { FC, RefObject, useRef, useState } from 'react';
import { useDrop } from 'react-dnd';
import { OrderResponse } from 'src/api';
import useOrder from 'src/api/hooks/queries/useOrder';
import WithLoader from 'src/components/utils/WithLoader';
import useLastDefined from 'src/hooks/useLastDefined';
import { ITEM_TYPES } from '../../constants';
import { EventGridContextProvider } from '../../context/EventGridContextProvider';
import { getDimensionsFromNode } from '../../helpers/grid';
import useEventsGridContext from '../../hooks/useEventsGridContext';
import useScrollToHour from '../../hooks/useScrollToHour';
import {
  EventDragObject,
  EventToGridRawFunc,
  GetGridCellDimensionsRawFunc,
  GridEvent,
  GridToTimeSpanRawFunc,
  HandleDropFunc,
} from '../../types';
import EventInfoPopup from '../EventInfoPopup';
import EventsDragLayer from '../EventsDragLayer';

export type EventsGridContainerProps = EventsGridProps & {
  eventToGrid: EventToGridRawFunc;
  gridToTimeSpan: GridToTimeSpanRawFunc;
  getGridCellDimensions: GetGridCellDimensionsRawFunc;
  cols: number;
  rows: number;
  startHour: number;
  endHour: number;
  disabledColumns?: number[];
  minEnabledMinute?: number;
  maxEnabledMinute?: number;
  scrollContainerRef: RefObject<HTMLElement>;
};

export type EventElementProps = {
  onClick?: (e: React.MouseEvent) => void;
  className?: string;
};

export type EventsGridProps = {
  className?: string;
  gridClassName?: string;
  olClassName?: string;
  disableEventPreviewOnClick?: boolean;

  events: GridEvent[];
  onMoveToDisabledColumn?: (eventId: number, targetColumn: number) => void;
  onDrop: HandleDropFunc;
  onEventClick?: (event: GridEvent) => void;
  onGridClick?: (col: number, row: number) => void;

  scrollTopOffest?: number;
  autoScrollToHour?: number;

  cellWidth?: number;
  cellHeight?: number;

  renderEvent: (event: GridEvent, props: EventElementProps) => JSX.Element;

  renderHoursBar?: () => JSX.Element;
  renderVerticalLines?: () => JSX.Element;
  renderHorizontalLines?: () => JSX.Element;
};

const EventsGridContainer: FC<EventsGridContainerProps> = ({
  events,
  eventToGrid,
  gridToTimeSpan,
  getGridCellDimensions,
  cols,
  rows,
  startHour,
  endHour,
  disabledColumns,
  minEnabledMinute,
  maxEnabledMinute,
  scrollContainerRef,
  ...rest
}) => {
  const gridRef = useRef<HTMLDivElement>(null);

  return (
    <EventGridContextProvider
      gridRef={gridRef}
      scrollContainerRef={scrollContainerRef}
      eventToGrid={eventToGrid}
      gridToTimeSpan={gridToTimeSpan}
      getGridCellDimensions={getGridCellDimensions}
      cols={cols}
      rows={rows}
      startHour={startHour}
      endHour={endHour}
      disabledColumns={disabledColumns}
      minEnabledMinute={minEnabledMinute}
      maxEnabledMinute={maxEnabledMinute}
    >
      <EventsGrid events={events} {...rest} />
    </EventGridContextProvider>
  );
};

EventsGridContainer.displayName = 'EventsGridContainer';

const EventsGrid: FC<EventsGridProps> = ({
  className,
  gridClassName,
  olClassName,
  disableEventPreviewOnClick,
  onDrop,
  onGridClick,
  events,
  onMoveToDisabledColumn,
  autoScrollToHour,
  scrollTopOffest,
  renderEvent,
  renderHoursBar,
  renderHorizontalLines,
  renderVerticalLines,
  cellWidth,
  cellHeight,
}) => {
  const context = useEventsGridContext();
  const { gridSettings, gridRef, scrollContainerRef, getGridCellDimensions } = context;
  const { ROWS, COLS, DISABLED_COLLUMNS } = gridSettings;

  const [, dropRef] = useDrop<EventDragObject>({
    accept: ITEM_TYPES.EVENT,
    canDrop: () => true,
    drop: (item, monitor) => {
      if (!gridRef.current) return;

      const { columnWidth, rowHeight } = getDimensionsFromNode(gridRef.current, gridSettings);
      const { x: rootX, y: rootY } = gridRef.current.getBoundingClientRect();
      const { x: sourceX, y: sourceY } = monitor.getSourceClientOffset() ?? { x: 0, y: 0 };
      const x = sourceX - rootX;
      const y = sourceY - rootY;
      const row = Math.round(y / rowHeight) + 1;
      const col = Math.round(x / columnWidth) + 1;
      const { rowSpan, colSpan } = item; // old values cand be reused

      if (DISABLED_COLLUMNS && DISABLED_COLLUMNS.includes(col)) {
        onMoveToDisabledColumn?.(item.id, col);
        return;
      }

      onDrop(item, monitor, context, { row, col, rowSpan, colSpan });
    },
  });

  const [showEventInfo, setShowEventInfo] = useState(false);
  const [shownOrderId, setShownOrderId] = useState<number | null>(null);
  const { data: order, isFetching: isFetchingOrder } = useOrder(shownOrderId ?? 0, { enabled: !!shownOrderId });
  const orderData = useLastDefined(order);

  useScrollToHour({ autoScrollToHour, scrollContainerRef, scrollTopOffest });

  return (
    <WithLoader isLoading={isFetchingOrder}>
      <div className={clsx('flex flex-auto', className)}>
        {renderHoursBar?.()}
        <div className={clsx('bg-white relative grid flex-auto grid-cols-1 grid-rows-1', gridClassName)}>
          {renderHorizontalLines?.()}
          {renderVerticalLines?.()}

          <ol
            ref={dropRef(gridRef) as unknown as RefObject<HTMLOListElement>}
            className={clsx('z-[60] col-start-1 col-end-2 row-start-1 grid', olClassName)}
            style={{
              gridTemplateColumns: `repeat(${COLS}, ${cellWidth ? cellWidth + 'px' : 'minmax(0, 1fr)'})`,
              gridTemplateRows: `repeat(${ROWS}, ${cellHeight ? cellHeight + 'px' : 'minmax(0, 1fr)'}) auto`,
            }}
            onClick={(e) => {
              const { columnWidth, rowHeight } = getGridCellDimensions();

              const rect = (e.target as HTMLOListElement).getBoundingClientRect();
              const x = e.clientX - rect.left; //x position within the element.
              const y = e.clientY - rect.top; //y position within the element.

              const col = Math.floor(x / columnWidth);
              const row = Math.floor(y / rowHeight);

              onGridClick?.(col, row);
            }}
          >
            {events.map((event) =>
              renderEvent(event, {
                onClick: (e) => {
                  e.stopPropagation();
                  if (disableEventPreviewOnClick) return;
                  setShownOrderId(event.id);
                  setShowEventInfo(true);
                },
              }),
            )}
            <EventsDragLayer events={events} />
          </ol>
        </div>
      </div>

      <EventInfoPopup
        open={showEventInfo && !isFetchingOrder && !!orderData}
        onClose={() => setShowEventInfo(false)}
        order={orderData ?? ({} as OrderResponse)}
      />
    </WithLoader>
  );
};

EventsGrid.displayName = 'EventsGrid';

export default EventsGridContainer;
