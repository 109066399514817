import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { MaterialResponse } from 'src/api';
import { useApiClient } from 'src/api/useApiClient';
import { CreateMaterialFormValues } from 'src/pages/MaterialPage/MaterialForm/schema';
import queryKeysFactory from 'src/store/queryKeysFactory';

const useAddMaterial = (): UseMutationResult<MaterialResponse, unknown, CreateMaterialFormValues> => {
  const queryClient = useQueryClient();
  const client = useApiClient();

  return useMutation<MaterialResponse, unknown, CreateMaterialFormValues>(
    async (material: CreateMaterialFormValues) => {
      const data = await client.material.createMaterial({ requestBody: material });
      return data as MaterialResponse;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeysFactory.materials.list.queryKey);
        toast(<FormattedMessage id='app.toasts.add_success' defaultMessage='Záznam úspěšně přidán' />, {
          type: 'success',
        });
      },
      onError: () => {
        toast(<FormattedMessage id='app.toasts.add_fail' defaultMessage='Chyba přidáni záznamu' />, {
          type: 'error',
        });
      },
    },
  );
};

export default useAddMaterial;
