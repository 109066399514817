import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { CreateDeliveryRateRequest, DeliveryRateResponse } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

const useAddDeliveryRate = (): UseMutationResult<DeliveryRateResponse, unknown, CreateDeliveryRateRequest> => {
  const queryClient = useQueryClient();
  const client = useApiClient();

  return useMutation<DeliveryRateResponse, unknown, CreateDeliveryRateRequest>(
    async (tariff: CreateDeliveryRateRequest) => {
      const data = await client.deliveryRates.createDeliveryRate({ requestBody: tariff });
      return data as DeliveryRateResponse;
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(queryKeysFactory.deliveryRates.list.queryKey);
        queryClient.invalidateQueries(queryKeysFactory.deliveryRates.detail(data.id).queryKey);
        toast(<FormattedMessage id='app.toasts.add_success' />, {
          type: 'success',
        });
      },
      onError: () => {
        toast(<FormattedMessage id='app.toasts.add_fail' />, {
          type: 'error',
        });
      },
    },
  );
};

export default useAddDeliveryRate;
