import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import AutoPopup from 'src/components/common/AutoPopup';
import UpdateUserForm from '../UpdateUserForm';

export type UpdateUserPopupProps = {
  className?: string;
};

const UpdateUserPopup: FC<UpdateUserPopupProps> = ({ className }) => {
  const userId = Number(useParams().userId);

  return (
    <AutoPopup
      id='update_user'
      title={<FormattedMessage id='app.user_page.update_user.popup_title' />}
      className={className}
    >
      <UpdateUserForm userId={userId} />
    </AutoPopup>
  );
};

UpdateUserPopup.displayName = 'UpdateUserPopup';

export default UpdateUserPopup;
