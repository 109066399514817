import clsx from 'clsx';
import React, { useEffect } from 'react';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { ContactResponse, CreateContactRequest } from 'src/api';
import { CheckboxControlled } from 'src/components/fields/Checkbox';
import NewItemPopupFooter from 'src/components/common/NewItemPopupFooter';
import FormDataRow from 'src/components/FormDataRow';
import { PhoneInputLocalizedControlled } from 'src/components/fields/PhoneInputLocalized';
import { TextFieldControlled } from 'src/components/fields/Textfield';
import AresIcoTextfield from './AresIcoTextfield';
import { ContactSchema } from './schema';
import { SelectControlled } from 'src/components/fields/Select';
import { CURRENCY_OPTIONS } from 'src/constants/currency';
import useSupportedLanguages from 'src/api/hooks/queries/useSupportedLanguages';
import { MessageId } from 'src/types/commonTypes';
import ToggleB2Cb2B from 'src/components/ToggleB2Cb2B';
import useCurrentTenantConfig from 'src/api/hooks/queries/useCurrentTenantConfig';
import { INTERNAL_CATEGORY_OPTIONS } from 'src/constants/internalCategories';
import { zodResolver } from '@hookform/resolvers/zod';

interface ContactFormProps {
  hideCategory?: boolean;
  defaultValues?: Partial<CreateContactRequest>;
  contactId?: number | string;
  deletedAt?: string | null;
  className?: string;
  onSubmit: SubmitHandler<ContactSchema>;
}

const DEFAULT_VALUES: Partial<CreateContactRequest> = {
  preferredCurrency: 'CZK',
  country: 'cz',
  preferredLanguage: 'cz',
};

const ContactForm: React.FC<ContactFormProps> = ({
  defaultValues,
  hideCategory,
  contactId,
  deletedAt,
  onSubmit,
  className,
}) => {
  const intl = useIntl();
  const form = useForm<ContactSchema>({
    resolver: zodResolver(ContactSchema),
    defaultValues: {
      ...DEFAULT_VALUES,
      ...(!hideCategory && { category: ContactResponse.category.B2B }),
      ...defaultValues,
    },
  });
  const { handleSubmit, control, setValue } = form;

  const { data } = useSupportedLanguages();
  const { data: tenantConfig } = useCurrentTenantConfig();

  useEffect(() => {
    if (!defaultValues) return;
    Object.entries(defaultValues).forEach(([k, v]) => setValue(k as keyof ContactSchema, v));
  }, [defaultValues]);

  return (
    <div className={clsx('flex-col w-full', className)}>
      <FormProvider {...form}>
        <form className='flex flex-col w-full h-full gap-x-24 gap-y-4' onSubmit={handleSubmit(onSubmit)}>
          {!hideCategory && (
            <FormDataRow label={<FormattedMessage id='app.customer.category' />}>
              <Controller
                control={control}
                name='category'
                render={({ field }) => <ToggleB2Cb2B value={field.value} onChange={field.onChange} />}
              />
            </FormDataRow>
          )}
          <Controller
            control={control}
            name='in'
            render={({ field, fieldState }) => (
              <AresIcoTextfield
                {...field}
                label={<FormattedMessage id='app.contact.ico' defaultMessage='IČO' />}
                value={field.value ?? ''}
                onIcoSelect={(data) => {
                  setValue('companyName', data.companyName);
                  setValue('city', data.city);
                  setValue('street', data.street);
                  setValue('zip', data.zip);
                  setValue('vat', data.vatNumber);
                }}
                error={fieldState.error?.message}
              />
            )}
          />

          <TextFieldControlled
            control={control}
            name='vat'
            label={<FormattedMessage id='app.common.vat' defaultMessage='DIČ' />}
          />

          <TextFieldControlled
            control={control}
            name='companyName'
            asterisk
            label={<FormattedMessage id='app.contact.company_name' defaultMessage='Název společnosti' />}
          />

          <TextFieldControlled
            control={control}
            name='email'
            asterisk
            label={<FormattedMessage id='app.common.email' />}
          />

          <PhoneInputLocalizedControlled
            control={control}
            name='phoneNumber'
            asterisk
            label={<FormattedMessage id='app.common.phone' defaultMessage='Telefonní číslo' />}
          />

          <SelectControlled
            control={control}
            name='preferredCurrency'
            asterisk
            label={<FormattedMessage id='app.common.preferred_currency' />}
            options={CURRENCY_OPTIONS}
            getOptionLabel={(option) => option}
            getOptionValue={(option) => option}
          />

          <SelectControlled
            control={control}
            name='preferredLanguage'
            asterisk
            label={<FormattedMessage id='app.common.preferred_language' />}
            options={data ?? []}
            getOptionLabel={(option) =>
              intl.formatMessage({
                id: `app.language.${option.toLowerCase()}` as MessageId,
                defaultMessage: option,
              })
            }
            getOptionValue={(option) => option}
          />

          <TextFieldControlled
            control={control}
            name='city'
            asterisk
            label={<FormattedMessage id='app.common.city' />}
          />

          <TextFieldControlled
            control={control}
            name='street'
            asterisk
            label={<FormattedMessage id='app.common.street' />}
          />

          <TextFieldControlled control={control} name='zip' asterisk label={<FormattedMessage id='app.common.zip' />} />

          <SelectControlled
            control={control}
            name='country'
            asterisk
            label={<FormattedMessage id='app.common.country' />}
            options={['cz', 'sk']}
            getOptionValue={(option) => option}
            getOptionLabel={(option) =>
              intl.formatMessage({
                id: `app.country.${option}` as MessageId,
                defaultMessage: option,
              })
            }
          />

          {tenantConfig?.contactForm?.showInternalCategory && (
            <SelectControlled
              label={<FormattedMessage id='app.contact.internal_category' />}
              options={INTERNAL_CATEGORY_OPTIONS}
              getOptionLabel={(option) => option}
              getOptionValue={(option) => option}
              control={control}
              name='internalCategory'
            />
          )}

          {tenantConfig?.contactForm?.showContractNumber && (
            <TextFieldControlled
              control={control}
              name='contractNumber'
              label={<FormattedMessage id='app.contact.contract_number' />}
            />
          )}

          {contactId && (
            <>
              {deletedAt === null ? (
                <CheckboxControlled
                  label={<FormattedMessage id='app.contact.active_contact' />}
                  control={control}
                  name='deletedAt'
                />
              ) : (
                // <Controller
                //   control={control}
                //   name='deletedAt'
                //   render={({ field, fieldState }) => <Checkbox {...field} error={fieldState.error?.message} />}
                // />
                <div className='flex flex-col'>
                  <span>
                    <FormattedMessage id='app.contact.active_contact' />
                  </span>
                  <FormattedMessage id='app.common.yes' defaultMessage='Ano' />
                </div>
              )}
            </>
          )}
          <NewItemPopupFooter />
        </form>
      </FormProvider>
    </div>
  );
};

export default ContactForm;
