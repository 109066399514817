import { useQuery, UseQueryResult } from '@tanstack/react-query';
import queryKeysFactory from 'src/store/queryKeysFactory';
import { MessageId } from 'src/types/commonTypes';

const useDefaultTranslations = (): UseQueryResult<Record<MessageId, string>> => {
  const { queryKey } = queryKeysFactory.translations.detail('default');

  return useQuery(
    queryKey,
    async () => {
      const translationModule = await import('src/translations/default.json');
      return translationModule.default;
    },
    {
      refetchInterval: false,
      staleTime: Infinity,
    },
  );
};

export default useDefaultTranslations;
