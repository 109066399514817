import { FC, HTMLAttributes, useCallback } from 'react';
import { usePopupContext } from 'src/components/Popup/hook';
import useAddContactEmployee from 'src/api/hooks/mutations/contact/useAddContactEmployee';
import { SubmitHandler } from 'react-hook-form';
import { EmployeeSchema } from '../EmployeeForm/schema';
import EmployeeForm from '../EmployeeForm';

export type AddEmployeeFormProps = {
  contactId: number;
} & HTMLAttributes<HTMLDivElement>;

const AddEmployeeForm: FC<AddEmployeeFormProps> = ({ contactId, className }) => {
  const addEmployee = useAddContactEmployee();
  const { onClose } = usePopupContext();

  const onSubmit: SubmitHandler<EmployeeSchema> = useCallback((data) => {
    addEmployee.mutate({ ...data, contactId });
    onClose();
  }, []);

  return <EmployeeForm onSubmit={onSubmit} className={className} />;
};

AddEmployeeForm.displayName = 'AddEmployeeForm';

export default AddEmployeeForm;
