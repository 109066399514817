import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Navigate } from 'react-router-dom';
import { NotificationResponse } from 'src/api';
import useCurrentTenantConfig from 'src/api/hooks/queries/useCurrentTenantConfig';
import useTenantNotificationsSettings from 'src/api/hooks/queries/useTenantNotificationsSettings';
import PageContentHeader from 'src/components/PageContentHeader';
import Loader from 'src/components/utils/Loader';
import useGetIsModuleValid from 'src/hooks/useGetIsModuleValid';
import { Modules } from 'src/types/commonTypes';
import CustomerBeforeServiceSms from './CustomerBeforeServiceSms/CustomerBeforeServiceSms';
import StockOrdersNotification from './StockOrdersNotification';
import DispatcherBeforeRoutineServiceEmailForm from './CustomerBeforeServiceSms/DispatcherBeforeRoutineServiceEmail';
import { ErrorBoundary } from 'react-error-boundary';
import PageCard from 'src/components/PageCard';

const NotificationPage: React.FC = () => {
  const getIsModuleValid = useGetIsModuleValid();
  const { data, isLoading } = useTenantNotificationsSettings();
  const { data: tenantConfig, isLoading: isLoadingTenantConfig } = useCurrentTenantConfig();

  if (!getIsModuleValid(Modules.SERVICES)) return <Navigate to='/' />;
  if (isLoading || isLoadingTenantConfig) return <Loader />;
  if (!data) return <Navigate to='/500' />;

  const customerBeforeServiceSms = data.find(
    (item) =>
      item.type === NotificationResponse.type.SMS && item.key === NotificationResponse.key.CUSTOMER_BEFORE_SERVICE,
  );

  const dispatcherBeforeService = data.find(
    (item) =>
      item.type === NotificationResponse.type.EMAIL &&
      item.key === NotificationResponse.key.DISPATCHER_BEFORE_ROUTINE_SERVICE,
  );

  return (
    <>
      <PageContentHeader
        className='my-4'
        title={<FormattedMessage id='app.notification_page.title' />}
        primaryButtonText={<FormattedMessage id='app.notification_page.new_notification_button' />}
        hidePrimaryButton
        hideSecondaryButton
      />
      <div className='grid gap-4 grid-cols-1 lg:grid-cols-2'>
        {customerBeforeServiceSms && (
          <ErrorBoundary fallback={null}>
            <PageCard>
              <CustomerBeforeServiceSms data={customerBeforeServiceSms} />
            </PageCard>
          </ErrorBoundary>
        )}
        {dispatcherBeforeService && (
          <ErrorBoundary fallback={null}>
            <PageCard>
              <DispatcherBeforeRoutineServiceEmailForm data={dispatcherBeforeService} />
            </PageCard>
          </ErrorBoundary>
        )}
        {tenantConfig?.enableStockOrders && (
          <PageCard>
            <StockOrdersNotification orderStockConfig={tenantConfig?.orderStockConfig} />
          </PageCard>
        )}
      </div>
    </>
  );
};

export default NotificationPage;
