import { toast } from 'react-toastify';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';
import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { DeleteResult } from '../../../.gen';
import { FormattedMessage } from 'react-intl';

export type UseDeleteOrderData = {
  orderId: number;
};

const useDeleteOrder = (): UseMutationResult<DeleteResult, unknown, UseDeleteOrderData> => {
  const queryClient = useQueryClient();
  const client = useApiClient();

  return useMutation<DeleteResult, unknown, UseDeleteOrderData>(
    async (orderData: UseDeleteOrderData) => {
      const data = await client.orders.delete({ id: orderData.orderId });
      return data;
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(queryKeysFactory.orders.list.queryKey);
        queryClient.invalidateQueries(queryKeysFactory.orders.detail(variables.orderId));
        queryClient.invalidateQueries(queryKeysFactory.allTechniciansWeekOrders._def);
        queryClient.invalidateQueries(queryKeysFactory.technicianMonthOrders._def);

        toast(<FormattedMessage id='app.toasts.delete_success' />, {
          type: 'success',
        });
      },
      onError: () => {
        toast(<FormattedMessage id='app.toasts.delete_fail' />, {
          type: 'error',
        });
      },
    },
  );
};

export default useDeleteOrder;
