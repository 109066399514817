import { CSSProperties, forwardRef, memo } from 'react';
import { GridEvent } from '../../types';
import EventContent from '../EventContent/EventContent';

export const createGridStyles = (
  row: number,
  rowSpan: number,
  col: number,
  colSpan: number,
): {
  gridColumn: string;
  gridRow: string;
} => ({
  gridColumn: `${col} / span ${colSpan}`,
  gridRow: `${row} / span ${rowSpan}`,
});

export type EventProps = GridEvent & {
  style?: CSSProperties;
  row: number;
  rowSpan: number;
  col: number;
  colSpan: number;
  onClick?: (e: React.MouseEvent) => void;
};

const Event = forwardRef<HTMLLIElement, EventProps>(
  ({ id, data, row, rowSpan, col, colSpan, style, onClick, ...rest }, ref) => {
    return (
      <li
        data-cy={`event-${id}`}
        className='relative z-[100] flex overflow-hidden'
        style={{
          ...style,
          ...createGridStyles(row, rowSpan, col, colSpan),
        }}
        ref={ref}
      >
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        <EventContent {...rest} id={id} ref={ref as any} onClick={onClick} data={data} />
      </li>
    );
  },
);

export default memo(Event);
