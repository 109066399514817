import React, { useEffect } from 'react';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { CreateBranchRequest } from 'src/api';
import NewItemPopupFooter from 'src/components/common/NewItemPopupFooter';
import { PhoneInputLocalizedControlled } from 'src/components/fields/PhoneInputLocalized';
import { TextFieldControlled } from 'src/components/fields/Textfield';
import { BranchSchema } from './schema';
import { zodResolver } from '@hookform/resolvers/zod';
import SeparatedTextField from 'src/components/fields/MultiInput/SeparatedTextField';

interface BranchFormProps {
  defaultValues?: Partial<CreateBranchRequest>;
  onSubmit: SubmitHandler<CreateBranchRequest>;
  className?: string;
}

// const DEFAULT_VALUES: Partial<CreateBranchRequest> = {
//   phoneNumber: null,
// }

const BranchForm: React.FC<BranchFormProps> = ({ defaultValues, onSubmit }) => {
  const form = useForm<CreateBranchRequest>({
    resolver: zodResolver(BranchSchema),
    defaultValues: {
      ...defaultValues,
    },
  });

  const { handleSubmit, control, setValue } = form;

  useEffect(() => {
    if (!defaultValues) return;
    Object.entries(defaultValues).forEach(([k, v]) => setValue(k as keyof CreateBranchRequest, v));
  }, [defaultValues]);

  return (
    <div className='flex-col w-full'>
      <form className='flex flex-col w-full gap-y-4' onSubmit={handleSubmit(onSubmit)}>
        <FormProvider {...form}>
          <TextFieldControlled
            control={control}
            name='name'
            asterisk
            label={<FormattedMessage id='app.branch.name' defaultMessage='Název' />}
            type='text'
          />

          <TextFieldControlled
            control={control}
            name='vat'
            label={<FormattedMessage id='app.common.branch.ico' defaultMessage='IČO pobočky' />}
          />

          <TextFieldControlled
            control={control}
            name='city'
            asterisk
            label={<FormattedMessage id='app.common.city' />}
          />

          <TextFieldControlled
            control={control}
            name='street'
            asterisk
            label={<FormattedMessage id='app.common.street' />}
          />

          <TextFieldControlled control={control} name='zip' asterisk label={<FormattedMessage id='app.common.zip' />} />

          <PhoneInputLocalizedControlled
            control={control}
            name='phoneNumber'
            label={<FormattedMessage id='app.common.phone' defaultMessage='Telefonní číslo' />}
          />

          <Controller
            control={control}
            name='email'
            render={({ field: { value, name, onChange }, fieldState }) => (
              <SeparatedTextField
                name={name}
                label={<FormattedMessage id='app.common.email' defaultMessage='Email' />}
                value={value ?? ''}
                onChange={onChange}
                separator=';'
                error={fieldState.error?.message}
              />
            )}
          />

          <TextFieldControlled
            control={control}
            name='contactPerson'
            label={<FormattedMessage id='app.branch.contact_person' defaultMessage='Kontaktní osoba' />}
          />

          <NewItemPopupFooter />
        </FormProvider>
      </form>
    </div>
  );
};

export default BranchForm;
