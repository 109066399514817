import { Combobox, Transition } from '@headlessui/react';
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';

import clsx from 'clsx';
import { ReactNode, useCallback, ChangeEvent, Fragment, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import FieldError from '../FieldError';
import FieldLabel from '../FieldLabel';

export type InputWithSuggestionsBaseProps<T> = {
  disabled?: boolean;
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
  onOptionClick?: (value: T) => void;
  options: Array<{ label: string; value: T }>;
  className?: string;
  notFoundMessage?: ReactNode;
  hideErrorMessage?: boolean;
  error?: ReactNode;
  label?: ReactNode;
  labelClassName?: string;
  labelTextClassName?: string;
};

export type InputWithSuggestionsProps<T> = InputWithSuggestionsBaseProps<T>;

export const InputWithSuggestionsBase = <T,>({
  label,
  labelClassName,
  labelTextClassName,
  value,
  className,
  placeholder,
  options,
  onChange,
  disabled,
  notFoundMessage,
  onOptionClick,
  hideErrorMessage,
  error,
}: InputWithSuggestionsBaseProps<T>): JSX.Element => {
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value);
    },
    [onChange],
  );

  return (
    <div className={clsx(className, 'w-full flex flex-col')}>
      {label && (
        <FieldLabel className={labelClassName} textClassName={labelTextClassName}>
          {label}
        </FieldLabel>
      )}
      <Combobox value={value} onChange={onChange} disabled={disabled}>
        <div className='relative'>
          <div className='relative w-full cursor-default overflow-hidden rounded bg-gray-50 text-left border border-gray-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300'>
            <Combobox.Input
              className='h-10 w-full border-none py-2 pl-3 pr-10 leading-5 text-gray-900 focus:ring-0'
              displayValue={() => value}
              onChange={handleChange}
              placeholder={placeholder}
            />
            <Combobox.Button className='absolute inset-y-0 right-0 flex items-center pr-2'>
              <ChevronUpDownIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
            </Combobox.Button>
          </div>
          <Transition as={Fragment} leave='transition ease-in duration-100' leaveFrom='opacity-100' leaveTo='opacity-0'>
            <Combobox.Options className='absolute z-[3000] mt-1 max-h-60 w-full overflow-auto rounded bg-gray-50 py-1 text-base shadow-lg ring-1 ring-gray-1000 ring-opacity-5 focus:outline-none'>
              {options.length === 0 && value !== '' ? (
                <div className='relative cursor-default select-none py-2 px-4 text-gray-700'>
                  {notFoundMessage ?? <FormattedMessage id='app.autocomplete.nothing_found' />}
                </div>
              ) : (
                options.map((option) => (
                  <Combobox.Option
                    key={option?.label}
                    className={({ active }) =>
                      `w-full relative select-none py-2 pl-10 pr-4 ${
                        active ? 'bg-gray-100 cursor-pointer text-gray-900' : 'text-gray-900'
                      }`
                    }
                    value={option.label}
                    onClick={() => onOptionClick?.(option.value)}
                  >
                    {() => <span className='block truncate font-normal'>{option?.label}</span>}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
      {!hideErrorMessage && error && <FieldError>{error}</FieldError>}
    </div>
  );
};

const InputWithSuggestions = <T,>({ value, options, ...rest }: InputWithSuggestionsProps<T>): JSX.Element => {
  const filteredOptions = useMemo(
    () =>
      value
        ? options.filter((option) =>
            option?.label.toLowerCase().replace(/\s+/g, '').includes(value.toLowerCase().replace(/\s+/g, '')),
          )
        : options,
    [options, value],
  );

  return <InputWithSuggestionsBase options={filteredOptions} value={value} {...rest} />;
};

InputWithSuggestions.displayName = 'InputWithSuggestions';

export default InputWithSuggestions;
