import { toast } from 'react-toastify';
import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { useIntl } from 'react-intl';
import { AxiosResponse } from 'axios';
import { useApiClient } from 'src/api/useApiClient';

type DeleteFileMutationVariables = { fileId: number };

const useDeleteFileMutation = (): UseMutationResult<AxiosResponse<unknown>, unknown, DeleteFileMutationVariables> => {
  const intl = useIntl();
  const apiClient = useApiClient();

  return useMutation<AxiosResponse<unknown>, unknown, DeleteFileMutationVariables>({
    mutationFn: ({ fileId }) => {
      return apiClient.file.deleteFile({ id: fileId });
    },
    onError: () => {
      toast(intl.formatMessage({ id: 'app.toasts.delete_fail' }), { type: 'error' });
    },
    onSuccess: () => {
      toast(intl.formatMessage({ id: 'app.toasts.delete_success' }), { type: 'success' });
    },
  });
};

export default useDeleteFileMutation;
