import { FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

export type FieldErrorProps = {
  className?: string;
  textClassName?: string;
  children: ReactNode;
};

const FieldError: FC<FieldErrorProps> = ({ className, textClassName, children }) => {
  return (
    <div className={twMerge('flex w-full', className)}>
      <span className={twMerge('text-rose-900', textClassName)}>{children}</span>
    </div>
  );
};

export default FieldError;
