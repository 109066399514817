import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { TranslationPackListItemResponse } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

const useCustomTranslationPacks = (): UseQueryResult<TranslationPackListItemResponse[]> => {
  const client = useApiClient();

  const { queryKey } = queryKeysFactory.customTranslationPacks.list;

  return useQuery(queryKey, async () => {
    const data = await client.translationPacks.getCustomTranslationPacksList();
    return data;
  });
};

export default useCustomTranslationPacks;
