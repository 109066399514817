import { match } from 'ts-pattern';
import { FinishOrderRequest } from 'src/api';

const getPriceWithSale = (
  price: number,
  saleType:
    | FinishOrderRequest.deliverySaleType
    | FinishOrderRequest.workSaleType
    | FinishOrderRequest.materialSaleType
    | null,
  saleValue: number | null,
): number => {
  if (!saleType || saleValue === null) return price;

  return match(saleType as FinishOrderRequest.deliverySaleType)
    .with(FinishOrderRequest.deliverySaleType.PERCENTAGE, () => {
      const safeSaleValue = Math.max(0, Math.min(saleValue, 100));
      return price * (1 - safeSaleValue / 100);
    })
    .with(FinishOrderRequest.deliverySaleType.VALUE, () => {
      const safesaleValue = Math.max(0, Math.min(saleValue, price));
      return price - safesaleValue;
    })
    .exhaustive();
};

export default getPriceWithSale;
