import { Column } from '@tanstack/react-table';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { OrderStatusResponse } from 'src/api';
import useStatuses from 'src/api/hooks/queries/useStatuses';
import CheckboxGroupFilter from 'src/components/Table/filters/CheckboxGroupFilter';

export type StatusFilterProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  column: Column<any, unknown>;
  onlyStatuses?: OrderStatusResponse.name[];
};

const StatusFilter: FC<StatusFilterProps> = ({ column, onlyStatuses }) => {
  const intl = useIntl();
  const { data: statuses } = useStatuses();
  const usedStatuses = onlyStatuses ? statuses?.filter((status) => onlyStatuses.includes(status.name)) : statuses;

  if (!usedStatuses) return null;
  return (
    <CheckboxGroupFilter
      column={column}
      options={usedStatuses.map((status) => ({
        label: intl.formatMessage({
          id: `app.order_status.${status.name?.toLowerCase() as Lowercase<OrderStatusResponse.name>}`,
        }),
        value: status.name,
        name: status.name,
      }))}
    />
  );
};

export default StatusFilter;
