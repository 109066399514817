import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { OrderSchema } from '../schema';
import { FormattedMessage } from 'react-intl';
import { TextFieldControlled } from 'src/components/fields/Textfield';
import { PhoneInputLocalizedControlled } from 'src/components/fields/PhoneInputLocalized';

const ServiceContactInfoForm: FC = () => {
  const { control } = useFormContext<OrderSchema>();

  return (
    <div className='grid grid-cols-2 gap-x-2 mb-16 gap-y-4'>
      <div className='font-bold text-gray-800 text-2xl'>
        <FormattedMessage id='app.order.section_title.contact_info' />
      </div>
      <TextFieldControlled
        className='col-span-2'
        control={control}
        name='contactName'
        label={<FormattedMessage id='app.order.contact_name' />}
      />
      <TextFieldControlled
        control={control}
        name='contactEmail'
        label={<FormattedMessage id='app.order.contact_email' />}
      />
      <PhoneInputLocalizedControlled
        control={control}
        name='contactPhone'
        label={<FormattedMessage id='app.order.contact_phone' />}
      />
    </div>
  );
};

export default ServiceContactInfoForm;
