import axios, { AxiosInstance, AxiosRequestHeaders } from 'axios';
import { useMemo } from 'react';
import { useTechnicianAuth } from 'src/store/technicianAuthContext';
import { useAuth } from '../store/authContext';
import { AppClient } from './.gen';

export interface UseClientOptions {
  excludeToken?: boolean;
  version?: number;
}

export const useOldApiClient = (options?: UseClientOptions): AxiosInstance => {
  const { token } = useAuth();
  const { token: techToken } = useTechnicianAuth();

  return useMemo(() => {
    const headers: AxiosRequestHeaders = {};

    if (!options?.excludeToken) {
      headers.Authorization = `Bearer ${techToken || token}`;
    }

    const client = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL || '/api'}/v${options?.version ?? 1}`,
      headers: headers,
    });

    return client;
  }, [token, techToken]);
};

export const useApiClient = (options?: UseClientOptions): AppClient => {
  const { token } = useAuth();
  const { token: techToken } = useTechnicianAuth();

  return useMemo(() => {
    const config = {
      TOKEN: options?.excludeToken ? undefined : techToken || token,
    };

    const client = new AppClient(config);

    return client;
  }, [token, techToken]);
};
