import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { HeadquartersResponse } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

const useHeadquarters = (id: number): UseQueryResult<HeadquartersResponse> => {
  const client = useApiClient();
  const { queryKey } = queryKeysFactory.headquarters.detail(id);

  return useQuery(
    queryKey,
    async () => {
      const data = await client.headquarters.getHeadquarters({
        id,
      });
      return data;
    },
    {
      refetchInterval: false,
      staleTime: Infinity,
    },
  );
};

export default useHeadquarters;
