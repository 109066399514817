import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

export type UseUpdateOrderTypeTranslationPackPayload = {
  language: string;
  json: Record<string, string>;
  orderTypeId: number;
};

const useUpdateOrderTypeTranslationPack = (): UseMutationResult<
  void,
  unknown,
  UseUpdateOrderTypeTranslationPackPayload
> => {
  const client = useApiClient();
  const queryClient = useQueryClient();

  return useMutation<void, unknown, UseUpdateOrderTypeTranslationPackPayload>(
    async ({ language, json, orderTypeId }) => {
      const data = await client.translationPacks.updateOrderTypeTranslations({
        language,
        orderTypeId,
        requestBody: json,
      });
      return data;
    },
    {
      onSuccess: (_, { orderTypeId }) => {
        queryClient.invalidateQueries(queryKeysFactory.orderTypes.detail(orderTypeId).queryKey);

        toast(<FormattedMessage id='app.toasts.update_success' />, {
          type: 'success',
        });
      },
      onError: () => {
        toast(<FormattedMessage id='app.toasts.update_fail' />, {
          type: 'error',
        });
      },
    },
  );
};

export default useUpdateOrderTypeTranslationPack;
