import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { UserResponse } from 'src/api';
import TechnicianAvatar from 'src/components/TechnicianAvatar/TechnicianAvatar';
import { twMerge } from 'tailwind-merge';

export type TechniciansBarItemProps = {
  user: UserResponse;
  onClick?: () => void;
  className?: string;
};

const TechniciansBarItem: FC<TechniciansBarItemProps> = ({ user, className, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={twMerge(
        'bg-white h-[7.5rem] min-w-40 p-3 flex flex-col justify-end',
        !onClick && 'cursor-pointer',
        className,
      )}
    >
      <TechnicianAvatar name={user.profile?.name ?? user.id.toString()} hideName />
      <span className='text-lg overflow-hidden whitespace-nowrap'>{user.profile?.name ?? user.id.toString()}</span>
      <span className='text-sm overflow-hidden whitespace-nowrap'>
        <FormattedMessage id={`app.role.${user.role}`} />
      </span>
    </div>
  );
};

export default TechniciansBarItem;
