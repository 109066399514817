import { createColumnHelper } from '@tanstack/react-table';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { MaterialResponse } from 'src/api';
import Table, { TableProps } from 'src/components/Table';
import TextFilter from 'src/components/Table/filters/TextFilter';
import { ExtendedColumnDef } from 'src/types/table';

export type MaterialTableProps = Omit<TableProps<MaterialResponse>, 'columns'>;

const columnHelper = createColumnHelper<MaterialResponse>();

const columns: ExtendedColumnDef<MaterialResponse>[] = [
  {
    ...columnHelper.accessor('materialName', {
      id: 'materialName',
      header: () => <FormattedMessage id='app.material.name' />,
      enableColumnFilter: true,
      enableSorting: true,
      cell: (info) => info.renderValue(),
      size: 250,
    }),
    renderFilter: (column) => <TextFilter column={column} />,
  },
  {
    ...columnHelper.accessor('materialValue', {
      id: 'materialValue',
      header: () => <FormattedMessage id='app.material.value' />,
      enableColumnFilter: false,
      enableSorting: true,
      cell: (info) => info.getValue(),
      size: 160,
    }),
  },
  {
    ...columnHelper.accessor('materialValueEur', {
      id: 'materialValueEur',
      header: () => <FormattedMessage id='app.common.price_eur' />,
      enableColumnFilter: false,
      enableSorting: true,
      cell: (info) => info.getValue(),
      size: 160,
    }),
  },
  {
    ...columnHelper.accessor('materialCode', {
      id: 'materialCode',
      header: () => <FormattedMessage id='app.material.code' />,
      enableColumnFilter: true,
      enableSorting: true,
      cell: (info) => info.getValue(),
      size: 160,
    }),
    renderFilter: (column) => <TextFilter column={column} />,
  },
  {
    ...columnHelper.accessor('supplierCode', {
      id: 'supplierCode',
      header: () => <FormattedMessage id='app.material.supplier_code' />,
      enableColumnFilter: true,
      enableSorting: true,
      cell: (info) => info.getValue(),
      size: 160,
    }),
    renderFilter: (column) => <TextFilter column={column} />,
  },
  {
    ...columnHelper.accessor('deletedAt', {
      id: 'deletedAt',
      header: () => <FormattedMessage id='app.common.active' />,
      enableColumnFilter: false,
      enableSorting: false,
      cell: (info) =>
        !info.getValue() ? <FormattedMessage id='app.common.yes' /> : <FormattedMessage id='app.common.no' />,
      size: 120,
    }),
  },
];

const MaterialTable: React.FC<MaterialTableProps> = (props) => {
  const navigate = useNavigate();

  return <Table columns={columns} {...props} handleRowClick={(original) => navigate(`/material/${original.id}`)} />;
};

export default MaterialTable;
