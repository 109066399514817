import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { TenantConfigResponse } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

const useTenantConfig = (tenantId: number): UseQueryResult<TenantConfigResponse> => {
  const client = useApiClient();
  const { queryKey } = queryKeysFactory.tenantConfig.detail(tenantId);

  return useQuery(queryKey, () => client.tenantConfigs.getTenantConfig({ tenantId }), {
    refetchInterval: false,
    staleTime: Infinity,
  });
};

export default useTenantConfig;
