import { FC, useCallback } from 'react';
import useAddDeliveryTariff from 'src/api/hooks/mutations/deliveryTariffs/useAddDeliveryTariff';
import DeliveryTariffForm from '../DeliveryTariffForm';
import { CreateDeliveryTariffRequest } from 'src/api';
import { usePopupContext } from 'src/components/Popup/hook';
import { SubmitHandler } from 'react-hook-form';

export type AddDeliveryTariffFormProps = {
  className?: string;
};

const AddDeliveryTariffForm: FC<AddDeliveryTariffFormProps> = ({ className }) => {
  const addDeliveryTariff = useAddDeliveryTariff();
  const { onClose } = usePopupContext();

  const onSubmit: SubmitHandler<CreateDeliveryTariffRequest> = useCallback(
    (data) => {
      addDeliveryTariff.mutate(data);
      onClose();
    },
    [addDeliveryTariff, onClose],
  );

  return <DeliveryTariffForm onSubmit={onSubmit} className={className} />;
};

AddDeliveryTariffForm.displayName = 'AddDeliveryTariffForm';

export default AddDeliveryTariffForm;
