import { TrashIcon } from '@heroicons/react/24/outline';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import Button from 'src/components/Button';
import BackIcon from 'src/components/Icons/BackIcon';
import PageContentHeader from 'src/components/PageContentHeader';
import useOpenPopup from 'src/hooks/popups/useOpenPopup';
import useIsCurrentUserAllowedToEdit from 'src/hooks/useIsCurrentUserAllowedToEdit';

type ProductDetailHeaderProps = {
  className?: string;
  disableEdit?: boolean;
  disableDelete?: boolean;
};

const ProductDetailHeader: React.FC<ProductDetailHeaderProps> = ({ className, disableDelete, disableEdit }) => {
  const isCurrentUserAllowedToEdit = useIsCurrentUserAllowedToEdit();
  const navigate = useNavigate();
  const back = useCallback(() => navigate(-1), [navigate]);
  const openUpdatePopup = useOpenPopup('update_product');
  const openDeletePopup = useOpenPopup('delete_product');

  return (
    <PageContentHeader
      mobilePrimaryButtonType='edit'
      className={className}
      title={
        <span className='flex'>
          <FormattedMessage id='app.product.detail_title' />
          {isCurrentUserAllowedToEdit && !disableDelete && (
            <Button variant='cta' size='small' onClick={openDeletePopup}>
              <TrashIcon className='h-6' />
            </Button>
          )}
        </span>
      }
      primaryButtonText={<FormattedMessage id='app.buttons.edit' />}
      hidePrimaryButton={!isCurrentUserAllowedToEdit || disableEdit}
      secondaryButtonText={
        <>
          <BackIcon />
          <FormattedMessage id='app.buttons.back' defaultMessage='Zpět' />
        </>
      }
      onPrimaryButtonClick={openUpdatePopup}
      onSecondaryButtonClick={back}
    />
  );
};

export default ProductDetailHeader;
