import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { UpdateEmployeeRequest } from 'src/api';
import { EmployeeResponse } from 'src/api';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';
import { WithContactId, WithId } from 'src/types/mutations';

const useUpdateContactEmployee = (): UseMutationResult<
  EmployeeResponse,
  unknown,
  WithContactId<WithId<UpdateEmployeeRequest>>
> => {
  const client = useApiClient();
  const queryClient = useQueryClient();

  return useMutation<EmployeeResponse, unknown, WithContactId<WithId<UpdateEmployeeRequest>>>(
    async (employee: WithContactId<WithId<UpdateEmployeeRequest>>) => {
      const data = await client.employee.updateEmployee({
        id: employee.id,
        contactId: employee.contactId,
        requestBody: employee,
      });
      return data as EmployeeResponse;
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(queryKeysFactory.employees.list(variables.contactId).queryKey);
        queryClient.invalidateQueries(queryKeysFactory.employees.detail(variables.contactId, variables.id).queryKey);
        toast(<FormattedMessage id='app.toasts.update_success' defaultMessage='Záznam úspěšně zmenen' />, {
          type: 'success',
        });
      },
      onError: () => {
        toast(<FormattedMessage id='app.toasts.update_fail' defaultMessage='Chyba zmeny záznamu' />, {
          type: 'error',
        });
      },
    },
  );
};

export default useUpdateContactEmployee;
