import { FC } from 'react';
import clsx from 'clsx';
import { ReactNode } from 'react';

export type AlertProps = {
  className?: string;
  icon?: ReactNode;
  text: ReactNode;
};

// TODO: add variant prop (warning, error, info...)

const Alert: FC<AlertProps> = ({ className, text, icon }) => {
  return (
    <div className={clsx(className, 'flex items-center w-full bg-warning min-h-[2.5rem] px-2 py-1 rounded-lg')}>
      {icon && <div className='flex items-center justify-center min-h-[1.5rem] min-w-[1.5rem] mr-2'>{icon}</div>}
      <span className='text-warningText'>{text}</span>
    </div>
  );
};

Alert.displayName = 'Alert';

export default Alert;
