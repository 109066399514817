import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { CreateWorkTariffRequest, WorkTariffResponse } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

const useAddWorkTariff = (): UseMutationResult<WorkTariffResponse, unknown, CreateWorkTariffRequest> => {
  const queryClient = useQueryClient();
  const client = useApiClient();

  return useMutation<WorkTariffResponse, unknown, CreateWorkTariffRequest>(
    async (tariff: CreateWorkTariffRequest) => {
      const data = await client.workTariffs.createWorkTariff({ requestBody: tariff });
      return data as WorkTariffResponse;
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(queryKeysFactory.workTariffs.list.queryKey);
        queryClient.invalidateQueries(queryKeysFactory.workTariffs.detail(data.id).queryKey);
        toast(<FormattedMessage id='app.toasts.add_success' />, {
          type: 'success',
        });
      },
      onError: () => {
        toast(<FormattedMessage id='app.toasts.add_fail' />, {
          type: 'error',
        });
      },
    },
  );
};

export default useAddWorkTariff;
