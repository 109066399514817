import clsx from 'clsx';
import { HTMLAttributes, PropsWithoutRef, ReactNode } from 'react';

export type ButtonGroupProps<T> = Omit<HTMLAttributes<HTMLDivElement>, 'onClick' | 'value'> & {
  value: T;
  options: Array<{ label: ReactNode; value: T }>;
  onClick: (value: T) => void;
};

const getPositionClassNames = (index: number, length: number): string => {
  if (length === 1) return 'rounded-md border';

  if (index === 0) return 'rounded-l-md border';
  if (index === length - 1) return 'rounded-r-md border-r border-l-0';
  return 'border-r';
};

const getSelectedClassNames = (selected: boolean): string => {
  if (selected) return 'bg-gray-200 hover:bg-gray-200';
  return 'hover:bg-gray-50';
};

const ButtonGroup = <T,>({ className, options, value, onClick }: PropsWithoutRef<ButtonGroupProps<T>>): JSX.Element => (
  <div className={clsx(className, 'w-max')}>
    {options.map(({ label, value: optionValue }, index) => (
      <button
        key={`${optionValue}`}
        type='button'
        disabled={value === optionValue}
        className={clsx(
          'relative inline-flex items-center border-t border-b border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 focus:z-10 focus:outline-none focus:ring-1 focus:ring-primary',
          getPositionClassNames(index, options.length),
          getSelectedClassNames(value === optionValue),
        )}
        onClick={() => onClick(optionValue)}
      >
        {label}
      </button>
    ))}
  </div>
);

ButtonGroup.displayName = 'ButtonGroup';

export default ButtonGroup;
