import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { UserResponse } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

type QueryKey = typeof queryKeysFactory.users.list._ctx.technicians.queryKey;
type QueryOptions = Omit<
  UseQueryOptions<UserResponse[], unknown, UserResponse[], QueryKey>,
  'queryKey' | 'queryFn' | 'initialData'
>;

const useAllTechnicians = (queryOptions: QueryOptions = {}): UseQueryResult<UserResponse[]> => {
  const client = useApiClient();
  const { queryKey } = queryKeysFactory.users.list._ctx.technicians;
  return useQuery(
    queryKey,
    async () => {
      const data = await client.user.getAllTechnicians();
      return data;
    },
    {
      refetchInterval: false,
      staleTime: 1000 * 60 * 5,
      ...queryOptions,
    },
  );
};

export default useAllTechnicians;
