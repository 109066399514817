import React, { useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import {
  HeadquartersResponse,
  OrderResponse,
  OrderTypeListItemRepsonse,
  TenantConfigDto,
  VatTariffResponse,
} from 'src/api';
import NewItemPopupFooter from 'src/components/common/NewItemPopupFooter';
import ServiceBranchForm from './parts/ServiceBranchForm';
import ServiceCustomerForm from './parts/ServiceCustomerForm';
import ServiceEmployeeForm from './parts/ServiceEmployeeForm';
import ServiceOrderForm from './parts/ServiceOrderForm';
import ServiceProductsForm from './parts/ServiceProductsForm';
import ServiceTechniciansForm from './parts/ServiceTechniciansForm';
import { OrderSchema } from './schema';
import { createServiceFormDefaultValues } from './helpers';
import ServiceContactInfoForm from './parts/ServiceContactInfoForm';
import { zodResolver } from '@hookform/resolvers/zod';
import clsx from 'clsx';
import { parseISO } from 'date-fns';
import { GridEvent } from 'src/components/EventsGrid/types';

interface ServiceFormProps {
  defaultValues?: Partial<OrderSchema>;
  vatTariffs: VatTariffResponse[];
  headquarters: HeadquartersResponse[];
  orderTypes: OrderTypeListItemRepsonse[];
  onSubmit: SubmitHandler<OrderSchema>;
  className?: string;
  disableCreateNew?: boolean;
  config?: TenantConfigDto;
  onDateChange?: (date: Date) => void;
  onPotentialEventsChange?: (events: GridEvent[]) => void;
}

const ServiceForm: React.FC<ServiceFormProps> = ({
  vatTariffs,
  headquarters,
  orderTypes,
  defaultValues,
  onSubmit,
  disableCreateNew,
  config,
  className,
  onDateChange,
  onPotentialEventsChange,
}) => {
  const form = useForm<OrderSchema>({
    resolver: zodResolver(OrderSchema),
    defaultValues: { ...createServiceFormDefaultValues(config), ...config?.orderForm?.defaultValues, ...defaultValues },
  });

  const { handleSubmit, watch } = form;
  const category = watch('category');
  const watchStartDatetime = watch('datetime');
  const watchEndDatetime = watch('endDatetime');
  const startDatetime = watchStartDatetime ? parseISO(watchStartDatetime) : null;
  const endDatetime = watchEndDatetime ? parseISO(watchEndDatetime) : null;
  const watchTechnicianIds = watch('technicianIds');
  const technicianIds = watchTechnicianIds?.map((t) => t.technicianId).filter(Boolean) ?? [];

  useEffect(() => {
    if (!onPotentialEventsChange || !startDatetime || !endDatetime || !technicianIds.length) return;

    const potentialEvents = technicianIds.map(
      (technicianId, index) =>
        ({
          id: -index,
          technicianId,
          start: startDatetime,
          end: endDatetime,
          isPotential: true,
          data: {
            technicians: technicianIds.map((t) => ({ id: t })),
          },
        } as GridEvent),
    );

    onPotentialEventsChange(potentialEvents);
  }, [startDatetime?.toISOString(), endDatetime?.toISOString(), technicianIds.join(',')]);

  return (
    <div className={clsx(className, 'flex-col w-full')}>
      <FormProvider {...form}>
        <form className='flex flex-col w-full h-full gap-x-24' onSubmit={handleSubmit(onSubmit, console.error)}>
          <div className='flex flex-col w-full'>
            <ServiceOrderForm
              onDateChange={onDateChange}
              config={config}
              vatTariffs={vatTariffs}
              headquarters={headquarters}
              orderTypes={orderTypes}
            />
            <ServiceTechniciansForm />
            <ServiceCustomerForm disableCreateNew={disableCreateNew} />
            {category === OrderResponse.category.B2B && <ServiceEmployeeForm disableCreateNew={disableCreateNew} />}
          </div>

          <div className='flex flex-col w-full'>
            {category === OrderResponse.category.B2B && <ServiceBranchForm disableCreateNew={disableCreateNew} />}
            {config?.orderForm?.showContactInfo && <ServiceContactInfoForm />}
            <ServiceProductsForm
              disableCreateNew={disableCreateNew}
              useProductTemplates={config?.orderForm?.useProductTemplates}
            />
          </div>
          <NewItemPopupFooter />
        </form>
      </FormProvider>
    </div>
  );
};

export default ServiceForm;
