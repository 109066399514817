/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FileUploadResponse } from '../models/FileUploadResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class FileService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns any
   * @throws ApiError
   */
  public getFile({ id }: { id: number }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/file/{id}',
      path: {
        id: id,
      },
    });
  }
  /**
   * @returns any
   * @throws ApiError
   */
  public deleteFile({ id }: { id: number }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/file/{id}',
      path: {
        id: id,
      },
    });
  }
  /**
   * @returns FileUploadResponse
   * @throws ApiError
   */
  public uploadFile(): CancelablePromise<FileUploadResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/file',
    });
  }
}
