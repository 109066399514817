import { FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

export type StatsProgressBarLegendItem = { value: number; className: string; label: ReactNode };

export type StatsProgressBarLegendProps = {
  items: Array<StatsProgressBarLegendItem>;
  className?: string;
};

const StatsProgressBarLegend: FC<StatsProgressBarLegendProps> = ({ className, items }) => {
  return (
    <div className={twMerge('flex justify-between', className)}>
      {items.map(({ value, label, className }, index) => (
        <div className='text-center' key={index}>
          <div className='flex items-center text-2xl font-bold'>
            <span>{value}</span>
            <span className={twMerge(`w-2 h-2 rounded-full ml-2`, className)} />
          </div>
          <span className='mt-1 text-sm'>{label}</span>
        </div>
      ))}
    </div>
  );
};

export default StatsProgressBarLegend;
