import { useEffect } from 'react';
import useCurrentTenant from '../api/hooks/queries/useCurrentTenant';

export function useTenantTitle(): void {
  const { data: tenant } = useCurrentTenant();
  useEffect(() => {
    if (tenant?.applicationTitle) {
      document.title = tenant.applicationTitle;
    }
  }, [tenant]);
}
