import { useMemo } from 'react';

const useIsTouchDevice = (): boolean =>
  useMemo(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    () => 'ontouchstart' in window || navigator.maxTouchPoints > 0 || (navigator as any).msMaxTouchPoints > 0,
    [],
  );

export default useIsTouchDevice;
