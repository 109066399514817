import { FC } from 'react';
import Popup from '../Popup';
import { FormattedMessage } from 'react-intl';

export type PaymentSupportRequestSentPopupProps = {
  open: boolean;
  onClose: () => void;
};

const PaymentSupportRequestSentPopup: FC<PaymentSupportRequestSentPopupProps> = ({ open, onClose }) => {
  return (
    <Popup open={open} onClose={onClose} title={<FormattedMessage id='app.demo.payment_support_request.popup_title' />}>
      <FormattedMessage id='app.demo.payment_support_request.popup_text' />
    </Popup>
  );
};

export default PaymentSupportRequestSentPopup;
