import { useMemo } from 'react';
import { FC, useCallback } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { CreateOrderTypeRequest } from 'src/api';
import useUpdateOrderType from 'src/api/hooks/mutations/orderTypes/useUpdateOrderType';
import useOrderType from 'src/api/hooks/queries/useOrderType';
import { usePopupContext } from 'src/components/Popup/hook';
import OrderTypeForm from 'src/pages/OrderTypesPage/OrderTypeForm';

export type UpdateOrderTypeFormProps = {
  className?: string;
  orderTypeId: number;
};

const UpdateOrderTypeForm: FC<UpdateOrderTypeFormProps> = ({ className, orderTypeId }) => {
  const updateOrderType = useUpdateOrderType();
  const { data } = useOrderType(orderTypeId);
  const defaultValues = useMemo(
    () => ({ ...data, slConfig: data?.slConfig ? JSON.stringify(data.slConfig) : '' }),
    [data],
  );
  const { onClose } = usePopupContext();

  const onSubmit: SubmitHandler<CreateOrderTypeRequest> = useCallback(
    (data) => {
      updateOrderType.mutate({ id: orderTypeId, ...data });
      onClose();
    },
    [updateOrderType, onClose],
  );

  return <OrderTypeForm onSubmit={onSubmit} defaultValues={defaultValues} className={className} />;
};

UpdateOrderTypeForm.displayName = 'UpdateOrderTypeForm';

export default UpdateOrderTypeForm;
