/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateOrderRequest } from '../models/CreateOrderRequest';
import type { DeleteResult } from '../models/DeleteResult';
import type { OrderListItemResponse } from '../models/OrderListItemResponse';
import type { OrderListItemsPageResponse } from '../models/OrderListItemsPageResponse';
import type { OrderResponse } from '../models/OrderResponse';
import type { OrdersDataStatsResponse } from '../models/OrdersDataStatsResponse';
import type { OrderServiceListDataResponse } from '../models/OrderServiceListDataResponse';
import type { OrdersPageResponse } from '../models/OrdersPageResponse';
import type { UpdateOrderRequest } from '../models/UpdateOrderRequest';
import type { UpdateOrderStatusRequest } from '../models/UpdateOrderStatusRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class OrdersService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns OrderListItemsPageResponse
   * @throws ApiError
   */
  public getOrderList({
    pageSize,
    pageIndex,
    statusProp,
    orderNumber,
    category,
    paymentMethods,
    productName,
    technicianProp,
    companyProp,
    branchName,
    companyId,
    branchId,
    productId,
    minPrice,
    maxPrice,
    datetime,
    endDatetime,
    minRealStart,
    minRealEnd,
    orderTypeId,
    technicianIds,
    withDeleted,
    direction,
    orderBy,
  }: {
    pageSize: number;
    pageIndex: number;
    statusProp?: string;
    orderNumber?: string;
    category?: 'B2B' | 'B2C' | null;
    paymentMethods?: string;
    productName?: string;
    technicianProp?: string;
    companyProp?: string;
    branchName?: string | null;
    companyId?: number | null;
    branchId?: number | null;
    productId?: number | null;
    minPrice?: number | null;
    maxPrice?: number | null;
    datetime?: string | null;
    endDatetime?: string | null;
    minRealStart?: string | null;
    minRealEnd?: string | null;
    orderTypeId?: number | null;
    technicianIds?: string;
    /**
     * With deleted
     */
    withDeleted?: boolean;
    direction?: 'ASC' | 'DESC';
    orderBy?: string;
  }): CancelablePromise<OrderListItemsPageResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/orders',
      query: {
        statusProp: statusProp,
        orderNumber: orderNumber,
        category: category,
        paymentMethods: paymentMethods,
        productName: productName,
        technicianProp: technicianProp,
        companyProp: companyProp,
        branchName: branchName,
        companyId: companyId,
        branchId: branchId,
        productId: productId,
        minPrice: minPrice,
        maxPrice: maxPrice,
        datetime: datetime,
        endDatetime: endDatetime,
        minRealStart: minRealStart,
        minRealEnd: minRealEnd,
        orderTypeId: orderTypeId,
        technicianIds: technicianIds,
        withDeleted: withDeleted,
        direction: direction,
        orderBy: orderBy,
        pageSize: pageSize,
        pageIndex: pageIndex,
      },
    });
  }
  /**
   * @returns OrderResponse
   * @throws ApiError
   */
  public create({ requestBody }: { requestBody: CreateOrderRequest }): CancelablePromise<OrderResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/orders',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns OrdersPageResponse
   * @throws ApiError
   */
  public getDetailedOrderList({
    pageSize,
    pageIndex,
    statusProp,
    orderNumber,
    category,
    paymentMethods,
    productName,
    technicianProp,
    companyProp,
    branchName,
    companyId,
    branchId,
    productId,
    minPrice,
    maxPrice,
    datetime,
    endDatetime,
    minRealStart,
    minRealEnd,
    orderTypeId,
    technicianIds,
    withDeleted,
    direction,
    orderBy,
  }: {
    pageSize: number;
    pageIndex: number;
    statusProp?: string;
    orderNumber?: string;
    category?: 'B2B' | 'B2C' | null;
    paymentMethods?: string;
    productName?: string;
    technicianProp?: string;
    companyProp?: string;
    branchName?: string | null;
    companyId?: number | null;
    branchId?: number | null;
    productId?: number | null;
    minPrice?: number | null;
    maxPrice?: number | null;
    datetime?: string | null;
    endDatetime?: string | null;
    minRealStart?: string | null;
    minRealEnd?: string | null;
    orderTypeId?: number | null;
    technicianIds?: string;
    /**
     * With deleted
     */
    withDeleted?: boolean;
    direction?: 'ASC' | 'DESC';
    orderBy?: string;
  }): CancelablePromise<OrdersPageResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/orders/exports/detailed',
      query: {
        statusProp: statusProp,
        orderNumber: orderNumber,
        category: category,
        paymentMethods: paymentMethods,
        productName: productName,
        technicianProp: technicianProp,
        companyProp: companyProp,
        branchName: branchName,
        companyId: companyId,
        branchId: branchId,
        productId: productId,
        minPrice: minPrice,
        maxPrice: maxPrice,
        datetime: datetime,
        endDatetime: endDatetime,
        minRealStart: minRealStart,
        minRealEnd: minRealEnd,
        orderTypeId: orderTypeId,
        technicianIds: technicianIds,
        withDeleted: withDeleted,
        direction: direction,
        orderBy: orderBy,
        pageSize: pageSize,
        pageIndex: pageIndex,
      },
    });
  }
  /**
   * @returns OrdersDataStatsResponse
   * @throws ApiError
   */
  public getTechnicianOrdersData({
    startDate,
    endDate,
  }: {
    startDate?: string | null;
    endDate?: string | null;
  }): CancelablePromise<Array<OrdersDataStatsResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/orders/exports/techniciansOrders',
      query: {
        startDate: startDate,
        endDate: endDate,
      },
    });
  }
  /**
   * @returns OrdersDataStatsResponse
   * @throws ApiError
   */
  public getProductsOrdersData({
    startDate,
    endDate,
  }: {
    startDate?: string | null;
    endDate?: string | null;
  }): CancelablePromise<Array<OrdersDataStatsResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/orders/exports/productsOrders',
      query: {
        startDate: startDate,
        endDate: endDate,
      },
    });
  }
  /**
   * @returns OrdersDataStatsResponse
   * @throws ApiError
   */
  public getCustomersOrdersData({
    startDate,
    endDate,
  }: {
    startDate?: string | null;
    endDate?: string | null;
  }): CancelablePromise<Array<OrdersDataStatsResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/orders/export/customerOrders',
      query: {
        startDate: startDate,
        endDate: endDate,
      },
    });
  }
  /**
   * @returns OrderResponse
   * @throws ApiError
   */
  public getOrder({ id }: { id: number }): CancelablePromise<OrderResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/orders/{id}',
      path: {
        id: id,
      },
    });
  }
  /**
   * @returns DeleteResult
   * @throws ApiError
   */
  public delete({ id }: { id: number }): CancelablePromise<DeleteResult> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/orders/{id}',
      path: {
        id: id,
      },
    });
  }
  /**
   * @returns OrderResponse
   * @throws ApiError
   */
  public updateData({
    id,
    requestBody,
  }: {
    id: number;
    requestBody: UpdateOrderRequest;
  }): CancelablePromise<OrderResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/orders/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns OrderServiceListDataResponse
   * @throws ApiError
   */
  public getOrderServiceListData({ id }: { id: number }): CancelablePromise<OrderServiceListDataResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/orders/{id}/sl-data',
      path: {
        id: id,
      },
    });
  }
  /**
   * @returns void
   * @throws ApiError
   */
  public invoiceOrder({
    id,
    requestBody,
  }: {
    id: number;
    requestBody: UpdateOrderStatusRequest;
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/orders/{id}/actions/invoice',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns void
   * @throws ApiError
   */
  public getPdfFile({ orderId }: { orderId: number }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/orders/{orderId}/service-list',
      path: {
        orderId: orderId,
      },
    });
  }
  /**
   * @returns void
   * @throws ApiError
   */
  public resendOrderInvitation({ orderId }: { orderId: number }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/orders/resendOrderInvitation/{orderId}',
      path: {
        orderId: orderId,
      },
    });
  }
  /**
   * @returns OrderListItemResponse
   * @throws ApiError
   */
  public getCurrentUserUpcomingOrders(): CancelablePromise<Array<OrderListItemResponse>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/orders/upcomingOrders/currentUser',
    });
  }
  /**
   * @returns void
   * @throws ApiError
   */
  public resendOrderServiceListMails({ orderId }: { orderId: number }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/orders/{orderId}/resendServiceListMails',
      path: {
        orderId: orderId,
      },
    });
  }
  /**
   * @returns void
   * @throws ApiError
   */
  public recreateServiceList({ orderId }: { orderId: number }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/orders/{orderId}/recreateServiceList',
      path: {
        orderId: orderId,
      },
    });
  }
}
