/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BatchUpdateTenantConfigField } from '../models/BatchUpdateTenantConfigField';
import type { TenantConfigDto } from '../models/TenantConfigDto';
import type { TenantConfigResponse } from '../models/TenantConfigResponse';
import type { UpdateOrderStockConfigRequest } from '../models/UpdateOrderStockConfigRequest';
import type { UpdateTenantConfigField } from '../models/UpdateTenantConfigField';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class TenantConfigsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns void
   * @throws ApiError
   */
  public updateTenantConfigField({ requestBody }: { requestBody: UpdateTenantConfigField }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/tenant-configs/update-config-field',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns void
   * @throws ApiError
   */
  public batchUpdateTenantConfigFields({
    requestBody,
  }: {
    requestBody: BatchUpdateTenantConfigField;
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/tenant-configs/batch-update-config-fields',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns void
   * @throws ApiError
   */
  public resetConfig(): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/tenant-configs/reset-config',
    });
  }
  /**
   * @returns TenantConfigResponse
   * @throws ApiError
   */
  public getTenantConfig({ tenantId }: { tenantId: number }): CancelablePromise<TenantConfigResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/tenant-configs/{tenantId}',
      path: {
        tenantId: tenantId,
      },
    });
  }
  /**
   * @returns TenantConfigResponse
   * @throws ApiError
   */
  public updateConfig({
    tenantId,
    requestBody,
  }: {
    tenantId: number;
    requestBody: TenantConfigDto;
  }): CancelablePromise<TenantConfigResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/tenant-configs/{tenantId}',
      path: {
        tenantId: tenantId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns void
   * @throws ApiError
   */
  public updateOrderStockEmail({
    requestBody,
  }: {
    requestBody: UpdateOrderStockConfigRequest;
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/tenant-configs/order-stock',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
