import { FC } from 'react';
import clsx from 'clsx';
import Title from 'src/components/Title';
import { Outlet } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

export type ExportsPageProps = {
  className?: string;
};

const ExportsPage: FC<ExportsPageProps> = ({ className }) => {
  return (
    <div className={clsx(className)}>
      <Title className='my-4'>
        <FormattedMessage id='app.export_page.title' />
      </Title>
      {/* <ExportsTabs className='mb-16' /> */}
      <Outlet />
    </div>
  );
};

ExportsPage.displayName = 'ExportsPage';

export default ExportsPage;
