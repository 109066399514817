/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateHeadquatersRequest } from '../models/CreateHeadquatersRequest';
import type { HeadquartersResponse } from '../models/HeadquartersResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class HeadquartersService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns HeadquartersResponse
   * @throws ApiError
   */
  public getAllHeadquarters(): CancelablePromise<Array<HeadquartersResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/headquarters',
    });
  }
  /**
   * @returns HeadquartersResponse
   * @throws ApiError
   */
  public createHeadquarter({
    formData,
  }: {
    formData: CreateHeadquatersRequest;
  }): CancelablePromise<HeadquartersResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/headquarters',
      formData: formData,
      mediaType: 'multipart/form-data',
    });
  }
  /**
   * @returns HeadquartersResponse
   * @throws ApiError
   */
  public getHeadquarters({ id }: { id: number }): CancelablePromise<HeadquartersResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/headquarters/{id}',
      path: {
        id: id,
      },
    });
  }
  /**
   * @returns HeadquartersResponse
   * @throws ApiError
   */
  public updateHeadquarters({
    id,
    formData,
  }: {
    id: number;
    formData: CreateHeadquatersRequest;
  }): CancelablePromise<HeadquartersResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/headquarters/{id}',
      path: {
        id: id,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
    });
  }
}
