import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { MaterialResponse } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

const useRestoreMaterial = (): UseMutationResult<MaterialResponse, unknown, number> => {
  const client = useApiClient();
  const queryClient = useQueryClient();

  return useMutation<MaterialResponse, unknown, number>(
    async (materialId: number) => {
      const data = await client.material.restoreMaterial({ id: materialId });
      return data as MaterialResponse;
    },
    {
      onSuccess: (_, materialId) => {
        queryClient.invalidateQueries(queryKeysFactory.materials.list.queryKey);
        queryClient.invalidateQueries(queryKeysFactory.materials.detail(materialId));
        toast(<FormattedMessage id='app.toasts.update_success' />, {
          type: 'success',
        });
      },
      onError: () => {
        toast(<FormattedMessage id='app.toasts.update_fail' />, {
          type: 'error',
        });
      },
    },
  );
};

export default useRestoreMaterial;
