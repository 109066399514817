import useLanguage from 'src/hooks/useLanguage';
import useFnsLocale from './useFnsLocale';

const useUserFnsLocale = (): ReturnType<typeof useFnsLocale> => {
  const [language] = useLanguage();

  return useFnsLocale(language);
};

export default useUserFnsLocale;
