import { FC, useCallback } from 'react';
import {
  FieldPath,
  FieldValues,
  PathValue,
  UnpackNestedValue,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import { FieldProps } from 'src/types/forms';

export type WithControllerProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  className?: string;
  name: TName;
  extraOnChange?: (value: UnpackNestedValue<PathValue<TFieldValues, TName>>) => void;
  control: UseControllerProps<TFieldValues, TName>['control'];
  shouldUnregister?: UseControllerProps<TFieldValues, TName>['shouldUnregister'];
};

const withController =
  <TProps extends FieldProps<TValue>, TValue = TProps['value']>(Component: FC<TProps>) =>
  <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>(
    props: Omit<TProps, 'name' | 'value' | 'onChange'> & WithControllerProps<TFieldValues, TName>,
  ) => {
    const { name, control, extraOnChange, ...rest } = props;
    const {
      field: { value, onChange, onBlur, ref },
      fieldState: { error },
    } = useController<TFieldValues, TName>({
      control,
      name,
    });

    const handleChange = useCallback(
      (v: UnpackNestedValue<PathValue<TFieldValues, TName>>) => {
        onChange(v);
        extraOnChange?.(v);
      },
      [extraOnChange, onChange],
    );

    return (
      <Component
        {...(rest as unknown as TProps)}
        ref={ref}
        name={name}
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
        error={error?.message}
      />
    );
  };

export default withController;
