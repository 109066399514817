import React, { HTMLAttributes } from 'react';

export type CheckIconProps = HTMLAttributes<HTMLOrSVGElement>;

const CheckIcon: React.FC<CheckIconProps> = ({ className, ...rest }) => (
  <svg
    {...rest}
    className={className}
    width='8'
    height='6'
    viewBox='0 0 8 6'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M7.76497 0.204742C8.06611 0.489151 8.07967 0.963831 7.79526 1.26497L3.54526 5.76497C3.40613 5.91228 3.21332 5.99703 3.01071 5.99993C2.8081 6.00282 2.61295 5.92361 2.46967 5.78033L0.21967 3.53033C-0.0732233 3.23744 -0.0732233 2.76257 0.21967 2.46967C0.512563 2.17678 0.987437 2.17678 1.28033 2.46967L2.98463 4.17397L6.70474 0.235034C6.98915 -0.0661045 7.46383 -0.0796667 7.76497 0.204742Z'
      fill='white'
    />
  </svg>
);

export default CheckIcon;
