import { FC, useState } from 'react';
import PageContentHeader from 'src/components/PageContentHeader';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import useHeadquarters from 'src/api/hooks/queries/useHeadquarters';
import HeadquartersPreview from './components/HeadquartersPreview';
import Loader from 'src/components/utils/Loader';
import { FormattedMessage } from 'react-intl';
import UpdateHeadquartersPopup from './components/UpdateHeadquartersPopup';
import PageCard from 'src/components/PageCard';

export type HeadquartersDetailPageProps = {
  //
};

const HeadquartersDetailPage: FC<HeadquartersDetailPageProps> = () => {
  const headquartersId = Number(useParams()?.headquartersId);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { data: headquarters, isLoading } = useHeadquarters(headquartersId);

  if (isLoading) return <Loader />;
  if (!headquarters) return <Navigate to='/404' />;

  return (
    <>
      <PageContentHeader
        title={<FormattedMessage id='app.headquarters_detail_page.page_title' values={{ value: headquartersId }} />}
        primaryButtonText={<FormattedMessage id='app.buttons.edit' />}
        onPrimaryButtonClick={() => setOpen(true)}
        secondaryButtonText={<FormattedMessage id='app.buttons.back' />}
        onSecondaryButtonClick={() => navigate(-1)}
        mobilePrimaryButtonType='edit'
        className='my-4'
      />
      <PageCard>
        <HeadquartersPreview data={headquarters} />
      </PageCard>
      <UpdateHeadquartersPopup open={open} onClose={() => setOpen(false)} />
    </>
  );
};

HeadquartersDetailPage.displayName = 'HeadquartersDetailPage';

export default HeadquartersDetailPage;
