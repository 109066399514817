import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

const useDeleteDeliveryTariff = (): UseMutationResult<void, unknown, number> => {
  const client = useApiClient();
  const queryClient = useQueryClient();

  return useMutation(
    async (deliveryTariffId: number) => {
      const data = await client.deliveryTariffs.deleteDeliveryTariff({ id: deliveryTariffId });
      return data;
    },
    {
      onSuccess: (_, deliveryTariffId) => {
        queryClient.invalidateQueries(queryKeysFactory.deliveryTariffs.detail(deliveryTariffId));
        queryClient.invalidateQueries(queryKeysFactory.deliveryTariffs.list.queryKey);
        toast(<FormattedMessage id='app.toasts.delete_success' />, {
          type: 'success',
        });
      },

      onError: () => {
        toast(<FormattedMessage id='app.toasts.delete_fail' defaultMessage='Chyba smazani záznamu' />, {
          type: 'error',
        });
      },
    },
  );
};

export default useDeleteDeliveryTariff;
