import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

const useDeleteProductTemplate = (): UseMutationResult<void, unknown, number> => {
  const client = useApiClient();
  const queryClient = useQueryClient();

  return useMutation<void, unknown, number>(
    async (id: number) => {
      const data = await client.productTemplate.deleteProductTemplate({ id });

      return data;
    },
    {
      onSuccess: (_, id) => {
        queryClient.invalidateQueries(queryKeysFactory.productTemplates.list.queryKey);
        queryClient.invalidateQueries(queryKeysFactory.productTemplates.detail(id));
        toast(<FormattedMessage id='app.toasts.delete_success' />, {
          type: 'success',
        });
      },
      onError: () => {
        toast(<FormattedMessage id='app.toasts.delete_fail' />, {
          type: 'error',
        });
      },
    },
  );
};

export default useDeleteProductTemplate;
