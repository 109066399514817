import clsx from 'clsx';
import { FC, HTMLAttributes } from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactSVG } from 'react-svg';
import useFileUrl from 'src/api/hooks/useFileUrl';

export type ImagePreviewProps = { id: number; className?: string; withBorder?: boolean; isSvg?: boolean } & Omit<
  HTMLAttributes<HTMLImageElement>,
  'id' | 'src'
>;

const ImagePreview: FC<ImagePreviewProps> = ({ id, isSvg, withBorder, className, ...rest }) => {
  const { data: url, isLoading, isError } = useFileUrl(id);

  if (isLoading) return <FormattedMessage id='app.image.loading' />;
  if (isError) return null;
  if (url && isSvg) return <ReactSVG src={url} className={clsx(className, withBorder && 'border border-gray-300')} />;
  return <img src={url} className={clsx(className, withBorder && 'border border-gray-300')} {...rest} />;
};

export default ImagePreview;
