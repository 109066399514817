/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateTenantRequest } from '../models/CreateTenantRequest';
import type { FileUploadResponse } from '../models/FileUploadResponse';
import type { StreamableFile } from '../models/StreamableFile';
import type { TenantsPageResponse } from '../models/TenantsPageResponse';
import type { TenantWithLicencesResponse } from '../models/TenantWithLicencesResponse';
import type { UpdateTenantRequest } from '../models/UpdateTenantRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class TenantService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns TenantWithLicencesResponse
   * @throws ApiError
   */
  public getCurrentTenant(): CancelablePromise<TenantWithLicencesResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/tenant/current',
    });
  }
  /**
   * @returns StreamableFile
   * @throws ApiError
   */
  public getLogo(): CancelablePromise<StreamableFile> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/tenant/logo',
    });
  }
  /**
   * @returns FileUploadResponse
   * @throws ApiError
   */
  public uploadLogo(): CancelablePromise<FileUploadResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/tenant/logo',
    });
  }
  /**
   * @returns TenantsPageResponse
   * @throws ApiError
   */
  public getTenantsPage({
    pageSize,
    pageIndex,
    tenantName,
    applicationTitle,
    accountingEmail,
    direction,
    orderBy,
  }: {
    pageSize: number;
    pageIndex: number;
    tenantName?: string;
    applicationTitle?: string;
    accountingEmail?: string;
    direction?: 'ASC' | 'DESC';
    orderBy?: 'tenant.id' | 'tenant_name' | 'application_title';
  }): CancelablePromise<TenantsPageResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/tenant',
      query: {
        tenantName: tenantName,
        applicationTitle: applicationTitle,
        accountingEmail: accountingEmail,
        direction: direction,
        orderBy: orderBy,
        pageSize: pageSize,
        pageIndex: pageIndex,
      },
    });
  }
  /**
   * @returns TenantWithLicencesResponse
   * @throws ApiError
   */
  public createTenant({ formData }: { formData: CreateTenantRequest }): CancelablePromise<TenantWithLicencesResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/tenant',
      formData: formData,
      mediaType: 'multipart/form-data',
    });
  }
  /**
   * @returns TenantWithLicencesResponse
   * @throws ApiError
   */
  public updateTenant({ formData }: { formData: UpdateTenantRequest }): CancelablePromise<TenantWithLicencesResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/tenant',
      formData: formData,
      mediaType: 'multipart/form-data',
    });
  }
  /**
   * @returns void
   * @throws ApiError
   */
  public updateColorTheme({ theme }: { theme: string }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/tenant/update-color-theme/{theme}',
      path: {
        theme: theme,
      },
    });
  }
  /**
   * @returns TenantWithLicencesResponse
   * @throws ApiError
   */
  public getTenant({ id }: { id: number }): CancelablePromise<TenantWithLicencesResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/tenant/{id}',
      path: {
        id: id,
      },
    });
  }
  /**
   * @returns void
   * @throws ApiError
   */
  public deactivateTenant({ id }: { id: number }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/tenant/{id}',
      path: {
        id: id,
      },
    });
  }
  /**
   * @returns void
   * @throws ApiError
   */
  public activateTenant({ id }: { id: number }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/tenant/{id}/activate',
      path: {
        id: id,
      },
    });
  }
  /**
   * @returns void
   * @throws ApiError
   */
  public requestPaymentSupport(): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/tenant/request-payment-support',
    });
  }
}
