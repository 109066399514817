/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateProductRequest } from '../models/CreateProductRequest';
import type { FileUploadRequest } from '../models/FileUploadRequest';
import type { ProductDocumentResponse } from '../models/ProductDocumentResponse';
import type { ProductHistoryPageResponse } from '../models/ProductHistoryPageResponse';
import type { ProductListResponse } from '../models/ProductListResponse';
import type { ProductResponse } from '../models/ProductResponse';
import type { ProductsPageResponse } from '../models/ProductsPageResponse';
import type { ProductWithLastOrderPageResponse } from '../models/ProductWithLastOrderPageResponse';
import type { UpdateProductRequest } from '../models/UpdateProductRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ProductService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns ProductResponse
   * @throws ApiError
   */
  public createProduct({ requestBody }: { requestBody: CreateProductRequest }): CancelablePromise<ProductResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/product',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns ProductsPageResponse
   * @throws ApiError
   */
  public getProductList({
    pageSize,
    pageIndex,
    productName,
    withDeleted,
    orderBy,
  }: {
    pageSize: number;
    pageIndex: number;
    /**
     * Product name
     */
    productName?: string;
    /**
     * With deleted
     */
    withDeleted?: boolean;
    orderBy?: string;
  }): CancelablePromise<ProductsPageResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/product',
      query: {
        productName: productName,
        withDeleted: withDeleted,
        orderBy: orderBy,
        pageSize: pageSize,
        pageIndex: pageIndex,
      },
    });
  }
  /**
   * @returns ProductListResponse
   * @throws ApiError
   */
  public getContactProductList({
    contactId,
    productName,
    withDeleted,
  }: {
    contactId: number;
    /**
     * Product name
     */
    productName?: string;
    /**
     * With deleted
     */
    withDeleted?: boolean;
  }): CancelablePromise<Array<ProductListResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/product/contact/{contactId}',
      path: {
        contactId: contactId,
      },
      query: {
        productName: productName,
        withDeleted: withDeleted,
      },
    });
  }
  /**
   * @returns ProductDocumentResponse
   * @throws ApiError
   */
  public getProductDocuments({ id }: { id: number }): CancelablePromise<Array<ProductDocumentResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/product/{id}/documents',
      path: {
        id: id,
      },
    });
  }
  /**
   * @returns void
   * @throws ApiError
   */
  public deleteProductDocument({ id, documentId }: { id: number; documentId: number }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/product/{id}/documents/{documentId}',
      path: {
        id: id,
        documentId: documentId,
      },
    });
  }
  /**
   * @returns void
   * @throws ApiError
   */
  public createFile({ id, formData }: { id: number; formData: FileUploadRequest }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/product/{id}/documents/file',
      path: {
        id: id,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
    });
  }
  /**
   * @returns ProductListResponse
   * @throws ApiError
   */
  public getBranchProductList({
    branchId,
    productName,
    withDeleted,
  }: {
    branchId: number;
    /**
     * Product name
     */
    productName?: string;
    /**
     * With deleted
     */
    withDeleted?: boolean;
  }): CancelablePromise<Array<ProductListResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/product/contact/{contactId}/branch/{branchId}/branch',
      path: {
        branchId: branchId,
      },
      query: {
        productName: productName,
        withDeleted: withDeleted,
      },
    });
  }
  /**
   * @returns ProductWithLastOrderPageResponse
   * @throws ApiError
   */
  public getProductsFromSearchQuery({
    query,
    pageSize,
    pageIndex,
    limit,
  }: {
    query: string;
    pageSize: number;
    pageIndex: number;
    limit?: number | null;
  }): CancelablePromise<ProductWithLastOrderPageResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/product/search',
      query: {
        query: query,
        limit: limit,
        pageSize: pageSize,
        pageIndex: pageIndex,
      },
    });
  }
  /**
   * @returns ProductResponse
   * @throws ApiError
   */
  public getProduct({ id }: { id: number }): CancelablePromise<ProductResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/product/{id}',
      path: {
        id: id,
      },
    });
  }
  /**
   * @returns ProductResponse
   * @throws ApiError
   */
  public updateProduct({
    id,
    requestBody,
  }: {
    id: number;
    requestBody: UpdateProductRequest;
  }): CancelablePromise<ProductResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/product/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns ProductResponse
   * @throws ApiError
   */
  public deleteProduct({ id }: { id: number }): CancelablePromise<ProductResponse> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/product/{id}',
      path: {
        id: id,
      },
    });
  }
  /**
   * @returns ProductHistoryPageResponse
   * @throws ApiError
   */
  public getProductHistory({
    pageSize,
    pageIndex,
    id,
    statusName,
    technicianName,
    datetime,
    direction,
    orderBy,
  }: {
    pageSize: number;
    pageIndex: number;
    id: number;
    statusName?: string;
    technicianName?: string;
    datetime?: string;
    direction?: 'ASC' | 'DESC';
    orderBy?: string;
  }): CancelablePromise<ProductHistoryPageResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/product/{id}/history',
      path: {
        id: id,
      },
      query: {
        statusName: statusName,
        technicianName: technicianName,
        datetime: datetime,
        direction: direction,
        orderBy: orderBy,
        pageSize: pageSize,
        pageIndex: pageIndex,
      },
    });
  }
}
