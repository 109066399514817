import clsx from 'clsx';
import React from 'react';

type AdminLayoutProps = {
  children: React.ReactNode;
};

export const AdminLayout: React.FC<AdminLayoutProps> = ({ children }) => {
  return <div className={clsx('flex flex-col w-full h-full bg-base-100')}>{children}</div>;
};
