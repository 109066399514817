import { ColumnFiltersState } from '@tanstack/react-table';
import { format } from 'date-fns';
import { FC, HTMLAttributes } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { ProductServiceHistory } from 'src/api';
import useProductHistory from 'src/api/hooks/queries/useProductHistory';
import PageContentHeader from 'src/components/PageContentHeader';
import useTableState from 'src/components/Table/hooks/useTableState';
import usePageBaseState from 'src/hooks/usePageBaseState';
import { ProductHistoryFilter } from 'src/types/filters';
import { WithId } from 'src/types/mutations';
import ProductHistoryTable from './components/ProductHistoryTable';

export type ProductHistoryPageProps = {
  //
} & HTMLAttributes<HTMLDivElement>;

const transformFilters = (filters: ColumnFiltersState): ProductHistoryFilter => {
  const datetimeValue = filters.find((i) => i.id === 'order.datetime')?.value;

  return {
    statusName: (filters.find((i) => i.id === 'order.status.name')?.value as string) ?? '',
    technicianName: (filters.find((i) => i.id === 'order.technicians.profile.name')?.value as string) ?? '',
    datetime: datetimeValue ? format(datetimeValue as Date, 'yyyy-MM-dd') : '',
  };
};

const defaultSortingState = [{ id: 'order.datetime', desc: true }];

const ProductHistoryPage: FC<ProductHistoryPageProps> = () => {
  const { isFilterOpen, handleToggleFilter } = usePageBaseState();

  const {
    columnFilters,
    setColumnFilters,
    sorting,
    setSorting,
    pagination,
    setPagination,
    debouncedServerFriendlyFilters,
    sortingQuery,
  } = useTableState<ProductHistoryFilter>({
    transformFilters,
    defaultSortingState,
  });

  const productId = Number(useParams().productId);

  const { data: history, isFetching } = useProductHistory(
    productId,
    debouncedServerFriendlyFilters,
    sortingQuery,
    pagination,
  );

  return (
    <>
      <PageContentHeader
        title={
          <span>
            <FormattedMessage id='app.contact_page.title' defaultMessage='Kontakty' /> {' - '}
            <FormattedMessage id='app.product.history_title' defaultMessage='Historie zarizeni' />
          </span>
        }
        hidePrimaryButton
        onSecondaryButtonClick={handleToggleFilter}
      />

      <ProductHistoryTable
        data={(history?.items as Array<WithId<ProductServiceHistory>>) ?? []}
        isLoading={isFetching}
        isFilterOpen={isFilterOpen}
        sorting={sorting}
        columnFilters={columnFilters}
        pagination={pagination}
        handleSort={setSorting}
        handleFilter={setColumnFilters}
        handlePaginationChange={setPagination}
        paginationMeta={history?.meta}
      />
    </>
  );
};

ProductHistoryPage.displayName = 'ProductHistoryPage';

export default ProductHistoryPage;
