import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { HeadquartersResponse } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

const useAllHeadquarters = (): UseQueryResult<HeadquartersResponse[]> => {
  const client = useApiClient();
  const { queryKey } = queryKeysFactory.headquarters.list;

  return useQuery(
    queryKey,
    async () => {
      const data = await client.headquarters.getAllHeadquarters();
      return data;
    },
    {
      refetchInterval: false,
      staleTime: 1000 * 60 * 5,
    },
  );
};

export default useAllHeadquarters;
