/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateBranchRequest } from './CreateBranchRequest';
import type { CreateContactRequest } from './CreateContactRequest';
import type { CreateEmployeeRequest } from './CreateEmployeeRequest';
import type { CreateOrderProductRequest } from './CreateOrderProductRequest';
export type UpdateOrderRequest = {
  technicianIds: Array<number>;
  datetime: string | null;
  endDatetime: string | null;
  invoiceNumber: string | null;
  createNewCompany: boolean | null;
  companyId: number | null;
  createNewBranch: boolean | null;
  branchId?: number | null;
  createNewEmployee: boolean | null;
  employeeId: number | null;
  shouldSendNotifications: boolean;
  company?: CreateContactRequest | null;
  branch?: CreateBranchRequest | null;
  employee?: CreateEmployeeRequest | null;
  orderProducts: Array<CreateOrderProductRequest>;
  orderTypeId: number;
  headquartersId: number | null;
  vatTariffId: number | null;
  emailTarget?: UpdateOrderRequest.emailTarget;
  useManualOrderNumber: boolean;
  manualOrderNumber?: string | null;
  clientOrderNumber?: string | null;
  contactName: string | null;
  contactEmail: string | null;
  contactPhone: string | null;
};
export namespace UpdateOrderRequest {
  export enum emailTarget {
    HQ = 'hq',
    BRANCH = 'branch',
  }
}
