import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { MaterialResponse } from 'src/api';
import { UpdateMaterialRequest } from 'src/api';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';
import { WithId } from 'src/types/mutations';

const useUpdateMaterial = (): UseMutationResult<MaterialResponse, unknown, WithId<UpdateMaterialRequest>> => {
  const queryClient = useQueryClient();
  const client = useApiClient();

  return useMutation<MaterialResponse, unknown, WithId<UpdateMaterialRequest>>(
    async (material: WithId<UpdateMaterialRequest>) => {
      const data = await client.material.updateMaterial({ id: material.id, requestBody: material });
      return data as MaterialResponse;
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(queryKeysFactory.materials.detail(variables.id).queryKey);
        queryClient.invalidateQueries(queryKeysFactory.materials.list.queryKey);
        toast(<FormattedMessage id='app.toasts.update_success' defaultMessage='Záznam úspěšně zmenen' />, {
          type: 'success',
        });
      },
      onError: () => {
        toast(<FormattedMessage id='app.toasts.update_fail' defaultMessage='Chyba zmeny záznamu' />, {
          type: 'error',
        });
      },
    },
  );
};

export default useUpdateMaterial;
