/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LicenceResponse } from './LicenceResponse';
import type { TenantConfigResponse } from './TenantConfigResponse';
import type { TenantDomainResponse } from './TenantDomainResponse';
export type TenantWithLicencesResponse = {
  id: number;
  maxUserCount: number;
  currentUserCount: number;
  tenantName: string;
  applicationTitle: string | null;
  mailSender: string | null;
  colorTheme: TenantWithLicencesResponse.colorTheme | null;
  tenantDomain: TenantDomainResponse | null;
  licenses: Array<LicenceResponse>;
  logoFileId: number | null;
  logoMimeType: string | null;
  tenantConfig: TenantConfigResponse | null;
  deletedAt: string | null;
  createdAt: string;
  isDemo: boolean;
};
export namespace TenantWithLicencesResponse {
  export enum colorTheme {
    ORANGE = 'orange',
    PURPLE = 'purple',
    BLUE = 'blue',
    GREEN = 'green',
    RED = 'red',
    YELLOW = 'yellow',
  }
}
