import { useQuery, UseQueryResult } from '@tanstack/react-query';
import queryKeysFactory from 'src/store/queryKeysFactory';

const sources = {
  en: () => import('date-fns/locale/en-GB').then((i) => i.default) as Locale,
  cz: () => import('date-fns/locale/cs').then((i) => i.default) as Locale,
  sk: () => import('date-fns/locale/sk').then((i) => i.default) as Locale,
  hr: () => import('date-fns/locale/hr').then((i) => i.default) as Locale,
  bg: () => import('date-fns/locale/bg').then((i) => i.default) as Locale,
} as const;

const hasLocaleForLanguage = (language: string): boolean => Object.keys(sources).includes(language);

const useFnsLocale = (language: string): UseQueryResult<Locale> => {
  const { queryKey } = queryKeysFactory.fnsLocale.detail(language);

  return useQuery(
    queryKey,
    () => {
      if (!hasLocaleForLanguage(language)) {
        console.warn(`No locale for language ${language} found. Using English instead.`);
        return sources.en();
      }
      return sources[language as keyof typeof sources]();
    },
    {
      refetchInterval: false,
      staleTime: Infinity,
    },
  );
};

export default useFnsLocale;
