import { useFloating, offset, useClick, useDismiss, useInteractions } from '@floating-ui/react';
import { CalendarDaysIcon } from '@heroicons/react/24/outline';
import { FC, useState } from 'react';
import PageCard from 'src/components/PageCard';
import DayPicker from 'src/components/datetime/DayPicker';
import { twMerge } from 'tailwind-merge';
import useCalendarSelectedDateParam from '../../hooks/useCalendarSelectedDateParam';
import CalendarButton from '../CalendarButton';

export type CalendarDateSelectProps = {
  className?: string;
};

const CalendarDateSelect: FC<CalendarDateSelectProps> = ({ className }) => {
  const [selectedDate, setSelectedDate] = useCalendarSelectedDateParam();
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'bottom-end',
    middleware: [offset(10)],
  });

  const click = useClick(context);
  const dismiss = useDismiss(context, { ancestorScroll: true });

  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss]);

  return (
    <>
      <CalendarButton
        className={twMerge('w-10 cursor-pointer flex items-center gap-1 text-gray-700', className)}
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        <CalendarDaysIcon className='w-6 h-6' />
      </CalendarButton>
      {isOpen && (
        <div ref={refs.setFloating} style={floatingStyles} className='z-[600]' {...getFloatingProps()}>
          <PageCard>
            <DayPicker selectedDay={selectedDate} onDaySelect={setSelectedDate} />
          </PageCard>
        </div>
      )}
    </>
  );
};

export default CalendarDateSelect;
