import { Updater } from '@tanstack/react-table';
import useSearchParam, { UseSearchParamOptions } from 'src/hooks/router/useSearchParam';

const useNumberArraySearchParam = (
  paramName: string,
  options: UseSearchParamOptions<number[]> = {},
): readonly [number[], (value: Updater<number[] | null>) => void] =>
  useSearchParam(paramName, {
    defaultValue: [] as number[],
    convertFromString: (value) => (value ? value.split(',').map(Number).filter(Number.isInteger) : []),
    convertToString: (value) => (value ? value.join(',') : ''),
    ...options,
  });
export default useNumberArraySearchParam;
