import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from 'src/store/authContext';

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { token } = useAuth();

  if (!token) {
    const href = window.location.href;

    if (href.includes('pocketservice.app')) {
      const root = href.split('/').slice(0, 3).join('/');
      const newRoot = root.replace('pocketservice.app', 'fiewo.com');
      window.location.href = `${newRoot}/login`;
      return;
    }

    return <Navigate to='/login' replace />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
