// VIEW 2 only
export const ROWS_PER_HOUR = 12;
export const MINUTES_PER_ROW = 60 / ROWS_PER_HOUR;

export const SEPARATORS_PER_HOUR = 2;

export const ITEM_TYPES = {
  EVENT: Symbol('event'),
};

export const COLS_PER_HOUR_MTD = 2;
export const MINUTES_PER_COL_MTD = 60 / COLS_PER_HOUR_MTD;
export const SEPARATORS_PER_HOUR_MTD = 1;
export const UNASSIGNED_ORDERS_ROW = 1;

export const GRID_CELL_HEIGHT_MTD = 120;
export const GRID_CELL_WIDTH_MTD = 48;

export const GRID_CELL_HEIGHT = 8;
