import clsx from 'clsx';
import { addMinutes, format, formatISO, startOfDay } from 'date-fns';
import { FC, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import useMoveOrder from 'src/api/hooks/mutations/order/useMoveOrder';
import useAllTechnicians from 'src/api/hooks/queries/useAllTechnicians';
import useAllTechniciansDayOrders from 'src/api/hooks/queries/useAllTechniciansDayOrders';
import EventsGrid from 'src/components/EventsGrid';
import DraggableEvent from 'src/components/EventsGrid/components/DraggableEvent';
import HorizontalLines from 'src/components/EventsGrid/components/HorizontalLines';
import HoursBar from 'src/components/EventsGrid/components/HoursBar';
import VerticalLines from 'src/components/EventsGrid/components/VerticalLines';
import { GRID_CELL_HEIGHT, MINUTES_PER_ROW, ROWS_PER_HOUR } from 'src/components/EventsGrid/constants';
import { eventToGridDay, getGridCellDimensions, gridToTimeSpanDay } from 'src/components/EventsGrid/helpers/grid';
import useHandleDropEvent from 'src/components/EventsGrid/hooks/useHandleDropEvent';
import TechnicianAvatar from 'src/components/TechnicianAvatar/TechnicianAvatar';
import WithLoader from 'src/components/utils/WithLoader';
import { snapToHalfHour } from 'src/helpers/datetime';
import orderListItemToGridEvent from 'src/helpers/orderListItemToGridEvents';
import useCalendarSelectedDateParam from 'src/pages/CalendarPage/hooks/useCalendarSelectedDateParam';
import useCalendarSingleTechnicianIdParam from 'src/pages/CalendarPage/hooks/useCalendarSingleTechnicianIdParam';
import AddServicePopup from 'src/pages/OrdersPage/AddServicePopup';
import { OrderSchema } from 'src/pages/OrdersPage/ServiceForm/schema';

const START_HOUR = 0;
const END_HOUR = 24;

const DayTab: FC = () => {
  const { data: technicians } = useAllTechnicians();
  const [technicianId] = useCalendarSingleTechnicianIdParam();
  const [selectedDate] = useCalendarSelectedDateParam();
  const containerRef = useRef<HTMLDivElement>(null);

  const { data: orders, isLoading: isLoadingEvents } = useAllTechniciansDayOrders(selectedDate);
  const { isLoading: isMovePending } = useMoveOrder();

  const technician = useMemo(() => technicians?.find((t) => t.id === technicianId), [technicians, technicianId]);

  const events = useMemo(
    () =>
      orders
        ?.map(orderListItemToGridEvent)
        .flat()
        .filter((i) => i.technicianId === technicianId) ?? [],
    [orders, technicianId],
  );

  const handleDrop = useHandleDropEvent(events ?? []);

  const [isAddServicePopupOpen, setIsAddServicePopupOpen] = useState(false);
  const [addServicePopupDefaultValues, setAddServicePopupDefaultValues] = useState<Partial<OrderSchema>>({});

  const handleGridClick = (_col: number, row: number): void => {
    const date = startOfDay(selectedDate);
    const start = addMinutes(date, snapToHalfHour(START_HOUR * 60 + MINUTES_PER_ROW * row));
    const end = addMinutes(start, 60);

    setAddServicePopupDefaultValues({
      technicianIds: technicianId ? [{ technicianId }] : [],
      _date: start,
      _start: format(start, 'HH:mm'),
      _end: format(end, 'HH:mm'),
      datetime: formatISO(start),
      endDatetime: formatISO(end),
    });
    setIsAddServicePopupOpen(true);
  };

  const isLoading = isMovePending || isLoadingEvents;

  return (
    <>
      <WithLoader isLoading={isLoading}>
        <div className={clsx('flex flex-col border border-neutral-300 rounded-t rounded-b overflow-hidden')}>
          <div className='h-11 bg-neutral-100 border-neutral-300 border-b-300 border-b-2 flex justify-center items-center'>
            {!technicianId ? (
              <FormattedMessage id='app.calendar.day.you_must_select_technician' />
            ) : (
              <TechnicianAvatar name={technician?.profile?.name || ''} />
            )}
          </div>
          <div className='grid grid-cols-1 w-full'>
            <div className='flex flex-col w-full border-r border-gray-200 overflow-y-scroll'>
              <EventsGrid
                olClassName=''
                gridToTimeSpan={gridToTimeSpanDay}
                eventToGrid={eventToGridDay}
                getGridCellDimensions={getGridCellDimensions}
                events={events}
                cols={1}
                rows={ROWS_PER_HOUR * (END_HOUR - START_HOUR)}
                startHour={START_HOUR}
                endHour={END_HOUR}
                cellHeight={GRID_CELL_HEIGHT}
                scrollContainerRef={containerRef}
                onDrop={handleDrop}
                onGridClick={handleGridClick}
                renderEvent={(event, props) => <DraggableEvent key={event.id} {...event} {...props} />}
                renderHoursBar={() => <HoursBar />}
                renderHorizontalLines={() => <HorizontalLines />}
                renderVerticalLines={() => <VerticalLines />}
              />
            </div>
          </div>
        </div>
      </WithLoader>
      <AddServicePopup
        defaultValues={addServicePopupDefaultValues}
        open={isAddServicePopupOpen}
        onClose={() => setIsAddServicePopupOpen(false)}
      />
    </>
  );
};

DayTab.displayName = 'DayTab';

export default DayTab;
