import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { DeliveryRateResponse } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

const useDeliveryRate = (id: number): UseQueryResult<DeliveryRateResponse> => {
  const client = useApiClient();
  const { queryKey } = queryKeysFactory.deliveryRates.detail(id);

  return useQuery(
    queryKey,
    async () => {
      const data = await client.deliveryRates.getDeliveryRate({ id });
      return data;
    },
    {
      refetchInterval: false,
      staleTime: 1000 * 60 * 5,
    },
  );
};

export default useDeliveryRate;
