import { createColumnHelper } from '@tanstack/react-table';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { RoutineServicesResponse } from 'src/api';
import Table, { TableProps } from 'src/components/Table';
import TextFilter from 'src/components/Table/filters/TextFilter';
import { ExtendedColumnDef } from 'src/types/table';

export type RoutineServicesTableProps = Omit<TableProps<RoutineServicesResponse>, 'columns'>;

const columnHelper = createColumnHelper<RoutineServicesResponse>();

const columns: ExtendedColumnDef<RoutineServicesResponse>[] = [
  {
    ...columnHelper.accessor('routineName', {
      id: 'routine_name',
      header: () => <FormattedMessage id='app.routine_service.name' />,
      enableColumnFilter: true,
      enableSorting: true,
      cell: (info) => info.renderValue(),
      size: 200,
    }),
    renderFilter: (column) => <TextFilter column={column} />,
  },
  {
    ...columnHelper.accessor('routineValue', {
      id: 'routine_value',
      header: () => <FormattedMessage id='app.routine_service.value' />,
      enableColumnFilter: true,
      enableSorting: true,
      cell: (info) => info.renderValue(),
      size: 200,
    }),
    renderFilter: (column) => <TextFilter column={column} />,
  },
];

const RoutineServicesTable: FC<RoutineServicesTableProps> = (props) => {
  const navigate = useNavigate();

  return (
    <Table
      columns={columns}
      {...props}
      handleRowClick={(original) => navigate(`/admin/routine-services/${original.id}`)}
    />
  );
};

export default RoutineServicesTable;
