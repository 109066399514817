import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { RoutineServicesResponse } from 'src/api';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

const useRoutineService = (id: number): UseQueryResult<RoutineServicesResponse> => {
  const client = useApiClient();
  const { queryKey } = queryKeysFactory.routineServices.detail(id);

  return useQuery(
    queryKey,
    async () => {
      const data = await client.routineServices.getRoutineServices({ id });
      return data;
    },
    {
      refetchInterval: false,
      staleTime: 1000 * 60 * 5,
    },
  );
};

export default useRoutineService;
