import { OrderResponse } from 'src/api';

export const EMAIL_TARGETS = Object.values(OrderResponse.emailTarget);
export const EMAIL_TARGET_OPTIONS = [
  {
    value: OrderResponse.emailTarget.HQ,
    labelRs: {
      id: 'app.order.company_name' as const,
    },
  },
  {
    value: OrderResponse.emailTarget.BRANCH,
    labelRs: {
      id: 'app.order.branch' as const,
    },
  },
];
