import { FC, HTMLAttributes, useMemo } from 'react';
import { SubmitHandler } from 'react-hook-form';
import useUpdateOrder from 'src/api/hooks/mutations/order/useUpdateOrder';
import { usePopupContext } from 'src/components/Popup/hook';
import ServiceForm from '../ServiceForm';
import { OrderSchema } from '../ServiceForm/schema';
import { format } from 'date-fns';
import {
  HeadquartersResponse,
  OrderResponse,
  OrderTypeListItemRepsonse,
  TenantConfigDto,
  VatTariffResponse,
} from 'src/api';
import { GridEvent } from 'src/components/EventsGrid/types';

export type UpdateServiceFormProps = {
  order: OrderResponse;
  vatTariffs: VatTariffResponse[];
  headquarters: HeadquartersResponse[];
  orderTypes: OrderTypeListItemRepsonse[];
  config?: TenantConfigDto;
  className?: string;
  onDateChange?: (date: Date) => void;
  onPotentialEventsChange?: (events: GridEvent[]) => void;
} & HTMLAttributes<HTMLDivElement>;

const UpdateServiceForm: FC<UpdateServiceFormProps> = ({
  className,
  config,
  order,
  orderTypes,
  headquarters,
  vatTariffs,
  onDateChange,
  onPotentialEventsChange,
}) => {
  const orderId = order.id;
  const updateOrder = useUpdateOrder();
  const { onClose } = usePopupContext();

  const defaultValues = useMemo(
    () =>
      ({
        ...order,
        orderProducts: order.orderProducts.map((item) => ({
          ...item,
          createNewProduct: false,
        })),
        number: {
          useManualOrderNumber: !!order?.useManualOrderNumber,
          manualOrderNumber: order?.useManualOrderNumber ? order?.orderNumber : null,
        },
        employee: {
          createNewEmployee: false,
          employeeId: order?.employee?.id,
          employee: order?.employee,
        },
        branch: {
          createNewBranch: false,
          branchId: order?.branch?.id,
          branch: order?.branch,
        },
        company: {
          createNewCompany: false,
          companyId: order?.company?.id,
          company: order?.company,
        },
        orderTypeId: order?.orderTypeId ?? undefined,
        technicianIds: order?.technicians?.map((item) => ({ technicianId: item.id })),
        _date: order?.datetime ? new Date(order.datetime) : null,
        _start: order?.datetime ? format(new Date(order.datetime), 'HH:mm') : null,
        _end: order?.endDatetime ? format(new Date(order.endDatetime), 'HH:mm') : null,
      } as unknown as OrderSchema),
    [order],
  );

  const onSubmit: SubmitHandler<OrderSchema> = (order) => {
    updateOrder.mutate({ ...order, id: orderId });
    onClose();
  };

  return (
    <ServiceForm
      config={config}
      orderTypes={orderTypes}
      headquarters={headquarters}
      vatTariffs={vatTariffs}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      className={className}
      onDateChange={onDateChange}
      onPotentialEventsChange={onPotentialEventsChange}
      disableCreateNew
    />
  );
};

UpdateServiceForm.displayName = 'UpdateServiceForm';

export default UpdateServiceForm;
