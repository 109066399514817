import { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import useAllVatTariffs from 'src/api/hooks/queries/useAllVatTariffs';
import PageContentHeader from 'src/components/PageContentHeader';
import Loader from 'src/components/utils/Loader';
import VatTariffPreview from './VatTariffPreview';
import { HeartIcon, TrashIcon } from '@heroicons/react/24/outline';
import Button from 'src/components/Button';
import useDeleteVatTariff from 'src/api/hooks/mutations/vatTariffs/useDeleteVatTariff';
import useRestoreVatTariff from 'src/api/hooks/mutations/vatTariffs/useRestoreVatTariff';
import UpdateVatTariffForm from './UpdateVatTariffForm';
import Popup from 'src/components/Popup';
import BackIcon from 'src/components/Icons/BackIcon';
import PageCard from 'src/components/PageCard';

export type VatTariffDetailPageProps = {
  //
};

const VatTariffDetailPage: FC<VatTariffDetailPageProps> = () => {
  const vatTariffId = Number(useParams().vatTariffId);
  const { data: vatTariffs, isFetching: isFetchingVatTariffs } = useAllVatTariffs({ withDeleted: true });
  const deleteMutation = useDeleteVatTariff();
  const restoreMutation = useRestoreVatTariff();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const back = (): void => navigate(-1);

  if (isFetchingVatTariffs) return <Loader />;

  const vatTariff = vatTariffs?.find((vatTariff) => vatTariff.id === vatTariffId);
  if (!vatTariff) return <Navigate to='/404' />;

  return (
    <>
      <PageContentHeader
        className='my-4'
        mobilePrimaryButtonType='edit'
        title={
          <span className='flex'>
            <FormattedMessage
              id='app.vat_tariff_detail_page.title'
              values={{ value: vatTariff?.name ?? vatTariff?.tariffValue }}
            />
            {!vatTariff.deletedAt ? (
              <Button variant='cta' size='small' onClick={() => deleteMutation.mutate(vatTariffId)}>
                <TrashIcon className='h-6' />
              </Button>
            ) : (
              <Button variant='cta' size='small' onClick={() => restoreMutation.mutate(vatTariffId)}>
                <HeartIcon className='h-6' />
              </Button>
            )}
          </span>
        }
        primaryButtonText={<FormattedMessage id='app.buttons.edit' />}
        onPrimaryButtonClick={() => setOpen(true)}
        secondaryButtonText={
          <>
            <BackIcon />
            <FormattedMessage id='app.buttons.back' />
          </>
        }
        onSecondaryButtonClick={back}
      />
      <PageCard>
        <VatTariffPreview data={vatTariff} />
      </PageCard>
      <Popup open={open} onClose={() => setOpen(false)}>
        <UpdateVatTariffForm id={vatTariffId} />
      </Popup>
    </>
  );
};

export default VatTariffDetailPage;
