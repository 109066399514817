import { ReactNode } from 'react';
import { ClassNameValue, twMerge } from 'tailwind-merge';

export function getFieldClassName(
  {
    disabled,
    error,
  }: {
    disabled?: boolean;
    error?: ReactNode;
  },
  ...classNames: ClassNameValue[]
): string {
  return twMerge(
    'text-left relative h-10 border border-neutral-200 px-4 py-2 rounded w-full',
    'focus:outline-none focus:border-b-2 focus:border-b-primaryFocus focus:pb-[calc(0.5rem-1px)]',
    'hover:border-neutral-400',
    disabled && 'text-neutral-400 border-b-neutral-300 cursor-not-allowed',
    error && 'border-red-600 focus:border-red-600 focus:pb-2',
    ...classNames,
  );
}
