import { z } from 'zod';

const TableSchema = z.object({
  keepFiltersInLocalStorage: z.boolean().optional(),
});

export const TenantConfigSchema = z
  .object({
    orderForm: z
      .object({
        defaultValues: z.object({}).passthrough().optional(),
        hiddenFields: z.array(z.string()).optional(),
        useProductTemplates: z.boolean().optional(),
        showContactInfo: z.boolean().optional(),
      })
      .optional(),
    productForm: z
      .object({
        hiddenFields: z.array(z.string()).optional(),
        showProductSerialNumber2: z.boolean().optional(),
        showWarehouse: z.boolean().optional(),
      })
      .optional(),
    contactForm: z
      .object({
        showInternalCategory: z.boolean().optional(),
      })
      .passthrough()
      .optional(),
    orderTable: TableSchema.optional().optional(),
    materialTable: TableSchema.optional().optional(),
    useBranchSearchInOrderForm: z.boolean().optional(),
    allowB2C: z.boolean().optional(),
    enableStockOrders: z.boolean().optional(),
    enableContactSearchByCompanyIn: z.boolean().optional(),
    enableContactSearchByProductCode: z.boolean().optional(),
    enableProductsTab: z.boolean().optional(),
    showHelpButton: z.boolean().optional(),
    disableVat: z.boolean().optional(),
    orderStockConfig: z
      .object({
        notificationEmails: z.array(z.string()).optional(),
      })
      .optional(),
    allowAbsentCustomer: z.boolean().optional(),
    allowOverwriteEmailInSL: z.boolean().optional(),
    showCustomNumberFieldInPDF: z.boolean().optional(),
  })
  .passthrough();

export type TenantConfigSchema = z.infer<typeof TenantConfigSchema>;
