/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateDeliveryRateRequest } from '../models/CreateDeliveryRateRequest';
import type { DeliveryRatePageResponse } from '../models/DeliveryRatePageResponse';
import type { DeliveryRateResponse } from '../models/DeliveryRateResponse';
import type { UpdateDeliveryRateRequest } from '../models/UpdateDeliveryRateRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class DeliveryRatesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns DeliveryRatePageResponse
   * @throws ApiError
   */
  public getDeliveryRates({
    pageSize,
    pageIndex,
    name,
    price,
    direction,
    orderBy,
  }: {
    pageSize: number;
    pageIndex: number;
    name?: string;
    price?: number;
    direction?: 'ASC' | 'DESC';
    orderBy?: string;
  }): CancelablePromise<DeliveryRatePageResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/delivery-rates',
      query: {
        name: name,
        price: price,
        direction: direction,
        orderBy: orderBy,
        pageSize: pageSize,
        pageIndex: pageIndex,
      },
    });
  }
  /**
   * @returns DeliveryRateResponse
   * @throws ApiError
   */
  public createDeliveryRate({
    requestBody,
  }: {
    requestBody: CreateDeliveryRateRequest;
  }): CancelablePromise<DeliveryRateResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/delivery-rates',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns DeliveryRateResponse
   * @throws ApiError
   */
  public getAllDeliveryRates(): CancelablePromise<Array<DeliveryRateResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/delivery-rates/all',
    });
  }
  /**
   * @returns DeliveryRateResponse
   * @throws ApiError
   */
  public getDeliveryRate({ id }: { id: number }): CancelablePromise<DeliveryRateResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/delivery-rates/{id}',
      path: {
        id: id,
      },
    });
  }
  /**
   * @returns DeliveryRateResponse
   * @throws ApiError
   */
  public updateDeliveryRate({
    id,
    requestBody,
  }: {
    id: number;
    requestBody: UpdateDeliveryRateRequest;
  }): CancelablePromise<DeliveryRateResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/delivery-rates/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
