import { FC, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from 'src/components/Button';
import useClosePopup from 'src/hooks/popups/useClosePopup';
import AutoPopup, { AutoPopupProps } from '../AutoPopup';

export type ConfirmationPopupProps = AutoPopupProps & {
  onOk: () => void;
  onCancel?: () => void;
  text?: React.ReactNode;
};

const ConfirmationPopup: FC<ConfirmationPopupProps> = ({ onOk, onCancel, text, id, ...rest }) => {
  const close = useClosePopup(id);

  const handleOk = useCallback(() => {
    onOk();
    close();
  }, [onOk, close]);

  const handleCancel = useCallback(() => {
    onCancel?.();
    close();
  }, [onCancel, close]);

  return (
    <AutoPopup {...rest} id={id}>
      <div className='flex flex-col w-full h-full'>
        {text && <div className='flex mb-10'>{text}</div>}
        <div className='flex w-full gap-x-8'>
          <Button className='w-full' variant='secondary' onClick={handleCancel}>
            <FormattedMessage id='app.buttons.cancel' />
          </Button>
          <Button className='w-full' onClick={handleOk}>
            <FormattedMessage id='app.common.yes' />
          </Button>
        </div>
      </div>
    </AutoPopup>
  );
};

ConfirmationPopup.displayName = 'ConfirmationPopup';

export default ConfirmationPopup;
