import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { EmployeeResponse } from 'src/api';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

const useContactEmployee = (contactId: number, employeeId: number): UseQueryResult<EmployeeResponse> => {
  const client = useApiClient();
  const { queryKey } = queryKeysFactory.employees.detail(contactId, employeeId);

  return useQuery(
    queryKey,
    async () => {
      const data = await client.employee.getEmployee({ contactId: contactId, id: employeeId });
      return data;
    },
    {
      refetchInterval: false,
      staleTime: 1000 * 60 * 5,
    },
  );
};

export default useContactEmployee;
