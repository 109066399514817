import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { useSetRecoilState } from 'recoil';
import { UserProfileResponse } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import { languageState } from 'src/store/atoms/translations';
import queryKeysFactory from 'src/store/queryKeysFactory';

const useUpdatePreferredLanguage = (): UseMutationResult<UserProfileResponse, unknown, string> => {
  const client = useApiClient();
  const queryClient = useQueryClient();
  const setLanguageState = useSetRecoilState(languageState);

  return useMutation<UserProfileResponse, unknown, string>(
    async (language: string) => {
      const data = await client.userProfile.updatePrefferedLanguage({ requestBody: { preferredLanguage: language } });
      return data;
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(queryKeysFactory.users.current.queryKey);
        queryClient.invalidateQueries(queryKeysFactory.userProfile.detail.queryKey);
        setLanguageState(data.preferredLanguage as string);
        toast(<FormattedMessage id='app.toasts.update_success' />, {
          type: 'success',
        });
      },
      onError: () => {
        toast(<FormattedMessage id='app.toasts.update_fail' />, {
          type: 'error',
        });
      },
    },
  );
};

export default useUpdatePreferredLanguage;
