import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import useTranslationPacks from 'src/api/hooks/queries/useTranslationPacks';
import PageContentHeader from 'src/components/PageContentHeader';
import useOpenPopup from 'src/hooks/popups/useOpenPopup';
import AddLanguagePopup from './components/AddLanguagePopup';
import TranslationPacksTable from './components/TranslationPacksTable';
import CustomTranslationPacksTable from './components/CustomTranslationPacksTable';
import useCustomTranslationPacks from 'src/api/hooks/queries/useCustomTranslationPacks';
import AddCustomLanguagePopup, { ADD_CUSTOM_LANGUAGE_POPUP_ID } from './components/AddCustomLanguagePopup';
import PageCard from 'src/components/PageCard';

export type TranslationsPageProps = {
  //
};

const TranslationsPage: FC<TranslationsPageProps> = () => {
  const { isLoading, data } = useTranslationPacks();
  const { isLoading: isLoadingCustomer, data: customData } = useCustomTranslationPacks();
  const openPopup = useOpenPopup('add_language');
  const openCustomPopup = useOpenPopup(ADD_CUSTOM_LANGUAGE_POPUP_ID);

  return (
    <>
      <PageContentHeader
        className='mb-12'
        title={<FormattedMessage id='app.translations_page.title' />}
        hideSecondaryButton
        onPrimaryButtonClick={openPopup}
        primaryButtonText={<FormattedMessage id='app.translations_page.add_new_language' />}
      />
      <PageCard className='flex flex-col mb-24'>
        <TranslationPacksTable isLoading={isLoading} data={data ?? []} />
      </PageCard>

      <PageContentHeader
        className='mb-12'
        title={<FormattedMessage id='app.translations_page.tenant.title' />}
        hideSecondaryButton
        onPrimaryButtonClick={openCustomPopup}
        primaryButtonText={<FormattedMessage id='app.translations_page.add_new_language' />}
      />

      <PageCard className='flex flex-col'>
        <CustomTranslationPacksTable isLoading={isLoadingCustomer} data={customData ?? []} />
      </PageCard>
      <AddLanguagePopup />
      <AddCustomLanguagePopup />
    </>
  );
};

TranslationsPage.displayName = 'TranslationsPage';

export default TranslationsPage;
