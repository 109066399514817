import { useFloating, offset, useInteractions, useHover, autoPlacement, useFocus, useRole } from '@floating-ui/react';
import { FC, ReactNode, useState } from 'react';
import { twMerge } from 'tailwind-merge';

export type TooltipProps = {
  text: ReactNode;
  children: React.ReactNode;
  className?: string;
  tooltipClassName?: string;
};

const Tooltip: FC<TooltipProps> = ({ text, children, className, tooltipClassName }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [offset(10), autoPlacement({ allowedPlacements: ['top', 'bottom'] })],
  });

  const focus = useFocus(context);
  const hover = useHover(context);
  const role = useRole(context, { role: 'tooltip' });

  const { getReferenceProps, getFloatingProps } = useInteractions([hover, focus, role]);

  return (
    <>
      <div ref={refs.setReference} {...getReferenceProps()} className={className}>
        {children}
      </div>
      {isOpen && (
        <div
          {...getFloatingProps()}
          ref={refs.setFloating}
          style={floatingStyles}
          className={twMerge(
            'px-3 py-1.5 bg-neutral-800 text-white text-sm rounded-md max-w-60 w-max  z-[4000]',
            tooltipClassName,
          )}
        >
          {text}
        </div>
      )}
    </>
  );
};

Tooltip.displayName = 'Tooltip';

export default Tooltip;
