import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import AutoPopup from 'src/components/common/AutoPopup';
import AddCustomLanguageForm from '../AddCustomLanguageForm';

export type AddCustomLanguagePopupProps = {
  className?: string;
};

export const ADD_CUSTOM_LANGUAGE_POPUP_ID = 'add_custom_language';

const AddCustomLanguagePopup: FC<AddCustomLanguagePopupProps> = ({ className }) => {
  return (
    <AutoPopup
      id={ADD_CUSTOM_LANGUAGE_POPUP_ID}
      title={<FormattedMessage id='app.translations_page.add_language_popup_title' />}
      className={className}
    >
      <AddCustomLanguageForm />
    </AutoPopup>
  );
};

AddCustomLanguagePopup.displayName = 'AddCustomLanguagePopup';

export default AddCustomLanguagePopup;
