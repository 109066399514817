import { FC, useCallback } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { CreateStockOrderRequest } from 'src/api';
import useAddStockOrder from 'src/api/hooks/mutations/stockOrders/useAddStockOrder';
import useStockOrders from 'src/api/hooks/queries/useStockOrders';
import PageContentHeader from 'src/components/PageContentHeader';
import Popup from 'src/components/Popup';
import useTableState from 'src/components/Table/hooks/useTableState';
import usePageBaseState from 'src/hooks/usePageBaseState';
import { StockOrderFilter } from 'src/types/filters';
import StockOrdersForm from './components/StockOrdersForm';
import StockOrdersTable from './components/StockOrdersTable';
import PageCard from 'src/components/PageCard';

export type StockOrdersPageProps = {
  className?: string;
};

const defaultSortingState = [{ id: 'createdAt', desc: true }];

const StockOrdersPage: FC<StockOrdersPageProps> = () => {
  const { isFilterOpen, isPopupOpen, handleClosePopup, handleOpenPopup, handleToggleFilter } = usePageBaseState();

  const addStockOrder = useAddStockOrder();

  const { columnFilters, setColumnFilters, pagination, setPagination, debouncedServerFriendlyFilters } =
    useTableState<StockOrderFilter>({
      defaultSortingState,
      localStorageKey: 'materials',
    });

  const { data, isLoading } = useStockOrders(pagination, debouncedServerFriendlyFilters);

  const handleSumbit: SubmitHandler<CreateStockOrderRequest> = useCallback(
    (data) => {
      addStockOrder.mutate(data);
      handleClosePopup();
    },
    [addStockOrder],
  );

  return (
    <>
      <PageContentHeader
        className='my-4'
        mobilePrimaryButtonType='new'
        title={<FormattedMessage id='app.stock_order_page.title' />}
        onPrimaryButtonClick={handleOpenPopup}
        primaryButtonText={<FormattedMessage id='app.stock_order_page.new_button' />}
        onSecondaryButtonClick={handleToggleFilter}
      />
      <PageCard>
        <StockOrdersTable
          data={data?.items ?? []}
          isLoading={isLoading}
          isFilterOpen={isFilterOpen}
          columnFilters={columnFilters}
          pagination={pagination}
          handlePaginationChange={setPagination}
          handleFilter={setColumnFilters}
          paginationMeta={data?.meta}
        />
      </PageCard>
      <Popup open={isPopupOpen} onClose={handleClosePopup}>
        <StockOrdersForm onSubmit={handleSumbit} />
      </Popup>
    </>
  );
};

StockOrdersPage.displayName = 'StockOrdersPage';

export default StockOrdersPage;
