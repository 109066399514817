import clsx from 'clsx';
import { range } from 'ramda';
import { CSSProperties, FC } from 'react';
import useEventsGridSettings from '../../hooks/useEventsGridSettings';

export type VerticalLinesProps = {
  className?: string;
  style?: CSSProperties;
};

const VerticalLines: FC<VerticalLinesProps> = ({ className, style }) => {
  const { COLS } = useEventsGridSettings();
  const days = range(1, COLS + 1);

  return (
    <div
      className={clsx('col-start-1 col-end-2 row-start-1 grid-rows-1 flex', className)}
      style={{
        gridTemplateColumns: `repeat(${COLS}, minmax(0, 1fr))`,
        ...style,
      }}
    >
      {days.map((day, index) => (
        <div
          key={day}
          className={clsx('flex-1', index < days.length - 1 && 'border-r border-neutral-200')}
          style={{ gridColumnStart: day }}
        />
      ))}
    </div>
  );
};

VerticalLines.displayName = 'VerticalLines';

export default VerticalLines;
