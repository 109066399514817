import { FC, HTMLAttributes } from 'react';
import AutoPopup from 'src/components/common/AutoPopup';
import { FormattedMessage } from 'react-intl';
import UpdateContactForm from '../UpdateContactForm';
import { Navigate, useParams } from 'react-router-dom';

export type UpdateContactPopupProps = {
  //
} & HTMLAttributes<HTMLDivElement>;

const UpdateContactPopup: FC<UpdateContactPopupProps> = ({ className }) => {
  const contactId = Number(useParams().contactId);

  if (!contactId) return <Navigate to='/404' />;

  return (
    <AutoPopup
      id='update_contact'
      title={<FormattedMessage id='app.contact_page.update_contact.popup_title' defaultMessage='Editace kontaktu' />}
      className={className}
    >
      <UpdateContactForm contactId={contactId} />
    </AutoPopup>
  );
};

UpdateContactPopup.displayName = 'UpdateContactPopup';

export default UpdateContactPopup;
