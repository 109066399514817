import clsx from 'clsx';
import React, { useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import useSupportedLanguages from 'src/api/hooks/queries/useSupportedLanguages';
import NewItemPopupFooter from 'src/components/common/NewItemPopupFooter';
import { SelectControlled } from 'src/components/fields/Select';
import { TextFieldControlled } from 'src/components/fields/Textfield';
import { USER_ROLES_WITHOUT_SYSADMIN } from 'src/constants/userRoles';
import { MessageId } from 'src/types/commonTypes';
import { UserSchema } from './schema';
import { zodResolver } from '@hookform/resolvers/zod';

export type UserFormProps = {
  defaultValues?: UserSchema;
  onSubmit: SubmitHandler<UserSchema>;
  className?: string;
};

const UserForm: React.FC<UserFormProps> = ({ defaultValues, className, onSubmit }) => {
  const intl = useIntl();
  const { data: languages } = useSupportedLanguages();
  const form = useForm<UserSchema>({
    defaultValues,
    resolver: zodResolver(UserSchema),
  });
  const { handleSubmit, control, setValue } = form;

  useEffect(() => {
    if (!defaultValues) return;
    Object.entries(defaultValues).forEach(([k, v]) => setValue(k as keyof UserSchema, v));
  }, [defaultValues]);

  return (
    <FormProvider {...form}>
      <div className={clsx(className, 'flex-col w-full')}>
        <form className='flex flex-col w-full gap-y-4' onSubmit={handleSubmit(onSubmit)}>
          <TextFieldControlled
            control={control}
            name='userEmail'
            asterisk
            label={<FormattedMessage id='app.common.email' />}
          />
          <SelectControlled
            asterisk
            label={<FormattedMessage id='app.user.role' />}
            getOptionValue={(option) => option}
            getOptionLabel={(option) => intl.formatMessage({ id: `app.role.${option}` })}
            options={USER_ROLES_WITHOUT_SYSADMIN}
            control={control}
            name='role'
          />
          <TextFieldControlled
            control={control}
            name='profile.name'
            asterisk
            label={<FormattedMessage id='app.user.profile.name' />}
          />
          <SelectControlled
            asterisk
            label={<FormattedMessage id='app.user.profile.preffered_language' />}
            control={control}
            name='profile.preferredLanguage'
            options={languages ?? []}
            getOptionValue={(option) => option}
            getOptionLabel={(option) =>
              intl.formatMessage({ id: `app.language.${option}` as MessageId, defaultMessage: option })
            }
          />
          <NewItemPopupFooter />
        </form>
      </div>
    </FormProvider>
  );
};

export default UserForm;
