import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { UserResponse } from 'src/api';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

type QueryKey = ReturnType<typeof queryKeysFactory.users.detail>['queryKey'];
type QueryOptions = Omit<
  UseQueryOptions<UserResponse, unknown, UserResponse, QueryKey>,
  'queryKey' | 'queryFn' | 'initialData'
>;

const useUser = (userId: number, options?: QueryOptions): UseQueryResult<UserResponse> => {
  const client = useApiClient();
  const { queryKey } = queryKeysFactory.users.detail(userId);

  return useQuery(
    queryKey,
    async () => {
      const data = await client.user.getUser({ id: userId });
      return data;
    },
    {
      refetchInterval: false,
      staleTime: 1000 * 60 * 5,
      ...options,
    },
  );
};

export default useUser;
