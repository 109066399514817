import { useForm, SubmitHandler } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { useApiClient } from 'src/api';
import Button from 'src/components/Button';
import { TextFieldControlled } from 'src/components/fields/Textfield';
import { ChangePasswordSchema } from './schema';
import { zodResolver } from '@hookform/resolvers/zod';

const ChangePasswordPage: React.FC = () => {
  const client = useApiClient();

  const { handleSubmit, control } = useForm<ChangePasswordSchema>({
    resolver: zodResolver(ChangePasswordSchema),
  });

  const onSubmit: SubmitHandler<ChangePasswordSchema> = async (data) => {
    try {
      await client.user.changePassword({ requestBody: data });
      toast(<FormattedMessage id='app.toasts.password_change_success' />, {
        type: 'success',
      });
    } catch (error) {
      toast(<FormattedMessage id='app.toasts.password_change_fail' />, {
        type: 'error',
      });
    }
  };

  return (
    <div className='grid'>
      <form onSubmit={handleSubmit(onSubmit, console.error)}>
        <div className='grid gap-y-2'>
          <h2 className=' text-3xl font-semibold text-gray-800 mb-8'>
            <FormattedMessage id='app.settings_page.password.title' />
          </h2>
          <TextFieldControlled
            control={control}
            name='userPsw'
            asterisk
            label={<FormattedMessage id='app.settings_page.password' />}
          />

          <TextFieldControlled
            control={control}
            name='userNewPsw'
            asterisk
            label={<FormattedMessage id='app.settings_page.new_password' />}
          />
          <TextFieldControlled
            control={control}
            name='userNewPswConfirm'
            asterisk
            label={<FormattedMessage id='app.settings_page.confirm_password' />}
          />

          <div className='mt-4'>
            <Button type='submit'>
              <FormattedMessage id='app.buttons.save' />
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ChangePasswordPage;
