/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CreateContactRequest = {
  vat: string | null;
  companyName: string;
  in: string | null;
  email: string;
  city: string;
  street: string;
  zip: string;
  country: string | null;
  category: CreateContactRequest.category;
  phoneNumber: string | null;
  preferredCurrency: string;
  preferredLanguage: string | null;
  internalCategory: string | null;
  contractNumber: string | null;
  notification: boolean;
};
export namespace CreateContactRequest {
  export enum category {
    B2B = 'B2B',
    B2C = 'B2C',
  }
}
