import { FC, ReactNode } from 'react';
import Popup from '../Popup';

export type InfoPopupProps = {
  open: boolean;
  title: ReactNode;
  content?: ReactNode;
  onClose: () => void;
};

const InfoPopup: FC<InfoPopupProps> = ({ title, open, content, onClose }) => {
  return (
    <Popup title={title} open={open} onClose={onClose}>
      <div className='flex flex-col w-full h-full'>{content && <div className='flex mb-10'>{content}</div>}</div>
    </Popup>
  );
};

export default InfoPopup;
