import { FC, HTMLAttributes, useCallback } from 'react';
import { SubmitHandler } from 'react-hook-form';
import useUpdateContactEmployee from 'src/api/hooks/mutations/contact/useUpdateContactEmployee';
import useContactEmployee from 'src/api/hooks/queries/useContactEmployee';
import { usePopupContext } from 'src/components/Popup/hook';
import EmployeeForm from '../EmployeeForm';
import { EmployeeSchema } from '../EmployeeForm/schema';

export type UpdateEmployeeFormProps = {
  contactId: number;
  employeeId: number;
} & HTMLAttributes<HTMLDivElement>;

const UpdateEmployeeForm: FC<UpdateEmployeeFormProps> = ({ contactId, employeeId, className }) => {
  const updateEmployee = useUpdateContactEmployee();
  const { data } = useContactEmployee(contactId, employeeId);
  const { onClose } = usePopupContext();

  const onSubmit: SubmitHandler<EmployeeSchema> = useCallback((data) => {
    updateEmployee.mutate({ ...data, contactId, id: employeeId });
    onClose();
  }, []);

  return <EmployeeForm defaultValues={data} onSubmit={onSubmit} className={className} />;
};

UpdateEmployeeForm.displayName = 'UpdateEmployeeForm';

export default UpdateEmployeeForm;
