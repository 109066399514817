import clsx from 'clsx';
import { range } from 'ramda';
import { CSSProperties, FC } from 'react';
import useEventsGridSettings from '../../hooks/useEventsGridSettings';

export type HorizontalLinesProps = {
  className?: string;
  style?: CSSProperties;
};

const HorizontalLines: FC<HorizontalLinesProps> = ({ className, style }) => {
  const { START_HOUR, END_HOUR } = useEventsGridSettings();
  const hours = range(START_HOUR, END_HOUR);

  return (
    <div
      className={clsx('col-start-1 col-end-2 row-start-1 grid', className)}
      style={{
        gridTemplateRows: `repeat(${END_HOUR - START_HOUR}, minmax(3.5rem, 1fr))`,
        ...style,
      }}
    >
      {hours.map((hour, index) => (
        <div key={hour} className={clsx('', index < hours.length - 1 && 'border-b-2 border-neutral-200')}></div>
      ))}
    </div>
  );
};

HorizontalLines.displayName = 'HorizontalLines';

export default HorizontalLines;
