import { SelectFieldSchemaResponse } from 'src/api';
import { z } from 'zod';

export const FormBuilderFieldSchema = z.object({
  id: z.string().nullish(),
  name: z.string().min(1),
  type: z.nativeEnum(SelectFieldSchemaResponse.type),
  label: z.string(),
  options: z.array(z.string()).nullable(),
  showIfName: z.string().nullish(),
  showIfValues: z.array(z.unknown()).nullish(),
  ignoreFalseInPDF: z.boolean().nullish(),
});

export type FormBuilderFieldSchema = z.infer<typeof FormBuilderFieldSchema>;
