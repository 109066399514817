import { FC, ReactNode, useCallback } from 'react';
import ReactPhoneInput, { CountryData, PhoneInputProps } from 'react-phone-input-2';
import useLanguage from 'src/hooks/useLanguage';
import useCountriesLocalization from 'src/api/hooks/queries/useCountriesLocalization';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import { FieldValues, FieldPath, UseControllerProps, Controller } from 'react-hook-form';
import './PhoneInputLocalized.css';
import FieldError from '../FieldError';
import FieldLabel from '../FieldLabel';

export type PhoneInputLocalizedProps = Omit<PhoneInputProps, 'localization'> & {
  name: string;
  value: string | null;
  onChange: (value: string | null) => void;
  error?: ReactNode;
  label?: ReactNode;
  disabled?: boolean;
  hideErrorMessage?: boolean;
  asterisk?: boolean;
  labelClassName?: string;
  labelTextClassName?: string;
};

const defaultProps: Partial<PhoneInputLocalizedProps> = {
  preferredCountries: ['cz', 'sk'],
  country: 'cz',
};

const withPrefix = (value: string, prefix = '+'): string => {
  if (!value.startsWith(prefix)) return `${prefix}${value}`;
  return value;
};

const getClassnamesProps = (disabled?: boolean, error?: boolean): Partial<PhoneInputLocalizedProps> => ({
  inputClass: clsx('!w-full !h-10', disabled && 'cursor-not-allowed !bg-gray-200', error && '!border-error'),
  buttonClass: clsx(disabled && 'cursor-not-allowed !bg-gray-200', error && '!border-error'),
});

export type ChangeHandler = (
  value: string | null,
  data: CountryData,
  event: React.ChangeEvent<HTMLInputElement>,
  formattedValue: string,
) => void;

const PhoneInputLocalized: FC<PhoneInputLocalizedProps> = ({
  name,
  inputProps,
  hideErrorMessage,
  disabled,
  error,
  onChange,
  label,
  asterisk,
  labelTextClassName,
  labelClassName,
  ...rest
}) => {
  const [lang] = useLanguage();
  const { data: localization } = useCountriesLocalization(lang);
  const handleChange = useCallback<ChangeHandler>(
    (v: string | null, ...args) => {
      onChange?.(withPrefix(v ?? ''), ...args);
    },
    [onChange],
  );

  const { buttonClass } = getClassnamesProps(disabled, !!error);

  const normalizedValue = rest.value || '+420';

  return (
    <div className='flex flex-col w-full'>
      <label className='flex flex-col gap-1'>
        {label && (
          <FieldLabel asterisk={asterisk} className={labelClassName} textClassName={labelTextClassName}>
            {label}
          </FieldLabel>
        )}
        <ReactPhoneInput
          placeholder=''
          copyNumbersOnly={false}
          {...rest}
          countryCodeEditable={false}
          value={normalizedValue}
          inputProps={{ ...inputProps, name }}
          buttonClass={twMerge(buttonClass, rest.dropdownClass)}
          disabled={disabled}
          onChange={handleChange}
          {...(localization && { localization })}
        />
      </label>
      {!hideErrorMessage && error && <FieldError>{error}</FieldError>}
    </div>
  );
};

PhoneInputLocalized.defaultProps = defaultProps;
PhoneInputLocalized.displayName = 'PhoneInputLocalized';

export default PhoneInputLocalized;

type PhoneInputLocalizedControlledProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<PhoneInputLocalizedProps, 'value' | 'onChange' | 'name' | 'error'> & {
  control: UseControllerProps<TFieldValues, TName>['control'];
  name: TName;
};

export const PhoneInputLocalizedControlled = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  ...textFieldProps
}: PhoneInputLocalizedControlledProps<TFieldValues, TName>): JSX.Element => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <PhoneInputLocalized {...textFieldProps} {...field} name={name} error={error?.message} />
      )}
    />
  );
};
