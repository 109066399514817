import clsx from 'clsx';
import { ReactNode } from 'react';

export type TabsProps<TTabValue extends string> = {
  tabs: Array<{ renderLabel: () => ReactNode; value: TTabValue }>;
  value: TTabValue;
  onChange: (value: TTabValue) => void;
  className?: string;
  variant?: 'bubble' | 'line';
};

export type TabsContextType<TTabValue extends string> = {
  value: TTabValue;
  onChange: (value: TTabValue) => void;
};

const getVariantNavClassNames = (variant: TabsProps<string>['variant']): string => {
  if (variant === 'bubble') return 'flex space-x-4';
  return 'flex w-fit divide-x divide-x-gray-300 border-l border-r border-gray-300';
};

const getVariantTabClassNames = (variant: TabsProps<string>['variant'], selected: boolean): string => {
  if (variant === 'bubble') {
    return clsx(
      selected ? 'bg-primary text-white' : 'text-gray-500 hover:text-gray-700',
      'cursor-pointer rounded-md px-3 py-2 text-sm font-medium',
    );
  }
  return clsx(
    selected
      ? 'border-b-2 border-b-primary text-gray-800'
      : 'border-b-2 border-b-gray-300 text-gray-500 hover:text-gray-700',
    'cursor-pointer px-3 py-2 text-md font-medium md:min-w-[200px] text-center',
  );
};

const Tabs = <TTabValue extends string>({
  className,
  variant = 'bubble',
  tabs,
  value,
  onChange,
}: TabsProps<TTabValue>): JSX.Element => {
  return (
    <div className={clsx(className)}>
      <div className=''>
        <nav className={getVariantNavClassNames(variant)} aria-label='Tabs'>
          {tabs.map((tab) => (
            <span
              key={tab.value}
              onClick={() => onChange(tab.value)}
              className={getVariantTabClassNames(variant, tab.value === value)}
            >
              {tab.renderLabel()}
            </span>
          ))}
        </nav>
      </div>
    </div>
  );
};

Tabs.displayName = 'Tabs';

export default Tabs;
