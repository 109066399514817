import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { PaginationState } from '@tanstack/react-table';
import { NotificationResponse } from 'src/api/.gen';
import { SortingQuery } from 'src/api/types/shared/sorting';
import { useApiClient } from 'src/api/useApiClient';
import { DEFAULT_TABLE_PAGINATION } from 'src/constants/table';
import queryKeysFactory from 'src/store/queryKeysFactory';
import { NotificationSettingsFilter } from 'src/types/filters';

export const useNotificationSettings = (
  filters?: NotificationSettingsFilter,
  sorting?: SortingQuery,
  pagination: PaginationState = DEFAULT_TABLE_PAGINATION,
): UseQueryResult<NotificationResponse[]> => {
  const client = useApiClient();
  const { queryKey } = queryKeysFactory.notificationSettings.list._ctx.search(filters, sorting, pagination);
  return useQuery(queryKey, () => client.notificationSettings.getNotificationList());
};
