import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { PaginationState } from '@tanstack/react-table';
import { ProductHistoryPageResponse } from 'src/api/.gen';
import { SortingQuery } from 'src/api/types/shared/sorting';
import { useApiClient } from 'src/api/useApiClient';
import { DEFAULT_TABLE_PAGINATION } from 'src/constants/table';
import queryKeysFactory from 'src/store/queryKeysFactory';
import { ProductHistoryFilter } from 'src/types/filters';

const useProductHistory = (
  productId: number,
  filters?: ProductHistoryFilter,
  sorting?: SortingQuery,
  pagination: PaginationState = DEFAULT_TABLE_PAGINATION,
): UseQueryResult<ProductHistoryPageResponse> => {
  const apiClient = useApiClient();

  const { queryKey } = queryKeysFactory.productHistory.list._ctx.search(productId, filters, sorting, pagination);

  return useQuery(queryKey, () =>
    apiClient.product.getProductHistory({
      ...filters,
      ...sorting,
      ...pagination,
      id: productId,
    }),
  );
};

export default useProductHistory;
