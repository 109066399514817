import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { PaginationState } from '@tanstack/table-core';
import { BranchesPageResponse } from 'src/api/.gen';
import { SortingQuery } from 'src/api/types/shared/sorting';
import { useApiClient } from 'src/api/useApiClient';
import { DEFAULT_TABLE_PAGINATION } from 'src/constants/table';
import queryKeysFactory from 'src/store/queryKeysFactory';

type QueryKey = ReturnType<typeof queryKeysFactory.branches.search>['queryKey'];
type QueryOptions = Omit<
  UseQueryOptions<BranchesPageResponse, unknown, BranchesPageResponse, QueryKey>,
  'queryKey' | 'queryFn' | 'initialData'
>;

const useBranchesFromSearch = (
  searchQueryString: string,
  pagination: PaginationState = DEFAULT_TABLE_PAGINATION,
  sorting: SortingQuery,
  options: QueryOptions = {},
): UseQueryResult<BranchesPageResponse> => {
  const client = useApiClient();
  const { queryKey } = queryKeysFactory.branches.search(searchQueryString, pagination, sorting);

  return useQuery(
    queryKey,
    async () => {
      const data = await client.branch.getBranchesFromSearchQuery({
        query: searchQueryString,
        ...pagination,
        ...sorting,
      });

      return data;
    },
    {
      refetchInterval: false,
      staleTime: 1000 * 60 * 5,
      ...options,
    },
  );
};

export default useBranchesFromSearch;
