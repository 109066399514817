import { toast } from 'react-toastify';
import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { useIntl } from 'react-intl';
import { AxiosResponse } from 'axios';
import { FileUploadResponse } from 'src/api/.gen';
import { renameFile } from 'src/utils/renameFile';
import { useOldApiClient } from 'src/api/useApiClient';

type UploadFileMutationVariables = { file: File };
type UploadFileMutationOptions = { noToast?: boolean };

const useUploadFileMutation = ({ noToast }: UploadFileMutationOptions = {}): UseMutationResult<
  AxiosResponse<FileUploadResponse>,
  unknown,
  UploadFileMutationVariables
> => {
  const intl = useIntl();
  const bareApiClient = useOldApiClient();

  return useMutation<AxiosResponse<FileUploadResponse>, unknown, UploadFileMutationVariables>({
    mutationFn: ({ file }) => {
      const encodedName = encodeURIComponent(file.name);
      const renamedFile = renameFile(file, encodedName);

      return bareApiClient.post<FileUploadResponse>(
        `/file`,
        {
          file: renamedFile,
          fileName: encodedName,
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            accept: '*/*',
          },
        },
      );
    },
    onError: () => {
      if (noToast) return;
      toast(intl.formatMessage({ id: 'app.toasts.add_fail' }), { type: 'error' });
    },
    onSuccess: () => {
      if (noToast) return;
      toast(intl.formatMessage({ id: 'app.toasts.add_success' }), { type: 'success' });
    },
  });
};

export default useUploadFileMutation;
