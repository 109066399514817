import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { CreateVatTariffRequest, VatTariffResponse } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

export type UpdateVatTariffMutationVariables = {
  id: number;
  data: CreateVatTariffRequest;
};

const useUpdateVatTariff = (): UseMutationResult<VatTariffResponse, unknown, UpdateVatTariffMutationVariables> => {
  const queryClient = useQueryClient();
  const client = useApiClient();

  return useMutation<VatTariffResponse, unknown, UpdateVatTariffMutationVariables>(
    async ({ id, data }: UpdateVatTariffMutationVariables) => {
      const res = await client.vatTariffs.updateVatTariff({ id, requestBody: data });
      return res;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeysFactory.vatTariffs._def);
        toast(<FormattedMessage id='app.toasts.update_success' />, {
          type: 'success',
        });
      },
      onError: () => {
        toast(<FormattedMessage id='app.toasts.update_fail' />, {
          type: 'error',
        });
      },
    },
  );
};

export default useUpdateVatTariff;
