import clsx from 'clsx';
import { FC, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

export const MobileServicePopupMode = {
  form: 'form',
  calendar: 'calendar',
} as const;
export type MobileServicePopupMode = (typeof MobileServicePopupMode)[keyof typeof MobileServicePopupMode];

export type MobileServicePopupTitleProps = {
  className?: string;
  mode: MobileServicePopupMode;
  onModeChange: (mode: MobileServicePopupMode) => void;
  title: ReactNode;
};

const MobileServicePopupTitle: FC<MobileServicePopupTitleProps> = ({ mode, title, className, onModeChange }) => {
  return (
    <h2
      className={clsx(
        className,
        'flex w-full justify-between relative text-3xl font-semibold text-gray-1000',
        'after:absolute after:block after:bg-primary after:w-[50px] after:h-[3px] after:bottom-[-10px] after:transition-all after:duration-500',
        mode === MobileServicePopupMode.form && 'after:left-0',
        mode === MobileServicePopupMode.calendar && 'after:left-[calc(100%-50px)]',
      )}
    >
      <span onClick={() => onModeChange('form')} className={clsx(mode !== 'form' && 'cursor-pointer text-gray-400')}>
        {title}
      </span>
      <span
        onClick={() => onModeChange('calendar')}
        className={clsx(mode !== 'calendar' && 'cursor-pointer text-gray-400')}
      >
        <FormattedMessage id='app.calendar_page.title' />
      </span>
    </h2>
  );
};

export default MobileServicePopupTitle;
