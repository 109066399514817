import clsx from 'clsx';
import { range } from 'ramda';
import { CSSProperties, FC } from 'react';
import useEventsGridSettings from 'src/components/EventsGrid/hooks/useEventsGridSettings';
import { COLS_PER_HOUR_MTD, SEPARATORS_PER_HOUR_MTD } from '../../constants';

export type VerticalLinesMTDProps = {
  className?: string;
  style?: CSSProperties;
};

const VerticalLinesMTD: FC<VerticalLinesMTDProps> = ({ className, style }) => {
  const { COLS } = useEventsGridSettings();
  const colsRange = range(1, COLS + 1).filter((i) => i % ((COLS_PER_HOUR_MTD * 1) / SEPARATORS_PER_HOUR_MTD) === 1);

  return (
    <div
      className={clsx('col-start-1 col-end-2 row-start-1 grid-rows-1 z-50 divide-x divide-neutral-300 grid', className)}
      style={{
        gridTemplateColumns: `repeat(${COLS}, minmax(0, 1fr))`,
        ...style,
      }}
    >
      {colsRange.map((i) => (
        <div key={i} className='flex row-span-full h-full' style={{ gridColumnStart: i }} />
      ))}
    </div>
  );
};

VerticalLinesMTD.displayName = 'VerticalLinesMTD';

export default VerticalLinesMTD;
