import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { OrderTypeRepsonse } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

const useOrderType = (id: number): UseQueryResult<OrderTypeRepsonse> => {
  const client = useApiClient();
  const { queryKey } = queryKeysFactory.orderTypes.detail(id);

  return useQuery(
    queryKey,
    async () => {
      const data = await client.orderTypes.getOrderType({ id });
      return data;
    },
    {
      refetchInterval: false,
      staleTime: 1000 * 60 * 5,
    },
  );
};

export default useOrderType;
