import { TrashIcon } from '@heroicons/react/24/outline';
import { useMemo } from 'react';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import useDeleteProductTemplate from 'src/api/hooks/mutations/productTemplates/useDeleteProductTemplate';
import useProductTemplate from 'src/api/hooks/queries/useProductTemplate';
import Button from 'src/components/Button';
import BackIcon from 'src/components/Icons/BackIcon';
import PageContentHeader from 'src/components/PageContentHeader';
import Loader from 'src/components/utils/Loader';
import useOpenPopup from 'src/hooks/popups/useOpenPopup';
import useWithConfirmationPopup from 'src/hooks/popups/useWithConfirmationPopup';
import ProductTemplatePreview from './ProductTemplatePreview';
import UpdateProductTemplatePopup, { UPDATE_PRODUCT_TEMPLATE_POPUP_ID } from './UpdateProductTemplatePopup';
import PageCard from 'src/components/PageCard';

export type DeviceDetailPageProps = {
  className?: string;
};

const DeviceDetailPage: FC<DeviceDetailPageProps> = ({ className }) => {
  const productTemplateId = Number(useParams().productTemplateId);
  const deleteProductTemplate = useDeleteProductTemplate();
  const navigate = useNavigate();
  const withConfirmationPopup = useWithConfirmationPopup();
  const openPopup = useOpenPopup(UPDATE_PRODUCT_TEMPLATE_POPUP_ID);

  const handleDeleteClick = useMemo(
    () =>
      withConfirmationPopup({
        title: <FormattedMessage id='app.devices.delete_device.confirmation_popup_title' />,
        onOk: () => {
          deleteProductTemplate.mutate(productTemplateId);
          navigate('/admin/devices');
        },
      }),
    [productTemplateId, deleteProductTemplate, withConfirmationPopup],
  );

  const { data, isLoading } = useProductTemplate(productTemplateId);

  if (isLoading) return <Loader />;
  if (!data) return <Navigate to='/404' />;

  return (
    <>
      <PageContentHeader
        mobilePrimaryButtonType='edit'
        className='my-4'
        title={
          <span className='flex'>
            <FormattedMessage id='app.devices.page_title' />
            <Button variant='cta' size='small' onClick={handleDeleteClick}>
              <TrashIcon className='h-6' />
            </Button>
          </span>
        }
        primaryButtonText={<FormattedMessage id='app.buttons.edit' defaultMessage='Upravit' />}
        secondaryButtonText={
          <>
            <BackIcon />
            <FormattedMessage id='app.buttons.back' defaultMessage='Zpět' />
          </>
        }
        onPrimaryButtonClick={openPopup}
        onSecondaryButtonClick={() => navigate(-1)}
      />
      <PageCard>
        <ProductTemplatePreview className={className} data={data} />
      </PageCard>
      <UpdateProductTemplatePopup />
    </>
  );
};

DeviceDetailPage.displayName = 'DeviceDetailPage';

export default DeviceDetailPage;
