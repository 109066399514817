import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { OrderResponse } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

type QueryKey = ReturnType<typeof queryKeysFactory.orders.detail>['queryKey'];
type QueryOptions = Omit<
  UseQueryOptions<OrderResponse, unknown, OrderResponse, QueryKey>,
  'queryKey' | 'queryFn' | 'initialData'
>;

const useOrder = (id: number, options?: QueryOptions): UseQueryResult<OrderResponse> => {
  const client = useApiClient();
  const { queryKey } = queryKeysFactory.orders.detail(id);

  return useQuery(queryKey, () => client.orders.getOrder({ id }), {
    refetchInterval: false,
    staleTime: Infinity,
    ...options,
  });
};

export default useOrder;
