import { FC } from 'react';
import { OrderProductResponse, OrderResponse } from 'src/api';
import FinishFormGenTemplate from 'src/components/FinishFormGenTemplate';
import ProductInfoForm from '../parts/ProductInfoForm';
import ProductMaterialsForm from '../parts/ProductMaterialsForm';
import ProductPhotosForm from '../parts/ProductPhotosForm';

export type ProductTabProps = {
  order: OrderResponse;
  orderProductId: number;
  orderProductIndex: number;
  orderProduct: OrderProductResponse;
  hideMaterials?: boolean;
};

const ProductTab: FC<ProductTabProps> = ({ orderProductIndex, orderProduct, hideMaterials, order }) => {
  return (
    <>
      <div className='flex flex-col mb-14 mt-[-1rem]'>
        <span>{orderProduct?.product?.productName}</span>
        {orderProduct?.product?.productSerialNumber && <span>{` ${orderProduct?.product?.productSerialNumber}`}</span>}
      </div>

      {!hideMaterials && <ProductMaterialsForm order={order} orderProductIndex={orderProductIndex} />}
      {order?.orderType?.slConfig?.productsSections && (
        <FinishFormGenTemplate
          sections={order?.orderType?.slConfig?.productsSections}
          baseNameFactory={() => `slConfigOutput.products.${orderProductIndex}`}
        />
      )}
      <ProductInfoForm order={order} orderProductIndex={orderProductIndex} />
      {!order?.orderType?.slConfig?.productsHiddenFeilds?.includes('photo') && (
        <ProductPhotosForm orderProductIndex={orderProductIndex} orderId={order.id} />
      )}
    </>
  );
};

export default ProductTab;
