import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { CreateOrderTypeRequest, OrderTypeRepsonse } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

const useAddOrderType = (): UseMutationResult<OrderTypeRepsonse, unknown, CreateOrderTypeRequest> => {
  const queryClient = useQueryClient();
  const client = useApiClient();

  return useMutation<OrderTypeRepsonse, unknown, CreateOrderTypeRequest>(
    async (orderType: CreateOrderTypeRequest) => {
      const data = await client.orderTypes.createOrderType({ requestBody: orderType });
      return data as OrderTypeRepsonse;
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(queryKeysFactory.orderTypes.list.queryKey);
        queryClient.invalidateQueries(queryKeysFactory.orderTypes.detail(data.id).queryKey);
        toast(<FormattedMessage id='app.toasts.add_success' />, {
          type: 'success',
        });
      },
      onError: () => {
        toast(<FormattedMessage id='app.toasts.add_fail' />, {
          type: 'error',
        });
      },
    },
  );
};

export default useAddOrderType;
