import { addMinutes, getDate, setDate, startOfDay } from 'date-fns';
import { flatten, pipe, range, splitEvery } from 'ramda';
import { UserResponse } from 'src/api';
import {
  EventToGridRawFunc,
  GetGridCellDimensionsRawFunc,
  GridToTimeSpanRawFunc,
} from 'src/components/EventsGrid/types';
import { getAllMinutes, snapToHalfHour } from 'src/helpers/datetime';
import { filterIndexed, mapIndexed } from 'src/utils/commonUtils';
import { COLS_PER_HOUR_MTD, GRID_CELL_HEIGHT_MTD, GRID_CELL_WIDTH_MTD } from '../constants';

export const dateToColMTD = (weekday: number): number => weekday + 1;

export const eventToGridMTDFactory: (technicians: UserResponse[]) => EventToGridRawFunc =
  (technicians) => (event, gridSettings) => {
    const { START_MINUTE } = gridSettings;

    const startMinute = getAllMinutes(event.start);
    const endMinute = getAllMinutes(event.end);
    const durationInHours = snapToHalfHour(endMinute - startMinute) / 60;

    const timeFromStartInHours = snapToHalfHour(startMinute - START_MINUTE) / 60;

    const row = !event.technicianId
      ? 1
      : technicians.findIndex((technician) => technician.id === event.technicianId) + 2;
    const rowSpan = 1;

    // + 1 - because we start from 1
    const col = timeFromStartInHours * COLS_PER_HOUR_MTD + 1;
    const colSpan = durationInHours * COLS_PER_HOUR_MTD;

    return {
      row,
      rowSpan,
      col,
      colSpan,
    };
  };

export const gridToTimeSpanMTDFactory: (date: Date) => GridToTimeSpanRawFunc = (date) => (options, gridSettings) => {
  const { start, col, end } = options;
  const { START_MINUTE } = gridSettings;

  const duration = getAllMinutes(end) - getAllMinutes(start);
  const startOfEventDay = startOfDay(start);
  const startMinute = snapToHalfHour(START_MINUTE + (1 / COLS_PER_HOUR_MTD) * (col - 1) * 60);
  const eventDayWithTime = addMinutes(startOfEventDay, startMinute);

  const newStartDate = setDate(eventDayWithTime, getDate(date));
  const newEndDate = addMinutes(newStartDate, duration);

  return {
    start: newStartDate,
    end: newEndDate,
  };
};

export const splitIntoColBorders = (startHour: number, endHour: number, hoursPerCol: number): [number, number][] => {
  const hours = range(startHour, endHour + 1);
  const borderHours = filterIndexed((_, index) => index % hoursPerCol === 0, hours);
  const borders = pipe(
    mapIndexed((v, i) => (i === 0 || i === borderHours.length - 1 ? [v] : [v, v])),
    flatten as (...args: unknown[]) => number[],
    splitEvery(2),
  )(borderHours) as [number, number][];

  return borders;
};

export const getGridCellDimensionsMTD: GetGridCellDimensionsRawFunc = () => {
  return {
    columnWidth: GRID_CELL_WIDTH_MTD,
    rowHeight: GRID_CELL_HEIGHT_MTD,
  };
};
