import clsx from 'clsx';
import { FC } from 'react';
import ColorAvatar from '../ColorAvatar';

export type TechnicianAvatarProps = {
  className?: string;
  hideName?: boolean;
  name: string;
};

const TechnicianAvatar: FC<TechnicianAvatarProps> = ({ hideName, className, name }) => {
  return (
    <div className={clsx('flex items-center gap-2', className)}>
      <ColorAvatar name={name} className='rounded-full border-none' />
      {!hideName && <span>{name}</span>}
    </div>
  );
};

export default TechnicianAvatar;
