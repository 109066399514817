import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { EmployeeResponse } from 'src/api';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

export type UseDeleteContactEmployeeData = {
  employeeId: number;
  contactId: number;
};

const useDeleteContactEmployee = (): UseMutationResult<EmployeeResponse, unknown, UseDeleteContactEmployeeData> => {
  const client = useApiClient();
  const queryClient = useQueryClient();

  return useMutation<EmployeeResponse, unknown, UseDeleteContactEmployeeData>(
    async ({ employeeId, contactId }) => {
      const data = await client.employee.deleteEmployee({ contactId, id: employeeId });
      return data;
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(queryKeysFactory.employees.list(variables.contactId).queryKey);
        queryClient.invalidateQueries(
          queryKeysFactory.employees.detail(variables.contactId, variables.employeeId).queryKey,
        );

        toast(<FormattedMessage id='app.toasts.delete_success' />, {
          type: 'success',
        });
      },
      onError: () => {
        toast(<FormattedMessage id='app.toasts.delete_success' />, {
          type: 'error',
        });
      },
    },
  );
};

export default useDeleteContactEmployee;
