import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { UserResponse } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

const useDeleteUser = (): UseMutationResult<UserResponse, unknown, number> => {
  const client = useApiClient();
  const queryClient = useQueryClient();

  return useMutation<UserResponse, unknown, number>(
    async (userId: number) => {
      const data = await client.user.deleteUser({ id: userId });
      return data;
    },
    {
      onSuccess: (_, userId) => {
        queryClient.invalidateQueries(queryKeysFactory.users.list.queryKey);
        queryClient.invalidateQueries(queryKeysFactory.users.detail(userId));
        queryClient.invalidateQueries(queryKeysFactory.tenant.current.queryKey);
        toast(<FormattedMessage id='app.toasts.delete_success' defaultMessage='Záznam úspěšně smazan' />, {
          type: 'success',
        });
      },
      onError: () => {
        toast(<FormattedMessage id='app.toasts.delete_fail' defaultMessage='Chyba smazani záznamu' />, {
          type: 'error',
        });
      },
    },
  );
};

export default useDeleteUser;
