import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query';
import { SavedOrderFormResponse } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

type QueryKey = ReturnType<typeof queryKeysFactory.savedOrderForms.detail>['queryKey'];
type QueryOptions = Omit<
  UseQueryOptions<SavedOrderFormResponse, unknown, SavedOrderFormResponse, QueryKey>,
  'queryKey' | 'queryFn' | 'initialData'
>;

const useSavedOrderForm = (orderId: number, options?: QueryOptions): UseQueryResult<SavedOrderFormResponse> => {
  const client = useApiClient();
  const { queryKey } = queryKeysFactory.savedOrderForms.detail(orderId);

  return useQuery(queryKey, () => client.savedOrderForms.findOne({ orderId }), {
    refetchInterval: false,
    staleTime: Infinity,
    ...options,
  });
};

export default useSavedOrderForm;
