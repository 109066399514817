import { FC } from 'react';
import { twMerge } from 'tailwind-merge';

export type DividerProps = {
  className?: string;
};

const Divider: FC<DividerProps> = ({ className }) => {
  return <hr className={twMerge('border-t border-gray-200 my-4 block', className)} />;
};

export default Divider;
