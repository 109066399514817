import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { EmployeeListResponse } from 'src/api';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

type QueryKey = ReturnType<typeof queryKeysFactory.employees.list>['queryKey'];
type QueryOptions = Omit<
  UseQueryOptions<EmployeeListResponse[], unknown, EmployeeListResponse[], QueryKey>,
  'queryKey' | 'queryFn' | 'initialData'
>;

const useContactEmployees = (contactId: number, options: QueryOptions = {}): UseQueryResult<EmployeeListResponse[]> => {
  const client = useApiClient();
  const { queryKey } = queryKeysFactory.employees.list(contactId);

  return useQuery<EmployeeListResponse[], unknown, EmployeeListResponse[], QueryKey>(
    queryKey,
    async () => {
      const data = client.employee.getEmployeeList({ contactId });
      return data;
    },
    {
      refetchInterval: false,
      staleTime: 1000 * 60 * 5,
      ...options,
    },
  );
};

export default useContactEmployees;
