import { CreateMaterialRequest } from 'src/api';
import { z } from 'zod';

export type CreateMaterialFormValues = CreateMaterialRequest;

export const MaterialSchema = z.object({
  materialValue: z.number().default(0),
  materialValueEur: z.number().nullable().default(0),
  materialName: z.string().min(1),
  materialCode: z.string().min(1),
  supplierCode: z.string().nullable(),
});

export type MaterialSchema = z.infer<typeof MaterialSchema>;
