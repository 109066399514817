import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { PaginationState } from '@tanstack/react-table';
import { DeliveryRatePageResponse } from 'src/api/.gen';
import { SortingQuery } from 'src/api/types/shared/sorting';
import { useApiClient } from 'src/api/useApiClient';
import { DEFAULT_TABLE_PAGINATION } from 'src/constants/table';
import queryKeysFactory from 'src/store/queryKeysFactory';
import { DeliveryRateFilter } from 'src/types/filters';

const useDeliveryRates = (
  filters?: DeliveryRateFilter,
  sorting?: SortingQuery,
  pagination: PaginationState = DEFAULT_TABLE_PAGINATION,
): UseQueryResult<DeliveryRatePageResponse> => {
  const client = useApiClient();
  const { queryKey } = queryKeysFactory.deliveryRates.list._ctx.search(filters, sorting, pagination);

  return useQuery(
    queryKey,
    async () => {
      const data = await client.deliveryRates.getDeliveryRates({
        ...filters,
        ...sorting,
        ...pagination,
      });
      return data;
    },
    {
      refetchInterval: false,
      staleTime: 1000 * 60 * 5,
    },
  );
};

export default useDeliveryRates;
