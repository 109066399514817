import { Dispatch, SetStateAction, useCallback, useState } from 'react';

export type UsePageBaseReturn = {
  isFilterOpen: boolean;
  setIsFilterOpen: Dispatch<SetStateAction<boolean>>;
  isPopupOpen: boolean;
  setIsPopupOpen: Dispatch<SetStateAction<boolean>>;

  handleClosePopup: () => void;
  handleOpenPopup: () => void;
  handleToggleFilter: () => void;
};

const usePageBaseState = (): UsePageBaseReturn => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleClosePopup = useCallback(() => setIsPopupOpen(false), []);
  const handleOpenPopup = useCallback(() => setIsPopupOpen(true), []);
  const handleToggleFilter = useCallback(() => setIsFilterOpen((prev) => !prev), []);

  return {
    isFilterOpen,
    isPopupOpen,
    setIsFilterOpen,

    setIsPopupOpen,
    handleClosePopup,
    handleOpenPopup,
    handleToggleFilter,
  };
};

export default usePageBaseState;
