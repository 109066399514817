import { FC } from 'react';
import clsx from 'clsx';
import useContactProduct from 'src/api/hooks/queries/useContactProduct';
import useContactBranch from 'src/api/hooks/queries/useContactBranch';
import Loader from 'src/components/utils/Loader';
import { Navigate } from 'react-router-dom';
import ProductPreview from '../ProductPreview';
import HyperLink from 'src/components/common/HyperLink';
import { FormattedMessage } from 'react-intl';
import { ArrowLongRightIcon } from '@heroicons/react/24/outline';

export type ProductListItemDetailProps = {
  productId: number;
  branchId?: number;
  className?: string;
};

const ProductListItemDetail: FC<ProductListItemDetailProps> = ({ className, branchId, productId }) => {
  const { data, isFetching } = useContactProduct(productId);
  const { data: branch, isFetching: isFetchnigBranch } = useContactBranch(branchId ?? 0, {
    enabled: !!branchId,
  });

  if (isFetching || isFetchnigBranch) return <Loader />;
  if (!data) return <Navigate to='/404' />;

  return (
    <div className={clsx('flex flex-col', className)}>
      <ProductPreview noTitle data={data} branch={branch} />
      <HyperLink className='flex self-end mt-4 font-semibold' href={`/product/${productId}`}>
        <FormattedMessage id='app.button.detail' />
        <ArrowLongRightIcon className='block ml-2 h-6' aria-hidden='true' />
      </HyperLink>
    </div>
  );
};

ProductListItemDetail.displayName = 'ProductListItemDetail';

export default ProductListItemDetail;
