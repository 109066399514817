import { useQuery } from '@tanstack/react-query';
import { useCallback, useId, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import useDebounce from 'src/hooks/useDebounce';
import queryKeysFactory from 'src/store/queryKeysFactory';
import { InputWithSuggestionsBase, InputWithSuggestionsBaseProps } from '../InputWithSuggestions/InputWithSuggestions';

export type InputWithSuggestionsBeProps<T> = Omit<InputWithSuggestionsBaseProps<T>, 'options'> & {
  getOptions: (query: string) => Promise<Array<{ label: string; value: T }>>;
};

const InputWithSuggestionsBe = <T,>({ getOptions, onChange, ...rest }: InputWithSuggestionsBeProps<T>): JSX.Element => {
  const id = useId();
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce<string>(query, 500);

  const handleChange = useCallback((value: string) => {
    setQuery(value);
    onChange(value);
  }, []);

  const { queryKey } = queryKeysFactory.autocomplete.detail(id, debouncedQuery);
  const {
    data: options,
    isFetching,
    isPlaceholderData,
  } = useQuery(queryKey, () => getOptions(debouncedQuery), { enabled: !!debouncedQuery, placeholderData: null });

  const notFoundMessage = useMemo(() => {
    if (isPlaceholderData || isFetching) return <FormattedMessage id='app.common.loading' />;
    return <FormattedMessage id='app.autocomplete.nothing_found' />;
  }, [isFetching, isPlaceholderData]);

  return (
    <InputWithSuggestionsBase
      {...rest}
      notFoundMessage={notFoundMessage}
      onChange={handleChange}
      options={options ?? []}
    />
  );
};

InputWithSuggestionsBe.displayName = 'InputWithSuggestionsBe';

export default InputWithSuggestionsBe;
