import { FC } from 'react';
import clsx from 'clsx';
import useUserFnsLocale from 'src/api/hooks/queries/useUserFnsLocale';
import { eachDayOfInterval, endOfWeek, format, startOfWeek } from 'date-fns';

export type WeekDaysHeaderProps = {
  className?: string;
};

const WeekDaysHeader: FC<WeekDaysHeaderProps> = ({ className }) => {
  const { data: locale } = useUserFnsLocale();

  const start = startOfWeek(new Date(), { locale, weekStartsOn: 1 });
  const end = endOfWeek(new Date(), { locale, weekStartsOn: 1 });
  const daysInWeek = eachDayOfInterval({ start, end });

  return (
    <div className={clsx(className, 'w-full h-11 flex border-b-2 border-neutral-300')}>
      <div className='w-24 border-r-2 border-neutral-300'></div>
      {daysInWeek.map((day, index) => (
        <div
          key={day.getTime()}
          className={clsx(
            'flex h-full items-center flex-1',
            index < daysInWeek.length - 1 && 'border-r border-neutral-300',
          )}
        >
          <span className={clsx('flex flex-1 justify-center items-center text-lg')}>
            {format(day, 'EEE', { locale }).slice(0, 2)}
          </span>
        </div>
      ))}
    </div>
  );
};

WeekDaysHeader.displayName = 'WeekDaysHeader';

export default WeekDaysHeader;
