import { OrderStatusResponse } from 'src/api';
import { match } from 'ts-pattern';

export const getStatusTextClassNames = (name: OrderStatusResponse.name): string =>
  match(name)
    .with(OrderStatusResponse.name.PLANNED, () => 'text-black')
    .with(OrderStatusResponse.name.INVOICED, () => 'text-black')
    .with(OrderStatusResponse.name.COMPLETED, () => 'text-black')
    .with(OrderStatusResponse.name.IN_PROGRESS, () => 'text-black')
    .with(OrderStatusResponse.name.UNSUCCESSFUL, () => 'text-white')
    .with(OrderStatusResponse.name.DELETED, () => 'text-black')
    .with(OrderStatusResponse.name.ROUTINE, () => 'text-white')
    .exhaustive();

export const getStatusBackgroundClassNames = (name: OrderStatusResponse.name): string =>
  match(name)
    .with(OrderStatusResponse.name.PLANNED, () => 'bg-yellow-400')
    .with(OrderStatusResponse.name.INVOICED, () => 'bg-blue-300')
    .with(OrderStatusResponse.name.COMPLETED, () => 'bg-lime-400')
    .with(OrderStatusResponse.name.IN_PROGRESS, () => 'bg-orange-400')
    .with(OrderStatusResponse.name.UNSUCCESSFUL, () => 'bg-red-500')
    .with(OrderStatusResponse.name.DELETED, () => 'bg-neutral-600')
    .with(OrderStatusResponse.name.ROUTINE, () => 'bg-purple-400')
    .exhaustive();
