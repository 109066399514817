import clsx from 'clsx';
import { FC, ReactNode, useCallback, useMemo, useState } from 'react';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { OrderProductResponse, OrderResponse } from 'src/api';
import Button from 'src/components/Button';
import ParametersRow from 'src/components/common/ParametersRow';
import Tabs from 'src/components/common/Tabs';
import Title from 'src/components/Title';
import { OrderFinishSchema } from './schema';
import GeneralInfoTab from './tabs/GeneralInfoTab';
import ProductTab from './tabs/ProductTab';
import useSaveOrderForm from 'src/api/hooks/mutations/savedOrderForms/useSaveOrderForm';
import useEvent from 'src/hooks/useEvent';
import { sanitizeForm } from './helpers';

export type FinishOrderFormProps = {
  order: OrderResponse;
  onSubmit: SubmitHandler<OrderFinishSchema>;
  className?: string;
};

type FinishFormTabValue = 'info' | `product-${number}-${number}`;

const createTabs = (
  orderProducts: OrderProductResponse[],
): Array<{ renderLabel: () => ReactNode; value: FinishFormTabValue }> => {
  const base = [
    {
      renderLabel: () => <FormattedMessage id='app.finish_order.preview' />,
      value: 'info' as const,
    },
  ];

  const productsTabs = orderProducts.map((op, index) => ({
    renderLabel: () => <FormattedMessage id='app.order.section_title.product' values={{ value: index + 1 }} />,
    value: `product-${op.productId}-${index}` as const,
  }));

  return [...base, ...productsTabs];
};

const FinishOrderForm: FC<FinishOrderFormProps> = ({ order, onSubmit, className }) => {
  const navigate = useNavigate();
  const back = useCallback(() => navigate(-1), []);
  const saveOrderForm = useSaveOrderForm();

  const [tab, setTab] = useState('info');
  const tabs = useMemo(() => createTabs(order?.orderProducts), [order]);

  const {
    handleSubmit,
    getValues,
    // formState: { isValid },
  } = useFormContext<OrderFinishSchema>();

  const handleSaveOrderForm = useEvent(() => {
    const form = getValues();
    const clearForm = sanitizeForm(form);
    const jsonString = JSON.stringify(clearForm);

    saveOrderForm.mutate({
      orderId: order.id,
      jsonString,
    });
  });

  const [, selectedProductId, selectedProductIndex] = tab.split('-').map(Number);

  const hideMaterials = order?.orderType?.slConfig?.hideMaterials;

  return (
    <form className={clsx(className, 'flex flex-col')} onSubmit={handleSubmit(onSubmit, console.error)}>
      <div className='flex mb-7 gap-x-4 w-full justify-between items-center'>
        <Title className='text-4xl'>
          <FormattedMessage id='app.order.section_title.order' />
        </Title>
        <Button className='w-fit min-w-max' onClick={handleSaveOrderForm}>
          <FormattedMessage id='app.buttons.save' />
        </Button>
      </div>
      <ParametersRow.Description>
        <FormattedMessage id='app.order.section_description.order' />
      </ParametersRow.Description>

      <Tabs className='mb-16' value={tab} tabs={tabs} onChange={(v) => setTab(v)} />

      {tab === 'info' && <GeneralInfoTab order={order} />}
      {tab.startsWith('product') && (
        <ProductTab
          key={selectedProductId}
          order={order}
          orderProduct={order?.orderProducts?.find((op) => op.productId === selectedProductId) as OrderProductResponse}
          orderProductId={selectedProductId}
          orderProductIndex={selectedProductIndex}
          hideMaterials={hideMaterials}
        />
      )}

      <div className='flex gap-x-6 mt-16'>
        <Button variant='secondary' className='w-full' onClick={back}>
          <FormattedMessage id='app.buttons.back' />
        </Button>
        <Button type='submit' className='w-full'>
          <FormattedMessage id='app.buttons.finish' />
        </Button>
      </div>
    </form>
  );
};

FinishOrderForm.displayName = 'FinishOrderForm';

export default FinishOrderForm;
