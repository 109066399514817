import { SectionSchemaResponse } from 'src/api';
import FieldWrapper from 'src/pages/TechnicianOrderFinishPage/components/FieldWrapper';
import { ArrayElement } from 'src/types/commonTypes';
import RSoRString from '../RSoRString';
import GenField from './GenField';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

export type GenFieldWithLabelProps = {
  field: ArrayElement<SectionSchemaResponse['fields']>;
  baseName?: string;
  className?: string;
};

const GenFieldWithLabel: FC<GenFieldWithLabelProps> = ({ field, baseName, className }) => {
  const { watch } = useFormContext();
  const showIfName = getShowIfName(field, baseName);
  const showIfValues = field.showIfValues;
  const showIfFieldValue = showIfName && watch(showIfName);
  const valueMatch = showIfValues?.includes(showIfFieldValue);
  const emptyLabelInPDF = field.emptyLabelInPDF;

  if (showIfName && !valueMatch) return null;

  return (
    <FieldWrapper
      asRow={field.type === 'checkbox'}
      reversed={field.type === 'checkbox'}
      label={emptyLabelInPDF ? null : <RSoRString label={field.label} />}
      className={className}
    >
      <GenField field={field} baseName={baseName} />
    </FieldWrapper>
  );
};

export default GenFieldWithLabel;

function getShowIfName(field: ArrayElement<SectionSchemaResponse['fields']>, baseName?: string): string | undefined {
  if (!field.showIfName) return undefined;
  return baseName ? `${baseName}.${field.showIfName}` : field.showIfName;
}
