import { FC, HTMLAttributes, useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Navigate, useParams } from 'react-router-dom';
import { UpdateProductRequest } from 'src/api';
import useUpdateProduct from 'src/api/hooks/mutations/products/useUpdateProduct';
import useContactProduct from 'src/api/hooks/queries/useContactProduct';
import AutoPopup from 'src/components/common/AutoPopup';
import NewItemPopupFooter from 'src/components/common/NewItemPopupFooter';
import DescriptionField from 'src/components/fields/DescriptionField';
import Loader from 'src/components/utils/Loader';
import useClosePopup from 'src/hooks/popups/useClosePopup';

export type UpdateProductDocumentationPopupProps = {
  //
} & HTMLAttributes<HTMLDivElement>;

const UpdateProductDocumentationPopup: FC<UpdateProductDocumentationPopupProps> = ({ className }) => {
  const productId = Number(useParams().productId);

  const { data: product, isLoading } = useContactProduct(productId);
  const { handleSubmit, control, setValue } = useForm<UpdateProductRequest>({ defaultValues: product });
  const updateProduct = useUpdateProduct();

  const closePopup = useClosePopup('update_product_documentation_note');

  useEffect(() => {
    setValue('productNote', product?.productNote);
  }, [product]);

  if (isLoading) return <Loader />;
  if (!product) return <Navigate to='/500' />;

  const onSubmit: SubmitHandler<UpdateProductRequest> = (data) => {
    updateProduct.mutate({
      ...product,
      ...data,
    });
    closePopup();
  };

  return (
    <AutoPopup
      id='update_product_documentation_note'
      title={<FormattedMessage id='app.contact_page.update_product_documentation_note.popup_title' />}
      className={className}
    >
      <form className='flex flex-col' onSubmit={handleSubmit(onSubmit)}>
        <span className='mb-6'>
          <FormattedMessage id='app.product.documentation_note' />
        </span>
        <Controller control={control} name='productNote' render={({ field }) => <DescriptionField {...field} />} />
        <NewItemPopupFooter />
      </form>
    </AutoPopup>
  );
};

UpdateProductDocumentationPopup.displayName = 'UpdateProductDocumentationPopup';

export default UpdateProductDocumentationPopup;
