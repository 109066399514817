import React, { forwardRef } from 'react';
import { FieldValues, FieldPath, UseControllerProps, Controller } from 'react-hook-form';
import { FieldProps } from 'src/types/commonTypes';
import { twMerge } from 'tailwind-merge';
import FieldLabel from '../FieldLabel';
import FieldError from '../FieldError';
import { getFieldClassName } from '../helpers';

export type DescriptionFieldProps = FieldProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement,
  string | number | null
> & {
  className?: string;
  labelTextClassName?: string;
  labelClassName?: string;
  hideErrorMessage?: boolean;
  inputClassName?: string;
  error?: string;
  asterisk?: boolean;
};

const DescriptionField = forwardRef<HTMLTextAreaElement, DescriptionFieldProps>((props, ref) => {
  const {
    value,
    disabled,
    inputClassName,
    hideErrorMessage,
    className,
    labelClassName,
    label,
    labelTextClassName,
    error,
    asterisk,
    ...rest
  } = props;

  return (
    <label className={twMerge('flex flex-col w-full gap-y-1', className)}>
      {label && (
        <FieldLabel asterisk={asterisk} className={labelClassName} textClassName={labelTextClassName}>
          {label}
        </FieldLabel>
      )}
      <textarea
        className={getFieldClassName({ disabled, error }, inputClassName, 'h-20')}
        value={value ?? ''}
        {...rest}
        ref={ref}
      ></textarea>
      {!hideErrorMessage && error && <FieldError>{error}</FieldError>}
    </label>
  );
});

export default DescriptionField;

type DescriptionFieldControlledProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<DescriptionFieldProps, 'value' | 'onChange' | 'name' | 'error'> & {
  control: UseControllerProps<TFieldValues, TName>['control'];
  name: TName;
};

export const DescriptionFieldControlled = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  ...textFieldProps
}: DescriptionFieldControlledProps<TFieldValues, TName>): JSX.Element => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <DescriptionField {...textFieldProps} {...field} name={name} error={error?.message} />
      )}
    />
  );
};
