import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { WorkTariffResponse } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

type QueryKey = (typeof queryKeysFactory.workTariffs.list)['queryKey'];
type QueryOptions = Omit<
  UseQueryOptions<WorkTariffResponse[], unknown, WorkTariffResponse[], QueryKey>,
  'queryKey' | 'queryFn' | 'initialData'
>;

const useAllWorkTariffs = (options?: QueryOptions): UseQueryResult<WorkTariffResponse[]> => {
  const client = useApiClient();
  const { queryKey } = queryKeysFactory.workTariffs.list;

  return useQuery(
    queryKey,
    async () => {
      const data = await client.workTariffs.getAllWorkTariffs();
      return data;
    },
    {
      refetchInterval: false,
      staleTime: 1000 * 60 * 5,
      ...options,
    },
  );
};

export default useAllWorkTariffs;
