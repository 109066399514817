import { set } from 'date-fns';
import { GridEvent } from 'src/components/EventsGrid/types';
import { OrderSchema } from '../ServiceForm/schema';

export function getDefaultPotentialEvents(defaultValues: Partial<OrderSchema> | undefined): GridEvent[] {
  if (!defaultValues) return [];

  const { technicianIds, _date, _start, _end } = defaultValues;
  if (!_date || !_start || !_end || !technicianIds?.length) return [];

  try {
    const start = set(_date, getHoursAndMinutesFromValue(_start));
    const end = set(_date, getHoursAndMinutesFromValue(_end));

    const potentialEvents = technicianIds.map(
      ({ technicianId }, index) =>
        ({
          id: -index,
          technicianId,
          start,
          end,
          isPotential: true,
          data: {
            technicians: technicianIds.map((t) => ({ id: t })),
          },
        } as unknown as GridEvent),
    );

    return potentialEvents;
  } catch (error) {
    return [];
  }
}

export function getHoursAndMinutesFromValue(value: string): { hours: number; minutes: number } {
  const [hoursStr, minutesStr] = value.split(':');

  if (!hoursStr || !minutesStr) {
    throw new Error('Invalid time format');
  }

  const hours = Number.parseInt(hoursStr);
  const minutes = Number.parseInt(minutesStr);

  return { hours, minutes };
}
