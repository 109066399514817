import { CellContext } from '@tanstack/react-table';
import { FC, useState } from 'react';
import { OrderListItemResponse } from 'src/api';
import TechnicianAvatar from 'src/components/TechnicianAvatar/TechnicianAvatar';
import TechnicianAvatarsStack from 'src/components/TechnicianAvatarsStack/TechnicianAvatarsStack';
import Tooltip from 'src/components/common/Tooltip';

export type TechniciansCellProps = {
  info: CellContext<OrderListItemResponse, unknown>;
};

const TechniciansCell: FC<TechniciansCellProps> = ({ info }) => {
  const [open, setOpen] = useState(false);
  const names = (info.row.original.technicians?.map((i) => i.profile?.name).filter(Boolean) ?? []) as string[];

  if (open) {
    return (
      <div className={'flex flex-col gap-y-4'}>
        {names.map((name, index) => (
          <TechnicianAvatar key={index} name={name} />
        ))}
      </div>
    );
  }

  return (
    <div className='flex items-center gap-1 relative flex-nowrap'>
      <TechnicianAvatarsStack names={names} />
      <span className='whitespace-nowrap overflow-hidden'>{names[0]}</span>
      {names.length > 1 && (
        <Tooltip text={<span className='block'>{names.join(', ')}</span>}>
          <span onClick={() => setOpen(true)} className='w-10 block'>{`...+${names.length - 1}`}</span>
        </Tooltip>
      )}
    </div>
  );
};

export default TechniciansCell;
