import clsx from 'clsx';
import { FC } from 'react';
import MonthTabHeader from './components/MonthTabHeader';
import MonthTabGrid from './components/MonthTabGrid';

const MonthTab: FC = () => {
  return (
    <div className={clsx('flex flex-col border border-neutral-300 overflow-hidden rounded')}>
      <MonthTabHeader />
      <MonthTabGrid />
    </div>
  );
};

MonthTab.displayName = 'MonthTab';

export default MonthTab;
