import { FC, useCallback } from 'react';
import useAddOrderType from 'src/api/hooks/mutations/orderTypes/useAddOrderType';
import { SubmitHandler } from 'react-hook-form';
import { CreateOrderTypeRequest } from 'src/api';
import { usePopupContext } from 'src/components/Popup/hook';
import OrderTypeForm from '../OrderTypeForm';

export type AddOrderTypeFormProps = {
  className?: string;
};

const defaultValues = {
  name: '',
  slConfig: '{}',
  pdfTemplate: '',
};

const AddOrderTypeForm: FC<AddOrderTypeFormProps> = ({ className }) => {
  const addOrderType = useAddOrderType();
  const { onClose } = usePopupContext();

  const onSubmit: SubmitHandler<CreateOrderTypeRequest> = useCallback(
    (data) => {
      addOrderType.mutate(data);
      onClose();
    },
    [addOrderType, onClose],
  );

  return <OrderTypeForm onSubmit={onSubmit} defaultValues={defaultValues} className={className} />;
};

AddOrderTypeForm.displayName = 'AddOrderTypeForm';

export default AddOrderTypeForm;
