import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { PaginationState } from '@tanstack/react-table';
import { RoutineServicesPageResponse } from 'src/api';
import { SortingQuery } from 'src/api/types/shared/sorting';
import { useApiClient } from 'src/api/useApiClient';
import { DEFAULT_TABLE_PAGINATION } from 'src/constants/table';
import queryKeysFactory from 'src/store/queryKeysFactory';
import { RoutineServiceFilter } from 'src/types/filters';

const useRoutineServices = (
  filters?: RoutineServiceFilter,
  sorting?: SortingQuery,
  pagination: PaginationState = DEFAULT_TABLE_PAGINATION,
): UseQueryResult<RoutineServicesPageResponse> => {
  const client = useApiClient();
  const { queryKey } = queryKeysFactory.routineServices.list._ctx.search(filters, sorting, pagination);
  return useQuery(
    queryKey,
    async () => {
      const data = await client.routineServices.getRoutineServicesList({
        ...filters,
        ...sorting,
        ...pagination,
      });
      return data;
    },
    {
      refetchInterval: false,
      staleTime: 1000 * 60 * 5,
    },
  );
};

export default useRoutineServices;
