import { Updater } from '@tanstack/react-table';
import { CALENDAR_TECHNICIANS_IDS_PARAM_KEY } from '../constants';
import useNumberArraySearchParam from 'src/hooks/router/useNumberArraySearchParam';

const useCalendarTechnicianIdsParam = ({ defaultValue }: { defaultValue?: number[] } = {}): readonly [
  number[],
  (value: Updater<number[] | null>) => void,
] => useNumberArraySearchParam(CALENDAR_TECHNICIANS_IDS_PARAM_KEY, { defaultValue });

export default useCalendarTechnicianIdsParam;
