import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { ProductTemplateResponse } from 'src/api/.gen/models/ProductTemplateResponse';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

const useAllProductTemplates = (): UseQueryResult<ProductTemplateResponse[]> => {
  const client = useApiClient();
  const { queryKey } = queryKeysFactory.productTemplates.list;

  return useQuery(
    queryKey,
    async () => {
      const data = await client.productTemplate.getAllProductTemplates();
      return data;
    },
    {
      refetchInterval: false,
      staleTime: 1000 * 60 * 5,
    },
  );
};

export default useAllProductTemplates;
