/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ContactResponse = {
  id: number;
  vat: string | null;
  companyName: string;
  in: string | null;
  internalCategory: string | null;
  email: string;
  city: string;
  street: string;
  zip: string;
  country: string | null;
  category: ContactResponse.category;
  phoneNumber: string;
  notification: boolean;
  preferredCurrency: string;
  preferredLanguage: string | null;
  contractNumber: string | null;
  updatedAt: string;
  deletedAt: string | null;
};
export namespace ContactResponse {
  export enum category {
    B2B = 'B2B',
    B2C = 'B2C',
  }
}
