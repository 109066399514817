import { RefObject, useEffect } from 'react';

export type UseScrollToHourOptions = {
  autoScrollToHour?: number;
  scrollContainerRef?: RefObject<HTMLElement>;
  scrollTopOffest?: number;
};

const useScrollToHour = ({ autoScrollToHour, scrollContainerRef, scrollTopOffest }: UseScrollToHourOptions): void => {
  useEffect(() => {
    if (!autoScrollToHour) return;

    const el = document.querySelector(`[data-events-grid=hour-${autoScrollToHour}]`);
    if (!el) return;

    if (!scrollContainerRef?.current) return el.scrollIntoView();

    const { top: containerTop } = scrollContainerRef.current.getBoundingClientRect();
    const { top: hourTop } = el.getBoundingClientRect();

    if (hourTop <= containerTop + (scrollTopOffest ?? 0)) return;

    scrollContainerRef.current.scroll(0, hourTop - containerTop - (scrollTopOffest ?? 0));
  }, []);
};

export default useScrollToHour;
