import { FC } from 'react';
import { OrderNumberFormatParts, orderFormatPartsToNumber } from './helpers';
import clsx from 'clsx';
import { ErrorBoundary } from 'react-error-boundary';
import { FormattedMessage } from 'react-intl';
import { sprintf } from 'sprintf-js';

export type OrderFormatPreviewProps = {
  parts: OrderNumberFormatParts;
  className?: string;
};

const OrderFormatPreview: FC<OrderFormatPreviewProps> = ({ parts, className }) => {
  const format = orderFormatPartsToNumber(parts);

  return (
    <ErrorBoundary fallback={null}>
      <div className={clsx(className, 'flex flex-col')}>
        <span className='font-semibold text-xl mb-2'>
          <FormattedMessage id='app.settings.order_type.update_order_format_popup.example.title' />
        </span>
        <span>{sprintf(format, 1)}</span>
        <span>{sprintf(format, 2)}</span>
        <span>{sprintf(format, 3)}</span>
      </div>
    </ErrorBoundary>
  );
};

export default OrderFormatPreview;
