import { path } from 'ramda';
import { FC } from 'react';
import useUpdateOrderTypeConfigField from 'src/api/hooks/mutations/orderTypes/useUpdateOrderTypeConfigField';
import useOrderType from 'src/api/hooks/queries/useOrderType';
import Toggle from 'src/components/Toggle';

export type OrderTypeFieldToggleProps = {
  className?: string;
  name: string;
  orderTypeId: number;
};

const OrderTypeFieldToggle: FC<OrderTypeFieldToggleProps> = ({ name, className, orderTypeId }) => {
  const updateMutation = useUpdateOrderTypeConfigField();
  const { data: orderTye } = useOrderType(orderTypeId);
  const config = orderTye?.slConfig ?? {};
  const value = path(name.split('.'), config);

  return (
    <Toggle
      className={className}
      value={!!value}
      onChange={(value) => {
        updateMutation.mutate({
          id: orderTypeId,
          path: name,
          value,
        });
      }}
    />
  );
};

export default OrderTypeFieldToggle;
