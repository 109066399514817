import { useEffect, useState } from 'react';

export const getRemSize = (): number => parseInt(window.getComputedStyle(document.documentElement).fontSize);

const useRemSize = (): number => {
  const [remSize, setRemSize] = useState(getRemSize);

  useEffect(() => {
    const handleResize = (): void => setRemSize(getRemSize());

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return remSize;
};

export default useRemSize;
