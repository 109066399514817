import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { ProductTemplateResponse, CreateProductTemplateRequest } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

const useAddProductTemplate = (): UseMutationResult<ProductTemplateResponse, unknown, CreateProductTemplateRequest> => {
  const client = useApiClient();
  const queryClient = useQueryClient();

  return useMutation<ProductTemplateResponse, unknown, CreateProductTemplateRequest>(
    async (productTemplate: CreateProductTemplateRequest) => {
      const data = await client.productTemplate.createProductTemplate({ requestBody: productTemplate });

      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeysFactory.productTemplates.list.queryKey);
        toast(<FormattedMessage id='app.toasts.add_success' />, {
          type: 'success',
        });
      },
      onError: () => {
        toast(<FormattedMessage id='app.toasts.add_fail' />, {
          type: 'error',
        });
      },
    },
  );
};

export default useAddProductTemplate;
