import { ChangeEvent, FC, useCallback, useRef } from 'react';
import clsx from 'clsx';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import useContactProduct from 'src/api/hooks/queries/useContactProduct';
import useProductDocuments from 'src/api/hooks/queries/useProductDocuments';
import useUploadFile from 'src/api/hooks/useUploadFile';
import Loader from 'src/components/utils/Loader';
import useOpenPopup from 'src/hooks/popups/useOpenPopup';
import queryKeysFactory from 'src/store/queryKeysFactory';
import { PencilSquareIcon, PlusCircleIcon } from '@heroicons/react/24/outline';
import { FormattedMessage } from 'react-intl';
import Button from 'src/components/Button';
import ParametersRow from 'src/components/common/ParametersRow';
import ProductDocumentsList from '../ContactDetailPage/ProductDocumentsList';
import UpdateProductDocumentationPopup from '../ContactDetailPage/UpdateProductDocumentationPopup';
import BackIcon from 'src/components/Icons/BackIcon';
import PageContentHeader from 'src/components/PageContentHeader';

export type ProductDocumentationPageProps = {
  className?: string;
};

const ProductDocumentationPage: FC<ProductDocumentationPageProps> = ({ className }) => {
  const productId = Number(useParams().productId);
  const navigate = useNavigate();
  const back = useCallback(() => navigate(-1), [navigate]);

  const queryClient = useQueryClient();

  const { data: product, isFetching } = useContactProduct(productId);
  const { data: documents, isLoading: isLoadingDocuments } = useProductDocuments(productId);

  const openPopup = useOpenPopup('update_product_documentation_note');

  // documents
  const uploadFile = useUploadFile(`product/${productId}/documents/file`);
  const hiddenFileInputRef = useRef<HTMLInputElement>(null);
  const handleButtonClick = useCallback(() => hiddenFileInputRef?.current?.click(), []);
  const handleFilesChange = async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
    if (!e.target.files) return;

    const files = Object.values(e.target.files);
    const requests = files.map((file) => uploadFile(file, { encodeFileName: true }));
    const responses = await Promise.all(requests);

    const getIsValidResponse = (res: AxiosResponse<unknown, unknown>): boolean => [200, 201].includes(res.status);
    const areAllValid = responses.every(getIsValidResponse);

    if (!areAllValid) return;

    queryClient.invalidateQueries(queryKeysFactory.productDocuments.list(productId));
  };

  if (isFetching) return <Loader />;
  if (!product) return <Navigate to='/404' />;

  return (
    <>
      <div className={clsx(className, 'flex flex-col w-full')}>
        <PageContentHeader
          className={className}
          title={
            <span className='flex'>
              <FormattedMessage id='app.contact_page.title' /> {' - '}
              <FormattedMessage id='app.product.documentation_title' />
            </span>
          }
          secondaryButtonText={
            <>
              <BackIcon />
              <FormattedMessage id='app.buttons.back' defaultMessage='Zpět' />
            </>
          }
          onSecondaryButtonClick={back}
          hidePrimaryButton
        />
        <ParametersRow.Title>
          <FormattedMessage id='app.product.documentation_note' />
          <Button variant='cta' size='small' onClick={openPopup}>
            <PencilSquareIcon className='h-6 mr-1' />
            <FormattedMessage id='app.buttons.edit' />
          </Button>
        </ParametersRow.Title>
        <span className='mb-20'>{product.productNote}</span>

        <ParametersRow.Title>
          <FormattedMessage id='app.product.files' />
          <Button variant='cta' size='small' className='ml-1' onClick={handleButtonClick}>
            <PlusCircleIcon className='h-6 mr-1' />
            <FormattedMessage id='app.buttons.add' />
          </Button>
        </ParametersRow.Title>

        <ProductDocumentsList data={documents} isLoading={isLoadingDocuments} />
      </div>

      <input ref={hiddenFileInputRef} type='file' className='hidden' onChange={handleFilesChange} multiple />
      <UpdateProductDocumentationPopup />
    </>
  );
};

ProductDocumentationPage.displayName = 'ProductDocumentationPage';

export default ProductDocumentationPage;
