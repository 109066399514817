import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { UpdateOrderStockConfigRequest } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

const useUpdateStockOrderConfig = (): UseMutationResult<unknown, unknown, UpdateOrderStockConfigRequest> => {
  const client = useApiClient();
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, UpdateOrderStockConfigRequest>(
    async (req) => {
      const data = await client.tenantConfigs.updateOrderStockEmail({ requestBody: req });
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeysFactory.tenant._def);
        toast(<FormattedMessage id='app.toasts.update_success' />, {
          type: 'success',
        });
      },
      onError: () => {
        toast(<FormattedMessage id='app.toasts.update_fail' />, {
          type: 'error',
        });
      },
    },
  );
};

export default useUpdateStockOrderConfig;
