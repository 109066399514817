import { ChangeEventHandler, ForwardedRef, ReactNode, forwardRef, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import FieldError from '../FieldError';
import FieldLabel from '../FieldLabel';
import { useEvent } from '@dnd-kit/utilities';
import Button from 'src/components/Button';
import { FieldValues, FieldPath, UseControllerProps, Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

export type FileFieldProps = Omit<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'onChange'
> & {
  onChange: (file: File | FileList | null) => void;
  error?: ReactNode;
  extraOnChange?: (file: File | FileList | null) => void;
  inputClassName?: string;
  label?: ReactNode;
  labelClassName?: string;
  labelTextClassName?: string;
  hideErrorMessage?: boolean;
  asterisk?: boolean;
};

export const FileField = forwardRef(
  (
    {
      label,
      labelClassName,
      labelTextClassName,
      inputClassName,
      error,
      className,
      disabled,
      extraOnChange,
      onChange,
      hideErrorMessage,
      multiple,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      value,
      ...inputProps
    }: FileFieldProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const [visibleText, setVisibleText] = useState('');
    const hiddenInputRef = useRef<HTMLInputElement>(null);

    const handleChange = useEvent<ChangeEventHandler<HTMLInputElement>>((e) => {
      const text = typeof e.target.value === 'string' ? e.target.value.replace('C:\\fakepath\\', '') : '';
      setVisibleText(text);

      if (multiple) {
        onChange?.(e.target.files);
        extraOnChange?.(e.target.files);
        return;
      }

      onChange?.(e.target.files?.[0] ?? null);
      extraOnChange?.(e.target.files?.[0] ?? null);
    });

    const handleClick = useEvent(() => {
      hiddenInputRef.current?.click();
    });

    return (
      <label className={twMerge('flex flex-col w-full gap-y-1', className)}>
        {label && (
          <FieldLabel className={labelClassName} textClassName={labelTextClassName}>
            {label}
          </FieldLabel>
        )}
        <div className='flex gap-4 items-center'>
          <Button ref={ref} onClick={handleClick} variant='secondary'>
            <FormattedMessage id='app.common.choose_file' />
          </Button>
          <span>{visibleText}</span>
        </div>
        <input
          {...inputProps}
          multiple={multiple}
          ref={hiddenInputRef}
          type='file'
          onChange={handleChange}
          disabled={disabled}
          className={twMerge('hidden', inputClassName)}
        />
        {!hideErrorMessage && error && <FieldError>{error}</FieldError>}
      </label>
    );
  },
);

type FileFieldControlledProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<FileFieldProps, 'value' | 'onChange' | 'name' | 'error'> & {
  control: UseControllerProps<TFieldValues, TName>['control'];
  name: TName;
};

export const FileFieldControlled = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  ...fileFieldProps
}: FileFieldControlledProps<TFieldValues, TName>): JSX.Element => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FileField {...fileFieldProps} {...field} name={name} error={error?.message} />
      )}
    />
  );
};
