import { UserResponse } from 'src/api';
import { z } from 'zod';

export const UserSchema = z.object({
  userEmail: z.string().email(),
  role: z.nativeEnum(UserResponse.role),
  profile: z.object({
    name: z.string().min(1),
    preferredLanguage: z.string().min(1),
  }),
});

export type UserSchema = z.infer<typeof UserSchema>;
