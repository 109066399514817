import { FC } from 'react';
import { VatTariffResponse } from 'src/api';
import { createColumnHelper } from '@tanstack/react-table';
import { FormattedMessage } from 'react-intl';
import { ExtendedColumnDef } from 'src/types/table';
import DumbTable, { DumbTableProps } from 'src/components/Table/DumbTable';
import { useNavigate } from 'react-router-dom';

export type VatTariffsTableProps = Omit<DumbTableProps<VatTariffResponse>, 'columns'>;

const columnHelper = createColumnHelper<VatTariffResponse>();

const columns: ExtendedColumnDef<VatTariffResponse>[] = [
  {
    ...columnHelper.accessor('tariffValue', {
      id: 'tariffValue',
      header: () => <FormattedMessage id='app.vat_tariff.value' />,
      enableColumnFilter: false,
      enableSorting: false,
      cell: (info) => info.renderValue(),
      size: 200,
    }),
  },
  {
    ...columnHelper.accessor('name', {
      id: 'name',
      header: () => <FormattedMessage id='app.vat.name' />,
      enableColumnFilter: false,
      enableSorting: false,
      cell: (info) => info.renderValue(),
      size: 200,
    }),
  },
  {
    ...columnHelper.accessor('deletedAt', {
      id: 'deletedAt',
      header: () => <FormattedMessage id='app.common.active' />,
      enableColumnFilter: false,
      enableSorting: false,
      cell: (info) =>
        info.getValue() ? <FormattedMessage id='app.common.no' /> : <FormattedMessage id='app.common.yes' />,
      size: 100,
    }),
  },
];

const VatTariffsTable: FC<VatTariffsTableProps> = (props) => {
  const navigate = useNavigate();

  return (
    <DumbTable
      columns={columns}
      handleRowClick={(original) => navigate(`/admin/vat-tariff/${original.id}`)}
      {...props}
    />
  );
};

VatTariffsTable.displayName = 'VatTariffsTable';

export default VatTariffsTable;
