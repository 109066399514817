import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { PaginationState } from '@tanstack/react-table';
import { BranchesPageResponse, BranchFilterQuery } from 'src/api/.gen';
import { SortingQuery } from 'src/api/types/shared/sorting';
import { useApiClient } from 'src/api/useApiClient';
import { DEFAULT_TABLE_PAGINATION } from 'src/constants/table';
import queryKeysFactory from 'src/store/queryKeysFactory';

type QueryKey = ReturnType<typeof queryKeysFactory.branches.list._ctx.search>['queryKey'];
type QueryOptions = Omit<
  UseQueryOptions<BranchesPageResponse, unknown, BranchesPageResponse, QueryKey>,
  'queryKey' | 'queryFn' | 'initialData'
>;

const useBranches = (
  filters: BranchFilterQuery,
  sorting: SortingQuery,
  pagination: PaginationState = DEFAULT_TABLE_PAGINATION,
  options: QueryOptions = {},
): UseQueryResult<BranchesPageResponse> => {
  const client = useApiClient();
  const { queryKey } = queryKeysFactory.branches.list._ctx.search(pagination, filters, sorting);

  return useQuery(
    queryKey,
    async () => {
      const data = await client.branch.getBranches({ ...filters, ...sorting, ...pagination });

      return data;
    },
    {
      refetchInterval: false,
      staleTime: 1000 * 60 * 5,
      ...options,
    },
  );
};

export default useBranches;
