/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MarkOrderSyncedRequest } from '../models/MarkOrderSyncedRequest';
import type { OrderResponse } from '../models/OrderResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ExternalSyncService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns OrderResponse Returns 10 unsynced orders
   * @throws ApiError
   */
  public getUnsyncedOrders({
    xApiKey,
  }: {
    /**
     * Tenant API key
     */
    xApiKey: string;
  }): CancelablePromise<Array<OrderResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/external-sync',
      headers: {
        'x-api-key': xApiKey,
      },
    });
  }
  /**
   * @returns void
   * @throws ApiError
   */
  public markOrdersSynced({
    xApiKey,
    requestBody,
  }: {
    /**
     * Tenant API key
     */
    xApiKey: string;
    requestBody: MarkOrderSyncedRequest;
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/external-sync/sync',
      headers: {
        'x-api-key': xApiKey,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
