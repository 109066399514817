import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { ProductListResponse } from 'src/api';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

type QueryKey = ReturnType<typeof queryKeysFactory.products.list._ctx.fromContact>['queryKey'];
type QueryOptions = Omit<
  UseQueryOptions<ProductListResponse[], unknown, ProductListResponse[], QueryKey>,
  'queryKey' | 'queryFn' | 'initialData'
>;

const useContactProducts = (contactId: number, options?: QueryOptions): UseQueryResult<ProductListResponse[]> => {
  const client = useApiClient();
  const { queryKey } = queryKeysFactory.products.list._ctx.fromContact(contactId);

  return useQuery(
    queryKey,
    async () => {
      const data = client.product.getContactProductList({ contactId });

      return data;
    },
    {
      refetchInterval: false,
      staleTime: 1000 * 60 * 5,
      ...options,
    },
  );
};

export default useContactProducts;
