import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { PaginationState } from '@tanstack/react-table';
import { UsersPageResponse } from 'src/api/.gen';
import { SortingQuery } from 'src/api/types/shared/sorting';
import { useApiClient } from 'src/api/useApiClient';
import { DEFAULT_TABLE_PAGINATION } from 'src/constants/table';
import queryKeysFactory from 'src/store/queryKeysFactory';
import { UserFilter } from 'src/types/filters';

const useUsers = (
  filters?: UserFilter,
  sorting?: SortingQuery,
  pagination: PaginationState = DEFAULT_TABLE_PAGINATION,
): UseQueryResult<UsersPageResponse> => {
  const client = useApiClient();
  const { queryKey } = queryKeysFactory.users.list._ctx.search(filters, sorting, pagination);

  return useQuery(
    queryKey,
    async () => {
      const data = await client.user.getUserList({ ...filters, ...sorting, ...pagination });
      return data;
    },
    {
      refetchInterval: false,
      staleTime: 1000 * 60 * 5,
    },
  );
};

export default useUsers;
