import { createColumnHelper } from '@tanstack/react-table';
import clsx from 'clsx';
import { props } from 'ramda';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import DumbTable from 'src/components/Table/DumbTable';
import Title from 'src/components/Title';
import { MessageId } from 'src/types/commonTypes';
import { ExtendedColumnDef } from 'src/types/table';

export type OrderTypeTranslationsTableProps = {
  className?: string;
  orderTypeId: number;
};

type OrderTypeTranslation = {
  language: string;
  id: number;
};

const columnHelper = createColumnHelper<OrderTypeTranslation>();

const columns: ExtendedColumnDef<OrderTypeTranslation>[] = [
  {
    ...columnHelper.accessor('language', {
      id: 'language',
      header: () => <FormattedMessage id='app.common.language' />,
      enableColumnFilter: false,
      enableSorting: false,
      cell: (info) => <FormattedMessage id={`app.language.${info.getValue()}` as MessageId} />,
    }),
  },
];

const data: OrderTypeTranslation[] = [
  {
    language: 'cz',
    id: 1,
  },
  {
    language: 'sk',
    id: 2,
  },
];

export const OrderTypeTranslationsTable: FC<OrderTypeTranslationsTableProps> = ({ className, orderTypeId }) => {
  const navigate = useNavigate();

  return (
    <div className={clsx(className, 'flex flex-col gap-y-10')}>
      <Title>
        <FormattedMessage id='app.navigation.translations' />
      </Title>
      <DumbTable
        noMinHeight
        data={data}
        columns={columns}
        handleRowClick={(original) => navigate(`/types-of-orders/${orderTypeId}/translations/${original.language}`)}
        {...props}
      />
    </div>
  );
};
