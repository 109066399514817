/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateProductTemplateRequest } from '../models/CreateProductTemplateRequest';
import type { ProductTemplateResponse } from '../models/ProductTemplateResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ProductTemplateService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns ProductTemplateResponse
   * @throws ApiError
   */
  public getAllProductTemplates(): CancelablePromise<Array<ProductTemplateResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/product-templates/all',
    });
  }
  /**
   * @returns ProductTemplateResponse
   * @throws ApiError
   */
  public getProductTemplate({ id }: { id: number }): CancelablePromise<Array<ProductTemplateResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/product-templates/{id}',
      path: {
        id: id,
      },
    });
  }
  /**
   * @returns ProductTemplateResponse
   * @throws ApiError
   */
  public updateProductTemplate({
    id,
    requestBody,
  }: {
    id: number;
    requestBody: CreateProductTemplateRequest;
  }): CancelablePromise<ProductTemplateResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/product-templates/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns void
   * @throws ApiError
   */
  public deleteProductTemplate({ id }: { id: number }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/product-templates/{id}',
      path: {
        id: id,
      },
    });
  }
  /**
   * @returns ProductTemplateResponse
   * @throws ApiError
   */
  public createProductTemplate({
    requestBody,
  }: {
    requestBody: CreateProductTemplateRequest;
  }): CancelablePromise<ProductTemplateResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/product-templates',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
