/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateRoutineServicesRequest } from '../models/CreateRoutineServicesRequest';
import type { RoutineServicesPageResponse } from '../models/RoutineServicesPageResponse';
import type { RoutineServicesResponse } from '../models/RoutineServicesResponse';
import type { UpdateRoutineServicesRequest } from '../models/UpdateRoutineServicesRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class RoutineServicesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns RoutineServicesPageResponse
   * @throws ApiError
   */
  public getRoutineServicesList({
    pageSize,
    pageIndex,
    routineName,
    routineValue,
    withDeleted,
    direction,
    orderBy,
  }: {
    pageSize: number;
    pageIndex: number;
    /**
     * Routine service name
     */
    routineName?: string;
    /**
     * Routine service value
     */
    routineValue?: string;
    /**
     * With deleted
     */
    withDeleted?: boolean;
    direction?: 'ASC' | 'DESC';
    orderBy?: string;
  }): CancelablePromise<RoutineServicesPageResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/routine-services',
      query: {
        routineName: routineName,
        routineValue: routineValue,
        withDeleted: withDeleted,
        direction: direction,
        orderBy: orderBy,
        pageSize: pageSize,
        pageIndex: pageIndex,
      },
    });
  }
  /**
   * @returns RoutineServicesResponse
   * @throws ApiError
   */
  public createRoutineServices({
    requestBody,
  }: {
    requestBody: CreateRoutineServicesRequest;
  }): CancelablePromise<RoutineServicesResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/routine-services',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns RoutineServicesResponse
   * @throws ApiError
   */
  public getAllRoutineServices(): CancelablePromise<Array<RoutineServicesResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/routine-services/all',
    });
  }
  /**
   * @returns RoutineServicesResponse
   * @throws ApiError
   */
  public getRoutineServices({ id }: { id: number }): CancelablePromise<RoutineServicesResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/routine-services/{id}',
      path: {
        id: id,
      },
    });
  }
  /**
   * @returns RoutineServicesResponse
   * @throws ApiError
   */
  public deleteRoutineServices({ id }: { id: number }): CancelablePromise<RoutineServicesResponse> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/routine-services/{id}',
      path: {
        id: id,
      },
    });
  }
  /**
   * @returns RoutineServicesResponse
   * @throws ApiError
   */
  public updateRoutineServices({
    id,
    requestBody,
  }: {
    id: number;
    requestBody: UpdateRoutineServicesRequest;
  }): CancelablePromise<RoutineServicesResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/routine-services/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
