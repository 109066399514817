import React, { createContext, useContext, useEffect, useState } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';

interface TechnicianAuthContextProps {
  token: string | null;
}

interface TechnicianAuthProviderProps {
  children: React.ReactNode;
}

const defaultState: TechnicianAuthContextProps = {
  token: null,
};

export const TechnicianAuthContext = createContext<TechnicianAuthContextProps>(defaultState);

export const TechnicianAuthProvider: React.FC<TechnicianAuthProviderProps> = ({ children }) => {
  const [search] = useSearchParams();
  const navigate = useNavigate();
  const [token, setToken] = useState(search.get('token'));

  useEffect(() => {
    const _token = search.get('token');
    if (_token) {
      setToken(_token);
    } else {
      navigate('/');
    }
  }, []);

  return <TechnicianAuthContext.Provider value={{ token }}>{children}</TechnicianAuthContext.Provider>;
};

export const useTechnicianAuth = (): TechnicianAuthContextProps => useContext(TechnicianAuthContext);
