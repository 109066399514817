import { FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';

import InfoPopup from './InfoPopup';
import { MainInfoPopupProps, InfoPopupContextValue, InfoPopupContext } from 'src/hooks/popups/useShowInfoPopup';

export const InfoPopupProvider: FC<PropsWithChildren> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [props, setProps] = useState<MainInfoPopupProps>({} as MainInfoPopupProps);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const contextValue = useMemo<InfoPopupContextValue>(() => ({ setOpen, props, setProps }), [setOpen, props, setProps]);

  return (
    <InfoPopupContext.Provider value={contextValue}>
      {children}
      <InfoPopup {...props} open={open} onClose={handleClose} />
    </InfoPopupContext.Provider>
  );
};
