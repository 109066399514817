/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateTranslationPackRequest } from '../models/CreateTranslationPackRequest';
import type { Object } from '../models/Object';
import type { TranslationPackListItemResponse } from '../models/TranslationPackListItemResponse';
import type { TranslationPackResponse } from '../models/TranslationPackResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class TranslationPacksService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns TranslationPackResponse
   * @throws ApiError
   */
  public getPdfTranslationsPack({ language }: { language: string }): CancelablePromise<TranslationPackResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/translation-packs/pdf/languages/{language}',
      path: {
        language: language,
      },
    });
  }
  /**
   * @returns void
   * @throws ApiError
   */
  public updateOrderTypeTranslations({
    orderTypeId,
    language,
    requestBody,
  }: {
    orderTypeId: number;
    language: string;
    requestBody: Object;
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/translation-packs/order-type/{orderTypeId}/languages/{language}',
      path: {
        orderTypeId: orderTypeId,
        language: language,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns TranslationPackListItemResponse
   * @throws ApiError
   */
  public getCustomTranslationPacksList(): CancelablePromise<Array<TranslationPackListItemResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/translation-packs/custom/languages',
    });
  }
  /**
   * @returns TranslationPackResponse
   * @throws ApiError
   */
  public getCustomTranslationPack({ language }: { language: string }): CancelablePromise<TranslationPackResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/translation-packs/custom/languages/{language}',
      path: {
        language: language,
      },
    });
  }
  /**
   * @returns TranslationPackResponse
   * @throws ApiError
   */
  public createCustomTranslationPack({
    requestBody,
  }: {
    requestBody: CreateTranslationPackRequest;
  }): CancelablePromise<TranslationPackResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/translation-packs/custom',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns TranslationPackResponse
   * @throws ApiError
   */
  public updateTenantTranslationPack({
    language,
    requestBody,
  }: {
    language: string;
    requestBody: Object;
  }): CancelablePromise<TranslationPackResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/translation-packs/custom/{language}',
      path: {
        language: language,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns TranslationPackListItemResponse
   * @throws ApiError
   */
  public getTranslationPacks(): CancelablePromise<Array<TranslationPackListItemResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/translation-packs',
    });
  }
  /**
   * @returns TranslationPackResponse
   * @throws ApiError
   */
  public createTranslationPack({
    requestBody,
  }: {
    requestBody: CreateTranslationPackRequest;
  }): CancelablePromise<TranslationPackResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/translation-packs',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns string
   * @throws ApiError
   */
  public getLanguages(): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/translation-packs/languages',
    });
  }
  /**
   * @returns TranslationPackResponse
   * @throws ApiError
   */
  public getDefaultTranslationsPack({ language }: { language: string }): CancelablePromise<TranslationPackResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/translation-packs/language/{language}/default',
      path: {
        language: language,
      },
    });
  }
  /**
   * @returns TranslationPackResponse
   * @throws ApiError
   */
  public getTranslationsPack({ language }: { language: string }): CancelablePromise<TranslationPackResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/translation-packs/language/{language}',
      path: {
        language: language,
      },
    });
  }
  /**
   * @returns TranslationPackResponse
   * @throws ApiError
   */
  public updateTranslationPack({
    language,
    requestBody,
  }: {
    language: string;
    requestBody: Object;
  }): CancelablePromise<TranslationPackResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/translation-packs/language/{language}',
      path: {
        language: language,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
