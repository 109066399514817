import { FC } from 'react';
import { twMerge } from 'tailwind-merge';

export type StatsProgressBarItem = { value: number; className: string };
export type StatsProgressBarProps = {
  items: Array<StatsProgressBarItem>;
  className?: string;
};

const StatsProgressBar: FC<StatsProgressBarProps> = ({ items, className }) => {
  const total = items.reduce((acc: number, item: StatsProgressBarItem) => acc + item.value, 0);
  const itemsWithPercentage = items
    .filter((i) => i.value > 0)
    .map((item) => ({ ...item, percentage: (item.value / total) * 100 }));

  return (
    <div className={twMerge('relative h-2 w-full rounded-full flex flex-nowrap', className)}>
      {itemsWithPercentage.map((item, index) => (
        <div
          key={index}
          className={twMerge(`h-full first:rounded-l-full last:rounded-r-full`, item.className)}
          style={{ width: `${item.percentage}%` }}
        />
      ))}
    </div>
  );
};

export default StatsProgressBar;
