import { FC, HTMLAttributes, useMemo } from 'react';
import clsx from 'clsx';
import useAllRoutineServices from 'src/api/hooks/queries/useAllRoutineServices';
import { useIntl } from 'react-intl';

export type RoutineServiceLabelProps = {
  id: number | null;
} & Omit<HTMLAttributes<HTMLDivElement>, 'id'>;

const RoutineServiceLabel: FC<RoutineServiceLabelProps> = ({ className, id }) => {
  const intl = useIntl();
  const { data: routineServices } = useAllRoutineServices();
  const label = useMemo(
    () => routineServices?.find((i) => i.id === id)?.routineName ?? intl.formatMessage({ id: 'app.common.no' }),
    [routineServices, id],
  );

  return <span className={clsx(className)}>{label}</span>;
};

RoutineServiceLabel.displayName = 'RoutineServiceLabel';

export default RoutineServiceLabel;
