import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

const useResetOrderTypeConfig = (): UseMutationResult<void, unknown, number> => {
  const queryClient = useQueryClient();
  const apiClient = useApiClient();

  return useMutation<void, unknown, number>(
    async (id: number) => {
      await apiClient.orderTypes.resetOrderTypeConfig({ id });
    },
    {
      onSuccess: (_data, id) => {
        queryClient.invalidateQueries(queryKeysFactory.orderTypes.detail(id).queryKey);
        toast(<FormattedMessage id='app.toasts.update_success' />, {
          type: 'success',
        });
      },
      onError: () => {
        toast(<FormattedMessage id='app.toasts.update_fail' />, {
          type: 'error',
        });
      },
    },
  );
};

export default useResetOrderTypeConfig;
