import { FC } from 'react';
import useSupportedLanguages from 'src/api/hooks/queries/useSupportedLanguages';
import useLanguage from 'src/hooks/useLanguage';
import { LanguageOptionValue } from 'src/types/commonTypes';
import ButtonGroup, { ButtonGroupProps } from '../ButtonGroup';

export type LanguageButtonGroupProps = Omit<ButtonGroupProps<LanguageOptionValue>, 'value' | 'options' | 'onClick'>;

const LanguageButtonGroup: FC<LanguageButtonGroupProps> = (props) => {
  const { data: languages } = useSupportedLanguages();
  const [language, setlanguage] = useLanguage();

  return (
    <ButtonGroup
      {...props}
      options={languages?.map((i) => ({ label: i, value: i })) ?? []}
      value={language}
      onClick={setlanguage}
    />
  );
};

LanguageButtonGroup.displayName = 'LanguageButtonGroup';

export default LanguageButtonGroup;
