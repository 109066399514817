import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { NotificationResponse, UpdateNotificationRequest } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';
import { WithId } from 'src/types/mutations';

const useUpdateNotificationSetting = (): UseMutationResult<
  NotificationResponse,
  unknown,
  WithId<UpdateNotificationRequest>
> => {
  const queryClient = useQueryClient();
  const client = useApiClient();

  return useMutation<NotificationResponse, unknown, WithId<UpdateNotificationRequest>>(
    async (notificationSettings: WithId<UpdateNotificationRequest>) =>
      client.notificationSettings.updateNotificationSettings({
        id: notificationSettings.id,
        requestBody: notificationSettings,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeysFactory.tenantNotificationsSettings.list.queryKey);
        toast(<FormattedMessage id='app.toasts.update_success' defaultMessage='Záznam úspěšně zmenen' />, {
          type: 'success',
        });
      },
      onError: () => {
        toast(<FormattedMessage id='app.toasts.update_fail' defaultMessage='Chyba zmeny záznamu' />, {
          type: 'error',
        });
      },
    },
  );
};

export default useUpdateNotificationSetting;
