import { twMerge } from 'tailwind-merge';
import { ForwardedRef, Fragment, ReactNode, forwardRef, useMemo } from 'react';
import { FieldValues, FieldPath, UseControllerProps, Controller } from 'react-hook-form';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline';
import FieldError from '../FieldError';
import FieldLabel from '../FieldLabel';
import { getFieldClassName } from '../helpers';

export type SelectProps<TOption, TValue> = {
  className?: string;
  asterisk?: boolean;
  labelTextClassName?: string;
  inputClassName?: string;
  disabled?: boolean;
  error?: string;
  hideErrorMessage?: boolean;
  extraOnChange?: (value: TValue, option: TOption | null) => void;
  getOptionLabel: (option: TOption) => string;
  getOptionValue: (option: TOption) => TValue;
  getOptionLabelClassname?: (option: TOption) => string;
  getIsOptionDisabled?: (option: TOption) => boolean;
  shouldHideOption?: (option: TOption) => boolean;
  label?: ReactNode;
  labelClassName?: string;
  name: string;
  onChange: (value: TValue) => void;
  options: readonly TOption[];
  value: TValue;
};

export const Select = forwardRef(
  <TOption, TValue>(
    {
      className,
      labelTextClassName,
      inputClassName,
      value,
      hideErrorMessage,
      disabled,
      onChange,
      extraOnChange,
      options,
      getOptionValue,
      getOptionLabel,
      getOptionLabelClassname,
      getIsOptionDisabled,
      shouldHideOption,
      error,
      asterisk,
      label,
      labelClassName,
    }: SelectProps<TOption, TValue>,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const handleChange = (newValue: TValue): void => {
      onChange(newValue);

      const option = options?.find((i) => getOptionValue(i) === newValue) ?? null;
      extraOnChange?.(newValue, option);
    };

    const selected = options.find((option) => getOptionValue(option) === value);
    const visibleOptions = useMemo(() => options.filter((option) => !shouldHideOption?.(option)), [options]);

    return (
      <div className={twMerge('flex flex-col w-full gap-y-1 max-w-full', className)}>
        {label && (
          <FieldLabel asterisk={asterisk} className={labelClassName} textClassName={labelTextClassName}>
            {label}
          </FieldLabel>
        )}

        <div className='w-full relative'>
          <Listbox value={value} onChange={handleChange} disabled={disabled}>
            <Listbox.Button ref={ref} className={getFieldClassName({ disabled, error }, inputClassName)}>
              <span className='block truncate'>{selected ? getOptionLabel(selected) : ''}</span>
              <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
                <ChevronUpDownIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
              </span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Listbox.Options className='bg-white absolute z-[3000] mt-1 max-h-60 w-full overflow-auto rounded py-1 text-base shadow-lg ring-1 ring-gray-1000 ring-opacity-5 focus:outline-none sm:text-sm'>
                {visibleOptions.map((option, i) => (
                  <Listbox.Option
                    key={i}
                    disabled={getIsOptionDisabled?.(option)}
                    className={({ active }) =>
                      twMerge(
                        `relative cursor-default select-none py-2 pl-10 pr-4 w-full text-gray-900`,
                        active && 'bg-gray-100 cursor-pointer',
                        getOptionLabelClassname?.(option),
                      )
                    }
                    value={getOptionValue(option)}
                  >
                    {({ selected }) => (
                      <>
                        <span className={twMerge(`block truncate`, selected ? 'font-medium' : 'font-normal')}>
                          {getOptionLabel(option)}
                        </span>
                        {selected ? (
                          <span className='absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600'>
                            <CheckIcon className='h-5 w-5' aria-hidden='true' />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </Listbox>
        </div>
        {!hideErrorMessage && error && <FieldError>{error}</FieldError>}
      </div>
    );
  },
) as <TOption, TValue>(
  props: SelectProps<TOption, TValue> & {
    ref?: ForwardedRef<HTMLButtonElement>;
  },
) => JSX.Element;

export type SelectControlledProps<
  TOption,
  TValue,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<SelectProps<TOption, TValue>, 'value' | 'onChange' | 'name' | 'error'> & {
  control: UseControllerProps<TFieldValues, TName>['control'];
  name: TName;
};

export function SelectControlled<
  TOption,
  TValue,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({ control, name, ...selectProps }: SelectControlledProps<TOption, TValue, TFieldValues, TName>): JSX.Element {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Select {...selectProps} {...field} name={name} error={error?.message} />
      )}
    />
  );
}
