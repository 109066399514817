import { ColorScheme } from 'src/helpers/setColorScheme';

const defaultSecondary = {
  secondary: '#ffffff',
  secondaryFocus: '#f5f5f5',
};

const orange = {
  primary: '#f05720',
  primaryFocus: '#f57c00',
  ...defaultSecondary,
};

const purple = {
  primary: '#5059C9',
  primaryFocus: '#4149B3',
  ...defaultSecondary,
};

const blue = {
  primary: '#0F6CBD',
  primaryFocus: '#0d60a8',
  ...defaultSecondary,
};

const green = {
  primary: '#51a52b',
  primaryFocus: '#75B91B',
  ...defaultSecondary,
};

const red = {
  primary: '#B71111',
  primaryFocus: '#ce1212',
  ...defaultSecondary,
};

const yellow = {
  primary: '#FED23B',
  primaryFocus: '#FFDB61',
  ...defaultSecondary,
};

const schemes = {
  orange,
  purple,
  blue,
  green,
  red,
  yellow,
};

export const colorSchemesNames = Object.keys(schemes) as (keyof typeof schemes)[];

export type ColorSchemeName = keyof typeof schemes;

export default schemes as Record<ColorSchemeName, ColorScheme>;
