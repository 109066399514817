/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BranchesPageResponse } from '../models/BranchesPageResponse';
import type { BranchListResponse } from '../models/BranchListResponse';
import type { BranchResponse } from '../models/BranchResponse';
import type { CreateBranchRequest } from '../models/CreateBranchRequest';
import type { UpdateBranchRequest } from '../models/UpdateBranchRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class BranchService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns BranchResponse
   * @throws ApiError
   */
  public createBranch({
    contactId,
    requestBody,
  }: {
    contactId: number;
    requestBody: CreateBranchRequest;
  }): CancelablePromise<BranchResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/branch/contact/{contactId}',
      path: {
        contactId: contactId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns BranchListResponse
   * @throws ApiError
   */
  public getContactBranches({
    contactId,
    branchName,
    branchZip,
    branchStreet,
    companyName,
    branchCity,
    withDeleted,
  }: {
    contactId: number;
    /**
     * Branch NAME
     */
    branchName?: string;
    branchZip?: string;
    branchStreet?: string;
    companyName?: string;
    /**
     * Branch CITY
     */
    branchCity?: string;
    /**
     * With deleted
     */
    withDeleted?: boolean;
  }): CancelablePromise<Array<BranchListResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/branch/contact/{contactId}',
      path: {
        contactId: contactId,
      },
      query: {
        branchName: branchName,
        branchZip: branchZip,
        branchStreet: branchStreet,
        companyName: companyName,
        branchCity: branchCity,
        withDeleted: withDeleted,
      },
    });
  }
  /**
   * @returns BranchesPageResponse
   * @throws ApiError
   */
  public getBranches({
    pageSize,
    pageIndex,
    branchName,
    branchZip,
    branchStreet,
    companyName,
    branchCity,
    withDeleted,
    direction,
    orderBy,
  }: {
    pageSize: number;
    pageIndex: number;
    /**
     * Branch NAME
     */
    branchName?: string;
    branchZip?: string;
    branchStreet?: string;
    companyName?: string;
    /**
     * Branch CITY
     */
    branchCity?: string;
    /**
     * With deleted
     */
    withDeleted?: boolean;
    direction?: 'ASC' | 'DESC';
    orderBy?: string;
  }): CancelablePromise<BranchesPageResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/branch',
      query: {
        branchName: branchName,
        branchZip: branchZip,
        branchStreet: branchStreet,
        companyName: companyName,
        branchCity: branchCity,
        withDeleted: withDeleted,
        direction: direction,
        orderBy: orderBy,
        pageSize: pageSize,
        pageIndex: pageIndex,
      },
    });
  }
  /**
   * @returns BranchesPageResponse
   * @throws ApiError
   */
  public getBranchesFromSearchQuery({
    query,
    pageSize,
    pageIndex,
    limit,
    direction,
    orderBy,
  }: {
    query: string;
    pageSize: number;
    pageIndex: number;
    limit?: number | null;
    direction?: 'ASC' | 'DESC';
    orderBy?: string;
  }): CancelablePromise<BranchesPageResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/branch/search',
      query: {
        query: query,
        limit: limit,
        direction: direction,
        orderBy: orderBy,
        pageSize: pageSize,
        pageIndex: pageIndex,
      },
    });
  }
  /**
   * @returns BranchResponse
   * @throws ApiError
   */
  public getBranch({ id }: { id: number }): CancelablePromise<BranchResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/branch/{id}',
      path: {
        id: id,
      },
    });
  }
  /**
   * @returns BranchResponse
   * @throws ApiError
   */
  public updateBranch({
    id,
    requestBody,
  }: {
    id: number;
    requestBody: UpdateBranchRequest;
  }): CancelablePromise<BranchResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/branch/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns BranchResponse
   * @throws ApiError
   */
  public deleteBranch({ id }: { id: number }): CancelablePromise<BranchResponse> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/branch/{id}',
      path: {
        id: id,
      },
    });
  }
}
