import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { ProductDocumentResponse } from 'src/api';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

const useProductDocuments = (productId: number): UseQueryResult<ProductDocumentResponse[]> => {
  const client = useApiClient();
  const { queryKey } = queryKeysFactory.productDocuments.list(productId);

  return useQuery(
    queryKey,
    async () => {
      const data = client.product.getProductDocuments({ id: productId });

      return data;
    },
    {
      refetchInterval: false,
      staleTime: 1000 * 60 * 5,
    },
  );
};

export default useProductDocuments;
