import { twMerge } from 'tailwind-merge';
import React, { ChangeEventHandler, ForwardedRef, ReactNode, forwardRef } from 'react';
import { Controller, UseControllerProps, FieldPath, FieldValues } from 'react-hook-form';
import useEvent from 'src/hooks/useEvent';
import FieldLabel from '../FieldLabel';
import FieldError from '../FieldError';
import { getFieldClassName } from '../helpers';

export type TextFieldProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  error?: ReactNode;
  extraOnChange?: (value: string) => void;
  inputClassName?: string;
  label?: ReactNode;
  labelClassName?: string;
  labelTextClassName?: string;
  hideErrorMessage?: boolean;
  asterisk?: boolean;
};

export const TextField = forwardRef(
  (
    {
      label,
      labelClassName,
      labelTextClassName,
      inputClassName,
      error,
      className,
      disabled,
      extraOnChange,
      onChange,
      hideErrorMessage,
      ...inputProps
    }: TextFieldProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const handleChange = useEvent<ChangeEventHandler<HTMLInputElement>>((e) => {
      onChange?.(e);
      extraOnChange?.(e.target.value);
    });
    return (
      <label className={twMerge('flex flex-col w-full gap-y-1', className)}>
        {label && (
          <FieldLabel className={labelClassName} textClassName={labelTextClassName}>
            {label}
          </FieldLabel>
        )}
        <input
          {...inputProps}
          ref={ref}
          onChange={handleChange}
          value={inputProps.value ?? ''}
          disabled={disabled}
          className={getFieldClassName({ disabled, error }, inputClassName)}
        />
        {!hideErrorMessage && error && <FieldError>{error}</FieldError>}
      </label>
    );
  },
);

type TextFieldControlledProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<TextFieldProps, 'value' | 'onChange' | 'name' | 'error'> & {
  control: UseControllerProps<TFieldValues, TName>['control'];
  name: TName;
};

export const TextFieldControlled = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  ...textFieldProps
}: TextFieldControlledProps<TFieldValues, TName>): JSX.Element => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField {...textFieldProps} {...field} name={name} error={error?.message} />
      )}
    />
  );
};
