import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import useOrder from 'src/api/hooks/queries/useOrder';
import { SelectControlled } from 'src/components/fields/Select';
import { TextFieldControlled } from 'src/components/fields/Textfield';
import Signature, { SignatureValue } from 'src/components/Signature';
import useOrderIdFromToken from 'src/pages/TechnicianOrderFinishPage/hooks/useOrderIdFromToken';
import FieldWrapper from '../../FieldWrapper';
import Section from '../../Section';
import SectionTitle from '../../SectionTitle';
import { OrderFinishSchema } from '../schema';
import { CheckboxControlled } from 'src/components/fields/Checkbox';
import useCurrentTenantConfig from 'src/api/hooks/queries/useCurrentTenantConfig';

export type SignaturesFormProps = {
  allowAbsentCustomer?: boolean;
};

const SignaturesForm: FC<SignaturesFormProps> = ({ allowAbsentCustomer }) => {
  const intl = useIntl();
  const orderId = useOrderIdFromToken();
  const { data: config } = useCurrentTenantConfig();
  const { data: order } = useOrder(orderId);

  const { control, watch, setValue, formState } = useFormContext<OrderFinishSchema>();
  const absentCustomer = watch('absentCustomer');

  return (
    <>
      <SectionTitle className='mb-4'>
        <FormattedMessage id='app.finish_order.section_title.signatures' />
      </SectionTitle>
      <Section className='items-end'>
        <div className='flex self-start flex-col gap-3 max-w-xs'>
          {config?.allowOverwriteEmailInSL && (
            <FieldWrapper className='flex-col' label={<FormattedMessage id='app.order.contact_email' />}>
              <TextFieldControlled control={control} name={'contactEmail'} />
            </FieldWrapper>
          )}
          {allowAbsentCustomer && (
            <CheckboxControlled
              label={<FormattedMessage id='app.finish_order.absent_customer' />}
              control={control}
              name='customerSignature.absentCustomer'
              extraOnChange={() => {
                setValue('customerSignature.orderSignatureCustomer.dataUrl', null);
                setValue('customerSignature.orderSignatureCustomer.image', null);
                setValue('signingCustomerName', null);
              }}
            />
          )}
          {!absentCustomer && (
            <>
              <TextFieldControlled
                control={control}
                name='signingCustomerName'
                placeholder={intl.formatMessage({ id: 'app.finish_order.signature_customer' })}
              />
              <Controller
                control={control}
                name={'customerSignature.orderSignatureCustomer'}
                render={({ field: { name, value, onChange } }) => (
                  <Signature
                    value={value}
                    onChange={onChange}
                    error={(formState.errors[name] as Record<string, { message?: string }>)?.dataUrl?.message}
                  />
                )}
              />
            </>
          )}
        </div>

        <FieldWrapper
          className=' max-w-xs'
          label={
            <SelectControlled
              control={control}
              className='mt-2'
              name='signingTechnicianId'
              getOptionLabel={(option) => option.profile?.name ?? option.id.toString()}
              getOptionValue={(option) => option.id}
              options={order?.technicians ?? []}
            />
          }
        >
          <Controller
            control={control}
            name={'orderSignatureTechnician'}
            render={({ field: { name, value, onChange } }) => (
              <Signature
                value={value as SignatureValue}
                onChange={onChange}
                error={(formState.errors[name] as Record<string, { message?: string }>)?.dataUrl?.message}
              />
            )}
          />
        </FieldWrapper>
      </Section>
    </>
  );
};

export default SignaturesForm;
