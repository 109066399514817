import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { ProductTemplateResponse, CreateProductTemplateRequest } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';
import { WithId } from 'src/types/mutations';

const useUpdateProductTemplate = (): UseMutationResult<
  ProductTemplateResponse,
  unknown,
  WithId<CreateProductTemplateRequest>
> => {
  const client = useApiClient();
  const queryClient = useQueryClient();

  return useMutation<ProductTemplateResponse, unknown, WithId<CreateProductTemplateRequest>>(
    async (productTemplate: WithId<CreateProductTemplateRequest>) => {
      const data = await client.productTemplate.updateProductTemplate({
        requestBody: productTemplate,
        id: productTemplate.id,
      });

      return data;
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(queryKeysFactory.productTemplates.list.queryKey);
        queryClient.invalidateQueries(queryKeysFactory.productTemplates.detail(variables.id).queryKey);

        toast(<FormattedMessage id='app.toasts.add_success' />, {
          type: 'success',
        });
      },
      onError: () => {
        toast(<FormattedMessage id='app.toasts.add_fail' />, {
          type: 'error',
        });
      },
    },
  );
};

export default useUpdateProductTemplate;
