import { FC } from 'react';
import { CreateOrderTypeRequest } from 'src/api';
import { FormProvider, useForm } from 'react-hook-form';
import { OrderTypeSchema } from './schema';
import { FormattedMessage } from 'react-intl';
import { TextFieldControlled } from 'src/components/fields/Textfield';
import NewItemPopupFooter from 'src/components/common/NewItemPopupFooter';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import Tooltip from 'src/components/common/Tooltip';
import { zodResolver } from '@hookform/resolvers/zod';

export type OrderTypeFormProps = {
  defaultValues?: Partial<CreateOrderTypeRequest>;
  onSubmit: (data: CreateOrderTypeRequest) => void;
  className?: string;
};

const OrderTypeForm: FC<OrderTypeFormProps> = ({ defaultValues, onSubmit }) => {
  const form = useForm<CreateOrderTypeRequest>({
    defaultValues,
    resolver: zodResolver(OrderTypeSchema),
  });

  const { handleSubmit, control } = form;

  return (
    <FormProvider {...form}>
      <div className='flex-col w-full'>
        <form className='flex flex-col w-full gap-y-4' onSubmit={handleSubmit(onSubmit)}>
          <TextFieldControlled control={control} name='name' label={<FormattedMessage id='app.order_type.name' />} />
          <TextFieldControlled
            control={control}
            name='orderNumberFormat'
            label={
              <div className='flex'>
                <FormattedMessage id='app.tenant.order_number_format' />
                <Tooltip
                  text={
                    <span className='inline-block max-w-sm w-max'>
                      <FormattedMessage id='app.tenants_page.tooltip.order_number_format' />
                    </span>
                  }
                >
                  <InformationCircleIcon className='w-6' />
                </Tooltip>
              </div>
            }
          />
          <TextFieldControlled
            control={control}
            name='slConfig'
            label={<FormattedMessage id='app.order_type.slConfig' />}
          />
          <NewItemPopupFooter />
        </form>
      </div>
    </FormProvider>
  );
};

OrderTypeForm.displayName = 'OrderTypeForm';

export default OrderTypeForm;
