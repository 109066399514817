import { FC, memo } from 'react';
import invertColor from 'src/helpers/invertColor';
import stringToHexColor from 'src/helpers/stringToColor';
import Avatar from '../Avatar';

export type ColorAvatarProps = {
  name: string;
  className?: string;
  style?: React.CSSProperties;
};

const ColorAvatar: FC<ColorAvatarProps> = ({ name, className, style }) => {
  const backgroundColor = stringToHexColor(name);
  const color = invertColor(backgroundColor);
  const letters = name
    .trim()
    .split(' ')
    .map((i) => i[0]?.toUpperCase() ?? '')
    .filter(Boolean)
    .slice(0, 2)
    .join('');

  return (
    <Avatar className={className} style={{ ...style, backgroundColor }}>
      <span className='text-xs font-bold' style={{ color }}>
        {letters}
      </span>
    </Avatar>
  );
};

export default memo(ColorAvatar);
