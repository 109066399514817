import clsx from 'clsx';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import ReactJson from 'react-json-view';
import { OrderTypeRepsonse } from 'src/api';
import ParametersRow from 'src/components/common/ParametersRow';

export type OrderTypePreviewProps = {
  className?: string;
  data: OrderTypeRepsonse;
};

const OrderTypePreview: FC<OrderTypePreviewProps> = ({ className, data }) => {
  return (
    <div className={clsx('flex flex-col w-full', className)}>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.common.id' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.id}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.order_type.name' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.name}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.tenant.order_number_format' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.orderNumberFormat}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow noBorder>
        <ParametersRow.Label>
          <FormattedMessage id='app.order_type.slConfig' />
        </ParametersRow.Label>
      </ParametersRow>
      <ReactJson src={data.slConfig ?? {}} collapsed={true} />
      <ParametersRow></ParametersRow>
    </div>
  );
};

OrderTypePreview.displayName = 'OrderTypePreview';

export default OrderTypePreview;
