import clsx from 'clsx';
import { ReactNode } from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import withController, { WithControllerProps } from 'src/hoc/withController';
import { ArrayElement } from 'src/types/commonTypes';
import { FieldProps } from 'src/types/forms';
import Checkbox from '../Checkbox';
import { twMerge } from 'tailwind-merge';
import FieldError from '../FieldError';
import FieldLabel from '../FieldLabel';

export type CheckboxGroupOption<TOptionValue> = {
  label: ReactNode | (() => ReactNode);
  name: string;
  value: TOptionValue;
  disabled?: boolean;
};

export type CheckboxGroupBaseProps<TValue extends Array<string | number | symbol>> = {
  hideErrorMessage?: boolean;
  value: TValue;
  error?: string | boolean;
  disabled?: boolean;
  className?: string;
  isFilter?: boolean;
  options: Array<CheckboxGroupOption<ArrayElement<TValue>>>;
  onChange: (value: TValue) => void;
  asRow?: boolean;
  label?: ReactNode;
  labelClassName?: string;
  labelTextClassName?: string;
};

export type CheckboxGroupProps<TValue extends Array<string | number | symbol>> = Omit<FieldProps<TValue>, 'onChange'> &
  CheckboxGroupBaseProps<TValue>;

const CheckboxGroupBase = <TValue extends Array<string | number | symbol>>({
  value = [] as unknown as TValue,
  className,
  label,
  labelClassName,
  labelTextClassName,
  disabled,
  hideErrorMessage,
  error,
  options,
  onChange,
  isFilter,
  asRow,
}: CheckboxGroupBaseProps<TValue>): JSX.Element => {
  const isChecked = (optionValue: ArrayElement<TValue>): boolean => value.includes(optionValue);

  const handleChangeFactory = (optionValue: ArrayElement<TValue>) => (checked: boolean | null | undefined) => {
    const nextValue = (checked ? [...value, optionValue] : value.filter((v) => v !== optionValue)) as TValue;
    onChange(nextValue);
  };

  return (
    <div className={twMerge('flex flex-col gap-y-1', className)}>
      {label && (
        <FieldLabel className={labelClassName} textClassName={labelTextClassName}>
          {label}
        </FieldLabel>
      )}
      <div className='flex flex-col'>
        <div className={clsx('flex gap-2', asRow ? 'flex-wrap' : 'flex-col')}>
          {options.map(({ label, name, value, disabled: optionDisabled }) => (
            <div key={name} className='flex items-center'>
              <Checkbox
                value={isChecked(value)}
                disabled={disabled || optionDisabled}
                name={name}
                onChange={handleChangeFactory(value)}
              />
              <label className={clsx('ml-2', isFilter && 'text-lg leading-none font-semibold')} htmlFor={name}>
                {typeof label === 'function' ? label() : label}
              </label>
            </div>
          ))}
        </div>
        {!hideErrorMessage && error && <FieldError>{error}</FieldError>}
      </div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CheckboxGroupRhfc = withController(CheckboxGroupBase as any) as <
  TValue extends Array<string | number | symbol>,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  props: Omit<CheckboxGroupBaseProps<TValue>, 'value' | 'onChange'> & WithControllerProps<TFieldValues, TName>,
) => JSX.Element;

CheckboxGroupBase.displayName = 'CheckboxGroupBase';

export default CheckboxGroupBase; // ??
