import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

const useUpdateCurrentTenantColorTheme = (): UseMutationResult<void, unknown, string> => {
  const client = useApiClient();
  const queryClient = useQueryClient();

  return useMutation<void, unknown, string>(
    async (theme: string) => {
      const data = await client.tenant.updateColorTheme({ theme });
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeysFactory.tenant.list.queryKey);
        queryClient.invalidateQueries(queryKeysFactory.tenant.current);
        toast(<FormattedMessage id='app.toasts.add_success' />, {
          type: 'success',
        });
      },
      onError: () => {
        toast(<FormattedMessage id='app.toasts.add_fail' />, {
          type: 'error',
        });
      },
    },
  );
};

export default useUpdateCurrentTenantColorTheme;
