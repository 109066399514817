import { TenantConfigDto, OrderResponse } from 'src/api';
import { OrderSchema, OrderProductSchema } from './schema';

export const createServiceFormDefaultValues = (config?: TenantConfigDto): Partial<OrderSchema> =>
  ({
    number: {
      useManualOrderNumber: false,
      manualOrderNumber: null,
    },
    shouldSendNotifications: false,
    company: {
      category: OrderResponse.category.B2B,
      phoneNumber: '+420',
      preferredCurrency: 'CZK',
      preferredLanguage: 'cz',
      createNewCompany: false,
      ...config?.orderForm?.defaultValues?.company,
    },
    branch: {
      createNewBranch: false,
      ...config?.orderForm?.defaultValues?.branch,
    },
    employee: {
      createNewEmployee: false,
      ...config?.orderForm?.defaultValues?.employee,
    },
    orderProducts: [
      {
        productId: null,
        productDescription: '',
        quarante: false,
        routineServiceId: null,
        createNewProduct: false,
      } as unknown as OrderProductSchema,
    ],
    technicianIds: [],
    emailTarget: OrderResponse.emailTarget.HQ,
    category: OrderResponse.category.B2B,
    useManualOrderNumber: false,
    manualOrderNumber: null,
  } as Partial<OrderSchema>);
