import { PlusCircleIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { ReactNode, useState } from 'react';
import { FC } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { FormattedMessage } from 'react-intl';
import Button from '../Button';
import Popup from '../Popup';
import Title from '../Title';
import FormBuilderSection from './components/FormBuilderSection';
import FormBuilderSectionForm from './components/FormBuilderSectionForm';
import { FORM_BUILDER_DROPPABLE_TYPES } from './constants';
import { FormBuilderContextProvider, FormBuilderContextValue } from './context';

export type FormBuilderProps = {
  className?: string;
  title?: ReactNode;
  state: FormBuilderContextValue;
};

const FormBuilder: FC<FormBuilderProps> = ({ className, state, title }) => {
  const { sectionsWithFields, addSection, onDragEnd } = state;
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const hasSections = sectionsWithFields.length > 0;

  return (
    <FormBuilderContextProvider value={state}>
      <div className={clsx(className, 'flex flex-col w-full')}>
        <Title className='flex items-center mb-8'>
          <span>{title ?? <FormattedMessage id='app.order_type.sl_config.sections' />}</span>
          <Button variant='cta' size='small' onClick={() => setIsPopupOpen(true)}>
            <PlusCircleIcon className='h-6 ml-1' />
          </Button>
        </Title>
        <DragDropContext
          onDragEnd={(result, provided) => {
            onDragEnd(result, provided);
          }}
        >
          <Droppable type={FORM_BUILDER_DROPPABLE_TYPES.ROOT} droppableId='root'>
            {(provided) => (
              <>
                <div className='flex flex-col' ref={provided.innerRef} {...provided.droppableProps}>
                  {hasSections ? (
                    sectionsWithFields.map((section, index) => (
                      <FormBuilderSection key={section.id} index={index} id={section.id} />
                    ))
                  ) : (
                    <FormattedMessage id='app.order_type.sl_config.sections.placeholder' />
                  )}
                </div>
                {provided.placeholder}
              </>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <Popup
        onClose={() => setIsPopupOpen(false)}
        open={isPopupOpen}
        title={<FormattedMessage id='app.order_type.sl_config.sections.add_field' />}
      >
        <FormBuilderSectionForm
          onSubmit={(data) => {
            addSection(data);
            setIsPopupOpen(false);
          }}
        />
      </Popup>
    </FormBuilderContextProvider>
  );
};

FormBuilder.displayName = 'FormBuilder';

export default FormBuilder;
