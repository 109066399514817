import { ColumnFiltersState } from '@tanstack/react-table';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import useRoutineServices from 'src/api/hooks/queries/useRoutineServices';
import PageContentHeader from 'src/components/PageContentHeader';
import Popup from 'src/components/Popup';
import useTableState from 'src/components/Table/hooks/useTableState';
import useLastDefined from 'src/hooks/useLastDefined';
import usePageBaseState from 'src/hooks/usePageBaseState';
import { RoutineServiceFilter } from 'src/types/filters';
import AddRoutineServicesForm from './AddRoutineServicesForm';
import RoutineServicesTable from './RoutineServicesTable';
import PageCard from 'src/components/PageCard';

export type SortType = {
  path: string[];
  direction: 'asc' | 'desc';
  type: 'number' | 'string';
};

const defaultSortingState = [{ id: 'routine_value', desc: false }];

const transformFilters = (filters: ColumnFiltersState): RoutineServiceFilter => ({
  routineName: (filters.find((i) => i.id === 'routine_name')?.value as string) ?? '',
  routineValue: (filters.find((i) => i.id === 'routine_value')?.value as string) ?? '',
});

const RoutineServicesPage: React.FC = () => {
  const { isFilterOpen, isPopupOpen, handleClosePopup, handleOpenPopup, handleToggleFilter } = usePageBaseState();

  const {
    columnFilters,
    setColumnFilters,
    sorting,
    setSorting,
    pagination,
    setPagination,
    debouncedServerFriendlyFilters,
    sortingQuery,
  } = useTableState<RoutineServiceFilter>({
    transformFilters,
    defaultSortingState,
  });

  const { data, isLoading } = useRoutineServices(debouncedServerFriendlyFilters, sortingQuery, pagination);
  const definedData = useLastDefined(data);

  return (
    <>
      <PageContentHeader
        className='my-4'
        mobilePrimaryButtonType='new'
        title={<FormattedMessage id='app.routine_services_page.title' />}
        onSecondaryButtonClick={handleToggleFilter}
        onPrimaryButtonClick={handleOpenPopup}
        primaryButtonText={
          <FormattedMessage id='app.routine_services_page.new_routine_service_button' defaultMessage='Nový uzivatel' />
        }
      />
      <PageCard>
        <RoutineServicesTable
          isLoading={isLoading}
          data={definedData?.items ?? []}
          isFilterOpen={isFilterOpen}
          sorting={sorting}
          columnFilters={columnFilters}
          pagination={pagination}
          handleSort={setSorting}
          handleFilter={setColumnFilters}
          handlePaginationChange={setPagination}
          paginationMeta={definedData?.meta}
        />
      </PageCard>
      <Popup
        title={<FormattedMessage id='app.routine_services_page.routine_service.popup_title' />}
        onClose={handleClosePopup}
        open={isPopupOpen}
      >
        <AddRoutineServicesForm />
      </Popup>
    </>
  );
};

export default RoutineServicesPage;
