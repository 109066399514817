import { FC, useCallback, useMemo } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { ContactResponse } from 'src/api';
import useAddProduct from 'src/api/hooks/mutations/products/useAddProduct';
import useContactBranches from 'src/api/hooks/queries/useContactBranches';
import { usePopupContext } from 'src/components/Popup/hook';
import ProductForm from '../ProductForm';
import { ProductSchemaB2B, ProductSchemaB2C } from '../ProductForm/schema';

export type AddProductFormProps = {
  fixedBranchId?: number | null;
  contactId: number;
  className?: string;
  category: ContactResponse.category;
};

const AddProductForm: FC<AddProductFormProps> = ({ category, className, contactId, fixedBranchId }) => {
  const addProduct = useAddProduct();
  const { data } = useContactBranches(contactId);
  const branchesOptions = useMemo(() => (data ?? []).map((i) => ({ label: i.name, value: i.id })), []);
  const { onClose } = usePopupContext();

  const onSubmit: SubmitHandler<ProductSchemaB2B | ProductSchemaB2C> = useCallback(
    (data) => {
      addProduct.mutate({
        ...data,
        ...(fixedBranchId && { branchId: fixedBranchId }),
        customerId: contactId,
      });
      onClose();
    },
    [addProduct, onClose],
  );
  const defaultValues = useMemo(
    () => ({
      customerId: contactId,
      branchId: fixedBranchId,
    }),
    [],
  );

  return (
    <ProductForm
      category={category}
      branchOptions={branchesOptions}
      onSubmit={onSubmit}
      className={className}
      hideBranch={fixedBranchId !== undefined}
      defaultValues={defaultValues}
    />
  );
};

AddProductForm.displayName = 'AddProductForm';

export default AddProductForm;
