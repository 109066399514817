import { Column, SortDirection } from '@tanstack/react-table';
import clsx from 'clsx';
import React, { ReactNode } from 'react';
import SortDownIcon from 'src/components/Icons/SortDownIcon';
import SortUpIcon from 'src/components/Icons/SortUpIcon';

export type ColumnSortProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  column: Column<any, unknown>;
};

const getSortingIcon = (dir: SortDirection): ReactNode => {
  if (dir === 'desc')
    return (
      <div className='pt-1'>
        <SortDownIcon />
      </div>
    );

  return (
    <div className='pt-1'>
      <SortUpIcon />
    </div>
  );
};

const ColumnSort: React.FC<ColumnSortProps> = ({ column }) => {
  const { getToggleSortingHandler, getIsSorted } = column;
  const dir = getIsSorted();
  const icon = getSortingIcon(dir || 'asc');

  return (
    <div
      onClick={getToggleSortingHandler()}
      className={clsx('transition-opacity', !dir && 'opacity-0 group-hover:opacity-100')}
    >
      {icon}
    </div>
  );
};

export default ColumnSort;
