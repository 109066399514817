import { Updater } from '@tanstack/react-table';
import { parse, format } from 'date-fns';
import useSearchParam, { UseSearchParamOptions } from 'src/hooks/router/useSearchParam';

const useDateSearchParam = (
  paramName: string,
  options: UseSearchParamOptions<Date> = {},
): [Date, (value: Updater<Date | null>) => void] => {
  const [date, setDate] = useSearchParam(paramName, {
    defaultValue: new Date(),
    convertFromString: (value) => (value ? parse(value, 'dd.MM.yyyy', new Date()) : new Date()),
    convertToString: (value) => format(value ?? new Date(), 'dd.MM.yyyy'),
    ...options,
  });

  return [date, setDate];
};

export default useDateSearchParam;
