export const ORDER_EXCEL_COLUMNS_WIDTHS = {
  datetime: 15,
  endDatetime: 15,
  realStart: 18,
  realEnd: 20,
  status: 10,
  invoiceNumber: 12,
  deliveryType: 15,
  deliveryTariffName: 20,
  orderNumber: 10,
  serviceDuration: 15,
  serviceMileage: 15,
  serviceTimeOnRoad: 15,
  serviceTotalPrice: 10,
  serviceTotalPriceWithVat: 10,
  technicianNames: 15,
  companyName: 22,
  companyAddress: 20,
  branchName: 10,
  branchAddress: 20,
  branchContactPerson: 14,
  employeehName: 10,
  productNames: 15,
};
