import clsx from 'clsx';
import React, { HTMLAttributes } from 'react';

export type HelperTextProps = HTMLAttributes<HTMLSpanElement> & {
  variant?: 'success' | 'error' | 'normal';
};

const getVariantClassnames = (variant: HelperTextProps['variant']): string => {
  if (variant === 'error') return 'text-error';
  if (variant === 'success') return 'text-success';
  return '';
};

const HelperText: React.FC<HelperTextProps> = ({ className, variant, ...rest }) => (
  <span
    className={clsx(className, getVariantClassnames(variant), 'block my-1 font-openSans font-semibold text-sm')}
    {...rest}
  />
);

export default HelperText;
