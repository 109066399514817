import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { VatTariffResponse } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

export type UseAllVatTariffsOptions = {
  withDeleted?: boolean;
};

const useAllVatTariffs = (options: UseAllVatTariffsOptions = {}): UseQueryResult<VatTariffResponse[]> => {
  const client = useApiClient();
  const { queryKey } = queryKeysFactory.vatTariffs.list({ withDeleted: options.withDeleted });

  return useQuery(
    queryKey,
    async () => {
      const data = await client.vatTariffs.getAllVatTariffs(options);
      return data;
    },
    {
      refetchInterval: false,
      staleTime: Infinity,
    },
  );
};

export default useAllVatTariffs;
