import { useMemo } from 'react';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import Badge from 'src/components/common/Badge';
import ExportCardList from '../../components/ExportCardList';
import { ExportCardData } from '../../components/ExportCard';

export type OrdersExportsTabProps = {
  className?: string;
};

const OrdersExportsTab: FC<OrdersExportsTabProps> = () => {
  const items = useMemo<ExportCardData[]>(
    () => [
      {
        title: <FormattedMessage id='app.export.orders.cards.custom_export.title' />,
        description: <FormattedMessage id='app.export.orders.cards.custom_export.description' />,
        badge: (
          <>
            <div className='flex gap-x-2'>
              <Badge text={<FormattedMessage id='app.common.excel' />} color='green' />
            </div>
          </>
        ),
        buttonLink: '/admin/exports/orders/custom-filter',
        buttonText: <FormattedMessage id='app.export.create_a_report' />,
      },
      {
        title: <FormattedMessage id='app.export.orders.cards.technician_orders.title' />,
        description: <FormattedMessage id='app.export.orders.cards.technician_orders.description' />,
        badge: (
          <>
            <div className='flex gap-x-2'>
              <Badge text={<FormattedMessage id='app.common.excel' />} color='green' />
            </div>
          </>
        ),
        buttonLink: '/admin/exports/orders/technicians-data',
        buttonText: <FormattedMessage id='app.export.create_a_report' />,
      },
      {
        title: <FormattedMessage id='app.export.orders.cards.customer_orders.title' />,
        description: <FormattedMessage id='app.export.orders.cards.customer_orders.description' />,
        badge: (
          <>
            <div className='flex gap-x-2'>
              <Badge text={<FormattedMessage id='app.common.excel' />} color='green' />
            </div>
          </>
        ),
        buttonLink: '/admin/exports/orders/customer-data',
        buttonText: <FormattedMessage id='app.export.create_a_report' />,
      },
      {
        title: <FormattedMessage id='app.export.orders.cards.product_orders.title' />,
        description: <FormattedMessage id='app.export.orders.cards.product_orders.description' />,
        badge: (
          <>
            <div className='flex gap-x-2'>
              <Badge text={<FormattedMessage id='app.common.excel' />} color='green' />
            </div>
          </>
        ),
        buttonLink: '/admin/exports/orders/products-data',
        buttonText: <FormattedMessage id='app.export.create_a_report' />,
      },
    ],
    [],
  );

  return <ExportCardList items={items} />;
};

OrdersExportsTab.displayName = 'OrdersExportsTab';

export default OrdersExportsTab;
