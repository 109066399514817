import { FC, useCallback } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { ContactResponse } from 'src/api';
import useUpdateProduct from 'src/api/hooks/mutations/products/useUpdateProduct';
import useContactProduct from 'src/api/hooks/queries/useContactProduct';
import { usePopupContext } from 'src/components/Popup/hook';
import ProductForm from '../ProductForm';
import { ProductSchemaB2B, ProductSchemaB2C } from '../ProductForm/schema';

export type UpdateProductFormProps = {
  className?: string;
  productId: number;
};

const UpdateProductForm: FC<UpdateProductFormProps> = ({ className, productId }) => {
  const updateProduct = useUpdateProduct();
  const { data: product } = useContactProduct(productId);

  const { onClose } = usePopupContext();

  const onSubmit: SubmitHandler<ProductSchemaB2B | ProductSchemaB2C> = useCallback(
    (data) => {
      if (!product) return;

      updateProduct.mutate({ ...data, customerId: product.customerId, id: productId });
      onClose();
    },
    [updateProduct, onClose],
  );

  return (
    <ProductForm
      category={product?.branchId ? ContactResponse.category.B2B : ContactResponse.category.B2C}
      hideBranch
      defaultValues={product}
      branchOptions={[]}
      onSubmit={onSubmit}
      className={className}
    />
  );
};

UpdateProductForm.displayName = 'UpdateProductForm';

export default UpdateProductForm;
