import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

export type UseDeleteProductDocumentData = {
  productId: number;
  documentId: number;
};

const useDeleteProductDocument = (): UseMutationResult<void, unknown, UseDeleteProductDocumentData> => {
  const queryClient = useQueryClient();
  const client = useApiClient();

  return useMutation<void, unknown, UseDeleteProductDocumentData>(
    async (data) => {
      await client.product.deleteProductDocument({ id: data.productId, documentId: data.documentId });
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(queryKeysFactory.productDocuments.list(variables.productId));

        toast(<FormattedMessage id='app.toasts.delete_success' />, {
          type: 'success',
        });
      },
      onError: () => {
        toast(<FormattedMessage id='app.toasts.delete_fail' />, {
          type: 'error',
        });
      },
    },
  );
};

export default useDeleteProductDocument;
