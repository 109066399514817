import { ColumnFiltersState, SortingState } from '@tanstack/react-table';
import { DEFAULT_TABLE_PAGINATION } from 'src/constants/table';
import { noop } from 'src/utils/commonUtils';
import Table, { TableProps } from './Table';

export type DumbTableProps<T extends { id: number }> = Omit<
  TableProps<T>,
  'columnFilters' | 'sorting' | 'pagination' | 'handleFilter' | 'handleSort' | 'handlePaginationChange'
>;

const ARRAY_STUB: unknown[] = [];

const DumbTable = <T extends { id: number }>(props: DumbTableProps<T>): JSX.Element => (
  <Table
    {...props}
    columnFilters={ARRAY_STUB as ColumnFiltersState}
    sorting={ARRAY_STUB as SortingState}
    pagination={DEFAULT_TABLE_PAGINATION}
    handleFilter={noop}
    handleSort={noop}
    handlePaginationChange={noop}
  />
);

export default DumbTable;
