export type OrderNumberFormatParts = {
  prefix: string;
  digits: number;
  postfix: string;
};

const DEFAULT_DIGITS = 5;
export function orderFormatNumberToParts(format: string | null): OrderNumberFormatParts {
  if (!format) return { prefix: '', digits: DEFAULT_DIGITS, postfix: '' };

  let i = 0;
  let prefix = '';
  for (; i < format.length; i++) {
    if (format[i] === '%') break;
    prefix += format[i];
  }

  // Skip '%'
  i++;

  let digitsFormat = '';
  for (; i < format.length; i++) {
    if (format[i] === 'd') break;
    digitsFormat += format[i];
  }
  const digits = parseInt(digitsFormat, 10);

  // Skip 'd'
  i++;

  let postfix = '';
  for (; i < format.length; i++) {
    postfix += format[i];
  }

  return { prefix, digits: Number.isNaN(digits) ? DEFAULT_DIGITS : digits, postfix };
}

export function orderFormatPartsToNumber(parts: OrderNumberFormatParts): string {
  return `${parts.prefix}%0${parts.digits}d${parts.postfix}`;
}
