import { CSSProperties, forwardRef, memo, useState } from 'react';
import { GridEvent } from '../../types';
import { Resizable, ResizeCallback } from 're-resizable';
import useGridCellDimensions from '../../hooks/useGridCellDimensions';
import useEvent from 'src/hooks/useEvent';
import EventContent from '../EventContent/EventContent';
import useResizeOrder from 'src/api/hooks/mutations/order/useResizeOrder';
import { COLS_PER_HOUR_MTD } from '../../constants';
import { addMinutes } from 'date-fns';

export const createGridStyles = (
  row: number,
  rowSpan: number,
  col: number,
  colSpan: number,
): {
  gridColumn: string;
  gridRow: string;
} => ({
  gridColumn: `${col} / span ${colSpan}`,
  gridRow: `${row} / span ${rowSpan}`,
});

export type ResizableEventProps = GridEvent & {
  style?: CSSProperties;
  row: number;
  rowSpan: number;
  col: number;
  colSpan: number;
  onClick?: (e: React.MouseEvent) => void;
};

const ResizableEvent = forwardRef<HTMLLIElement, ResizableEventProps>(
  ({ id, row, rowSpan, col, colSpan, style, onClick, ...rest }, ref) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const resizeOrder = useResizeOrder();

    const { rowHeight, columnWidth } = useGridCellDimensions();
    const [width, setWidth] = useState(columnWidth * colSpan);

    const handleResizeStop = useEvent<ResizeCallback>((event, direction, _elRef, delta) => {
      event.stopPropagation();
      const totalWidth = width + delta.width;
      const snappedWidth = Math.round(totalWidth / columnWidth) * columnWidth;
      setWidth(snappedWidth);

      const { start } = rest;
      const newDurationInMinutes = (snappedWidth / columnWidth / COLS_PER_HOUR_MTD) * 60;
      const newEnd = addMinutes(start, newDurationInMinutes);

      resizeOrder.mutate({
        id,
        start: start,
        end: newEnd,
      });
    });

    return (
      <Resizable
        enable={{
          top: false,
          right: true,
          bottom: false,
          left: false,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false,
        }}
        handleComponent={{
          right: (
            <div className='absolute left-[-0.75rem] top-0 right-0 h-full flex justify-around items-center w-3 z-[120]'>
              <span className='block w-0.5 bg-neutral-300 h-10'></span>
              <span className='block w-0.5 bg-neutral-300 h-10'></span>
            </div>
          ),
        }}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onClick={(e) => e.stopPropagation()}
        size={{ height: rowSpan * rowHeight, width: width }}
        onResizeStop={handleResizeStop}
        data-cy={`event-${id}`}
        className='relative z-[100] flex overflow-hidden'
        style={{
          ...style,
          ...createGridStyles(row, rowSpan, col, colSpan),
        }}
      >
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        <EventContent {...rest} id={id} ref={ref as any} onClick={onClick} />
      </Resizable>
    );
  },
);

export default memo(ResizableEvent);
