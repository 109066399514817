import { createColumnHelper } from '@tanstack/react-table';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { ContactResponse } from 'src/api';
import Table, { TableProps } from 'src/components/Table';
import { ExtendedColumnDef } from 'src/types/table';

export type ContactTableProps = Omit<TableProps<ContactResponse>, 'columns'>;

const columnHelper = createColumnHelper<ContactResponse>();

const columns: ExtendedColumnDef<ContactResponse>[] = [
  {
    ...columnHelper.accessor('companyName', {
      id: 'company_name',
      header: () => <FormattedMessage id='app.contact.company_name' defaultMessage='Název společnosti' />,
      enableColumnFilter: false,
      enableSorting: true,
      cell: (info) => info.renderValue(),
      size: 350,
    }),
  },
  {
    ...columnHelper.accessor('city', {
      id: 'city',
      header: () => <FormattedMessage id='app.common.city' />,
      enableColumnFilter: false,
      enableSorting: true,
      cell: (info) => info.renderValue(),
      size: 350,
    }),
  },
  {
    ...columnHelper.accessor('phoneNumber', {
      id: 'phoneNumber',
      header: () => <FormattedMessage id='app.common.phone' />,
      enableColumnFilter: false,
      enableSorting: true,
      cell: (info) => info.renderValue(),
      size: 200,
    }),
  },
  {
    ...columnHelper.accessor('category', {
      id: 'category',
      header: () => <FormattedMessage id='app.customer.category' />,
      enableColumnFilter: false,
      enableSorting: true,
      cell: (info) => info.renderValue(),
      size: 120,
    }),
  },
  {
    ...columnHelper.accessor('internalCategory', {
      id: 'internalCategory',
      header: () => <FormattedMessage id='app.contact.internal_category' />,
      enableColumnFilter: true,
      enableSorting: true,
      cell: (info) => info.renderValue(),
      size: 120,
    }),
    showOnlyWhen: (config) => !!config?.contactForm?.showInternalCategory,
  },
  {
    ...columnHelper.accessor('deletedAt', {
      id: 'deleted_at',
      header: () => <FormattedMessage id='app.contact.active_contact' />,
      enableColumnFilter: false,
      enableSorting: true,
      cell: (info) =>
        info.getValue() ? <FormattedMessage id='app.common.no' /> : <FormattedMessage id='app.common.yes' />,
      size: 220,
    }),
  },
];

const ContactTable: React.FC<ContactTableProps> = (props) => {
  const navigate = useNavigate();
  return <Table columns={columns} {...props} handleRowClick={(original) => navigate(`/contact/${original.id}`)} />;
};

export default ContactTable;
