/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContributorResponse } from '../models/ContributorResponse';
import type { OrderListItemResponse } from '../models/OrderListItemResponse';
import type { WeekOrdersStatisticsForStatusResponse } from '../models/WeekOrdersStatisticsForStatusResponse';
import type { WeekRequest } from '../models/WeekRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class DashboardService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns OrderListItemResponse
   * @throws ApiError
   */
  public getAllWeekOrders({
    requestBody,
  }: {
    requestBody: WeekRequest;
  }): CancelablePromise<Array<OrderListItemResponse>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/dashboard/weekOrders',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns ContributorResponse
   * @throws ApiError
   */
  public getWeekContributions({
    requestBody,
  }: {
    requestBody: WeekRequest;
  }): CancelablePromise<Array<ContributorResponse>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/dashboard/week-contributions',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns WeekOrdersStatisticsForStatusResponse
   * @throws ApiError
   */
  public getWeekOrdersStatistics({
    requestBody,
  }: {
    requestBody: WeekRequest;
  }): CancelablePromise<Array<WeekOrdersStatisticsForStatusResponse>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/dashboard/week-orders-statistics',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
