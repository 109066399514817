import { Updater } from '@tanstack/react-table';

const updateLocalStorageItem = <T extends object>(key: string, value: Updater<T>): void => {
  try {
    const item = localStorage.getItem(key);
    const parsedItem = item ? JSON.parse(item) : null;
    const newValue = typeof value === 'function' ? value(parsedItem) : value;
    localStorage.setItem(key, JSON.stringify(newValue));
  } catch (_) {
    localStorage.removeItem(key);
  }
};

export default updateLocalStorageItem;
