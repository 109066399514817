import { FC, HTMLAttributes, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import useDeleteContactEmployee from 'src/api/hooks/mutations/contact/useDeleteContactEmployee';
import ConfirmationPopup from 'src/components/common/ConfirmationPopup';

export type DeleteEmployeePopupProps = {
  //
} & HTMLAttributes<HTMLDivElement>;

const DeleteEmployeePopup: FC<DeleteEmployeePopupProps> = ({ className }) => {
  const contactId = Number(useParams().contactId);
  const employeeId = Number(useParams().employeeId);
  const navigate = useNavigate();

  const deleteEmployee = useDeleteContactEmployee();

  const onOk = useCallback(() => {
    deleteEmployee.mutate({ contactId, employeeId });
    navigate(`/contact/${contactId}`);
  }, [contactId, employeeId, deleteEmployee]);

  if (!contactId || !employeeId) return <Navigate to='/404' />;

  return (
    <ConfirmationPopup
      className={className}
      id='delete_employee'
      onOk={onOk}
      title={<FormattedMessage id='app.contact_page.delete_employee.popup_title' />}
    />
  );
};

DeleteEmployeePopup.displayName = 'DeleteEmployeePopup';

export default DeleteEmployeePopup;
