import { FC, useCallback } from 'react';
import useAddDeliveryRate from 'src/api/hooks/mutations/deliveryRates/useAddDeliveryRate';
import DeliveryRateForm from '../DeliveryRateForm';
import { CreateDeliveryRateRequest } from 'src/api';
import { usePopupContext } from 'src/components/Popup/hook';
import { SubmitHandler } from 'react-hook-form';

export type AddDeliveryRateFormProps = {
  className?: string;
};

const AddDeliveryRateForm: FC<AddDeliveryRateFormProps> = ({ className }) => {
  const addDeliveryRate = useAddDeliveryRate();
  const { onClose } = usePopupContext();

  const onSubmit: SubmitHandler<CreateDeliveryRateRequest> = useCallback(
    (data) => {
      addDeliveryRate.mutate(data);
      onClose();
    },
    [addDeliveryRate, onClose],
  );

  return <DeliveryRateForm onSubmit={onSubmit} className={className} />;
};

AddDeliveryRateForm.displayName = 'AddDeliveryRateForm';

export default AddDeliveryRateForm;
