import { createColumnHelper } from '@tanstack/react-table';
import { FC } from 'react';
import { format, parseISO } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import { OrderStatusResponse, ProductServiceHistory } from 'src/api';
import StatusLabel from 'src/components/StatusLabel';
import Table, { TableProps } from 'src/components/Table';
import { WithId } from 'src/types/mutations';
import { ExtendedColumnDef } from 'src/types/table';
import DocumentIcon from '@heroicons/react/24/outline/DocumentIcon';
import HyperLink from 'src/components/common/HyperLink';
import DateFilter from 'src/components/Table/filters/DateFilter';
import TextFilter from 'src/components/Table/filters/TextFilter';
import StatusFilter from 'src/pages/OrdersPage/ServiceTable/filters/StatusFilter';

export type ProductHistoryTableProps = Omit<TableProps<WithId<ProductServiceHistory>>, 'columns'>;

const columnHelper = createColumnHelper<WithId<ProductServiceHistory>>();

const columns: ExtendedColumnDef<WithId<ProductServiceHistory>>[] = [
  {
    ...columnHelper.accessor('serviceDate', {
      id: 'order.datetime',
      header: () => <FormattedMessage id='app.order.datetime' />,
      enableColumnFilter: false,
      enableSorting: true,
      cell: (info) => format(parseISO(info.getValue()), 'dd/MM/yyyy'),
      size: 130,
    }),
    renderFilter: (column) => <DateFilter column={column} />,
  },
  {
    ...columnHelper.accessor('orderNumber', {
      id: 'orderNumber',
      header: () => <FormattedMessage id='app.order.number' />,
      enableColumnFilter: false,
      enableSorting: true,
      cell: (info) => info.renderValue(),
      size: 90,
    }),
  },
  {
    ...columnHelper.accessor((info) => info.status, {
      id: 'order.status.name',
      header: () => <FormattedMessage id='app.order.status' />,
      enableColumnFilter: true,
      enableSorting: true,
      cell: (info) => <StatusLabel name={info.getValue()} />,
      size: 180,
    }),
    renderFilter: (column) => (
      <StatusFilter
        column={column}
        onlyStatuses={[
          OrderStatusResponse.name.COMPLETED,
          OrderStatusResponse.name.INVOICED,
          OrderStatusResponse.name.DELETED,
          OrderStatusResponse.name.UNSUCCESSFUL,
        ]}
      />
    ),
  },
  {
    ...columnHelper.accessor((info) => info.technicians.map((technician) => technician.profile?.name).join(', '), {
      id: 'order.technicians.profile.name',
      header: () => <FormattedMessage id='app.order.technician' />,
      enableColumnFilter: true,
      enableSorting: true,
    }),
    renderFilter: (column) => <TextFilter column={column} />,
  },
  {
    ...columnHelper.accessor('serviceListLink', {
      id: 'serviceList',
      header: () => <FormattedMessage id='app.product.service_list_link' />,
      enableColumnFilter: false,
      enableSorting: false,
      cell: (info) => (
        <HyperLink forceExternal href={info.getValue()} target='_blank'>
          <DocumentIcon className='h-8' />
        </HyperLink>
      ),
      size: 130,
    }),
  },
];

const ProductHistoryTable: FC<ProductHistoryTableProps> = (props) => {
  return <Table {...props} columns={columns} />;
};

ProductHistoryTable.displayName = 'ProductHistoryTable';

export default ProductHistoryTable;
