import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { PaginationState } from '@tanstack/react-table';
import { PaymentMethodsPageResponse } from 'src/api/.gen';
import { SortingQuery } from 'src/api/types/shared/sorting';
import { useApiClient } from 'src/api/useApiClient';
import { DEFAULT_TABLE_PAGINATION } from 'src/constants/table';
import queryKeysFactory from 'src/store/queryKeysFactory';
import { PaymentMethodFilter } from 'src/types/filters';

const usePaymentMethods = (
  filters?: PaymentMethodFilter,
  sorting?: SortingQuery,
  pagination: PaginationState = DEFAULT_TABLE_PAGINATION,
): UseQueryResult<PaymentMethodsPageResponse> => {
  const client = useApiClient();
  const { queryKey } = queryKeysFactory.paymentMethods.list._ctx.search(filters, sorting, pagination);
  return useQuery(
    queryKey,
    async () => {
      const data = await client.paymentMethod.getPaymentMethodList({
        ...filters,
        ...sorting,
        ...pagination,
      });
      return data;
    },
    {
      refetchInterval: false,
      staleTime: 1000 * 60 * 5,
    },
  );
};

export default usePaymentMethods;
