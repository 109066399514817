import clsx from 'clsx';
import { FC, HTMLAttributes } from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactSVG } from 'react-svg';
import useCurrentTenant from 'src/api/hooks/queries/useCurrentTenant';
import useFileUrl from 'src/api/hooks/useFileUrl';

export type LogoProps = Omit<HTMLAttributes<HTMLImageElement>, 'src' | 'srcset'>;

const Logo: FC<LogoProps> = ({ className, ...rest }) => {
  const { data } = useCurrentTenant();
  const { data: url, isLoading, isError } = useFileUrl(data?.logoFileId ?? 0);

  const isSvg = data?.logoMimeType === 'image/svg+xml';

  if (isLoading) return <FormattedMessage id='app.image.loading' />;
  if (isError) return null;

  if (url && isSvg)
    return (
      <ReactSVG
        renumerateIRIElements={false}
        src={url}
        className={clsx(className, 'App-logo-svg-wrapper')}
        style={{
          height: '3rem',
          maxHeight: '3rem',
        }}
      />
    );

  return <img src={url} className={className} {...rest} alt='Logo' />;
};

Logo.displayName = 'Logo';

export default Logo;
