import i18next from 'i18next';
import { useCallback, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import useUpdatePreferredLanguage from 'src/api/hooks/mutations/userProfile/useUpdatePreferredLanguage';
import useCurrentUser from 'src/api/hooks/queries/useCurrentUser';
import { languageState } from 'src/store/atoms/translations';

const useLanguage = (): [string, (value: string) => void] => {
  const { data } = useCurrentUser();
  const [language, setLanguage] = useRecoilState(languageState);
  const updatePreferredLanguage = useUpdatePreferredLanguage();

  useEffect(() => {
    const preffredLanguage = data?.profile?.preferredLanguage;
    if (!preffredLanguage) return;

    setLanguage(preffredLanguage);
    const mappedLanguage = preffredLanguage === 'cz' ? 'cs' : preffredLanguage;
    i18next.changeLanguage(mappedLanguage);
  }, [data?.profile?.preferredLanguage]);

  const handleChangeLanguage = useCallback(
    (preferredLanguage: string) => {
      updatePreferredLanguage.mutate(preferredLanguage);
    },
    [updatePreferredLanguage],
  );

  return [language, handleChangeLanguage];
};

export default useLanguage;
