import React, { FC, useEffect } from 'react';
import { useDrag } from 'react-dnd';
import { OrderStatusResponse } from 'src/api';
import { ITEM_TYPES } from '../../constants';
import useEventToGrid from '../../hooks/useEventToGrid';
import Event, { EventProps } from '../Event';
import ResizableEvent from '../ResizableEvent';

export type DraggableEventProps = Omit<EventProps, 'row' | 'rowSpan' | 'col' | 'colSpan'> & {
  onClick?: (e: React.MouseEvent) => void;
  disableDrag?: boolean;
  resizable?: boolean;
};

const DraggableEvent: FC<DraggableEventProps> = (props) => {
  const eventToGrid = useEventToGrid();

  const { id, disableDrag, technicianId, data, resizable } = props;
  const status = data.status?.name as OrderStatusResponse.name;
  const { row, rowSpan, col, colSpan } = eventToGrid(props);

  const [{ isDragging }, dropRef, preview] = useDrag({
    canDrag: () =>
      !disableDrag && [OrderStatusResponse.name.PLANNED, OrderStatusResponse.name.ROUTINE].includes(status),
    type: ITEM_TYPES.EVENT,
    item: {
      type: ITEM_TYPES.EVENT,
      id,
      row,
      rowSpan,
      col,
      colSpan,
      status,
      technicianId,
      technicianIds: data.technicians?.map((i) => i.id) ?? [],
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  useEffect(() => {
    preview(document.createElement('div'), { captureDraggingState: true });
  }, [preview]);

  const EventComponent = resizable ? ResizableEvent : Event;

  if (isDragging)
    return (
      <EventComponent
        {...props}
        row={row}
        rowSpan={rowSpan}
        col={col}
        colSpan={colSpan}
        style={{ opacity: 0 }}
        ref={dropRef}
      />
    );

  return <EventComponent row={row} rowSpan={rowSpan} col={col} colSpan={colSpan} {...props} ref={dropRef} />;
};

export default DraggableEvent;
