import { FC } from 'react';
import { Switch } from '@headlessui/react';
import clsx from 'clsx';
import { ReactNode } from 'react';

export type ToggleProps = {
  className?: string;
  value: boolean;
  alwayActive?: boolean;
  onChange: (value: boolean) => void;
  srOnlyText?: ReactNode;
  disabled?: boolean;
};

const Toggle: FC<ToggleProps> = ({ className, disabled, alwayActive, srOnlyText, value, onChange }) => {
  return (
    <Switch
      disabled={disabled}
      checked={value}
      onChange={onChange}
      className={clsx(
        className,
        value || alwayActive ? 'bg-primary' : 'bg-gray-200',
        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none',
      )}
    >
      {srOnlyText && <span className='sr-only'>{srOnlyText}</span>}
      <span
        aria-hidden='true'
        className={clsx(
          value ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow transition duration-200 ease-in-out',
        )}
      />
    </Switch>
  );
};

Toggle.displayName = 'Toggle';

export default Toggle;
