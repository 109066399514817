import { twMerge } from 'tailwind-merge';
import { FC } from 'react';
import useFileUrl from 'src/api/hooks/useFileUrl';
import { TrashIcon } from '@heroicons/react/24/outline';

export type UploadedImagePreviewProps = {
  className?: string;
  fileId: number;
  onClose?: () => void;
};

const UploadedImagePreview: FC<UploadedImagePreviewProps> = ({ fileId, className, onClose }) => {
  const { data } = useFileUrl(fileId);

  return (
    <div className={twMerge('w24 relative h-24', className)}>
      {data && <img className='h-full w-full object-contain' src={data} />}
      {onClose && (
        <button
          onClick={onClose}
          type='button'
          className='absolute bg-white border border-primary right-[-0.5rem] top-[-0.5rem] rounded-full bg-primary-content p-[0.2rem] text-primary'
        >
          <TrashIcon className='w-7 h-7' />
        </button>
      )}
    </div>
  );
};

export default UploadedImagePreview;
