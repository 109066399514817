import { TenantConfigDto } from 'src/api/.gen';
import useCurrentTenant from './useCurrentTenant';

export type UseCurrentTenantConfigReturn = {
  data?: TenantConfigDto;
  isLoading: boolean;
  isFetching: boolean;
};

const useCurrentTenantConfig = (): UseCurrentTenantConfigReturn => {
  const currentTenantQuery = useCurrentTenant();

  return {
    ...currentTenantQuery,
    data: currentTenantQuery.data?.tenantConfig?.config ?? undefined,
  };
};

export default useCurrentTenantConfig;
