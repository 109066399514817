import { useFloating, offset, useClick, useDismiss, useInteractions } from '@floating-ui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { startOfMonth, getWeekOfMonth, format, addDays } from 'date-fns';
import { FC, useMemo, useState } from 'react';
import PageCard from 'src/components/PageCard';
import WeekPicker from 'src/components/datetime/WeekPicker';
import { twMerge } from 'tailwind-merge';

export type WeekSelectProps = {
  className?: string;
  weekStart: Date;
  onWeekStartChange: (date: Date) => void;
};

const WeekSelect: FC<WeekSelectProps> = ({ className, weekStart, onWeekStartChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'bottom-end',
    middleware: [offset(10)],
  });

  const click = useClick(context);
  const dismiss = useDismiss(context, { ancestorScroll: true });

  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss]);

  const defaultSelected = useMemo(() => {
    return {
      monthStart: startOfMonth(weekStart),
      weekRow: getWeekOfMonth(weekStart, { weekStartsOn: 1 }) - 1,
      weekStart,
      weekEnd: weekStart,
    };
  }, []);

  return (
    <>
      <div
        className={twMerge('w-max cursor-pointer flex items-center gap-1 text-gray-700', className)}
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        <span>{format(weekStart, 'dd.M')}</span>-<span>{format(addDays(weekStart, 6), 'dd.M')}</span>
        <ChevronDownIcon className='w-6 h-6' />
      </div>
      {isOpen && (
        <div ref={refs.setFloating} style={floatingStyles} className='z-50' {...getFloatingProps()}>
          <PageCard>
            <WeekPicker
              defaultSelected={defaultSelected}
              defaultCurrentMonthStart={startOfMonth(weekStart)}
              onWeekSelect={({ weekStart }) => onWeekStartChange(weekStart)}
            />
          </PageCard>
        </div>
      )}
    </>
  );
};

export default WeekSelect;
