import { FC } from 'react';
import { twMerge } from 'tailwind-merge';
import useGrouppedCurrentWeekOrders from './hooks/useGrouppedCurrentWeekOrders';
import { FormattedMessage } from 'react-intl';
import OrderDayCards from './components/OrderDayCards';
import Loader from 'src/components/utils/Loader';
import { isSameWeek, format } from 'date-fns';
import useGrouppedWeekOrders from './hooks/useGrouppedWeekOrders';

export type OrdersCardListProps = {
  className?: string;
  dayOfTheWeek: Date;
};

const OrdersCardListForCurrentWeek: FC<OrdersCardListProps> = ({ className, dayOfTheWeek }) => {
  const { data, isLoading } = useGrouppedCurrentWeekOrders(dayOfTheWeek);
  const { before, today, tomorrow, afterTomorrow } = data;

  if (isLoading) return <Loader />;

  return (
    <div className={twMerge('flex flex-col gap-4 pb-4', className)}>
      <OrderDayCards label={<FormattedMessage id='app.common.before_today_days' />} items={before} />
      <OrderDayCards label={<FormattedMessage id='app.common.today' />} items={today} />
      <OrderDayCards label={<FormattedMessage id='app.common.tomorrow' />} items={tomorrow} />
      <OrderDayCards label={<FormattedMessage id='app.common.after_tomorrow_days' />} items={afterTomorrow} />
    </div>
  );
};

const OrdersCardListForAnyWeek: FC<OrdersCardListProps> = ({ className, dayOfTheWeek }) => {
  const { data, isLoading } = useGrouppedWeekOrders(dayOfTheWeek);

  if (isLoading) return <Loader />;

  return (
    <div className={twMerge('flex flex-col gap-4 pb-4', className)}>
      {data.map(({ day, items }) => (
        <OrderDayCards key={day.toISOString()} label={format(day, 'dd.MM.yyyy')} items={items} />
      ))}
    </div>
  );
};

const OrdersCardList: FC<OrdersCardListProps> = ({ className, dayOfTheWeek }) => {
  const isCurrentWeek = isSameWeek(dayOfTheWeek, new Date(), { weekStartsOn: 1 });

  if (isCurrentWeek) {
    return <OrdersCardListForCurrentWeek className={className} dayOfTheWeek={dayOfTheWeek} />;
  }

  return <OrdersCardListForAnyWeek className={className} dayOfTheWeek={dayOfTheWeek} />;
};

export default OrdersCardList;
