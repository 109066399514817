import { useCallback } from 'react';
import useLicenses from 'src/api/hooks/queries/useLicenses';

const useGetHasLicense = (): (() => boolean) => {
  const { data } = useLicenses();

  return useCallback(() => (data ?? []).some((license) => license.isValid), [data]);
};

export default useGetHasLicense;
