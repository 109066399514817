import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import AutoPopup from 'src/components/common/AutoPopup';
import UpdateTenantForm from '../UpdateTenantForm';

export type UpdateTenantPopupProps = {
  className?: string;
};

const UpdateTenantPopup: FC<UpdateTenantPopupProps> = ({ className }) => {
  const tenantId = Number(useParams().tenantId);

  return (
    <AutoPopup
      id='update_tenant'
      title={<FormattedMessage id='app.tenants_page.update_tenant.popup_title' />}
      className={className}
    >
      <UpdateTenantForm tenantId={tenantId} />
    </AutoPopup>
  );
};

UpdateTenantPopup.displayName = 'UpdateTenantPopup';

export default UpdateTenantPopup;
