import { FC } from 'react';
import { OrderResponse } from 'src/api';
import CalculatedDeliveryForm from './CalculatedDeliveryForm';
import ManualDeliveryForm from './ManualDeliveryForm';
import TariffDeliveryForm from './TariffDeliveryForm';

export type DeliveryTypeFormSwitchProps = {
  deliveryType: OrderResponse.deliveryType;
  hiddenFields?: string[];
};

const DeliveryTypeFormSwitch: FC<DeliveryTypeFormSwitchProps> = ({ deliveryType, hiddenFields }) => {
  switch (deliveryType) {
    case OrderResponse.deliveryType.TARIFF: {
      return <TariffDeliveryForm hiddenFields={hiddenFields} />;
    }
    case OrderResponse.deliveryType.MANUAL: {
      return <ManualDeliveryForm hiddenFields={hiddenFields} />;
    }
    default: {
      return <CalculatedDeliveryForm hiddenFields={hiddenFields} />;
    }
  }
};

export default DeliveryTypeFormSwitch;
