import { useMemo } from 'react';
import { UserResponse } from 'src/api';
import useCurrentUser from 'src/api/hooks/queries/useCurrentUser';

const useIsCurrentUserAllowedToEdit = (): boolean => {
  const { data: currentUser } = useCurrentUser();

  return useMemo(
    () =>
      !!currentUser &&
      [
        UserResponse.role.SYSTEM_ADMINISTRATOR,
        UserResponse.role.ADMINISTRATOR,
        UserResponse.role.ADMINISTRATOR_TECHNICIAN,
        UserResponse.role.DISPATCHER_TECHNICIAN,
        UserResponse.role.DISPATCHER,
      ].includes(currentUser.role),
    [currentUser],
  );
};

export default useIsCurrentUserAllowedToEdit;
