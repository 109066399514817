import { FC, useState } from 'react';
import { useFloating, offset, useClick, useDismiss, useInteractions } from '@floating-ui/react';
import { AdjustmentsHorizontalIcon } from '@heroicons/react/24/outline';
import PageCard from 'src/components/PageCard';
import { twMerge } from 'tailwind-merge';
import CalendarButton from '../CalendarButton';
import useAllTechnicians from 'src/api/hooks/queries/useAllTechnicians';
import Checkbox from 'src/components/fields/Checkbox';
import { UserResponse } from 'src/api';
import useCalendarSingleTechnicianIdParam from '../../hooks/useCalendarSingleTechnicianIdParam';

export type CalendarSingleTechnicianSelectProps = {
  className?: string;
  technicians: UserResponse[];
};

const CalendarSingleTechnicianSelectContainer: FC<Omit<CalendarSingleTechnicianSelectProps, 'technicians'>> = ({
  className,
}) => {
  const { data: technicians, isLoading } = useAllTechnicians();

  if (isLoading) {
    return (
      <CalendarButton
        disabled
        className={twMerge('w-max min-w-10 cursor-pointer flex items-center gap-1 text-gray-700', className)}
      >
        <AdjustmentsHorizontalIcon className='w-6 h-6' />
      </CalendarButton>
    );
  }

  return <CalendarSingleTechnicianSelect className={className} technicians={technicians ?? []} />;
};

const CalendarSingleTechnicianSelect: FC<CalendarSingleTechnicianSelectProps> = ({ technicians, className }) => {
  const [technicianId, setTechnicianId] = useCalendarSingleTechnicianIdParam();

  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'bottom-end',
    middleware: [offset(10)],
  });

  const click = useClick(context);
  const dismiss = useDismiss(context, { ancestorScroll: true });

  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss]);

  return (
    <>
      <CalendarButton
        className={twMerge('w-10 cursor-pointer flex items-center gap-1 text-gray-700', className)}
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        <AdjustmentsHorizontalIcon className='w-6 h-6' />
      </CalendarButton>
      {isOpen && (
        <div ref={refs.setFloating} style={floatingStyles} className='z-[600]' {...getFloatingProps()}>
          <PageCard>
            {technicians?.map((technician) => (
              <Checkbox
                name={`technician[${technician.id}]`}
                key={technician.id}
                label={technician.profile?.name}
                value={technicianId === technician.id}
                onChange={(nextValue) => {
                  if (nextValue) {
                    setTechnicianId(technician.id);
                    return;
                  }
                  setTechnicianId(null);
                }}
              />
            ))}
          </PageCard>
        </div>
      )}
    </>
  );
};

export default CalendarSingleTechnicianSelectContainer;
