import { useQueryClient } from '@tanstack/react-query';
import { CellContext } from '@tanstack/react-table';
import React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { OrderListItemResponse, UpdateOrderStatusRequest, useApiClient } from 'src/api';
import Button from 'src/components/Button';
import Popup, { PopupProps } from 'src/components/Popup';
import { TextFieldControlled } from 'src/components/fields/Textfield';
import queryKeysFactory from 'src/store/queryKeysFactory';
import useOrder from 'src/api/hooks/queries/useOrder';
import WithLoader from 'src/components/utils/WithLoader';
import { NumberFieldControlled } from 'src/components/fields/NumberField';

export type ServiceInvoicePopupProps = Omit<PopupProps, 'title'> & {
  info: CellContext<OrderListItemResponse, unknown>;
};

const DEFAULT_VALUES = {
  serviceInvoice: '',
  serviceTotalPrice: 0,
  materialPrice: 0,
};

const ServiceInvoicePopup: React.FC<ServiceInvoicePopupProps> = ({ info, open, onClose }) => {
  const queryClient = useQueryClient();
  const { data: order, isLoading } = useOrder(info.row.original.id, { enabled: open });
  const client = useApiClient();
  const methods = useForm<UpdateOrderStatusRequest>({ defaultValues: DEFAULT_VALUES });
  const { control, handleSubmit } = methods;

  const onSubmit: SubmitHandler<UpdateOrderStatusRequest> = async (data) => {
    try {
      await client.orders.invoiceOrder({ id: info.row.original.id, requestBody: data });
      queryClient.invalidateQueries(queryKeysFactory.orders._def);
      toast(<FormattedMessage id='app.toasts.order_invoice_success' />, {
        type: 'success',
      });
    } catch (_) {
      toast(<FormattedMessage id='app.toasts.order_invoice_fail' />, {
        type: 'error',
      });
    } finally {
      onClose();
    }
  };

  return (
    <WithLoader isLoading={!!open && isLoading}>
      <Popup open={open} onClose={onClose} title={<FormattedMessage id='app.invoice_popup.title' />}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextFieldControlled
              name='serviceInvoice'
              control={control}
              label={<FormattedMessage id='app.form.invoice_id.label' />}
            />
            {order?.orderType?.slConfig?.allowManualInvoicePrices && (
              <>
                <NumberFieldControlled
                  control={control}
                  name='serviceTotalPrice'
                  label={<FormattedMessage id='app.finish_order.service_total_price_without_vat' />}
                />
                <NumberFieldControlled
                  control={control}
                  name='materialPrice'
                  label={<FormattedMessage id='app.finish_order.material_price.without_vat' />}
                />
              </>
            )}
            <div className='flex w-full justify-between gap-x-8 mt-10'>
              <Button className='w-full' variant='secondary' type='button' onClick={onClose}>
                <FormattedMessage id='app.buttons.cancel' defaultMessage='Storno' />
              </Button>
              <Button className='w-full' type='submit'>
                <FormattedMessage id='app.buttons.finish' defaultMessage='Dokončit' />
              </Button>
            </div>
          </form>
        </FormProvider>
      </Popup>
    </WithLoader>
  );
};

export default ServiceInvoicePopup;
