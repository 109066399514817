import { FC } from 'react';
import FormBuilder, { FormBuilderProps } from './FormBuilder';
import { UseFormBuilderStateOptions, useFormBuilderState } from './context';

export type FormBuilderContainerProps = Omit<FormBuilderProps, 'state'> & {
  defaultStateOptions: UseFormBuilderStateOptions;
};

const FormBuilderContainer: FC<FormBuilderContainerProps> = (props) => {
  const { defaultStateOptions, ...rest } = props;
  const state = useFormBuilderState(defaultStateOptions);

  return <FormBuilder {...rest} state={state} />;
};

export default FormBuilderContainer;
