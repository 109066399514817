import { FC } from 'react';
import { useParams } from 'react-router-dom';
import useCustomTranslationPack from 'src/api/hooks/queries/useCustomTranslationPack';
import Loader from 'src/components/utils/Loader';
import NotFoundPage from '../NotFoundPage';
import useTranslationPack from 'src/api/hooks/queries/useTranslationPack';
import useUpdateCustomTranslationPack from 'src/api/hooks/mutations/translations/useUpdateCustomTranslationPack';
import { FormattedMessage } from 'react-intl';
import useCurrentTenant from 'src/api/hooks/queries/useCurrentTenant';
import CustomTranslationsList, { CustomTranslationsFormValues } from 'src/components/CustomTranslationsList';

export type CustomTranslationsWizzardPageProps = {
  //
};

const CustomTranslationsWizzardPage: FC<CustomTranslationsWizzardPageProps> = () => {
  const language = useParams().language as string;
  const { data: tenant } = useCurrentTenant();
  const { data: defaultTranslations, isLoading: isLoadingDefaultTranslations } = useTranslationPack(language);
  const { data, isLoading } = useCustomTranslationPack(language);

  const updateTranslationsMutation = useUpdateCustomTranslationPack();
  const onSubmit = (data: CustomTranslationsFormValues): void => {
    const json = Object.fromEntries(data.translations.map(({ key, value }) => [key, value]));

    updateTranslationsMutation.mutate({
      language,
      json,
    });
  };

  if (isLoading || isLoadingDefaultTranslations) return <Loader />;
  if (!data || !defaultTranslations) return <NotFoundPage />;

  return (
    <CustomTranslationsList
      title={<FormattedMessage id='app.pages.custom_translations' values={{ value: tenant?.tenantName ?? '' }} />}
      onSubmit={onSubmit}
      sourceTranslations={defaultTranslations?.json}
      translations={data.json}
    />
  );
};

export default CustomTranslationsWizzardPage;
