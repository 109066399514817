import { FC } from 'react';
import { Navigate } from 'react-router-dom';
import useAllPaymentMethods from 'src/api/hooks/queries/useAllPaymentMethods';
import useAllTechnicians from 'src/api/hooks/queries/useAllTechnicians';
import useStatuses from 'src/api/hooks/queries/useStatuses';
import Loader from 'src/components/utils/Loader';
import OrderToExcelFiltersForm, {
  OrderToExcelFiltersFormProps,
} from '../OrderToExcelFiltersForm/OrderToExcelFiltersForm';

export type OrderToExcelFiltersFormDataProviderProps = Omit<
  OrderToExcelFiltersFormProps,
  'statuses' | 'technicians' | 'paymentMethods'
>;

const OrderToExcelFiltersFormDataProvider: FC<OrderToExcelFiltersFormDataProviderProps> = (props) => {
  const { data: statuses, isLoading: isLoadingStatuses } = useStatuses();
  const { data: technicians, isLoading: isLoadingTechnicians } = useAllTechnicians();
  const { data: paymentMethods, isLoading: isLoadingPaymentMethods } = useAllPaymentMethods();

  if (isLoadingStatuses || isLoadingTechnicians || isLoadingPaymentMethods) return <Loader />;
  if (!statuses || !technicians || !paymentMethods) return <Navigate to='/500' />;

  return (
    <OrderToExcelFiltersForm {...props} paymentMethods={paymentMethods} statuses={statuses} technicians={technicians} />
  );
};

OrderToExcelFiltersFormDataProvider.displayName = 'OrderToExcelFiltersFormDataProvider';

export default OrderToExcelFiltersFormDataProvider;
