import { CheckIcon, DocumentTextIcon } from '@heroicons/react/24/outline';
import { CellContext } from '@tanstack/react-table';
import { FC, useState } from 'react';
import { OrderListItemResponse, OrderStatusResponse, UserResponse } from 'src/api';
import ServiceInvoicePopup from '../../ServiceInvoicePopup';
import useCurrentUser from 'src/api/hooks/queries/useCurrentUser';

export type ActionsCellProps = {
  info: CellContext<OrderListItemResponse, unknown>;
};

const ActionsCell: FC<ActionsCellProps> = ({ info }) => {
  const status = info.row.original.status?.name;
  const { data: currentUser } = useCurrentUser();
  const [open, setOpen] = useState(false);

  if (status === OrderStatusResponse.name.COMPLETED || currentUser?.role === UserResponse.role.SYSTEM_ADMINISTRATOR)
    return (
      <>
        <DocumentTextIcon
          className='h-6'
          onClick={(e) => {
            e.stopPropagation();
            setOpen(true);
          }}
        />
        <ServiceInvoicePopup info={info} open={open} onClose={() => setOpen(false)} />
      </>
    );

  if (status === OrderStatusResponse.name.INVOICED) return <CheckIcon className='h-6' />;
  return null;
};

export default ActionsCell;
