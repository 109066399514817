import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { TranslationPackListItemResponse } from 'src/api';
import { ExtendedColumnDef } from 'src/types/table';
import { createColumnHelper } from '@tanstack/react-table';
import { FormattedMessage } from 'react-intl';
import { MessageId } from 'src/types/commonTypes';
import DumbTable, { DumbTableProps } from 'src/components/Table/DumbTable';

export type TranslationPacksTableProps = Omit<DumbTableProps<TranslationPackListItemResponse>, 'columns'>;

const columnHelper = createColumnHelper<TranslationPackListItemResponse>();

const columns: ExtendedColumnDef<TranslationPackListItemResponse>[] = [
  {
    ...columnHelper.accessor('id', {
      id: 'id',
      header: () => <FormattedMessage id='app.common.id' />,
      enableColumnFilter: false,
      enableSorting: false,
      cell: (info) => info.renderValue(),
      size: 70,
    }),
  },
  {
    ...columnHelper.accessor('language', {
      id: 'language',
      header: () => <FormattedMessage id='app.common.language' />,
      enableColumnFilter: false,
      enableSorting: false,
      cell: (info) => <FormattedMessage id={`app.language.${info.getValue()}` as MessageId} />,
    }),
  },
];

const TranslationPacksTable: FC<TranslationPacksTableProps> = (props) => {
  const navigate = useNavigate();

  return (
    <DumbTable
      noMinHeight
      columns={columns}
      handleRowClick={(original) => navigate(`/translations/${original.language}/wizzard`)}
      {...props}
    />
  );
};

TranslationPacksTable.displayName = 'TranslationPacksTable';

export default TranslationPacksTable;
