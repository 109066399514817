import { ColumnFiltersState } from '@tanstack/react-table';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Outlet } from 'react-router-dom';
import useMaterials from 'src/api/hooks/queries/useMaterials';
import PageContentHeader from 'src/components/PageContentHeader';
import Popup from 'src/components/Popup';
import useTableState from 'src/components/Table/hooks/useTableState';
import useLastDefined from 'src/hooks/useLastDefined';
import usePageBaseState from 'src/hooks/usePageBaseState';
import { MaterialFilter } from 'src/types/filters';
import AddMaterialForm from './AddMaterialForm';
import MaterialTable from './MaterialTable';
import useIsCurrentUserAllowedToEdit from 'src/hooks/useIsCurrentUserAllowedToEdit';
import PageCard from 'src/components/PageCard';

const transformFilters = (filters: ColumnFiltersState): MaterialFilter => ({
  name: (filters.find((i) => i.id === 'materialName')?.value as string) ?? '',
  price: (filters.find((i) => i.id === 'materialValue')?.value as string) ?? '',
  code: (filters.find((i) => i.id === 'materialCode')?.value as string) ?? '',
  supplierCode: (filters.find((i) => i.id === 'supplierCode')?.value as string) ?? '',
});

const defaultSortingState = [{ id: 'materialName', desc: false }];

const MaterialPage: React.FC = () => {
  const { isFilterOpen, isPopupOpen, handleClosePopup, handleOpenPopup, handleToggleFilter } = usePageBaseState();
  const isCurrentUserAllowedToEdit = useIsCurrentUserAllowedToEdit();
  // TODO: set keepFiltersInLocalStorage in config where it is needed
  const {
    columnFilters,
    setColumnFilters,
    sorting,
    setSorting,
    pagination,
    setPagination,
    debouncedServerFriendlyFilters,
    sortingQuery,
  } = useTableState<MaterialFilter>({
    transformFilters,
    defaultSortingState,
    localStorageKey: 'materials',
    keepFiltersInLocalStorage: true,
  });

  const { data, isLoading } = useMaterials(
    { ...debouncedServerFriendlyFilters, withDeleted: true },
    sortingQuery,
    pagination,
  );
  const definedData = useLastDefined(data);

  return (
    <>
      <PageContentHeader
        className='mb-12'
        mobilePrimaryButtonType='new'
        title={<FormattedMessage id='app.material_page.title' />}
        onSecondaryButtonClick={handleToggleFilter}
        onPrimaryButtonClick={handleOpenPopup}
        hidePrimaryButton={!isCurrentUserAllowedToEdit}
        primaryButtonText={
          <FormattedMessage id='app.material_page.new_material_button' defaultMessage='Novy material' />
        }
      />
      <PageCard className='flex flex-col gap-12'>
        <MaterialTable
          isLoading={isLoading}
          data={definedData?.items ?? []}
          isFilterOpen={isFilterOpen}
          columnFilters={columnFilters}
          sorting={sorting}
          pagination={pagination}
          handleFilter={setColumnFilters}
          handleSort={setSorting}
          handlePaginationChange={setPagination}
          paginationMeta={definedData?.meta}
        />

        <Outlet />

        <Popup
          title={<FormattedMessage id='app.material_page.new_material_button' defaultMessage='Novy technik' />}
          onClose={handleClosePopup}
          open={isPopupOpen}
        >
          <AddMaterialForm />
        </Popup>
      </PageCard>
    </>
  );
};

export default MaterialPage;
