import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

type UseUpdateOrderTypeNameVariables = {
  orderTypeId: number;
  name: string;
};
const useUpdateOrderTypeName = (): UseMutationResult<unknown, unknown, UseUpdateOrderTypeNameVariables> => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, UseUpdateOrderTypeNameVariables>(
    async ({ orderTypeId, name }: UseUpdateOrderTypeNameVariables) => {
      await apiClient.orderTypes.updateOrderTypeName({ id: orderTypeId, name });
    },
    {
      onSuccess: (_data, { orderTypeId }) => {
        queryClient.invalidateQueries(queryKeysFactory.orderTypes.detail(orderTypeId).queryKey);
        queryClient.invalidateQueries(queryKeysFactory.orderTypes.list._def);
        toast(<FormattedMessage id='app.toasts.update_success' />, {
          type: 'success',
        });
      },
      onError: () => {
        toast(<FormattedMessage id='app.toasts.update_fail' />, {
          type: 'error',
        });
      },
    },
  );
};

export default useUpdateOrderTypeName;
