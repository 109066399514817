import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import Popup from 'src/components/Popup';
import { orderFormatNumberToParts, orderFormatPartsToNumber } from './helpers';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { OrderFormatSchema } from './schema';
import { NumberFieldControlled } from 'src/components/fields/NumberField';
import { TextFieldControlled } from 'src/components/fields/Textfield';
import useUpdateOrderTypeNumberFormat from 'src/api/hooks/mutations/orderTypes/useUpdateOrderTypeNumberFormat';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Button from 'src/components/Button';
import OrderFormatPreview from './OrderFormatPreview';

export type UpdateOrderFormatPopupProps = {
  orderTypeId: number;
  orderNumberFormat: string | null;
  open: boolean;
  onClose: () => void;
};

const UpdateOrderFormatPopup: FC<UpdateOrderFormatPopupProps> = ({ orderTypeId, orderNumberFormat, open, onClose }) => {
  const form = useForm({
    reValidateMode: 'onChange',
    mode: 'onChange',
    resolver: zodResolver(OrderFormatSchema),
    defaultValues: orderFormatNumberToParts(orderNumberFormat),
  });
  const { control, handleSubmit, formState } = form;
  const updateMutation = useUpdateOrderTypeNumberFormat();
  const onSubmit = async (data: OrderFormatSchema): Promise<void> => {
    onClose();
    await updateMutation.mutateAsync({ orderTypeId, numberFormat: orderFormatPartsToNumber(data) });
  };
  const values = form.watch();

  return (
    <Popup
      open={open}
      onClose={onClose}
      title={<FormattedMessage id='app.settings.order_type.update_order_format_popup.title' />}
    >
      <form className='flex flex-col w-full h-full' onSubmit={handleSubmit(onSubmit)}>
        <FormProvider {...form}>
          <TextFieldControlled
            label={<FormattedMessage id='app.order_format.prefix' />}
            control={control}
            name='prefix'
          />
          <NumberFieldControlled
            control={control}
            name='digits'
            asterisk
            label={<FormattedMessage id='app.order_format.digits' />}
          />
          <TextFieldControlled
            control={control}
            name='postfix'
            label={<FormattedMessage id='app.order_format.postfix' />}
          />
          {formState.isValid && <OrderFormatPreview parts={values} />}
          <div className='mt-[2.875rem] flex justify-end gap-x-6 sm:mt-4'>
            <Button size='normal' type='button' variant='secondary' onClick={onClose}>
              <XMarkIcon className='h-8 w-8 mr-1 text-gray' aria-hidden='true' />
              <FormattedMessage id='app.buttons.close' defaultMessage='Zavřít' />
            </Button>
            <Button size='normal' type='submit'>
              <FormattedMessage id='app.buttons.save' defaultMessage='Uložit' />
            </Button>
          </div>
        </FormProvider>
      </form>
    </Popup>
  );
};

export default UpdateOrderFormatPopup;
