import clsx from 'clsx';
import { FC } from 'react';
import { createGridStyles } from '../Event';
import useEventToGrid from '../../hooks/useEventToGrid';
import { GridEvent } from '../../types';
import { FormattedMessage } from 'react-intl';

export type PotentialEventProps = GridEvent;

const PotentialEvent: FC<PotentialEventProps> = (props) => {
  const eventToGrid = useEventToGrid();
  const { row, rowSpan, col, colSpan } = eventToGrid(props);

  return (
    <li className='relative mt-px flex overflow-hidden' style={createGridStyles(row, rowSpan, col, colSpan)}>
      <div
        className={clsx(
          'cursor-pointer bg-status-new group absolute inset-1 flex flex-col rounded-lg p-2 text-xs overflow-hidden leading-5',
        )}
      >
        <p className='font-semibold'>
          <FormattedMessage id='app.order.potential_order.preview' />
        </p>
      </div>
    </li>
  );
};

export default PotentialEvent;
