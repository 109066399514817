import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useApiClient } from 'src/api';
import HyperLink from 'src/components/common/HyperLink';
import Logo from 'src/components/common/Logo';
import { useAuth } from 'src/store/authContext';
import Button from '../../components/Button';
import { CheckboxControlled } from '../../components/fields/Checkbox';
import { TextFieldControlled } from '../../components/fields/Textfield';
import PageCard from 'src/components/PageCard';

type LoginForm = {
  email: string;
  password: string;
  canSaveToken: boolean;
};

function LoginPage(): React.ReactElement {
  const client = useApiClient();

  const { handleSubmit, control } = useForm<LoginForm>();

  const navigate = useNavigate();

  const { setAuthToken, setAuthEmail } = useAuth();

  const [isLoginInvalid, setIsLoginInvalid] = useState(false);

  const onSubmit: SubmitHandler<LoginForm> = async (data) => {
    try {
      const email = data.email.toLocaleLowerCase().trim();

      const result = await client.auth.login({
        requestBody: {
          userEmail: email,
          userPsw: data.password,
        },
      });
      setAuthToken(result.token, data.canSaveToken);
      setAuthEmail(email);
      navigate('/');
    } catch (error) {
      setIsLoginInvalid(true);
      toast(<FormattedMessage id='app.toasts.login_fail' />, {
        type: 'error',
      });
    }
  };

  return (
    <div className='bg-gray-50 grid place-items-center h-screen'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='grid place-items-center'>
          <div className='flex justify-center items-center w-52 h-28'>
            <Logo className='App-logo' />
          </div>
          <h1 className='text-4xl font-semibold font-openSans text-gray-800 my-10'>
            <FormattedMessage id='app.login.title' />
          </h1>

          <PageCard className='md:p-8 gap-y-4 flex flex-col'>
            <TextFieldControlled control={control} name='email' label={<FormattedMessage id='app.common.email' />} />
            <TextFieldControlled
              control={control}
              name='password'
              type='password'
              label={<FormattedMessage id='app.login.password' />}
            />

            <span className='text-error text-sm flex'>{isLoginInvalid}</span>

            <div className='flex justify-between gap-x-2'>
              <CheckboxControlled
                control={control}
                name='canSaveToken'
                id='canSaveToken'
                label={<FormattedMessage id='app.login.remember_me' />}
              />
              <HyperLink href='/forgot-password' className='font-semibold'>
                <FormattedMessage id='app.login.forgot_password' />
              </HyperLink>
            </div>
            <Button type='submit' className='w-full'>
              <FormattedMessage id='app.login.button' />
            </Button>
          </PageCard>
        </div>
      </form>
    </div>
  );
}

export default LoginPage;
