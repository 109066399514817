import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Outlet } from 'react-router-dom';
import Title from 'src/components/Title';
import ChangePasswordPage from './ChangePassword';
import ProfileSettings from './ProfileSettings';
import PageCard from 'src/components/PageCard';

const ProfileSettingPage: React.FC = () => {
  return (
    <>
      <div className='flex-col w-full'>
        <div className='flex justify-between'>
          <Title className='pb-3 mb-20'>
            <FormattedMessage id='app.profile_settings_page.title' />
          </Title>
        </div>
        <PageCard className='flex flex-col gap-12'>
          <div className='md:w-full w-1/2'>
            <ChangePasswordPage />
          </div>
          <div className='md:w-full w-1/2'>
            <ProfileSettings />
          </div>
        </PageCard>
      </div>
      <Outlet />
    </>
  );
};

export default ProfileSettingPage;
