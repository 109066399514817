// https://www.geeksforgeeks.org/check-if-any-two-intervals-overlap-among-a-given-set-of-intervals/

import { Interval, isDate } from 'date-fns';

const hasOverlapingIntervals = (intervals: Interval[]): boolean => {
  const arr = intervals.map((interval) => ({
    start: isDate(interval.start) ? (interval.start as Date).getTime() : (interval.start as number),
    end: isDate(interval.end) ? (interval.end as Date).getTime() : (interval.end as number),
  }));
  const n = arr.length;

  // Sort intervals in increasing order of start time
  arr.sort((i1, i2) => i1.start - i2.start);

  // In the sorted array, if start time of an interval
  // is less than end of previous interval, then there
  // is an overlap
  for (let i = 1; i < n; i++) if (arr[i - 1].end > arr[i].start) return true;

  // If we reach here, then no overlap
  return false;
};

export default hasOverlapingIntervals;
