import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import useCurrentTenant from 'src/api/hooks/queries/useCurrentTenant';
import { ColorSchemeName } from 'src/constants/colorSchemes';
import isValidColorSchemeName from 'src/helpers/isValidColorSchemeName';
import setColorScheme from 'src/helpers/setColorScheme';

const useApplyColorScheme = (): void => {
  const [searchParams] = useSearchParams();
  const themeFromUrl = searchParams.get('theme');
  const { data: tenant } = useCurrentTenant();

  const theme = themeFromUrl ?? tenant?.colorTheme;

  useEffect(() => {
    if (!theme) return;
    if (!isValidColorSchemeName(theme)) return;

    setColorScheme(theme as ColorSchemeName);
  }, [theme]);
};

export default useApplyColorScheme;
