import { FC, useCallback } from 'react';
import { SubmitHandler } from 'react-hook-form';
import useAddTenant from 'src/api/hooks/mutations/tenant/useAddTenant';
import { usePopupContext } from 'src/components/Popup/hook';
import WithLoader from 'src/components/utils/WithLoader';
import TenantForm from '../TenantForm';
import { TenantSchema } from '../TenantForm/schema';

export type AddTenantFormProps = {
  //
  className?: string;
};

const AddTenantForm: FC<AddTenantFormProps> = ({ className }) => {
  const { onClose } = usePopupContext();
  const addTenant = useAddTenant();

  const onSubmit: SubmitHandler<TenantSchema> = useCallback(async (tenant) => {
    addTenant.mutate(tenant as TenantSchema);
    onClose();
  }, []);

  return (
    <WithLoader isLoading={addTenant.isLoading}>
      <TenantForm onSubmit={onSubmit} className={className} />
    </WithLoader>
  );
};

AddTenantForm.displayName = 'AddTenantForm';

export default AddTenantForm;
