import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { TenantConfigDto } from 'src/api';
import { CheckboxControlled } from 'src/components/fields/Checkbox';
import { CheckboxGroupRhfc } from 'src/components/fields/CheckboxGroup';

const FIELD_OPTIONS = [
  {
    label: () => 'productSerialNumber',
    name: 'productSerialNumber',
    value: 'productSerialNumber',
  },
  {
    label: () => 'productProducer',
    name: 'productProducer',
    value: 'productProducer',
  },
  {
    label: () => 'productNote',
    name: 'productNote',
    value: 'productNote',
  },
  {
    label: () => 'inUseFrom',
    name: 'inUseFrom',
    value: 'inUseFrom',
  },
  {
    label: () => 'routineServiceId',
    name: 'routineServiceId',
    value: 'routineServiceId',
  },
  {
    label: () => 'productDescription',
    name: 'productDescription',
    value: 'productDescription',
  },
  {
    label: () => 'quarante',
    name: 'quarante',
    value: 'quarante',
  },
];

const TenantConfigurationProductForm: FC = () => {
  const { control } = useFormContext<TenantConfigDto>();

  return (
    <>
      <span className='text-2xl my-4'>
        <FormattedMessage id='app.tenants_page.configuration.product_form' />
      </span>
      <div className='flex w-full gap-3 items-center'>
        <details className='flex w-full gap-3'>
          <summary className='flex w-full gap-3'>
            <InformationCircleIcon className='w-6 h-6 text-primary' />
            <FormattedMessage id='app.tenants_page.configuration.show_2_serial_number' />
            <CheckboxControlled control={control} name='productForm.showProductSerialNumber2' />
          </summary>
          <span className='mt-2'>
            <FormattedMessage id='app.tenants_page.configuration.show_2_serial_number.hint' />
          </span>
        </details>
      </div>
      <div className='flex w-full gap-3 items-center mb-4'>
        <details className='flex w-full gap-3'>
          <summary className='flex w-full gap-3'>
            <InformationCircleIcon className='w-6 h-6 text-primary' />
            <FormattedMessage id='app.tenants_page.configuration.show_technologies' />
            <CheckboxControlled control={control} name='productForm.showTechnology' />
          </summary>
          <span className='mt-2'>
            <FormattedMessage id='app.tenants_page.configuration.show_technologies.hint' />
          </span>
        </details>
      </div>
      <div className='flex w-full gap-3 items-center mb-4'>
        <details className='flex w-full gap-3'>
          <summary className='flex w-full gap-3'>
            <InformationCircleIcon className='w-6 h-6 text-primary' />
            <FormattedMessage id='app.tenants_page.configuration.show_warranty' />
            <CheckboxControlled control={control} name='productForm.showWarranty' />
          </summary>
          <span className='mt-2'>
            <FormattedMessage id='app.tenants_page.configuration.show_warranty.hint' />
          </span>
        </details>
      </div>
      <span className='text-xl mb-4'>
        <FormattedMessage id='app.tenants_page.configuration.order_form.hidden_fields' />
      </span>
      <CheckboxGroupRhfc
        className='mb-12'
        name={'productForm.hiddenFields'}
        control={control}
        options={FIELD_OPTIONS}
      />
    </>
  );
};

export default TenantConfigurationProductForm;
