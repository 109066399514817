import { FC } from 'react';
import { ProductDocumentResponse } from 'src/api';
import ParametersRow from 'src/components/common/ParametersRow';
import { FormattedMessage } from 'react-intl';
import DocumentPreview from '../DocumentPreview';
import Button from 'src/components/Button';
import useWithConfirmationPopup from 'src/hooks/popups/useWithConfirmationPopup';
import { TrashIcon } from '@heroicons/react/24/outline';
import useDeleteProductDocument from 'src/api/hooks/mutations/products/useDeleteProductDocument';

export type ProductDocumentsPreviewProps = {
  data?: ProductDocumentResponse[];
  isLoading: boolean;
};

const ProductDocumentsPreview: FC<ProductDocumentsPreviewProps> = ({ isLoading, data }) => {
  const withConfirmationPopup = useWithConfirmationPopup();
  const deleteProductDocument = useDeleteProductDocument();

  if (isLoading) return <FormattedMessage id='app.common.loading' />;
  if (!data || data.length === 0) return <FormattedMessage id='app.common.no_items' />;

  return (
    <>
      {data.map(({ id, fileName, fileId, productId }) => (
        <ParametersRow key={id}>
          <ParametersRow.Label>{fileName}</ParametersRow.Label>
          <ParametersRow.Value>
            <DocumentPreview fileId={fileId} />
            <Button
              variant='cta'
              size='small'
              onClick={withConfirmationPopup({
                onOk: () => deleteProductDocument.mutate({ productId, documentId: id }),
                title: <FormattedMessage id='app.product.delete_product_popup_title' />,
                text: <FormattedMessage id='app.product.delete_product_popup_text' values={{ value: fileName }} />,
              })}
            >
              <TrashIcon className='text-primary h-8 hover:text-primaryFocus' />
            </Button>
          </ParametersRow.Value>
        </ParametersRow>
      ))}
    </>
  );
};

ProductDocumentsPreview.displayName = 'ProductDocumentsPreview';

export default ProductDocumentsPreview;
