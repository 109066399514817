import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { LicenceResponse } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

const useLicenses: () => UseQueryResult<LicenceResponse[]> = () => {
  const client = useApiClient();
  const { queryKey } = queryKeysFactory.licences.list;

  return useQuery(queryKey, () => client.licences.getLicences(), {
    refetchInterval: false,
    staleTime: 1000 * 60 * 5,
  });
};

export default useLicenses;
