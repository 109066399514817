/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, Dispatch, ReactNode, SetStateAction, useMemo, useState } from 'react';
import stub from 'src/utils/stub';

export type AutoPopupsContextValue = [
  value: Record<string, { open: boolean; props?: any }>,
  setValue: Dispatch<SetStateAction<Record<string, { open: boolean; props?: any }>>>,
];

const defautlValue: AutoPopupsContextValue = [{}, stub];

export const AutoPopupsContext = createContext<AutoPopupsContextValue>(defautlValue);

export const AutoPopupsProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [value, setValue] = useState({});
  const contextValue: AutoPopupsContextValue = useMemo(() => [value, setValue], [value]);

  return <AutoPopupsContext.Provider value={contextValue}>{children}</AutoPopupsContext.Provider>;
};
