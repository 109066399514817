import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { CreateDeliveryRateRequest, DeliveryRateResponse } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';
import { WithId } from 'src/types/mutations';

const useUpdateDeliveryRate = (): UseMutationResult<
  DeliveryRateResponse,
  unknown,
  WithId<CreateDeliveryRateRequest>
> => {
  const queryClient = useQueryClient();
  const client = useApiClient();

  return useMutation<DeliveryRateResponse, unknown, WithId<CreateDeliveryRateRequest>>(
    async (tariff: WithId<CreateDeliveryRateRequest>) => {
      const data = await client.deliveryRates.updateDeliveryRate({ id: tariff.id, requestBody: tariff });
      return data as DeliveryRateResponse;
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(queryKeysFactory.deliveryRates.list.queryKey);
        queryClient.invalidateQueries(queryKeysFactory.deliveryRates.detail(data.id).queryKey);
        toast(<FormattedMessage id='app.toasts.update_success' />, {
          type: 'success',
        });
      },
      onError: () => {
        toast(<FormattedMessage id='app.toasts.update_fail' />, {
          type: 'error',
        });
      },
    },
  );
};

export default useUpdateDeliveryRate;
