import clsx from 'clsx';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { VatTariffResponse } from 'src/api';
import ParametersRow from 'src/components/common/ParametersRow';

export type VatTariffPreviewProps = {
  className?: string;
  data: VatTariffResponse;
};

const VatTariffPreview: FC<VatTariffPreviewProps> = ({ className, data }) => {
  return (
    <div className={clsx(className, 'flex flex-col w-full')}>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.vat_tariff.value' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.tariffValue}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.vat.name' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.name}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.common.active' />
        </ParametersRow.Label>
        <ParametersRow.Value>
          {data.deletedAt ? <FormattedMessage id='app.common.no' /> : <FormattedMessage id='app.common.yes' />}
        </ParametersRow.Value>
      </ParametersRow>
    </div>
  );
};

export default VatTariffPreview;
