import { FC, HTMLAttributes, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Navigate, useParams } from 'react-router-dom';
import useDeleteMaterial from 'src/api/hooks/mutations/material/useDeleteMaterial';
import ConfirmationPopup from 'src/components/common/ConfirmationPopup';

export type DeleteMaterialPopupProps = {
  //
} & HTMLAttributes<HTMLDivElement>;

const DeleteMaterialPopup: FC<DeleteMaterialPopupProps> = ({ className }) => {
  const materialId = Number(useParams().materialId);

  const deleteMaterial = useDeleteMaterial();

  const onOk = useCallback(() => {
    deleteMaterial.mutate({ materialId });
  }, [materialId, deleteMaterial]);

  if (!materialId) return <Navigate to='/404' />;

  return (
    <ConfirmationPopup
      className={className}
      id='delete_material'
      onOk={onOk}
      title={<FormattedMessage id='app.material_page.delete_material.popup_title' />}
    />
  );
};

DeleteMaterialPopup.displayName = 'DeleteMaterialPopup';

export default DeleteMaterialPopup;
