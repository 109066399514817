import { FC } from 'react';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import Title from 'src/components/Title';

export type ExpiredUnpaidDemoPageProps = {
  //
};

const ExpiredUnpaidDemoPage: FC<ExpiredUnpaidDemoPageProps> = () => {
  return (
    <div className={clsx('flex flex-col min-h-screen justify-center items-center w-full h-full grow')}>
      <div className='flex items-center'>
        <div className='flex flex-col items-center gap-y-8 max-w-[90vw]'>
          <Title className='text-7xl'>
            <FormattedMessage id='app.demo.expired.title' />
          </Title>
          <p className='text-3xl text-center'>
            <FormattedMessage id='app.demo.expired.text' />
          </p>
        </div>
      </div>
    </div>
  );
};

ExpiredUnpaidDemoPage.displayName = 'ExpiredUnpaidDemoPage';

export default ExpiredUnpaidDemoPage;
