import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { TranslationPackResponse } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

const useDefaultTranslationPack = (
  language: string,
  options?: UseQueryOptions<TranslationPackResponse>,
): UseQueryResult<TranslationPackResponse> => {
  const client = useApiClient();

  const { queryKey } = queryKeysFactory.translationPacks.defaultDetail(language);

  return useQuery({
    queryKey,
    queryFn: async () => {
      const data = await client.translationPacks.getTranslationsPack({ language });

      return data;
    },
    ...options,
  });
};

export default useDefaultTranslationPack;
