import { PlusCircleIcon, TrashIcon } from '@heroicons/react/24/outline';
import { FC } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { OrderResponse } from 'src/api';
import useAllRoutineServices from 'src/api/hooks/queries/useAllRoutineServices';
import useBranchProducts from 'src/api/hooks/queries/useBranchProducts';
import useContactProducts from 'src/api/hooks/queries/useContactProducts';
import Button from 'src/components/Button';
import { CheckboxControlled } from 'src/components/fields/Checkbox';
import { DescriptionFieldControlled } from 'src/components/fields/DescriptionField';
import { SelectControlled } from 'src/components/fields/Select';
import useGetIsHiddenProductFormField from 'src/hooks/formsConfig/useGetIsHiddenProductFormField';
import { OrderProductSchema, OrderSchema } from '../schema';
import ServiceNewProductForm from './ServiceNewProductForm';
import ServiceNewProductFormWithTemplates from './ServiceNewProductFormWithTemplates';

const PRODUCT_FIELDS = ['productName', 'productProducer', 'productSerialNumber', 'productNote'] as const;

export type ServiceProductsFormProps = {
  disableCreateNew?: boolean;
  useProductTemplates?: boolean;
};

const ServiceProductsForm: FC<ServiceProductsFormProps> = ({ disableCreateNew, useProductTemplates }) => {
  const intl = useIntl();
  const { control, watch, setValue, resetField } = useFormContext<OrderSchema>();
  const { fields: productFields, remove, append } = useFieldArray<OrderSchema>({ name: 'orderProducts', control });
  const branchId = watch('branch.branchId');
  const contactId = watch('company.companyId');

  const createNewBranch = watch('branch.createNewBranch');
  const createNewCompany = watch('company.createNewCompany');

  const category = watch('category');

  const { data: branchProducts } = useBranchProducts(branchId ?? 0, {
    enabled: category === OrderResponse.category.B2B && !!branchId,
  });
  const { data: contactProducts } = useContactProducts(contactId ?? 0, {
    enabled: category === OrderResponse.category.B2C && !!contactId,
  });

  const products = category === OrderResponse.category.B2B ? branchProducts : contactProducts;

  const { data: rountineServices } = useAllRoutineServices();

  const handleCreateNewProductChangeFactory = (index: number) => (value: boolean | null | undefined) => {
    if (!value) {
      resetField(`orderProducts.${index}.product`);
      return;
    }

    resetField(`orderProducts.${index}.productId`);
    PRODUCT_FIELDS.forEach((field) => setValue(`orderProducts.${index}.${field}` as keyof OrderSchema, ''));
  };

  const allowRemovingProducts = category === OrderResponse.category.B2C || productFields.length > 1;

  const getIsHiddenField = useGetIsHiddenProductFormField();

  return (
    <div className='flex flex-col gap-y-4'>
      <div className='font-bold text-gray-800 text-2xl flex'>
        <FormattedMessage id='app.order.section_title.products' />
        <Button
          variant='cta'
          size='small'
          className='ml-2'
          onClick={() =>
            append({
              productId: null,
              routineServiceId: null,
              productDescription: '',
              quarante: false,
              createNewProduct: createNewCompany || createNewBranch,
            } as unknown as OrderProductSchema)
          }
        >
          <PlusCircleIcon className='h-6 mr-1' />
          <FormattedMessage id='app.buttons.add' />
        </Button>
      </div>
      {productFields.map((field, index) => {
        const createNewProduct = watch(`orderProducts.${index}.createNewProduct`);

        return (
          <div key={field.id} className='mb-8 grid grid-cols-2 gap-x-2 gap-y-4'>
            <div className='font-semibold text-xl flex'>
              <span>
                <FormattedMessage id='app.order.section_title.product' values={{ value: index + 1 }} />
              </span>
              {allowRemovingProducts && (
                <Button variant='cta' size='small' onClick={() => remove(index)}>
                  <TrashIcon className='h-6' />
                </Button>
              )}
            </div>

            {!disableCreateNew && (
              <CheckboxControlled
                className='col-span-2'
                label={<FormattedMessage id='app.order.new_product' />}
                control={control}
                name={`orderProducts.${index}.createNewProduct`}
                disabled={!!createNewCompany || !!createNewBranch}
                extraOnChange={handleCreateNewProductChangeFactory(index)}
              />
            )}

            {createNewProduct ? (
              useProductTemplates ? (
                <ServiceNewProductFormWithTemplates selectClassName='col-span-2' index={index} />
              ) : (
                <ServiceNewProductForm index={index} />
              )
            ) : (
              <SelectControlled
                asterisk
                className='col-span-2'
                label={<FormattedMessage id='app.order.product' />}
                control={control}
                name={`orderProducts.${index}.productId`}
                options={products ?? []}
                getOptionLabel={(option) =>
                  option.productSerialNumber
                    ? `${option.productName} (${option.productSerialNumber})`
                    : option.productName
                }
                getOptionValue={(option) => option.id}
                shouldHideOption={(option) =>
                  productFields.some((f) => (f as { productId?: number }).productId === option.id)
                }
              />
            )}

            {!getIsHiddenField('quarante') && (
              <SelectControlled
                asterisk
                label={<FormattedMessage id='app.order.quarantee' />}
                control={control}
                name={`orderProducts.${index}.quarante`}
                options={[
                  { label: intl.formatMessage({ id: 'app.common.yes' }), value: true },
                  { label: intl.formatMessage({ id: 'app.common.no' }), value: false },
                ]}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
              />
            )}

            {!getIsHiddenField('routineServiceId') && (
              <SelectControlled
                control={control}
                name={`orderProducts.${index}.routineServiceId`}
                asterisk
                label={<FormattedMessage id='app.product.routine_service' />}
                options={[
                  { routineName: intl.formatMessage({ id: 'app.common.no' }), id: null },
                  ...(rountineServices ?? []),
                ]}
                getOptionLabel={(option) => option.routineName}
                getOptionValue={(option) => option.id}
              />
            )}

            {!getIsHiddenField('routineServiceId') && (
              <DescriptionFieldControlled
                control={control}
                className='col-span-2'
                name={`orderProducts.${index}.productDescription`}
                label={<FormattedMessage id='app.order.product_description' />}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ServiceProductsForm;
