import { FC } from 'react';
import { useParams } from 'react-router-dom';
import useOrderType from 'src/api/hooks/queries/useOrderType';
import Loader from 'src/components/utils/Loader';
import NotFoundPage from '../NotFoundPage';
import CustomTranslationsList, { CustomTranslationsFormValues } from 'src/components/CustomTranslationsList';
import usePdfTranslationPack from 'src/api/hooks/queries/usePdfTranslationPack';
import { FormattedMessage } from 'react-intl';
import useUpdateOrderTypeTranslationPack from 'src/api/hooks/mutations/translations/useUpdateOrderTypeTranslationPack';

export type OrderTypeTranslationDetailPageProps = {
  //
};

const OrderTypeTranslationDetailPage: FC<OrderTypeTranslationDetailPageProps> = () => {
  const orderTypeId = Number(useParams().orderTypeId);
  const language = useParams().language as string;
  const { isLoading, data } = useOrderType(orderTypeId);
  const { isLoading: isLoadingPdfTranslations, data: pdfTranslations } = usePdfTranslationPack(language);
  const translation = data?.slConfig?.pdfTranslations?.[language] ?? {};

  const updateTranslationsMutation = useUpdateOrderTypeTranslationPack();
  const onSubmit = (data: CustomTranslationsFormValues): void => {
    const json = Object.fromEntries(data.translations.map(({ key, value }) => [key, value]));

    updateTranslationsMutation.mutate({
      orderTypeId,
      language,
      json,
    });
  };

  if (isLoading || isLoadingPdfTranslations) return <Loader />;
  if (!data || !pdfTranslations) return <NotFoundPage />;

  return (
    <CustomTranslationsList
      title={
        <FormattedMessage
          id='app.order_type.translations.title'
          values={{
            orderType: data.name,
            language,
          }}
        />
      }
      onSubmit={onSubmit}
      sourceTranslations={pdfTranslations.json}
      translations={translation}
    />
  );
};

export default OrderTypeTranslationDetailPage;
