import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { MaterialResponse } from 'src/api';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

export type UseDeleteMaterialData = {
  materialId: number;
};

const useDeleteMaterial = (): UseMutationResult<MaterialResponse, unknown, UseDeleteMaterialData> => {
  const queryClient = useQueryClient();
  const client = useApiClient();

  return useMutation<MaterialResponse, unknown, UseDeleteMaterialData>(
    async (materialData: UseDeleteMaterialData) => {
      const data = await client.material.deleteMaterial({ id: materialData.materialId });
      return data as MaterialResponse;
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(queryKeysFactory.materials.list.queryKey);
        queryClient.invalidateQueries(queryKeysFactory.materials.detail(variables.materialId));
        toast(<FormattedMessage id='app.toasts.delete_success' />, {
          type: 'success',
        });
      },
      onError: () => {
        toast(<FormattedMessage id='app.toasts.delete_fail' />, {
          type: 'error',
        });
      },
    },
  );
};

export default useDeleteMaterial;
