import { LinkIcon } from '@heroicons/react/24/outline';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { UserResponse } from 'src/api';
import HyperLink from 'src/components/common/HyperLink';
import Popup from 'src/components/Popup';
import UserPreview from 'src/pages/UserDetailPage/UserPreview';

export type TechnicianInfoPopupProps = {
  className?: string;
  data: UserResponse;
  open: boolean;
  onClose: () => void;
};

export type TechnicianInfoPopupContainerProps = Omit<TechnicianInfoPopupProps, 'data'> & {
  isLoading: boolean;
  data?: UserResponse;
};

const TechnicianInfoPopup: FC<TechnicianInfoPopupProps> = ({ className, onClose, open, data }) => {
  return (
    <Popup
      open={open}
      onClose={onClose}
      title={
        <div className='flex items-center gap-x-2'>
          <FormattedMessage
            id='app.calendar.technician_info_popup.title'
            values={{
              value: data.profile?.name,
            }}
          />
          <HyperLink href={`/admin/user/${data.id}`}>
            <LinkIcon className='w-7' />
          </HyperLink>
        </div>
      }
      className={className}
    >
      <UserPreview data={data} />
    </Popup>
  );
};

TechnicianInfoPopup.displayName = 'TechnicianInfoPopup';

export default TechnicianInfoPopup;
