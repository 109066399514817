import { useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import React, { useRef } from 'react';
import { Link, Navigate, useLocation } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { UserResponse } from 'src/api';
import useCurrentTenant from 'src/api/hooks/queries/useCurrentTenant';
import {
  defaultIsSelectedNavigationItem,
  helpNavigationItem,
  logoutNavigationItem,
  normalMenuNavigationItems,
  settingsNavigationItems,
  systemAdminNavigationItems,
} from 'src/constants/navigation';
import useGetIsRoleAllowed from 'src/hooks/useGetIsRoleAllowed';
import { useAuth } from 'src/store/authContext';
import Logo from '../common/Logo';
import SidebarItem from '../SidebarItem';
import Loader from '../utils/Loader';
import { adminJoyrideState } from 'src/store/atoms/joyrides';
import sleep from 'src/utils/sleep';

const itemIconProps = {};

export type SidePanelProps = {
  className?: string;
  onItemClick?: () => void;
};

const SidePanel: React.FC<SidePanelProps> = ({ className, onItemClick }) => {
  const { data: currentTenant, isLoading } = useCurrentTenant();
  const { pathname } = useLocation();
  const queryClient = useQueryClient();
  const getIsRoleAllowed = useGetIsRoleAllowed();
  const { clearAuth } = useAuth();
  const setAdminJoyrideState = useSetRecoilState(adminJoyrideState);
  const helpItemRef = useRef<HTMLDivElement>(null);

  if (isLoading) return <Loader />;
  if (!currentTenant) return <Navigate to='/500' />;

  return (
    <div
      className={clsx('w-80 px-2 h-full bg-white flex flex-col overflow-y-auto border-r border-r-gray-300', className)}
    >
      <div className='w-full flex items-center my-10 h-12 px-[14px]'>
        <Link to='/' className='w-full h-full'>
          <Logo className='App-logo max-h-12 max-w-[160px] px-1 flex items-center' />
        </Link>
      </div>

      {getIsRoleAllowed([UserResponse.role.SYSTEM_ADMINISTRATOR]) &&
        systemAdminNavigationItems.map((item, i) => (
          <SidebarItem
            onClick={onItemClick}
            key={i}
            data-joyride={item.dataJoyride}
            isSelected={
              item.getIsSelected?.(pathname, item.href) ?? defaultIsSelectedNavigationItem(pathname, item.href)
            }
            text={item.renderText()}
            href={item.href}
            icon={item.renderIcon(itemIconProps)}
          />
        ))}

      {normalMenuNavigationItems.map(
        (item) =>
          (!item.allowedRoles || getIsRoleAllowed(item.allowedRoles)) &&
          !item.shouldHideOnThisTenant?.(currentTenant) && (
            <SidebarItem
              onClick={onItemClick}
              key={item.href}
              data-joyride={item.dataJoyride}
              isSelected={
                item.getIsSelected?.(pathname, item.href) ?? defaultIsSelectedNavigationItem(pathname, item.href)
              }
              text={item.renderText()}
              href={item.href}
              icon={item.renderIcon(itemIconProps)}
            />
          ),
      )}

      <div className='flex flex-col mt-auto'>
        {settingsNavigationItems.map(
          (item, i) =>
            (!item.allowedRoles || getIsRoleAllowed(item.allowedRoles)) && (
              <SidebarItem
                onClick={onItemClick}
                data-joyride={item.dataJoyride}
                key={i}
                isSelected={
                  item.getIsSelected?.(pathname, item.href) ?? defaultIsSelectedNavigationItem(pathname, item.href)
                }
                text={item.renderText()}
                href={item.href}
                icon={item.renderIcon(itemIconProps)}
              />
            ),
        )}
        {!helpNavigationItem.shouldHideOnThisTenant?.(currentTenant) && (
          <SidebarItem
            isSelected={false}
            ref={helpItemRef}
            text={helpNavigationItem.renderText()}
            href={helpNavigationItem.href}
            icon={helpNavigationItem.renderIcon(itemIconProps)}
            onClick={async () => {
              await sleep(100);
              window.scrollTo(0, 0);
              helpItemRef.current?.scrollIntoView();
              setAdminJoyrideState((state) => ({ ...state, stepIndex: 0, run: true }));
              onItemClick?.();
            }}
          />
        )}
        <SidebarItem
          isSelected={false}
          text={logoutNavigationItem.renderText()}
          href={logoutNavigationItem.href}
          icon={logoutNavigationItem.renderIcon(itemIconProps)}
          onClick={() => {
            onItemClick?.();
            clearAuth();
            queryClient.clear();
          }}
        />
      </div>
    </div>
  );
};

export default SidePanel;
