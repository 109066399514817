import { useMemo } from 'react';
import useCurrentTenantConfig from 'src/api/hooks/queries/useCurrentTenantConfig';
import { ExtendedColumnDef } from 'src/types/table';

export const useVisibleColumns = <T>(columns: ExtendedColumnDef<T>[]): ExtendedColumnDef<T>[] => {
  const { data: tenantConfig } = useCurrentTenantConfig();
  const visibleColumns = useMemo(
    () =>
      columns.filter((column) => {
        if (!column.showOnlyWhen) return true;
        return column.showOnlyWhen(tenantConfig);
      }),
    [columns, tenantConfig],
  );

  return visibleColumns;
};
