import { PlusCircleIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from 'src/components/Button';
import { ItemBase, ParametersListProps } from '../ParametersList/ParametersList';
import ParametersRow from '../ParametersRow';
import { ParametersRowWithPreview } from '../ParametersRow/ParametersRow';

export type ItemBaseWithPreview = ItemBase & { renderPreview: () => ReactNode };

export type ParametersListWithPreviewsProps<T extends ItemBaseWithPreview> = ParametersListProps<T>;

const ParametersListWithPreviews = <T extends ItemBaseWithPreview>({
  className,
  title,
  onAddClick,
  hideAddButton,
  data,
}: ParametersListWithPreviewsProps<T>): JSX.Element => {
  return (
    <div className={clsx('flex flex-col w-full', className)}>
      <>
        <ParametersRow.Title>
          {title}
          {!hideAddButton && onAddClick && (
            <Button variant='cta' size='small' className='ml-2' onClick={onAddClick}>
              <PlusCircleIcon className='h-6 mr-1' />
              <FormattedMessage id='app.buttons.add' />
            </Button>
          )}
        </ParametersRow.Title>
        {data.length > 0 ? (
          data.map((item, i) => (
            <ParametersRowWithPreview key={i} label={i + 1} value={item.title} renderPreview={item.renderPreview} />
          ))
        ) : (
          <FormattedMessage id='app.common.no_items' defaultMessage='Zadny zaznam nenalezen.' />
        )}
      </>
    </div>
  );
};

ParametersListWithPreviews.displayName = 'ParametersListWithPreviews';

export default ParametersListWithPreviews;
