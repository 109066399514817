import { FC, HTMLAttributes } from 'react';
import clsx from 'clsx';
import { MaterialResponse } from 'src/api';
import ParametersRow from 'src/components/common/ParametersRow';
import { FormattedMessage } from 'react-intl';

export type MaterialPreviewProps = {
  data: MaterialResponse;
} & HTMLAttributes<HTMLDivElement>;

const MaterialPreview: FC<MaterialPreviewProps> = ({ data, className }) => {
  return (
    <div className={clsx('flex flex-col w-full', className)}>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.material.name' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.materialName}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.material.code' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.materialCode}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.material.supplier_code' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.supplierCode}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.material.value' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.materialValue}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.common.price_eur' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.materialValueEur}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.common.active' />
        </ParametersRow.Label>
        <ParametersRow.Value>
          {!data.deletedAt ? <FormattedMessage id='app.common.yes' /> : <FormattedMessage id='app.common.no' />}
        </ParametersRow.Value>
      </ParametersRow>
    </div>
  );
};

MaterialPreview.displayName = 'MaterialPreview';

export default MaterialPreview;
