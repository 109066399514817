import { FC } from 'react';
import { ExtendedColumnDef } from 'src/types/table';
import { StockOrderResponse } from 'src/api';
import { FormattedMessage } from 'react-intl';
import { createColumnHelper } from '@tanstack/react-table';
import Table, { TableProps } from 'src/components/Table';
import { useNavigate } from 'react-router-dom';
import TextFilter from 'src/components/Table/filters/TextFilter';
import { format, parseISO } from 'date-fns';

export type StockOrdersTableProps = Omit<TableProps<StockOrderResponse>, 'columns'>;

const columnHelper = createColumnHelper<StockOrderResponse>();

const columns: ExtendedColumnDef<StockOrderResponse>[] = [
  {
    ...columnHelper.accessor('company.companyName', {
      id: 'companyName',
      header: () => <FormattedMessage id='app.stock_order.customer' />,
      enableColumnFilter: false,
      enableSorting: false,
      cell: (info) => info.row.original.company?.companyName ?? '',
    }),
    renderFilter: (column) => <TextFilter column={column} />,
  },
  {
    ...columnHelper.accessor('product.productName', {
      id: 'productName',
      header: () => <FormattedMessage id='app.stock_order.product_name' />,
      enableColumnFilter: true,
      enableSorting: false,
      cell: (info) => info.row.original.product?.productName ?? '',
    }),
    renderFilter: (column) => <TextFilter column={column} />,
  },
  {
    ...columnHelper.accessor('stockOrderMaterials.material', {
      id: 'materialName',
      header: () => <FormattedMessage id='app.stock_order.material' />,
      enableColumnFilter: true,
      enableSorting: false,
      cell: (info) => info.row.original.stockOrderMaterials?.map((m) => m?.material?.materialName).join(', ') ?? '',
    }),
    renderFilter: (column) => <TextFilter column={column} />,
  },
  {
    ...columnHelper.accessor('text', {
      id: 'text',
      header: () => <FormattedMessage id='app.stock_order.text' />,
      enableColumnFilter: true,
      enableSorting: false,
      cell: (info) => info.row.original.text?.slice(0, 48) ?? '',
    }),
    renderFilter: (column) => <TextFilter column={column} />,
  },
  {
    ...columnHelper.accessor('createdAt', {
      id: 'createdAt',
      header: () => <FormattedMessage id='app.stock_order.created_ad' />,
      enableColumnFilter: false,
      enableSorting: false,
      cell: (info) => format(parseISO(info.getValue()), 'HH:mm d.M.yyyy') ?? '',
    }),
  },
];

const StockOrdersTable: FC<StockOrdersTableProps> = (props) => {
  const navigate = useNavigate();

  return (
    <Table columns={columns} handleRowClick={(original) => navigate(`/admin/stock-orders/${original.id}`)} {...props} />
  );
};

StockOrdersTable.displayName = 'StockOrdersTable';

export default StockOrdersTable;
