import { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { OrderResponse } from 'src/api';
import useAllPaymentMethods from 'src/api/hooks/queries/useAllPaymentMethods';
import useCurrentTenant from 'src/api/hooks/queries/useCurrentTenant';
import FinishFormGenTemplate from 'src/components/FinishFormGenTemplate';
import Loader from 'src/components/utils/Loader';
import DeliveryForm from '../parts/DeliveryForm/DeliveryForm';
import PaymentForm from '../parts/PaymentForm';
import SignaturesForm from '../parts/SignaturesForm';
import WorkForm from '../parts/WorkForm';
import useCurrentTenantConfig from 'src/api/hooks/queries/useCurrentTenantConfig';
import { FormattedMessage } from 'react-intl';
import RealTimeForm from '../parts/RealTimeForm';
import MaterialsSaleForm from '../parts/MaterialsSaleForm';

export type GeneralInfoTabProps = {
  order: OrderResponse;
};

const GeneralInfoTab: FC<GeneralInfoTabProps> = ({ order }) => {
  const { data: tenant, isLoading: isLoadingTenant } = useCurrentTenant();
  const { data: tenantConfig, isLoading: isLoadingTenantConfig } = useCurrentTenantConfig();
  const { data: paymentMethods, isLoading: isLoadingPaymentMethods } = useAllPaymentMethods();

  const shouldShowWorkAndTravel = order.orderType?.slConfig?.pdfSectionsOrder?.includes('worktravel') ?? true;
  const shouldShowPayment =
    !order.orderType?.slConfig?.hidePaymentSection &&
    (order.orderType?.slConfig?.pdfSectionsOrder?.includes('summary') ?? true);

  const shoudlHideDelivery = order.orderType?.slConfig?.hideDeliverySection ?? false;
  const shoudlHideWork = order.orderType?.slConfig?.hideWorkSection ?? false;
  const shouldShowRealTime = order.orderType?.slConfig?.allowManualRealTime ?? false;
  const hiddenFields = order.orderType?.slConfig?.generalHiddenFeilds ?? [];
  const showSale = tenantConfig?.enableDiscountsInSl ?? false;

  if (isLoadingPaymentMethods || isLoadingTenant || isLoadingTenantConfig) return <Loader />;
  if (!paymentMethods || !tenant || !tenantConfig) return <Navigate to='/500' />;

  return (
    <>
      {tenantConfig?.contactForm?.showInternalCategory && (
        <div className='flex gap-x-1 mb-14 mt-[-1rem]'>
          <span>
            <FormattedMessage id='app.contact.internal_category' />
          </span>
          :<span>{order?.company?.internalCategory}</span>
        </div>
      )}
      {shouldShowWorkAndTravel && (
        <>
          {!shoudlHideDelivery && <DeliveryForm order={order} hiddenFields={hiddenFields} showSale={showSale} />}
          {!shoudlHideWork && <WorkForm order={order} hiddenFields={hiddenFields} showSale={showSale} />}
        </>
      )}
      {shouldShowPayment && <PaymentForm order={order} tenantConfig={tenantConfig} paymentMethods={paymentMethods} />}
      {shouldShowRealTime && <RealTimeForm />}
      {showSale && <MaterialsSaleForm />}

      {order.orderType?.slConfig?.sections && <FinishFormGenTemplate sections={order.orderType.slConfig?.sections} />}
      <SignaturesForm allowAbsentCustomer={tenantConfig.allowAbsentCustomer} />
    </>
  );
};

export default GeneralInfoTab;
