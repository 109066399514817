import { ReactElement, useEffect } from 'react';
import { ContactAresDataResponse } from 'src/api';
import useAresData from 'src/api/hooks/queries/useAresData';
import Textfield, { TextFieldProps } from 'src/components/fields/Textfield';
import useDebounce from 'src/hooks/useDebounce';

export type AresIcoTextfieldProps = TextFieldProps & {
  onIcoSelect: (data: ContactAresDataResponse) => void;
};

const AresIcoTextfield = (props: Omit<AresIcoTextfieldProps, 'ref'>): ReactElement => {
  const { onIcoSelect } = props;
  const debouncedValue = useDebounce<string>((props.value ?? '') as string, 500);
  const { data } = useAresData(debouncedValue);

  useEffect(() => {
    if (!data) return;

    onIcoSelect(data);
  }, [data]);

  return <Textfield {...props} />;
};

AresIcoTextfield.displayName = 'AresIcoTextfield';

export default AresIcoTextfield;
