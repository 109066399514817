import clsx from 'clsx';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Navigate } from 'react-router-dom';
import { OrderTypeRepsonse } from 'src/api';
import useAllPaymentMethods from 'src/api/hooks/queries/useAllPaymentMethods';
import Title from 'src/components/Title';
import Loader from 'src/components/utils/Loader';
import OrderTypeConfigurationForm from '../OrderTypeConfigurationForm';

export type OrderTypeConfigurationWizzardProps = {
  className?: string;
  orderType: OrderTypeRepsonse;
};

const OrderTypeConfigurationWizzard: FC<OrderTypeConfigurationWizzardProps> = ({ className, orderType }) => {
  const { data: paymentMethods, isLoading } = useAllPaymentMethods();

  if (isLoading) return <Loader />;
  if (!paymentMethods) return <Navigate to='/500' />;

  return (
    <div className={clsx(className, 'flex flex-col gap-y-10')}>
      <Title>
        <FormattedMessage id='app.common.configuration' />
      </Title>
      <OrderTypeConfigurationForm orderType={orderType} paymentMethods={paymentMethods} />
    </div>
  );
};

OrderTypeConfigurationWizzard.displayName = 'OrderTypeConfigurationWizzard';

export default OrderTypeConfigurationWizzard;
