import { OrderResponse, OrderStatusResponse } from 'src/api';

export const finishedStatuses = [
  OrderStatusResponse.name.COMPLETED,
  OrderStatusResponse.name.INVOICED,
  OrderStatusResponse.name.UNSUCCESSFUL,
  OrderStatusResponse.name.DELETED,
];

export const statusesWithLinkToPdf = [OrderStatusResponse.name.COMPLETED, OrderStatusResponse.name.INVOICED];

export const getCanFinishB2C = (order: OrderResponse): boolean => {
  const hasTechnicians = order.technicians?.length > 0;
  const isNotFinished = !finishedStatuses.includes(order.status as OrderStatusResponse.name);

  return hasTechnicians && isNotFinished;
};

export const getCanFinishB2B = (order: OrderResponse): boolean => {
  const hasTechnicians = order.technicians?.length > 0;
  const hasProducts = order.orderProducts?.length > 0;
  const isNotFinished = !finishedStatuses.includes(order.status as OrderStatusResponse.name);
  const hasDate = !!order.datetime;
  const hasHQ = !!order.headquartersId;
  const hasVatTariff = !!order.vatTariffId;

  const canFinish = hasTechnicians && hasProducts && isNotFinished && hasDate && hasHQ && hasVatTariff;

  return canFinish;
};

export const getCanFinish = (order: OrderResponse): boolean => {
  if (order.category === OrderResponse.category.B2C) return getCanFinishB2C(order);
  return getCanFinishB2B(order);
};

export const getCanEdit = (order: OrderResponse): boolean => {
  const isNotFinished = !finishedStatuses.includes(order.status as OrderStatusResponse.name);

  return isNotFinished;
};

export const getHasPdfLink = (order: OrderResponse): boolean => {
  const hasPdfLink = statusesWithLinkToPdf.includes(order.status as OrderStatusResponse.name);

  return hasPdfLink;
};
