import { differenceInMinutes, formatDuration, parseISO } from 'date-fns';
import { FC } from 'react';
import { OrderListItemResponse } from 'src/api';
import useUserFnsLocale from 'src/api/hooks/queries/useUserFnsLocale';

export type MonthTabSmallEventDurationProps = {
  data: OrderListItemResponse;
};

const MonthTabSmallEventDuration: FC<MonthTabSmallEventDurationProps> = ({ data }) => {
  const { data: locale } = useUserFnsLocale();
  if (!data.endDatetime || !data.datetime) return null;

  const d1 = parseISO(data.endDatetime);
  const d2 = parseISO(data.datetime);

  const diffInMinutes = Math.abs(differenceInMinutes(d1, d2));
  const duration = formatDuration({ minutes: diffInMinutes }, { locale });

  return duration;
};

export default MonthTabSmallEventDuration;
