import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { MaterialResponse } from 'src/api';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

const useMaterial = (id: number): UseQueryResult<MaterialResponse> => {
  const client = useApiClient();
  const { queryKey } = queryKeysFactory.materials.detail(id);

  return useQuery(
    queryKey,
    async () => {
      const data = await client.material.getMaterial({ id });
      return data;
    },
    {
      refetchInterval: false,
      staleTime: Infinity,
    },
  );
};

export default useMaterial;
