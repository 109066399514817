import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { UserResponse } from 'src/api';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

const useCurrentUser = (): UseQueryResult<UserResponse> => {
  const client = useApiClient();
  const { queryKey } = queryKeysFactory.users.current;

  return useQuery(
    queryKey,
    async () => {
      const data = await client.user.getCurrentUser();
      return data;
    },
    {
      refetchInterval: false,
      staleTime: 1000 * 60 * 5,
    },
  );
};

export default useCurrentUser;
