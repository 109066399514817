import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { ArrayElement } from 'src/types/commonTypes';

const units = ['piece', 'kg', 'liter', 'meter', 'hour'] as const;

export type UnitData = ArrayElement<typeof units>;
export type UseUnitsReturn = Array<{ value: UnitData; label: string }>;

const useUnits = (): UseUnitsReturn => {
  const intl = useIntl();
  const unitsWithTranslation = useMemo(
    () =>
      units.map((unit) => ({
        value: unit,
        label: intl.formatMessage({ id: `app.material_unit.${unit}` }),
      })),
    [],
  );

  return unitsWithTranslation;
};

export default useUnits;
