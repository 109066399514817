import { FC, HTMLAttributes, useCallback, useMemo } from 'react';
import ContactForm from '../ContactForm';
import { usePopupContext } from 'src/components/Popup/hook';
import { SubmitHandler } from 'react-hook-form';
import useUpdateContact from 'src/api/hooks/mutations/contact/useUpdateContact';
import useDeleteContact from 'src/api/hooks/mutations/contact/useDeleteContact';
import useContact from 'src/api/hooks/queries/useContact';
import { ContactSchema } from '../ContactForm/schema';
import useCurrentTenantConfig from 'src/api/hooks/queries/useCurrentTenantConfig';

export type UpdateContactFormProps = {
  contactId: number;
} & HTMLAttributes<HTMLDivElement>;

const UpdateContactForm: FC<UpdateContactFormProps> = ({ className, contactId }) => {
  const { data: currentTenantConfig } = useCurrentTenantConfig();
  const updateContact = useUpdateContact();
  const deleteContact = useDeleteContact();
  const { data } = useContact(contactId);
  const { onClose } = usePopupContext();

  const onSubmit: SubmitHandler<ContactSchema> = useCallback(
    (data) => {
      updateContact.mutate({ ...data, id: contactId });
      if (data.deletedAt) deleteContact.mutate(contactId);
      onClose();
    },
    [updateContact, deleteContact, onClose],
  );

  const defaultValues = useMemo(
    () => ({ ...currentTenantConfig?.contactForm?.defaultValues, ...data }),
    [currentTenantConfig, data],
  );

  return (
    <ContactForm
      hideCategory
      className={className}
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      contactId={contactId}
    />
  );
};

UpdateContactForm.displayName = 'UpdateContactForm';

export default UpdateContactForm;
