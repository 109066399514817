import { Column } from '@tanstack/react-table';
import { filter } from 'ramda';
import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { UserResponse } from 'src/api';
import DropdownFilter from 'src/components/Table/filters/DropdownFilter';
import { USER_ROLES } from 'src/constants/userRoles';

export type RolesFilterProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  column: Column<any, unknown>;
  onlyRoles?: UserResponse.role[];
};

const RolesFilter: FC<RolesFilterProps> = ({ column, onlyRoles }) => {
  const intl = useIntl();
  const usedRoles = onlyRoles ?? filter((role) => role !== UserResponse.role.SYSTEM_ADMINISTRATOR, USER_ROLES);

  return (
    <DropdownFilter
      defaultValue={''}
      items={[
        {
          label: <FormattedMessage id='app.common.all' />,
          value: '',
        },
        ...(usedRoles ?? []).map((role) => ({
          label: intl.formatMessage({
            id: `app.role.${role}`,
          }),
          value: role,
        })),
      ]}
      column={column}
    />
  );
};

export default RolesFilter;
