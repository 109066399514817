import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { ContactResponse } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

const useRestoreContact = (): UseMutationResult<ContactResponse, unknown, number> => {
  const client = useApiClient();
  const queryClient = useQueryClient();

  return useMutation<ContactResponse, unknown, number>(
    async (contactId: number) => {
      const data = await client.contact.restoreContact({ id: contactId });
      return data as ContactResponse;
    },
    {
      onSuccess: (_, contactId) => {
        queryClient.invalidateQueries(queryKeysFactory.contacts.list.queryKey);
        queryClient.invalidateQueries(queryKeysFactory.contacts.detail(contactId));
        toast(<FormattedMessage id='app.toasts.update_success' />, {
          type: 'success',
        });
      },
      onError: () => {
        toast(<FormattedMessage id='app.toasts.update_fail' />, {
          type: 'error',
        });
      },
    },
  );
};

export default useRestoreContact;
