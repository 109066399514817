import { FC, useState } from 'react';
import { isSameDay, isSameMonth } from 'date-fns';
import { twMerge } from 'tailwind-merge';
import WeekDaysHeader from '../common/WeekDaysHeader';
import MonthHeader from '../common/MonthHeader';
import WeekRow from '../common/WeekRow';
import clsx from 'clsx';
import useMonthGridState from '../hooks/useMonthGridState';
import DayCell from '../common/DayCell';

export type WeekPickerProps = {
  className?: string;
  defaultCurrentMonthStart?: Date;
  defaultSelected?: { monthStart: Date; weekRow: number; weekStart: Date; weekEnd: Date };
  onWeekSelect?: (result: { monthStart: Date; weekRow: number; weekStart: Date; weekEnd: Date }) => void;
};

export type WeekPickerSelectedValue = { monthStart: Date; weekRow: number };

const WeekPicker: FC<WeekPickerProps> = ({ className, defaultCurrentMonthStart, defaultSelected, onWeekSelect }) => {
  const [selected, setSelected] = useState<WeekPickerSelectedValue | undefined>(defaultSelected);
  const { weekRows, currentMonthStart, onNextMonth, onPrevMonth } = useMonthGridState({
    defaultCurrentMonthStart,
  });

  const handleSelectWeek = (monthStart: Date, weekRow: number): void => {
    setSelected({ monthStart, weekRow });
    onWeekSelect?.({
      monthStart,
      weekRow,
      weekStart: weekRows[weekRow][0],
      weekEnd: weekRows[weekRow].at(-1) as Date,
    });
  };

  return (
    <div className={twMerge('w-max', className)}>
      <MonthHeader onNextMonth={onNextMonth} onPrevMonth={onPrevMonth} currentMonthStart={currentMonthStart} />
      <WeekDaysHeader />
      <div className='flex flex-col gap-y-1'>
        {weekRows.map((week, index) => {
          const isWeekSelected =
            selected && isSameDay(selected.monthStart, currentMonthStart) && selected.weekRow === index;

          return (
            <WeekRow
              key={index}
              week={week}
              onClick={() => handleSelectWeek(currentMonthStart, index)}
              className={twMerge(
                'cursor-pointer',
                isWeekSelected ? 'border-primary' : 'hover:border-y-gray-400 hover:border-dashed',
              )}
            >
              {week.map((date) => (
                <DayCell
                  greyedOut={!isSameMonth(date, currentMonthStart)}
                  key={date.getTime()}
                  date={date}
                  className={clsx(
                    isWeekSelected && 'first:bg-primary first:text-white last:bg-primary last:text-white',
                  )}
                />
              ))}
            </WeekRow>
          );
        })}
      </div>
    </div>
  );
};

export default WeekPicker;
