import { FC, HTMLAttributes, useCallback } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { CreateBranchRequest } from 'src/api';
import useAddContactBranch from 'src/api/hooks/mutations/contact/useAddContactBranch';
import { usePopupContext } from 'src/components/Popup/hook';
import BranchForm from '../BranchForm';

export type AddBranchFormProps = {
  contactId: number;
} & HTMLAttributes<HTMLDivElement>;

const AddBranchForm: FC<AddBranchFormProps> = ({ className, contactId }) => {
  const addBranch = useAddContactBranch();
  const { onClose } = usePopupContext();

  const onSubmit: SubmitHandler<CreateBranchRequest> = useCallback(
    (data) => {
      addBranch.mutate({ ...data, contactId });
      onClose();
    },
    [addBranch, onClose],
  );

  return <BranchForm onSubmit={onSubmit} className={className} />;
};

AddBranchForm.displayName = 'AddBranchForm';

export default AddBranchForm;
