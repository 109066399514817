import { createColumnHelper } from '@tanstack/react-table';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { LicenceResponse, TenantWithLicencesResponse } from 'src/api';
import Table, { TableProps } from 'src/components/Table';
import TextFilter from 'src/components/Table/filters/TextFilter';
import { ExtendedColumnDef } from 'src/types/table';

export type TenantsTableProps = Omit<TableProps<TenantWithLicencesResponse>, 'columns'>;

const columnHelper = createColumnHelper<TenantWithLicencesResponse>();

const columns: ExtendedColumnDef<TenantWithLicencesResponse>[] = [
  {
    ...columnHelper.accessor('id', {
      id: 'tenant.id',
      header: () => <FormattedMessage id='app.common.id' />,
      enableColumnFilter: false,
      enableSorting: true,
      cell: (info) => info.renderValue(),
      size: 50,
    }),
  },
  {
    ...columnHelper.accessor('tenantName', {
      id: 'tenant_name',
      header: () => <FormattedMessage id='app.tenant.tenant_name' />,
      cell: (info) => info.renderValue(),
      enableColumnFilter: true,
      enableSorting: true,
    }),
    renderFilter: (column) => <TextFilter column={column} />,
  },
  {
    ...columnHelper.accessor('applicationTitle', {
      id: 'application_title',
      header: () => <FormattedMessage id='app.tenant.application_title' />,
      cell: (info) => info.renderValue(),
      enableColumnFilter: true,
      enableSorting: true,
    }),
    renderFilter: (column) => <TextFilter column={column} />,
  },
  {
    ...columnHelper.accessor('maxUserCount', {
      id: 'maxUserCount',
      header: () => <FormattedMessage id='app.tenant.max_user_count' />,
      cell: (info) => info.renderValue(),
      enableColumnFilter: false,
      enableSorting: false,
    }),
  },
  {
    ...columnHelper.accessor('currentUserCount', {
      id: 'currentUserCount',
      header: () => <FormattedMessage id='app.tenant.current_user_count' />,
      cell: (info) => info.renderValue(),
      enableColumnFilter: false,
      enableSorting: false,
    }),
  },
  // TODO: add lic filter
  {
    ...columnHelper.accessor('licenses', {
      enableColumnFilter: false,
      enableSorting: false,
      id: 'licenses',
      header: () => <FormattedMessage id='app.tenant.licenses' />,
      cell: (info) => (info.getValue() as LicenceResponse[])?.map((licence) => licence.module).join(', '),
    }),
  },
  {
    ...columnHelper.accessor('deletedAt', {
      id: 'deleted_at',
      header: () => <FormattedMessage id='app.contact.active_tenant' />,
      enableColumnFilter: false,
      enableSorting: true,
      cell: (info) =>
        info.getValue() ? <FormattedMessage id='app.common.no' /> : <FormattedMessage id='app.common.yes' />,
      size: 220,
    }),
  },
];

const TenantsTable: React.FC<TenantsTableProps> = (props) => {
  const navigate = useNavigate();
  return <Table columns={columns} handleRowClick={(original) => navigate(`/tenant/${original.id}`)} {...props} />;
};

export default TenantsTable;
