import clsx from 'clsx';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ContactResponse } from 'src/api';
import useCurrentTenantConfig from 'src/api/hooks/queries/useCurrentTenantConfig';
import ParametersRow from 'src/components/common/ParametersRow';
import { MessageId } from 'src/types/commonTypes';

export type ContactPreviewProps = {
  data: ContactResponse;
  className: string;
};

const ContactPreview: React.FC<ContactPreviewProps> = ({ data, className }) => {
  const { data: tenantConfig } = useCurrentTenantConfig();

  return (
    <div className={clsx(className, 'flex flex-col w-full')}>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.contact.company_name' defaultMessage='Název společnosti' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.companyName}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.customer.category' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.category}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.common.city' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.city}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.common.street' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.street}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.common.zip' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.zip}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.common.country' />
        </ParametersRow.Label>
        <ParametersRow.Value>
          {data.country && (
            <FormattedMessage id={`app.country.${data.country}` as MessageId} defaultMessage={data.country} />
          )}
        </ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.common.phone' defaultMessage='Telefon' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.phoneNumber}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.common.email' defaultMessage='Email' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.email}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.common.ico' defaultMessage='IČO' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.in}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.common.vat' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.vat}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.common.preferred_currency' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.preferredCurrency}</ParametersRow.Value>
      </ParametersRow>
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.common.preferred_language' />
        </ParametersRow.Label>
        <ParametersRow.Value>
          {data.preferredLanguage && (
            <FormattedMessage
              id={`app.language.${data.preferredLanguage.toLocaleLowerCase()}` as MessageId}
              defaultMessage={data.preferredLanguage}
            />
          )}
        </ParametersRow.Value>
      </ParametersRow>
      {tenantConfig?.contactForm?.showInternalCategory && (
        <ParametersRow>
          <ParametersRow.Label>
            <FormattedMessage id='app.contact.internal_category' />
          </ParametersRow.Label>
          <ParametersRow.Value>{data.internalCategory}</ParametersRow.Value>
        </ParametersRow>
      )}
      {tenantConfig?.contactForm?.showContractNumber && (
        <ParametersRow>
          <ParametersRow.Label>
            <FormattedMessage id='app.contact.contract_number' />
          </ParametersRow.Label>
          <ParametersRow.Value>{data.contractNumber}</ParametersRow.Value>
        </ParametersRow>
      )}

      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.contact.active_contact' />
        </ParametersRow.Label>
        <ParametersRow.Value>
          {' '}
          {!data.deletedAt ? <FormattedMessage id='app.common.yes' /> : <FormattedMessage id='app.common.no' />}
        </ParametersRow.Value>
      </ParametersRow>
    </div>
  );
};

export default ContactPreview;
