import { FC, useCallback } from 'react';
import useAddHeadquaters from 'src/api/hooks/mutations/headquaters/useAddHeadquaters';
import { usePopupContext } from 'src/components/Popup/hook';
import { CreateHeadquatersRequest } from 'src/api';
import { SubmitHandler } from 'react-hook-form';
import HeadquatersForm from '../HeadquatersForm';

export type AddHeadquatersFormProps = {
  className?: string;
};

const AddHeadquatersForm: FC<AddHeadquatersFormProps> = ({ className }) => {
  const addHeadquaters = useAddHeadquaters();
  const { onClose } = usePopupContext();

  const onSubmit: SubmitHandler<CreateHeadquatersRequest> = useCallback(
    (data) => {
      addHeadquaters.mutate(data);
      onClose();
    },
    [addHeadquaters, onClose],
  );

  return <HeadquatersForm className={className} onSubmit={onSubmit} />;
};

AddHeadquatersForm.displayName = 'AddHeadquatersForm';

export default AddHeadquatersForm;
