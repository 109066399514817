import { FC } from 'react';
import { twMerge } from 'tailwind-merge';
import Avatar from '../Avatar';
import { UserIcon } from '@heroicons/react/24/outline';
import ColorAvatar from '../ColorAvatar';

export type TechnicianAvatarsStackProps = {
  className?: string;
  names: string[];
};

const VISIBLE_NAMES_COUNT = 3;

const TechnicianAvatarsStack: FC<TechnicianAvatarsStackProps> = ({ names, className }) => {
  const namesCount = names.length;
  const hiddenNamesCount = Math.max(namesCount - VISIBLE_NAMES_COUNT, 0);
  const visibleNames = names.slice(0, VISIBLE_NAMES_COUNT);

  return (
    <div
      className={twMerge('flex relative h-8', className)}
      style={{
        minWidth: hiddenNamesCount ? `${32 + (VISIBLE_NAMES_COUNT + 1) * 20}px` : `${32 + (namesCount - 1) * 20}px`,
      }}
    >
      {!!hiddenNamesCount && (
        <Avatar className='z-[2000] bg-rose-400 rounded-full border-2 border-neutral-100'>
          <UserIcon className='text-rose-900 w-5 h-5' />
        </Avatar>
      )}
      <div className={twMerge(`absolute flex h-8`)}>
        {visibleNames.map((name, index) => (
          <ColorAvatar
            key={index}
            name={name}
            className={twMerge('absolute rounded-full top-0', visibleNames.length > 1 && 'border-2 border-neutral-100')}
            style={{
              left: `${((hiddenNamesCount ? 1 : 0) + index) * 20}px`,
              zIndex: 2000 + index,
            }}
          />
        ))}
      </div>
      {!!hiddenNamesCount && (
        <Avatar
          style={{ left: `${(VISIBLE_NAMES_COUNT + 1) * 20}px`, zIndex: 2000 + names.length }}
          className='absolute bg-white rounded-full border-2 border-neutral-100'
        >
          <div className='w-full h-full border-2 border-neutral-200 rounded-full flex justify-center items-center'>
            <span className='text-sm text-center font-semibold'>{`+${hiddenNamesCount}`}</span>
          </div>
        </Avatar>
      )}
    </div>
  );
};

export default TechnicianAvatarsStack;
