import { FC } from 'react';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import Title from 'src/components/Title';
import TenantConfigurationForm from '../TenantConfigurationForm';
import { Navigate } from 'react-router-dom';
import useAllHeadquarters from 'src/api/hooks/queries/useAllHeadquarters';
import useAllOrderTypes from 'src/api/hooks/queries/useAllOrderTypes';
import useAllVatTariffs from 'src/api/hooks/queries/useAllVatTariffs';
import Loader from 'src/components/utils/Loader';
import useTenantConfig from 'src/api/hooks/queries/useTenantConfig';

export type TenantConfigurationWizzardProps = {
  className?: string;
  tenantId: number;
};

const TenantConfigurationWizzard: FC<TenantConfigurationWizzardProps> = ({ className, tenantId }) => {
  const { data: vatTariffs, isLoading: isLoadingVatTariffs } = useAllVatTariffs();
  const { data: headquarters, isLoading: isLoadingHeadquarters } = useAllHeadquarters();
  const { data: orderTypes, isLoading: isLoadingOrderTypes } = useAllOrderTypes();
  const { data: tenantConfig, isLoading: isLoadingConfig } = useTenantConfig(tenantId);
  const config = tenantConfig?.config;

  const isLoading = isLoadingVatTariffs || isLoadingHeadquarters || isLoadingOrderTypes || isLoadingConfig;

  if (isLoading) return <Loader />;
  if (!vatTariffs || !headquarters || !orderTypes) return <Navigate to='/500' />;
  if (!config) return null;

  return (
    <div className={clsx(className, 'flex flex-col gap-y-10')}>
      <Title>
        <FormattedMessage id='app.common.configuration' />
      </Title>
      <TenantConfigurationForm
        tenantId={tenantId}
        config={config}
        orderTypes={orderTypes}
        vatTariffs={vatTariffs}
        headquarters={headquarters}
      />
    </div>
  );
};

TenantConfigurationWizzard.displayName = 'TenantConfigurationWizzard';

export default TenantConfigurationWizzard;
