import { FC, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import useDeliveryTariff from 'src/api/hooks/queries/useDeliveryTariff';
import BackIcon from 'src/components/Icons/BackIcon';
import PageContentHeader from 'src/components/PageContentHeader';
import Popup from 'src/components/Popup';
import Loader from 'src/components/utils/Loader';
import useGetIsModuleValid from 'src/hooks/useGetIsModuleValid';
import { Modules } from 'src/types/commonTypes';
import DeliveryTariffPreview from './DeliveryTariffPreview';
import UpdateDeliveryTariffFrom from './UpdateDeliveryTariffFrom';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';
import Button from 'src/components/Button';
import useDeleteDeliveryTariff from 'src/api/hooks/mutations/deliveryTariffs/useDeleteDeliveryTariff';
import useRestoreDeliveryTariff from 'src/api/hooks/mutations/deliveryTariffs/useRestoreDeliveryTariff';
import PageCard from 'src/components/PageCard';

export type DeliveryTariffsDetailPageProps = {
  //
};

const DeliveryTariffsDetailPage: FC<DeliveryTariffsDetailPageProps> = () => {
  const deliveryTariffId = Number(useParams().deliveryTariffId);
  const navigate = useNavigate();
  const getIsModuleValid = useGetIsModuleValid();
  const { isLoading, data } = useDeliveryTariff(deliveryTariffId);
  const deleteDeliveryTariff = useDeleteDeliveryTariff();
  const restoreDeliveryTariff = useRestoreDeliveryTariff();
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleButtonClick = useCallback(() => {
    if (data?.deletedAt) restoreDeliveryTariff.mutate(deliveryTariffId);
    else deleteDeliveryTariff.mutate(deliveryTariffId);
  }, [restoreDeliveryTariff, deleteDeliveryTariff, data, deliveryTariffId]);

  if (!getIsModuleValid(Modules.ADMINISTRATION)) return <Navigate to='/' />;
  if (isLoading) return <Loader />;
  if (!data) return <Navigate to='/404' />;

  return (
    <>
      <PageContentHeader
        className='my-4'
        title={
          <div className='flex gap-x-2'>
            <FormattedMessage id='app.delivery_tariff_detail_page.title' values={{ value: data.id }} />
            <Button size='small' variant='cta' onClick={handleButtonClick}>
              {data?.deletedAt ? <EyeIcon className='h-6 mr-1' /> : <EyeSlashIcon className='h-6 mr-1' />}
            </Button>
          </div>
        }
        primaryButtonText={<FormattedMessage id='app.buttons.edit' />}
        secondaryButtonText={
          <>
            <BackIcon />
            <FormattedMessage id='app.buttons.back' defaultMessage='Zpět' />
          </>
        }
        onPrimaryButtonClick={() => setIsPopupOpen(true)}
        onSecondaryButtonClick={() => navigate(-1)}
        mobilePrimaryButtonType='edit'
      />
      <PageCard>
        <DeliveryTariffPreview data={data} />
      </PageCard>
      <Popup
        title={<FormattedMessage id='app.delivery_tariff_page.update_tarriff_popup_title' />}
        open={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
      >
        <UpdateDeliveryTariffFrom deliveryTariffId={deliveryTariffId} />
      </Popup>
    </>
  );
};

DeliveryTariffsDetailPage.displayName = 'DeliveryTariffsDetailPage';

export default DeliveryTariffsDetailPage;
