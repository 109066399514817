import { Column } from '@tanstack/react-table';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import useAllOrderTypes from 'src/api/hooks/queries/useAllOrderTypes';
import DropdownFilter from 'src/components/Table/filters/DropdownFilter';

export type OrderTypeFilterProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  column: Column<any, unknown>;
};

const OrderTypeFilter: FC<OrderTypeFilterProps> = ({ column }) => {
  const { data } = useAllOrderTypes();

  return (
    <DropdownFilter<null | number>
      horizontal='left'
      defaultValue={null}
      items={[
        {
          label: <FormattedMessage id='app.common.all' />,
          value: null,
        },
        ...(data ?? []).map((orderType) => ({
          label: orderType.name,
          value: orderType.id,
        })),
      ]}
      column={column}
    />
  );
};

export default OrderTypeFilter;
