import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { assocPath } from 'ramda';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { OrderTypeRepsonse, UpdateTenantConfigField } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';
import { WithId } from 'src/types/mutations';

type UpdateTenantConfigFieldNormalized = Omit<UpdateTenantConfigField, 'value'> & { value: unknown };

const useUpdateOrderTypeConfigField = (): UseMutationResult<
  void,
  unknown,
  WithId<UpdateTenantConfigFieldNormalized>
> => {
  const queryClient = useQueryClient();
  const client = useApiClient();

  return useMutation<void, unknown, WithId<UpdateTenantConfigFieldNormalized>, { oldOrderType: OrderTypeRepsonse }>(
    async ({ id, path, value }) => {
      await client.orderTypes.updateOrderTypeConfigField({
        id,
        requestBody: {
          path,
          value,
        } as UpdateTenantConfigField,
      });
    },
    {
      onMutate: async ({ id, path, value }) => {
        const queryKey = queryKeysFactory.orderTypes.detail(id).queryKey;
        const orderType = queryClient.getQueryData<OrderTypeRepsonse>(queryKey);
        if (!orderType) return;
        const changePath = path.split('.');

        const config = orderType.slConfig;
        const newConfig = assocPath(changePath, value, config ?? {});
        const newOrderType = assocPath(['slConfig'], newConfig, orderType);

        queryClient.setQueryData(queryKey, newOrderType);
        return { id, oldOrderType: orderType };
      },
      onSuccess: (_, { id }) => {
        queryClient.invalidateQueries(queryKeysFactory.orderTypes.list.queryKey);
        queryClient.invalidateQueries(queryKeysFactory.orderTypes.detail(id).queryKey);
        toast(<FormattedMessage id='app.toasts.update_success' />, {
          type: 'success',
        });
      },
      onError: (_err, { id }, context) => {
        toast(<FormattedMessage id='app.toasts.update_fail' />, {
          type: 'error',
        });

        const oldOrderType = context?.oldOrderType;
        if (!oldOrderType) return;

        const queryKey = queryKeysFactory.orderTypes.detail(id).queryKey;
        queryClient.setQueryData(queryKey, oldOrderType);
      },
    },
  );
};

export default useUpdateOrderTypeConfigField;
