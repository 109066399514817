import { Transition } from '@headlessui/react';
import { HeartIcon, TrashIcon } from '@heroicons/react/24/outline';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Navigate, useParams } from 'react-router-dom';
import { ContactResponse } from 'src/api';
import useDeleteContact from 'src/api/hooks/mutations/contact/useDeleteContact';
import useRestoreContact from 'src/api/hooks/mutations/contact/useRestoreContact';
import useContact from 'src/api/hooks/queries/useContact';
import useContactBranches from 'src/api/hooks/queries/useContactBranches';
import useContactEmployees from 'src/api/hooks/queries/useContactEmployees';
import useContactProducts from 'src/api/hooks/queries/useContactProducts';
import Button from 'src/components/Button';
import ParametersListWithPreviews from 'src/components/common/ParametersListWithPreviews';
import ParametersRow from 'src/components/common/ParametersRow';
import Loader from 'src/components/utils/Loader';
import { standartTransitionProps } from 'src/constants/transitions';
import mapOrEmpty from 'src/helpers/mapOrEmpty';
import useOpenPopup from 'src/hooks/popups/useOpenPopup';
import useWithConfirmationPopup from 'src/hooks/popups/useWithConfirmationPopup';
import useIsCurrentUserAllowedToEdit from 'src/hooks/useIsCurrentUserAllowedToEdit';
import AddBranchPopup from './AddBranchPopup';
import AddEmployeePopup from './AddEmployeePopup';
import AddProductPopup from './AddProductPopup';
import BranchListItemDetail from './BranchListItemDetail';
import ContactPreview from './ContactPreview';
import EmployeeListItemDetail from './EmployeeListItemDetail';
import ProductListItemDetail from './ProductListItemDetail';

export type SortType = {
  path: string[];
  direction: 'asc' | 'desc';
  type: 'number' | 'string';
};

const ContactDetailPage: React.FC = () => {
  const isCurrentUserAllowedToEdit = useIsCurrentUserAllowedToEdit();
  const withConfirmationPopup = useWithConfirmationPopup();

  const contactId = Number(useParams()?.contactId);

  const openAddBranchPopup = useOpenPopup('add_branch');
  const openAddEmployeePopup = useOpenPopup('add_employee');
  const openAddProductPopup = useOpenPopup('add_product');

  const deleteContact = useDeleteContact();
  const onDeleteContact = withConfirmationPopup({
    title: <FormattedMessage id='app.contact_page.delete_contact_popup.title' />,
    onOk: () => deleteContact.mutate(contactId),
  });

  const restoreContact = useRestoreContact();
  const onRestoreContact = withConfirmationPopup({
    title: <FormattedMessage id='app.contact_page.restore_contact_popup.title' />,
    onOk: () => restoreContact.mutate(contactId),
  });

  const { data: contact, isFetched: isContactFetched, isLoading: isContactLoading } = useContact(contactId);
  const {
    data: employees,
    isFetched: areEmployeesFetched,
    isLoading: isLoadingEmployees,
  } = useContactEmployees(contactId);
  const { data: branches, isFetched: areBranchesFetched, isLoading: isLoadingBranches } = useContactBranches(contactId);
  const { data: products, isFetched: areProductsFetched, isLoading: isLoadingProducts } = useContactProducts(contactId);

  const employeesListItems = useMemo(
    () =>
      mapOrEmpty(
        (i) => ({
          title: i.name,
          path: `/contact/${contactId}/employee/${i.id}`,
          renderPreview: () => <EmployeeListItemDetail contactId={contactId} employeeId={i.id} />,
        }),
        employees,
      ),
    [employees],
  );
  const branchesListItems = useMemo(
    () =>
      mapOrEmpty(
        (i) => ({
          title: i.name,
          path: `/contact/${contactId}/branch/${i.id}`,
          renderPreview: () => <BranchListItemDetail contactId={contactId} branchId={i.id} />,
        }),
        branches,
      ),
    [branches],
  );
  const productsListItems = useMemo(
    () =>
      mapOrEmpty(
        (i) => ({
          title: i.productName + (i.productSerialNumber ? ` (${i.productSerialNumber})` : ``),
          path: `/product/${i.id}`,
          renderPreview: () => <ProductListItemDetail branchId={i.branchId ?? undefined} productId={i.id} />,
        }),
        products,
      ),
    [products],
  );

  const isFetched = isContactFetched && areEmployeesFetched && areBranchesFetched && areProductsFetched;
  const isLoading = isContactLoading || isLoadingEmployees || isLoadingBranches || isLoadingProducts;

  const isB2C = contact?.category === ContactResponse.category.B2C;

  if (isLoading) return <Loader />;
  if (!contact) return <Navigate to='/404' />;

  return (
    <>
      <Transition show={isFetched} {...standartTransitionProps}>
        <div className='flex w-full'>
          <div className='flex flex-col w-full pb-20'>
            <ParametersRow.Title>
              <FormattedMessage id='app.contact_page.detail_title' defaultMessage='Detail kontaktu' />
              {isFetched && !contact?.deletedAt ? (
                <Button variant='cta' size='small' onClick={onDeleteContact}>
                  <TrashIcon className='h-6' />
                </Button>
              ) : (
                <Button variant='cta' size='small' onClick={onRestoreContact}>
                  <HeartIcon className='h-6' />
                </Button>
              )}
            </ParametersRow.Title>
            <ContactPreview className={'mb-20'} data={contact} />
            <div className='grid lg:grid-cols-2 gap-24 grid-cols-1'>
              {contact.category === ContactResponse.category.B2B && (
                <>
                  <ParametersListWithPreviews
                    data={branchesListItems}
                    title={<FormattedMessage id='app.contact_page.branches_list.title' />}
                    onAddClick={openAddBranchPopup}
                    hideAddButton={!isCurrentUserAllowedToEdit}
                  />
                  <ParametersListWithPreviews
                    data={employeesListItems}
                    title={<FormattedMessage id='app.contact_page.employees_list.title' />}
                    onAddClick={openAddEmployeePopup}
                    hideAddButton={!isCurrentUserAllowedToEdit}
                  />
                </>
              )}
              <ParametersListWithPreviews
                data={productsListItems}
                onAddClick={openAddProductPopup}
                hideAddButton={!isCurrentUserAllowedToEdit}
                title={<FormattedMessage id='app.contact_page.products_list.title' />}
              />
            </div>
          </div>
        </div>
      </Transition>
      <AddBranchPopup />
      <AddEmployeePopup />
      <AddProductPopup category={contact?.category} contactId={contactId} fixedBranchId={isB2C ? null : undefined} />
    </>
  );
};

export default ContactDetailPage;
